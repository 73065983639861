export class TractDestination {
  constructor (serverObj) {
    this.tractDestinationId = serverObj.tractDestinationId
    this.tractId = serverObj.tractId
    this.tractName = serverObj.tractName
    this.destinationAccountId = serverObj.destinationAccountId
    this.destinationAccountName = serverObj.destinationAccount
    this.isExternal = serverObj.isExternal ?? true
    this.distance = serverObj.distance
    this.requiresExt1 = serverObj.requiresExt1
    this.requiresExt2 = serverObj.requiresExt2
    this.contractCount = serverObj.contractCount
    this.products = serverObj.tractDestinationProducts.map(p => new TractDestinationProduct(p))
  }

  isProductAdded = (productId) => {
    const index = this.products.findIndex(p => p.productId === productId)
    return index !== -1
  }
}

class TractDestinationProduct {
  constructor (serverObj) {
    this.tractDestinationProductId = serverObj.tractDestinationProductId
    this.productId = serverObj.productId
    this.productName = serverObj.product
    this.isByproduct = serverObj.isByproduct
  }
}
