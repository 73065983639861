import messages from '../../lang'
import i18n from '../../i18n'

export const getRelevantUrlComponent = (url) => {
  const pathComponents = url.split('/')
  // Is the last component an id (i.e. a number) or not?
  const lastComponentOffset = isNaN(parseInt(pathComponents[pathComponents.length - 1])) ? 1 : 2
  // Use the offset to get the last relevant component.
  let lastComponent = (pathComponents[pathComponents.length - lastComponentOffset]).toLowerCase()
  // Remove the last character if it is an s.
  if (lastComponent.charAt(lastComponent.length - 1) === 's') {
    lastComponent = lastComponent.slice(0, lastComponent.length - 1)
  }
  // Return the dictionary value or the last component.
  return renameDictionary[lastComponent] || lastComponent
}

const m = (process.env.NODE_ENV === 'development')
  ? label => {
    const o = messages[i18n.locale][label]
    if (o === undefined) console.warn(`Rename dictionary defines a key for missing i18n label '${label}'`)
    return o ?? label
  } : label => (messages[i18n.locale]?.[label] ?? label).toLowerCase()

const renameDictionary = {
  tracttype: m('tractType'),
  tractpayable: m('tractPayable'),
  tractcontent: m('cruiseData'),
  tractharvest: m('tractHarvest'),
  journalentrie: m('accrual'),
  ticketexport: m('ticketExport'),
  defectdefinition: m('defectDefinition'),
  businessentitie: m('entity'),
  timeinyard: m('timeInYard'),
  tractdeliveredcost: m('deliveredTractCosts'),
  ticketsummarie: m('ticketSummaries'),
  harvestbyregion: m('harvestByRegion'),
  deviceregistrations: m('deviceRegistrations'),
  applicationuser: m('user'),
  ticketfinancialrecoveriesexport: m('financialRecoveryExport'),
  ticketfinancialsexport: m('financialExport'),
  ticketimage: m('ticketImage'),
  companyinfo: m('companyInfo'),
  servertime: m('serverTime'),
  activitytemplate: m('activityTemplate'),
  recoverie: m('recovery'),
  activitydetail: m('activityDetail'),
  activitie: m('activity'),
  userpreference: m('userPreferences'),
  millinfo: m('millInformation'),
  consumptionbatche: m('consumptionBatch'),
  postbatche: m('postBatch'),
  jecsvexport: m('accrualCsvExport'),
  arcsvexport: m('receivableCsvExport'),
  apcsvexport: m('payableCsvExport'),
  tractpayment: m('tractPayment'),
  journalentrycalculation: m('accrualCalculations'),
  journalentryregister: m('accrualRegister'),
  receivableregister: m('receivableRegister'),
  arproductionsummary: m('receivableProductionSummary'),
  producerproduction: m('producerProduction'),
  ticketregister: m('ticketRegister'),
  paymentregister: m('paymentRegister'),
  payperiod: m('payPeriod'),
  fiscalyear: m('fiscalYear'),
  addresse: m('address'),
  diameterweight: m('diameterWeights'),
  modification: m('advanceModification'),
  activitytemplatedetail: m('templateDetail'),
  exportbatche: m('exportBatch'),
  activitybuilder: m('activityBuilder'),
  accountpayable: m('accountPayment'),
  insurancepolicie: m('insurancePolicy'),
  receiversetting: m('receiverSettings')
}
