import moment from 'moment'
import { timeframesInLocalTime as tf, timeframeToUtcIsoString } from '../../utils/DateFormatter'

export const weekTimeframe = () => {
  const t = moment().hours(0).minutes(0).seconds(0)
  return {
    sinceTime: t.subtract(7, 'days').utc().toISOString()
  }
}

export const timeframes = [
  tf.today,
  tf.yesterday,
  tf.last7Days,
  tf.last14Days,
  tf.last30Days,
  tf.thisWeek,
  tf.lastWeek,
  tf.thisMonth,
  tf.lastMonth,
  tf.thisQuarter,
  tf.lastQuarter
].map(timeframeToUtcIsoString)

export const MAX_DAYS_FOR_WIDGET_INTERACTION = 15
