<template>
  <v-card flat>
    <v-card-text :class="containerClass">
      <v-row dense>
        <v-col cols="12" v-if="!hideHeader">
          <TableActions
          :actions="actions"
          :showSearch="showSearch"
          :showDense="showDense"
          @search="searchChanged"
          @dense-toggled="denseToggled"
          @table-action="emitTableAction"
        >
          <template #prepend-search v-if="showSearch">
            <slot name="prepend-search"/>
          </template>
          <template #search-replacement>
            <slot name="search-replacement"></slot>
          </template>
          <template #custom-controls>
            <slot name="custom-controls"></slot>
          </template>
        </TableActions>
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="12">
              <slot name="filters"></slot>
            </v-col>
            <v-col cols="12">
              <slot name="selection-controls"></slot>
            </v-col>
            <v-col cols="12">
              <v-data-table
              :id="abbreviateHeaders ? 'small-headers-table' : ''"
              ref="dataTable"
              :items="items"
              :headers="headers"
              :search="search"
              :dense="dense"
              :group-by="groupBy"
              :item-key="tableKey"
              :expanded="expanded"
              :show-select="showSelect"
              v-model="propSelected"
              :show-expand="showExpand"
              :sort-by="defaultSort"
              :sort-desc="sortDesc"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1]
              }"
              :items-per-page="25"
              :loading="loading"
              style="table-layout: fixed !important;"
              :hide-default-footer="items.length < 25"
              :no-data-text="$t('noItemsAvailable')"
              @current-items="setCurrentItems"
              @click:row="rowClicked"
            >
              <template
                v-for="slot in customCells"
                v-slot:[`item.${slot.value}`]="{ item }">
                <span v-if="slot.dateFormat" :key="slot.value">
                  {{getDate(item[slot.value], slot.dateFormat)}}
                </span>
                <slot v-else :name="`${slot.slotName}`" :item="item"></slot>
              </template>

              <template #group.header="slotProp" v-if="groupBy">
                <div style="background-color: white;" :class="abbreviateHeaders ? 'py-2 px-4' : 'pa-4'" :id="$vuetify.breakpoint.xs ? 'group-header-xs' : 'group-header'">
                  <slot name="group.header" v-bind="slotProp">
                    <span class="title">
                      {{ slotProp.group }}
                    </span>
                  </slot>
                </div>
              </template>

              <template #group.summary="slotProp" v-if="groupBy">
                <slot name="group.summary" v-bind="slotProp">
                  <slot name="group.summary.prepend" v-bind="slotProp"/>
                  <v-col v-if="$vuetify.breakpoint.xs" class="pa-0 group-totals-row-mobile">
                    <tr v-for="(total, i) in getFormattedTotals(slotProp.items)" :key="`table-total-${i}`" :class="`${total.classes} font-weight-bold v-data-table__mobile-table-row`">
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header pl-4" style="font-size:.75rem"> {{ $t(total.name) }} </div>
                        <div class="v-data-table__mobile-row__cell pr-4" style="font-size:.75rem"> {{ total.value }} </div>
                      </td>
                    </tr>
                  </v-col>
                  <td v-for="(total, i) in getFormattedTotals(slotProp.items)" :key="`table-total-${i}`" :class="`${total.classes} font-weight-bold`">
                    <div v-if="!$vuetify.breakpoint.xs">
                      <slot :name="`group-total.${total.name}`" v-bind="{ total, group: slotProp }">
                        {{total.value}}
                      </slot>
                    </div>
                  </td>
                  <slot name="group.summary.append" v-bind="slotProp"/>
                </slot>
              </template>

              <template v-for="h in headers.filter(h => h.tooltip)" v-slot:[`header.${h.value}`]>
                <v-tooltip :key="h.value" color="secondary" top >
                  <template v-slot:activator="{ on }">
                    <span :style="abbreviateHeaders ? 'font-size: .8rem !important;' : ''" v-on="on">{{ abbreviateHeaders ? h.textAbbr || h.text : h.text }}</span>
                  </template>
                  <span class="subtitle-1 white--text" style="white-space: pre-line">{{h.tooltip}}</span>
                </v-tooltip>
              </template>

              <template v-for="header in formattedHeaders" #[`item.${header.value}`]="{ item }">
                <span :key="`item-${header.value}`">
                {{header.format ? header.format(item[header.value]) : item[header.value]}}
                </span>
              </template>

              <template #body.append="{ groupedItems }">
                <tr v-if="!$vuetify.breakpoint.xs && groupBy && groupedItems?.length > 1">
                  <div class="py-4" style="background-color: white;"/>
                </tr>
                <tr v-if="!$vuetify.breakpoint.xs && groupedItems?.length !== 1 && showTotals" class="font-weight-bold" style="border: solid thin;">
                  <td v-for="(total, i) in formattedTotals" :key="`table-total-${i}`" :class="total.classes">
                    <slot :name="`total.${total.name}`" v-bind="total">
                      {{total.value}}
                    </slot>
                  </td>
                </tr>
                <span
                  v-if="$vuetify.breakpoint.xs && groupedItems?.length !== 1 && showTotals"
                  class="text-subtitle-2 ml-4 mt-4 mb-2 font-weight-black"
                >
                  {{ $t('totals') }}
                </span>
                <div v-if="$vuetify.breakpoint.xs && groupedItems?.length !== 1 && showTotals">
                  <tr v-for="(total, i) in formattedTotals" :key="`table-total-${i}`" :class="`${total.classes} font-weight-bold v-data-table__mobile-table-row`">
                    <td class="v-data-table__mobile-row">
                      <div class="v-data-table__mobile-row__header pl-4" style="font-size:.75rem"> {{ $t(total.name) }} </div>
                      <div class="v-data-table__mobile-row__cell pr-4" style="font-size:.75rem"> {{ total.value }} </div>
                    </td>
                  </tr>
                </div>
                <slot name="body.append"></slot>
              </template>

              <template #footer>
                <slot name="footer"></slot>
              </template>
              <template #item.data-table-expand="{ item }">
                <v-icon v-if="shouldShowExpandIcon(item)" @click="expandItem(item)">{{ expandIcon }}</v-icon>
              </template>
              <template #expanded-item="{item}">
                <td :colspan="headers.length">
                  <slot name="expanded-item" :item="item"></slot>
                </td>
              </template>
            </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { tickTock } from '../../../utils/componentHelpers'
import { sumsFor } from '../../../utils/base'
export default {
  name: 'DataTable',

  components: {
    TableActions: () => import('./TableActions.vue')
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    customCells: {
      type: Array,
      required: false
    },
    headers: {
      type: Array,
      required: true
    },
    sumHeaders: {
      type: Array,
      required: false,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      required: false
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    showDense: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    expandIcon: {
      type: String,
      default: 'mdi-chevron-down'
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    tableKey: {
      type: String,
      required: false,
      default: 'default-key'
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    propDense: {
      type: Boolean,
      default: true
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: String,
      default: ''
    },
    sumValues: {
      type: Array,
      default: () => []
    },
    activityTemplate: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showTotals: {
      type: Boolean,
      required: false,
      default: false
    },
    groupBy: {
      type: String,
      required: false,
      default: undefined
    },
    abbreviateHeaders: {
      type: Boolean,
      required: false,
      default: false
    },
    keepSelectionWhenItemsChange: {
      type: Boolean,
      required: false,
      default: false
    },
    containerClass: {
      type: String,
      required: false,
      default: undefined
    }
  },

  watch: {
    items (_) {
      if (!this.keepSelectionWhenItemsChange) this.propSelected = []
    },

    currentItems () {
      this.$emit('filtered-items', this.$refs.dataTable.$children[0].filteredItems)
    }
  },

  data: () => ({
    search: '',
    dense: true,
    expanded: [],
    currentItems: undefined
  }),
  created () {
    this.dense = this.propDense
  },

  computed: {
    customValues () {
      return (this.customCells !== undefined)
        ? new Set(this.customCells.map(c => c.value))
        : new Set()
    },
    formattedHeaders () {
      return this.headers.filter(h => h.format !== undefined && !this.customValues.has(h.value))
    },
    formattedTotals () {
      return this.getFormattedTotals(this.items)
    },
    propSelected: {
      get () { return this.selected },
      set (value) { this.$emit('update:selected', value) }
    }
  },

  methods: {
    tickTock,
    utcToLocalDate,
    searchChanged (val) {
      this.search = val
    },

    expandItem (item) {
      if (this.expanded.length === 0) {
        this.expanded = [item]
      } else {
        if (this.expanded[0][this.tableKey] === item[this.tableKey]) {
          this.expanded = []
        } else {
          this.expanded = [item]
        }
      }
    },

    denseToggled (val) {
      this.dense = val
    },
    emitTableAction (val) {
      this.$emit(val)
    },

    getDate (date, dateFormat) {
      if (date === '' || !date) {
        return this.$t('notAvailable')
      } else {
        return utcToLocalDate(date, dateFormat)
      }
    },

    getSumValue (header) {
      const sumIndex = this.sumValues.findIndex(sv => sv.headerName === header.value)
      if (sumIndex !== -1) {
        return this.sumValues[sumIndex].value
      } else {
        return '-'
      }
    },

    shouldShowExpandIcon (item) {
      if (item.activityDetails) {
        return item.activityDetails.length > 0
      } else {
        return true
      }
    },

    setCurrentItems (val) {
      this.currentItems = val
    },

    getFormattedTotals (items) {
      if (!this.showTotals) return []

      const headers = (this.groupBy)
        ? this.headers.filter(h => h.value !== this.groupBy)
        : this.headers

      const sums = sumsFor(items, this.sumHeaders ?? headers.filter(h => h.total).map(h => h.value))

      const alignmentClassFor = hh => {
        switch (hh.align) {
          case 'right':
          case 'end':
            return 'text-right'
          case 'center':
          case 'middle':
            return 'text-center'
          default:
            return 'text-left'
        }
      }

      return headers.map(h => {
        const totalValue = (h.total)
          ? h.total({ items, sums, total: sums[h.value], count: items.length })
          : undefined

        return {
          name: h.value,
          value: totalValue !== undefined ? h.format !== undefined ? h.format(totalValue) : totalValue : '-',
          classes: alignmentClassFor(h)
        }
      })
    },

    rowClicked (data, related, e) {
      this.$emit('click:row', { data, related, e })
    }
  }
}
</script>
<style>
#small-headers-table>.v-data-table__wrapper>table>tbody>tr>td { /* Affects all non-header rows */
  font-size: .75rem;
}
#small-headers-table #group-header>span.title { /* Affects group headers */
  font-size: .85rem !important;
}
#small-headers-table #group-header-xs>span.title { /* Affects group headers on xs screen*/
  font-size: .9rem !important;
  font-weight: 600;
}
</style>
