<template>
  <v-dialog v-model="shortcuts" width="350px" persistent @click:outside="closeDialog" id="shortcuts-dialog" scrollable>
    <v-card style="width: auto; height: 95%; position: absolute; bottom: 10px; right: 10px;">
      <v-card-title class="secondary white--text headline">
        <span>Keyboard Shortcuts</span>
        <v-spacer/>
        <Icon
        icon="mdi-close"
        dataTestId="shortcut-close-dialog"
        iconColor="white"
        :large="true"
        :small="false"
        :tooltipText="$t('close')"
        @icon-clicked="closeDialog"
        />
      </v-card-title>
      <v-card-text>
        <v-row class="mt-0">
          <v-col cols="12" xs="12" sm="12" md="6">
            <span class="title">{{ this.$t('globalNavigation') }}</span>
            <table width="100%" style="border-collapse: separate; border-spacing: 0 15px">
              <tr v-for="(shortcut, index) in globalNavigation" :key="index" class="mt-6">
                <td>
                  {{shortcut.shortcut}}
                </td>
                <td>
                  {{shortcut.name}}
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <span class="title">{{ this.$t('settingsNavigation') }}</span>
            <table width="100%" style="border-collapse: separate; border-spacing: 0 15px">
              <tr v-for="(shortcut, index) in settingsNavigation" :key="`settings-${index}`" class="mt-6">
                <td>
                  {{shortcut.shortcut}}
                </td>
                <td>
                  {{shortcut.name}}
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <span class="title">{{ this.$t('globalShortcuts') }}</span>
            <table width="100%" style="border-collapse: separate; border-spacing: 0 15px">
              <tr v-for="(shortcut, index) in globalShortcuts" :key="`global-${index}`" class="mt-6">
                <td>
                  {{shortcut.shortcut}}
                </td>
                <td>
                  {{shortcut.name}}
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <span class="title">{{ this.$t('ticketsShortcuts') }}</span>
            <table width="100%" style="border-collapse: separate; border-spacing: 0 15px">
              <tr v-for="(shortcut, index) in ticketsKeyboardShortcuts" :key="`tickets-${index}`" class="mt-6">
                <td>
                  {{shortcut.shortcut}}
                </td>
                <td>
                  {{shortcut.name}}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { globalNavigationShortcuts, settingsNavigationShortcuts, ticketsShortcuts, commonShortcuts } from '../../utils/Shortcuts'
export default {
  name: 'GlobalShortcuts',

  components: {
    Icon: () => import('../helper/Icon.vue')
  },

  data: () => ({
    commandInitiated: false,
    focused: false,
    globalNavigation: globalNavigationShortcuts.shortcuts,
    settingsNavigation: settingsNavigationShortcuts.shortcuts,
    ticketsKeyboardShortcuts: ticketsShortcuts.shortcuts,
    globalShortcuts: commonShortcuts
  }),

  computed: {
    ...mapGetters('global', ['shortcuts']),
    ...mapGetters('user', ['initialized', 'initializing'])
  },

  beforeDestroy () {
    document.removeEventListener('focusin', this.focusListener)
    document.removeEventListener('focusout', this.blurListener)
    document.removeEventListener('keyup', this.keyListener)
  },

  mounted () {
    document.addEventListener('focusin', this.focusListener)
    document.addEventListener('focusout', this.blurListener)
    document.addEventListener('keyup', this.keyListener)
  },

  methods: {
    ...mapMutations('global', ['setShortcuts', 'setSettingName']),

    handleDialogCommands (key) {
      switch (key) {
        case '?':
          if (!this.initialized || this.initializing) {
            return
          }
          this.setShortcuts(!this.shortcuts)
          break
        case 'g':
          this.commandInitiated = true
          return true
        case 'Escape':
          if (this.shortcuts) {
            this.closeDialog()
          }
          break
        default: break
      }
    },

    handleShortcutCommands (key) {
      this.globalNavigation.forEach((s) => {
        if (key === s.key) {
          this.route(s.route)
        }
      })
      this.commandInitiated = false
    },

    focusListener (e) {
      if (e.target.type === 'text') {
        this.focused = true
      } else if (!e.target.textContent.includes('Keyboard Shortcuts')) {
        this.focused = true
      }
    },

    blurListener (e) {
      if (e.target.type === 'text') {
        this.focused = false
      } else if (!e.target.textContent.includes('Keyboard Shortcuts')) {
        this.focused = false
      }
    },

    keyListener (e) {
      if (this.focused) {
        this.commandInitiated = false
        return
      }

      if (this.handleDialogCommands(e.key)) {
        return
      }

      if (this.commandInitiated) {
        this.handleShortcutCommands(e.key)
      }
    },

    closeDialog () {
      this.setShortcuts(false)
    },

    route (name) {
      if (this.$router.app._route.path !== `/${name}`) {
        this.$router.push(name)
      }
      this.commandInitiated = false
      this.closeDialog()
    }
  }
}
</script>
