<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <LocationSelection
        :isEditing="isEditing"
        :spot="tract.spot"
        :markerCircle="tract?.pickupRange ?? 1"
        :locations="locations"
        :propTract="propTract"
        @dragged="locationChanged"
        @shapefile="shapeFileUploaded"
        @geojsonfile="geojsonUploaded"
        v-if="!mapLoading">
          <template #map-and-parcel-number>
            <v-col cols="12" xs="12" sm="12" md="4" lg="3">
              <v-text-field
                v-model="tract.mapAndParcelNumber"
                data-testid="tract-parcel-number"
                :label="$t('mapAndParcelNumber')"
                color="black"
                counter
                maxlength="32"
              ></v-text-field>
            </v-col>
          </template>
          <template #acres>
            <v-col cols="3" xs="3" sm="2" md="1" lg="1">
              <v-text-field
                data-testid="tract-acres"
                v-model="tract.acres"
                :label="$t('acres')"
                :rules="[rules.integer, rules.validAcres]"
                color="black"
              />
            </v-col>
          </template>
          <template #pickup-radius>
            <v-col cols="9" xs="9" sm="5" md="3" lg="2">
              <v-select
              color="black"
              item-color="secondary"
              v-model="tract.pickupRange"
              :label="$t('tractPickupRadius')"
              :items="pickupRadiusValues"
              />
            </v-col>
          </template>
          <template #logging-accessibility>
            <v-col cols="12" xs="12" sm="5" md="4" lg="3">
              <v-select
              color="black"
              item-color="secondary"
              item-text="value"
              item-value="tagId"
              clearable
              v-model="tract.loggingAccessibilityTagId"
              :label="$t('loggingAccessibility')"
              :items="loggingAccessibilityTags"
              />
            </v-col>
          </template>
        </LocationSelection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'
import { TagArea } from '../../../utils/Enumerations'
import { defaultCoordinates, addMapControlScript, removeMapControlScript } from '@/utils/MapHelpers'
import { getFormattedCoord } from '../../../utils/NumericMutations'
export default {
  name: 'TractGeography',

  props: {
    propTract: {
      type: Object
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    LocationSelection: () => import('@/components/tract/LocationSelection.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    tract: {
      spot: null,
      acres: 0,
      mapAndParcelNumber: '',
      loggingAccessibilityTagId: undefined,
      pickupRange: 1609
    },
    mapLoading: true,
    locations: [],
    pickupRadiusValues: [],
    loggingAccessibilityTags: []
  }),

  watch: {
    tract: {
      handler (val) {
        this.$emit('tract-changed', this.tract)
      },
      deep: true
    }
  },

  async created () {
    this.setInitialValues(this.propTract)
    addMapControlScript()
    if (!this.tract.spot.spotId) {
      this.getLocation()
    } else {
      this.mapLoading = false
    }
    this.pickupRadiusValues = this.getTractPickupRadiusValues()
    this.loggingAccessibilityTags = await this.fetchTags(TagArea.LoggingAccessibility.value)
  },

  beforeDestroy () {
    removeMapControlScript()
  },

  methods: {
    ...mapActions('tract', ['getLocationWithCompanyAddress', 'getLocationWithCoordinate']),
    ...mapActions('tags', ['fetchTags']),
    async requestLocation () {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },

    locationChanged (spot) {
      this.tract.spot = spot
      this.$emit('tract-changed', this.tract)
    },

    shapeFileUploaded (shapefile) {
      this.$emit('shapefile', shapefile)
    },

    geojsonUploaded (geojson) {
      this.$emit('geojson', geojson)
    },

    async getLocation () {
      try {
        const { coords: coordinate } = await this.requestLocation()
        const { countrySubdivision, countrySecondarySubdivision } = await this.getLocationWithCoordinate({
          latitude: coordinate.latitude,
          longitude: coordinate.longitude
        })

        this.tract.spot = {
          latitude: getFormattedCoord(coordinate.latitude),
          longitude: getFormattedCoord(coordinate.longitude),
          countrySubdivision: countrySubdivision,
          countrySecondarySubdivision: countrySecondarySubdivision
        }
      } catch (error) {
        try {
          const spotInfo = await this.getLocationWithCompanyAddress()
          this.tract.spot = spotInfo
        } catch (error) {
          this.tract.spot = {
            latitude: defaultCoordinates.latitude,
            longitude: defaultCoordinates.longitude,
            countrySubdivision: 'NC',
            countrySecondarySubdivision: 'Forsyth'
          }
        }
      } finally {
        this.mapLoading = false
      }
    },

    revealMap () {
      this.mapLoading = false
    },

    setInitialValues (propTract) {
      this.tract.spot = propTract.spot ?? {
        countrySecondarySubdivision: '',
        countrySubdivision: '',
        latitude: 0,
        longitude: 0,
        name: ''
      }
      this.tract.acres = propTract.acres
      this.tract.mapAndParcelNumber = propTract.mapAndParcelNumber
      this.tract.pickupRange = propTract.pickupRange ?? 1609
      this.tract.loggingAccessibilityTagId = propTract.loggingAccessibilityTagId
    },

    getTractPickupRadiusValues () {
      return [
        {
          text: '¼ Mile',
          value: 402
        },
        {
          text: '½ Mile',
          value: 804
        },
        {
          text: '¾ Mile',
          value: 1207
        },
        {
          text: '1 Mile',
          value: 1609
        },
        {
          text: '2 Miles',
          value: 3218
        },
        {
          text: '3 Miles',
          value: 4828
        }
      ]
    }
  }
}
</script>
