<template>
  <v-card>
    <v-card-title class="secondary white--text">
      {{$t('editBundle')}}
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper @submit="saveBtnClicked">
        <v-container class="px-0">
          <v-text-field
          v-if="bundle"
          v-model="bundle.name"
          :rules="[rules.required]"
          maxlength="64"
          counter
          />
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { rules } from '@/utils/FormRules.js'
import { mapActions } from 'vuex'
export default {
  name: 'EditBundleDialog',

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    propBundle: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    bundle: null,
    rules
  }),

  created () {
    this.bundle = JSON.parse(JSON.stringify(this.propBundle))
  },

  methods: {
    ...mapActions('activity-templates', ['updateTemplateBundleName']),
    async saveBtnClicked () {
      if (this.bundle.name.length === 0) {
        this.setSnackError('Bundle name cannot be blank.')
        return
      }

      await this.updateTemplateBundleName(this.bundle)
      this.$emit('close', true)
    }
  }
}
</script>
