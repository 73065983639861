<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
        :items="settlements"
        :headers="headers"
        :expanded="expanded"
        item-key="contractInfo.contractId"
        show-expand
        single-expand
        @click:row="rowClicked"
        style="cursor: pointer;"
        dense>
          <template #item.contractInfo.netTons="{item}">
            {{numberWithCommas(item.contractInfo.netTons, 3)}}
          </template>
          <template #item.contractInfo.grossTons="{item}">
            {{numberWithCommas(item.contractInfo.grossTons, 3)}}
          </template>
          <template #item.contractInfo.grossAmount="{item}">
            {{formatMoney(item.contractInfo.grossAmount)}}
          </template>
          <template #item.contractInfo.amount="{item}">
            {{formatMoney(item.contractInfo.amount)}}
          </template>
          <template #item.rate="{item}">
            <Icon
            v-if="item.contractInfo.inlineRates.length > 1"
            icon="mdi-dots-horizontal"
            iconColor="black"
            tooltipColor="black"
            :tooltipText="$t('multipleRatesForContract')"
            @icon-clicked="rateVerificationClicked(item)"
            elementId="slrv"/>
            <span v-else>
              {{ item.contractInfo.inlineRates[0] ? formatMoney(item.contractInfo.inlineRates[0]) : formatMoney(0) }}/t
            </span>
          </template>
          <template #item.contractInfo.status="{item}">
            <v-chip
            small
            @click.stop="toggleSettlementStatus(item.contractInfo)"
            :class="getSettlementStatus(item.contractInfo.contractId).color">
              {{getSettlementStatus(item.contractInfo.contractId).text}}
            </v-chip>
          </template>
          <template #expanded-item="{item}">
            <td :colspan="headers.length + 1">
              <SettlementAccountTable :contractInfo="item.contractInfo" :payeeInfo="item.payeeInfo" @refresh-settlement="refreshModuleDataAndClearStaleData"/>
            </td>
          </template>
          <template #item.actions="{item}">
            <SettlementsContractActions
              :contract="item.contractInfo"
              :tickets="item.payeeInfo[0].loadSummaries"
              @unposted="refreshModuleData()"
              @edited="refreshModuleData()"
            />
          </template>
        </v-data-table>
      </v-col>
      <Dialog :stateId="dialogId"
      @dialog-closing="closeRateVerification">
        <RateVerificationDialog
        v-if="rateVerificationDialog"
        @close="closeRateVerification"
        @refresh-batch="refreshModuleData()"
        :contractId="rateVerificationContractId"/>
      </Dialog>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { ContractModes, PayPeriodStatus, UserClaims } from '@/utils/Enumerations.js'
import { settlementsByContractHeaders } from '@/headers/Cycles.js'
import { mapActions, mapGetters } from 'vuex'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
export default {
  name: 'SettlementsByContract',

  components: {
    SettlementAccountTable: () => import('@/components/settlements/accounts-payable-module/settlements-by-contract/SettlementAccountTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    RateVerificationDialog: () => import('@/components/settlements/rate-verification/RateVerificationDialog.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    SettlementsContractActions: () => import('@/components/settlements/SettlementsContractActions.vue')
  },

  props: {
    settlementsObject: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    expanded: [],
    rateVerificationDialog: false,
    rateVerificationSettlement: undefined,
    rateVerificationContractId: undefined,
    UserClaims,
    dialogId: uniqueDialogId('rate-verification-dialog')
  }),

  computed: {
    ...mapGetters('settlements', ['dateConfiguration', 'settlementContractStatuses']),
    ...mapGetters('user', ['companyInfo']),
    settlements () {
      if (this.settlementsObject === undefined) { return [] }
      return this.settlementsObject.settlements
    },

    headers () {
      return settlementsByContractHeaders(this.$i18n.locale)
    },

    isExportBatch () {
      return this.dateConfiguration.exportBatch?.exportBatchId !== undefined
    },

    logContractMode () {
      return ContractModes[0]
    }
  },

  methods: {
    ...mapActions('settlements', ['toggleContractStatus', 'refreshModuleData', 'clearStaleSubModuleData']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    numberWithCommas,
    userAssignedClaim,

    rowClicked (item) {
      if (item.contractInfo.contractId === this.expanded[0]?.contractInfo.contractId) {
        this.expanded = []
        return
      }
      this.expanded = []
      this.$nextTick(() => {
        this.expanded = [item]
      })
    },

    toggleSettlementStatus (contract) {
      if (this.dateConfiguration?.payPeriod.status === 3) return
      this.toggleContractStatus(contract)
    },

    getSettlementStatus (contractId) {
      if (this.dateConfiguration.payPeriod.status === PayPeriodStatus.Exported.value || this.dateConfiguration.exportBatch?.exportBatchId !== undefined) {
        return {
          text: 'Verified',
          color: 'green white--text'
        }
      }

      const status = this.settlementContractStatuses[this.dateConfiguration.payPeriod.payPeriodId][contractId]
      const isSameAmount = status.oldAmt === status.amt

      if (status.verified === 1) {
        return {
          text: isSameAmount ? 'Verified' : `Modified (${formatMoney(status.oldAmt)})`,
          color: isSameAmount ? 'green white--text' : 'red white--text'
        }
      } else {
        return {
          text: 'Pending',
          color: ''
        }
      }
    },

    rateVerificationClicked (settlement) {
      this.rateVerificationContractId = settlement.contractInfo.contractId
      this.rateVerificationDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    closeRateVerification () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.rateVerificationDialog = false
      this.rateVerificationContractId = undefined
    },

    async refreshModuleDataAndClearStaleData () {
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: false })
    }
  }
}
</script>
