export default {
  allTracts: (state) => state.tracts,
  tractsLoading: (state) => state.tractsLoading,
  chosenTract: (state) => state.chosenTract,
  transitionMode: (state) => state.transitionMode,
  tractTimberVolume: (state) => state.tractTimberVolume,
  standingTimberFilter: (state) => state.standingTimberFilter,
  committedTimberFilter: (state) => state.committedTimberFilter,
  timberPortfolioFilter: (state) => state.timberPortfolioFilter
}
