<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <span class="subtitle-1">
            {{ $t('contacts') }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col cols="12" md="6" lg="6" xl="6">
          {{ $t('mainContact') }}
          <ContactCard
          :contact="contactForCard(mainContact)"
          isMainContact
          @click:delete="$emit('deleteMainContact')"
          @click:edit="editMainContact = true"/>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          {{ $t('ticketContact') }}
          <ContactCard
          :contact="contactForCard(ticketContact)"
          isMainContact
          @click:delete="$emit('deleteTicketContact')"
          @click:edit="editTicketContact = true"/>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="showDialog" max-width="50%">
      <ContactForm
      v-if="showDialog"
      isCompanyInfoContact
      :propContact="getPropContact"
      @close="closeDialog()"
      @contact-updated="contactUpdated"/>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'LocationContacts',

  components: {
    ContactCard: () => import('@/components/account/contacts/ContactCard.vue'),
    ContactForm: () => import('@/components/account/contacts/ContactForm.vue')
  },

  props: {
    mainContact: {
      type: Object,
      default: undefined
    },
    ticketContact: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    editMainContact: false,
    editTicketContact: false
  }),

  computed: {
    showDialog () {
      return this.editMainContact || this.editTicketContact
    },

    getPropContact () {
      if (this.editMainContact) return this.contactForCard(this.mainContact)
      if (this.editTicketContact) return this.contactForCard(this.ticketContact)
      return undefined
    }
  },

  methods: {
    closeDialog () {
      this.editMainContact = false
      this.editTicketContact = false
    },

    isContactEmpty (contact) {
      return contact.firstName === '' &&
      contact.lastName === '' &&
      contact.email === '' &&
      contact.phoneNumber === ''
    },

    contactForCard (contact) {
      return {
        person: {
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          position: 'position',
          email: contact?.email,
          phoneNumber: contact?.phoneNumber
        }
      }
    },

    contactUpdated (contact) {
      if (this.editMainContact) this.$emit('mainContactSet', contact.person)
      if (this.editTicketContact) this.$emit('ticketContactSet', contact.person)
      this.closeDialog()
    }
  }
}
</script>
