<template>
  <v-row v-if="loading" class="my-12" justify="center" align="center">
    <v-progress-linear indeterminate color="secondary"/>
    <span>Loading Financial Information</span>
  </v-row>
  <v-tabs v-else v-model="currentTab" color="secondary">
    <v-tab v-show="payables.length > 0">{{$t('payables')}}</v-tab>
    <v-tab v-show="receivables.length > 0">{{$t('receivables')}}</v-tab>
    <v-tab v-show="journalEntries.length > 0">{{$t('accruals')}}</v-tab>
    <v-tab>{{$t('advances')}}</v-tab>
    <v-tab-item disabled>
      <PayableExpansionPanels
      v-show="payables.length > 0"
      class="mt-4"
      :payables.sync="payables"/>
    </v-tab-item>
    <v-tab-item disabled>
      <ReceivableExpansionPanels
      v-show="receivables.length > 0"
      class="mt-4"
      :receivables.sync="receivables"/>
    </v-tab-item>
    <v-tab-item disabled>
      <JournalEntryExpansionPanels
      v-show="journalEntries.length > 0"
      class="mt-4"
      :journalEntries.sync="journalEntries"/>
    </v-tab-item>
    <v-tab-item disabled>
      <AccountAdvances
      :accountDetail="true"
      :account="account"/>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AccountFinancials',

  components: {
    PayableExpansionPanels: () => import('@/components/accounting/accounts-payable/PayableExpansionPanels.vue'),
    ReceivableExpansionPanels: () => import('@/components/accounting/accounts-receivable/ReceivableExpansionPanels.vue'),
    JournalEntryExpansionPanels: () => import('@/components/accounting/journal-entries/JournalEntryExpansionPanels.vue'),
    AccountAdvances: () => import('@/components/account/advances/AccountAdvances.vue')
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  computed: {
    loading () {
      return this.receivablesLoading || this.journalEntriesLoading || this.payablesLoading
    }
  },

  created () {
    this.getFinancialData(this.account.accountId)
  },

  watch: {
    loading (val) {
      if (!val) {
        this.setInitialTab()
      }
    }
  },

  data: () => ({
    currentTab: 0,
    payables: [],
    payablesLoading: true,
    receivables: [],
    receivablesLoading: true,
    journalEntries: [],
    journalEntriesLoading: true
  }),

  methods: {
    ...mapActions('payable', ['getPayablesForAccount']),
    ...mapActions('receivable', ['getReceivablesForAccount']),
    ...mapActions('journal-entries', ['getJournalEntriesForAccount']),

    async getFinancialData (accountId) {
      this.payablesLoading = true
      this.receivablesLoading = true
      this.journalEntriesLoading = true
      try {
        const payableResponse = await this.getPayablesForAccount(accountId)
        const receivablesResponse = await this.getReceivablesForAccount(accountId)
        const entriesResponse = await this.getJournalEntriesForAccount(accountId)
        this.payables = payableResponse
        this.receivables = receivablesResponse
        this.journalEntries = entriesResponse
      } finally {
        this.payablesLoading = false
        this.receivablesLoading = false
        this.journalEntriesLoading = false
      }
    },

    setInitialTab () {
      if (this.payables.length > 0) {
        return
      }

      if (this.receivables.length > 0) {
        this.currentTab = 1
        return
      }

      if (this.journalEntries.length > 0) {
        this.currentTab = 2
        return
      }

      this.currentTab = 3
    }
  }
}
</script>
