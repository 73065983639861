<template>
  <v-row dense>
    <v-col cols="auto">
      <v-select
      v-model="selectedFiscalYear"
      :items="fiscalYears"
      :label="$t('fiscalYear')"
      :menu-props="{ bottom: true, offsetY: true }"
      item-value="fiscalYearId"
      color="secondary"
      class="select-field-width"
      return-object
      outlined
      @change="fiscalYearSelected"
      >
        <template #selection="{item}">
          <Icon
            dataTestId="fiscal-year-status"
            :icon="getFiscalYearStatus(item).icon"
            :tooltipText="getFiscalYearStatus(item).tooltipText"
            :small="false"
            :iconColor="getFiscalYearStatus(item).color"
            :tooltipColor="getFiscalYearStatus(item).color"
          />
          <span>{{getPeriodString(item)}}</span>
        </template>
        <template #item="{item}">
          <Icon
            dataTestId="fiscal-year-status"
            :icon="getFiscalYearStatus(item).icon"
            :tooltipText="getFiscalYearStatus(item).tooltipText"
            :small="false"
            :iconColor="getFiscalYearStatus(item).color"
            :tooltipColor="getFiscalYearStatus(item).color"
          />
          <span>{{getPeriodString(item)}}</span>
        </template>
      </v-select>
    </v-col>
    <v-col cols="auto">
      <v-select
      v-model="selectedPayPeriod"
      :items="payPeriods"
      :label="$t('payPeriod')"
      :menu-props="{ bottom: true, offsetY: true }"
      color="secondary"
      class="select-field-width"
      item-value="payPeriodId"
      return-object
      outlined
      @change="payPeriodSelected"
      >
        <template #selection="{item}">
          <Icon
            v-if="item.payPeriodId"
            dataTestId="fiscal-year-status"
            :icon="getPeriodStatus(item).icon"
            :tooltipText="getPeriodStatus(item).tooltipText"
            :small="false"
            :iconColor="getPeriodStatus(item).color"
            :tooltipColor="getPeriodStatus(item).color"
          />
          <span>{{getPeriodString(item)}}</span>
        </template>
        <template #item="{item}">
          <Icon
            v-if="item.payPeriodId"
            dataTestId="fiscal-year-status"
            :icon="getPeriodStatus(item).icon"
            :tooltipText="getPeriodStatus(item).tooltipText"
            :small="false"
            :iconColor="getPeriodStatus(item).color"
            :tooltipColor="getPeriodStatus(item).color"
          />
          <span>{{getPeriodString(item)}}</span>
        </template>
      </v-select>
    </v-col>
    <v-col :style="{ maxWidth: $vuetify.breakpoint.xl ? '900px' : '300px', minWidth: '280px', wordBreak: 'break-word' }" v-if="!selectPeriod">
      <v-autocomplete
      v-model="selectedExportBatch"
      :label="$t('exportBatch')"
      :items="exportBatches"
      :menu-props="{ bottom: true, offsetY: true }"
      item-text="label"
      item-value="exportBatchId"
      color="secondary"
      outlined
      return-object
      id="export-batch-autocomplete"
      @change="exportBatchSelected">
        <template #selection="{item}">
          {{ item.label }}
          <Icon
          v-if="item.settlementArchiveStatus === SettlementArchiveStatus.Pending.value || item.settlementArchiveStatus === SettlementArchiveStatus.Upgrade.value"
          icon="mdi-sync"
          :small="false"
          :tooltipText="$t('exportInProgress')"/>
        </template>
        <template #item="{item}">
          {{ item.label }}
          <Icon
          v-if="item.settlementArchiveStatus === SettlementArchiveStatus.Pending.value || item.settlementArchiveStatus === SettlementArchiveStatus.Upgrade.value"
          icon="mdi-sync"
          :small="false"
          :tooltipText="$t('exportInProgress')"/>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="auto" v-if="!selectPeriod">
      <v-select
      v-model="currentCategory"
      :label="$t('accountingCategory')"
      :items="categories"
      :menu-props="{ bottom: true, offsetY: true }"
      class="select-category"
      color="secondary"
      item-text="name"
      return-object
      outlined
      @change="$emit('category-changed', currentCategory)"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { PayPeriodStatus, FiscalYearStatus, AccountingCategory, SettlementArchiveStatus } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PayPeriodSelector',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    selectPeriod: {
      type: Boolean,
      default: false
    },
    entireFiscalYearSelectable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    fiscalYears: [],
    payPeriods: [],
    exportBatches: [],
    selectedFiscalYear: undefined,
    categories: AccountingCategory.enums,
    currentCategory: AccountingCategory.enums[0],
    selectedPayPeriod: undefined,
    selectedExportBatch: undefined,
    SettlementArchiveStatus
  }),

  computed: {
    ...mapGetters('pay-period', ['allPayPeriods']),
    ...mapGetters('fiscal-year', ['allFiscalYears']),
    ...mapGetters('export-batch', ['allExportBatches'])
  },

  watch: {
    allPayPeriods: {
      handler () {
        this.payPeriods = []

        const filteredPayPeriods = this.allPayPeriods
          .filter(pp => pp.status !== PayPeriodStatus.Inactive.value)
          .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))

        if (this.entireFiscalYearSelectable) {
          filteredPayPeriods.push({
            title: this.$t('allPayPeriods'),
            fiscalYearId: this.selectedFiscalYear.fiscalYearId
          })
        }

        this.payPeriods = filteredPayPeriods

        const payPeriod = filteredPayPeriods.find(pp => pp.payPeriodId === this.selectedPayPeriod?.payPeriodId && pp.payPeriodId !== undefined) ?? filteredPayPeriods[0]
        this.payPeriodSelected(payPeriod)
      },
      deep: true
    },
    allFiscalYears: {
      handler () {
        const filteredFiscalYears = this.allFiscalYears
          .filter(fy => fy.status !== FiscalYearStatus.Inactive.value)
          .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))

        this.fiscalYears = filteredFiscalYears
        this.fiscalYearSelected(filteredFiscalYears[0])
      },
      deep: true
    },
    allExportBatches: {
      handler () {
        this.exportBatches = []
        const sortedBatches = JSON.parse(JSON.stringify(this.allExportBatches)).sort((a, b) => Date.parse(b.exportDate) - Date.parse(a.exportDate))

        if (this.selectedPayPeriod.status !== PayPeriodStatus.Exported.value) {
          sortedBatches.unshift({
            exportBatchId: undefined,
            label: this.$t('unexported'),
            exportDate: undefined
          })
        } else if (sortedBatches.length === 0) {
          sortedBatches.unshift({
            exportBatchId: undefined,
            label: this.$t('noExportBatches'),
            exportDate: undefined
          })
        }

        this.exportBatches = sortedBatches
        const updatedSelectedBatch = this.selectedExportBatch
          ? sortedBatches.find(eb => eb.exportBatchId === this.selectedExportBatch.exportBatchId) ?? sortedBatches[0]
          : sortedBatches[0]

        this.exportBatchSelected(updatedSelectedBatch)
      },
      deep: true
    }
  },

  created () {
    this.getFiscalYears()
  },

  methods: {
    ...mapActions('fiscal-year', ['fetchFiscalYears']),
    ...mapActions('pay-period', ['fetchPayPeriods']),
    ...mapActions('export-batch', ['fetchExportBatches']),
    ...mapActions('settlements', ['setCurrentDateConfiguration']),
    utcToLocalDate,

    async getFiscalYears () {
      this.fetchFiscalYears()
    },

    getPayPeriodsForFiscalYear (fiscalYearId) {
      this.fetchPayPeriods(fiscalYearId)
    },

    getExportBatchesForPayPeriod (payPeriodId) {
      this.fetchExportBatches(payPeriodId)
    },

    fiscalYearSelected (fiscalYear) {
      this.selectedFiscalYear = fiscalYear
      this.getPayPeriodsForFiscalYear(fiscalYear.fiscalYearId)
    },

    payPeriodSelected (payPeriod) {
      this.selectedPayPeriod = payPeriod
      this.selectedExportBatch = null
      if (this.selectPeriod) {
        this.$emit('period-selected', {
          ...this.selectedPayPeriod,
          fiscalYearId: this.selectedFiscalYear.fiscalYearId
        })
        return
      }
      this.getExportBatchesForPayPeriod(payPeriod.payPeriodId)
    },

    exportBatchSelected (exportBatch) {
      if (!exportBatch) return
      this.selectedExportBatch = exportBatch
      this.setCurrentDateConfiguration({
        fiscalYear: this.selectedFiscalYear,
        payPeriod: this.selectedPayPeriod,
        exportBatch: this.selectedExportBatch
      })
    },

    getPeriodString (period) {
      if (period.periodId === undefined && period.title) {
        return period.title
      }
      const { startDate, endDate } = period
      const convertedEndDate = new Date(endDate)
      convertedEndDate.setDate(convertedEndDate.getDate() - 1)
      return `${utcToLocalDate(startDate)} - ${utcToLocalDate(convertedEndDate)}`
    },

    getExportBatchString ({ label = '', exportDate }, limit = 0) {
      if (limit > 0 && label.length > limit) return label === '' ? utcToLocalDate(exportDate) : `${label.slice(0, limit)}...`

      return label === '' ? utcToLocalDate(exportDate, 'L - LT') : label
    },

    getPeriodStatus (payPeriod) {
      switch (payPeriod.status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('exported'),
            icon: 'mdi-export'
          }
      }
    },

    getFiscalYearStatus (fiscalYear) {
      switch (fiscalYear.status) {
        case FiscalYearStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case FiscalYearStatus.Open.value:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case FiscalYearStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
      }
    }
  }
}
</script>

<style scoped>
.select-field-width {
  width: 280px;
}

.select-category {
  width: 180px;
}
</style>

<style>
.v-select.v-text-field #export-batch-autocomplete {
  height: 0;
  padding: 0;
}

.v-autocomplete.v-select.v-input--is-focused #export-batch-autocomplete {
  height: inherit;
  padding: inherit;
}
</style>
