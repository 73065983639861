export default {
  tracts: [],
  tractsLoading: false,
  chosenTract: null,
  transitionMode: null,
  tractTimberVolume: [],
  standingTimberFilter: {
    status: [],
    tractType: [],
    tractForester: [],
    team: [],
    myTracts: false
  },
  committedTimberFilter: {
    status: [],
    tractType: [],
    tractForester: [],
    team: [],
    myTracts: false
  },
  timberPortfolioFilter: {
    status: [2, 3],
    tractType: [],
    tractForester: [],
    team: [],
    myTracts: false
  }
}
