<template>
  <v-menu
    v-model="datePickerMenu"
    ref="datePicker"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :close-on-content-click="false"
    :data-testid="dataTestId"
  >
    <template v-slot:activator="{ on, attrs }" >
      <v-row class="pa-0 ma-0">
        <v-row>
          <span style="color:#adadad; margin-left:10px; margin-top:10px; margin-bottom: 0px; font-size:.75em">{{dateLabel}}</span>
          <v-spacer/>
        </v-row>
        <v-row align="center" dense>
            <v-col sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="formattedDateMonth"
                color="black"
                class="centered-input pa-0 ma-0"
                ref="formattedDateMonth"
                v-bind="attrs"
                maxlength=2
                messages="MM"
                :data-testid="`${dataTestId}-picker-month`"
                :rules="[rules.validMonth]"
                :disabled="disabled"
                @keydown.191.exact="$event.preventDefault()"
                @keyup.191.exact="$refs.formattedDateDay.focus()"
                @keydown.111.exact="$event.preventDefault()"
                @keyup.111.exact="$refs.formattedDateDay.focus()"
                @keydown.shift.191.111="$event.preventDefault()"
                @keyup.shift.191.111="$event.preventDefault()"
              ></v-text-field>
            </v-col>
            <v-col sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="formattedDateDay"
                color="black"
                ref="formattedDateDay"
                class="centered-input pa-0 ma-0"
                v-bind="attrs"
                maxlength=2
                messages="DD"
                :data-testid="`${dataTestId}-picker-day`"
                :disabled="disabled"
                :rules="[rules.validDay]"
                @keydown.191.111="$event.preventDefault()"
                @keyup.191.111="$refs.formattedDateYear.focus()"
                @keydown.shift.191.111="$event.preventDefault()"
                @keyup.shift.191.111="$refs.formattedDateMonth.focus()"
              ></v-text-field>
            </v-col>
            <v-col sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="formattedDateYear"
                color="black"
                ref="formattedDateYear"
                class="centered-input pa-0 ma-0"
                v-bind="attrs"
                maxlength=2
                messages="YY"
                :data-testid="`${dataTestId}-picker-year`"
                :disabled="disabled"
                :rules="[rules.validYear]"
                @keydown.191.111="$event.preventDefault()"
                @keyup.191.111="$refs.calendar.$el.focus()"
                @keydown.shift.191.111="$event.preventDefault()"
                @keyup.shift.191.111="$refs.formattedDateDay.focus()"
              >
                <template #append>
                  <v-icon
                    v-on="on"
                    ref="calendar"
                    :disabled="disabled"
                    :color="disabled ? '' : 'secondary'"
                    data-testid="picker-calendar-button"
                    tabindex="-1"
                    @click="setIsoDate(true)"
                    @keydown.shift.191.111="$event.preventDefault()"
                    @keyup.shift.191.111="$refs.formattedDateYear.focus()"
                  >
                    mdi-calendar
                  </v-icon>
                  <v-icon @click="clearDate" color="secondary" v-if="clearable">mdi-close</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <div v-if="!isValidDate || invalid" class="red--text">{{ (invalid) ? (typeof invalid === 'string' ? invalid : 'invalid date') : validDateString }}</div>
            </v-col>
          </v-row>
        </v-row>
    </template>
    <v-date-picker
      v-model="isoDate"
      dark
      color="secondary"
      header-color="black"
      width="500"
      no-title
      :max="max"
      :min="min"
      @input="setFormattedDate()"
      @click:date="() => {
        datePickerMenu = false
      }"
    />
  </v-menu>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import moment from 'moment'

export default {
  name: 'DatePicker',

  props: {
    dateLabel: String,
    startDate: { String, default: new Date().toISOString() },
    focus: { Boolean, default: false },
    hint: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowFuture: {
      type: Boolean,
      default: true
    },
    dataTestId: {
      type: String,
      default: '',
      required: false
    },
    min: {
      type: String,
      default: undefined,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    focusInput: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  watch: {
    isoDate (val) {
      if (val === null) {
        this.$emit('date-picked', null)
        return
      }
      this.$emit('date-picked', moment(val).format())
    },

    formattedDateMonth (val) {
      if (!val) {
        return
      }
      this.setIsoDate()
      const monthNum = parseInt(val)
      if (val.length < 2 && monthNum > 1) {
        this.formattedDateMonth = `0${this.formattedDateMonth}`
      }
    },

    formattedDateDay (val) {
      if (!val) {
        return
      }
      this.setIsoDate()
      const dayNum = parseInt(val)
      if (val.length < 2 && dayNum > 3) {
        this.formattedDateDay = `0${this.formattedDateDay}`
      }
    },

    formattedDateYear (val) {
      if (!val) {
        return
      }
      this.setIsoDate()
    },

    focusInput (val) {
      if (val) {
        this.$refs.formattedDateMonth.focus()
      }
    }
  },

  data: () => ({
    datePickerMenu: false,
    isoDate: '',
    rules: fieldRules.rules,
    formattedDate: '',
    formattedDateMonth: '',
    formattedDateDay: '',
    formattedDateYear: ''
  }),

  computed: {
    isValidDate () {
      if (this.isoDate === null) {
        this.$emit('valid-date', true)
        return true
      }

      if (!this.allowFuture) {
        if (this.formattedDate === '//' ||
        !this.rules.validMonth(this.formattedDateMonth) ||
        !this.rules.validDay(this.formattedDateDay) ||
        !this.rules.validYear(this.formattedDateYear) ||
        this.rules.validDate(this.formattedDate) === this.$t('invalidDate') ||
        this.rules.validPastDate(this.formattedDate) === this.$t('invalidFutureDate')) {
          this.$emit('valid-date', false)
          return false
        }
      } else {
        if (this.formattedDate === '//' ||
        !this.rules.validMonth(this.formattedDateMonth) ||
        !this.rules.validDay(this.formattedDateDay) ||
        !this.rules.validYear(this.formattedDateYear) ||
        this.rules.validDate(this.formattedDate) === this.$t('invalidDate')) {
          this.$emit('valid-date', false)
          return false
        }
      }
      this.$emit('valid-date', true)
      return true
    },
    validDateString () {
      if (this.isoDate === null) return ''

      if (!this.rules.validMonth(this.formattedDateMonth)) {
        return this.$t('invalidMonth')
      } else if (!this.rules.validDay(this.formattedDateDay)) {
        return this.$t('invalidDay')
      } else if (!this.rules.validYear(this.formattedDateYear)) {
        return this.$t('invalidYear')
      } else if (this.rules.validDate(this.formattedDate) === this.$t('invalidDate')) {
        return this.$t('invalidDate')
      }
      if (!this.allowFuture) {
        if (this.rules.validPastDate(this.formattedDate) === this.$t('invalidFutureDate')) {
          return this.$t('invalidFutureDate')
        }
      }
      return ''
    },

    hasError () {
      return this.invalid
    },

    splitDateLabel () {
      return this.dateLabel.split(' ')
    }
  },

  created () {
    if (this.startDate === null) {
      this.isoDate = null
    } else {
      this.isoDate = this.startDate
      this.setFormattedDate()
    }
  },

  mounted () {
    setTimeout(() => {
      if (this.focusInput) {
        this.$refs.formattedDateMonth.focus()
      }
    }, 0)
  },

  methods: {
    setIsoDate (fromIcon) {
      if (fromIcon && (this.isoDate === null || this.isoDate === 'Invalid Date')) {
        this.isoDate = new Date().toISOString()
        this.setFormattedDate()
      }

      this.formattedDate = `${this.formattedDateMonth}/${this.formattedDateDay}/${this.formattedDateYear}`
      if (this.isValidDate) {
        let [month, day, year] = this.formattedDate.split('/')
        if (year.length < 2) {
          year = `0${year}`
        }
        year = `20${year}`
        this.isoDate = moment.utc(`${month}/${day}/${year}`).format('YYYY-MM-DD')
      }
    },

    clearDate () {
      this.isoDate = null
      this.formattedDateMonth = ''
      this.formattedDateDay = ''
      this.formattedDateYear = ''

      this.$nextTick(() => this.$refs.formattedDateYear.blur())
      requestAnimationFrame(() => {
        this.$refs.formattedDateMonth.focus()
      })
    },

    setFormattedDate () {
      if (this.isoDate === null) {
        return
      }

      this.formattedDate = moment(this.isoDate).format('MM/DD/YY')
      const splitDate = this.formattedDate.split('/')
      this.formattedDateMonth = splitDate[0]
      this.formattedDateDay = splitDate[1]
      this.formattedDateYear = splitDate[2]
    }
  }
}
</script>

<style scoped>
  .centered-input ::v-deep(input) {
    text-align: center
  }
  .label {
    text-align: center;
    font-size: .75em;
  }
</style>
