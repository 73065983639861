export default {
  resetState (state) {
    state.entities = {}
  },
  setPayableLoadingStatus (state, status) {
    state.payableLoadingStatus = status
  },
  setPayables (state, payables) {
    state.payables = payables
    state.entities = groupItemsByBusinessEntity(state, payables, 'payables')
  },
  setTractPayables (state, tractPayables) {
    state.tractPayables = tractPayables
    state.entities = groupItemsByBusinessEntity(state, tractPayables, 'tractPayables')
  },
  setUnpaidAdvances (state, unpaidAdvances) {
    state.unpaidAdvances = unpaidAdvances
    state.entities = groupItemsByBusinessEntity(state, unpaidAdvances, 'advances')
  },
  setAccountPayables (state, accountPayables) {
    state.accountPayables = accountPayables
    state.entities = groupItemsByBusinessEntity(state, accountPayables, 'accountPayables')
  }
}

const groupItemsByBusinessEntity = (state, items, key) => {
  items.forEach((item) => {
    if (!state.entities[item.businessEntityName]) {
      state.entities[item.businessEntityName] = {
        payables: [],
        advances: [],
        tractPayables: [],
        accountPayables: []
      }
    }

    state.entities[item.businessEntityName][key].push(item)
  })
  return state.entities
}
