<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" :lg="showImageViewer ? 7 : 12" :xl="showImageViewer ? 7 : 12">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card min-height="205" class="fill-height" outlined hover>
              <v-card-title class="subtitle-1 mt-n1" >{{ $t('typeOfInformation', { typeOf: $t('load') }) }}</v-card-title>
              <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
                <v-container data-testid="load-information-details" fluid grid-list-sm v-if="ticket.createdAt">
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('createdAt')}}:
                    </v-col>
                    <v-col>
                      {{utcToLocalDate(ticket.loadCreatedAt, 'L - LT')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.departedAt">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('departedAt')}}:
                    </v-col>
                    <v-col>
                      {{`${utcToLocalDate(ticket.departedAt, 'L - LT')}`}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('weighedInAt')}}:
                    </v-col>
                    <v-col>
                      {{`${utcToLocalDate(ticket.weighedInAt, 'L - LT')}`}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('inTransit')}}
                    </v-col>
                    <v-col>
                      {{`${timeBetween(ticket.loadCreatedAt, ticket.weighedInAt)}`}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('weighedOut')}}:
                    </v-col>
                    <v-col>
                      {{`${utcToLocalDate(ticket.weighedOutAt, 'L - LT')}`}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('inYard')}}:
                    </v-col>
                    <v-col>
                      {{`${timeBetween(ticket.weighedInAt, ticket.weighedOutAt)}`}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{ $t('product') }}:
                    </v-col>
                    <v-col>
                      {{ticket.product || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row v-if="ticket.hasLogCounts && isLogsTicket" no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      <span id="log-count-shortcut" @click="$emit('logCounts')">{{ $t('pieceCount') }}:</span>
                    </v-col>
                    <v-col>
                      <span id="log-count-shortcut" @click="$emit('logCounts')">{{ticket.pieces || $t('notAvailable')}}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="!ticket.hasLogCounts && isLogsTicket" no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{ $t('pieceCount') }}:
                    </v-col>
                    <v-col>
                      {{ticket.pieces || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row v-if="isLogsTicket" no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{ $t('averageSED') }}:
                    </v-col>
                    <v-col>
                      {{ticket.averageSED || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card min-height="205" class="fill-height" outlined hover>
              <v-card-title class="subtitle-1 mt-n1">{{ $t('typeOfInformation', { typeOf: $t('misc') }) }}</v-card-title>
              <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
                <v-container data-testid="misc-information-details" fluid grid-list-sm v-if="ticket.createdAt">
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('trailerId')}}:
                    </v-col>
                    <v-col>
                      {{ticket.trailerIdentifier}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.logger !== undefined && isLogsTicket">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('logger')}}:
                    </v-col>
                    <v-col>
                      <AccountName
                        :accountId="ticket.loggerAccountId"
                        :certificationStatus="ticket.loggerCertificationStatus"
                      >
                        {{ticket.logger || $t('notAvailable')}}
                      </AccountName>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('driver')}}:
                    </v-col>
                    <v-col>
                      {{ ticket.correctedDriver ?? ticket.driver ?? $t('notSpecified') }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('destinationDeck')}}:
                    </v-col>
                    <v-col>
                      {{ticket.deckName || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.fromDeckId !== null">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('fromDeck')}}:
                    </v-col>
                    <v-col>
                      {{ticket.fromDeckName || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('createdOn')}}:
                    </v-col>
                    <v-col>
                      {{getDeviceString()}}
                    </v-col>
                  </v-row>
                  <v-row v-if="isLogsTicket" no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('averageLength')}}:
                    </v-col>
                    <v-col>
                      {{ticket.averageLength || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('extTicketNumber1')}}:
                    </v-col>
                    <v-col>
                      {{ticket.extTicketNumber1 || extTicketNumberTextField}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('extTicketNumber2')}}:
                    </v-col>
                    <v-col>
                      {{ticket.extTicketNumber2 || extTicketNumberTextField}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.rolledOutAt !== null">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('rolloutActive')}}:
                    </v-col>
                    <v-col>
                      {{utcToLocalDate(ticket.rolledOutAt, 'L - LT')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.rolloutCompletedAt !== null">
                    <v-col :cols="infoColumnBreakpoint">
                      {{ $t('rolloutCompleted') }}:
                    </v-col>
                    <v-col>
                      {{utcToLocalDate(ticket.rolloutCompletedAt, 'L - LT')}}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card min-height="205" class="fill-height" outlined hover>
              <v-card-title class="subtitle-1 mt-n1">{{ $t('typeOfInformation', { typeOf: $t('contract') }) }}</v-card-title>
              <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
                <v-container fluid grid-list-sm>
                  <v-row no-gutters v-if="ticket.tract">
                    <v-col :cols="infoColumnBreakpoint">
                      {{ $t('tract') }}:
                    </v-col>
                    <v-col>
                      <Icon
                      v-if="ticket.tractIsCertified"
                      icon="mdi-certificate-outline"
                      iconColor="black"
                      :tooltipText="$t('tractIsCertified')"
                      tooltipColor="black"/>
                      {{ ticket.tract }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.setting">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('setting')}}:
                    </v-col>
                    <v-col>
                      {{ticket.setting}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.fromAccount">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('fromAccount')}}:
                    </v-col>
                    <v-col>
                      {{ticket.fromAccount || $t('notAvailable')}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="ticket.account">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('account')}}:
                    </v-col>
                    <v-col>
                      <AccountName
                        :certificationStatus="ticket.accountCertificationStatus"
                        :accountId="ticket.accountId"
                      >
                        {{ticket.account}}
                      </AccountName>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('destination')}}:
                    </v-col>
                    <v-col>
                      {{ticket.destination}}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card min-height="205" class="fill-height" outlined hover>
              <v-card-title class="subtitle-1 mt-n1">{{ $t('typeOfInformation', { typeOf: $t('weight') }) }}</v-card-title>
              <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
                <v-container data-testid="weight-information-details" fluid grid-list-sm v-if="ticket.createdAt">
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('inWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(ticket.inWeight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('outWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(ticket.outWeight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{defectWeightBreakdown !== undefined ? $t('totalDefectWeight') : $t('defectWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(ticket.defectWeight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="defectWeightBreakdown !== undefined">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('natureDefectWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(defectWeightBreakdown.natureDefectWeight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="defectWeightBreakdown !== undefined">
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('mismanufacturedDefectWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(defectWeightBreakdown.mismanufactureDefectWeight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('netWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{getNetWeight()}}
                    </v-col>
                  </v-row>
                  <v-row v-if="ticket.contractType === 3" no-gutters>
                    <v-col :cols="infoColumnBreakpoint">
                      {{$t('departureWeight')}}:
                    </v-col>
                    <v-col class="text-right weightfield" :cols="weightCols">
                      {{tonStringFromPounds(ticket.departureWeight)}}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
        <v-card>
          <TicketImageViewer
          v-if="showImageViewer"
          :ticketNumber="ticket.ticketNumber"
          :ticketImageData="ticketImageData"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { utcToLocalDate, timeBetween } from '@/utils/DateFormatter.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { TicketOrigin } from '@/model/Ticket.js'
import { ticketRecognitionClient } from '../../../utils/TicketImages.js'
import { DefectCategory, CorrectionType, ContractType } from '@/utils/Enumerations.js'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketInformation',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountName: () => import('@/components/account/AccountName.vue'),
    TicketImageViewer: () => import('@/components/ticket/TicketImageViewer.vue')
  },

  props: {
    ticket: {
      type: Object,
      required: true
    },
    corrections: {
      type: Array,
      default: () => []
    },
    shouldFetchImages: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    logCounts: false,
    ticketImageData: []
  }),

  computed: {
    ...mapGetters('defects', ['allTicketDefects']),

    defectWeightBreakdown () {
      if (this.allTicketDefects.length === 0) return

      if (this.corrections.length > 0) {
        const correction = this.corrections[0].correction
        if (correction.type === CorrectionType.SpecifyNewTicketValues.value) {
          return {
            natureDefectWeight: correction.natureDefectWeight,
            mismanufactureDefectWeight: correction.mismanufactureDefectWeight
          }
        }
      }

      return this.allTicketDefects.reduce((total, defect) => {
        switch (defect.category) {
          case DefectCategory.Nature.value:
            total.natureDefectWeight += defect.totalLBS
            break
          case DefectCategory.Mismanufactured.value:
            total.mismanufactureDefectWeight += defect.totalLBS
            break
        }
        return total
      }, { natureDefectWeight: 0, mismanufactureDefectWeight: 0 })
    },

    infoColumnBreakpoint () {
      return this.$vuetify.breakpoint.lgAndDown ? 6 : 4
    },

    weightCols () {
      return this.showImageViewer ? 3
        : 2
    },

    showImageViewer () {
      return this.ticketImageData.length > 0
    },

    isLogsTicket () {
      return this.ticket.contractType === ContractType.Production.value ||
      this.ticket.contractType === ContractType.WoodsSale.value ||
      this.ticket.contractType === ContractType.LogYardSale.value
    },

    extTicketNumberTextField () {
      return this.isLogsTicket ? this.$t('notAvailable') : ''
    }
  },

  async created () {
    if (this.shouldFetchImages) {
      this.ticketImageData = await ticketRecognitionClient.getRecognitionData(this.ticket.ticketId, false)
    }
  },

  methods: {
    utcToLocalDate,
    tonStringFromPounds,
    timeBetween,
    getNetWeight () {
      return this.tonStringFromPounds(this.ticket.inWeight - this.ticket.outWeight - this.ticket.defectWeight)
    },

    getDeviceString () {
      switch (this.ticket.origin) {
        case TicketOrigin.WEB_CLIENT:
          return this.$t('createdOnWebClient')
        case TicketOrigin.LOADER:
          return this.$t('createdOnLoader')
        case TicketOrigin.RECEIVER:
          return this.$t('createdOnReceiver')
        case TicketOrigin.YARD_OPERATOR:
          return this.$t('createdOnYardOperator')
        case TicketOrigin.TRANSPORTER:
          return this.$t('createdOnTransporter')
        case TicketOrigin.KIOSK:
          return this.$t('createdOnKiosk')
      }
    }
  }
}
</script>

<style>
  #log-count-shortcut:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #D15F27;
  }
  .weightfield {
    font-family: 'IBM Plex Mono', monospace;
  }
</style>
