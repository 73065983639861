<template>
  <v-data-table
  :items-per-page="-1"
  hide-default-footer
  :items="entries"
  :headers="aggregateHeaders">
    <template #item.businessEntity="{item}">
      <span id="table-shortcut" @click="selection(item)">{{ item.businessEntity }}</span>
    </template>
    <template #item.activity="{item}">
      <span id="table-shortcut" @click="selection(item)">{{ item.activity }}</span>
    </template>
    <template #item.glCode="{item}">
      <span id="table-shortcut" @click="selection(item)">{{ item.glCode }}</span>
    </template>
    <template #item.glOffset="{item}">
      <span id="table-shortcut" @click="selection(item)">{{ item.glOffset }}</span>
    </template>
    <template #item.amount="{item}">
      <span id="table-shortcut" @click="selection(item)">{{ formatMoney(item.amount) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
export default {
  name: 'BasicFinancialTable',

  props: {
    entries: {
      type: Array,
      required: true
    }
  },

  computed: {
    aggregateHeaders () {
      return AdjustingEntriesHeaders.miscPayments()
    }
  },

  methods: {
    formatMoney,
    selection (item) {
      this.$emit('aggregate-selected', item)
    }
  }
}
</script>
