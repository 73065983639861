import { saveAs } from 'file-saver'
import i18n from '@/i18n'
export function exportCSVFile (headers, items, fileName) {
  if (headers) {
    items.unshift(headers)
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items)
  const csv = convertToCSV(jsonObject)
  exportCSVStringAsFile(csv, fileName)
}

export function exportCSVStringAsFile (csvString, fileName) {
  const exportedFilename = fileName + '.csv' || 'export.csv'

  const blob = new Blob([csvString], { type: 'text/csvcharset=utf-8' })
  saveAs(blob, exportedFilename)
}

function convertToCSV (objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  let str = ''

  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const index in array[i]) {
      if (line !== '') line += ','
      line += `"${typeof array[i][index] === 'string' ? array[i][index].replaceAll('"', '""') : array[i][index]}"`
    }

    str += line + '\r\n'
  }

  return str
}

const defaultCsvStringOptions = {
  emptyValue: ''
}

/* column (for items of type T[]) {
 *   label: String,
 *   value: T => Any,
 *   emptyValue: String?,
 * }
 */

const firstDisplayableItem = (...items) => items.find(i => i !== undefined && i !== null)

export function generateCsvString (items, columns, options = defaultCsvStringOptions) {
  options = (options !== defaultCsvStringOptions) ? {
    ...defaultCsvStringOptions,
    ...options
  } : options

  const headerString = columns
    .map(c => c.text || i18n.t(c.label))
    .map(h => h.indexOf(',') !== -1 ? `"${h}"`.replaceAll('"', '""') : h)
    .join(',')

  const dataString = items.map(item => columns.map(c => {
    const value = c.value(item)
    const content = (typeof value === 'string') ? value.replaceAll('"', '""') : value
    return firstDisplayableItem(content, c.emptyValue, options.emptyValue)
  }).map(c => `"${c}"`).join(',')).join('\n')
  return `${headerString}\n${dataString}\n`
}
