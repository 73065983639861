<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="headline white--text">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      testId="deck-form-save-btn"
      @submit="saveChanges">
        <v-container fluid grid-list-md>
          <v-row dense>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
              ref="firstField"
              color="black"
              data-testid="deck-name"
              :rules="[rules.required]"
              :label="$t('name')"
              v-model="deck.name"
              maxlength="64"
              counter/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
              color="black"
              data-testid="deck-tons"
              :label="$t('onDeckTons')"
              :rules="[rules.positiveDecimal, rules.validOnDeckTons]"
              :disabled="propDeck !== undefined"
              v-model="deck.onDeck"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <MoneyTextField
                data-testid="deck-value"
                :initialValue="deck.value"
                :disabled="propDeck !== undefined"
                :label="$t('value')"
                @val-changed="deckValueEntered"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-select
                v-model="deck.contentType"
                :items="contentTypes"
                :label="$t('contentType')"
                :disabled="isEditing"
                persistent-hint>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="deck.consumptionMode"
                :disabled="deck.onDeck > 0 || isByproductContentType"
                :hint="deck.onDeck > 0 ? $t('deckAlreadyHasWeight') : ''"
                :items="consumptionModes"
                :label="$t('consumptionMode')"
                persistent-hint>
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-row>
                <v-col>
                  <v-checkbox
                  v-model="deck.isDefault"
                  data-testid="deck-default-check"
                  :label="$t('isDefault')"
                  color="secondary"
                  class="mr-3"/>
                </v-col>
                <v-col>
                  <v-checkbox
                  v-model="deck.status"
                  data-testid="deck-status-check"
                  color="secondary"
                  :label="$t('open')"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions } from 'vuex'
import { ConsumptionMode } from '@/utils/Enumerations.js'
import { DeckContentType } from '../../../utils/Enumerations'
export default {
  name: 'DeckForm',

  props: {
    propDeck: {
      type: Object,
      default: undefined
    },
    location: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    MoneyTextField: () => import('../../helper/MoneyTextField.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    consumptionModes: ConsumptionMode.names,
    contentTypes: DeckContentType.names,
    deck: {
      name: '',
      status: true,
      value: 0,
      onDeck: 0,
      locationId: -1,
      contentType: 0,
      consumptionMode: 0,
      isDefault: false
    }
  }),

  computed: {
    title () {
      if (this.propDeck) {
        return this.$t('editingDeck', { deckName: this.propDeck.name })
      } else {
        return this.$t('creatingDeck')
      }
    },

    isByproductContentType () {
      return this.deck.contentType === DeckContentType.Byproducts.name
    },

    isEditing () {
      return this.propDeck !== undefined
    }
  },

  watch: {
    isByproductContentType (value) {
      if (!value) return
      this.deck.consumptionMode = ConsumptionMode.WeightBased.name
      this.deck.isDefault = false
    }
  },

  created () {
    if (this.propDeck) {
      this.deck = JSON.parse(JSON.stringify(this.propDeck))
      this.deck.onDeck = parseFloat((this.deck.onDeck / 2000).toFixed(3))
      this.deck.status = this.deck.status === 0
      this.deck.consumptionMode = ConsumptionMode.fromInt(this.deck.consumptionMode)
      this.deck.contentType = DeckContentType.fromInt(this.deck.contentType)
    } else {
      this.deck.consumptionMode = ConsumptionMode.fromInt(this.location.consumptionMode)
      this.deck.contentType = DeckContentType.Logs.name
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  methods: {
    ...mapActions('decks', ['createDeck', 'updateDeck', 'fetchDeckWithId']),
    deckValueEntered (value) { this.deck.value = value },

    async saveChanges () {
      if (!this.validateDeck()) {
        return
      }

      const requestObj = JSON.parse(JSON.stringify(this.deck))
      requestObj.status = requestObj.status ? 0 : 1
      requestObj.locationId = this.location.locationId
      requestObj.value = parseFloat(this.deck.value)
      requestObj.onDeck = Math.round(parseFloat(this.deck.onDeck) * 2000)
      requestObj.contentType = DeckContentType.toInt(this.deck.contentType)
      requestObj.consumptionMode = ConsumptionMode.toInt(this.deck.consumptionMode)

      if (this.propDeck) {
        await this.updateDeck(requestObj)
        this.$emit('deck-mutated')
      } else {
        await this.createDeck(requestObj)
        this.$emit('deck-mutated')
      }
    },

    closeForm () {
      this.$emit('close')
    },

    validateDeck () {
      const { name } = this.deck
      if (name.trim() === '') {
        this.setSnackError(this.$t('deckNameError'))
        return false
      }

      return true
    }
  }
}
</script>
