<template>
  <v-container fluid class="mx-n3">
    <v-tabs
    v-model="currentSubModuleTab"
    slider-color="secondary"
    color="#888888"
    slider-size="4">
      <v-tab v-for="subModule in subModules" :key="subModule.name">
        <span class="black--text" style="font-size: 16px;">
          {{subModule.name}}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <Register
        v-if="currentSubModuleTab === 0"
        :registerObject="currentSubModule"
        isByproduct/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="secondary"/>
    </v-row>
    <v-row class="my-12 pa-12" v-if="showNoDataText" justify="center" align="center">
      <span class="headline black--text">
        {{ $t('noDataForSelection') }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BPAccrualsModule',

  components: {
    Register: () => import('@/components/settlements/accruals-module/register/Register.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  computed: {
    ...mapGetters('settlements', ['accrualsModule', 'currentSubModuleKey', 'loading']),

    subModules () {
      return this.accrualsModule.moduleNames.map(mn => ({
        name: mn,
        hidden: mn === this.$t('depletion')
      })).filter(mn => !mn.hidden)
    },

    currentSubModule () {
      return this.accrualsModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      return this.currentSubModule.journalEntries.length === 0
    }
  }
}
</script>
