import store from '@/store/index.js'

export async function autoPopulate (templateDetails, activityId, contractId) {
  let successes = 0
  let failures = 0
  for (let i = 0; i < templateDetails.length; i++) {
    const templateDetail = templateDetails[i]
    const detailRequestObj = {
      activityDetail: {
        rate: parseFloat(templateDetail.rate)
      },
      activityId: activityId,
      contractId: contractId,
      productId: templateDetail.productId
    }

    try {
      await store.dispatch('activity-detail/createActivityDetail', detailRequestObj)
      successes += 1
    } catch {
      failures += 1
    }
  }

  return {
    successes: successes,
    failures: failures
  }
}

export async function deleteBulkTickets (ticketIds) {
  let successes = 0
  let failures = 0
  for (let i = 0; i < ticketIds.length; i++) {
    const ticketId = ticketIds[i]
    try {
      await store.dispatch('ticket/deleteTicket', ticketId)
      successes += 1
    } catch {
      failures += 1
    }
  }

  return {
    successes: successes,
    failures: failures
  }
}
