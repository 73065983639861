<template>
  <div>
      <v-text-field
        v-model.lazy="value"
        v-money="money"
        :data-testid="this.dataTestid"
        :reverse="dense"
        :label="label"
        :disabled="disabled"
        :readonly="readonly"
        :rules="textFieldRules"
        :persistent-hint="hint !== null"
        :hint="hint"
        @blur="focusLost()"
        @focus="selectField"
        @keydown="$event.key === '-' && !allowNegative ? $event.preventDefault() : null"
        ref="moneyField"
        color="black"
      >
        <template slot="append">
          <slot name="append">
            <span v-if="expectedValue && outsideOfTreshold === VarianceIndicatorLevel.Error.value">
              <Icon
                  icon="mdi-alert"
                  iconColor="error"
                  :small="false"
                  tooltipColor="error"
                  :tooltipText="$t('rateVarianceTooltip', {percentage: 75, expectedRate: expectedValue.toFixed(2)})"/>
            </span>
            <span v-else-if="expectedValue && outsideOfTreshold === VarianceIndicatorLevel.Warning.value">
              <Icon
                  icon="mdi-alert"
                  iconColor="warning"
                  :small="false"
                  tooltipColor="warning"
                  :tooltipText="$t('rateVarianceTooltip', {percentage: 25, expectedRate: expectedValue.toFixed(2)})"/>
            </span>
          </slot>
        </template>
      </v-text-field>
    <span v-if="isLowerThanMinimumValue" class="error--text font-weight-bold">
      {{minimumValueConfig.error}}
    </span>
  </div>
</template>

<script>
import fieldRules from '@/utils/rules'
import { VMoney } from 'v-money'
import { VarianceIndicatorLevel } from '@/utils/Enumerations.js'

export default {
  name: 'MoneyTextField',

  props: {
    initialValue: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: 'Amount'
    },
    focus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dataTestid: {
      type: String,
      default: ''
    },
    allowNegative: {
      type: Boolean,
      default: false,
      required: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    shouldHighlight: {
      type: Boolean,
      default: true
    },
    itemId: {
      required: false
    },
    minimumValueConfig: {
      type: Object,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    expectedValue: {
      required: false,
      default: undefined
    },
    extraRules: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  created () {
    this.value = this.initialValue.toFixed(2)
  },

  watch: {
    value (val) {
      // Remove commas and dollar sign.
      const formatted = parseFloat(val.replace(/,/g, '').slice(1))
      if (this.itemId) {
        this.$emit('val-entered', {
          amount: formatted,
          itemId: this.itemId
        })
      }
      this.$emit('val-changed', formatted)
    },

    focus (val) {
      this.$refs.moneyField.focus()
    }
  },

  directives: { money: VMoney },

  data: () => ({
    value: '',
    money: {
      decimal: '.',
      thousands: ',',
      prefix: '$',
      suffix: '',
      precision: 2,
      masked: true
    },
    rules: fieldRules.rules,
    VarianceIndicatorLevel
  }),

  computed: {
    isLowerThanMinimumValue () {
      if (!this.minimumValueConfig) return false
      return parseFloat(this.value.replace(/,/g, '').slice(1)) < this.minimumValueConfig.min
    },

    outsideOfTreshold () {
      const currentValue = parseFloat(this.value.replace(/,/g, '').slice(1))
      if (currentValue === 0) return 0
      const warningRange = this.expectedValue * 0.25
      const errorRange = this.expectedValue * 0.75
      const delta = Math.abs(currentValue - this.expectedValue)
      if (delta > errorRange) return VarianceIndicatorLevel.Error.value
      if (delta > warningRange) return VarianceIndicatorLevel.Warning.value
      return VarianceIndicatorLevel.None.value
    },

    textFieldRules () {
      var result = [this.rules.validMoney]
      this.extraRules.forEach(r => result.push(this.rules[r]))
      return result
    }
  },

  methods: {
    selectField (el) {
      if (this.shouldHighlight) {
        setTimeout(() => {
          el.target.select()
        }, 25)
      }
    },

    focusLost () {
      // Remove commas and dollar sign.
      const formatted = parseFloat(this.value.replace(/,/g, '').slice(1))
      if (this.itemId) {
        this.$emit('blur-entered', {
          amount: formatted,
          itemId: this.itemId
        })
      }
    }
  }
}
</script>
