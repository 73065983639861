<template>
  <v-autocomplete
    :data-testid="dataTestid"
    :menu-props="{ contentClass: `${dataTestid}-menu` }"
    color="black"
    item-color="secondary"
    v-model="chosenUser"
    :label="label"
    @change="userChosen"
    :click:clear="userChosen"
    :loading="loading"
    :items="items"
    :rules="rules"
    item-text="name"
    clearable
    return-object
    :multiple="multiple"
    :chips="chips"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    :disabled="disabled"
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon @click="refreshUsers" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { localeCompareAlphanumeric } from '@/utils/base'

export default {
  name: 'UserAutocomplete',

  props: {
    propUserId: { type: Number },
    propUserIds: { type: Array, default: () => [] },
    propTeam: { type: Object, default: undefined },
    dataTestid: { type: String },
    label: { type: String, default: 'User' },
    fetchUsers: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    deletableChips: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    allUserIds: { type: Array, required: false, default: undefined },
    disabled: { type: Boolean, default: false }
  },

  watch: {
    propTeam (team) {
      if (team?.users && this.multiple) {
        this.chosenUser = team.users
      } else if (!team && this.multiple) {
        this.chosenUser = []
      }
      this.$emit('user-chosen', this.chosenUser)
      this.setItems()
    },

    allUserIds () {
      this.setItems()
    }
  },

  data: () => ({
    chosenUser: undefined,
    items: [],
    loading: false
  }),

  async created () {
    if (this.fetchUsers) await this.getAllUsers()
    this.setItems()
    if (this.propUserId) {
      this.chosenUser = this.allUsers.find(u => u.applicationUserId === this.propUserId)
    } else if (this.propUserIds) {
      this.chosenUser = this.propUserIds.map(uid => this.allUsers.find(u => u.applicationUserId === uid))
    } else if (this.propTeam) {
      this.chosenUser = this.propTeam.users
    }
    this.sortChosenUsers()
  },

  computed: {
    ...mapGetters('user', ['allUsers'])
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),

    async refreshUsers () {
      this.loading = true
      await this.getAllUsers()
      this.setItems()
      this.loading = false
    },

    userChosen () {
      this.sortChosenUsers()
      this.$emit('user-chosen', this.chosenUser)
    },

    sortChosenUsers () {
      if (Array.isArray(this.chosenUser)) {
        this.chosenUser = this.chosenUser.sort((a, b) => localeCompareAlphanumeric(a.name, b.name))
      }
    },

    setItems () {
      const unsorted = this.allUserIds
        ? this.allUserIds.map(id => this.allUsers.find(u => u.applicationUserId === id))
        : this.propTeam?.users
          ? this.propTeam.users
          : this.allUsers

      this.items = unsorted.sort((a, b) => localeCompareAlphanumeric(a.name, b.name))

      if (this.chosenUser?.applicationUserId && !this.items.some(i => this.chosenUser.applicationUserId === i.applicationUserId)) {
        this.chosenUser = undefined
        this.userChosen()
      }
    }
  }
}
</script>
