<template>
  <CsvImport v-bind="{
    parse,
    preview,
    upload,
    exampleData,
    entityName,
    rowName,
    disabled
  }">
    <template #preview-item.activityStatus="{ value }">
      <Icon v-if="value === enums.ActivityStatus.Active.value"
        icon="mdi-checkbox-marked-circle"
        iconColor="success"
        tooltipColor="success"
        :tooltipText="enums.ActivityStatus.Active.name"
      />
      <Icon v-else
        icon="mdi-cancel"
        iconColor="black"
        tooltipColor="black"
        tooltipText="enums.ActivityStatus.Inactive.name"
      />
    </template>
    <template #preview-item.rate="{ item }">
      <span>{{ formatMoney(item.baseCost.rate) }}</span>
    </template>
    <template #preview-item.businessEntityName="{ item }">
      <span>{{ businessEntities.find(entity => entity.businessEntityId === item.businessEntityId)?.name }}</span>
    </template>
  </CsvImport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ActivityModifier, ActivityStatus, TemplateAccountingCategory, TemplateCostType, PayOn, PayBy, UserClaims } from '@/utils/Enumerations.js'
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import ActivityHeaders from '@/headers/Activity.js'
import { formatMoney } from '../../../utils/NumericMutations'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'ActivityTemplateImports',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    CsvImport: () => import('./CsvImport.vue')
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    parse () {
      return {
        schema: async () => {
          const businessEntities = await this.fetchAllBusinessEntities()
          const validateStringLength = maxLength =>
            s => !s
              ? this.$t('stringCannotBeEmpty')
              : s.length > maxLength
                ? this.$t('stringCannotBeLongerThanNCharacters', { n: maxLength })
                : true

          return {
            name: CsvColumn.build(() => ({
              name: 'name',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(64)
              })
            })),
            glCode: CsvColumn.from({
              name: 'glCode',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(32)
              })
            }),
            glOffset: CsvColumn.from({
              name: 'glOffset',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(32)
              })
            }),
            businessEntityId: CsvColumn.build(() => ({
              name: 'entity',
              type: CsvTypeTransformation.from({
                transform: e => businessEntities.find(be => be.name === e)?.businessEntityId,
                validate: id => id !== undefined ? true : this.$t('invalidBusinessEntityName')
              })
            })),
            costType: CsvColumn.build(types => ({
              name: 'costType',
              type: types.enumNameToInt(TemplateCostType, this.$t('costType'))
            })),
            category: CsvColumn.build(types => ({
              name: 'category',
              type: types.enumNameToInt(TemplateAccountingCategory, this.$t('category'))
            })),
            modifier: CsvColumn.build(types => ({
              name: 'modifier',
              type: types.enumNameToInt(ActivityModifier, this.$t('modifier'))
            })),
            activityStatus: CsvColumn.build(types => ({
              name: 'status',
              type: types.enumNameToInt(ActivityStatus, this.$t('status'))
            })),
            baseCost: {
              rate: CsvColumn.build(types => ({
                name: 'rate',
                type: types.float.validateWith(f => f < 0 ? this.$t('negativeRateNotAllowed') : true)
              })),
              payBy: CsvColumn.build(types => ({
                name: 'payBy',
                type: types.enumNameToInt(PayBy, this.$t('payBy'))
              })),
              payOn: CsvColumn.build(types => ({
                name: 'payOn',
                type: types.enumNameToInt(PayOn, this.$t('payOn'))
              }))
            }
          }
        }
      }
    },

    preview: () => ({
      headers: ActivityHeaders.activityTemplateBulkImportPreviewHeaders()
    }),

    upload () {
      return { action: this.createActivityTemplate }
    },

    enums: () => ({ ActivityStatus }),

    rowName () {
      return template => template.name
    },

    entityName () {
      return this.$t('activityTemplate')
    },

    exampleData () {
      return 'data:text/csv;charset=utf-8,name,entity,category,costType,glCode,glOffset,rate,payOn,payBy,modifier,status\nDefault 1,"Acme Lumber Co.",Accounts Payable,Production Cost,GL8496,GL-OFFSET,60.75,Gross,Weight,None,Active\nDefault 2,"Acme Lumber Co.",Accruals,Non-Inventory Cost,GL8499,GL-OFFSET,20.99,Gross,Weight,None,Inactive\nDefault 3,"Acme Lumber Co.",Accounts Receivable,Unspecified,GL8502,GL-OFFSET,23.99,Gross,Weight,Distance Traveled,Active\nDefault 4,"Acme Lumber Co.",Accruals,Stumpage Cost,GL8498,GL-OFFSET,19.99,Gross,Weight,Distance Traveled,Inactive'
    },

    disabled () {
      return !userAssignedClaim(UserClaims.TemplateManager)
    }
  },

  methods: {
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('activity-templates', ['createActivityTemplate']),
    formatMoney,

    async csvColumns () {
      const businessEntities = await this.fetchAllBusinessEntities()
      const validateStringLength = maxLength =>
        s => !s
          ? this.$t('stringCannotBeEmpty')
          : s.length > maxLength
            ? this.$t('stringCannotBeLongerThanNCharacters', { n: maxLength })
            : true

      return {
        name: CsvColumn.build(() => ({
          name: 'name',
          type: CsvTypeTransformation.from({
            validate: validateStringLength(64)
          })
        })),
        glCode: CsvColumn.from({
          name: 'glCode',
          type: CsvTypeTransformation.from({
            validate: validateStringLength(32)
          })
        }),
        glOffset: CsvColumn.from({
          name: 'glOffset',
          type: CsvTypeTransformation.from({
            validate: validateStringLength(32)
          })
        }),
        businessEntityId: CsvColumn.build(() => ({
          name: 'entity',
          type: CsvTypeTransformation.from({
            transform: e => businessEntities.find(be => be.name === e)?.businessEntityId,
            validate: id => id !== undefined ? true : this.$t('invalidBusinessEntityName')
          })
        })),
        costType: CsvColumn.build(types => ({
          name: 'costType',
          type: types.enumNameToInt(TemplateCostType, this.$t('costType'))
        })),
        category: CsvColumn.build(types => ({
          name: 'category',
          type: types.enumNameToInt(TemplateAccountingCategory, this.$t('category'))
        })),
        modifier: CsvColumn.build(types => ({
          name: 'modifier',
          type: types.enumNameToInt(ActivityModifier, this.$t('modifier'))
        })),
        activityStatus: CsvColumn.build(types => ({
          name: 'status',
          type: types.enumNameToInt(ActivityStatus, this.$t('status'))
        })),
        baseCost: {
          rate: CsvColumn.build(types => ({
            name: 'rate',
            type: types.float.validateWith(f => f < 0 ? this.$t('negativeRateNotAllowed') : true)
          })),
          payBy: CsvColumn.build(types => ({
            name: 'payBy',
            type: types.enumNameToInt(PayBy, this.$t('payBy'))
          })),
          payOn: CsvColumn.build(types => ({
            name: 'payOn',
            type: types.enumNameToInt(PayOn, this.$t('payOn'))
          }))
        }
      }
    }
  }
}
</script>
