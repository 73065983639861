import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async updateRecovery (_, requestObject) {
    const { recovery, advanceId } = requestObject
    const path = `advances/${advanceId}/recoveries/${recovery.recoveryId}`
    const response = await ApiServer.put(path, recovery)
    return response.data
  },

  async deleteRecovery (_, requestObject) {
    const { recoveryId, advanceId } = requestObject
    const path = `advances/${advanceId}/recoveries/${recoveryId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async fetchAdvanceRecoveries (context, advanceId) {
    try {
      context.commit('setLoading', true)
      const path = `v2/advances/${advanceId}/recoveries`
      const response = await ApiServer.get(path)
      context.commit('setAdvanceRecoveries', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  }
}
