<template>
  <v-row dense>
    <v-col cols="auto" v-if="!isTransfer">
      <v-icon :color="color" small>mdi-crosshairs-gps</v-icon>
      <span>
        {{contractKeys.tractName}}
      </span>
    </v-col>
    <v-col cols="auto" v-if="isTransfer">
      <v-icon :color="color" small>mdi-account-arrow-right</v-icon>
      <span>
        {{ contractKeys.fromAccountName }}
      </span>
    </v-col>
    <v-col cols="auto" v-if="isTransfer">
      >
    </v-col>
    <v-col cols="auto">
      <v-icon :color="color" small>mdi-domain</v-icon>
      <span>
        {{ isTransfer ? contractKeys.destinationAccountName : contractKeys.destinationName}}
      </span>
    </v-col>
    <v-col cols="auto" v-if="!isTransfer">
      <v-icon :color="color" small>mdi-map-marker-circle</v-icon>
      <span>
        {{contractKeys.settingName}}
      </span>
    </v-col>
    <v-col cols="auto">
      <v-icon :color="color" small>mdi-account</v-icon>
      <span>
        {{contractKeys.accountName}}
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TRPanelHeader',

  props: {
    contractKeys: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    isTransfer: {
      type: Boolean,
      default: false
    }
  }
}
</script>
