import i18n from '@/i18n'

export default {
  deviceRegistrationHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('app'),
        value: 'application',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('code'),
        value: 'accountCode'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'accountName'
      },
      {
        sortable: true,
        text: i18n.t('deviceId'),
        value: 'deviceID'
      },
      {
        sortable: true,
        text: i18n.t('version'),
        value: 'lastReportedVersion'
      },
      {
        sortable: true,
        text: i18n.t('registeredOn'),
        value: 'registeredOn'
      },
      {
        sortable: true,
        text: i18n.t('expiresOn'),
        value: 'expiresOn'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
