import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchContacts (context, accountId) {
    const path = `accounts/${accountId}/contacts`
    const response = await ApiServer.get(path)
    context.commit('setContacts', response.data)
    return response.data
  },
  async deleteContact (context, identifiers) {
    const path = `accounts/${identifiers.accountId}/contacts/${identifiers.contactId}`
    const response = await ApiServer.delete(path)
    context.commit('removeContact', identifiers.contactId)
    return response.data
  },
  async createContact (context, contactRequestObj) {
    const path = `accounts/${contactRequestObj.identifiers.accountId}/contacts/`
    const response = await ApiServer.post(path, contactRequestObj.contact)
    context.commit('addContact', response.data)
    return response.data
  },
  async updateContact (context, contactRequestObj) {
    const accountId = contactRequestObj.identifiers.accountId
    const contactId = contactRequestObj.identifiers.contactId
    const path = `accounts/${accountId}/contacts/${contactId}`
    const response = await ApiServer.put(path, contactRequestObj.contact)
    context.commit('updateContact', contactRequestObj.contact)
    return response.data
  }
}
