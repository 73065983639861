import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getFooter } from './GenericSettlementsFunctions.js'
import store from '@/store/index.js'
import moment from 'moment'

PDFMake.vfs = pdfFonts.pdfMake.vfs

export function getAccountQrCodeData (account, companyInfo) {
  if (!account.code) throw new Error(`Account ${account.name} is missing code`)
  const payload = {
    t: 'DR',
    v: 1,
    ac: account.code,
    tc: companyInfo.code
  }
  return JSON.stringify(payload)
}

export async function generateAccountIdSheetPDF (account, qrCode) {
  const companyInfo = store.getters['user/companyInfo']

  const content = [
    { text: `Account Identification Sheet for ${companyInfo.name}`, style: 'title' },
    { text: account.name, style: 'subtitle', margin: [0, 5] },
    { text: `Generated on ${moment().toString('YYYY MM dd hh:mm:ss')}`, fontSize: 12, alignment: 'center', margin: [0, 5] },
    { image: qrCode, alignment: 'center' },
    { text: 'Scan code in transporter to register a new user under this account', italics: true, fontSize: 12, alignment: 'center', margin: [0, 10] }
  ]

  const documentDefinition = {
    footer: getFooter(1, 1),

    content,

    defaultStyle: {
      margin: [0, 20]
    },

    styles: {
      title: {
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      subtitle: {
        fontSize: 14,
        bold: false,
        alignment: 'center'
      }
    }
  }
  await PDFMake.createPdf(documentDefinition).download(`account-idsheet-${encodeURIComponent(account.code.trim())}`)
}
