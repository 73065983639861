<template>
  <v-card v-if="receivable">
    <v-card-title class="secondary">
      <span class="white--text headline">{{titleText}}</span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      dataTestId="receivable-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <ReceivableDetailContent :propReceivable="receivable"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ReceivableDetail',

  props: {
    propReceivable: Object,
    receivableId: Number
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    ReceivableDetailContent: () => import('./ReceivableDetailContent.vue')
  },

  computed: {
    titleText () {
      return `${this.receivable.accountName}`
    }
  },

  data: () => ({
    receivable: undefined
  }),

  async created () {
    if (this.receivableId) {
      const response = await this.getReceivable(this.receivableId)
      this.receivable = response
    } else {
      this.receivable = this.propReceivable
    }
  },

  methods: {
    ...mapActions('receivable', ['getReceivable'])
  }
}
</script>
