export const createResourceUrl = (uri, container, name = undefined) => {
  const urlParams = uri.slice(Math.max(uri.indexOf('?'), 0))
  const url = new URL(`${container}/${name}${urlParams}`, uri)
  return url
}

export const sasNeedsRefresh = uri => {
  if (!uri) return true
  const url = new URL(uri)
  const exp = url.searchParams.get('se')
  const minDelta = 30 * 1000
  return (exp)
    ? (new Date(exp) - Date.now() < minDelta)
    : true
}
