import i18n from '@/i18n'

export default {
  tractTypeHeaders () {
    return [
      {
        align: 'center',
        sortable: false,
        text: '',
        value: 'status',
        width: '50px'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'category',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
