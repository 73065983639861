<template>
  <v-card>
    <v-card-title class="headline white--text secondary">
      <span>
        {{ $t('recoveryFor', { accountName: advance.payeeName, activityName: advance.activityName }) }}
      </span>
      <v-spacer/>
      <Icon
      icon="mdi-plus"
      icon-color="white"
      dataTestId="recovery-add-button"
      :small="false"
      :large="true"
      :tooltipText="$t('manualRecovery')"
      :disabled="!userAssignedClaim(UserClaims.ContractManager)"
      @icon-clicked="mutateRecovery()"/>
      <Icon
      icon="mdi-refresh"
      iconColor="white"
      dataTestId="recovery-refresh-button"
      :large="true"
      :small="false"
      :tooltipText="$t('refresh')"
      @icon-clicked="refreshRecoveries"/>
      <Icon
      icon="mdi-close"
      iconColor="white"
      dataTestId="recovery-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"/>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-dialog v-model="dialog" width="400">
        <RecoveryForm
        :advanceId="advance.advanceId"
        :propRecovery="focusedRecovery"
        @recovery-mutated="close(true)"
        v-if="mutatingRecovery"
        @close="close"
        />
        <ConfirmDelete
        v-if="deleting"
        :title="$t('recovery')"
        @cancel-delete="close"
        @delete="deleteItem">
        </ConfirmDelete>
      </v-dialog>
      <v-layout>
        <v-spacer/>
      </v-layout>
      <RecoveryPanels
        @edit-recovery="mutateRecovery"
        @delete-recovery="confirmDeleteRecovery"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '@/utils/Enumerations'
import { userAssignedClaim } from '@/utils/ClaimUtility'
export default {
  name: 'Recoveries',

  props: {
    advance: Object
  },

  components: {
    RecoveryPanels: () => import('../recoveries/RecoveriesPanels.vue'),
    RecoveryForm: () => import('./RecoveryForm.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    Icon: () => import('../../helper/Icon.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    focusedRecovery: undefined,
    dialog: false,
    deleting: false,
    mutatingRecovery: false,
    UserClaims
  }),

  created () {
    this.refreshRecoveries()
  },

  methods: {
    ...mapActions('recovery', ['deleteRecovery', 'fetchAdvanceRecoveries']),
    ...mapActions('advances', ['postRecovery']),
    userAssignedClaim,
    recoveryEntered (amount) {
      this.recoveryAmount = amount
    },

    mutateRecovery (recovery) {
      this.close()
      this.focusedRecovery = recovery
      this.mutatingRecovery = true
      this.dialog = true
    },

    close (refresh) {
      if (refresh) {
        this.refreshRecoveries()
        this.$emit('recovery-changed')
      }

      this.dialog = false
      this.deleting = false
      this.mutatingRecovery = false
      this.focusedRecovery = undefined
    },

    async refreshRecoveries () {
      await this.fetchAdvanceRecoveries(this.advance.advanceId)
    },

    async deleteItem () {
      const requestObj = {
        advanceId: this.advance.advanceId,
        recoveryId: this.focusedRecovery.recoveryId
      }
      await this.deleteRecovery(requestObj)
      this.close(true)
    },

    confirmDeleteRecovery (recovery) {
      this.focusedRecovery = recovery
      this.deleting = true
      this.dialog = true
    },

    async applyRecovery () {
      const recoveryRequestObject = {
        recovery: {
          amount: this.recoveryAmount
        },
        advanceId: this.advance.advanceId
      }

      await this.postRecovery(recoveryRequestObject)
      this.$emit('close')
    }
  }
}
</script>
