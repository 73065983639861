import { exportCSVFile } from '@/utils/CSVUtility.js'
import messages from '@/lang'
import i18n from '@/i18n'
import { exportCSVStringAsFile, generateCsvString } from '../utils/CSVUtility'

export class BarChart {
  constructor (data, key, value, type) {
    this.data = data
    this.key = key
    this.type = type
    this.xValues = data.map(item => item[key])
    this.yValues = data.map(item => item[value])
  }
}

export class BarChartLoadsByProduct {
  constructor (data, key, type, isTons = false, includeWeightData = true) {
    this.data = data
    this.key = key
    this.type = type
    this.isTons = isTons
    this.xValues = data.map(item => item[key])
    this.yValuesTons = data.some(d => d.tons !== undefined) ? data.map(item => item.tons) : undefined
    this.yValuesLoads = data.map(item => item.loadCount)
    this.elementId = `${type}`
    this.includeWeightData = includeWeightData
  }

  yValues () {
    return this.isTons ? this.yValuesTons : this.yValuesLoads
  }

  toggleIsTons () {
    this.isTons = !this.isTons
  }

  generateCSV (destinationName = '') {
    const destFileNamePart = destinationName !== '' ? `-${destinationName.replaceAll(' ', '')}` : ''
    const title = `Load-Info-By-${i18n.t(this.type).replace(' ', '-')}${destFileNamePart}`

    const weightData = this.yValuesTons !== undefined

    const csvColumns = [
      (this.key === 'productName') ? { label: 'product', value: p => p.productName } : undefined,
      { label: 'loads', value: p => p.loadCount },
      (weightData && this.includeWeightData) ? { label: 'pounds', value: p => Math.round(p.tons * 2000) } : undefined,
      (weightData && this.includeWeightData) ? { label: 'tons', value: p => p.tons } : undefined
    ].filter(c => c !== undefined)

    exportCSVStringAsFile(generateCsvString(this.data, csvColumns), title)
  }
}

export class BarChartTonsByCertification {
  constructor (data, key, type) {
    this.data = data
    this.key = key
    this.type = type
    this.xValues = data.map(item => item[key] ? item[key] : messages[i18n.locale].uncertified)
    this.yValuesTons = data.map(item => item.tons)
    this.elementId = `${type}`
  }

  yValues () {
    return this.yValuesTons
  }

  generateCSV () {
    const headers = ['Name', 'Code', 'Tons']
    const title = 'tons_by_certification'

    const csvObjects = this.data.map(item => ({
      name: item.certificationName ?? 'Uncertified',
      code: item.certificationCode ?? '',
      tons: item.tons
    }))

    exportCSVFile(headers, csvObjects, title)
  }
}
