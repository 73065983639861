import ApiServer from '@/utils/api/ApiServer.js'
import { localeCompareAlphanumeric } from '@/utils/base'

export default {
  async fetchTeams (context) {
    context.commit('setTeamsLoading', true)
    const response = await ApiServer.get('teams')
    var sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    sortedData.forEach(team => {
      team.users.sort((a, b) => localeCompareAlphanumeric(a.name, b.name))
    })
    context.commit('setTeams', sortedData)
    context.commit('setTeamsLoading', false)
    return sortedData
  },

  async fetchTeamMemberships (context) {
    context.commit('setTeamMembershipsLoading', true)
    const response = await ApiServer.get('teams')
    var sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    context.commit('setTeamMemberships', sortedData)
    context.commit('setTeamMembershipsLoading', false)
    return sortedData
  },

  async createTeam (_, team) {
    const response = await ApiServer.post('teams', team)
    return response.data
  },

  async deleteTeam (_, teamId) {
    const response = await ApiServer.delete(`teams/${teamId}`)
    return response.data
  },

  async updateTeam (_, teamRequest) {
    const response = await ApiServer.put(`teams/${teamRequest.teamId}`, teamRequest)
    return response.data
  }
}
