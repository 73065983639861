import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchProductById (_, { productId }) {
    const { data: product } = await ApiServer.get(`products/${productId}`)
    return product
  },
  async fetchProducts (context, requestObject) {
    const { contractId, includeByProducts, includeProduction } = requestObject
    const path = `products?includeByProducts=${includeByProducts ?? false}&includeProduction=${includeProduction ?? true}`
    const headers = {}
    if (contractId) {
      headers.contractId = contractId
    }

    try {
      context.commit('setLoading', true)
      const response = await ApiServer.get(path, { headers })
      context.commit('setProducts', response.data)
      return response.data.sort((a, b) => a.name.localeCompare(b.name))
    } finally {
      context.commit('setLoading', false)
    }
  },
  async deleteProduct (_, productId) {
    const response = await ApiServer.delete(`products/${productId}`)
    return response.data
  },
  async createProduct (context, product) {
    const response = await ApiServer.post('products', product)
    context.commit('addProduct', product)
    return response.data
  },
  async updateProduct (context, product) {
    const response = await ApiServer.put('products/' + product.productId, product)
    context.commit('updateProduct', product)
    return response.data
  }
}
