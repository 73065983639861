<template>
  <CsvImport
   v-bind="{
    parse,
    preview,
    upload,
    exampleData,
    entityName,
    rowName,
    disabled
  }"
  @parse-result="setLandownerMap">
    <template #preview-item.typeName="{ item }">
      <span>{{ item.tractType.name }}</span>
    </template>
    <template #preview-item.logger="{ item }">
      <span>{{ accountDictionary?.[`id-${item.loggerAccountId}`]?.name }}</span>
    </template>
    <template #preview-item.landOwner="{ item }">
      <span>
        <span v-if="landownerOwnersDictionary.get(item.name)?.length === 1">{{ accountDictionary?.[`id-${landownerOwnersDictionary.get(item.name)[0].accountId}`]?.name }}</span>
        <span v-else-if="landownerOwnersDictionary.get(item.name)?.length === 0">{{ $t('notAvailable') }}</span>
        <span v-else>
          <v-tooltip bottom color="black">
            <template #activator="{on}">
              <v-icon v-on="on" color="black">
                mdi-dots-horizontal
              </v-icon>
            </template>
            <span class="subtitle-1 white--text">
              {{ getOwnershipString(item) }}
            </span>
          </v-tooltip>
        </span>
      </span>
    </template>
    <template #preview-item.businessEntity="{ item }">
      <span v-if="item.tractType.category !== enums.TractTypeCategory.Delivered.value">
        {{ businessEntityDictionary?.[`id-${item.businessEntityId}`]?.name }}
      </span>
      <span v-else>{{ $t('notAvailable') }}</span>
    </template>
    <template #preview-item.coordinates="{ item }">
      <span>{{ `${item.spot.latitude}, ${item.spot.longitude}` }}</span>
    </template>
    <template #preview-item.location="{ item }">
      <span>{{ `${item.spot.countrySecondarySubdivision}, ${item.spot.countrySubdivision}` }}</span>
    </template>
  </CsvImport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import counties from '@/utils/state-counties.json'
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import { TractTypeCategory, UserClaims } from '../../../utils/Enumerations.js'
import { tractBulkImportPreviewHeaders } from '../../../headers/Tract'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { formatPercentage } from '../../../utils/NumericMutations'
const MAX_LANDOWNERS_ON_IMPORT = 5
export default {
  name: 'TractImports',

  components: {
    CsvImport: () => import('./CsvImport.vue')
  },

  data: () => ({
    accountDictionary: [],
    businessEntityDictionary: [],
    tractTypeDictionary: [],
    landownerOwnersDictionary: new Map()
  }),

  computed: {
    ...mapGetters('tract-type', ['allTractTypes']),
    ...mapGetters('account', ['allAccounts']),
    ...mapGetters('user', ['businessEntities']),

    parse () {
      return {
        schema: async () => {
          const validateStringLength = maxLength =>
            s => !s
              ? this.$t('stringCannotBeEmpty')
              : s.length > maxLength
                ? this.$t('stringCannotBeLongerThanNCharacters', { n: maxLength })
                : true

          const validateStringLengthOrEmpty = maxLength =>
            s => s !== '' && s.length > maxLength
              ? this.$t('stringCannotBeLongerThanNCharacters', { n: maxLength })
              : true

          await this.loadInitialData()
          return {
            name: CsvColumn.from({
              name: 'name',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(64)
              })
            }),
            code: CsvColumn.from({
              name: 'code',
              type: CsvTypeTransformation.from({
                validate: validateStringLengthOrEmpty(10)
              })
            }),
            tractType: CsvColumn.from({
              name: 'typeName',
              type: CsvTypeTransformation.from({
                transform: typeName => this.tractTypeDictionary[`name-${typeName.toLowerCase()}`],
                validate: tt => tt === undefined ? this.$t('tractTypeBlank') : true
              })
            }),
            loggerAccountId: CsvColumn.from({
              name: 'logger',
              type: CsvTypeTransformation.from({
                transform: accountName => this.accountDictionary['name-' + accountName.toLowerCase()]?.accountId,
                validate: (id, arg) => (arg && id === undefined) ? this.$t('tractLoggerInvalid') : true
              })
            }),
            businessEntityId: CsvColumn.from({
              name: 'businessEntity',
              type: CsvTypeTransformation.from({
                transform: entity => this.businessEntityDictionary['name-' + entity.toLowerCase()]?.businessEntityId,
                validate: (id, entity) => entity !== '' && id === undefined ? this.$t('tractBusinessEntityInvalid') : true
              })
            }),
            cost: CsvColumn.build(types => ({
              name: 'cost',
              type: types.float
            })),
            acres: CsvColumn.build(types => ({
              name: 'acres',
              type: types.integer
            })),
            mapAndParcelNumber: CsvColumn.from({
              name: 'mapAndParcelNumber',
              type: CsvTypeTransformation.from({
                validate: validateStringLengthOrEmpty(32)
              })
            }),
            spot: {
              locationArg0: CsvColumn.build(types => ({ name: 'countrySubdivision/latitude', type: types.string })),
              locationArg1: CsvColumn.build(types => ({ name: 'countrySecondarySubdivision/longitude', type: types.string }))
            },
            landowners: Array.apply(null, Array(MAX_LANDOWNERS_ON_IMPORT)).map((_, index) => {
              const accountColumnName = `landowner${index + 1}`
              const ownershipColumnName = `landowner${index + 1}Ownership`
              return {
                accountId: CsvColumn.from({
                  name: accountColumnName,
                  required: false,
                  type: CsvTypeTransformation.from({
                    transform: accountName => this.accountDictionary['name-' + accountName.toLowerCase()]?.accountId,
                    validate: (id, arg) => (arg && id === undefined) ? this.$t('tractLandownerInvalid') : true
                  })
                }),
                accountName: CsvColumn.from({
                  name: accountColumnName,
                  required: false
                }),
                ownership: CsvColumn.from({
                  name: ownershipColumnName,
                  required: false,
                  type: CsvTypeTransformation.from({
                    transform: ownership => Number(Number(ownership).toFixed(2)),
                    validate: ownership => ownership > 100 ? this.$t('invalidLandownershipAmount') : true
                  })
                })
              }
            })
          }
        },

        validate: async tract => {
          const errors = []
          if (tract.tractType.category === TractTypeCategory.Stumpage.value && tract.businessEntityId === undefined) {
            errors.push(this.$t('stumpageTractBusinessEntityInvalid'))
          } else if (tract.tractType.category === TractTypeCategory.Delivered.value && tract.businessEntityId !== undefined) {
            errors.push(this.$t('deliveredTractBusinessEntityInvalid'))
          }

          tract.tractTypeId = tract.tractType.tractTypeId

          const latitude = parseFloat(tract.spot.locationArg0)
          const longitude = parseFloat(tract.spot.locationArg1)

          if (Number.isNaN(latitude) && Number.isNaN(longitude)) {
            const state = tract.spot.locationArg0.toUpperCase()
            const county = tract.spot.locationArg1

            const stateCounties = counties[state]
            const countyCoords = stateCounties?.find(c => c.N === county)

            if (stateCounties !== undefined && countyCoords !== undefined) {
              const [latitude, longitude] = countyCoords.C.split(',')
              tract.spot = {
                latitude,
                longitude,
                countrySubdivision: state,
                countrySecondarySubdivision: county
              }
            } else {
              if (stateCounties === undefined) errors.push(this.$t('invalidState', { state }))
              if (countyCoords === undefined) errors.push(this.$t('couldNotFindCounty', { county }))
            }
          } else if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
            const { countrySubdivision, countrySecondarySubdivision } = await this.getLocationWithCoordinate({ latitude, longitude })
            const newSpot = (countrySubdivision !== undefined && countrySecondarySubdivision !== undefined)
              ? { latitude, longitude, countrySubdivision, countrySecondarySubdivision }
              : undefined

            tract.spot = newSpot
            if (!tract.spot) {
              errors.push(this.$t('tractCsvInvalidCoordsArgument', { latitude, longitude }))
            }
          } else {
            errors.push(this.$t('tractCsvInvalidLocationArgument'))
          }

          const landowners = this.getLandowners(tract)
          const totalOwnership = landowners.reduce((allocation, owner) => allocation + owner.ownership, 0)
          if (totalOwnership !== 100) errors.push(this.$t('landownershipIsNotAllocated', { actual: formatPercentage(totalOwnership) }))

          return errors
        }
      }
    },

    preview () {
      return {
        headers: tractBulkImportPreviewHeaders()
      }
    },

    upload () { return { action: this.createTractWithLandowners } },

    enums: () => ({ TractTypeCategory }),

    entityName () {
      return this.$t('tract')
    },
    rowName () {
      return tract => tract.name
    },
    exampleData () {
      return 'data:text/csv;charset=utf-8,name,code,typeName,logger,businessEntity,cost,acres,mapAndParcelNumber,countrySubdivision/latitude,countrySecondarySubdivision/longitude,landowner1,landowner1Ownership,landowner2,landowner2Ownership,landowner3,landowner3Ownership,landowner4,landowner4Ownership,landowner5,landowner5Ownership\nDefault 1,TR1,Gatewood - GWCX,Tuberville,,1234.56,456,,NC,Forsyth,Treeworx,100,,,,,,,,\nDefault 2,TR2,Gatewood - GWCX,Tuberville,,1234.56,456,354-3333-333,NC,Forsyth,Treeworx,60,Acme,40,,,,,,\nDefault 3,TR3,Gatewood - GWCX,Panola,,1234.56,456,354-3333-334,NC,Forsyth,,,,,,,,,,\nDefault 4,TR4,Gatewood - GWCX,Yancey,,1234.56,456,354-3333-335,NC,Forsyth,Acme,100,,,,,,,,\nDefault 5,TR5,Gatewood - GWCX,Congaree Forestry Services,,1234.56,456,354-3333-336,NC,Forsyth,Woody Timber Co.,75,Acme,20,Treeworx,5,,,,\nDefault 6,TR6,Gatewood - GWCX,Acme,,1234.56,456,354-3333-337,NC,Forsyth,Acme,20,Treeworx,20,Woody Timber Co.,20,Hawthorne Lumber,20,Owner Account,20\nDefault 7,TR7,Timber Tracts - STMP,HW Plainview,"Acme Lumber Co.",1234.56,456,354-3333-338,NC,Forsyth,,,,,,,,,,\nDefault 8,TR8,Timber Tracts - STMP,Spud Hauling,"Acme Lumber Co.",1234.56,456,354-3333-339,NC,Forsyth,,,,,,,,,,\nDefault 9,TR9,Timber Tracts - STMP,Hawthorne Lumber,"Acme Lumber Co.",1234.56,456,354-3333-340,40.29672,-81.930112,,,,,,,,,,\nDefault 10,TR10,Timber Tracts - STMP,Main Location,New Entity,1234.56,456,354-3333-341,40.29672,-81.930112,,,,,,,,,,\nDefault 11,TR11,Timber Tracts - STMP,Main Location,New Entity,1234.56,456,354-3333-342,36.092273,-80.24327,,,,,,,,,,\nDefault 12,TR12,Fee Logging - FLCV,Treeworx,,1234.56,456,,36.092273,-80.24327,,,,,,,,,,\nDefault 13,TR13,Fee Logging - FLCV,Woody Timber Co.,,1234.56,456,,36.092273,-80.24327,,,,,,,,,,\nDefault 14,TR14,Fee Logging - FLCV,Woody Timber Co.,,1234.56,456,,36.092273,-80.24327,,,,,,,,,,'
    },
    disabled () {
      return !userAssignedClaim(UserClaims.ContractManager)
    }
  },

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('tract', ['getLocationWithCoordinate', 'createTract']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('user', ['fetchAllBusinessEntities']),

    async loadInitialData () {
      const [accounts, businessEntities, tractTypes] = await Promise.all([this.fetchAccounts(), this.fetchAllBusinessEntities(), this.fetchTractTypes()])

      this.accountDictionary = accounts.reduce((dict, account) => {
        dict[`name-${account.name.toLowerCase()}`] = account
        dict[`id-${account.accountId}`] = account
        return dict
      }, {})

      this.businessEntityDictionary = businessEntities.reduce((dict, businessEntity) => {
        dict[`name-${businessEntity.name.toLowerCase()}`] = businessEntity
        dict[`id-${businessEntity.businessEntityId}`] = businessEntity
        return dict
      }, {})

      this.tractTypeDictionary = tractTypes.reduce((dict, tractType) => {
        dict[`name-${tractType.name.toLowerCase()}`] = tractType
        dict[`id-${tractType.tractTypeId}`] = tractType
        return dict
      }, {})
    },

    async createTractWithLandowners (tract) {
      tract.landowners = this.getLandowners(tract).map(lo => ({ accountId: lo.accountId, ownership: lo.ownership }))
      await this.createTract(tract)
    },

    getLandowners (tract) {
      return (Object.values(tract.landowners)).filter(lo => lo.accountId !== undefined)
    },

    getOwnershipString (tract) {
      const landowners = this.getLandowners(tract).map(lo => `${lo.accountName} (${formatPercentage(lo.ownership)})`)
      return landowners.join(', ')
    },

    setLandownerMap (parseResult) {
      const dictionary = new Map()
      parseResult.forEach(t => {
        dictionary.set(t.name, this.getLandowners(t))
      })
      this.landownerOwnersDictionary = dictionary
    }
  }
}
</script>
