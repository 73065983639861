import ApiServer from '@/utils/api/ApiServer.js'
import { TractDestination } from '@/model/TractDestination.js'

const fetchTractDestinations = async (_, tractId) => {
  const path = `tracts/${tractId}/destinations`
  const { data: destinations } = await ApiServer.get(path)
  return destinations.map(d => new TractDestination(d))
}

const resolveDestinationFromAccountId = async (tractId, accountId) => {
  return await new Promise((resolve, reject) => {
    fetchTractDestinations(null, tractId).then(dests => {
      const targetDest = dests.find(td => td.destinationAccountId === accountId)
      if (targetDest) resolve(targetDest)
      else reject(Error('could not resolve destination with accountId ' + accountId))
    })
  })
}

const postTractDestination = async (_, requestObj) => {
  const path = `tracts/${requestObj.tractId}/destinations`
  await ApiServer.post(path, requestObj)
  return await resolveDestinationFromAccountId(requestObj.tractId, requestObj.destinationAccountId)
}

const updateTractDestination = async (_, requestObj) => {
  const path = `tracts/${requestObj.tractId}/destinations/${requestObj.tractDestinationId}`
  await ApiServer.put(path, requestObj)
  return await resolveDestinationFromAccountId(requestObj.tractId, requestObj.destinationAccountId)
}

const deleteTractDestination = async (_, requestObj) => {
  const { tractId, tractDestinationId } = requestObj
  const path = `tracts/${tractId}/destinations/${tractDestinationId}`
  await ApiServer.delete(path)
}

export default {
  fetchTractDestinations,
  postTractDestination,
  updateTractDestination,
  deleteTractDestination
}
