import { PublicClientApplication } from '@azure/msal-browser'
import store from '@/store'
import { msalConfig } from './config.js'
import { getToken, setToken } from './AuthenticationHelpers.js'

const AuthService = new PublicClientApplication(msalConfig)

const handleLoginResult = (loginResult) => {
  if (loginResult !== null) {
    const { accessToken, fromCache } = loginResult
    setToken(accessToken)

    if (!fromCache) {
      store.dispatch('user/setLoginRedirectResult', true)
    }

    return loginResult
  }
}

export const authorizeUser = async () => {
  // If token is present and not expired, token will be a string with a valid token.
  const token = getToken()
  if (token) { return token }

  const loginResult = await acquireTokenSilentlyForAccount()
  return handleLoginResult(loginResult).accessToken
}

const acquireTokenSilentlyForAccount = () => {
  const loginRequest = {
    scopes: msalConfig.scope.scopes,
    account: AuthService.getAllAccounts()[0]
  }

  return AuthService.acquireTokenSilent(loginRequest)
}

export const signInWithRedirect = () => {
  AuthService.loginRedirect(msalConfig.scope)
}

export const hasAccount = () => {
  const allAccounts = AuthService.getAllAccounts()
  return allAccounts.length > 0
}

export const logoutWithRedirect = () => {
  AuthService.logoutRedirect()
}

AuthService.handleRedirectPromise().then(handleLoginResult)
