export default {
  setLoading (state, loading) {
    state.loading = loading
  },
  setRouterJump (state, routerJump) {
    state.routerJump = routerJump
  },
  setShortcuts (state, value) {
    state.shortcuts = value
  },
  setSettingName (state, value) {
    state.settingName = value
  }
}
