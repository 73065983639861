import { SasTokenLocations } from './constants'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { BlobServiceClient } from '@azure/storage-blob'

class CompanyLogoClient {
  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  async getEndpoint () {
    return 'companyinfo/blobinfo/companyinfologo'
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.COMPANY_LOGO_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = await this.getEndpoint()
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.COMPANY_LOGO_SAS, JSON.stringify(response))
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.COMPANY_LOGO_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  async getBlobLocation (companyInfoId) {
    return `${this.location}/${companyInfoId}/`
  }

  async getCompanyLogo (companyInfoId) {
    try {
      const containerClient = await this.getContainerClient()
      const blobLocation = await this.getBlobLocation(companyInfoId)
      const blobs = containerClient.listBlobsByHierarchy('/', { prefix: blobLocation })
      const blobItem = await blobs.next()
      const blobUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.COMPANY_LOGO_SAS)).data[0].blobSasUri, this.container, blobItem.value.name)
      return blobUrl.href
    } catch (e) {
      if (e.name !== 'TypeError') console.error(e) // Check for no logo
    }
  }

  async deleteCompanyLogo (companyLogoUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (companyLogoUrl instanceof URL)
        ? decodeURI(companyLogoUrl)
        : decodeURI(new URL(decodeURI(companyLogoUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = await containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.deleteIfExists()
    } catch (e) {
      console.error(e)
    }
  }

  async uploadCompanyLogo (companyInfoId, companyLogoFile) {
    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = await this.getBlobLocation(companyInfoId) + companyLogoFile.name
      const blockBlobClient = containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: companyLogoFile.type
        }
      }
      await blockBlobClient.uploadData(companyLogoFile, options)
    } catch (e) {
      console.error(e)
    }
  }

  // Functions for rendering the logo on PDFs
  async getCompanyLogoAsDataUrl (companyInfoId) {
    try {
      const companyLogoUrl = await this.getCompanyLogo(companyInfoId)
      if (companyLogoUrl === undefined) {
        return undefined
      }
      const response = await fetch(companyLogoUrl)
      const logoAsBlob = await response.blob()
      return await this.convertImageToDataUrl(logoAsBlob)
    } catch (e) {
      console.error(e)
      return undefined
    }
  }

  async convertImageToDataUrl (image) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader()
      fr.onerror = () => {
        fr.abort()
        reject(new DOMException('No logo found'))
      }
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(image)
    })
  }

  async getLogoDimensions (imageAsDataUrl) {
    if (imageAsDataUrl === undefined) {
      return undefined
    }
    return new Promise(function (resolve, reject) {
      var i = new Image()
      i.onload = function () {
        resolve({ width: i.width, height: i.height })
      }
      i.src = imageAsDataUrl
    })
  }

  async getTopMarginToCenterImage (companyInfoId) {
    const imageDimensions = await this.getLogoDimensions(await this.getCompanyLogoAsDataUrl(companyInfoId))
    if (imageDimensions === undefined) {
      return undefined
    }
    var margin = PdfLogoDimensions.BASE_MARGIN
    var x = imageDimensions.width / imageDimensions.height
    if (x <= PdfLogoDimensions.LOGO_MAX_WIDTH / PdfLogoDimensions.LOGO_MAX_HEIGHT) {
      return margin
    }
    return margin + ((PdfLogoDimensions.LOGO_MAX_HEIGHT - (PdfLogoDimensions.LOGO_MAX_WIDTH / x)) / 2)
  }
}

export const companyLogoClient = new CompanyLogoClient()

export const PdfLogoDimensions = {
  LOGO_MAX_HEIGHT: 60,
  LOGO_MAX_WIDTH: 90,
  BASE_MARGIN: 5
}
