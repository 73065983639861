<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="headline white--text">{{title}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="30vw">
          <v-autocomplete
          :items="counterparties"
          return-object
          item-text="name"
          multiple
          chips
          :label="$t('selectCounterparties')"
          v-model="selectedCounterparties">
          </v-autocomplete>
        </v-col>
        <v-btn
        :disabled="saveButtonDisabled"
        class="secondary"
        @click="$emit('download-contract', selectedCounterparties)"
        >
          {{ $t('download') }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'DownloadContractDialog',
  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  created () {
    if (this.counterparties.length === 1) {
      this.selectedCounterparty = this.counterparties[0]
    }
  },

  props: {
    contract: {
      type: Object,
      required: true
    },
    counterparties: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedCounterparties: [],

    loading: false
  }),

  computed: {

    title () {
      return this.$t('downloadContract')
    },

    saveButtonDisabled () {
      return this.selectedCounterparties.length === 0 || this.loading
    }
  }
}

</script>
