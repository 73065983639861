import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchDestinations (context) {
    try {
      context.commit('setLoading', true)
      const response = await ApiServer.get('destinations')
      context.commit('setDestinations', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async deleteDestination (context, destinationId) {
    const response = await ApiServer.delete('destinations/' + destinationId)
    context.commit('removeDestination', destinationId)
    return response.data
  },
  async createDestination (context, destination) {
    const response = await ApiServer.post('destinations', destination)
    context.commit('addDestination', response.data)
    return response.data
  },
  async updateDestination (context, destination) {
    const response = await ApiServer.put('destinations/' + destination.destinationId, destination)
    context.commit('updateDestination', response.data)
    return response.data
  }
}
