import i18n from '../i18n'

export default {
  allAdvanceHeaders () {
    return [
      {
        align: 'center',
        sortable: true,
        text: i18n.t('preIssued'),
        value: 'preIssued'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'note',
        width: '300px'
      },
      {
        sortable: true,
        text: i18n.t('payee'),
        value: 'payeeName'
      },
      {
        align: 'center',
        sortable: true,
        text: i18n.t('effective'),
        value: 'effectiveDate'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('balance'),
        value: 'balance',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('recoveryRate'),
        value: 'recovery',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('recoveryStartDate'),
        value: 'recoveryStartDate',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('activity'),
        value: 'activityName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('glCodeAndOffset'),
        value: 'glCode',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },
  advanceHeaders () {
    return [
      {
        align: 'center',
        sortable: true,
        text: '',
        value: 'status'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'note',
        width: '300px'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntityName'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract'
      },
      {
        align: 'center',
        sortable: true,
        text: i18n.t('effective'),
        value: 'effectiveDate'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('balance'),
        value: 'balance',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('recoveryRate'),
        value: 'recovery',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('recoveryStartDate'),
        value: 'recoveryStartDate',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('activity'),
        value: 'activityName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('glCodeAndOffset'),
        value: 'glCode',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },
  advanceModificationsHeaders () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'note'
      },
      {
        align: 'center',
        sortable: true,
        text: i18n.t('amount'),
        value: 'modificationAmount'
      },
      // {
      //   align: 'left',
      //   sortable: true,
      //   text: messages[]['type'],
      //   value: 'modificationType'
      // },
      {
        align: 'center',
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'createdAt'
      }
    ]
  }
}
