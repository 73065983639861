<template>
  <v-row dense>
    <v-col cols="12" class="text-h6">
      <span>{{$t('createdBy')}}:</span>
      <span class="ml-2">{{correctionUser.name}}</span>
    </v-col>
    <v-col cols="12">
      <span>{{correctionUser.preferredUsername}}</span>
    </v-col>
    <v-col cols="12">
      <span>Type: {{type}}</span>
    </v-col>
    <v-col cols="12">
      <span>Note: {{correction.notes}}</span>
    </v-col>
    <v-col cols="12">
      <span>{{utcToLocalDate(this.createdAt, 'L - LT')}}</span>
    </v-col>
  </v-row>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'CorrectionUser',

  props: {
    correction: {
      required: true,
      type: Object
    },
    createdAt: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    }
  },

  computed: {
    correctionUser () {
      return this.correction.creationUser
    }
  },

  methods: {
    utcToLocalDate
  }
}
</script>
