import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import { tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import store from '@/store'
import { CorrectionType, ContractType, TicketStatus, DefectCategory } from '@/utils/Enumerations.js'
import { fileNameForString } from './GenericSettlementsFunctions'

PDFMake.vfs = pdfFonts.pdfMake.vfs
const emptyLine = ['', '', '']

export async function getTicketPDF (tickets, companyInfo, tracts) {
  const ticketTable = []
  let ticketPDFName = ''
  let pageBreak = false
  let payPeriodEnd = ''

  const locations = await store.dispatch('locations/fetchLocations')
  const dateConfig = store.getters['settlements/dateConfiguration'].payPeriod
  if (dateConfig !== undefined) {
    payPeriodEnd = moment(dateConfig.endDate).subtract(1, 'days').format('YYYY-MM-DD')
  }

  if (tickets.length > 0) {
    if (!(tracts?.length > 0)) {
      tracts = [tickets[0].tract]
    }
    if (tracts.length > 1) {
      ticketPDFName = `tickets_${payPeriodEnd}`
    } else {
      ticketPDFName = fileNameForString(`${tracts[0].name} tickets ${payPeriodEnd}`)
    }
    pageBreak = true
    for (const [index, ticket] of tickets.entries()) {
      if (index === tickets.length - 1) pageBreak = false
      const table = await getTicketTable(ticket, companyInfo, locations, pageBreak)
      ticketTable.push(table)
    }
  } else {
    ticketPDFName = `Ticket_${tickets.ticketNumber}`
    const table = await getTicketTable(tickets, companyInfo, locations, pageBreak)
    ticketTable.push(table)
  }
  const docDefinition = {
    content: ticketTable
  }

  await PDFMake.createPdf(docDefinition).download(ticketPDFName)
}

async function getTicketTable (ticket, companyInfo, locations, pageBreak) {
  const defectTable = await getTicketDefectsTable(ticket)
  const correctionTable = await getCorrectionTable(ticket)
  const weightTable = getWeightTable(ticket)
  const contactInfoTable = await getContactInfoTableForTicket(ticket, companyInfo, locations)

  return [
    {
      table: {
        widths: ['auto', 'auto', '*'],
        body: [
          ...correctionTable,
          ...contactInfoTable,
          emptyLine,
          emptyLine,
          ['Ticket #:', ticket.ticketNumber || 'N/A', ''],
          [ticket.extTicketNumber1 ? 'External #1:' : '', ticket.extTicketNumber1 || '', ''],
          [ticket.extTicketNumber2 ? 'External #2:' : '', ticket.extTicketNumber2 || '', ''],
          emptyLine,
          ['Date:', moment().format('L - LT'), ''],
          ['Time In:', utcToLocalDate(ticket.weighedInAt, 'L - LT'), ''],
          ['Time Out:', utcToLocalDate(ticket.weighedOutAt, 'L - LT'), ''],
          ['Load Created:', utcToLocalDate(ticket.loadCreatedAt, 'L - LT'), ''],
          emptyLine,
          emptyLine,
          ['Product:', (ticket.product === '' || !ticket.product) ? 'Other' : ticket.product, ''],
          ['Pieces:', ticket.pieces || 'N/A', ''],
          ...getContractInformationRows(ticket),
          ['Trailer ID:', ticket.trailerIdentifier || 'N/A', ''],
          emptyLine
        ]
      },
      layout: {
        vLineColor: () => 'white',
        hLineColor: () => 'white'
      }
    },
    {
      table: {
        body: [
          ['Weight', defectTable ? 'Defect Information' : ''],
          [weightTable, defectTable ?? {}]
        ]
      },
      layout: {
        vLineColor: () => 'white',
        hLineColor: () => 'white'
      },
      pageBreak: pageBreak ? 'after' : undefined
    }
  ]
}

const getContractInformationRows = (ticket) => {
  if (ticket.contractType === ContractType.Production.value || ticket.contractType === ContractType.WoodsSale.value) {
    const tractInformation = [
      ['Tract:', ticket.tract, ''],
      ticket.landownerCount > 0
        ? ['Landowner:', ticket.landownerCount === 1 ? ticket.tractLandOwnerName : 'Multiple', '']
        : undefined,
      ['County:', ticket.tractCountrySubdivision2, '']
    ].filter(row => row !== undefined)
    return [
      ...tractInformation,
      ['Account:', ticket.account || 'N/A', ''],
      ['Logger:', ticket.logger || 'N/A', ''],
      ['Destination:', ticket.destination || 'N/A', '']
    ]
  } else {
    return [
      ['Account:', ticket.account, ''],
      ['From Account:', ticket.fromAccount, ''],
      ['Destination:', ticket.destination, '']
    ]
  }
}

const getCorrectionTable = async (ticket) => {
  if (ticket.status !== TicketStatus.Exported.value || ticket.correctionType === null || ticket.correctionType === CorrectionType.RecalculateWithNewContractValues.value) return []

  const corrections = await store.dispatch('ticket/getCorrectionsForTicket', ticket.ticketId)
  const note = corrections[0].correction.notes
  const type = CorrectionType.fromInt(ticket.correctionType)
  return [
    ['**CORRECTED**', '', ''],
    ['Correction Type:', type, ''],
    ['Correction Note:', note, ''],
    emptyLine
  ]
}

function formatWeightString (weightInLbs, formatToTons = false) {
  if (weightInLbs <= 0) {
    return 'N/A'
  }

  return formatToTons
    ? tonStringFromPounds(weightInLbs)
    : numberWithCommas(weightInLbs)
}

const getWeightTable = (ticket) => {
  const grossWeight = ticket.inWeight - ticket.outWeight
  const netWeight = grossWeight - ticket.defectWeight

  const body = [
    ['', 'LBs', 'Tons'],
    ['In', formatWeightString(ticket.inWeight), formatWeightString(ticket.inWeight, true)],
    ['Out', formatWeightString(ticket.outWeight), formatWeightString(ticket.outWeight, true)],
    ['Gross', formatWeightString(grossWeight), formatWeightString(grossWeight, true)],
    ['Defect', formatWeightString(ticket.defectWeight), formatWeightString(ticket.defectWeight, true)],
    ['Net', formatWeightString(netWeight), formatWeightString(netWeight, true)]
  ]

  if (ticket.departureWeight > 0) {
    body.splice(1, 0, ['Departure', formatWeightString(ticket.departureWeight), formatWeightString(ticket.departureWeight, true)])
  }

  return {
    table: {
      body
    }
  }
}

const getTicketDefectsTable = async ({ ticketId, defectWeight }) => {
  if (defectWeight <= 0) return

  const defects = await store.dispatch('defects/fetchDefectsForTicketById', ticketId)
  const body = []

  if (defects.length === 0) return

  const isSegmented = defects[0].diameter !== null && defects[0].percentage !== null

  if (isSegmented) {
    body.push(['Type', 'Category', 'LBs', 'Tons', 'Diameter', '%'])
    body.push(...defects.map(defect => [
      defect.name,
      DefectCategory.fromInt(defect.category),
      formatWeightString(defect.totalLBS),
      formatWeightString(defect.totalLBS, true),
      defect.diameter,
      defect.percentage
    ]))
  } else {
    body.push(['Type', 'Category', 'Quantity', 'LBs', 'Tons'])
    body.push(...defects.map(defect => [
      defect.name,
      DefectCategory.fromInt(defect.category),
      defect.quantity,
      formatWeightString(defect.totalLBS),
      formatWeightString(defect.totalLBS, true)
    ]))
  }

  return {
    table: {
      body
    }
  }
}

const getContactInfoTableForTicket = async ({ destinationAccountId, isExternal }, { mainContact, name: companyName }, locations) => {
  let contact = mainContact

  if (isExternal === false) {
    const location = locations.find(l => l.accountId === destinationAccountId)
    contact = location.ticketContact ?? mainContact
    if (location?.name && location?.name !== companyName) {
      companyName = `${companyName} - ${location.name}`
    }
  }

  const table = [
    [companyName, '', ''],
    [`${contact.firstName} ${contact.lastName}`, '', ''],
    [contact.phoneNumber, '', '']
  ]

  return table
}
