<template>
  <v-card>
    <v-card-title class="white--text headline secondary">
      {{ $t('modificationNote') }}
    </v-card-title>
    <v-card-text class="mt-6">
      {{ noteText }}
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        class="secondary"
        @click="$emit('close')"
      >
        {{ $t('okay') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ViewNoteDialog',

  props: {
    noteText: {
      type: String,
      required: true
    }
  }
}
</script>
