export default {
  setLoading (state, status) {
    state.destinationsLoading = status
  },
  setDestinations (state, destinations) {
    state.destinations = destinations
  },
  addDestination (state, destination) {
    state.destinations.push(destination)
  },
  updateDestination (state, destination) {
    const index = state.destinations.findIndex(d => d === destination.destinationId)
    if (index !== -1) {
      Object.assign(state.destinations[index], destination)
    }
  },
  removeDestination (state, destinationId) {
    state.destinations = state.destinations.filter(d => d.destinationId !== destinationId)
  }
}
