import { SETTING_KEYS } from '@/utils/UserSettings.js'
import { DataTableColumn, ColumnSet } from '@/model/DataTableColumn.js'
import { HarvestUnits, TractTypeCategory } from '@/utils/Enumerations'
import { formatMoney, numberWithCommas, tonStringFromPounds } from '../utils/NumericMutations'
import { utcToLocalDate } from '../utils/DateFormatter'
import i18n from '../i18n'
import { localeCompareAlphanumeric } from '../utils/base'
import { sortLandowners } from '../utils/SortHelpers'

export default {
  tractHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('name'),
        value: 'name',
        hidable: false
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('category'),
        value: 'category',
        hidable: true,
        group: 'properties'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('tractType'),
        value: 'typeName',
        hidable: false
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('landOwner'),
        value: 'landownerBundle',
        group: 'properties',
        sort: sortLandowners
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('logger'),
        value: 'defaultLoggerName',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 29,
        sortable: true,
        text: i18n.t('team'),
        value: 'teamName',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('forester'),
        value: 'foresterUserName',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 19,
        sortable: true,
        text: i18n.t('consultingForester'),
        value: 'consultingForesterAccount',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('cost'),
        value: 'cost',
        align: 'right',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 30,
        sortable: true,
        text: i18n.t('depletionBalance'),
        value: 'balance',
        align: 'right',
        group: 'properties',
        hidable: true
      }),
      new DataTableColumn({
        id: 25,
        sortable: true,
        text: i18n.t('advanceBalance'),
        value: 'advanceBalance',
        align: 'right',
        hidable: true,
        group: 'properties'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('harvestedTons'),
        value: 'harvestedTons',
        align: 'right',
        hidable: true,
        group: 'analysis'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('expectedTons'),
        value: 'expectedTons',
        align: 'right',
        hidable: true,
        group: 'analysis'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('cutoutPercentage'),
        value: 'harvestPercentage',
        align: 'right',
        group: 'analysis',
        sort: localeCompareAlphanumeric
      }),
      new DataTableColumn({
        id: 26,
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi',
        align: 'right',
        group: 'properties',
        format: value => value.toFixed(2)
      }),
      new DataTableColumn({
        id: 27,
        sortable: true,
        text: i18n.t('dbh'),
        value: 'dbh',
        align: 'right',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 28,
        sortable: true,
        text: i18n.t('age'),
        value: 'age',
        align: 'right',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 22,
        sortable: true,
        text: i18n.t('loggingAccessibility'),
        value: 'loggingAccessibilityTag',
        group: 'location'
      }),
      new DataTableColumn({
        id: 23,
        sortable: true,
        text: i18n.t('precipitation'),
        value: 'singleDayRainfallInches',
        sort: (a, b) => a !== null ? (a - b) : -9999,
        align: 'right',
        hidable: true,
        group: 'location'
      }),
      new DataTableColumn({
        id: 20,
        sortable: true,
        text: i18n.t('acres'),
        value: 'acres',
        align: 'right',
        group: 'location'
      }),
      new DataTableColumn({
        id: 21,
        sortable: true,
        text: i18n.t('harvestedTonsPerAcre'),
        value: 'harvestedTonsPerAcre',
        align: 'right',
        group: 'analysis'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('county'),
        value: 'countrySecondarySubdivision',
        group: 'location'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('state'),
        value: 'countrySubdivision',
        group: 'location'
      }),
      new DataTableColumn({
        id: 17,
        sortable: true,
        text: i18n.t('purchaseDate'),
        value: 'purchaseDate',
        align: 'right',
        hidable: true,
        group: 'properties'
      }),
      new DataTableColumn({
        id: 24,
        sortable: true,
        text: i18n.t('bonds'),
        value: 'bondBalance',
        align: 'right',
        hidable: true,
        group: 'properties'
      }),
      new DataTableColumn({
        id: 13,
        sortable: true,
        text: i18n.t('harvestStart'),
        value: 'harvestStartDate',
        align: 'right',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 14,
        sortable: true,
        text: i18n.t('harvestEnd'),
        value: 'harvestEndDate',
        align: 'right',
        group: 'properties'
      }),
      new DataTableColumn({
        id: 18,
        sortable: true,
        text: i18n.t('daysRemainingUntilHarvestEnd'),
        value: 'millisecondsTillHarvestEnd',
        align: 'right',
        hidable: true,
        group: 'properties'
      }),
      new DataTableColumn({
        id: 15,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 16,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TractGridHeader',
      userSettingKey: SETTING_KEYS.TRACT_GRID_HIDDEN_COLUMNS
    })
  },

  deliveredWoodHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('name'),
        value: 'tractName',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('supplier'),
        value: 'accountName',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 2,
        sortable: false,
        text: i18n.t('type'),
        value: 'tractType',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi',
        align: 'right',
        format: value => value.toFixed(2)
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('dbh'),
        value: 'dbh',
        align: 'right'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('age'),
        value: 'age',
        align: 'right'
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('loads'),
        value: 'loads',
        align: 'right',
        total: sum
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossTons',
        align: 'right',
        format: (value) => numberWithCommas(value, 3),
        total: sum
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netTons',
        align: 'right',
        hidable: false,
        format: (value) => numberWithCommas(value, 3),
        total: sum
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('costPerTon'),
        value: 'costPerTon',
        align: 'right',
        hidable: false,
        format: formatMoney,
        total: ({ items }) => {
          const totalCost = items.reduce((sum, item) => sum + item.totalCost, 0)
          const totalNetTons = items.reduce((sum, item) => sum + item.netTons, 0)
          return totalNetTons > 0 ? totalCost / totalNetTons : 0
        }
      }),
      new DataTableColumn({
        id: 12,
        sortable: false,
        text: i18n.t('totalCost'),
        value: 'totalCost',
        align: 'right',
        format: formatMoney,
        total: sum
      })
    ]
    return new ColumnSet({
      columns: columns,
      setName: 'DeliveredWoodHeaders',
      userSettingKey: SETTING_KEYS.DELIVERED_WOOD_HIDDEN_COLUMNS
    })
  },

  fileHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('filename'),
        value: 'name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('type'),
        value: 'type',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('uploadedBy'),
        value: 'user',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('uploadedDate'),
        value: 'date',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      }
    ]
  },

  tractPaymentRegisterHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('title'),
        value: 'note',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName'
      },
      {
        sortable: true,
        text: i18n.t('activity'),
        value: 'activity'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        sortable: true,
        text: i18n.t('glOffset'),
        value: 'glOffset'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      }
    ]
  },

  tractPayableHeaders () {
    return [
      {
        sortable: true,
        text: '',
        value: 'type',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('title'),
        value: 'paymentNote'
      },
      {
        sortable: true,
        text: i18n.t('activity'),
        value: 'activityName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntityName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'accountName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('issuedAt'),
        value: 'issuedAt',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('payDate'),
        value: 'payDate',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('glOffset'),
        value: 'glOffset',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('createdBy'),
        value: 'createdByUser',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'createdAt',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  currentTractPayableHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('accountName'),
        value: 'accountName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('tractName'),
        value: 'tractName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('title'),
        value: 'paymentNote',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('activity'),
        value: 'activityName',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'accountName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('payDate'),
        value: 'payDate',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('createdBy'),
        value: 'createdByUser',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'createdAt',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  depletionBalanceHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('tractCategory'),
        value: 'tractTypeName',
        align: 'left'
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName',
        align: 'left'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('cost'),
        value: 'tractCost',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('depletedInBatch'),
        value: 'depletionInBatch',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('previouslyDepleted'),
        value: 'previouslyDepleted',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('totalDepleted'),
        value: 'totalDepleted',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('purchaseDate'),
        value: 'purchasedAt',
        align: 'right',
        format: (value) => utcToLocalDate(value)
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('harvestEndDate'),
        value: 'harvestEndDate',
        align: 'right',
        format: (value) => utcToLocalDate(value)
      })
    ]

    return new ColumnSet({
      columns,
      setName: 'depletionHeaders'
    })
  },

  liveDepletionHeaders ({ isPeriod }) {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('tractCategory'),
        value: 'tractTypeName',
        align: 'left'
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName',
        align: 'left'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('cost'),
        value: 'tractCost',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t(isPeriod ? 'depletedInPeriod' : 'depletedInFiscalYear'),
        value: 'depletedInSelection',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('remaining'),
        value: 'remaining',
        align: 'right',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('purchaseDate'),
        value: 'purchasedAt',
        align: 'right',
        format: (value) => utcToLocalDate(value)
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('harvestEndDate'),
        value: 'harvestEndDate',
        align: 'right',
        format: (value) => utcToLocalDate(value)
      })
    ]

    return new ColumnSet({
      columns,
      setName: 'liveDepletionHeaders'
    })
  },

  tractProductionHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'productName'
      },
      {
        sortable: true,
        text: i18n.t('rate'),
        value: 'rate',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('loads'),
        value: 'loads',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('netTons'),
        value: 'weight',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        align: 'right',
        value: 'amount'
      }
    ]
  },

  standingTimberHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        hidable: false,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName',
        align: 'left',
        tooltip: i18n.t('tractName')
      }),
      new DataTableColumn({
        id: 14,
        hidable: true,
        sortable: true,
        text: i18n.t('team'),
        value: 'teamName',
        tooltip: i18n.t('team')
      }),
      new DataTableColumn({
        id: 1,
        hidable: false,
        sortable: true,
        text: i18n.t('forester'),
        value: 'tractForester',
        align: 'left',
        tooltip: i18n.t('forester')
      }),
      new DataTableColumn({
        id: 2,
        hidable: false,
        sortable: true,
        text: i18n.t('type'),
        value: 'tractType',
        align: 'left',
        tooltip: i18n.t('tractType')
      }),
      new DataTableColumn({
        id: 3,
        hidable: false,
        sortable: true,
        text: i18n.t('expectedTons'),
        value: 'expectedTons',
        align: 'right',
        tooltip: i18n.t('cruisedTonsTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 4,
        hidable: false,
        sortable: true,
        text: i18n.t('harvestedTons'),
        value: 'harvestedTons',
        align: 'right',
        tooltip: i18n.t('harvestedVolumeTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 5,
        hidable: true,
        sortable: true,
        text: i18n.t('remainingTons'),
        value: 'remainingTons',
        align: 'right',
        tooltip: i18n.t('remainingCruiseVolumeTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 6,
        hidable: true,
        sortable: true,
        text: i18n.t('overcutTons'),
        value: 'overcutTons',
        align: 'right',
        tooltip: i18n.t('overcutVolumeTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 7,
        hidable: true,
        sortable: true,
        text: i18n.t('cruisedValue'),
        value: 'cruisedValue',
        align: 'right',
        tooltip: i18n.t('cruiseValueNoHarvestValueTooltip'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 8,
        hidable: true,
        sortable: true,
        text: i18n.t('cost'),
        value: 'tractCost',
        align: 'right',
        tooltip: i18n.t('costTooltip'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 15,
        hidable: true,
        sortable: true,
        text: i18n.t('depletionBalance'),
        value: 'balance',
        align: 'right',
        tooltip: i18n.t('depletionBalance'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 9,
        hidable: true,
        sortable: true,
        text: i18n.t('stumpageValue'),
        value: 'stumpageValue',
        align: 'right',
        tooltip: i18n.t('remainingCruiseValueTooltip'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 10,
        hidable: true,
        sortable: true,
        text: i18n.t('averageCutoutValue'),
        value: 'cutoutValue',
        align: 'right',
        tooltip: i18n.t('averageCutoutValueTooltip'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 11,
        hidable: true,
        sortable: true,
        text: i18n.t('acres'),
        value: 'tractAcres',
        align: 'right',
        tooltip: i18n.t('tractAcres')
      }),
      new DataTableColumn({
        id: 13,
        hidable: true,
        sortable: true,
        text: i18n.t('loggingAccessibility'),
        value: 'tractLoggingAccessibility',
        align: 'left',
        tooltip: i18n.t('loggingAccessibility')
      }),
      new DataTableColumn({
        id: 12,
        hidable: false,
        sortable: true,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        tooltip: i18n.t('actions')
      })
    ]
    return new ColumnSet({
      columns,
      setName: 'StandingTimberHeaders',
      userSettingKey: SETTING_KEYS.STANDING_TIMBER_HEADERS
    })
  },

  committedTimberHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        hidable: false,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName',
        align: 'left',
        tooltip: i18n.t('tractName')
      }),
      new DataTableColumn({
        id: 12,
        hidable: true,
        sortable: true,
        text: i18n.t('team'),
        value: 'teamName',
        tooltip: i18n.t('team')
      }),
      new DataTableColumn({
        id: 1,
        hidable: false,
        sortable: true,
        text: i18n.t('forester'),
        value: 'tractForester',
        align: 'left',
        tooltip: i18n.t('forester')
      }),
      new DataTableColumn({
        id: 2,
        hidable: false,
        sortable: true,
        text: i18n.t('type'),
        value: 'tractType',
        align: 'left',
        tooltip: i18n.t('tractType')
      }),
      new DataTableColumn({
        id: 3,
        hidable: false,
        sortable: true,
        text: i18n.t('expectedTons'),
        value: 'expectedTons',
        align: 'right',
        tooltip: i18n.t('cruisedTonsTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 4,
        hidable: false,
        sortable: true,
        text: i18n.t('harvestedTons'),
        value: 'harvestedTons',
        align: 'right',
        tooltip: i18n.t('harvestedVolumeTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 5,
        hidable: true,
        sortable: true,
        text: i18n.t('remainingTons'),
        value: 'remainingTons',
        align: 'right',
        tooltip: i18n.t('remainingCruiseVolumeTooltip'),
        format: i => numberWithCommas(i, 3)
      }),
      new DataTableColumn({
        id: 6,
        hidable: true,
        sortable: true,
        text: i18n.t('cruisedValue'),
        value: 'cruisedValue',
        align: 'right',
        tooltip: i18n.t('cruiseValueNoHarvestValueTooltip'),
        format: formatMoney
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi',
        align: 'right',
        format: value => value.toFixed(2)
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('dbh'),
        value: 'dbh',
        align: 'right'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('age'),
        value: 'age',
        align: 'right'
      }),
      new DataTableColumn({
        id: 10,
        hidable: true,
        sortable: true,
        text: i18n.t('loggingAccessibility'),
        value: 'tractLoggingAccessibility',
        align: 'left',
        tooltip: i18n.t('loggingAccessibility')
      }),
      new DataTableColumn({
        id: 9,
        hidable: false,
        sortable: true,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        tooltip: i18n.t('actions')
      })
    ]
    return new ColumnSet({
      columns,
      setName: 'CommittedTimberHeaders',
      userSettingKey: SETTING_KEYS.COMMITTED_TIMBER_HEADERS
    })
  },

  timberPortfolioTractForProductHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('name'),
        value: 'name',
        hidable: false
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('type'),
        value: 'typeName',
        hidable: false
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('logger'),
        value: 'loggerAccountName',
        group: 'properties',
        hidable: false
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('forester'),
        value: 'foresterUserName',
        group: 'properties',
        hidable: false
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('tons'),
        value: 'remainingTons',
        align: 'right',
        hidable: false,
        group: 'analysis',
        tooltip: i18n.t('remainingTons')
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('loggingAccessibility'),
        value: 'loggingAccessibilityTagName',
        group: 'location',
        hidable: true
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('county'),
        value: 'countrySecondarySubdivision',
        group: 'location',
        hidable: true
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('state'),
        value: 'countrySubdivision',
        group: 'location',
        hidable: true
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('days'),
        value: 'daysRemaining',
        align: 'right',
        hidable: true,
        group: 'properties',
        tooltip: i18n.t('daysRemainingUntilHarvestEnd')
      }),
      new DataTableColumn({
        id: 10,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns,
      setName: 'TimberPortfolioHeaders',
      userSettingKey: SETTING_KEYS.TIMBER_PORTFOLIO_HIDDEN_COLUMNS
    })
  },

  timberPortfolioTractForLoggerHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false,
        width: '82px'
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('tract'),
        value: 'name',
        hidable: false,
        width: '200px'
      }),
      new DataTableColumn({
        id: 2,
        sortable: false,
        text: '',
        value: 'timeline',
        hidable: false
      }),
      new DataTableColumn({
        id: 3,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false,
        width: '92px'
      })
    ]
    return new ColumnSet({
      columns: columns
    })
  },

  tractSummaryHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        hidable: false,
        sortable: true,
        text: i18n.t('tract'),
        value: 'name'
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        hidable: false,
        text: i18n.t('type'),
        value: 'tractType',
        align: 'left'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('loads'),
        value: 'loads',
        align: 'right'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right'
      }),
      new DataTableColumn({
        id: 17,
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi',
        align: 'right',
        format: value => value.toFixed(2)
      }),
      new DataTableColumn({
        id: 18,
        sortable: true,
        text: i18n.t('dbh'),
        value: 'dbh',
        align: 'right'
      }),
      new DataTableColumn({
        id: 19,
        sortable: true,
        text: i18n.t('age'),
        value: 'age',
        align: 'right'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('percentageOfTotal'),
        value: 'percentageOfTotal',
        align: 'right',
        format: value => `${(value.toFixed(2))}%`
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('grossTons'),
        align: 'right',
        value: 'grossWeight',
        format: tonStringFromPounds
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('netTons'),
        align: 'right',
        value: 'netWeight',
        format: tonStringFromPounds
      }),
      new DataTableColumn({
        id: 8,
        sortable: false,
        text: i18n.t('averageNetTons'),
        align: 'right',
        value: 'averageWeight'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('stumpageCostPerTon'),
        align: 'right',
        value: 'stumpageCostPerTon',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('productionCostPerTon'),
        align: 'right',
        value: 'productionCostPerTon',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('stumpageCost'),
        align: 'right',
        value: 'stumpageCost',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('productionCost'),
        align: 'right',
        value: 'productionCost',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('costPerGrossTon'),
        align: 'right',
        value: 'costPerGrossTon',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 13,
        sortable: true,
        text: i18n.t('costPerNetTon'),
        align: 'right',
        value: 'costPerNetTon',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 14,
        sortable: true,
        text: i18n.t('totalCost'),
        align: 'right',
        value: 'totalCost',
        format: formatMoney
      }),
      new DataTableColumn({
        id: 15,
        hidable: false,
        text: i18n.t('actions'),
        align: 'center',
        value: 'actions'
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'ProductCostHiddenColumns',
      userSettingKey: SETTING_KEYS.PRODUCT_COSTS_HIDDEN_COLUMNS
    })
  },

  detailedSummaryHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        hidable: false,
        text: i18n.t('product'),
        value: 'productName'
      }),
      new DataTableColumn({
        id: 1,
        text: i18n.t('loads'),
        value: 'loads',
        align: 'right',
        total: sum
      }),
      new DataTableColumn({
        id: 2,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right'
      }),
      new DataTableColumn({
        id: 3,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right'
      }),
      new DataTableColumn({
        id: 4,
        text: i18n.t('percentageOfTotal'),
        value: 'percentageOfTotal',
        align: 'right',
        format: value => `${(value.toFixed(2))}%`,
        total: sum
      }),
      new DataTableColumn({
        id: 5,
        text: i18n.t('grossTons'),
        align: 'right',
        value: 'grossWeight',
        format: tonStringFromPounds,
        total: sum
      }),
      new DataTableColumn({
        id: 6,
        hidable: false,
        sortable: false,
        text: i18n.t('netTons'),
        align: 'right',
        value: 'netWeight',
        format: tonStringFromPounds,
        total: sum
      }),
      new DataTableColumn({
        id: 7,
        sortable: false,
        text: i18n.t('averageNetTons'),
        align: 'right',
        value: 'averageWeight',
        format: tonStringFromPounds,
        total: ({ sums }) => sums.netWeight / sums.loads
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('stumpageCostPerTon'),
        align: 'right',
        value: 'stumpageCostPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.stumpageCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('productionCostPerTon'),
        align: 'right',
        value: 'productionCostPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.productionCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('stumpageCost'),
        align: 'right',
        value: 'stumpageCost',
        format: formatMoney,
        total: sum
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('productionCost'),
        align: 'right',
        value: 'productionCost',
        format: formatMoney,
        total: sum
      }),
      new DataTableColumn({
        id: 12,
        sortable: false,
        text: i18n.t('costPerTon'),
        align: 'right',
        value: 'costPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.totalCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 13,
        hidable: false,
        sortable: false,
        text: i18n.t('totalCost'),
        align: 'right',
        value: 'totalCost',
        format: formatMoney,
        total: sum
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TractProductCostHiddenColumns',
      userSettingKey: SETTING_KEYS.TRACT_PRODUCT_COST_HIDDEN_COLUMNS
    })
  },

  detailedTicketHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        hidable: false,
        sortable: false,
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber'
      }),
      new DataTableColumn({
        id: 14,
        hidable: true,
        sortable: false,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        format: (value) => value === '' ? i18n.t('notAvailable') : value
      }),
      new DataTableColumn({
        id: 15,
        hidable: true,
        sortable: false,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        format: (value) => value === '' ? i18n.t('notAvailable') : value
      }),
      new DataTableColumn({
        id: 1,
        hidable: false,
        sortable: false,
        text: i18n.t('product'),
        value: 'productName'
      }),
      new DataTableColumn({
        id: 2,
        sortable: false,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right'
      }),
      new DataTableColumn({
        id: 3,
        sortable: false,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right'
      }),
      new DataTableColumn({
        id: 4,
        sortable: false,
        text: i18n.t('weighedOutAt'),
        value: 'weighedOutAt',
        format: (value) => utcToLocalDate(value, 'MM/DD/YYYY - LT')
      }),
      new DataTableColumn({
        id: 6,
        text: i18n.t('grossTons'),
        align: 'right',
        value: 'grossWeight',
        format: tonStringFromPounds,
        total: sum
      }),
      new DataTableColumn({
        id: 7,
        hidable: false,
        sortable: false,
        text: i18n.t('netTons'),
        align: 'right',
        value: 'netWeight',
        format: tonStringFromPounds,
        total: sum
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('stumpageCostPerTon'),
        align: 'right',
        value: 'stumpageCostPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.stumpageCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('productionCostPerTon'),
        align: 'right',
        value: 'productionCostPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.productionCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('stumpageCost'),
        align: 'right',
        value: 'stumpageCost',
        format: formatMoney,
        total: sum
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('productionCost'),
        align: 'right',
        value: 'productionCost',
        format: formatMoney,
        total: sum
      }),
      new DataTableColumn({
        id: 12,
        sortable: false,
        text: i18n.t('costPerTon'),
        align: 'right',
        value: 'costPerTon',
        format: formatMoney,
        total: ({ sums }) => sums.totalCost / (sums.netWeight / 2000)
      }),
      new DataTableColumn({
        id: 13,
        sortable: false,
        hidable: false,
        text: i18n.t('totalCost'),
        align: 'right',
        value: 'totalCost',
        format: formatMoney,
        total: sum
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TicketProductCostHiddenColumns',
      userSettingKey: SETTING_KEYS.TICKET_PRODUCT_COSTS_HIDDEN_COLUMNS
    })
  },

  templateBundleHeaders () {
    return [
      {
        sortable: true,
        text: '',
        value: 'activityStatus'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'activityTemplateName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('counterparty'),
        value: 'counterpartyName'
      },
      {
        sortable: false,
        text: i18n.t('recoveryMode'),
        value: 'recovers'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'category',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'baseRate',
        width: '150',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'payOn'
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'payBy'
      },
      {
        sortable: true,
        text: i18n.t('modifier'),
        value: 'modifier',
        align: 'center'
      }
    ]
  },

  bundleProductHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'productName'
      },
      {
        sortable: true,
        text: i18n.t('rate'),
        value: 'baseRate',
        width: '250',
        align: 'right'
      }
    ])
  }
}

const formatTons = t => numberWithCommas(t, 3)
const formatPct = p => `${Math.round(p)}%`
const sum = ({ total }) => total

export const tractHarvestHeaders = (tractTypeCategory, harvestUnits = HarvestUnits.Tons) => {
  const isStumpage = tractTypeCategory === TractTypeCategory.Stumpage
  const isTons = harvestUnits === HarvestUnits.Tons

  const columns = [
    new DataTableColumn({
      id: 0,
      text: i18n.t('product'),
      value: 'product',
      tooltip: i18n.t('productTooltip'),
      hidable: false
    }),
    new DataTableColumn({
      id: 2,
      text: isTons ? i18n.t('cruisePricePerTon') : i18n.t('cruisePricePerMBF'),
      value: isTons ? 'pricePerTon' : 'pricePerMBF',
      align: 'right',
      tooltip: i18n.t('cruisePricePerTonTooltip'),
      hidable: false,
      format: formatMoney,
      group: 'cruise'
    }),
    new DataTableColumn({
      id: 3,
      text: isTons ? i18n.t('cruisedTons') : i18n.t('cruisedMBF'),
      value: isTons ? 'expectedTons' : 'expectedMBF',
      align: 'right',
      tooltip: i18n.t('cruisedTonsTooltip'),
      hidable: false,
      format: formatTons,
      total: sum,
      group: 'cruise'
    }),
    new DataTableColumn({
      id: 4,
      text: i18n.t('cruisedValue'),
      value: 'cruisedValue',
      align: 'right',
      tooltip: i18n.t('cruisedValueTooltip'),
      hidable: false,
      format: formatMoney,
      total: sum,
      group: 'cruise'
    }),
    isStumpage ? new DataTableColumn({
      id: 17,
      text: i18n.t('purchaseAllocation'),
      textAbbr: i18n.t('purchaseAllocationAbbr'),
      value: 'purchaseAllocation',
      align: 'right',
      tooltip: i18n.t('purchaseAllocationCruiseTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial'
    }) : undefined,
    new DataTableColumn({
      id: 1,
      text: i18n.t('loads'),
      value: 'loads',
      align: 'right',
      hidable: false,
      tooltip: i18n.t('loadsTooltip'),
      total: sum
    }),
    new DataTableColumn({
      id: 5,
      text: isTons ? i18n.t('harvestedTons') : i18n.t('harvestedMBF'),
      textAbbr: isTons ? i18n.t('harvestedTonsAbbr') : i18n.t('harvestedMBFAbbr'),
      value: isTons ? 'harvestedTons' : 'harvestedMBF',
      align: 'right',
      tooltip: i18n.t('harvestedTonsTooltip'),
      format: formatTons,
      total: sum,
      group: 'operational'
    }),
    new DataTableColumn({
      id: 6,
      text: i18n.t('harvested'),
      textAbbr: i18n.t('harvestedAbbr'),
      value: 'percentageOfTotalHarvest',
      align: 'right',
      tooltip: i18n.t('harvestedTonsPercentageTooltip'),
      format: formatPct,
      total: sum,
      group: 'operational',
      columnAdjusterText: i18n.t('harvestedTonsPercentage')
    }),
    new DataTableColumn({
      id: 7,
      text: isTons ? i18n.t('remainingCruiseTons') : i18n.t('remainingCruiseMBF'),
      textAbbr: isTons ? i18n.t('remainingCruiseTonsAbbr') : i18n.t('remainingCruiseMBFAbbr'),
      value: isTons ? 'remainingTons' : 'remainingMBF',
      align: 'right',
      tooltip: i18n.t('remainingTonsTooltip'),
      format: formatTons,
      total: sum,
      group: 'operational'
    }),
    new DataTableColumn({
      id: 8,
      text: i18n.t('cutout'),
      value: 'cutoutPercentage',
      align: 'right',
      tooltip: i18n.t('cutoutPercentageTooltip'),
      format: formatPct,
      group: 'operational',
      columnAdjusterText: i18n.t('cutoutPercentage')
    }),
    new DataTableColumn({
      id: 9,
      text: isTons ? i18n.t('overcutTons') : i18n.t('overcutMBF'),
      value: isTons ? 'overcutTons' : 'overcutMBF',
      align: 'right',
      tooltip: i18n.t('overcutTonsTooltip'),
      format: formatTons,
      total: sum,
      group: 'operational'
    }),
    new DataTableColumn({
      id: 10,
      text: i18n.t('overcut'),
      value: 'overcutPercentage',
      align: 'right',
      tooltip: i18n.t('overcutPercentageTooltip'),
      format: formatPct,
      group: 'operational',
      columnAdjusterText: i18n.t('overcutPercentage')
    }),
    new DataTableColumn({
      id: 18,
      text: i18n.t('harvestedCruise'),
      textAbbr: i18n.t('harvestedCruiseAbbr'),
      value: 'harvestedCruiseValue',
      align: 'right',
      tooltip: i18n.t('harvestedCruiseValueTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('harvestedCruiseValue')
    }),
    new DataTableColumn({
      id: 19,
      text: i18n.t('overUnderCruise'),
      value: 'overUnderCruiseValue',
      align: 'right',
      tooltip: i18n.t('overUnderCruiseValueTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial'
    }),
    new DataTableColumn({
      id: 11,
      text: i18n.t('remainingCruise'),
      textAbbr: i18n.t('remainingCruiseValueAbbr'),
      value: 'remainingCruiseValue',
      align: 'right',
      tooltip: i18n.t('remainingCruiseValueTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('remainingCruiseValue')
    }),
    new DataTableColumn({
      id: 15,
      text: i18n.t('stumpage'),
      value: 'totalStumpageCost',
      align: 'right',
      tooltip: i18n.t('stumpageCostCruiseTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('stumpageCostCruise')
    }),
    isStumpage ? new DataTableColumn({
      id: 20,
      text: i18n.t('overUnderPurchase'),
      textAbbr: i18n.t('overUnderPurchaseAbbr'),
      value: 'overUnderPurchaseValue',
      align: 'right',
      tooltip: i18n.t('overUnderPurchaseValueTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('overUnderPurchaseValue')
    }) : undefined,
    isStumpage ? new DataTableColumn({
      id: 21,
      text: i18n.t('remainingPurchase'),
      textAbbr: i18n.t('remainingPurchaseAbbr'),
      value: 'remainingPurchaseValue',
      align: 'right',
      tooltip: i18n.t('remainingPurchaseValueTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('remainingPurchaseValue')
    }) : undefined,
    new DataTableColumn({
      id: 14,
      text: i18n.t('production'),
      textAbbr: i18n.t('productionAbbr'),
      value: 'totalProductionCost',
      align: 'right',
      tooltip: i18n.t('totalProductionCostCruiseTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial',
      columnAdjusterText: i18n.t('totalProductionCostCruise')
    }),
    new DataTableColumn({
      id: 16,
      text: i18n.t('total'),
      value: 'totalCost',
      align: 'right',
      tooltip: i18n.t('totalCruiseCostTooltip'),
      hidable: false,
      format: formatMoney,
      total: sum
    }),
    isStumpage ? new DataTableColumn({
      id: 22,
      text: i18n.t('revenue'),
      value: 'revenue',
      align: 'right',
      tooltip: i18n.t('revenueCruiseTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial'
    }) : undefined,
    isStumpage ? new DataTableColumn({
      id: 23,
      text: i18n.t('grossProfit'),
      value: 'grossProfit',
      align: 'right',
      tooltip: i18n.t('grossProfitCruiseTooltip'),
      format: formatMoney,
      total: sum,
      group: 'financial'
    }) : undefined,
    isStumpage ? new DataTableColumn({
      id: 24,
      text: isTons ? i18n.t('grossProfitPerTon') : i18n.t('grossProfitPerMBF'),
      value: isTons ? 'grossProfitPerTon' : 'grossProfitPerMBF',
      align: 'right',
      tooltip: isTons ? i18n.t('grossProfitPerTonCruiseTooltip') : i18n.t('grossProfitPerMBFCruiseTooltip'),
      format: formatMoney,
      group: 'financial',
      total: ({ sums }) => sums.grossProfit / (isTons ? sums.harvestedTons : sums.harvestedMBF)
    }) : undefined
  ].filter(dt => dt !== undefined)

  return new ColumnSet({
    columns: columns,
    setName: 'TractHarvestHeaders',
    userSettingKey: SETTING_KEYS.TRACT_CRUISE_HIDDEN_COLUMNS
  })
}

export const tractPtDownloadHeaders = () => ([
  {
    sortable: true,
    text: i18n.t('name'),
    value: 'name'
  },
  {
    sortable: true,
    text: i18n.t('code'),
    value: 'code'
  },
  {
    sortable: true,
    text: i18n.t('type'),
    value: 'type'
  },
  {
    sortable: false,
    text: i18n.t('actions'),
    value: 'actions',
    align: 'center'
  }
])

export const tractBulkImportPreviewHeaders = () => {
  return ([
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('code'),
      value: 'code'
    },
    {
      sortable: true,
      text: i18n.t('typeName'),
      value: 'typeName'
    },
    {
      sortable: true,
      text: i18n.t('logger'),
      value: 'logger'
    },
    {
      sortable: true,
      text: i18n.t('landOwner'),
      value: 'landOwner'
    },
    {
      sortable: true,
      text: i18n.t('businessEntity'),
      value: 'businessEntity'
    },
    {
      sortable: true,
      text: i18n.t('cost'),
      value: 'cost',
      align: 'right',
      format: formatMoney
    },
    {
      sortable: true,
      text: i18n.t('acres'),
      value: 'acres',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('mapAndParcelNumber'),
      value: 'mapAndParcelNumber'
    },
    {
      sortable: false,
      text: i18n.t('coordinates'),
      value: 'coordinates'
    },
    {
      sortable: true,
      text: i18n.t('location'),
      value: 'location'
    }
  ])
}
