import {
  DashboardLogsWidgets as logsWidgets,
  DashboardOperationalWidgets as operationalWidgets,
  DashboardByproductWidgets as byproductWidgets
} from '@/components/dashboard/WidgetMappings'

export default class IncludeHeadersForWidget {
  constructor (widget) {
    this.widget = widget

    this.AllWeight = [logsWidgets.DefectsByAccount, logsWidgets.CompletedLoadsByLogger].includes(this.widget)

    this.NetWeight = [
      logsWidgets.TransferLoads,
      logsWidgets.LogQualityAnalysis,
      logsWidgets.CompletedLoadsByLandOwner,
      logsWidgets.CompletedLoadsByDestination,
      logsWidgets.CompletedLoadsByTract,
      logsWidgets.CompletedLoadsByAccount,
      logsWidgets.CompletedLoadsByProduct,
      logsWidgets.CompletedLoadsByRegion,
      logsWidgets.CompletedLoadsByTractType,
      logsWidgets.TonsByCertification,
      byproductWidgets.CompletedLoadsByDestination,
      byproductWidgets.CompletedLoadsByProduct,
      byproductWidgets.CompletedLoadsByAccount
    ].includes(this.widget)

    this.DefectWeight = [logsWidgets.ItemizedDefectSummary].includes(this.widget)

    this.DepartureWeight = [
      byproductWidgets.CompletedLoadsByAccount,
      byproductWidgets.CreatedLoadsByAccount
    ].includes(this.widget)

    this.Ext = ![
      logsWidgets.ItemizedDefectSummary,
      operationalWidgets.LoadsInTransitByDestination,
      logsWidgets.LogQualityAnalysis,
      logsWidgets.CompletedLoadsByLandOwner
    ].includes(this.widget)

    this.Destination = ![logsWidgets.ItemizedDefectSummary].includes(this.widget)

    this.Driver = [
      operationalWidgets.LoadsInTransitByDestination,
      operationalWidgets.IncompleteTickets
    ].includes(this.widget)

    this.AllowHideDriver = ![operationalWidgets.IncompleteTickets].includes(widget)

    this.Account = ![
      operationalWidgets.LoadsInTransitByDestination,
      logsWidgets.LogQualityAnalysis,
      logsWidgets.CompletedLoadsByLandOwner
    ].includes(this.widget)

    this.Quality = [logsWidgets.LogQualityAnalysis].includes(this.widget)

    this.Logger = ![
      logsWidgets.TransferLoads,
      logsWidgets.LogQualityAnalysis,
      logsWidgets.CompletedLoadsByLandOwner,
      byproductWidgets.CompletedLoadsByDestination,
      byproductWidgets.CompletedLoadsByProduct,
      byproductWidgets.CompletedLoadsByAccount,
      byproductWidgets.CreatedLoadsByDestination,
      byproductWidgets.CreatedLoadsByProduct,
      byproductWidgets.CreatedLoadsByAccount
    ].includes(this.widget)

    this.TractType = [logsWidgets.CreatedLoadsByTractType].includes(this.widget)

    this.Landowner = [logsWidgets.CompletedLoadsByLandOwner].includes(this.widget)

    this.Tract = ![
      logsWidgets.TransferLoads,
      byproductWidgets.CompletedLoadsByDestination,
      byproductWidgets.CompletedLoadsByProduct,
      byproductWidgets.CompletedLoadsByAccount,
      byproductWidgets.CreatedLoadsByDestination,
      byproductWidgets.CreatedLoadsByProduct,
      byproductWidgets.CreatedLoadsByAccount
    ].includes(this.widget)

    this.FromAccount = [
      logsWidgets.TransferLoads,
      byproductWidgets.CompletedLoadsByDestination,
      byproductWidgets.CompletedLoadsByProduct,
      byproductWidgets.CompletedLoadsByAccount,
      byproductWidgets.CreatedLoadsByDestination,
      byproductWidgets.CreatedLoadsByProduct,
      byproductWidgets.CreatedLoadsByAccount
    ].includes(this.widget)

    this.CreatedAt = [operationalWidgets.LoadsReadyForPickup].includes(this.widget)
  }
}
