export default {
  setLoading (state, status) {
    state.advancesLoading = status
  },
  setAdvances (state, advances) {
    state.advances = advances
  },
  addAdvance (state, advance) {
    state.advances.push(advance)
  },
  setFocusedAdvanceId (state, advanceId) {
    state.focusedAdvanceId = advanceId
  },
  removeAdvance (state, advanceId) {
    state.advances = state.advances.filter(s => s.advanceId !== advanceId)
  },
  updateAdvance (state, advance) {
    const index = state.advances.findIndex(c => c.advanceId === advance.advanceId)
    if (index !== -1) {
      Object.assign(state.advances[index] = advance)
    }
  },
  setSelectedAdvanceId (state, advanceId) {
    state.selectedAdvanceId = advanceId
  },
  setAdvancesForAccount (state, advancesForAccount) {
    state.advancesForAccount = advancesForAccount
  },
  setAdvanceModifications (state, advanceModifications) {
    state.advanceModifications = advanceModifications
  },
  setAdvanceModification (state, advanceModification) {
    state.advanceModification = advanceModification
  },
  setAdvanceModificationsDialog (state, dialogObj) {
    state.advanceModificationsDialog.status = dialogObj.status
    state.advanceModificationsDialog.modifications = dialogObj.modifications
  }
}
