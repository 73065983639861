export default {
  setActivityDetails (state, activityDetails) {
    state.activityDetails = activityDetails
  },
  addActivityDetail (state, activityDetail) {
    state.activityDetails.push(activityDetail)
  },
  removeActivityDetail (state, activityDetailId) {
    state.activityDetails = state.activityDetails.filter(s => s.activityDetailId !== activityDetailId)
  },
  updateActivityDetail (state, activityDetail) {
    const index = state.activityDetails.findIndex(c => c.activityDetailId === activityDetail.activityDetailId)
    if (index !== -1) {
      Object.assign(state.activityDetails[index] = activityDetail)
    }
  }
}
