import { createAxiosObject } from './Configuration.js'
import { interceptor } from './Interceptor.js'
import { responseHandler } from './ResponseHandler.js'
import { errorHandler } from './ErrorHandler.js'

const ApiServer = createAxiosObject()

ApiServer.interceptors.request.use(interceptor)
ApiServer.interceptors.response.use(responseHandler, errorHandler)

export default ApiServer
