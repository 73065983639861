<template>
  <div style="display: inline-block">
    <v-menu
    v-show="dateConfiguration.selectedPayPeriod !== undefined"
    bottom
    left>
      <template #activator="{ on, attrs}">
        <v-icon
        color="secondary"
        v-bind="attrs"
        v-on="on">mdi-dots-vertical</v-icon>
      </template>
      <v-list>
        <v-list-item
        v-for="(action, index) in actions"
        :key="`accounting-action-${index}`"
        :disabled="action.disabled"
        @click="action.onClick(action.clickArgument)">
          <v-avatar left >
            <v-icon
            :color="action.isEnabled ? 'grey' : 'black'">
              {{action.icon}}
            </v-icon>
          </v-avatar>
          <v-list-item-title class="subtitle-1">
            {{action.text}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="csvDialog" width="400">
      <CSVExport
      v-if="csvDialog"
      :isByProducts="isByProducts"
      @close-csv-export="csvDialog = false"/>
    </v-dialog>
    <Dialog :stateId="dialogId" maxWidth="800">
      <SettlementSheetPayeeSelector
      v-if="bulkSettlementDeliveryDialog"
      :deliveryMethod="settlementSheetDeliveryMethod"
      @close="close"/>
    </Dialog>
  </div>
</template>

<script>
import { generateZippedSettlementReport } from '@/utils/pdf-formatters/ZippedSettlementsReport.js'
import { generateSinglePDFSettlementReport } from '@/utils/pdf-formatters/SinglePDFSettlementsReport.js'
import { createProductionSummaryPDF } from '@/utils/pdf-formatters/ProductionSummaryReport.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PayPeriodStatus, FinancialIntegrationType, ErrorSource, SettlementArchiveStatus, BulkSettlementDeliveryMode } from '@/utils/Enumerations.js'
import saveAs from 'file-saver'
import { fileNameForString } from '@/utils/pdf-formatters/GenericSettlementsFunctions.js'
import { SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
export default {
  name: 'SettlementActionMenu',

  components: {
    CSVExport: () => import('@/components/settlements/CSVExport.vue'),
    SettlementSheetPayeeSelector: () => import('@/components/settlements/SettlementSheetPayeeSelector.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  props: {
    isByProducts: {
      type: Boolean,
      required: true
    },
    isExporter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    csvDialog: false,
    bulkSettlementDeliveryDialog: false,
    zippedPdf: false,
    showRebuild: false,
    bulkEmail: false,
    settlementSheetDeliveryMethod: undefined,
    dialogId: uniqueDialogId('payeeSelector')
  }),

  computed: {
    ...mapGetters('settlements', ['currentModuleKey', 'currentSubModuleKey', 'dateConfiguration', 'currentSubModule']),
    ...mapGetters('user', ['companyInfo']),

    showIntegrationsOption () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined &&
        (this.companyInfo.financialIntegrationType === FinancialIntegrationType.BusinessCentralWithBinaryStream.value ||
          this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value || !this.isNullOrUndefined(this.dateConfiguration?.exportBatch?.secondaryBiPumpStatus))
    },

    showCsvOption () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined &&
        (this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDownloads.value ||
        this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value)
    },

    pdfDownloadDisabled () {
      if (this.currentSubModule === undefined) return true

      switch (this.currentSubModuleKey) {
        case 'settlementsByContract': return this.currentSubModule.settlements.length === 0
        case 'settlementsByPayee':
        case 'byproductSettlementsByPayee': return this.currentSubModule.payees.length === 0
        case 'productionSummary': return this.currentModuleKey !== 'accountsPayable'
        case 'register':
        case 'bpRegister': return !this.dateConfiguration.exportBatch.exportBatchId || this.currentSubModule.journalEntries.length === 0
        case 'receivableRegister':
        case 'bpReceivableRegister': return !this.dateConfiguration.exportBatch.exportBatchId || this.currentSubModule.receivables.length === 0
        case 'bpPaymentRegister':
        case 'paymentRegister': return !this.dateConfiguration.exportBatch.exportBatchId || this.currentSubModule.payments.length === 0
        default: return true
      }
    },

    singlePdfFileText () {
      switch (this.currentModuleKey) {
        case 'accountsPayable':
          switch (this.currentSubModuleKey) {
            case 'bpPaymentRegister':
            case 'paymentRegister': return this.$t('paymentRegisterPDF')
            case 'productionSummary': return this.$t('productionSummaryPDF')
            case 'byproductSettlementsByPayee':
            case 'settlementsByContract':
            case 'settlementsByPayee': return this.$t('settlementStatementPDF')
          }
        // eslint-disable-next-line no-fallthrough
        case 'accountsReceivable':
          switch (this.currentSubModuleKey) {
            case 'receivableRegister':
            case 'bpReceivableRegister': return this.$t('receivableRegisterPDF')
            case 'productionSummary': return this.$t('receivableSummaryPDF')
          }
        // eslint-disable-next-line no-fallthrough
        case 'accruals':
          switch (this.currentSubModuleKey) {
            case 'register':
            case 'bpRegister': return this.$t('registerPDF')
          }
        // eslint-disable-next-line no-fallthrough
        default: return this.$t('singlePDFFile')
      }
    },

    rateVerificationDisabled () {
      if (this.currentSubModule === undefined || this.currentModuleKey === undefined) return true
      if (this.currentSubModuleKey === 'settlementsByContract' && this.currentModuleKey === 'accountsPayable') return this.currentSubModule.settlements.length === 0
      if ((this.currentSubModuleKey === 'settlementsByPayee' || this.currentSubModuleKey === 'byproductSettlementsByPayee') && this.currentModuleKey === 'accountsPayable') return !this.currentSubModule.payees.some(p => p.contracts.length > 0)

      return true
    },

    actions () {
      let isExportDisabled = true
      let exportGenComplete = true
      const { payPeriod, exportBatch } = this.dateConfiguration
      if (payPeriod !== undefined && exportBatch !== undefined) {
        isExportDisabled = (payPeriod.status !== PayPeriodStatus.Closed.value) || !this.isExporter
        exportGenComplete = exportBatch.settlementArchiveStatus === SettlementArchiveStatus.Success.value || exportBatch.exportBatchId === undefined
      }

      const menuOptions = [
        {
          text: this.$t('zippedPDFFolder'),
          disabled: this.pdfDownloadDisabled || !exportGenComplete || (this.currentSubModuleKey !== 'settlementsByContract' && this.currentSubModuleKey !== 'settlementsByPayee' && this.currentSubModuleKey !== 'byproductSettlementsByPayee'),
          icon: 'mdi-folder-zip-outline',
          onClick: this.downloadPDFReport,
          clickArgument: true
        },
        {
          text: this.singlePdfFileText,
          disabled: this.pdfDownloadDisabled || !(exportGenComplete),
          icon: 'mdi-file-pdf-box',
          onClick: this.downloadPDFReport,
          clickArgument: false
        }
      ]

      if (exportBatch?.exportBatchId !== undefined) {
        menuOptions.push(
          {
            text: this.$t('bulkEmailDelivery'),
            disabled: this.pdfDownloadDisabled || !exportGenComplete || (this.currentSubModuleKey !== 'settlementsByContract' && this.currentSubModuleKey !== 'settlementsByPayee' && this.currentSubModuleKey !== 'byproductSettlementsByPayee'),
            icon: 'mdi-email-multiple',
            onClick: this.bulkEmailDialog
          }
        )
      }

      menuOptions.push(
        {
          text: this.$t('rateVerification'),
          icon: 'mdi-check-decagram',
          onClick: this.viewRateVerification,
          disabled: this.rateVerificationDisabled
        }
      )

      if (this.showCsvOption) {
        menuOptions.push(
          {
            text: this.$t('CSV'),
            icon: 'mdi-file-table-box',
            onClick: this.openCSVExport
          }
        )
      }

      if (this.showIntegrationsOption) {
        menuOptions.push(
          {
            text: this.$t('integrations'),
            icon: 'mdi-cloud-sync',
            onClick: this.openIntegrationsDialog
          }
        )
      }

      if (!this.isByProducts) {
        menuOptions.push(
          {
            text: this.getExportButtonText(),
            disabled: isExportDisabled,
            icon: 'mdi-export',
            onClick: this.exportPayPeriod
          },
          {
            text: this.$t('ticketsInPeriodPDF'),
            icon: 'mdi-printer',
            onClick: this.openTicketPDFPrint
          }
        )
      }

      const canRebuildArchiveData = exportBatch?.settlementArchiveStatus === SettlementArchiveStatus.Success.value && this.showRebuild
      if (canRebuildArchiveData) {
        menuOptions.push({
          text: this.$t('rebuildArchiveData'),
          icon: 'mdi-bulldozer',
          onClick: this.rebuildArchiveData
        })
      }
      return menuOptions
    }
  },

  created () {
    document.addEventListener('keydown', event => {
      if (event.ctrlKey && event.keyCode === 71) this.showRebuild = !this.showRebuild
    })
  },

  beforeDestroy () {
    document.removeEventListener('keydown', document)
  },

  methods: {
    ...mapActions('settlements', ['fetchByproductSettlementsByPayee', 'fetchSettlementsByPayee', 'downloadSubmoduleBlob', 'refreshModuleData']),
    ...mapMutations('global', ['setLoading']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    isNullOrUndefined (v) {
      return v === null || v === undefined
    },
    async downloadPDFReport (zipped) {
      switch (this.currentSubModuleKey) {
        case 'productionSummary':
          createProductionSummaryPDF(this.currentSubModule)
          break
        case 'settlementsByContract':
        case 'settlementsByPayee':
          this.handleSettlementSheetPdf(zipped)
          break
        case 'byproductSettlementsByPayee':
          this.handleSettlementSheetPdf(zipped)
          break
        case 'register':
        case 'bpRegister':
        case 'receivableRegister':
        case 'bpReceivableRegister':
        case 'bpPaymentRegister':
        case 'paymentRegister':
          this.handleRegisterPdf()
          break
        default:
          break
      }
    },

    async performLoadingAction (asyncLoadingAction) {
      this.setLoading(true)
      try {
        await asyncLoadingAction()
      } catch (error) {
        console.error(error)
        this.setSnackError({
          message: error.message,
          code: 'NO_DATA',
          source: ErrorSource.WebClient
        })
      } finally {
        this.setLoading(false)
      }
    },

    openCSVExport () {
      if (this.dateConfiguration.payPeriod === undefined) {
        return
      }

      if (this.dateConfiguration.exportBatch !== undefined) {
        this.csvDialog = true
        return
      }

      this.setSnackError(this.$t('unavailableCSV'))
    },

    exportPayPeriod () {
      this.$emit('export-button-pressed')
    },

    openTicketPDFPrint () {
      this.$emit('open-ticket-pdf-print')
    },

    openIntegrationsDialog () {
      this.$emit('open-integrations-dialog')
    },

    getExportButtonText () {
      if (this.dateConfiguration.payPeriod) {
        switch (this.dateConfiguration.payPeriod.status) {
          case PayPeriodStatus.Closed.value:
            return this.$t('exportPayPeriod')
          case PayPeriodStatus.Open.value:
            return this.$t('cannotExport')
          case PayPeriodStatus.Inactive.value:
            return this.$t('notAvailable')
          case PayPeriodStatus.Exported.value:
            return this.$t('exported')
          default:
            return 'EXCEPTION'
        }
      } else {
        return this.$t('cannotExport')
      }
    },

    viewRateVerification () {
      this.$emit('open-rate-verification-dialog')
    },

    rebuildArchiveData () {
      this.$emit('rebuild-archive-data', this.dateConfiguration?.exportBatch)
    },

    handleSettlementSheetPdf (zipped) {
      const exportBatch = this.dateConfiguration?.exportBatch
      if (exportBatch.exportBatchId !== null && exportBatch.exportBatchId !== undefined) {
        this.settlementSheetDeliveryMethod = zipped ? BulkSettlementDeliveryMode.ZippedFolder : BulkSettlementDeliveryMode.SinglePdf
        this.bulkSettlementDeliveryDialog = true
        this.openOrUpdateDialog({ id: this.dialogId, width: 400 })
      } else {
        this.performLoadingAction(async () => {
          const settlementsByPayee = this.isByProducts ? await this.fetchByproductSettlementsByPayee() : await this.fetchSettlementsByPayee()
          const { blob, filename } = zipped
            ? await generateZippedSettlementReport(settlementsByPayee, { exportBatch })
            : await generateSinglePDFSettlementReport(settlementsByPayee, { exportBatch })
          saveAs(blob, filename)
        })
      }
    },

    bulkEmailDialog () {
      this.settlementSheetDeliveryMethod = BulkSettlementDeliveryMode.Email
      this.bulkSettlementDeliveryDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: 400 })
    },

    async handleRegisterPdf () {
      const { exportBatch } = this.dateConfiguration
      let moduleSegment = ''
      switch (this.currentSubModuleKey) {
        case (SettlementSubKeys.AP.PaymentRegister):
        case (SettlementSubKeys.AP.BPPaymentRegister):
          moduleSegment = this.$t('payables')
          break
        case (SettlementSubKeys.AR.ReceivableRegister):
        case (SettlementSubKeys.AR.BPReceivableRegister):
          moduleSegment = this.$t('receivables')
          break
        case (SettlementSubKeys.AC.Register):
        case (SettlementSubKeys.AC.BPRegister):
          moduleSegment = this.$t('accruals')
      }
      const filename = `${this.$t(this.isByProducts ? 'byproducts' : 'logs')}${moduleSegment}_${fileNameForString(exportBatch.label)}`
      await this.downloadSubmoduleBlob({ accountId: undefined, filename })
    },

    async close ({ refresh = false }) {
      this.bulkSettlementDeliveryDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
      this.settlementSheetDeliveryMethod = undefined
      if (refresh) await this.refreshModuleData()
    }
  }
}
</script>
