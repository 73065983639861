<template>
  <v-app style="background-color: #F9F6DF">
    <v-main>
      <GlobalSnackbar/>
      <div v-if="initialized && !initializing">
        <core-toolbar id="core-toolbar" @toggleDrawer="openDrawer"/>
        <Drawer id="core-drawer" :propDrawer="drawer" @drawerChanged="handleDrawerChanged"/>
        <core-view class="mt-n12 mb-13" id="core-view"/>
        <core-footer id="core-footer" />
      </div>
      <v-container v-else fluid>
        <v-row align="center" justify="center" style="height: 100vh;">
          <Login
            v-if="!initialized && !initializing"
            @open-multi-tenant-dialog="configureApplication"
          />
          <v-progress-circular indeterminate color="secondary" v-if="initializing"></v-progress-circular>
        </v-row>
      </v-container>
      <v-dialog v-model="multiTenantDialog" persistent width="400px">
        <MultiTenantSelection
        :configuration="MultiTenantHandler"
        @close="multiTenantDialog = false"
        @set-tenant="setTenant"
        />
      </v-dialog>
      <SignOutDialog/>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MultiTenantHandler from './msal-browser/MultiTenantHandler'
import { ErrorCodes } from '@/utils/constants.js'
export default {
  name: 'App',

  components: {
    Drawer: () => import('@/components/core/drawer/Drawer'),
    Login: () => import('@/views/Login.vue'),
    GlobalSnackbar: () => import('@/components/helper/Snack.vue'),
    MultiTenantSelection: () => import('@/components/core/MultiTenantSelection.vue'),
    SignOutDialog: () => import('@/components/core/SignOutDialog.vue')
  },

  data: () => ({
    multiTenantDialog: false,
    MultiTenantHandler,
    drawer: false
  }),

  computed: {
    ...mapGetters('user', ['initializing', 'initialized'])
  },

  mounted () {
    this.addEnvConfigFile()
    this.configureApplication()
  },

  methods: {
    ...mapMutations('user', ['setInitializing']),
    ...mapActions('user', ['initializeApplication']),

    async configureApplication () {
      try {
        await MultiTenantHandler.initialize()

        if (MultiTenantHandler.isMultiTenant && !MultiTenantHandler.isTenantSet && MultiTenantHandler.isInitialized) {
          this.multiTenantDialog = true
        } else {
          await this.initializeApplication()
        }
      } catch (error) {
        this.handleInitializationError(error)
      }
    },

    addEnvConfigFile () {
      const configFile = document.createElement('script')
      configFile.setAttribute('src', '../../../config.js')
      configFile.setAttribute('type', 'application.javascript')
      document.head.appendChild(configFile)
    },

    async handleInitializationError (error) {
      let errorSnack
      switch (error.response?.status) {
        case 403:
          const subtenants = await MultiTenantHandler.getSubtenants()
          errorSnack = {
            message: subtenants.length > 0 ? this.$t('noValidBusinessesDefined') : this.$t('invalidMicrosoftAccount'),
            code: 403
          }
          break
        case 401:
          errorSnack = {
            message: this.$t('invalidOrExpiredToken'),
            code: 401
          }
          break
      }

      if (error.message === ErrorCodes.INVALID_MT_CONFIGURATION) {
        errorSnack = {
          message: this.$t('invalidMultiTenantConfiguration'),
          code: ErrorCodes.INVALID_MT_CONFIGURATION
        }
      }

      if (error.message === ErrorCodes.NO_TENANTS_CONFIGURED) {
        errorSnack = {
          message: this.$t('noValidBusinessesDefined'),
          code: ErrorCodes.NO_TENANTS_CONFIGURED
        }
      }

      if (error.message === ErrorCodes.ADMIN_CONSENT_NOT_GRANTED) {
        errorSnack = {
          message: this.$t('adminConsentNotGranted'),
          code: ErrorCodes.ADMIN_CONSENT_NOT_GRANTED
        }
      }

      if (errorSnack) {
        this.setSnackError(errorSnack)
      }
    },

    setTenant (tenant) {
      MultiTenantHandler.setTenant(tenant)
      this.multiTenantDialog = false

      this.initializeApplication()
    },

    openDrawer () {
      this.drawer = true
    },

    handleDrawerChanged (val) {
      this.drawer = val
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import '../src/styles/material-dashboard/_tables.css';
@import '../src/styles/material-dashboard/_footer.css';
@import '../src/styles/material-dashboard/_typography.css';
@import '../src/styles/material-dashboard/_sidebar.css';
@import '../src/styles/material-dashboard/_toolbar.css';
@import '../src/styles/material-dashboard/_misc.css';
@import '../src/styles/material-dashboard/_inputs.css';
@import '../src/styles/material-dashboard/_cards.css';
@import '../src/styles/material-dashboard/_tabs.css';
@import '../src/styles/material-dashboard/_checkboxes.css';
@import '../src/styles/material-dashboard/_tooltips.css';
@import '../src/styles/material-dashboard/_buttons.css';
@import '../src/styles/material-dashboard/_alerts.css';
@import '../src/styles/material-dashboard/_fixed-plugin.css';
@import '../src/styles/material-dashboard/_dropdown.css';
@import '../src/styles/misc-global.css';
#app {
  font-family: 'IBM Plex Sans', sans-serif;
}

@media (hover: none) and (pointer: coarse) {
  .v-tooltip__content {
    display: none;
  }
}

#core-view {
  height: 90vh;
}

#core-footer {
  height: 5vh;
}

.lg_expansion-panel_wrapper {
  max-height: 60vh;
  overflow-y: auto;
}

.v-expansion-panel--active .v-expansion-panel-content {
  border-right: 3px #D15F27 solid;
  border-left: 3px #D15F27 solid;
  border-bottom: 3px #D15F27 solid;
}

.v-expansion-panel-content {
  border-width: 0px
}

.v-expansion-panel-header {
  height: 75px;
  font-size: 20px;
  background-color: #f7f7f7;
  color: black;
  border-bottom: 1px solid #e0e0e0
}

.v-expansion-panel-header:hover {
  background-color: #e4e4e4;
}

.v-expansion-panel-header--active,
.v-expansion-panel-header--active:hover {
  color: white;
  background-color: #D15F27;
  border-bottom: 0px
}

tbody tr:nth-of-type(even) {
    background-color: white;
  }

tbody tr:nth-of-type(odd) {
  background-color: rgba(247, 247, 247);
}

.v-date-picker-table td {
  background-color: #424242;
}

.v-date-picker-table td .v-btn:hover {
  background-color: #858585;
}

.v-date-picker-table .v-btn.v-btn--active {
  background-color: #D15F27 !important;
  color: white !important;
}

.v-date-picker-table .v-btn.v-date-picker-table__current {
  color: white;
  border: 1px solid #D15F27;
}

.v-date-picker-table .v-btn.v-date-picker-table__current.v-btn--active div {
  color: white !important;
  border: 1px solid #D15F27 !important;
}

.v-date-picker-table .v-btn.v-date-picker-table__current div{
  color: #D15F27;
}
</style>
