<template>
  <v-container fluid>
    <v-row>
      <v-col xs12>
        <material-card
        color="secondary"
        :title="$t('userPermissions')"
        :text="$t('userPermissionsSubtitle')"
        class="padding-3"
        full-width>
          <v-container fluid>
            <v-row>
              <UsersByRole
              class="mt-4"
              :claimsByUser="claimsByUser"
              @assign-user="assignUserToRole"
              @refresh-roles="getClaims()"/>
            </v-row>
          </v-container>
        </material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="assignRoleDialog" width="600px">
      <AssignUserToRole
      v-if="assignRoleDialog"
      :role="focusedRole"
      @close="closeDialogs()"
      @assign-user="assignUser"
      @refresh-users="refreshUsers()"
      :availableUsers="usersWithoutFocusedRole"/>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaimStatus } from '@/utils/Enumerations.js'
export default {
  name: 'UserPermissions',

  components: {
    UsersByRole: () => import('@/components/settings-components/user-permissions/UsersByRole.vue'),
    AssignUserToRole: () => import('@/components/settings-components/user-permissions/AssignUserToRole.vue')
  },

  data: () => ({
    users: [],
    claimsByUser: [],
    assignRoleDialog: false,
    focusedRole: undefined
  }),

  computed: {
    ...mapGetters('user', ['userClaims']),

    usersWithoutFocusedRole () {
      return this.users.filter(u => {
        const userWithClaims = this.claimsByUser.find(uc => uc.applicationUserId === u.applicationUserId)
        const claim = userWithClaims?.userClaims.find(c => c.systemClaim === this.focusedRole.value)
        return !claim || claim.value === UserClaimStatus.NotSet.value
      })
    }
  },

  async created () {
    await this.getClaims()
  },

  methods: {
    ...mapActions('user', ['fetchAllUserClaims', 'getAllUsers', 'updateUserClaims']),
    async getClaims () {
      const claimsByUser = await this.fetchAllUserClaims()
      this.claimsByUser = claimsByUser
      await this.refreshUsers()
    },

    closeDialogs () {
      this.assignRoleDialog = false
      this.focusedRole = undefined
    },

    assignUserToRole (role) {
      this.assignRoleDialog = true
      this.focusedRole = role
    },

    async assignUser (user) {
      await this.updateUserClaims({
        id: user.applicationUserId,
        claims: [
          { systemClaim: this.focusedRole.value, value: UserClaimStatus.Allow.value }
        ]
      })
      await this.getClaims()
      this.closeDialogs()
    },

    async refreshUsers () {
      this.users = await this.getAllUsers()
    }
  }
}
</script>
