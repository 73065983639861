<template>
    <v-container fluid grid-list-lg :data-testid="dataTestId">
    <v-row dense>
      <v-col md="12">
        <material-card
          :color="color"
          :offsetTop="24"
          :offsetBottom="24"
          full-width
        >
          <template #header>
            <v-row dense>
              <v-col style="min-width:50%">
                <p class="headline font-weight-regular mb-2" v-text="title" data-testid="bp-card-heading"/>
                <p class="category font-weight-thin" v-text="subtitle"/>
              </v-col>
              <v-col cols="auto" class="mt-3">
                <v-select
                v-if="mode !== null"
                v-model="mode"
                :items="contractModes"
                :menu-props="{ offsetY: true }"
                dense
                id="select"
                data-testid="contract-mode-select"
                item-text="text"
                outlined
                return-object
                class="mb-n6"
                style="width:160px;"
                ref="BpCardSelector"
                @change="onSelection"
                @keyup="onSelection"
                >
                  <template #selection="{item}">
                    <v-icon small color="white" class="mr-2">{{item.icon}}</v-icon>
                    <span>{{item.text}}</span>
                  </template>
                  <template #item="{item}">
                    <v-row @click="onSelection" no-gutters class="py-2 my-n2 px-4 mx-n4">
                      <v-icon small :color="item.color" class="mr-2">{{item.icon}}</v-icon>
                      <span>{{item.text}}</span>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>
          <slot/>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ContractModes } from '@/utils/Enumerations.js'
export default {
  name: 'BPCard',

  props: {
    headerConfig: {
      type: Object
    },
    contractMode: {
      type: Object
    },
    hideTransfers: {
      type: Boolean,
      default: false
    },
    dataTestId: {
      type: String,
      required: false,
      default: 'bp-card'
    }
  },

  computed: {
    title () {
      return this.headerConfig?.title ?? ''
    },

    contractModes () {
      return ContractModes.filter(mode => mode.value !== 2 || !this.hideTransfers)
    },

    subtitle () {
      return this.headerConfig?.subtitle ?? ''
    },

    color () {
      return this.contractMode?.color ?? 'secondary'
    },

    mode: {
      get () { return this.contractMode },
      set (value) { this.$emit('update:contractMode', value) }
    },

    selectorKeys () {
      return ['enter', ...ContractModes.map(cm => cm.text.charAt(0).toLowerCase())]
    }
  },

  methods: {
    onSelection (e = undefined) {
      if (this.selectorKeys.includes(e?.key?.toLowerCase()) || !e?.key) {
        this.$refs.BpCardSelector.blur()
      }
    }
  }

}
</script>

<style scoped>
.v-select {
  width: 200px;
}
</style>
