import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchContractApprovals (_, contractId) {
    const path = `contracts/${contractId}/contractapprovals`
    const response = await ApiServer.get(path)
    return response.data
  },

  async getMostRecentContractApproval (_, contractId) {
    const path = `contracts/${contractId}/activecontractapproval`
    const response = await ApiServer.get(path)
    return response.data
  },

  async createContractApproval (_, requestObj) {
    const path = `contracts/${requestObj.contractId}/contractapprovals`
    try {
      const response = await ApiServer.post(path, requestObj)
      return response?.data
    } catch (e) {
      console.error(e)
    }
  },

  async updateContractApproval (_, requestObj) {
    const path = `contracts/${requestObj.contractId}/contractapprovals/${requestObj.contractApprovalId}`
    const response = await ApiServer.put(path, requestObj)
    return response.data
  }
}
