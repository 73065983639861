<template>
  <v-row>
    <v-col cols="12">
      <v-tabs
      v-model="activeTab"
      background-color="white"
      color="#888888"
      slider-color="secondary"
      slider-size="4">
        <v-tab active-class="active-tab">
          {{ $t('shippingAddresses') }}
        </v-tab>
        <v-tab active-class="active-tab">
          {{ $t('billingAddresses') }}
        </v-tab>
        <v-tab active-class="active-tab">
          {{ $t('contacts') }}
        </v-tab>
        <v-spacer/>
        <Icon
        icon="mdi-plus"
        tooltipColor="secondary"
        :tooltipText="addButtonText"
        :small="false"
        tabindex="0"
        @icon-clicked="showCreateDialog(activeTab)"/>
        <v-tab-item disabled>
          <v-container fluid>
            <v-col cols="12">
              <v-row v-if="shippingAddresses.length > 0">
                <v-col v-for="(address, index) in shippingAddresses" :key="`shipping-${index}`" cols="12" lg="4">
                  <AddressCard
                  :address="address"
                  :accountId="account.accountId"
                  @edit-address-clicked="editAddress"
                  @delete-address-clicked="addressDeleted"/>
                </v-col>
              </v-row>
              <v-row v-else>
                {{ $t('noShippingAddressesAdded') }}
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
        <v-tab-item disabled>
          <v-container fluid>
            <v-col cols="12">
              <v-row v-if="billingAddresses.length > 0">
                <v-col v-for="(address, index) in billingAddresses" :key="`shipping-${index}`" cols="12" lg="4">
                  <AddressCard
                  :address="address"
                  :accoundId="account.accountId"
                  @edit-address-clicked="editAddress"
                  @delete-address-clicked="addressDeleted"/>
                </v-col>
              </v-row>
              <v-row v-else>
                {{ $t('noBusinessAddressesAdded') }}
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
        <v-tab-item disabled>
          <v-container fluid>
            <v-col cols="12">
              <v-row v-if="account.contacts.length > 0">
                <v-col v-for="(contact, index) in account.contacts" :key="index" cols="12" lg="4">
                  <ContactCard
                  showCrud
                  :contact="contact"
                  @click:edit="editContact"
                  @click:delete="deleteContact"/>
                </v-col>
              </v-row>
              <v-row v-else>
                {{ $t('noContactsAdded') }}
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-dialog v-model="addressFormDialog" max-width="60%">
      <AddressForm
      :type="addressType"
      v-if="addressFormDialog"
      :propAddress="focusedAddress"
      @address-created="addressCreated"
      @address-updated="addressUpdated"
      @close="closeDialogs"/>
    </v-dialog>
    <v-dialog v-model="contactFormDialog" width="800px">
      <ContactForm
      v-if="contactFormDialog"
      @close="closeDialogs"
      :propContact="focusedContact"
      :dtrDisabled="account?.isExternal !== undefined && !account.isExternal"
      @contact-updated="contactUpdated"
      @contact-created="contactCreated"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { AddressType } from '@/utils/Enumerations.js'
export default {
  name: 'AccountTabs',

  components: {
    AddressCard: () => import('./address/AddressCard.vue'),
    AddressForm: () => import('./address/AddressForm.vue'),
    ContactForm: () => import('@/components/account/contacts/ContactForm.vue'),
    ContactCard: () => import('@/components/account/contacts/ContactCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    activeTab: 0,

    addressFormDialog: false,
    addressType: AddressType.ShipTo.value,
    focusedAddress: undefined,

    contactFormDialog: false,
    focusedContact: undefined
  }),

  watch: {
    addressFormDialog (val) {
      if (!val) {
        this.focusedAddress = undefined
      }
    },

    contactFormDialog (val) {
      if (!val) {
        this.focusedContact = undefined
      }
    }
  },

  computed: {
    shippingAddresses () {
      return this.account.addresses.filter(a => a.address.type === AddressType.ShipTo.value)
    },
    billingAddresses () {
      return this.account.addresses.filter(a => a.address.type === AddressType.BillTo.value)
    },
    addButtonText () {
      return this.activeTab === 0 || this.activeTab === 1
        ? this.$t('newAddress')
        : this.$t('newContact')
    }
  },

  methods: {
    showCreateDialog (tab) {
      if (tab === 0 || tab === 1) {
        this.addressType = tab
        this.addressFormDialog = true
      } else {
        this.contactFormDialog = true
      }
    },

    editAddress (address) {
      this.addressType = address.address.type
      this.focusedAddress = address
      this.addressFormDialog = true
    },

    addressDeleted (address) {
      const index = this.findIndexWithProperties(address)
      const payload = {
        address: address,
        index: index
      }
      this.$emit('address-deleted', payload)
    },

    addressCreated (addresses) {
      this.$emit('address-created', [...addresses])
      this.closeDialogs()
    },

    addressUpdated (address) {
      const index = this.findIndexWithProperties(this.focusedAddress.address)
      const payload = {
        address: address,
        index: index
      }
      this.$emit('address-updated', payload)
      this.closeDialogs()
    },

    editContact (contact) {
      this.focusedContact = contact
      this.contactFormDialog = true
    },

    deleteContact (contact) {
      const index = this.findContactWithInfo(contact.person)
      this.$emit('contact-deleted', {
        contact: contact,
        index: index
      })
    },

    contactCreated (contact) {
      this.$emit('contact-created', contact)
      this.closeDialogs()
    },

    contactUpdated (contact) {
      const index = this.findContactWithInfo(this.focusedContact.person)
      this.$emit('contact-updated', {
        contact: contact,
        index: index
      })
      this.closeDialogs()
    },

    closeDialogs () {
      this.addressFormDialog = false
      this.contactFormDialog = false
    },

    findIndexWithProperties (address) {
      return this.account.addresses.findIndex(a => {
        return a.address.addressLine1 === address.addressLine1 &&
        a.address.addressLine2 === address.addressLine2 &&
        a.address.postalCode === address.postalCode &&
        a.address.city === address.city &&
        a.address.state === address.state &&
        a.address.type === address.type
      })
    },

    findContactWithInfo (contact) {
      return this.account.contacts.findIndex(c => {
        return c.person.firstName === contact.firstName &&
        c.person.lastName === contact.lastName &&
        c.person.email === contact.email &&
        c.person.phoneNumber === contact.phoneNumber
      })
    }
  }
}
</script>
