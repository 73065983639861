<template>
  <v-container fluid>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs" maxWidth="80vw">
      <ContractDetails v-if="focusedContractId" :contractId="focusedContractId"/>
      <TicketDetails v-if="focusedTicketId" :ticketId="focusedTicketId" :contractMode="contractMode"/>
      <PayablesByTract v-if="focusedAccountPayable"  @payable-detail="$emit('payable-detail')" :accountPayable="focusedAccountPayable" isTDialog/>
    </Dialog>
    <v-row>
      <v-col>
        <DataTable
        :loading="loading"
        :headers.sync="payableHeaders"
        :items.sync="payables"
        :customCells="customCells"
        :showDense="false"
        :actions="tableActions"
        @toggle="toggleAllPayables"
        @filtered-items="setFilteredPayables"
        >
            <template #actions="{item}" v-if="showingAllPayables">
                <Icon
                icon="mdi-clipboard-outline"
                iconColor="gray"
                dataTestId="payable-view-ticket"
                :tooltipText="$t('viewTicket')"
                @icon-clicked="actionButtonPressed(item, 'ticket')"/>
                <Icon
                icon="mdi-altimeter"
                dataTestId="payable-view-contract"
                :tooltipText="$t('viewContract')"
                @icon-clicked="actionButtonPressed(item, 'contract')"/>
            </template>
            <template #actions="{item}" v-else-if="contractMode?.value === 0">
              <Icon
              icon="mdi-clipboard-text"
              dataTestId="payable-view-tract"
              :small=false
              :tooltipText="$t('viewPayablesByTract')"
              @icon-clicked="actionButtonPressed(item, 'tract')"
              />
            </template>

            <!-- Payables By Account Custom Cells -->
            <template #total-tickets="{item}">{{
              item.ticketCount
            }}</template>
            <template #total-net-weight="{item}">{{
              tonStringFromPounds(item.totalNetWeight)
            }}</template>
            <template #total-payable="{item}">{{
              formatMoney(item.totalPayable)
            }}</template>
            <template #average-rate="{item}">{{
              formatMoney(item.averageRate)
            }}</template>
            <!-- All Payables Custom Cells -->
            <template #in-weight="{item}">{{
              tonStringFromPounds(item.inWeight)
            }}</template>
            <template #out-weight="{item}">{{
              tonStringFromPounds(item.outWeight)
            }}</template>
            <template #defect-weight="{item}">{{
              tonStringFromPounds(item.defectWeight)
            }}</template>
            <template #net-weight="{item}">{{
              tonStringFromPounds(item.netWeight)
            }}</template>
            <template #product="{item}">{{
              item.product ? item.product : 'Other'
            }}</template>
            <template #pay-on="{item}">
              {{payOn(item.computedAtPayOn)}}
            </template>
            <template #pay-by="{item}">
              {{payBy(item.computedAtPayBy)}}
            </template>
            <template #amount="{item}">{{
              `${formatMoney(item.amount)} ${item.grossAmount === item.amount ? '' : `(${formatMoney(item.grossAmount)})`}`
            }}</template>
            <template #rate="{item}">
              <span>{{ formatMoney(item.computedAtRate) }}</span>
            </template>
            <template #activityWithSplit="{item}">
              <span>
                {{ item.activityName }}
              </span>
              <span v-if="item.computedAtSplit">
                ({{ formatPercentage(item.computedAtSplit) }})
              </span>
          </template>
            <template #body.append>
              <tr class="font-weight-bold">
                <td class="text-left" v-if="showingAllPayables && contractMode?.value === 0"/>
                <td class="text-left" v-if="showingAllPayables"/>
                <td class="text-left"/>
                <td class="text-right">
                  <span v-if="!showingAllPayables">
                    {{totalUniqueTicketCount}}
                  </span>
                </td>
                <td class="text-right" v-if="!showingAllPayables">
                  {{tonStringFromPounds(totalNetWeight)}}
                </td>
                <td class="text-right">
                  <span v-if="!showingAllPayables"> {{`${formatMoney(totalPayable)} ${totalGross === totalPayable ? '' : `(${formatMoney(totalGross)})`}`}} </span>
                </td>
                <td class="text-right"/>
                <td class="text-right" v-if="showingAllPayables"/>
                <td class="text-right" v-if="showingAllPayables">
                  {{tonStringFromPounds(totalNetWeight)}}
                </td>
                <td class="text-right" v-if="showingAllPayables"/>
                <td class="text-right" v-if="showingAllPayables"/>
                <td class="text-right" v-if="showingAllPayables"/>
                <td class="text-right" v-if="showingAllPayables">
                  {{`${formatMoney(totalPayable)} ${totalGross === totalPayable ? '' : `(${formatMoney(totalGross)})`}`}}
                </td>
                <td v-if="payableHeaders[payableHeaders.length-1].text == 'Actions'"/>
              </tr>
            </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney, numberWithCommas, tonStringFromPounds, formatPercentage } from '@/utils/NumericMutations'
import { allPayablesCustomCells, payablesByAccountCustomCells } from '@/components/accounting/accounts-payable/PayableCustomCells.js'
import PayableHeaders, { allPayableHeaders, allBPPayableHeaders } from '@/headers/Payable'
import { PayOn, PayBy } from '@/utils/Enumerations.js'
import { mapActions, mapMutations } from 'vuex'
import AccountingHelpers from '@/components/accounting/AccountingHelpers.js'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
export default {
  name: 'CurrentPayables',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ContractDetails: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    TicketDetails: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    PayablesByTract: () => import('@/components/accounting/accounts-payable/PayablesByTractDialog.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propPayables: {
      type: Array
    },
    isByProducts: {
      type: Boolean,
      required: false,
      default: false
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    loading: false,
    dialogId: uniqueDialogId('current-payables'),
    showingAllPayables: false,
    focusedPayableId: undefined,
    focusedTicketId: undefined,
    focusedContractId: undefined,
    focusedAccountPayable: undefined,
    filteredPayables: []
  }),

  computed: {
    payables () {
      if (this.showingAllPayables) {
        return this.propPayables.map(p => ({
          ...p,
          activityWithSplit: {
            name: p.activityName,
            split: p.computedAtSplit
          }
        }))
      } else {
        return this.payablesByAccount
      }
    },

    payablesByAccount () {
      const payablesByAccount = AccountingHelpers.groupAccountingItemsByAccountName(this.propPayables, 'payables')
      payablesByAccount.forEach(pba => {
        pba.totalPayable = (pba.payables.reduce((a, b) => a + b.amount, 0))
        pba.totalNetWeight = AccountingHelpers.getUniqueNetWeight(pba.payables)
        pba.averageRate = (pba.totalPayable / (pba.totalNetWeight / 2000))
        pba.ticketCount = AccountingHelpers.uniqueTicketCount(pba.payables)
      })

      return payablesByAccount
    },
    totalUniqueTicketCount () { return AccountingHelpers.uniqueTicketCount(this.filteredPayables) },
    totalNetWeight () { return AccountingHelpers.getUniqueNetWeight(this.filteredPayables) },
    totalGross () { return this.filteredPayables.reduce((a, b) => a + b.grossAmount, 0) },
    totalPayable () { return this.filteredPayables.reduce((a, b) => a + b.amount, 0) },

    payableHeaders () {
      const accountPayableHeader = PayableHeaders.payablesByAccountHeaders(this.$i18n.locale)
      if (this.contractMode.value === 1 || this.contractMode.value === 2) accountPayableHeader.pop()
      if (this.isByProducts) accountPayableHeader.pop()
      const allPayableHeader = this.contractMode?.value !== 0 ? allBPPayableHeaders(this.$i18n.locale) : allPayableHeaders(this.$i18n.locale)
      return this.showingAllPayables
        ? allPayableHeader
        : accountPayableHeader
    },

    tableActions () {
      return [
        {
          icon: this.showingAllPayables ? 'mdi-account' : 'mdi-view-list',
          text: this.showingAllPayables ? this.$t('viewByAccount') : this.$t('viewAllPayables'),
          actionName: 'toggle'
        }
      ]
    },

    customCells () {
      return this.showingAllPayables
        ? allPayablesCustomCells()
        : payablesByAccountCustomCells()
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),

    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    formatPercentage,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),

    toggleAllPayables () {
      this.showingAllPayables = !this.showingAllPayables
    },

    resetDialogs () {
      this.focusedContractId = undefined
      this.focusedTicketId = undefined
      this.focusedAccountPayable = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    actionButtonPressed (payable, dialogType) {
      this.resetDialogs()
      switch (dialogType) {
        case 'contract': this.focusedContractId = payable.contractId; break
        case 'ticket': this.focusedTicketId = payable.ticketId; break
        case 'tract': this.focusedAccountPayable = payable; break
        default: return
      }

      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    setFilteredPayables (filteredPayables) {
      if (!this.showingAllPayables) {
        this.filteredPayables = []
        for (const account in filteredPayables) {
          this.filteredPayables = this.filteredPayables.concat(filteredPayables[account].payables)
        }
      } else {
        this.filteredPayables = filteredPayables
      }
    }
  }
}
</script>
