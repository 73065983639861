import { TimberType, WoodType } from '../utils/Enumerations'
import i18n from '../i18n'

export default {
  productHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('productName'),
        value: 'name'
      }
    ])
  },
  productCRUDHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('woodType'),
        align: 'center',
        value: 'woodType'
      },
      {
        sortable: true,
        text: i18n.t('timberType'),
        align: 'center',
        value: 'timberType'
      },
      {
        sortable: true,
        text: i18n.t('conversionRate'),
        value: 'conversionRateW2V',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('requiresPieces'),
        align: 'center',
        value: 'collectPieceInfo'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  },
  byProductCRUDHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('woodType'),
        align: 'center',
        value: 'woodType'
      },
      {
        sortable: true,
        text: i18n.t('timberType'),
        align: 'center',
        value: 'timberType'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  },
  productBulkImportPreviewHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('code'),
        value: 'code'
      },
      {
        sortable: true,
        text: i18n.t('woodType'),
        align: 'center',
        value: 'woodType',
        format: i => WoodType.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('timberType'),
        align: 'center',
        value: 'timberType',
        format: i => TimberType.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('conversionRate'),
        value: 'conversionRateW2V',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('requiresPieces'),
        align: 'center',
        value: 'collectPieceInfo'
      }
    ])
  }
}
