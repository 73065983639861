import messages from '@/lang'
import i18n from '../i18n'

export const rules = {
  required: value => !!value || messages[i18n.locale].required,
  validEmail: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || messages[i18n.locale].invalidEmail
  },
  phoneNumber: value => {
    const pattern = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/
    return pattern.test(value) || messages[i18n.locale].invalidPhoneNumber
  },
  validWeightPounds: value => {
    const valueInt = parseInt(value)
    return ((Number.isInteger(parseFloat(value)) &&
    value !== '' &&
    (valueInt >= 0 && valueInt <= 500000))) ||
    messages[i18n.locale].invalidPoundsValue
  },
  validWeightTons: value => {
    const valueFloat = parseFloat(value)
    return ((typeof (valueFloat) === 'number' &&
    value !== '' &&
    (valueFloat >= 0 && valueFloat <= 250))) ||
    messages[i18n.locale].invalidTonsValue
  },
  validGuid: value => {
    const pattern = /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/
    return pattern.test(value)
  }
}
