<template>
  <v-select
  v-model="selectedDeck"
  :data-testid="dataTestId"
  :items="decks"
  :label="label"
  :menu-props="{ bottom: true, offsetY: true }"
  item-text="name"
  item-value="name"
  return-object
  color="black"
  item-color="secondary"
  >
    <template #item="{item}">
      <span class="mr-2">
        {{item.name}}
      </span>
      <Icon
      :icon="getDeckTooltip(item).icon"
      :tooltipText="getDeckTooltip(item).text"
      :small="true"
      iconColor="black"
      tooltipColor="black"
      propagateClick
      />
    </template>
    <template #selection="{item}">
      <span class="mr-2">
        {{item.name}}
      </span>
      <Icon
      :icon="getDeckTooltip(item).icon"
      :tooltipText="getDeckTooltip(item).text"
      :small="true"
      iconColor="black"
      tooltipColor="black"
      propagateClick
      />
    </template>
  </v-select>
</template>

<script>
import { DeckStatus, ConsumptionMode } from '@/utils/Enumerations'
export default {
  name: 'DeckSelect',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    accountId: {
      required: true,
      type: Number
    },
    label: {
      type: String,
      default: () => (this.$t('selectDeck'))
    },
    propDeckId: {
      type: Number,
      default: undefined
    },
    locations: {
      type: Array,
      required: true
    },
    dataTestId: {
      type: String,
      default: () => ('deck-select')
    },
    weightBasedOnly: {
      type: Boolean,
      default: true
    },
    contentType: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    selectedDeck: undefined
  }),

  computed: {
    decks () {
      const chosenLocation = this.locations.find(location => location.accountId === this.accountId)
      return (chosenLocation?.decks ?? [])
        .filter(d => d.consumptionMode === ConsumptionMode.WeightBased.value || this.weightBasedOnly === false)
        .filter(d => !this.contentType || d.contentType === this.contentType.value)
        .filter(d => d.status === DeckStatus.Open.value || d.deckId === this.propDeckId)
    }
  },

  watch: {
    selectedDeck (val) {
      this.$emit('deck-selected', val)
    },

    accountId: {
      handler (val) {
        if (val && this.decks.length === 1) {
          this.selectedDeck = this.decks[0]
        } else {
          this.selectedDeck = this.decks.find(d => d.isDefault === true)
        }
      },
      immediate: true
    }
  },

  mounted () {
    if (this.propDeckId !== undefined) {
      this.selectedDeck = this.decks.find(deck => deck.deckId === this.propDeckId)
    }
  },

  methods: {
    getDeckTooltip ({ consumptionMode }) {
      switch (consumptionMode) {
        case 0:
          return {
            text: this.$t('ticketBased'),
            icon: 'mdi-clipboard-outline'
          }
        case 1:
          return {
            text: this.$t('weightBased'),
            icon: 'mdi-weight'
          }
        default:
          return {
            text: this.$t('ticketBased'),
            icon: 'mdi-clipboard-outline'
          }
      }
    }
  }
}
</script>
