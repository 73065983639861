import i18n from '../i18n'
import { ActivityModifier, PayBy, PayOn, CostType, TemplateAccountingCategory } from '@/utils/Enumerations'
import { formatMoney } from '@/utils/NumericMutations'
export default {
  activityNoCRUDHeaders () {
    return ([
      {
        sortable: false,
        text: '',
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'activityTemplate.name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'activityTemplate.category'
      },
      {
        sortable: true,
        text: i18n.t('counterparty'),
        value: 'contractor.name'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'activityTemplate.glCode'
      },
      {
        sortable: true,
        text: i18n.t('glCodeOffset'),
        value: 'activityTemplate.glOffset'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'baseCost.rate',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'baseCost.payOn'
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'baseCost.payBy'
      },
      {
        sortable: true,
        text: i18n.t('startDate'),
        value: 'effectiveDate'
      },
      {
        sortable: false,
        text: '',
        value: 'data-table-expand'
      }
    ])
  },
  activityCRUDHeaders () {
    return [
      {
        sortable: false,
        text: '',
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'activityTemplate.name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'activityTemplate.category'
      },
      {
        sortable: true,
        text: i18n.t('counterparty'),
        value: 'contractor.name'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'activityTemplate.glCode'
      },
      {
        sortable: true,
        text: i18n.t('glCodeOffset'),
        value: 'activityTemplate.glOffset'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'baseCost.rate',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'baseCost.payOn'
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'baseCost.payBy'
      },
      {
        sortable: true,
        text: i18n.t('startDate'),
        value: 'effectiveDate'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      },
      {
        sortable: false,
        text: '',
        value: 'data-table-expand'
      }
    ]
  },
  activityTemplateHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'activityStatus',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntityName'
      },
      {
        sortable: true,
        text: i18n.t('type'),
        format: i => CostType.fromInt(i),
        value: 'costType'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        sortable: true,
        text: i18n.t('glCodeOffset'),
        value: 'glOffset'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'rate',
        format: formatMoney,
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'payOn',
        format: i => PayOn.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'payBy',
        format: PayBy.fromInt
      },
      {
        sortable: true,
        text: i18n.t('modifier'),
        value: 'modifier',
        format: i => ActivityModifier.fromInt(i),
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  templateBundleHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'activityStatus',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'activityTemplateName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('counterparty'),
        value: 'counterpartyName'
      },
      {
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntity'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'category'
      },
      {
        sortable: true,
        text: i18n.t('type'),
        value: 'costBehavior'
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        sortable: true,
        text: i18n.t('glCodeOffset'),
        value: 'glOffset'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'baseRate',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'payOn'
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'payBy'
      },
      {
        sortable: true,
        text: i18n.t('modifier'),
        value: 'modifier',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  detailHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'product'
      },
      {
        sortable: true,
        text: i18n.t('rate'),
        value: 'rate',
        align: 'right'
      },
      {
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },
  activityTemplateBulkImportPreviewHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'activityStatus',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntityName'
      },
      {
        sortable: true,
        text: i18n.t('category'),
        value: 'category',
        format: i => TemplateAccountingCategory.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('type'),
        value: 'costType',
        format: i => CostType.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        sortable: true,
        text: i18n.t('glCodeOffset'),
        value: 'glOffset'
      },
      {
        sortable: true,
        text: i18n.t('baseRate'),
        value: 'rate',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('payOn'),
        value: 'payOn',
        format: i => PayOn.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('payBy'),
        value: 'payBy',
        format: i => PayBy.fromInt(i)
      },
      {
        sortable: true,
        text: i18n.t('modifier'),
        value: 'modifier',
        align: 'center',
        format: i => ActivityModifier.fromInt(i)
      }
    ]
  }
}
