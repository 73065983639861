<template>
  <v-row dense v-if="!hidePayBy">
    <v-col md="4" sm="6">
      <MoneyTextField
        data-testid="base-cost-rate"
        :initialValue="baseCost.rate"
        :label="isBaseCostForm ? $t('baseRate') : $t('rate')"
        :focus="focus"
        :expectedValue="expectedValue"
        @val-changed="rateEntered"
        :extraRules="['validActivityRate']"
      />
    </v-col>
    <v-col md="4" sm="6">
      <PayVariationSelector
        type="PayOn"
        class="pay-on"
        data-testid="base-cost-payon"
        :disabled="isPayOnDisabled"
        :initialValue="baseCost.payOn"
        :accountingCategory="accountingCategory"
        :isLogsContract="isLogsContract"
        @pay-on-chosen="payOnChosen"
      />
    </v-col>
    <v-col md="4" sm="6">
      <PayVariationSelector
        type="PayBy"
        class="pay-by"
        data-testid="base-cost-pay-selector"
        :initialValue="baseCost.payBy"
        :accountingCategory="accountingCategory"
        @pay-by-chosen="payByChosen"
      />
    </v-col>
  </v-row>
  <span v-else>
    <MoneyTextField
      data-testid="base-cost-rate"
      :initialValue="baseCost.rate"
      :label="isBaseCostForm ? $t('baseRate') : $t('rate')"
      :expectedValue="expectedValue"
      @val-changed="rateEntered"
      :extraRules="['validActivityRate']"
    />
  </span>
</template>

<script>
import { PayBy, PayOn } from '@/utils/Enumerations.js'
export default {
  name: 'BaseCostForm',

  components: {
    PayVariationSelector: () => import('../autocomplete/PayVariationSelect.vue'),
    MoneyTextField: () => import('../helper/MoneyTextField.vue')
  },

  props: {
    propBaseCost: { Object, default: {} },
    accountingCategory: { Number, default: 0 },
    editing: { Boolean, default: false },
    focus: { Boolean, default: false },
    hidePayBy: { Boolean, default: false },
    isBaseCostForm: { Boolean, default: false },
    expectedValue: { Number, default: undefined, required: false },
    isLogsContract: { type: Boolean, default: true }
  },

  data: () => ({
    baseCost: {
      rate: 0,
      payBy: 1,
      payOn: 0,
      savedPayOn: 0,
      savedRates: 0
    }
  }),

  watch: {
    baseCost: {
      handler (newVal) {
        this.$emit('base-cost-changed', newVal)
      },
      deep: true
    }
  },

  computed: {
    isPayOnDisabled () {
      return this.baseCost.payBy === PayBy.Load.value
    }
  },

  created () {
    this.baseCost = JSON.parse(JSON.stringify(this.propBaseCost))
  },

  methods: {
    rateEntered (rate) { this.baseCost.rate = rate },
    payOnChosen (payOn) { this.baseCost.payOn = payOn },
    payByChosen (payBy) {
      if (payBy === PayBy.Load.value) {
        this.savedPayOn = this.baseCost.payOn
        this.savedRate = this.baseCost.rate
        this.baseCost.payOn = PayOn.NotApplicable.value
        this.$emit('is-by-load', true)
      } else if (this.baseCost.payBy === PayBy.Load.value) {
        this.baseCost.payOn = this.savedPayOn
        this.baseCost.rate = this.savedRate
        this.$emit('is-by-load', false)
      }
      this.baseCost.payBy = payBy
    }
  }

}
</script>
