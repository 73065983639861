<template>
  <div class="fill-height">
    <GridChartCard
      v-if="!loading"
      :title="title"
      :canvasId="elementId"
      :containerId="containerId"
      downloadCSV
      :hasData="xValues.length > 0"
      @download-csv="exportCSV"
      @draw="createChart"
    />
    <v-skeleton-loader type="image" class="fill-height dashboard-widget rounded-lg" v-else />
  </div>
</template>

<script>
import Card from '@/components/material/Card'
import { secondsFormatter } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'
import { Chart } from 'chart.js'
import { mapGetters } from 'vuex'
import { hexCodeForContractMode } from '@/utils/componentHelpers'
import { utcToLocalDate } from '@/utils/DateFormatter'
export default {
  inheritAttrs: false,
  name: 'TimeInYardChart',

  components: {
    GridChartCard: () => import('./GridChartCard.vue')
  },
  props: {
    ...Card.props,
    xValues: {
      type: Array,
      required: true
    },
    yValues: {
      type: Array,
      required: true
    },
    loadCounts: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: undefined
    },
    csvFilename: {
      type: String,
      default: 'export.csv'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    chart: null,
    isTons: false,
    containerId: 'canvas-container'
  }),

  created () {
    this.containerId = `${this.elementId}-container`
  },

  computed: {
    ...mapGetters('ticket', ['contractMode'])
  },

  methods: {
    exportCSV () {
      let dataString = ''
      for (let i = 0; i < this.xValues.length; i++) {
        dataString += `${this.xValues[i]}, ${secondsFormatter(this.yValues[i])}` + '\n'
      }
      const blob = new Blob([`${this.$t('date')}, ${this.$t('yardTime(hh:mm:ss)')}` + '\n' + dataString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, this.csvFilename.length > 0 ? this.csvFilename : 'export.csv')
    },

    createChart (canvas) {
      if (this.loading) return
      const loadCounts = this.loadCounts
      this.chart = new Chart(canvas, {
        type: 'line',
        data: {
          labels: this.xValues.map(x => utcToLocalDate(x, 'MM/DD/YYYY')),
          datasets: [{
            label: '',
            data: this.yValues.map(y => Math.floor(y / 60)),
            backgroundColor: hexCodeForContractMode(this.contractMode.value),
            borderColor: hexCodeForContractMode(this.contractMode.value).concat('66')
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 500,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const duration = tooltipItem.parsed.y
                  const loadCount = loadCounts[tooltipItem.dataIndex]
                  const tooltipText = [
                    `Time in Yard: ${secondsFormatter(duration)}`,
                    `Load Count: ${loadCount}`
                  ]
                  return tooltipText
                }
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              grid: {
                display: true
              },
              ticks: {
                callback: function (val, index) {
                  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
                  return days[new Date(this.getLabelForValue(val)).getDay()]
                }
              }
            },
            y: {
              display: true,
              ticks: {
                display: true,
                precision: 0
              },
              grid: {
                display: true
              }
            }
          }
        }
      })
    }
  }
}
</script>
