<template>
  <v-card>
    <v-card-title class="secondary white--text headline">
      <span>{{ $t('selectPayees') }}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close', { refresh: false })"
      :tooltipText="$t('close')"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      large
      />
    </v-card-title>
    <v-card-text>
      <span v-if="loading" style="background-color: red;">
        <v-row class="mt-2">
          <v-col align="center">
            <v-progress-circular indeterminate color="secondary"/>
          </v-col>
        </v-row>
      </span>
      <span v-else-if="noPayeesWithSettlementContacts">
        <v-row class="mt-1">
          <v-col align="center">
            <p class="subtitle-1 black--text font-weight-medium" style="white-space: pre-line">
              {{ $t('noAccountsWithSpecifiedSettlementRecipients') }}
            </p>
          </v-col>
        </v-row>
      </span>
      <v-row v-else>
        <v-col>
          <v-row>
            <v-col class="mb-n2" cols="9">
              <v-checkbox
              :label="$t('allPayees')"
              v-model="allPayees"
              @change="allPayeeCheckboxToggled"/>
            </v-col>
            <v-spacer/>
            <span class="mt-8 mr-1">
              <Icon
              v-if="deliveryMethod.value === BulkSettlementDeliveryMode.Email.value"
              :small="false"
              icon="mdi-information-outline"
              :tooltipText="$t('excludesAccountsWithoutSettlementRecipient')"/>
            </span>
          </v-row>
          <hr class="mb-n4 mt-n3"/>
          <v-row v-for="(payee, index) in payees" :key="index" class="mt-1">
            <v-col class="mb-n8">
              <v-checkbox
              :label="payee.name"
              v-model="payee.selected"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right">
              <v-btn
              class="secondary"
              @click="downloadMethod()"
              :disabled="buttonDisabled">
                {{ btnText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SettlementDeliveryMode, BulkSettlementDeliveryMode } from '@/utils/Enumerations'
import PDFMerger from 'pdf-merger-js/browser'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import { fileNameForString, getDateInfo, getFileNameForPeriod } from '../../utils/pdf-formatters/GenericSettlementsFunctions'
import { SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'
export default {
  name: 'SettlementSheetPayeeSelector',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    deliveryMethod: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    payees: [],
    allPayees: false,
    loading: false,
    settlementsByPayee: undefined,
    BulkSettlementDeliveryMode
  }),

  async created () {
    if (this.currentSubModuleKey === SettlementSubKeys.AP.SettlementsByContract) {
      this.loading = true
      this.settlementsByPayee = await this.fetchSettlementsByPayee()
      this.loading = false
    } else {
      this.settlementsByPayee = this.currentSubModule
    }

    this.payees = this.settlementsByPayee?.payees ?? []

    if (this.deliveryMethod.value === BulkSettlementDeliveryMode.Email.value) {
      this.payees = this.payees.filter(p => p.settlementRecipientContactId !== null)
    }

    this.payees = (this.payees).map(p => ({
      accountId: p.payeeId,
      accountContactId: p.settlementRecipientContactId,
      name: p.payeeName,
      selected: this.deliveryMethod.value === BulkSettlementDeliveryMode.Email.value
        ? (p.settlementDeliveryMode === SettlementDeliveryMode.Email.value || p.settlementDeliveryMode === SettlementDeliveryMode.EmailAndPrint.value)
        : (p.settlementDeliveryMode === SettlementDeliveryMode.Print.value || p.settlementDeliveryMode === SettlementDeliveryMode.EmailAndPrint.value)
    }))
  },

  watch: {
    payees: {
      handler (val) {
        this.allPayees = val.every(p => p.selected)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('settlements', ['currentSubModule', 'currentSubModuleKey', 'dateConfiguration']),
    buttonDisabled () {
      return this.payees.every(p => !p.selected)
    },
    downloadMethod () {
      switch (this.deliveryMethod.value) {
        case BulkSettlementDeliveryMode.SinglePdf.value:
          return this.downloadSinglePdf
        case BulkSettlementDeliveryMode.ZippedFolder.value:
          return this.downloadZippedPdf
        case BulkSettlementDeliveryMode.Email.value:
          return this.sendEmails
      }
      return this.zipped ? this.downloadZippedPdf : this.downloadSinglePdf
    },
    filename () {
      const dateInfo = getDateInfo(this.settlementsByPayee, this.dateConfiguration.exportBatch)
      return getFileNameForPeriod(dateInfo).replace(/\s/g, '')
    },
    btnText () {
      switch (this.deliveryMethod.value) {
        case BulkSettlementDeliveryMode.SinglePdf.value:
          return this.$t('downloadPDF')
        case BulkSettlementDeliveryMode.ZippedFolder.value:
          return this.$t('downloadZip')
        case BulkSettlementDeliveryMode.Email.value:
          return this.$t('sendEmails')
      }
      return 'exception'
    },
    noPayeesWithSettlementContacts () {
      return this.payees.every(p => p.accountContactId === null) && this.deliveryMethod.value === BulkSettlementDeliveryMode.Email.value
    }
  },

  methods: {
    ...mapActions('settlements', ['fetchSubmoduleBlob', 'fetchSettlementsByPayee', 'createSettlementEmailRequest']),
    allPayeeCheckboxToggled (event) {
      this.payees.forEach(p => { p.selected = event })
    },

    async downloadSinglePdf () {
      const merger = new PDFMerger()
      for (const payee of this.payees.filter(p => p.selected)) {
        const blob = await this.fetchSubmoduleBlob({ accountId: payee.accountId })
        await merger.add(blob)
      }
      const mergedPdf = await merger.saveAsBlob()
      saveAs(mergedPdf, fileNameForString(this.filename, 'pdf'))
      this.$emit('close', { refresh: false })
    },

    async downloadZippedPdf () {
      const zip = new JSZip()
      const folderName = fileNameForString(this.filename)
      const folder = zip.folder(folderName)
      for (const payee of this.payees.filter(p => p.selected)) {
        const blob = await this.fetchSubmoduleBlob({ accountId: payee.accountId })
        folder.file(`${fileNameForString(payee.name, 'pdf')}`, blob)
      }
      const downloadableZip = await zip.generateAsync({ type: 'blob' })
      saveAs(downloadableZip, fileNameForString(this.filename), 'zip')
      this.$emit('close', { refresh: false })
    },

    async sendEmails () {
      await Promise.all(this.payees.filter(p => p.selected).map(p => this.createSettlementEmailRequest({ ...p })))
      this.$emit('close', { refresh: true })
    }
  }
}
</script>
