<template>
  <v-card>
    <v-card-title class="secondary">
      <v-row>
        <v-col cols="12">
          <span class="headline white--text">{{ $t('assignUserToRole', {roleName: $t(role.label)}) }}</span>
        </v-col>
      </v-row>
      <v-spacer/>
      <Icon
      icon="mdi-close"
      iconColor="white"
      :small="false"
      large
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"/>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="10">
            <v-autocomplete
            color="black"
            item-color="secondary"
            v-model="selectedUser"
            :label="$t('users')"
            :items="availableUsers"
            item-text="name"
            clearable
            return-object>
            <template #append-outer>
              <v-icon @click="refreshUsers" color="secondary" tabindex="-1">mdi-refresh</v-icon>
            </template>
          </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn color="secondary" type="submit" :disabled="selectedUser === undefined" @click="assignUserToRole">
                {{ $t('assign') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AssignUserToRole',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    role: {
      type: Object,
      required: true
    },
    availableUsers: {
      type: Array,
      default () { return [] }
    }
  },

  data: () => ({
    selectedUser: undefined,
    userNames: []
  }),

  methods: {
    assignUserToRole () {
      this.$emit('assign-user', this.selectedUser)
    },

    refreshUsers () {
      this.$emit('refresh-users')
    }
  }
}
</script>
