<template>
  <div>
    <v-dialog
    scrollable
    :width="width"
    :max-width="maxWidth"
    persistent
    :fullscreen="fullscreen"
    v-model="show"
    @click:outside="handleCloseRequest">
      <slot v-if="show"></slot>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import store from '@/store/index.js'
import DialogState from '../model/DialogState.js'
export default {
  name: 'Dialog',

  props: {
    maxWidth: {
      type: String,
      default: undefined,
      required: false
    },
    fullscreenBreakPoint: {
      type: String,
      default: 'mdAndDown',
      required: false
    },
    stateId: {
      type: String,
      default: undefined
    },
    enforcePersistence: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    stateObject: {
      type: DialogState,
      default: undefined
    },
    show: false
  }),

  created () {
    this.stateObject = undefined
    this.show = false
    if (this.topDialogId === this.stateId) {
      this.stateObject = this.topDialogState
      this.show = true
    }
    store.subscribe((mutation) => {
      if (
        (mutation.type === 'dialog/pushDialog' || mutation.type === 'dialog/pushOrSetDialog') &&
        mutation.payload.id === this.stateId
      ) {
        this.stateObject = mutation.payload
        this.show = true
      }
    })

    document.addEventListener('keydown', this.keyListener)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', document)
    this.popAllDialogsAtAndAbove(this.stateId)
  },

  computed: {
    ...mapGetters('dialog', ['dialogCount', 'dialogStateById', 'hasOpenDialogById', 'topDialogId', 'topDialogState']),
    width () {
      return this.stateObject?.width ?? '400px'
    },
    fullscreen () {
      return (
        this.stateObject?.allowFullscreen &&
        (this.stateObject?.fullscreen ||
        this.$vuetify.breakpoint[this.fullscreenBreakPoint])
      ) ?? false
    },
    closeRequested () {
      return this.stateObject?.closeRequested ?? false
    }
  },

  watch: {
    closeRequested (val) {
      if (val) {
        this.handleCloseRequest()
      }
    }
  },

  methods: {
    ...mapMutations('dialog', ['popDialog', 'popAllDialogsAtAndAbove']),

    keyListener (event) {
      if (event.keyCode === 27) { // 27 is the esc key
        this.handleCloseRequest(event)
      }
    },

    close () {
      this.$emit('dialog-closing')
    },

    handleCloseRequest (event) {
      if (this.enforcePersistence) return
      this.$emit('click:outside', event)
      this.doClose()
    },

    doClose () {
      if (this.stateObject) this.stateObject.closeRequested = false
      this.popDialog()
      this.$emit('close')
      this.stateObject = undefined
      this.show = false
    }
  }
}
</script>
