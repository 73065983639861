import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchAccounts (context, tags) {
    context.commit('setLoading', true)
    try {
      let tagFilter = ''
      if (tags) {
        tagFilter = (tags.map(tag => tag.value)).toString()
      }
      const accountResponse = await ApiServer.get('accounts', {
        headers: {
          tagsFilter: tagFilter
        }
      })
      context.commit('setAccounts', accountResponse.data)
      return accountResponse.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async deleteAccount (_, accountId) {
    const response = await ApiServer.delete(`accounts/${accountId}`)
    return response
  },
  async createAccount (_, account) {
    const response = await ApiServer.post('accounts', account)
    return response.data
  },
  async updateAccount (_, account) {
    const response = await ApiServer.put(`accounts/${account.accountId}`, account)
    return response.data
  },

  async fetchAccountById (_, accountId) {
    const path = `accounts/${accountId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchAddressToAccount (_, accountId) {
    const path = `accounts/${accountId}/addresses`
    const response = await ApiServer.get(path)
    return response.data
  },

  async addAddressToAccount (_, createRequest) {
    const path = `accounts/${createRequest.accountId}/addresses`
    const response = await ApiServer.post(path, createRequest.address)
    return response.data
  },

  async deleteAccountAddress (_, createRequest) {
    const { accountId, accountAddressId } = createRequest
    const path = `accounts/${accountId}/addresses/${accountAddressId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async updateAccountAddress (_, createRequest) {
    const { accountId, address } = createRequest
    const path = `accounts/${accountId}/addresses/${address.accountAddressId}`
    const response = await ApiServer.put(path, createRequest.address)
    return response.data
  },

  async getAccountsWithTags (_, tagArray) {
    const tagValues = (tagArray.map(tag => tag.value)).toString()
    const response = await ApiServer.get('accounts', {
      headers: {
        tagsFilter: tagValues
      }
    })
    return response.data
  },

  async fetchAccountPayables (_, accountId) {
    const response = await ApiServer.get(`accounts/${accountId}/accountpayables`)
    return response.data
  },

  async fetchAccountPayableById (_, { accountId, accountPayableId }) {
    const response = await ApiServer.get(`accounts/${accountId}/accountpayables/${accountPayableId}`)
    return response.data
  },

  async createAccountPayable (_, { accountId, accountPayable }) {
    const response = await ApiServer.post(`accounts/${accountId}/accountpayables`, accountPayable)
    return response.data
  },

  async updateAccountPayable (_, { accountId, accountPayable }) {
    accountPayable.accountId = accountId
    const response = await ApiServer.put(`accounts/${accountId}/accountPayables/${accountPayable.accountPayableId}`, accountPayable)
    return response.data
  },

  async deleteAccountPayable (_, { accountId, accountPayableId }) {
    const response = await ApiServer.delete(`accounts/${accountId}/accountPayables/${accountPayableId}`)
    return response.data
  }
}
