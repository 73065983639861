import vuetify from '@/plugins/vuetify'

const bpt = vuetify.framework.breakpoint

export const getNumEmptySlots = (numCards) => {
  const cardsPerRow = bpt.smAndDown ? 1
    : bpt.lgAndDown ? 2
      : 3
  return (cardsPerRow - (numCards % cardsPerRow)) % cardsPerRow
}
