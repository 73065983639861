<template>
  <v-menu
  bottom
  :close-on-content-click="false"
  v-model="open"
  left>
    <template #activator="{on}">
      <v-icon v-on="on" class="mr-2" color="secondary">mdi-filter</v-icon>
    </template>
    <v-card width="400px">
      <v-card-title class="secondary white--text">
        <span>
          {{ $t('filterDevices') }}
        </span>
        <v-spacer/>
        <Icon
        icon="mdi-refresh"
        iconColor="tertiary"
        dataTestId="device-filter-refresh-button"
        :small="false"
        :tooltipText="$t('reset')"
        @icon-clicked="resetClicked"/>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-container fluid>
          <v-row dense>
            <span class="text-h6">
              {{ $t('byApplication') }}
            </span>
          </v-row>
          <v-row dense class="mt-n3">
            <v-col cols="6">
              <v-checkbox
              v-model="filters.includeLoader"
              data-testid="filter-open-check"
              :label="$t('loader')"
              color="secondary"/>
            </v-col>
            <v-col cols="6">
              <v-checkbox
              :label="$t('transporter')"
              data-testid="filter-open-check"
              v-model="filters.includeTransporter"
              color="secondary"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn data-testid="filter-submit-button" class="secondary white--text" @click="applyButtonClicked">{{$t('filter')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DeviceRegistrationFilter',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    open: false,
    saveChanges: false,
    filters: {
      includeLoader: false,
      includeTransporter: false
    }
  }),

  computed: {
    ...mapGetters('device-registration', ['filter'])
  },

  watch: {
    filter: {
      handler (val) {
        this.filters = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },

    open (isOpen) {
      if (!isOpen) {
        if (this.saveChanges) {
          this.doSaveChanges()
        } else {
          setTimeout(() => {
            this.filters = JSON.parse(JSON.stringify(this.filter))
          }, 75)
        }
      }
    }
  },
  created () {
    this.filters = JSON.parse(JSON.stringify(this.filter))
  },

  methods: {
    ...mapActions('device-registration', ['setFilter']),
    resetFilters () {
      this.filters = {
        includeLoader: false,
        includeTransporter: false
      }
    },

    resetClicked () {
      this.resetFilters()
      this.applyButtonClicked()
    },

    doSaveChanges () {
      this.setFilter(this.filters)
    },

    applyButtonClicked () {
      this.saveChanges = true
      this.open = false
      this.$nextTick(() => {
        this.saveChanges = false
      })
    }
  }
}
</script>
