import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchTractNotes (_, tractId) {
    const path = `tracts/${tractId}/notes`
    const response = await ApiServer.get(path)
    return response.data
  },
  async fetchTractNote (_, requestObject) {
    const { tractId, noteId } = requestObject
    const response = await ApiServer.delete(`tracts/${tractId}/notes/${noteId}`)
    return response.data
  },
  async createTractNote (_, requestObject) {
    const response = await ApiServer.post(`tracts/${requestObject.tractId}/notes`, requestObject)
    return response.data
  },
  async updateTractNote (_, requestObject) {
    const response = await ApiServer.put(`tracts/${requestObject.tractId}/notes/${requestObject.objectNoteId}`, requestObject)
    return response.data
  },
  async deleteTractNote (_, requestObject) {
    const { tractId, objectNoteId } = requestObject
    const response = await ApiServer.delete(`tracts/${tractId}/notes/${objectNoteId}`)
    return response.data
  }
}
