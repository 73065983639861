export const getOriginationTicketId = (contractId) => {
  const randomPrefix = generateRandomString(4)
  const contractIdHash = contractId.toString(36).toUpperCase()
  const timeSince2020 = getMillisecondsSince2020()

  return `WC-${randomPrefix}-${contractIdHash}-${timeSince2020}`
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

// Used to mimic the behavior of the Loader/Receiver app.
const generateRandomString = (length) => {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

const getMillisecondsSince2020 = () => {
  const millisecondsSince2020 = new Date().getTime() - new Date('2020-01-01').getTime()
  return millisecondsSince2020.toString(36).toUpperCase()
}
