<template>
  <material-card>
    <v-card
    :color="color"
    style="padding: 15px;"
    class="float-left"
    >
      <v-icon color="white" size="40">{{ icon }}</v-icon>
    </v-card>
    <div
      class="text-right"
    >
      <p
        class="category grey--text"
        v-text="title"
      />
      <h3
        class="subtitle">
        {{ value }} <small v-if="smallValue">{{ smallValue }}</small>
      </h3>
    </div>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  name: 'StatsCard',

  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    }
  }
}
</script>
