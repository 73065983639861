import { numberWithCommas, tonStringFromPounds } from '@/utils/NumericMutations'
import { HarvestUnits } from '@/utils/Enumerations'

export const cruiseDataTotals = (cruiseData, units = HarvestUnits.Tons) => {
  cruiseData = Array.isArray(cruiseData) ? cruiseData : [cruiseData]
  const isTons = units === HarvestUnits.Tons

  const totals = cruiseData.reduce((total, cd) => {
    total.harvested += isTons ? cd.harvestedPounds : cd.harvestedMBF
    total.expected += isTons ? cd.expectedPounds : cd.expectedMBF
    total.adjustment += isTons ? cd.adjustmentPounds : cd.adjustmentMBF
    total.cruisedValue += cd.cruisedValue
    total.remaining += isTons ? cd.remainingTons : cd.remainingMBF
    return total
  }, {
    harvested: 0,
    adjustment: 0,
    remaining: 0,
    expected: 0,
    cruisedValue: 0
  })

  totals.remaining = Math.max(totals.remaining, 0)

  totals.remaining = numberWithCommas(totals.remaining, 3)
  totals.harvested = isTons ? tonStringFromPounds(totals.harvested) : numberWithCommas(totals.harvested)
  totals.adjustment = isTons ? tonStringFromPounds(totals.adjustment) : numberWithCommas(totals.adjustment)
  totals.expected = isTons ? tonStringFromPounds(totals.expected) : numberWithCommas(totals.expected)

  return totals
}
