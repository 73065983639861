<template>
  <v-snackbar class="subtitle-1" v-model="show" :timeout="timeout" :color="snackColor">
    {{ snack }}
  </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Snack',

  created () {
    this.$store.watch(state => state.snackbar.snack, () => {
      const msg = this.$store.state.snackbar.snack
      if (msg !== '') {
        this.show = true
      }
    })
  },

  data () {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters('snackbar', ['timeout', 'snack', 'snackColor'])
  },

  watch: {
    show () {
      if (!this.show) {
        this.setSnack('')
      }
    }
  },

  methods: {
    ...mapMutations('snackbar', ['setSnack'])
  }
}
</script>
