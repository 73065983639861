import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchReceivables (context, contractMode) {
    try {
      context.commit('setReceivableLoadingStatus', true)
      const response = await ApiServer.get(`receivables?byproduct=${contractMode === 1}&transfer=${contractMode === 2}`)
      context.commit('setReceivables', response.data)
      return response.data
    } finally {
      context.commit('setReceivableLoadingStatus', false)
    }
  },
  async getReceivable (_, receivableId) {
    const path = `receivables/${receivableId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async deleteReceivable (_, receivableId) {
    const response = await ApiServer.delete('receivables/' + receivableId)
    return response.data
  },
  async createReceivable (context, receivable) {
    const response = await ApiServer.post('receivables', {
      code: receivable.code,
      name: receivable.name
    })
    context.commit('addReceivable', response.data)
    return receivable
  },
  async updateReceivable (context, receivable) {
    const response = await ApiServer.put('receivable/' + receivable.receivableId, {
      receivableId: receivable.receivableId,
      code: receivable.code,
      name: receivable.name
    })
    context.commit('updateReceivable', response.data)
    return response.data
  },
  async getReceivablesForAccount (_, accountId) {
    const path = `receivables/?accountId=${accountId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async getReceivablesForTicket (_, ticketId) {
    const path = `tickets/${ticketId}/receivables`
    const response = await ApiServer.get(path)
    return response.data
  }
}
