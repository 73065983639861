<template>
  <v-card min-height="800px">
    <v-card-title class="headline py-4 secondary white--text">
      <span>{{ $t("journalEntriesFor", { account: accountName }) }}</span>
      <v-spacer/>
      <span class="mr-2" >{{$t("accountTotal", { amount: totalJournalEntry })}} </span>
      <div v-if="isTDialog">
         <BaseDialogActions hideRefresh />
      </div>
      <Icon
      v-else
      iconColor="white"
      icon="mdi-close"
      dataTestId="entry-by-tract-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <p class="headline mt-2" v-if="noJournalEntries">
        {{$t('noTractJournalEntries')}}
      </p>
      <v-expansion-panels v-model="openPanel" flat accordion tile class="mt-10" v-else>
        <v-expansion-panel
        v-for="(tract, index) in tractJournalEntries"
        :key="index">
          <v-expansion-panel-header :ripple="true">
            <v-layout>
              <Icon v-if="!tract.tractName" icon="mdi-transfer" :iconColor="(index === openPanel) ? 'white' : 'secondary'" :tooltipText="$t('transfers')" :small="false"/>
              {{tract.tractName ? $t("tractNamePlaceholder", { tractName: tract.tractName }) : $t('transfers')}}
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{tract.totalJournalEntry}}
                </v-row>
                <v-row justify="end">
                  {{tract.totalNetWeight}}
                </v-row>
                <v-row justify="end">
                  {{tract.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <JournalEntryTable
              :hideFooter="true"
              :dense="true"
              :journalEntries.sync="tract.journalEntries"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-dialog v-model="viewingDetail" width="60%">
      <JournalEntryDetail
      @close="viewingDetail = false"
      v-if="viewingDetail"
      :journalEntry="focusedJournalEntry"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatMoney, getFormattedTons } from '@/utils/NumericMutations'
import { mapActions } from 'vuex'

export default {
  name: 'JournalEntryByTract',

  props: {
    accountJournalEntry: Object,
    account: {
      type: Object,
      default: undefined
    },
    isTDialog: Boolean
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    JournalEntryTable: () => import('./JournalEntryTable.vue'),
    JournalEntryDetail: () => import('./JournalEntryDetail.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    totalJournalEntry () {
      return formatMoney(this.journalEntries.reduce((a, b) => a + b.amount, 0))
    },

    accountName () {
      if (this.account) {
        return this.account.name
      } else {
        return this.accountJournalEntry.accountName
      }
    }
  },

  async created () {
    if (this.account) {
      const journalEntries = await this.getJournalEntriesForAccount(this.account.accountId)
      this.journalEntries = journalEntries
      this.noJournalEntries = journalEntries.length === 0
      this.createTractJournalEntries(journalEntries)
    } else {
      this.createTractJournalEntries(this.accountJournalEntry.journalEntries)
    }
  },

  data: () => ({
    amountTotal: 0,
    openPanel: -1,
    tractJournalEntries: [],
    journalEntries: [],
    noJournalEntries: false,
    viewingDetail: false,
    focusedJournalEntry: undefined
  }),

  methods: {
    ...mapActions('journalEntry', ['getJournalEntriesForAccount']),
    formatMoney,
    getFormattedTons,

    viewJournalEntryDetail (journalEntry) {
      this.focusedJournalEntry = journalEntry
      this.viewingDetail = true
    },

    createTractJournalEntries (journalEntries) {
      this.journalEntries = journalEntries
      const tractJournalEntries = {}

      journalEntries.forEach(journalEntry => {
        if (tractJournalEntries[journalEntry.tractName]) {
          tractJournalEntries[journalEntry.tractName].journalEntries.push(journalEntry)
        } else {
          tractJournalEntries[journalEntry.tractName] = {
            tractName: journalEntry.tractName,
            journalEntries: [journalEntry]
          }
        }
      })

      this.tractJournalEntries = Object.values(tractJournalEntries)
      let totalJournalEntry = 0
      let totalNetWeight = 0
      let uniqueTicketNumbers = {}

      this.tractJournalEntries.forEach(tractJournalEntry => {
        tractJournalEntry.journalEntries.forEach(journalEntry => {
          totalJournalEntry += journalEntry.amount
          totalNetWeight += journalEntry.netWeight
          uniqueTicketNumbers[journalEntry.ticketNumber] = journalEntry.ticketNumber
        })
        tractJournalEntry.totalJournalEntry = this.formatMoney(totalJournalEntry)
        tractJournalEntry.totalNetWeight = `${this.getFormattedTons(totalNetWeight)} tons`
        tractJournalEntry.loadCount = `${Object.keys(uniqueTicketNumbers).length} loads`

        totalJournalEntry = 0
        totalNetWeight = 0
        uniqueTicketNumbers = {}
      })
    }
  }
}
</script>
