<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col v-if="contractHasFromAccount" cols="12" xs="12" sm="12" md="12" lg="4">
          <v-tooltip :disabled="!contract.fromAccountCode?.length > 0 " bottom color="secondary">
            <template v-slot:activator="{ on }">
              <v-text-field data-testid="contract-detail-from-account" v-on="on" :label="$t('fromAccount')" outlined color="secondary" readonly :value="contract.fromAccount"/>
            </template>
            <span class="white--text subtitle-1">{{ contract.fromAccountCode }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" :lg="!isLogsContract ? 4 : 3">
          <v-tooltip :disabled="!contract.accountCode.length > 0 " bottom color="secondary">
            <template v-slot:activator="{ on }">
              <v-text-field
              data-testid="contract-detail-account"
              :label="$t('account')"
              outlined
              color="secondary"
              readonly
              :value="contract.account"
              v-on="on"
              :append-icon="isCertified ? 'mdi-certificate-outline' : ''"/>
            </template>
            <span class="white--text subtitle-1">
              {{ contract.accountCode }}
              <span v-if="isCertified">
                <br>
                {{$t('accountIsCertified')}}
              </span>
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" :lg="!isLogsContract ? 4 : 3">
          <v-text-field data-testid="contract-detail-destination" :label="$t('destination')" outlined color="secondary" readonly :value="contract.destination"/>
        </v-col>
        <v-col v-if="contractHasTract" cols="12" xs="12" sm="12" md="12" :lg="contractTypeIsByproductPurchase ? 4 : 3">
          <v-tooltip :disabled="!contract.tractIsCertified" bottom color="secondary">
            <template v-slot:activator="{ on }">
              <v-text-field
              data-testid="contract-detail-tract"
              :label="$t('tract')"
              outlined
              color="secondary"
              readonly
              :value="contract.tract"
              :append-icon="contract.tractIsCertified ? 'mdi-certificate-outline' : ''"
              v-on="on"
              />
            </template>
            <span class="white--text subtitle-1">
              {{$t('tractIsCertified')}}
            </span>
          </v-tooltip>
        </v-col>
        <v-col v-if="isLogsContract" cols="12" xs="12" sm="12" md="12" lg="3">
          <v-text-field data-testid="contract-detail-setting" :label="$t('setting')" outlined color="secondary" readonly :value="contract.setting"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" :lg="cardColumnWidth">
      <v-card :min-height='minCardHeightForPropertiesAndProducts'>
        <v-card-title class="subtitle-1 mt-n1" >
          <span>{{$t('properties')}}</span>
          <v-spacer/>
          <Icon
          v-for="attribute in attributes"
          dataTestId="contract-attribute"
          :key="attribute.text"
          :icon="attribute.icon"
          :tooltipText="attribute.text"
          :iconColor="attribute.color"
          :tooltipColor="attribute.color"
          />
        </v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container data-testid="contract-details-prop-table" fluid>
            <v-row dense>
              <v-col cols="12">
                {{$t('type')}}: {{contractTypeFromInt(contract.type)}}
              </v-col>
              <v-col v-if="contractHasTract" cols="12">
                {{$t('tractType')}}: {{contract.tractType}}
              </v-col>
              <v-col v-if="contractHasTract" cols="12">
                {{$t('logger')}}: {{contract.tractDefaultLogger ?? $t('notAvailable')}}
              </v-col>
              <v-col cols="12" v-if="contract.distance > 0">
                {{$t('distanceLength', { distance: contract.distance })}}
              </v-col>
              <v-col cols="12" v-if="contract.tareWeight">
                {{$t('tareWeight')}}: {{ numberWithCommas(contract.tareWeight) }} LBS
              </v-col>
              <v-col cols="12" v-if="contractTypeIsByproduct">
                <span>
                  {{ $t('allowsLoadPickup')}}:
                  <Icon
                  :tooltipText="contract.allowPickupLoads ? $t('allowsLoadPickup') : $t('notAllowsLoadPickup')"
                  :icon="contract.allowPickupLoads ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
                  :iconColor="contract.allowPickupLoads ? 'success' : 'error'"/>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{utcToLocalDate(contract.effectiveDate)}}</span> -
                <span>{{utcToLocalDate(contract.expirationDate)}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" :lg="cardColumnWidth" v-if="tractLandowners.length > 0 && tractLandowners.length < 6">
      <v-card :min-height="minCardHeightForPropertiesAndProducts">
        <v-card-title class="subtitle-1 mt-n1">
          <span>{{ $t('tractLandowners') }}</span>
        </v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container fluid>
            <v-row dense v-for="(landowner, index) in tractLandowners" :key="index">
              <v-col cols="9">
                {{ landowner.accountName }}
              </v-col>
              <v-col cols="3" align="right">
                {{ formatPercentage(landowner.ownership) }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" :lg="cardColumnWidth">
      <v-card :min-height='minCardHeightForPropertiesAndProducts'>
        <v-card-title class="subtitle-1 mt-n1" >
          <span>{{$t('products')}}</span>
          <v-spacer/>
          <Icon
          dataTestId="contract-load-icon"
          iconColor="grey"
          tooltipColor="grey"
          :tooltipText="contract.acceptsAnyLoad ? $t('acceptsAnyProduct') : $t('restrictedProducts')"
          :icon="contract.acceptsAnyLoad ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline'"
          />
        </v-card-title>
        <v-card-text style="border-top: solid 2px #D15F27" class="mt-n1">
          <v-container data-testid="contract-details-product-table" fluid>
            <v-row dense v-if="contract.products.length > 0">
              <v-col cols="12">
                <v-row v-if="contractTypeIsWoodsSale" class="mb-n7">
                  <v-col cols="6">
                    <span style="text-decoration: underline;">{{ $t('product') }}</span>
                  </v-col>
                  <v-col cols="6" align="right">
                    <span style="text-decoration: underline">
                      <Icon
                      v-if="showPayByLoadWarning"
                      icon="mdi-information-outline"
                      :tooltipText="$t('profitPerTonCannotBeCalculatedForPayByLoadEditing')"/>
                      <Icon
                      v-if="showNonInventoryWarning"
                      icon="mdi-information-outline"
                      :tooltipText="$t('nonInventoryCostsExcluded')"/>
                      {{ $t('grossProfit') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row v-for="n in (contractTypeIsWoodsSale ? 3 : 4)" :key="n" class="mb-n7">
                  <v-col cols="8">
                    <span>{{ getProductAtIndex(n) }}</span>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="4" align="right" v-if="contractTypeIsWoodsSale && (n <= contract.products.length) && (!showPayByLoadWarning)">
                    <span>{{ `${formatMoney(getProductProfitRateAtIndex(n))}/t` }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="contract.products.length > 3">
                  <v-col cols="12">
                    <span class="mt-2" style="text-decoration: underline; cursor: pointer;" @click="productDialog = true">
                      {{$t('viewAll')}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else dense>
              <v-col cols="12">
                <span>{{$t('noProductsSpecified')}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="tractLandowners.length > 5">
      <v-expansion-panels flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header style="border-width: 0px;">
            <v-row dense class="subtitle-1 mt-n1" style="flex-wrap: nowrap; overflow: hidden; text-wrap: nowrap;">
              <v-col>
                <span> {{$t('tractLandowners')}} </span>
              </v-col>
              <v-col cols="auto">
                <span> {{ landownersPanelString }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
            mobile-breakpoint="396"
            class="mt-4"
            hide-default-footer
            :items-per-page="-1"
            :items="tractLandowners"
            :headers="landownerHeaders"
            sort-by="ownership"
            sort-desc
            dense>
              <template #item.name="{item}">
                <v-row no-gutters align="center">
                  {{item.accountName}}
                </v-row>
              </template>
              <template #item.ownership="{item}">
                <span>{{formatPercentage(item.ownership)}}</span>
              </template>
              <template #item.isDefault="{item}">
                <v-icon color="black" v-if="item.isDefault">
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-dialog v-model="productDialog" max-width="400">
      <ProductDialog
      :products="contract.products"
      :profitByProduct="profitByProduct"/>
    </v-dialog>
  </v-row>
</template>

<script>
import ProductHeaders from '@/headers/Product'
import { numberWithCommas, formatMoney, formatPercentage } from '@/utils/NumericMutations'
import { ContractType, TemplateAccountingCategory, CostType, ActivityModifier, PayBy, TemplateCostType, CertificationStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'ContractInformation',

  components: {
    ProductDialog: () => import('@/components/contract/contract-detail/ProductDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    contract: {
      required: true,
      type: Object
    },
    allActivities: {
      type: Array,
      default: undefined,
      required: true
    },
    tractLandowners: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    productDialog: false,
    profitByProduct: undefined
  }),

  computed: {
    contractTypeIsWoodsSale () {
      return this.contract.type === ContractType.WoodsSale.value
    },

    contractTypeIsByproduct () {
      return this.contract.type === ContractType.ByproductSale.value
    },

    contractTypeIsByproductPurchase () {
      return this.contract.type === ContractType.ByproductPurchase.value
    },

    productHeaders () { return ProductHeaders.productHeaders(this.$i18n.locale) },

    attributes () {
      const attributes = []

      if (!this.contract.isExternal) {
        attributes.push({
          text: this.$t('internal'),
          icon: 'mdi-home',
          color: 'black'
        })
      }

      if (this.contract.isDraft) {
        attributes.push({
          text: this.contract.hasPendingTickets ? this.$t('hasPendingTickets') : this.$t('draftContract'),
          icon: 'mdi-file',
          color: this.contract.hasPendingTickets ? 'red' : 'black'
        })
      }

      if (this.contract.requiresLogger) {
        attributes.push({
          text: this.$t('requiresLogger'),
          icon: 'mdi-axe',
          color: 'brown'
        })
      }

      if (this.contract.requiresExt1) {
        attributes.push({
          text: this.$t('requiresExt1'),
          icon: 'mdi-pound',
          color: 'secondary'
        })
      }

      if (this.contract.requiresExt2) {
        attributes.push({
          text: this.$t('requiresExt2'),
          icon: 'mdi-pound',
          color: 'secondary'
        })
      }

      return attributes
    },

    isLogsContract () {
      return this.contract?.type === ContractType.Production.value || this.contract?.type === ContractType.WoodsSale.value
    },

    contractHasTract () {
      return this.isLogsContract ||
        (this.contract?.type === ContractType.ByproductPurchase.value && this.contract.tract)
    },

    contractHasFromAccount () {
      return !this.isLogsContract && this.contract.fromAccountId
    },

    requiresLoggerText () { return this.contract.requiresLogger ? this.$t('requiresLogger') : this.$t('noLoggerRequired') },

    minCardHeightForPropertiesAndProducts () { if (this.contract.distance > 0 || this.contract.products.length > 4) { return '240px' } else { return '210px' } },

    showPayByLoadWarning () {
      return this.allActivities?.flatMap(a => a.activityDetails).some(ad => ad.cost.payBy === PayBy.Load.value)
    },

    showNonInventoryWarning () {
      return this.allActivities?.some(a => a.activityTemplate.costType === TemplateCostType.nonInventoryCost.value)
    },

    isCertified () {
      return CertificationStatus.isCertified(this.contract.accountCertificationStatus)
    },

    cardColumnWidth () {
      return this.tractLandowners.length > 0 && this.tractLandowners.length < 6 ? 4 : 6
    },

    landownersPanelString () {
      return `${this.tractLandowners.reduce((a, lo) => { return lo.ownership > a.ownership ? lo : a }).accountName}${this.tractLandowners?.length > 1 ? ', ' + this.$t('nMore', { n: this.tractLandowners.length - 1 }) : ''}`
    },

    landownerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'accountName'
        },
        {
          text: this.$t('ownership'),
          value: 'ownership',
          align: 'right'
        }
      ]
    }
  },

  created () {
    if (this.contractTypeIsWoodsSale) this.initializeProfitByProductsMap()
  },

  methods: {
    utcToLocalDate,
    numberWithCommas,
    formatMoney,
    formatPercentage,
    contractTypeFromInt: (type) => ContractType.fromInt(type),

    getProductAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.contract.products[index - 1].name
      } else {
        return ''
      }
    },

    getProductProfitRateAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.profitByProduct.get(this.contract.products[index - 1].productId)
      }
    },

    initializeProfitByProductsMap () {
      if (this.showPayByLoadWarning) return undefined
      this.profitByProduct = this.allActivities
        .filter(a => a.activityTemplate.costType !== CostType.NonInventory.value)
        .reduce((productMap, activity) => {
          const factor = activity.activityTemplate.category === TemplateAccountingCategory.AccountsReceivable.value ? 1 : -1
          activity.activityDetails.forEach(ad => {
            const amount = activity.activityTemplate.modifier === ActivityModifier.DistanceTraveled.value
              ? ad.cost.rate * this.contract.distance * factor
              : ad.cost.rate * factor

            const existingAmount = productMap.get(ad.product.productId) ?? 0
            const updatedAmount = existingAmount + amount

            productMap.set(ad.product.productId, updatedAmount)
          })
          return productMap
        }, new Map())
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined ::v-deep(fieldset) {
  border-color: #D15F27;
}
</style>
