import QRCode from 'qrcode'
import { createCanvas, loadImage } from 'canvas'

export async function getLegnaQrCodeUrl (width, qrContent) {
  const version = getQrVersion(qrContent.length)
  const centerWidth = getDensityRatio(version, width)
  const center = (width - centerWidth) / 2
  const logoWidth = centerWidth * 0.85
  const logoCenter = (width - logoWidth) / 2

  const canvas = createCanvas(width, width)

  QRCode.toCanvas(
    canvas,
    qrContent,
    {
      errorCorrectionLevel: 'H',
      margin: 1,
      width: width,
      version: version
    }
  )

  const ctx = canvas.getContext('2d')

  const img = await loadImage('./img/Legna-Icon-BLACK.svg')
  img.width = width
  img.height = width

  ctx.imageSmoothingEnabled = false

  ctx.fillStyle = 'white'
  ctx.fillRect(center, center, centerWidth, centerWidth)

  ctx.drawImage(img, logoCenter, logoCenter, logoWidth, logoWidth)

  return canvas.toDataURL('image/svg')
}

// need to know the density of the qr code to make the size of the legna logo right in the qr code,
// the density of qr codes are called versions. there is more that goes into calculating the version
// of the qr code other than the number of characters,
// so we are forcing the version based on the length of the string itself
// https://www.qrcode.com/en/about/version.html

function getQrVersion (length) {
  if (length < 8) {
    return 1
  } else if (length < 15) {
    return 2
  } else if (length < 25) {
    return 3
  } else if (length < 35) {
    return 4
  } else if (length < 45) {
    return 5
  } else if (length < 55) {
    return 6
  } else if (length < 65) {
    return 7
  } else if (length < 75) {
    return 8
  } else if (length < 85) {
    return 9
  } else if (length < 100) {
    return 10
  } else if (length < 110) {
    return 11
  } else if (length < 120) {
    return 12
  } else if (length < 130) {
    return 13
  } else {
    throw new Error('legnaQrCode does not support strings larger than 130 characters')
  }
}

function getDensityRatio (version, width) {
  if (version === 1) {
    return width * 0.314
  } else if (version === 2) {
    return width * 0.34
  } else if (version === 3) {
    return width * 0.359
  } else if (version === 4) {
    return width * 0.38
  } else if (version === 5) {
    return width * 0.34
  } else if (version === 6) {
    return width * 0.355
  } else if (version === 7) {
    return width * 0.367
  } else if (version === 8) {
    return width * 0.337
  } else if (version === 9) {
    return width * 0.352
  } else if (version === 10) {
    return width * 0.36
  } else if (version === 11) {
    return width * 0.366
  } else if (version === 12) {
    return width * 0.376
  } else if (version === 13) {
    return width * 0.355
  } else {
    throw new Error('legnaQrCode does not support strings larger than 130 characters')
  }
}
