import ApiServer from './api/ApiServer'
import axios from 'axios'
import AuditEntry from '../model/AuditEntry'

export const getAuditEntries = async (entityType, entityId) => {
  const url = await getSasUri()
  url.searchParams.append('$filter', getEntryFilter(entityType, entityId))
  const headers = {
    'x-ms-date': new Date(Date.now()).getUTCDate(),
    Accept: 'application/json;odata=nometadata, */*'
  }
  const response = await axios.get(url, {
    headers
  })
  const entries = (response.data?.value ?? []).map(d => AuditEntry.create(d, entityType))
  entries.sort((a, b) => Date.parse(b.modifiedAt) - Date.parse(a.modifiedAt))
  return entries
}

const getEntryFilter = (entityType, entityId) => {
  const pk = `PartitionKey eq '${entityType}'`
  const rk = (`${entityId}`.match(/^9+$/)) ? `RowKey ge '${entityId}-' and RowKey lt '${entityId}0-'` : `RowKey ge '${entityId}-' and RowKey lt '${entityId + 1}-'`
  return `${pk} and ${rk}`
}

let sasUri
const getSasUri = async () => {
  if (!sasUri || Date.parse(sasUri.searchParams.get('se')) < Date.now()) {
    const response = await ApiServer.get(ApiServer.urlFor('companyinfo/tableinfo/auditchanges'))
    sasUri = new URL(response.data[0].tableSasUri)
  }
  return new URL(sasUri)
}
