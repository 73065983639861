import messages from '@/lang'

export default {
  detailHeadersWithCRUD (locale) {
    return [
      {
        sortable: true,
        text: messages[locale].product,
        value: 'product.name'
      },
      {
        sortable: true,
        text: messages[locale].rate,
        value: 'cost.rate',
        align: 'right'
      },
      {
        text: messages[locale].actions,
        value: 'action',
        align: 'center'
      }
    ]
  },

  detailHeadersNoCRUD (locale) {
    return ([
      {
        sortable: true,
        text: messages[locale].product,
        value: 'product.name'
      },
      {
        sortable: true,
        text: messages[locale].rate,
        value: 'cost.rate',
        align: 'right'
      }
    ])
  }
}
