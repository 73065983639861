import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchDefectsForLocation (context, locationId) {
    const path = `locations/${locationId}/defectdefinitions`
    const response = await ApiServer.get(path)
    context.commit('setLocationDefects', response.data)
    return response.data
  },

  async updateLocationDefect (_, defect) {
    const path = `locations/${defect.locationId}/defectdefinitions/${defect.defectDefinitionId}`
    const response = await ApiServer.put(path, defect)
    return response.data
  },

  async deleteLocationDefect (_, defect) {
    const path = `locations/${defect.locationId}/defectdefinitions/${defect.defectDefinitionId}`
    const response = await ApiServer.delete(path, defect)
    return response.data
  },

  async createLocationDefect (_, defect) {
    const path = `locations/${defect.locationId}/defectdefinitions`
    const response = await ApiServer.post(path, defect)
    return response.data
  },

  async fetchDefectsForTicketById (context, ticketId) {
    const path = `v3/tickets/${ticketId}/defects`
    const response = await ApiServer.get(path)
    context.commit('setTicketDefects', response.data)
    return response.data
  },

  async fetchDiameterInfoForLocation (_, locationId) {
    const path = `locations/${locationId}/diameterweights`
    const { data: diameterWeights } = await ApiServer.get(path)
    return diameterWeights
  },
  async putDiameterWeights (_, { diameterWeights, locationId }) {
    const path = `locations/${locationId}/diameterweights`
    await ApiServer.put(path, {
      diameterWeights: diameterWeights
    })
  },
  async updateTicketDefect (_, defect) {
    const path = `v3/tickets/${defect.ticketId}/defects/${defect.ticketDefectId}`
    const response = await ApiServer.put(path, defect)
    return response.data
  },
  async createLocationDefects (_, payload) {
    const { defects, ticket } = payload
    const path = `v3/tickets/${ticket.ticketId}/defects`
    const response = await ApiServer.post(path, defects)
    return response.data
  },
  async deleteAllTicketDefects (_, ticket) {
    const path = `v3/tickets/${ticket.ticketId}/defects`
    const response = await ApiServer.delete(path)
    return response.data
  }
}
