import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import JSZip from 'jszip'
import store from '@/store/index.js'
import {
  defaultDocumentConfig,
  nestedTableStyling,
  getHeaderForSettlement,
  getAdditionalFinancialTables,
  formatAddress,
  getDateInfo,
  getFileNameForPeriod,
  getHeader,
  fileNameForString
} from './GenericSettlementsFunctions.js'
import { getCoverPageForPayee } from './SettlementCoverPage.js'
import {
  getReportForContract
} from './SinglePDFSettlementsReport.js'
import { companyLogoClient, PdfLogoDimensions } from '@/utils/CompanyLogo'

PDFMake.vfs = pdfFonts.pdfMake.vfs

export const generateZippedSettlementReport = async (settlementsByPayee, options = {}) => {
  const { exportBatch } = options
  const dateInfo = getDateInfo(settlementsByPayee, exportBatch)
  const companyInfoId = store.getters['user/companyInfo'].companyInfoId
  const logoAsDataUrl = await companyLogoClient.getCompanyLogoAsDataUrl(companyInfoId)
  const logoTopMargin = await companyLogoClient.getTopMarginToCenterImage(companyInfoId)

  const pdfData = [
    ...(await Promise.all(getCoverPages(settlementsByPayee, dateInfo, { logoAsDataUrl, logoTopMargin }))),
    ...(await Promise.all(getContractPDFData(settlementsByPayee, dateInfo, { logoAsDataUrl, logoTopMargin }))),
    ...(await Promise.all(getOtherTables(settlementsByPayee, dateInfo, { logoAsDataUrl, logoTopMargin })))
  ]

  if (pdfData.length === 0) {
    throw new Error('No data available to download.')
  }

  const zip = new JSZip()
  pdfData.forEach(pdf => {
    const folderName = fileNameForString(pdf.payeeName)
    const folder = zip.folder(folderName)
    folder.file(pdf.fileName, pdf.data)
  })

  const downloadableZip = await zip.generateAsync({ type: 'blob' })
  return {
    blob: downloadableZip,
    filename: getFileNameForPeriod(dateInfo)
  }
}

const getBlobFromPdf = (pdfData) => {
  return new Promise((resolve) => {
    const pdf = PDFMake.createPdf(pdfData.docDefinition)
    delete pdfData.docDefinition
    pdf.getBlob(data => {
      resolve({
        ...pdfData,
        data: data
      })
    })
  })
}

const getOtherTables = (settlements, dateInfo, { logoAsDataUrl, logoTopMargin }) => {
  return settlements.payees.reduce((tableData, payee) => {
    const additionalTables = getAdditionalFinancialTables(payee.tractPayables, payee.advances, payee.corrections, payee.recoveryInfo, payee.accountPayables)
    additionalTables.forEach(table => {
      tableData.push({
        payeeName: payee.payeeName,
        fileName: fileNameForString(`${payee.payeeName}-${getTableName(table.type)}`, 'pdf'),
        docDefinition: {
          ...defaultDocumentConfig,
          header: getHeader({ logoAsDataUrl, logoTopMargin }),
          content: [
            {
              table: {
                widths: ['*'],
                body: [
                  getHeaderForSettlement(dateInfo, payee),
                  [table.table]
                ]
              },
              ...nestedTableStyling
            }
          ]
        }
      })
    })
    return tableData
  }, []).map(pdfData => getBlobFromPdf(pdfData))
}

const getTableName = (type) => {
  switch (type) {
    case 'tp':
      return 'tract-payables'
    case 'ad':
      return 'advances'
    case 'co':
      return 'ticket-corrections'
    case 'ap':
      return 'account-payments'
  }
}
const getCoverPages = (obj, dateInfo, { logoAsDataUrl, logoTopMargin }) => {
  return obj.payees.reduce((coverPages, payee) => {
    coverPages.push({
      payeeName: payee.payeeName,
      fileName: fileNameForString(`${payee.payeeName}-summary`, 'pdf'),
      docDefinition: {
        ...defaultDocumentConfig,
        header: getHeaderForCoverPage({ logoAsDataUrl, logoTopMargin }),
        content: getCoverPageForPayee(dateInfo, payee, { pageBreak: false })
      }
    })
    return coverPages
  }, []).map(getBlobFromPdf)
}

const getContractPDFData = (obj, dateInfo, { logoAsDataUrl, logoTopMargin }) => {
  const getFileNameForContract = (contract, payee) => ((contract.tractName && contract.settingName)
    ? fileNameForString(`${payee.payeeName}-${contract.tractName}-${contract.destinationName}-${contract.accountName}-${contract.settingName}`, 'pdf')
    : fileNameForString(`${payee.payeeName}-${contract.accountName}-${contract.fromAccountName}-${contract.destinationName}`, 'pdf'))

  return obj.payees.reduce((contractData, payee) => {
    contractData.push(
      ...payee.contracts.map((contract) => ({
        payeeName: payee.payeeName,
        fileName: getFileNameForContract(contract, payee),
        docDefinition: {
          ...defaultDocumentConfig,
          header: getHeader({ logoAsDataUrl, logoTopMargin }),
          content: getReportForContract(dateInfo, payee, contract, true)
        }
      }))
    )

    return contractData
  }, []).map(pdfData => getBlobFromPdf(pdfData))
}

const getHeaderForCoverPage = ({ logoAsDataUrl, logoTopMargin }) => {
  const companyInfo = store.getters['user/companyInfo']
  const companyName = companyInfo.name ? companyInfo.name : ''
  const companyAddress = formatAddress(companyInfo)
  const companyPhoneNumber = companyInfo.mainContact && companyInfo.mainContact.phoneNumber ? companyInfo.mainContact.phoneNumber : ''
  const companyInfoSection = (logoAsDataUrl === undefined || logoTopMargin === undefined)
    ? [
      {
        table: {
          body: [
            [''],
            [{ text: companyName, alignment: 'left' }],
            [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
            [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
          ]
        },
        layout: 'noBorders'
      }
    ]
    : [
      {
        image: logoAsDataUrl,
        maxWidth: PdfLogoDimensions.LOGO_MAX_WIDTH,
        maxHeight: PdfLogoDimensions.LOGO_MAX_HEIGHT,
        marginTop: logoTopMargin,
        alignment: 'center'
      },
      {
        table: {
          body: [
            [''],
            [{ text: companyName, alignment: 'left' }],
            [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
            [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
          ]
        },
        layout: 'noBorders'
      }
    ]
  return {
    fontSize: 15,
    bold: true,
    marginLeft: 40,
    marginRight: 40,
    table: {
      widths: '*',
      body: [
        [
          {
            table: {
              body: [
                companyInfoSection
              ]
            },
            layout: 'noBorders'
          },
          {
            alignment: 'center',
            table: {
              widths: '*',
              body: [
                [''],
                [''],
                [{ text: 'Settlement Statement Summary', fontSize: 15, aligntment: 'center' }]
              ]
            },
            layout: 'noBorders'
          },
          { text: '' }
        ]
      ]
    },
    layout: 'noBorders'
  }
}
