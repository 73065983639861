import ApiServer from '@/utils/api/ApiServer.js'
import { saveAs } from 'file-saver'
import { utcToLocalDate } from '@/utils/DateFormatter.js'

export default {
  async takeOnDemandSnapshot (_) {
    const path = 'adjustingentries'
    const response = await ApiServer.post(path)
    return response.data
  },

  async snapshotsList (_) {
    const path = 'adjustingentries'
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchSnapshot (_, timestamp) {
    const path = `adjustingentries/${timestamp.adjustingEntriesSnapshotRecordId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async patchSnapshotRecord (_, { snapshotRecordId, body }) {
    const url = ApiServer.urlFor(`adjustingentries/${snapshotRecordId}`)
    const response = await ApiServer.patch(url, body)
    return response.data
  },

  async fetchAdjustingEntriesCSV (_, { snapshotId, type, periodEndDate }) {
    const path = `adjustingentries/${snapshotId}/${type}`
    const response = await ApiServer.get(path, { headers: { 'Content-Type': 'text/csv' } })
    generateCSV(response.data, type, periodEndDate)
  }
}

const generateCSV = (csvData, name, periodEndDate) => {
  const parsedName = getCSVName(name)
  const csvFile = new Blob([csvData], { type: 'text/csv' })
  saveAs(csvFile, `${parsedName}_${utcToLocalDate(periodEndDate, 'YYYYMMDD')}.csv`)
}

const getCSVName = (name) => {
  switch (name) {
    case 'apcsvexport':
      return 'AP'
    case 'jecsvexport':
      return 'AC'
    case 'arcsvexport':
      return 'AR'
  }
}
