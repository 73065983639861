import DialogState from '../../../model/DialogState'

export default {
  openDialog ({ commit }, args) {
    commit('pushDialog', new DialogState((typeof args === 'string') ? args : args?.id, args?.width ?? '', args?.fullscreen ?? false, args?.allowFullscreen ?? true))
  },
  openOrUpdateDialog ({ commit }, args) {
    commit('pushOrSetDialog', new DialogState((typeof args === 'string') ? args : args?.id, args?.width ?? '', args?.fullscreen ?? false, args?.allowFullscreen ?? true))
  },
  setTopDialogFullscreen ({ commit }, isFullscreen) {
    commit('setTopDialogFullscreen', isFullscreen)
  },

  setDialogFullscreenById ({ commit }, { id, fullscreen }) {
    commit('setDialogFullscreenById', { id, fullscreen })
  },

  closeDialog ({ commit }) {
    commit('requestCloseDialog')
  },
  closeDialogById ({ commit }, dialogId) {
    commit('requestCloseDialogById', dialogId)
  },
  closeDialogsAtOrAbove ({ commit }, dialogId) {
    commit('requestCloseDialogsAtOrAbove', dialogId)
  },
  closeAllDialogs ({ commit }) {
    commit('requestCloseAllDialogs')
  },

  forceCloseDialog ({ commit }) {
    commit('popDialog')
  },
  forceCloseDialogsAtAndAboveId ({ commit }, dialogId) {
    commit('popAllDialogsAtAndAbove', dialogId)
  }
}
