<template>
  <v-card data-testid="activity-template-form">
    <v-card-title class="secondary white--text">
      <span class="headline mr-2">{{ title }}</span>
      <Icon
      v-if="isEditing && template.specialization === TemplateSpecialization.Transfer.value"
      icon="mdi-transfer"
      iconColor="white"
      tooltipColor="secondary"
      :tooltipText="$t('transferActivity')"
      :small="false"/>
      <Icon
      v-if="isEditing && template.specialization === TemplateSpecialization.Depletion.value"
      icon="mdi-wallet-bifold"
      tooltipColor="secondary"
      :tooltipText="$t('depletionActivity')"
      iconColor="white"
      :small="false"/>
      <v-spacer />
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="templateForm"
        testId="save-activity-template"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveTemplate(false)"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" class="mb-n3 mt-4">
              <span class="title">{{ $t("templateDetails") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" :md="hideCostType ? 4 : 6">
              <v-text-field
                v-model="template.name"
                ref="firstField"
                :label="$t('name')"
                :rules="[rules.required]"
                data-testid="template-name"
                color="black"
                maxlength="64"
                counter
              ></v-text-field>
            </v-col>
            <v-col v-if="!hideCostType" cols="12" xs="12" :sm="hideCostType ? 6 : 12" :md="hideCostType ? 4 : 6">
              <v-select
                v-model="template.costType"
                :label="$t('costType')"
                :items="costTypes"
                :rules="[rules.required]"
                item-color="secondary"
                item-text="name"
                item-value="value"
                return-object
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" :md="hideCostType ? 4 : 6">
              <v-text-field
                v-model="template.glCode"
                :label="$t('glCode')"
                :rules="[rules.required]"
                data-testid="template-gl-code"
                color="black"
                counter
                maxlength="32"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" :md="hideCostType ? 4 : 6">
              <v-text-field
                v-model="template.glOffset"
                :label="$t('glCodeOffset')"
                :rules="glOffsetRequired ? [rules.required] : []"
                data-testid="template-gl-offset"
                color="black"
                maxlength="32"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <EntitySelector
                :entities="businessEntities"
                :initialEntity.sync="initialEntity"
                @entity-selected="entityChosen"
                class="mr-2"
              />
            </v-col>
            <v-col cols="12" xs="12" v-if="template.specialization !== TemplateSpecialization.Transfer.value">
              <span class="title">{{ $t("baseCost") }}</span>
            </v-col>
            <v-col cols="12" xs="12" v-if="template.specialization !== TemplateSpecialization.Transfer.value">
              <BaseCostForm
                :propBaseCost="template.baseCost"
                :editing="isEditing"
                :accountingCategory="chosenCategory"
                @base-cost-changed="baseCostChanged"
                @is-by-load="byLoadChanged"
                :isBaseCostForm="true"
              />
            </v-col>
            <v-col cols="12" xs="6" sm="4" v-if="template.specialization !== TemplateSpecialization.Transfer.value">
              <v-select
                v-model="template.modifier"
                :items="modifiers"
                :disabled="disabledModifierHint !== undefined"
                :hint="disabledModifierHint"
                :label="$t('modifier')"
                data-testid="template-modifier"
                item-color="secondary"
                item-text="name"
                item-value="value"
                return-object
                persistent-hint
                color="black"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="6" sm="4">
              <v-select
                data-testid="template-status"
                v-if="isEditing"
                :items="statuses"
                :label="$t('status')"
                item-text="name"
                item-value="value"
                return-object
                item-color="secondary"
                color="black"
                v-model="template.activityStatus"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <span class="title">{{ $t('properties') }}</span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" class="mt-n3">
              <v-row dense align="center">
                <v-col cols="2">
                  <v-checkbox
                  :disabled="selectedSpecialization.value === TemplateSpecialization.Transfer.value"
                  :label="$t('tractLevelCapture')"
                  color="secondary"
                  data-testid="template-tract-level-capture"
                  v-model="template.tractLevelCapture"
                  />
                </v-col>
                <v-col cols="2" v-if="mutateAccrualConfigs">
                  <v-select
                  :label="$t('templateSpecialization')"
                  v-model="selectedSpecialization"
                  :items="TemplateSpecialization.enums"
                  item-text="name"
                  return-object
                  />
                </v-col>
                <v-col cols="2" id="applyChangesToExistingContracts">
                  <v-checkbox
                  :label="$t('applyChangesToExistingContracts')"
                  color="secondary"
                  v-if="isEditing"
                  data-testid="template-apply-check"
                  v-model="applyChangesDownstream"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" >
              <ProductDetails
              v-if="isEditing && template.specialization !== TemplateSpecialization.Transfer.value"
              :propProductDetails="template.details"
              @new-product-detail-clicked="openProductDetailForm"
              @edit-template-detail-clicked="openProductDetailForm"
              @delete-template-detail-clicked="confirmDeleteTemplateDetail"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
    <v-dialog width="400" v-model="confirmDialog">
      <ConfirmDialog
        :title="$t('confirmUpdate')"
        :body="$t('confirmActivityUpdate')"
        :confirmText="$t('yes')"
        @confirm="saveTemplate(true)"
        @cancel="confirmDialog = false"
      />
    </v-dialog>
    <v-dialog width="400" v-model="confirmDeleteDetailDialog">
      <ConfirmDialog
        v-if="focusedDetail"
        :title="$t('deleteTemplateDetail', { productName: focusedDetail.product })"
        :body="$t('deleteTemplateDetailBody')"
        @confirm="deleteTemplateConfirmed"
        @cancel="closeDialogs"
      />
    </v-dialog>
    <v-dialog v-model="templateDetailForm"
    width="600px">
      <ProductDetailForm
      v-if="templateDetailForm"
      :propTemplateDetail="focusedDetail"
      :existingDetails="template.details"
      @close="closeDialogs"
      @template-detail-updated="templateDetailMutated"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import * as fieldRules from '@/utils/rules'
import { mapActions, mapGetters } from 'vuex'
import { ActivityModifier, CostType, ActivityStatus, PayOn, PayBy, AccountingCategory, TemplateSpecialization } from '@/utils/Enumerations.js'
export default {
  name: 'TemplateForm',

  components: {
    BaseCostForm: () => import('@/components/activity/BaseCostForm.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    ProductDetails: () => import('@/components/settings-components/activity-template/ProductDetails.vue'),
    ProductDetailForm: () => import('@/components/settings-components/activity-template/ProductDetailForm.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propTemplate: {
      type: Object,
      default: undefined
    },
    initialEntity: {
      type: Object,
      default: undefined
    },
    chosenCategory: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    TemplateSpecialization,
    confirmDialog: false,
    confirmDeleteDetailDialog: false,
    templateDetailForm: false,
    applyChangesDownstream: false,
    focusedDetail: undefined,
    rules: fieldRules.default.rules,
    maxBaseRate: fieldRules.MAX_ACTIVITY_RATE,
    modifiers: ActivityModifier.enums,
    statuses: ActivityStatus.enums,
    savedModifier: ActivityModifier.None,
    selectedSpecialization: TemplateSpecialization.None,
    template: {
      name: '',
      glCode: '',
      glOffset: '',
      activityStatus: ActivityStatus.Active,
      category: AccountingCategory.Payable.value,
      modifier: ActivityModifier.None,
      tractLevelCapture: false,
      transfer: false,
      depletion: false,
      businessEntityId: null,
      costType: null,
      details: [],
      baseCost: {
        rate: 0.00,
        payBy: PayBy.Weight.value,
        payOn: PayOn.Net.value
      },
      specialization: TemplateSpecialization.None.value
    }
  }),

  mounted () {
    this.$nextTick(this.$refs.firstField?.focus)
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    title () {
      return this.isEditing
        ? this.$t('editingActivityTemplate', { type: AccountingCategory.fromInt(this.chosenCategory) })
        : this.$t('creatingActivityTemplate', { type: AccountingCategory.fromInt(this.chosenCategory) })
    },

    isEditing () {
      return this.propTemplate?.activityTemplateId !== undefined
    },

    mutateAccrualConfigs () {
      return !this.isEditing && this.chosenCategory === AccountingCategory.Accrual.value
    },

    glOffsetRequired () {
      return this.template.category === AccountingCategory.Receivable.value ||
        this.template.category === AccountingCategory.Accrual.value
    },

    saveBtnText () {
      return this.isEditing
        ? this.$t('saveChanges')
        : this.$t('createNewTemplate')
    },

    costTypes () {
      return CostType.enums.filter(ct => ct !== CostType.Unspecified)
    },

    hideCostType () {
      return this.template.category === AccountingCategory.Receivable.value
    },

    disabledModifierHint () {
      return this.template.baseCost.payBy === PayBy.Load.value
        ? this.$t('disTemplateModifierHint')
        : undefined
    }
  },

  watch: {
    confirmDeleteDetailDialog (val) {
      if (!val) {
        this.focusedDetail = undefined
      }
    },

    templateDetailForm (val) {
      if (!val) {
        this.focusedDetail = undefined
      }
    },

    selectedSpecialization: {
      handler (s) {
        if (s === TemplateSpecialization.Transfer) {
          this.template.tractLevelCapture = false
        }
      },
      deep: true
    }
  },

  async created () {
    this.template.businessEntityId = this.initialEntity?.businessEntityId
    this.template.category = this.chosenCategory

    if (this.isEditing) {
      this.template = JSON.parse(JSON.stringify(this.propTemplate))
      this.selectedSpecialization = TemplateSpecialization.forInt(this.template.specialization)
      this.template.costType = CostType.forInt(this.propTemplate.costType)
      this.template.modifier = ActivityModifier.forInt(this.propTemplate.modifier)
      this.template.activityStatus = ActivityStatus.forInt(this.propTemplate.activityStatus)
    }
  },

  methods: {
    ...mapActions('activity-templates', [
      'createActivityTemplate',
      'updateActivityTemplate',
      'updateTemplateDetail',
      'deleteTemplateDetail',
      'createTemplateDetail',
      'fetchTemplateDetails'
    ]),

    baseCostChanged (baseCost) { this.template.baseCost = baseCost },
    entityChosen ({ businessEntityId }) { this.template.businessEntityId = businessEntityId },

    async refreshDetails () {
      const details = await this.fetchTemplateDetails(this.template.activityTemplateId)
      this.template.details = details
    },

    confirmDeleteTemplateDetail (templateDetail) {
      this.focusedDetail = templateDetail
      this.confirmDeleteDetailDialog = true
    },

    async deleteTemplateConfirmed () {
      await this.deleteTemplateDetail({
        activityTemplateId: this.template.activityTemplateId,
        activityTemplateDetailId: this.focusedDetail.activityTemplateDetailId
      })
      this.closeDialogs()
      this.refreshDetails()
    },

    closeDialogs () {
      this.templateDetailForm = false
      this.confirmDeleteDetailDialog = false
      this.focusedDetail = undefined
    },

    openProductDetailForm (templateDetail = undefined) {
      if (templateDetail !== undefined) {
        this.focusedDetail = JSON.parse(JSON.stringify(templateDetail))
      }
      this.templateDetailForm = true
    },

    async templateDetailMutated (templateDetail) {
      if (templateDetail.activityTemplateDetailId === undefined) {
        await this.createTemplateDetail({
          templateDetail: templateDetail,
          activityTemplateId: this.template.activityTemplateId
        })
      } else {
        await this.updateTemplateDetail({
          templateDetail: templateDetail,
          activityTemplateId: this.template.activityTemplateId
        })
      }

      this.closeDialogs()
      this.refreshDetails()
    },

    async saveTemplate (forceChanges) {
      if (!this.$refs.form.$refs.templateForm.validate()) {
        if (this.template.baseCost.rate > this.maxBaseRate) {
          this.setSnackError(this.$t('invalidActivityRate'))
        }
        return
      }

      if (this.selectedSpecialization.value === TemplateSpecialization.Transfer.value && this.template.costType !== CostType.Production && this.template.costType !== CostType.NonInventory) {
        this.setSnackError(this.$t('transferActivityCostTypeError'))
        return
      }

      if (this.selectedSpecialization.value === TemplateSpecialization.Depletion.value) {
        if (this.template.costType.value !== CostType.Stumpage.value) {
          this.setSnackError(this.$t('depletionActivityMustHaveStumpageCostType'))
          return
        }

        if (this.template.baseCost.payBy !== PayBy.Weight.value) {
          this.setSnackError(this.$t('depletionActivityMustPayByWeight'))
          return
        }

        if (this.template.baseCost.payOn !== PayOn.Gross.value) {
          this.setSnackError(this.$t('depletionActivityMustPayOnGross'))
          return
        }

        if (this.template.modifier !== ActivityModifier.None) {
          this.setSnackError(this.$t('depletionActivityCannotHaveModifier'))
          return
        }
      }

      const formattedTemplate = this.getFormattedTemplate()

      if (this.isEditing) {
        if (this.isBaseCostChanged() && this.applyChangesDownstream && !forceChanges) {
          this.confirmDialog = true
          return
        }

        const updateRequest = {
          template: formattedTemplate,
          applyChangesDownstream: this.applyChangesDownstream
        }

        await this.updateActivityTemplate(updateRequest)
        this.$emit('template-mutated')
      } else {
        await this.createActivityTemplate(formattedTemplate)
        this.$emit('template-mutated')
      }
    },

    isBaseCostChanged () {
      return (
        this.template.baseCost?.rate !== this.propTemplate.baseCost?.rate ||
        this.template.baseCost?.payOn !== this.propTemplate.baseCost?.payOn ||
        this.template.baseCost?.payBy !== this.propTemplate.baseCost?.payBy
      )
    },

    getFormattedTemplate () {
      return {
        activityTemplateId: this.isEditing ? this.template.activityTemplateId : null,
        baseCost: this.selectedSpecialization.value === TemplateSpecialization.Transfer.value ? null : this.template.baseCost,
        category: this.template.category,
        glCode: this.template.glCode,
        glOffset: this.template.glOffset,
        costType: this.template.category === AccountingCategory.Receivable.value ? CostType.Unspecified.value : this.template.costType.value,
        businessEntityId: this.template.businessEntityId,
        name: this.template.name,
        tractLevelCapture: this.template.tractLevelCapture,
        activityStatus: this.template.activityStatus.value,
        modifier: this.selectedSpecialization.value === TemplateSpecialization.Transfer.value ? ActivityModifier.None.value : this.template.modifier.value,
        specialization: this.selectedSpecialization.value
      }
    },

    byLoadChanged (isByLoad) {
      if (isByLoad) {
        this.savedModifier = this.template.modifier
        this.template.modifier = ActivityModifier.None
      } else {
        this.template.modifier = this.savedModifier
      }
    }
  }
}
</script>
