import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchTags (_, area) {
    const response = await ApiServer.get('tags', {
      headers: {
        area: area
      }
    })
    var sortedData = response.data.sort((a, b) => a.value.localeCompare(b.value))
    return sortedData
  },

  async fetchAccountTags (context) {
    context.commit('setAccountTagsLoading', true)
    const response = await ApiServer.get('tags', {
      headers: {
        area: 0
      }
    })
    var sortedData = response.data.sort((a, b) => a.value.localeCompare(b.value))
    context.commit('setAccountTags', sortedData)
    context.commit('setAccountTagsLoading', false)
    return sortedData
  },

  async createTag (_, tag) {
    const response = await ApiServer.post('tags', tag)
    return response.data
  },

  async deleteTag (_, tagId) {
    const response = await ApiServer.delete(`tags/${tagId}`)
    return response.data
  },

  async updateTag (_, tagRequest) {
    const { tagId, tag } = tagRequest
    const response = await ApiServer.put(`tags/${tagId}`, tag)
    return response.data
  }
}
