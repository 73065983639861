<template>
  <div>
    <v-row no-gutters>
      <Selector
      :items="timeframes"
      :initialItem="chosenTimeframe"
      label="Timeframe"
      @item-selected="timeframeChosen"
      useCustomSlots
      itemValue="label">
        <template #selection="{item}"> {{$t(item.label)}} </template>
        <template #item="{item}">{{$t(item.label)}}</template>
        <template #append-outer="{item}">
          <v-btn v-if="item.appendOuterAction" icon color="secondary" class="ma-0 mt-n2 pa-0" @click="item.appendOuterAction">
            <v-icon>
              {{ item.appendOuter }}
            </v-icon>
          </v-btn>
        </template>
      </Selector>
      <span class="ml-3 mt-3 title">{{ dateString }}</span>
    </v-row>

    <v-dialog v-model="customDateRangeDialog" width="400px">
      <CustomDateRangeForm
        :sinceTime="sinceTime"
        :untilTime="untilTime"
        :max="31"
        @dates-chosen="customTimePeriodChosen"
        @close="customDateRangeDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { utcToLocalDate, timeframesInLocalTime as tf, timeframeToUtcIsoString } from '@/utils/DateFormatter.js'

export default {
  name: 'TimeframePicker',

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    CustomDateRangeForm: () => import('@/components/dashboard/CustomDateRangeForm.vue')
  },

  data: () => ({
    timeframes: [
      tf.today,
      tf.yesterday,
      tf.last7Days,
      tf.last14Days,
      tf.thisWeek,
      {
        label: 'custom',
        resolve: tf.last7Days.resolve,
        appendOuter: 'mdi-clock-edit-outline',
        appendOuterAction: undefined
      }
    ].map(timeframeToUtcIsoString),
    customDateRangeDialog: false,
    chosenTimeframe: timeframeToUtcIsoString(tf.last7Days),
    sinceTime: '',
    untilTime: ''
  }),

  computed: {
    dateString () {
      return `${utcToLocalDate(this.sinceTime)}-${utcToLocalDate(this.untilTime)}`
    }
  },

  created () {
    this.chosenTimeframe = this.timeframes[2]
    this.timeframes
      .find(tf => tf.label === 'custom')
      .appendOuterAction = () => { this.customDateRangeDialog = true }
  },

  watch: {
    chosenTimeframe: {
      handler (timeframe) {
        const r = timeframe.resolve()
        this.sinceTime = r.sinceTime
        this.untilTime = r.untilTime
        this.$emit('date-range-chosen', r)
      },
      immediate: true
    }
  },

  methods: {
    utcToLocalDate,
    customTimePeriodChosen (dateRange) {
      this.customDateRangeDialog = false
      this.chosenTimeframe = {
        label: 'custom',
        resolve: () => ({
          sinceTime: dateRange.sinceTime,
          untilTime: dateRange.untilTime
        })
      }
    },

    timeframeChosen (timeframe) {
      if (this.chosenTimeframe === timeframe) return
      if (timeframe.label === 'custom') {
        this.customDateRangeDialog = true
        return
      }
      this.chosenTimeframe = timeframe
    }
  }
}
</script>
