<template>
  <v-data-table
    :items="certifications"
    :headers="headers"
    dense
  >
    <template #item.name="{item}">
      {{ item.name }}
    </template>
    <template #item.code="{item}">
      {{ item.code }}
    </template>
    <template #item.effectiveDate="{item}">
      {{ utcToLocalDate(item.effectiveDate) }}
    </template>
    <template #item.expirationDate="{item}">
      {{ utcToLocalDate(item.expirationDate) }}
    </template>
    <template #item.certificateNumber="{item}">
      {{ item.certificateNumber }}
    </template>
    <template #item.actions="{item}">
      <Icon
      icon="mdi-file-download-outline"
      :tooltipColor="downloadIconColor(item)"
      :iconColor="downloadIconColor(item)"
      :tooltipText="downloadFileTooltip(item)"
      @icon-clicked="downloadFile(item)"
    />
    </template>
  </v-data-table>
</template>

<script>
import CertificationHeaders from '@/headers/Certification'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'

export default {
  name: 'AccountCertificationsTable',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    certifications: {
      type: Array,
      required: true
    },
    files: {
      type: Array,
      required: false,
      default: undefined
    }
  },

  computed: {
    headers () {
      return CertificationHeaders.accountCertificationHeaders()
    }
  },

  methods: {
    utcToLocalDate,

    getFileByPolicy (policy) {
      return this.files?.find(f => Number(f?.policyId) === policy?.certificationId)
    },

    async downloadFile (policy) {
      const fileRef = this.getFileByPolicy(policy)?.ref
      if (fileRef) {
        const fetchedFile = await fetch(fileRef)
        const blob = await fetchedFile.blob()
        saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
      }
    },

    downloadFileTooltip (policy) {
      if (this.getFileByPolicy(policy)) {
        return `${this.$t('downloadFile')}
        (${this.getFileByPolicy(policy).name})`
      } else {
        return this.$t('noFileAvailableForDownload')
      }
    },

    downloadIconColor (policy) {
      if (this.getFileByPolicy(policy)) {
        return 'black'
      } else {
        return 'grey'
      }
    }
  }
}
</script>
