<template>
  <div>
    <v-row v-if="editMode">
      <v-col>
        <span class="subtitle-1">
          {{ $t('certifications') }}
        </span>
        <Icon
          icon="mdi-plus"
          :small="false"
          :tooltipText="$t('addCertification')"
          @icon-clicked="openForm(undefined)"
          tabindex="0"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(certification, index) in certifications" :key="`cert-${index}`" cols="12" sm="6" md="4">
        <AccountCertificationCard
          :showCrud="editMode"
          :certification="certification"
          @certification-edit-initiated="openForm"
          @certification-removed="removeCertification"
          :file="getFileByPolicy(certification)"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="formDialogIsOpen" max-width="60%">
      <AccountCertificationForm
        v-if="formDialogIsOpen"
        :propCertification="focusedCertification"
        :accountId="accountId"
        :certificationsFilter="certificationsFilter"
        @form-closed="closeForm"
        @certification-added="addCertification"
        @certification-edited="editCertification"
        @file-edited="fileEdited"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AccountCertificationCardCollection',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountCertificationCard: () => import('@/components/account/certifications/AccountCertificationCard.vue'),
    AccountCertificationForm: () => import('@/components/account/certifications/AccountCertificationForm.vue')
  },

  props: {
    certifications: {
      type: Array,
      required: true
    },
    accountId: {
      type: Number,
      default: undefined
    },
    editMode: {
      type: Boolean,
      default: true
    },
    files: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: () => ({
    formDialogIsOpen: false,
    focusedCertification: undefined
  }),

  watch: {
    formDialogIsOpen (val) {
      if (val === false) {
        this.focusedCertification = undefined
      }
    }
  },

  computed: {
    certificationsFilter () {
      return this.editMode && !this.focusedCertification && this.certifications.length > 0
        ? this.certifications.map(c => c.certificationId)
        : undefined
    }
  },

  methods: {
    addCertification (certification) {
      this.closeForm()
      this.$emit('account-certification-added', certification)
    },

    editCertification (certification) {
      this.closeForm()
      this.$emit('account-certification-edited', certification)
    },

    removeCertification (certification) {
      this.$emit('account-certification-removed', certification)
    },

    openForm (certification) {
      this.focusedCertification = certification
      this.formDialogIsOpen = true
    },

    closeForm () {
      this.formDialogIsOpen = false
      this.focusedCertification = undefined
    },

    fileEdited () {
      this.$emit('file-edited')
    },

    getFileByPolicy (policy) {
      return this.files?.find(f => Number(f?.policyId) === policy?.certificationId)
    }
  }
}
</script>
