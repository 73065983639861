import ApiServer from '@/utils/api/ApiServer.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'

export default {
  async fetchDecks (context, { includeLogs = true, includeByproducts = false } = {}) {
    try {
      context.commit('setLoading', true)
      const url = ApiServer.urlFor('decks', {
        params: { includeLogs, includeByproducts }
      })
      const response = await ApiServer.get(url)
      context.commit('setDecks', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchDeckWithId (_, deckId) {
    const path = `decks/${deckId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async fetchDecksForLocation (_, locationId) {
    const path = `locations/${locationId}/decks`
    const response = await ApiServer.get(path)
    return response.data
  },
  async updateDeck (_, deck) {
    const path = `locations/${deck.locationId}/decks/${deck.deckId}`
    const response = await ApiServer.put(path, deck)
    return response.data
  },
  async createDeck (_, deck) {
    const path = `locations/${deck.locationId}/decks`
    const response = await ApiServer.post(path, deck)
    return response.data
  },
  async createDeckAdjustment (_, { locationId, deckId, deckAdjustment }) {
    const path = `locations/${locationId}/decks/${deckId}/deckAdjustments`
    const response = await ApiServer.post(path, deckAdjustment)
    return response.data
  },
  async fetchDeckAdjustments (_, { locationId, deckId }) {
    const path = `locations/${locationId}/decks/${deckId}/deckAdjustments`
    const response = await ApiServer.get(path)
    return response.data
  },
  async deleteDeckAdjustment (_, { locationId, deckId, deckAdjustmentId }) {
    const path = `locations/${locationId}/decks/${deckId}/deckAdjustments/${deckAdjustmentId}`
    await ApiServer.delete(path)
  },
  async getArchivedDeckHistory (_, {
    locationId,
    deckId,
    sinceTime,
    untilTime,
    contentType
  }) {
    const path = ApiServer.urlFor('deckHistory', {
      params: {
        sinceTime,
        untilTime,
        locationId,
        deckId,
        contentType
      }
    })

    const response = await ApiServer.get(path)
    return response.data.map(dh => ({ ...dh, weight: tonStringFromPounds(dh.onDeck) }))
  },
  async fetchHistory (_, { locationId, deckId }) {
    if (locationId < 1) locationId = undefined
    if (deckId < 1) deckId = undefined
    if (!(!locationId && deckId)) {
      const path = `locations${locationId ? `/${locationId}/decks` : ''}${deckId ? `/${deckId}` : ''}/deckHistory`
      const response = await ApiServer.get(path)
      return response.data
    }
  }
}
