<template>
  <v-row align="center">
    <v-col cols="12" v-if="!isEditing">
      <span class="subtitle-1">
        {{ $t('printerSettings') }}
      </span>
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="auto" style="max-width: fit-content;">
      <v-text-field data-testid="printer-settings-address"
        v-model="printerSettings.address"
        :label="$t('address')"
        outlined color="secondary"
        :value="printerSettings.address"
        @blur="updateSettings"
        :rules="[rules.validIpAddressOrBlank]"
        :readonly="!isEditing"
      />
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="auto" v-if="displayDetails.logs || displayDetails.both" style="max-width: fit-content;">
      <v-text-field
        v-model="printerSettings.logTicketCopies"
        type="number"
        hide-spin-buttons
        data-testid="printer-log"
        :label="$t('logTicketCopies')"
        outlined color="secondary"
        :value="printerSettings.logTicketCopies"
        @blur="updateSettings"
        :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
        :readonly="!isEditing"
      />
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="auto" v-if="displayDetails.byproducts || displayDetails.both" style="max-width: fit-content;">
      <v-text-field
        v-model="printerSettings.byproductTicketCopies"
        type="number"
        hide-spin-buttons
        data-testid="printer-byproduct"
        :label="$t('byproductTicketCopies')"
        outlined color="secondary"
        :value="printerSettings.byproductTicketCopies"
        @blur="updateSettings"
        :rules="[rules.required, rules.integer, rules.validNumericRange(1, 5)]"
        :readonly="!isEditing"
      />
    </v-col>
    <v-col cols="12" xs="12" sm="4" md="4" lg="auto" class="mt-n7">
      <v-checkbox
        v-model="printerSettings.showDefectQuantities"
        :label="$t('showDefectQuantities')"
        @change="updateSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDiplay
        :off-icon="determineCheckBoxFalseDiplay"
        :color="determineCheckBoxEditingColor(printerSettings.showDefectQuantities)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" xs="12" sm="4" md="4" lg="auto" class="mt-n7">
      <v-checkbox
        v-if="(displayDetails.byproducts || displayDetails.both) && displayDetails.byproductDelivery"
        v-model="printerSettings.displayBarcode"
        :label="$t('displayBarcodeOnByproductTickets')"
        @change="updateSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDiplay
        :off-icon="determineCheckBoxFalseDiplay"
        :color="determineCheckBoxEditingColor(printerSettings.displayBarcode)"
        :ripple="isEditing"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import fieldRules from '@/utils/rules.js'
export default {
  name: 'PrinterSettingsForm',

  props: {
    propPrinter: {
      type: Object,
      required: true
    },
    displayDetails: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    printerSettings: {
      displayBarcode: false,
      showDefectQuantities: false,
      address: '',
      logTicketCopies: 1,
      byproductTicketCopies: 1
    },
    rules: fieldRules.rules
  }),

  created () {
    if (this.propPrinter) this.printerSettings = JSON.parse(JSON.stringify(this.propPrinter))
  },

  computed: {
    ...mapGetters('receiver-settings', ['loading']),
    determineCheckBoxTrueDiplay () {
      return this.isEditing ? '$checkboxOn' : 'mdi-check'
    },
    determineCheckBoxFalseDiplay () {
      return this.isEditing ? '$checkboxOff' : 'mdi-close'
    }
  },

  methods: {
    updateSettings () {
      this.$emit('updatePrinter', this.printerSettings)
    },
    determineCheckBoxEditingColor (value) {
      return this.isEditing ? undefined : value ? 'success' : 'red'
    }
  }
}
</script>
