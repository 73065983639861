import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchTractTypes (context) {
    const response = await ApiServer.get('tractTypes')
    context.commit('setTractTypes', response.data)
    var sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    return sortedData
  },
  async deleteTractType (context, tractTypeId) {
    const response = await ApiServer.delete(`tractTypes/${tractTypeId}`)
    context.commit('removeTractType', tractTypeId)
    return response.data
  },
  async createTractType (context, tractType) {
    const response = await ApiServer.post('tractTypes', tractType)
    context.commit('addTractType', response.data)
    return response.data
  },
  async updateTractType (context, tractType) {
    let basePath = `tractTypes/${tractType.tractTypeId}`
    if (tractType.businessEntityId) basePath += `?businessEntityId=${tractType.businessEntityId}`
    const response = await ApiServer.put(basePath, tractType)

    context.commit('updateTractType', response.data)
    return response.data
  }
}
