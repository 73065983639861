<template>
<div>
  <v-row class="pl-4">
    <span class="subtitle-1 black--text mr-2">{{ $t('tractNotes') }}</span>
    <Icon
      icon="mdi-plus"
      tooltipColor="secondary"
      iconColor="secondary"
      dataTestId="tract-note-add"
      :tooltipText="$t('createNote')"
      :small="false"
      @icon-clicked="openTractNoteForm()"
    />
  </v-row>
  <v-row>
    <v-card
      flat
      style="overflow-y: auto; max-height: 65vh;"
      width="100%"
      :loading="loading"
    >
      <v-card-text>
        <v-card v-if="!loading && emptyNotes" class="mb-3">
          <v-card-title>
            <span class="subtitle-1 black--text">{{ $t('createFirstNote') }}</span>
          </v-card-title>
          <v-card-text>
            {{ $t('tractHasNoNotes') }}
          </v-card-text>
        </v-card>
        <v-card v-for="(note, index) in tractNotes" :key="note.createdAt" class="mb-3">
          <v-card-title>
            <Icon
              icon="mdi-account"
              tooltipColor="secondary"
              iconColor="secondary"
              :data-testid="`tract-note-create-${index}`"
              :tooltipText="$t('creator')"
            />
            <span :data-testid="`tract-note-created-by-${index}`" class="subtitle-1 black--text">{{note.createdBy}}</span>
            <v-spacer />
            <Icon
              v-if="note.createdBy === userInfo.name"
              icon="mdi-pencil"
              tooltipColor="success"
              iconColor="success"
              :data-testid="`tract-note-edit-${index}`"
              :tooltipText="$t('editNote')"
              @icon-clicked="openTractNoteForm(note)"
            />
            <Icon
              v-if="note.createdBy === userInfo.name"
              icon="mdi-delete-forever"
              tooltipColor="error"
              iconColor="error"
              :data-testid="`tract-note-delete-${index}`"
              :tooltipText="$t('deleteNote')"
              @icon-clicked="deleteTractNoteClicked(note)"
            />
          </v-card-title>
          <v-card-subtitle>
            {{utcToLocalDate(note.createdAt, 'L - LT')}}
          </v-card-subtitle>
          <v-card-text :data-testid="`tract-note-content-${index}`">
            {{note.content}}
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-row>
  <v-dialog v-model="tractNoteFormDialog" width="40vw">
    <TractNoteForm
      v-if="tractNoteFormDialog"
      :tractId="tractId"
      :propNote="propNote"
      :editing="editing"
      @tract-note-modified="tractNoteModified"
      @close="closeTractNoteForm"
    />
  </v-dialog>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'TractNotes',

  props: {
    tractId: {
      type: Number,
      required: true
    }
  },

  components: {
    TractNoteForm: () => import('@/components/tract/tract-detail/TractNoteForm.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    tractNotes: [],
    propNote: undefined,
    loading: false,
    tractNoteFormDialog: false,
    editing: false
  }),

  computed: {
    ...mapGetters('user', ['userInfo']),
    emptyNotes () {
      this.$emit('emptyNotes', this.tractNotes.length === 0)
      return this.tractNotes.length === 0
    }
  },

  async created () {
    this.loading = true
    try {
      await this.refreshTractNotes()
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('notes', ['fetchTractNotes', 'deleteTractNote']),
    utcToLocalDate,

    async refreshTractNotes () {
      this.tractNotes = await this.fetchTractNotes(this.tractId)
    },

    openTractNoteForm (note) {
      if (note) this.editing = true
      this.propNote = note
      this.tractNoteFormDialog = true
    },

    closeTractNoteForm () {
      this.editing = false
      this.propNote = undefined
      this.tractNoteFormDialog = false
    },

    async tractNoteModified () {
      this.tractNoteFormDialog = false
      this.loading = true
      try {
        await this.refreshTractNotes()
      } finally {
        this.loading = false
      }
    },

    async deleteTractNoteClicked (note) {
      this.loading = true
      note.tractId = this.tractId
      try {
        await this.deleteTractNote(note)
        await this.refreshTractNotes()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
