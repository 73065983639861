<template>
  <v-container fluid class="mx-n3">
    <v-tabs
    v-model="currentSubModuleTab"
    slider-color="secondary"
    color="#888888"
    slider-size="4">
      <v-tab v-for="subModuleName in subModuleNames" :key="subModuleName">
        <span class="black--text" style="font-size: 16px;">
          {{subModuleName}}
        </span>
      </v-tab>
      <v-spacer/>
      <Icon
        :icon="!metricTonsToggle ? 'mdi-weight' : 'mdi-weight-pound'"
        @icon-clicked="metricTonsToggle = !metricTonsToggle"
        :small="false"
        :tooltipText="metricTonsToggle ? $t('shortTons') : $t('metricTons')"
      />
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <ReceivableRegister
        v-if="currentSubModuleTab === 0 && !loading"
        :receivableRegisterObject="currentSubModule"
        :isByproduct="false"/>
      </v-tab-item>
      <v-tab-item>
        <ReceivableSummary
        v-if="currentSubModuleTab === 1 && !loading"
        :productionSummaryObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <EntitySelector
              :entities="entities"
              :initialEntity.sync="selectedEntity"
              @entity-selected="entitySelected"
            />
          </v-row>
          <v-row>
            <SalesAnalysis
            v-if="currentSubModuleTab === 2"
            :salesAnaylsisObject="currentSubModule"/>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="secondary"/>
    </v-row>
    <v-row class="my-12 pa-12" v-if="showNoDataText" justify="center" align="center">
      <span class="headline black--text">
        {{ $t('noDataForSelection') }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SettlementKeys, SettlementSubKeys, SubKeyToTabMap } from '@/components/settlements/SettlementKeys.js'
export default {
  name: 'AccountsReceivableModule',

  components: {
    ReceivableRegister: () => import('@/components/settlements/accounts-receivable-module/receivable-register/ReceivableRegister.vue'),
    ReceivableSummary: () => import('@/components/settlements/accounts-receivable-module/production-summary/ReceivableProductionSummary.vue'),
    SalesAnalysis: () => import('@/components/settlements/accounts-receivable-module/sales-analysis/SalesAnalysis.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    currentSubModuleTab: 0,
    entities: [],
    selectedEntity: {}
  }),

  watch: {
    async currentSubModuleTab (val) {
      const moduleConfiguration = {
        moduleKey: SettlementKeys.AccountsReceivable,
        subModuleKey: SettlementSubKeys.AR.ReceivableRegister
      }

      moduleConfiguration.subModuleKey = SubKeyToTabMap.AR.Logs.find(kv => kv.Tab === val)?.Key ?? moduleConfiguration.subModuleKey
      if (moduleConfiguration.subModuleKey === SettlementSubKeys.AR.SalesAnalysis) {
        await this.fetchAllBusinessEntities()
        this.entities = this.businessEntities
        this.selectedEntity = this.entities[0]
        this.setARSalesAnalysisBusinessEntity(this.selectedEntity)
      }

      this.setCurrentModule(moduleConfiguration)
    }
  },

  computed: {
    ...mapGetters('settlements', ['receivableModule', 'currentModuleKey', 'currentSubModuleKey', 'loading', 'arSalesAnalysisBusinessEntity', 'useMetricTons']),
    ...mapGetters('user', ['businessEntities']),

    subModuleNames () {
      return this.receivableModule.moduleNames
    },

    metricTonsToggle: {
      get () {
        return this.useMetricTons
      },
      set (value) {
        this.setUseMetricTons(value)
      }
    },

    currentSubModule () {
      return this.receivableModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AR.ReceivableRegister:
          return this.currentSubModule.receivables.length === 0
        case SettlementSubKeys.AR.ProductionSummary:
          return this.currentSubModule.production.length === 0
        case SettlementSubKeys.AR.SalesAnalysis:
          return false
      }

      return true
    }
  },

  created () {
    this.currentSubModuleTab = SubKeyToTabMap.AR.Logs.find(kv => kv.Key === this.currentSubModuleKey)?.Tab ?? 0
  },

  methods: {
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('settlements', ['refreshModuleData', 'setCurrentModule']),
    ...mapMutations('settlements', ['setARSalesAnalysisBusinessEntity', 'setUseMetricTons']),
    entitySelected (entity) {
      this.selectedEntity = entity
      this.setARSalesAnalysisBusinessEntity(this.selectedEntity)
      this.refreshModuleData()
    }
  }
}
</script>
