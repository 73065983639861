export default {
  setUserSettings (state, userSettings) {
    state.userSettings = JSON.parse(JSON.stringify(userSettings))
    state.mutatedUserSettings = JSON.parse(JSON.stringify(userSettings))
  },

  mutateUserSetting (state, newSetting) {
    if (!state.userSettings[newSetting.type]) {
      state.userSettings[newSetting.type] = {}
    }
    state.mutatedUserSettings[newSetting.type][newSetting.key] = newSetting.value
  },

  removeUserSetting (state, setting) {
    delete state.mutatedUserSettings[setting.type][setting.key]
  }
}
