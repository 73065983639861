export default {
  contracts: [],
  contractsLoading: false,
  filter: {
    includeOpen: true,
    includeOnHold: true,
    includeClosed: false,
    includeExpired: true,
    limitToDraft: false,
    limitToPaused: false,
    limitToInternal: false,
    includeProduction: true,
    includeWoodsSale: true,
    approvalStatus: undefined
  },
  focusedContractId: -1,
  selectedContractId: -1
}
