<template>
  <div ref="shortcutWrapper">
    <slot/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { UserClaims } from '../../utils/Enumerations'
import { userAssignedClaim } from '../../utils/ClaimUtility'
export default {
  name: 'ShortcutWrapper',
  props: {
    isDialog: Boolean,
    propShortcuts: {
      type: Array,
      required: false,
      default: undefined
    },
    propInitiatingKey: {
      type: String,
      required: false,
      default: undefined
    },
    nShortcutRequiredClaim: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    commandInitiated: false,
    UserClaims
  }),

  created () {
    if (typeof window !== 'undefined') document.addEventListener('keyup', this.onKeyup)
  },

  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyup)
  },

  methods: {
    ...mapMutations('global', ['setSettingName']),
    userAssignedClaim,

    onKeyup (e) {
      if (this.isActive() && (this.activeDialogCount() === 0 || this.isDialog) && !this.activeElementIsInput()) {
        switch (e.key) {
          case 'n':
            if (!this.nShortcutRequiredClaim || userAssignedClaim(this.nShortcutRequiredClaim)) {
              this.emitShortcut('n-shortcut')
            }
            break
          case 'ArrowLeft':
            this.emitShortcut('arrow-left-shortcut')
            break
          case 'ArrowRight':
            this.emitShortcut('arrow-right-shortcut')
            break
        }

        if (this.propShortcuts) {
          if ((this.commandInitiated || !this.propInitiatingKey)) {
            this.propShortcuts.forEach(s => {
              if (s.key === e.key && (!s.requiredClaim || userAssignedClaim(s.requiredClaim))) {
                this.$emit('shortcut', s.id)
              }
            })
            this.commandInitiated = false
          } else {
            if (e.key === this.propInitiatingKey) {
              this.commandInitiated = true
            }
          }
        }
      }
    },

    key (id) {
      const shortcut = this.propShortcuts.find(s => s.id === id)
      if (shortcut) {
        return shortcut.key
      }
    },

    activeDialogCount () {
      return document.getElementsByClassName('v-dialog--active').length
    },

    activeElementIsInput () {
      return document.activeElement.nodeName === 'INPUT'
    },

    emitShortcut (shortcut) {
      if (this.$listeners[shortcut]) this.$emit(shortcut)
    },

    isActive () {
      const componentBounds = this.$refs.shortcutWrapper.getBoundingClientRect()
      return componentBounds.width > 0 && componentBounds.height > 0
    }
  }
}
</script>
