<template>
  <v-card>
    <v-card-title :class="`secondary white--text headline`">
      <span>
        {{$t('modificationHistoryTitle', { type: $t(csvType.label) })}}
      </span>
      <v-spacer/>
      <Icon
        @icon-clicked="$emit('cancel')"
        :tooltipText="$t('cancel')"
        dataTestId="get-csv-cancel-button"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        large
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper
        :buttonText="$t('emailCSV')"
        :disabled="!validDates"
        @submit="emailCsv"
      >
        <v-container fluid>
          <DatePicker
            :dateLabel="$t('startDate')"
            dataTestId="get-csv-start-date-picker"
            :startDate="initialStartDate"
            :max="maxStartDate"
            @valid-date="validateStartDate"
            @date-picked="setStartDate"
          />
          <DatePicker
            :dateLabel="$t('endDate')"
            dataTestId="get-csv-end-date-picker"
            :startDate="initialEndDate"
            :min="startDate"
            :max="getDate()"
            @valid-date="validateEndDate"
            @date-picked="setEndDate"
          />
        </v-container>
      </FormWrapper>
      <v-row dense>
        <v-col>
          <div v-if="!validDates" class="red--text">{{ invalidDateString() }}</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="userInfoFormDialog" width="60vw">
      <UserInfoFormDialog
        @close="userInfoFormDialog = false"
        @user-info-added="emailCsv"
        v-if="userInfoFormDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import { localToUTC } from '@/utils/DateFormatter.js'
import { mapActions, mapGetters } from 'vuex'

const MAX_RANGE = 92
const INITIAL_RANGE = 31

export default {
  name: 'GetCsvDialog',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    UserInfoFormDialog: () => import('@/components/settings-components/UserInfoFormDialog.vue')
  },

  data: () => ({
    startDate: undefined,
    endDate: undefined,
    startDateIsWellDefined: true,
    endDateIsWellDefined: true,
    userInfoFormDialog: false
  }),

  props: {
    csvType: Object
  },

  computed: {
    ...mapGetters('user', ['userInfo']),

    initialStartDate () {
      return this.getDate(0 - INITIAL_RANGE)
    },

    initialEndDate () {
      return this.getDate()
    },

    maxStartDate () {
      return moment.min(moment(this.endDate), moment()).format()
    },

    startBeforeEnd () {
      return moment(this.startDate).isSameOrBefore(this.endDate)
    },

    endBeforeNow () {
      return moment(this.endDate).isSameOrBefore()
    },

    validRangeSize () {
      return moment(this.endDate).diff(this.startDate, 'days') <= MAX_RANGE
    },

    validDates () {
      return this.startDateIsWellDefined && this.endDateIsWellDefined && this.startBeforeEnd && this.endBeforeNow && this.validRangeSize
    }
  },

  created () {
    this.startDate = this.initialStartDate
    this.endDate = this.initialEndDate
  },

  methods: {
    ...mapActions('export-downloads', ['triggerModificationHistoryExportEmail']),
    ...mapActions('user', ['getUserInfo']),

    getDate (offset) {
      offset ??= 0
      return moment().add(offset, 'days').format()
    },

    setStartDate (newDate) {
      this.startDate = newDate
    },

    setEndDate (newDate) {
      this.endDate = newDate
    },

    validateStartDate (isValid) {
      this.startDateIsWellDefined = isValid
    },

    validateEndDate (isValid) {
      this.endDateIsWellDefined = isValid
    },

    invalidDateString () {
      if (!this.startDateIsWellDefined || !this.endDateIsWellDefined) {
        return ''
      } else if (!this.startBeforeEnd) {
        return this.$t('startDateAfterEndDate')
      } else if (!this.endBeforeNow) {
        return this.$t('endDateAfterNow')
      } else if (!this.validRangeSize) {
        return this.$t('dateRangeTooLarge', { maxDays: MAX_RANGE })
      } else {
        return this.$t('invalidDate')
      }
    },

    async emailCsv () {
      const requestObj = {
        type: this.csvType.value,
        startDate: localToUTC(this.startDate),
        endDate: localToUTC(this.endDate)
      }
      const userInfo = this.userInfo ?? await this.getUserInfo()
      if (!userInfo?.person) {
        this.userInfoFormDialog = true
      } else {
        try {
          await this.triggerModificationHistoryExportEmail(requestObj)
          this.setSnack(this.$t('modificationHistoryEmailSent', { email: this.userInfo.person.email }))
        } finally {
          this.$emit('csv-request-submitted')
        }
      }
    }
  }
}
</script>
