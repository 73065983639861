export default {
  setTeamMemberships (state, teamMemberships) {
    state.teamMemberships = teamMemberships
  },

  setTeamMembershipsLoading (state, teamMembershipsLoading) {
    state.teamMembershipsLoading = teamMembershipsLoading
  },

  setTeams (state, teams) {
    state.teams = teams
  },

  setTeamsLoading (state, teamsLoading) {
    state.teamsLoading = teamsLoading
  }
}
