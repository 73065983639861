<template>
  <v-card v-if="payable">
    <v-card-title :class="colorClassForContractType(payable.contractType)">
      <span class="white--text headline">{{titleText}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <PayableDetailContent :propPayable="payable"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { colorClassForContractType } from '../../../utils/componentHelpers'
export default {
  name: 'PayableDetail',

  props: {
    propPayable: Object,
    payableId: Number
  },

  components: {
    PayableDetailContent: () => import('./PayableDetailContent.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    titleText () {
      return `${this.payable.accountName}`
    }
  },

  data: () => ({
    payable: undefined
  }),

  async created () {
    if (this.payableId) {
      const response = await this.getPayable(this.payableId)
      this.payable = response
    } else {
      this.payable = this.propPayable
    }
  },

  methods: {
    colorClassForContractType,
    ...mapActions('payable', ['getPayable'])
  }
}
</script>
