<template>
  <v-card max-width="400px">
    <v-card-title class="white--text error headline">
      {{titleText()}}
    </v-card-title>
    <v-card-text class="mt-6">
      <p class="subtitle-1 black--text font-weight-medium">{{bodyText()}}</p>
    </v-card-text>
    <v-card-actions>
      <v-container fluid class="px-0">
        <v-row>
          <v-col class="px-0">
            <v-btn text color="black" @click="$emit('cancel-delete')" ref="cancelButton">{{$t('cancel')}}</v-btn>
          </v-col>
          <v-col cols="auto" class="px-0">
            <v-btn class="error" @click="$emit('delete')" ref="submitButton">{{confirmButtonText()}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmDelete',

  props: {
    title: String,
    initialFocus: { type: String, required: false, default: undefined }
  },

  mounted () {
    this.$nextTick(() => {
      if (!this.initialFocus) return
      if (this.initialFocus === 'submit') this.$refs.submitButton.$el.focus()
      else if (this.initialFocus === 'cancel') this.$refs.cancelButton.$el.focus()
      else console.error(`Invalid initial focus argument '${this.initialFocus}'. Must be one of 'submit' | 'cancel'`)
    })
  },

  methods: {
    titleText () {
      return this.$t('deleteTitle', { object: this.title }) + '?'
    },

    bodyText () {
      const titleLowercase = this.title.toLowerCase()
      return this.$t('confirmDelete', { object: titleLowercase })
    },

    confirmButtonText () {
      return this.$t('deleteTitle', { object: this.title })
    }
  }
}
</script>
