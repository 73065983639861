<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="white--text headline">
        {{ $t('modifications') }}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
      <DataTable
        :items.sync="modifications"
        :customCells="customCells"
        :showSearch="false"
        :showDense="false"
        :headers="headers"
        :itemsPerPage="10"
      >
        <template #amount="{ item }">
          <span>{{formatMoney(item.modificationAmount)}}</span>
        </template>
        <template #createdAt="{ item }">
          <span>{{utcToLocalDate(item.createdAt, 'L - LT')}}</span>
        </template>
      </DataTable>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import AdvanceModificationsHeaders from '@/headers/Advances.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AdvanceModHistory',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  props: {
    propModifications: {
      type: Array,
      default: undefined,
      required: true
    }
  },

  data: () => ({
    modifications: []
  }),

  computed: {
    ...mapGetters('advances', ['advanceModifications']),
    headers () {
      return AdvanceModificationsHeaders.advanceModificationsHeaders(this.$i18n.locale)
    },
    customCells () {
      return [
        {
          slotName: 'amount',
          value: 'modificationAmount'
        },
        {
          slotName: 'type',
          value: 'modificationType'
        },
        {
          slotName: 'createdAt',
          value: 'createdAt'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  created () {
    this.modifications = this.propModifications
  },

  methods: {
    ...mapActions('advances', ['deleteAdvanceModification', 'fetchModificationsForAdvance']),
    // advanceModificationTypesFromInt: (x) => AdvanceModificationTypes.fromInt(x),
    formatMoney,
    utcToLocalDate
  }
}
</script>
