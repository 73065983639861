import ApiServer from '@/utils/api/ApiServer.js'
const paths = (receiverSettingsId, locationId) => {
  const path = {
    getAllOrCreate: `locations/${locationId}/receiversettings`,
    getOneDeleteOrUpdate: `locations/${locationId}/receiversettings/${receiverSettingsId}`
  }
  return path
}

export default {

  async fetchReceiverSettings (context, locationId) {
    const response = await ApiServer.get(paths(null, locationId).getAllOrCreate)
    context.commit('setReceiverSettings', response.data)
    return response.data
  },

  async fetchReceiverSettingsById (_, receiverId, locationId) {
    const response = await ApiServer.get(paths(receiverId, locationId).getOneDeleteOrUpdate)
    return response.data
  },

  async updateReceiverSettingsById (_, { receiverId, locationId, settings }) {
    const response = await ApiServer.put(paths(receiverId, locationId).getOneDeleteOrUpdate, settings)
    return response.data
  },

  async createReceiverSettings (_, { locationId, settings }) {
    const response = await ApiServer.post(paths(null, locationId).getAllOrCreate, settings)
    return response.data
  },

  async deleteReceiverSettingsById (_, { receiverId, locationId }) {
    const response = await ApiServer.delete(paths(receiverId, locationId).getOneDeleteOrUpdate)
    return response.data
  }
}
