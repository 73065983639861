<template>
  <div>
    <v-autocomplete
    :items="filteredCertifications"
    return-object
    item-text="name"
    multiple
    chips
    :label="(label) ? selectLabel : undefined"
    v-model="selectedCertifications">
      <template #append-outer>
        <v-icon color="secondary" @click="openCertificationForm" :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)">mdi-plus</v-icon>
      </template>
    </v-autocomplete>
    <Dialog :stateId="dialogId">
      <CertificationForm
        :certificationCategory="categoryAsString"
        :isEditing="false"
        :propCertification="{}"
        @certification-mutated="closeCertificationForm"
      />
    </Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CertificationCategory, UserClaims } from '@/utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

export default {
  name: 'Certifications',

  props: {
    propCertifications: {
      type: Array[Object],
      default: () => []
    },
    useIdOnly: {
      type: Boolean,
      default: false
    },
    category: {
      type: Number,
      required: true
    },
    label: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    dataTestid: String,
    propFetchCertifications: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    CertificationForm: () => import('./CertificationForm.vue')
  },

  data: () => ({
    dialogId: 'certifications-form',
    selectedCertifications: [],
    addingCertification: false,
    newCertification: {
      name: '',
      code: ''
    },
    UserClaims
  }),

  computed: {
    ...mapGetters('certification', ['allCertifications']),
    filteredCertifications () {
      return this.allCertifications.filter(c => c.category === this.category)
    },
    categoryAsString () {
      return CertificationCategory.fromInt(this.category)
    },
    selectedCertificationsLength () {
      return this.selectedCertifications?.length ?? 0
    },
    selectLabel () {
      if (typeof this.label === 'string') return this.label
      switch (this.category) {
        case 0:
          return this.$t('tractCertifications')
        case 1:
          return this.$t('accountCertifications')
        default:
          return this.$t('certifications')
      }
    }
  },

  watch: {
    propCertifications () {
      this.selectedCertifications = this.filteredCertifications.filter(cert => this.propCertifications.some(pc =>
        ((this.useIdOnly) ? pc : pc.certificationId) === cert.certificationId))
    },

    selectedCertificationsLength () {
      this.$emit('certifications', (this.useIdOnly) ? this.selectedCertifications.map(c => c.certificationId) : this.selectedCertifications)
    }
  },

  async created () {
    await this.getCertifications()
  },

  methods: {
    ...mapActions('certification', ['fetchCertifications']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,

    async getCertifications () {
      if (this.propFetchCertifications) await this.fetchCertifications()
      this.selectedCertifications = this.filteredCertifications.filter(cert => this.propCertifications.some(pc =>
        ((this.useIdOnly) ? pc : pc.certificationId) === cert.certificationId))
    },

    certificationsSelected (certs) {
      this.$emit('certifications', (this.useIdOnly) ? certs.map(c => c.certificationId) : certs)
    },

    openCertificationForm () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px', allowFullscreen: false })
    },

    closeCertificationForm () {
      this.getCertifications()
      const hello = this.filteredCertifications
      hello.map(h => h)
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
