<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <AdvanceTable
        :propAdvances="advances"
        :accountColumn="true"
        :showModDialog="false"
        :dense="true">
        </AdvanceTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CurrentAdvances',

  props: {
    advances: {
      type: Array
    }
  },

  components: {
    AdvanceTable: () => import('@/components/accounting/advances/AdvanceTable.vue')
  }
}
</script>
