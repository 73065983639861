<template>
  <canvas id="production-summary-chart"/>
</template>

<script>
import { Chart } from 'chart.js'
export default {
  name: 'ProductSummaryChart',

  props: {
    productSummary: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    chartCanvas: false
  }),

  mounted () {
    this.buildChart()
  },

  methods: {
    buildChart () {
      const chartElement = document.getElementById('production-summary-chart')
      this.chart = new Chart(chartElement, {
        type: 'doughnut',
        data: this.getChartData(this.productSummary)
      })
    },

    getChartData (productSummary) {
      const grayColors = [
        '#C0C0C0',
        '#A9A9A9',
        '#808080',
        '#696969',
        '#778899',
        '#708090',
        '#2F4F4F'
      ]

      const pieColors = []
      for (let i = 0; i < productSummary.length; i++) {
        pieColors.push(grayColors[i % 7])
      }
      const labels = productSummary.map((ps) => ps.productName)
      const weightInfo = productSummary.map((ps) =>
        parseFloat((ps.weight / 2000).toFixed(3))
      )
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: pieColors,
            data: weightInfo
          }
        ]
      }
    }
  }
}
</script>
