<template>
  <v-container fluid>
    <Dialog :stateId="dialogId" maxWidth="80vw" @dialog-closing="resetDialogs()">
      <TicketForm
      v-if="ticketForm && !isByProducts"
      :propTicket="focusedTicket"
      :isByProducts="isByProducts"
      :isNested="true"
      :propTractId="tractId"
      :contractMode="logsContractMode"
      @ticket-changed="refreshTickets"
      @refresh="refreshTickets"
      @close="refreshTickets"
      />
      <TicketDetail
      @edit-ticket="parseTableAction"
      v-if="ticketDetail"
      :ticketId="focusedTicket.ticketId"
      :propTicket="focusedTicket"
      :isNested="true"
      @close="refreshTickets"/>
      <InteractiveMap
      v-if="mapView"
      :ticket="focusedTicket"
      @map-closed="resetDialogs"
      :height="$vuetify.breakpoint.smAndDown ? '100%' : '80vh'"
      :isNested="true"
      >
      </InteractiveMap>
      <ContractDetail
      :contractId="focusedTicket.contractId"
      v-if="contractDetail"
      :isNested="true"
      @close="refreshTickets"/>
    </Dialog>
    <v-dialog v-model="deleting" width="400px">
      <ConfirmDialog
      v-if="deleting"
      :title="$t('deleteTicket')"
      :body="$t('deleteTicketBody', { ticketNumber: focusedTicket.ticketNumber })"
      @confirm="deleteItem"
      @cancel="refreshTickets"/>
    </v-dialog>
    <TicketTable
    :loading="loading"
    :isTractDetail="true"
    @ticket-action="parseTableAction"
    @refresh="refreshTickets"/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Ticket from '@/model/Ticket.js'
import { ContractModes } from '../../../utils/Enumerations'

export default {
  props: {
    tractId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    dialogId: 'tract-tickets',
    deleting: false,
    ticketForm: false,
    mapView: false,
    ticketDetail: false,
    contractDetail: false,
    loading: false,
    isByProducts: false,
    search: '',
    focusedTicket: undefined,
    tickets: []
  }),

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    TicketForm: () => import('@/components/ticket/TicketForm.vue'),
    InteractiveMap: () => import('@/components/maps/InteractiveMap.vue'),
    TicketTable: () => import('@/components/ticket/TicketTable.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    TicketDetail: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('global', ['routerJump']),
    logsContractMode () {
      return ContractModes[0]
    }
  },

  created () {
    this.refreshTickets()
  },

  methods: {
    ...mapActions('ticket', ['deleteTicket']),
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('dialog', ['openDialog', 'openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('tract', ['fetchTractTickets']),
    ...mapMutations('ticket', ['setTickets']),

    resetDialogs () {
      this.closeDialogsAtOrAbove('tract-tickets')
      this.ticketForm = false
      this.mapView = false
      this.deleting = false
      this.contractDetail = false
      this.ticketDetail = false
      this.focusedTicket = undefined
      this.focusedTractId = -1
    },

    refreshTickets () {
      this.resetDialogs()
      this.loading = true
      this.fetchLocations()
        .then(() => this.fetchTractTickets({ tractId: this.tractId }))
        .then(tickets => this.setTickets(tickets.map(t => new Ticket(t))))
        .finally(() => {
          this.loading = false
        })
    },

    async parseTableAction (action) {
      this.resetDialogs()
      if (action.ticket) {
        this.focusedTicket = action.ticket
      }
      switch (action.actionType) {
        case 'email-csv':
          if (this.userInfo === undefined) {
            await this.getUserInfo()
            this.verifyEmail()
          } else {
            this.verifyEmail()
          }
          break
        case 'view-detail':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.ticketDetail = true
          break
        case 'contract-detail':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.contractDetail = true
          break
        case 'open-form':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.ticketForm = true
          break
        case 'open-map':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.mapView = true
          break
        case 'delete-ticket':
          this.deleting = true
          break
        default: break
      }
    },

    async deleteItem () {
      await this.deleteTicket(this.focusedTicket.ticketId)
      this.resetDialogs()
      this.refreshTickets()
    }
  }
}
</script>

<style>
  #progress-bar-table {
    position: absolute;
    left: 50%;
    top: 30%;
  }
</style>
