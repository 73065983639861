<template>
  <v-container fluid>
    <v-row v-for="(productionDetail, index) in production.productionDetails" :key="`production-${production.producerId}-${index}`">
      <v-col cols="12">
        <span class="title black--text">{{productionDetail.tractName}}</span>
      </v-col>
      <v-col cols="12">
        <DataTable
        :items.sync="productionDetail.tractProduction"
        :headers="headers"
        :propDense="true"
        :customCells="customCells"
        :hideHeader="true"
        :actions=[]>
          <template #weight="{item}">
              {{tonStringFromPounds(item.weight, 3, useMetricTons)}}
          </template>
          <template #amount="{item}">
            {{formatMoney(item.amount)}}
          </template>
          <template #rate="{item}">
            {{formatMoney(item.rate)}}
          </template>
          <template #profit-loss="{item}">
            <span :style="`color: ${item.pl < 0 ? 'red' : 'black'}`">
              {{getPLString(item.pl)}}
            </span>
          </template>
          <template #body.append>
            <tr class="font-weight-bold">
              <td class="text-left">Totals</td>
              <td></td>
              <td class="text-right">{{ getTotalLoads(productionDetail.tractProduction)}}</td>
              <td class="text-right">{{ getTotalWeight(productionDetail.tractProduction) }}</td>
              <td class="text-right">{{ getTotalAmount(productionDetail.tractProduction) }}</td>
            </tr>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { tractProductionHeaders } from '@/headers/Cycles.js'
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ProductionCard',

  props: {
    production: {
      type: Object,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    customCells: [
      {
        slotName: 'weight',
        value: 'weight'
      },
      {
        slotName: 'amount',
        value: 'amount'
      },
      {
        slotName: 'profit-loss',
        value: 'pl'
      },
      {
        slotName: 'rate',
        value: 'rate'
      }
    ]
  }),

  computed: {
    ...mapGetters('settlements', ['useMetricTons']),
    headers () {
      return tractProductionHeaders(this.$i18n.locale)
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney,

    getTotalAmount (tractProduction) {
      return formatMoney(tractProduction.reduce((a, b) => a + b.amount, 0))
    },

    getTotalLoads (tractProduction) {
      return tractProduction.reduce((a, b) => a + b.loads, 0)
    },

    getTotalWeight (tractProduction) {
      return tonStringFromPounds(tractProduction.reduce((a, b) => a + b.weight, 0), 3, this.useMetricTons)
    }
  }
}
</script>
