<template>
  <v-row align="center">
    <v-overlay
      :opacity="0.6"
      :value="loading"
      :z-index="1000"
    >
        <span>
          {{$t('processingRequest')}}
        </span>
          <v-progress-circular
          indeterminate
          color="white"
          :size="25"
          class="ml-4"
          />
    </v-overlay>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoadingOverlay',

  computed: {
    ...mapGetters('global', ['loading'])
  }
}
</script>
