import i18n from '../i18n'

export default {
  certificationHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'right'
      }
    ]
  },

  accountCertificationHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('code'),
        value: 'code'
      },
      {
        sortable: true,
        text: i18n.t('effectiveDate'),
        value: 'effectiveDate'
      },
      {
        sortable: true,
        text: i18n.t('expirationDate'),
        value: 'expirationDate'
      },
      {
        sortable: true,
        text: i18n.t('certificateNumber'),
        value: 'certificateNumber'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
