export default {

  setLoading (state, status) {
    state.settingsLoading = status
  },
  setSettings (state, settings) {
    state.settings = settings
  },
  addSetting (state, setting) {
    state.settings.push({
      settingId: setting.settingId,
      code: setting.code,
      name: setting.name,
      exportCode: setting.exportCode,
      email: setting.email
    })
  },
  removeSetting (state, settingId) {
    state.settings = state.settings.filter(setting => setting.settingId !== settingId)
  },
  updateSetting (state, setting) {
    const index = state.settings.findIndex(v => v.settingId === setting.settingId)
    if (index !== -1) {
      Object.assign(state.settings[index], setting)
    }
  }
}
