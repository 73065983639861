export default class DialogState {
  constructor (id, width = '', fullscreen = false, allowFullscreen = true) {
    this.id = id
    this.fullscreen = fullscreen
    this.allowFullscreen = allowFullscreen
    this.width = width
    this.unsavedChanges = false
    this.closeRequested = false
    this.uniqueHash = Math.random().toString(16).slice(2, 8)
  }
}
