<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="white--text headline">{{ $t('defects') }}</span>
      <v-spacer />
      <BaseDialogActions hideFullscreen hideRefresh/>
    </v-card-title>
    <v-card-text>
      <DefectTable :ticketDefects="ticketDefects"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TicketDefectCard',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DefectTable: () => import('@/components/defects/DefectTable.vue')
  },

  props: {
    ticketDefects: {
      type: Array,
      default: undefined,
      required: true
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
