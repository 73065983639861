var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({scopedSlots:_vm._u([{key:"preview-item.shipping",fn:function({ value }){return _vm._l((_vm.formatAddress(value)),function(addressLine,i){return _c('p',{key:`shippingaddr-line-${addressLine}-${i}`,staticClass:"ma-0"},[_vm._v(" "+_vm._s(addressLine)+" "),_c('br')])})}},{key:"preview-item.billing",fn:function({ value }){return _vm._l((_vm.formatAddress(value)),function(addressLine,i){return _c('p',{key:`billingaddr-line-${addressLine}-${i}`,staticClass:"ma-0"},[_vm._v(" "+_vm._s(addressLine)+" "),_c('br')])})}},{key:"preview-item.tags",fn:function({ value }){return [_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.formatTags(value))+" ")])]}}])},'CsvImport',{
  exampleData: _vm.exampleData,
  entityName: _vm.entityName,
  rowName: _vm.rowName,
  parse: _vm.parse,
  preview: _vm.preview,
  upload: _vm.upload
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }