<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :label="label"
    :hint="disabledHint"
    :disabled="disabled"
    item-text="name"
    data-testid="pay-variation-select"
    ref="selector"
    color="black"
    item-color="secondary"
    persistent-hint
    return-object
  ></v-select>
</template>

<script>
import { PayOn, PayBy, AccountingCategory } from '@/utils/Enumerations.js'
export default {
  name: 'PayVariationSelector',

  data: () => ({
    selectedItem: null
  }),

  props: {
    initialValue: { type: Number, default: 0 },
    accountingCategory: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'PayBy' },
    isLogsContract: { type: Boolean, default: true }
  },

  watch: {
    selectedItem (val) {
      const eventName = this.type === 'PayBy' ? 'pay-by-chosen' : 'pay-on-chosen'
      this.$emit(eventName, val.value)
    }
  },

  created () {
    this.selectedItem = this.type === 'PayBy' ? PayBy.forInt(this.initialValue) : PayOn.forInt(this.initialValue)
  },

  computed: {
    items () {
      return this.type === 'PayBy'
        ? PayBy.enums.filter(payBy => payBy !== PayBy.MetricWeight || this.accountingCategory === AccountingCategory.Receivable.value)
        : PayOn.enums.filter(payOn => {
          return (payOn !== PayOn.NotApplicable || this.disabled) &&
            ((payOn !== PayOn.Defect && payOn !== PayOn.NatureDefect && payOn !== PayOn.MismanufacturedDefect) || (this.accountingCategory === AccountingCategory.Payable.value && this.isLogsContract))
        })
    },

    label () {
      return this.type === 'PayBy' ? this.$t('payBy') : this.$t('payOn')
    },

    disabledHint () {
      if (this.type === 'PayOn' && this.disabled) return this.$t('disPayVariationHint')
      else return ''
    }
  }
}
</script>
