export class LocalStorageActor {
  constructor ({ key, read, write, init }) {
    this.key = key
    this._readTransform = read
    this._writeTransform = write
    this._init = init
  }

  write (item) {
    try {
      localStorage.setItem(this.key, JSON.stringify(this._writeTransform(item)))
    } catch (e) {
      console.error(`Failed writing to local storage value '${this.key}'`, e)
    }
  }

  read (context) {
    let stored = localStorage.getItem(this.key)
    if (stored === 'undefined') stored = null

    const isPresent = stored !== null
    try {
      if (this._init && !isPresent) {
        const initial = this._init(context)
        this.write(initial)
        return initial
      } else {
        return this._readTransform(JSON.parse(stored), { context, isPresent })
      }
    } catch (e) {
      console.error(`Failed transforming local storage value '${this.key}'`, stored, e)
      return undefined
    }
  }
}
