export const customRegisterCells =
  [
    {
      slotName: 'amount',
      value: 'amount'
    },
    {
      slotName: 'weight',
      value: 'weight'
    },
    {
      slotName: 'product',
      value: 'product'
    },
    {
      slotName: 'actions',
      value: 'actions'
    },
    {
      slotName: 'base-cost-rate',
      value: 'baseCost.rate'
    },
    {
      slotName: 'activityName',
      value: 'activityName'
    }
  ]

export const customCorrectionCells =
  [
    {
      slotName: 'activity',
      value: 'activity'
    }
  ]
