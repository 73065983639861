<template>
  <v-container fluid class="pb-6 pt-4">
    <v-row justify="end" dense>
      <div class="ma-0 pa-0 mt-n1 mr-4">
        <Icon
          v-if="allowDenseCharts"
          :icon="denseCharts ? 'mdi-grid-large' : 'mdi-grid'"
          iconColor="secondary"
          :tooltipText="denseCharts ? $t('displayExpanded') : $t('displayDense')"
          :small="false"
          margin="mt-4 mr-3"
          @icon-clicked="toggleDenseCharts"
        />
        <OperationalWidgetPicker
          :propWidgetSet="widgetSet"
          :openMenuKey="widgetPickerMenuKey"
          @new-widget-layout="newWidgetLayout"
        />
      </div>
    </v-row>
    <v-row
      v-if="widgetSlotSet.widgetSlots.length === 0"
      style="opacity: 0.2;"
    >
      <div style="text-align: center; width: 100%; padding-top: min(48px, 10vh);">
        <v-icon
          size="40vmin"
          @click="addNewWidget"
        >
          mdi-chart-box-plus-outline
        </v-icon>
        <br>
        <span
          class="title"
          @click="addNewWidget"
          style="user-select: none;"
        >
          {{$t('clickToAddAWidget')}}
        </span>
      </div>
    </v-row>
    <GridContainer
      v-if="widgetSlotSet.widgetSlots.length"
      :rows="containerRows"
      :columns="containerColumns"
      flow="row"
      gap="20px"
      class="py-4"
    >
      <GridArea
        v-for="widget, idx in widgetSlotSet.widgetSlots"
        :key="`widget-id-${widget.id !== 'empty' ? widget.id : widget.id.concat(idx)}`"
        :area="widget.dimensions"
        :id="widget.id"
      >
        <v-card
        v-if="widget.id === 'incompleteTickets'"
        class="fill-height"
        @click="onDataClicked(DashboardOperationalWidgets.IncompleteTickets, undefined, undefined, incompleteTickets)"
        >
          <v-card-text>
            <v-card :color="'black'" class="float-left">
              <v-icon color="white" style="padding: 15px; font-size: 40px;">
                mdi-receipt-text-clock-outline
              </v-icon>
            </v-card>
            <div class="text-right">
              <p class="grey--text text-right category">
                {{ $t('incompleteTickets') }}
              </p>
              <v-skeleton-loader type="chip" class="py-1" style="float: right;" v-if="!incompleteTickets"/>
              <h3 class="subtitle pa-0 ma-0" v-else>
                {{ incompleteTickets.count }}
              </h3>
            </div>
          </v-card-text>
        </v-card>

        <StackedBarChartCard
          type="LoadsInTransitByDestination"
          v-if="widget.id === 'loadsInTransitByDestination' && loadsInTransitByDestinationChart"
          :overrideTitle="$t('loadsInTransitByDestination')"
          :data="loadsInTransitByDestinationChart"
          elementId="in-transit-loads-by-external-destination-and-tract-chart"
          :tooltip="$t('loadsInTransitTooltip')"
          isInteractive
          @data-clicked="onDataClicked(DashboardOperationalWidgets.LoadsInTransitByDestination, 'destination', 'tract', loadsInTransitByDestinationChart, $event)"
        />

        <StackedBarChartCardForTicketsForPickup
          type="TicketsReadyForPickup"
          v-if="widget.id === 'loadsReadyForPickup' && ticketsReadyForPickupChart"
          :data="ticketsReadyForPickupChart"
          elementId="tickets-ready-for-pickup-chart"
          isInteractive
          @data-clicked="onDataClicked(DashboardOperationalWidgets.LoadsReadyForPickup, 'tract', 'product', ticketsReadyForPickupChart, $event)"
        />

        <StackedBarChartCard
          type="ForecastedDaysRemaining"
          :allowToggle=false
          v-if="widget.id === 'forecastedDaysRemainingByLogger' && forecastedRemainingDaysChart"
          :overrideTitle="$t('forecastedDaysRemainingByLogger')"
          :data="forecastedRemainingDaysChart"
          elementId="forecasted-days-remaining-chart"
        />
      </GridArea>
    </GridContainer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { OperationalDashboardWidgetSet } from '@/model/DashboardWidget.js'
import {
  StackedBarChartForLoadsInTransitByDestination,
  StackedBarChartForTicketsReadyForPickup,
  StackedBarChartForForecastedDaysRemaining
} from '@/model/StackedBarChart.js'
import { WidgetSlot, WidgetSlotSet } from '@/model/WidgetSlot.js'
import { DashboardOperationalWidgets } from '@/components/dashboard/WidgetMappings'
import { getNumEmptySlots } from '@/utils/DashboardHelpers'
export default {
  name: 'OperationalSummary',

  components: {
    GridArea: () => import('@/components/helper/GridArea.vue'),
    GridContainer: () => import('@/components/helper/GridContainer.vue'),
    StackedBarChartCard: () => import('@/components/dashboard/StackedBarChartCard.vue'),
    StackedBarChartCardForTicketsForPickup: () => import('@/components/dashboard/StackedBarChartCardForTicketsForPickup.vue'),
    OperationalWidgetPicker: () => import('@/components/dashboard/OperationalWidgetPicker.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    DashboardOperationalWidgets,
    containerRows: { sm: '60px', md: 'calc(45px + 0.5vw)' },
    containerColumns: { sm: 'repeat(12, 1fr)', md: 'repeat(24, 1fr)' },
    ticketsReadyForPickup: undefined,
    loadsInTransitByDestination: undefined,
    forecastedRemainingDays: undefined,
    widgetSet: undefined,
    denseCharts: false,
    widgetPickerMenuKey: 0
  }),

  props: {
    allowDenseCharts: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  computed: {
    ...mapGetters('dashboard', ['incompleteTickets']),

    widgetSlotSet () {
      const selectedWidgets = this.widgetSet.widgets
        .filter(c => c.visible)
        .map(c => new WidgetSlot(
          c.text,
          this.isCard(c) ? this.cardArea : this.chartArea,
          { widgetType: c.widgetType }
        ))

      if (selectedWidgets.some(c => this.isCard(c))) {
        const numCards = selectedWidgets.filter(c => this.isCard(c)).length
        if (getNumEmptySlots(numCards) > 0) {
          const li = selectedWidgets.findLastIndex(c => this.isCard(c))
          return new WidgetSlotSet([
            ...selectedWidgets.slice(0, li + 1),
            ...new Array(getNumEmptySlots(numCards)).fill(new WidgetSlot('empty', this.cardArea)),
            ...selectedWidgets.slice(li + 1)
          ])
        }
      }
      return new WidgetSlotSet(selectedWidgets)
    },

    loadsInTransitByDestinationChart () {
      return (this.loadsInTransitByDestination)
        ? new StackedBarChartForLoadsInTransitByDestination(this.loadsInTransitByDestination, 'destinationName', 'tractName')
        : undefined
    },

    ticketsReadyForPickupChart () {
      return (this.ticketsReadyForPickup)
        ? new StackedBarChartForTicketsReadyForPickup(this.ticketsReadyForPickup, 'tract', 'tract')
        : undefined
    },

    forecastedRemainingDaysChart () {
      return (this.forecastedRemainingDays)
        ? new StackedBarChartForForecastedDaysRemaining(this.forecastedRemainingDays, 'loggerName', 'tract')
        : undefined
    },

    chartArea () {
      return (this.denseCharts)
        ? [7, 8]
        : [9, 12]
    },

    cardArea () {
      return [2, {
        lg: 12, xl: 8
      }]
    }
  },

  created () {
    this.widgetSet = new OperationalDashboardWidgetSet()
    this.fetchWidgetData()
  },

  methods: {
    ...mapActions('dashboard', [
      'fetchLoadsInTransitByDestination',
      'fetchTicketsReadyForPickup',
      'fetchForecastedDaysRemaining',
      'fetchIncompleteTickets'
    ]),

    newWidgetLayout (widgetSet) {
      this.fetchWidgetData(widgetSet)
    },

    async fetchWidgetData (widgetSet = this.widgetSet) {
      const loadsInTransitByDestinationWidgets = [0]
      const ticketsReadyForPickupWidgets = [1]
      const forecastedDaysRemainingWidgets = [2]
      const incompleteTickets = [3]

      if (widgetSet.someWidgetShowing(loadsInTransitByDestinationWidgets)) {
        this.loadsInTransitByDestination = await this.fetchLoadsInTransitByDestination()
      }
      if (widgetSet.someWidgetShowing(ticketsReadyForPickupWidgets)) {
        this.ticketsReadyForPickup = await this.fetchTicketsReadyForPickup()
      }
      if (widgetSet.someWidgetShowing(forecastedDaysRemainingWidgets)) {
        this.forecastedRemainingDays = await this.fetchForecastedDaysRemaining()
      }
      if (widgetSet.someWidgetShowing(incompleteTickets)) {
        await this.fetchIncompleteTickets()
      }
      this.widgetSet = widgetSet
    },

    toggleDenseCharts () {
      this.denseCharts = !this.denseCharts
    },

    addNewWidget () {
      this.widgetPickerMenuKey += 1
    },

    onDataClicked (widget, pfk, sfk, data, dataObject) {
      this.$emit('data-clicked', {
        widget: widget,
        data: { ...data, paused: dataObject?.paused },
        primaryFilterKey: pfk,
        secondaryFilterKey: sfk,
        primaryFilterValue: dataObject?.xAxisValue,
        secondaryFilterValue: dataObject?.yAxisValue
      })
    },

    isCard (widget) {
      return widget.details?.widgetType?.value === 0 || widget.widgetType?.value === 0
    }
  }
}
</script>

<style scoped>
.v-card--link:focus:before { /* This prevents clicked cards from staying gray indefinitely */
  opacity: 0;
}

.v-card--link:hover { /* Vuetify's native "hover" class keeps the element hovered even after you click and move your cursor away. This uses Vuetify elevation-6's style but avoids that bug */
  box-shadow: 0px 3px 5px -1px
    var(--v-shadow-key-umbra-opacity,
    rgba(0, 0, 0, 0.2)), 0px 6px 10px 0px
    var(--v-shadow-key-penumbra-opacity,
    rgba(0, 0, 0, 0.14)), 0px 1px 18px 0px
    var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)) !important;
  transition: all 0.2s;
}

.v-card--link {
  transition: all 0.2s;
}
</style>
