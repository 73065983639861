<template>
  <v-container fluid>
    <v-row v-if="miscPayments.advances?.length > 0">
      <v-col>
        <SettlementAdvances
        :advances="miscPayments.advances"
        :isExportBatch="isExportBatch"/>
      </v-col>
    </v-row>
    <v-row v-if="miscPayments.tractPayments?.length > 0">
      <v-col>
        <v-container fluid>
          <span class="title">{{ $t('tractPayments') }}</span>
          <DataTable
          :items="miscPayments.tractPayments"
          :headers="settlementTractPaymentHeaders()"
          :showSearch="false"
          :showDense="false"/>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="miscPayments.accountPayments?.length > 0">
      <v-col>
        <v-container fluid>
          <span class="title">{{ $t('accountPayments') }}</span>
          <DataTable
          :items="miscPayments.accountPayments"
          :headers="settlementAccountPaymentHeaders()"
          :showSearch="false"
          :showDense="false"/>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ContractModes } from '../../../../utils/Enumerations'
import { settlementTractPaymentHeaders, settlementAccountPaymentHeaders } from '@/headers/ExportBatch.js'
export default {
  name: 'MiscSettlementPayments',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    SettlementAdvances: () => import('@/components/settlements/accounts-payable-module/other-modules/SettlementAdvances.vue')
  },

  props: {
    miscPayments: {
      type: Object,
      required: true
    },
    isExportBatch: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    ContractModes
  }),

  methods: {
    settlementTractPaymentHeaders,
    settlementAccountPaymentHeaders
  }
}
</script>
