<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="4" v-for="payee in payeeInfo" :key="payee.payeeId">
        <v-card>
          <v-card-title
          class="secondary white--text subtitle-1"
          style="height: 35px;"
          @click="openActivityBreakdown(payee)">
            <Icon
            margin="mt-n3 mr-1"
            dataTestId="tract-name"
            :icon="'mdi-account'"
            :tooltipText="$t('account')"
            iconColor="white"
            tooltipColor="secondary"
            />
            <div class="mt-n3" style="white-space: nowrap; word-break: normal; overflow: hidden; text-overflow: ellipsis;">{{payee.payeeName}}</div>
            <v-spacer/>
            <span class="mt-n3">{{formatMoney(payee.payout)}}</span>
            <span v-if="payee.payout !== payee.grossPayout" class="mt-n3">
              &nbsp;({{formatMoney(payee.grossPayout)}})
            </span>
          </v-card-title>
          <v-card-text class="mt-2" style="overflow-y: auto; height:125px;">
            <v-row>
              <v-col @click="openActivityBreakdown(payee)">
                <table>
                  <tr class="font-weight-bold">
                    <td>{{$t('activity')}}</td>
                    <td>{{$t('averageRate')}}</td>
                    <td>{{$t('unit')}}</td>
                  </tr>
                  <tr v-for="activity in payee.paymentRollup.slice(0, 2)" :key="`${payee.payeeId}-${activity.activityId}`">
                    <td>{{activity.activityName}}</td>
                    <td class="text-right">{{formatMoney(activity.averageRate)}}</td>
                    <td class="text-right">{{getUnitTextForActivity(activity)}}</td>
                  </tr>
                </table>
              <span class="font-italic ml-2" v-if="payee.paymentRollup.length > 2">{{$t('clickToShowAllActivities')}}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Dialog stateId="settlement-activity-breakdown"
    @dialog-closing="close"
    maxWidth="80vw">
      <SettlementActivityBreakdown
      :propPayee="focusedPayee"
      :propContractInfo="contractInfo"
      @refresh-settlement="$emit('refresh-settlement')"
      v-if="activityBreakdownDialog"/>
    </Dialog>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import { PayOn, PayBy, ActivityModifier } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SettlementAccountTable',

  props: {
    payeeInfo: {
      type: Array,
      required: true
    },

    contractInfo: {
      type: Object
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    SettlementActivityBreakdown: () => import('@/components/settlements/accounts-payable-module/settlements-by-contract/SettlementActivityBreakdown.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  data: () => ({
    focusedPayee: undefined,
    activityBreakdownDialog: false,
    dialog: false
  }),

  watch: {
    payeeInfo: {
      handler () {
        this.refreshPaymentDetailDialog()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('settlements', ['currentSubModule', 'dateConfiguration'])
  },

  methods: {
    ...mapActions('settlements', ['fetchSettlementsByPayee']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,

    openActivityBreakdown (payee) {
      this.focusedPayee = payee
      this.activityBreakdownDialog = true
      this.openOrUpdateDialog({ id: 'settlement-activity-breakdown', width: '70%' })
    },

    close () {
      this.closeDialogsAtOrAbove('settlement-activity-breakdown')
      this.focusedPayee = null
      this.activityBreakdownDialog = false
    },

    getUnitTextForActivity ({
      payOn,
      payBy,
      modifier,
      grossTons,
      defectTons,
      mismanufactureDefectTons,
      natureDefectTons,
      netTons
    }) {
      if (payBy === PayBy.Load.value) return this.$t('payByLoad')
      if (modifier === ActivityModifier.DistanceTraveled.value) { return this.$t('distanceInMiles', { distance: this.contractInfo.distance }) }

      let weight = 0
      switch (payOn) {
        case PayOn.Gross.value: weight = grossTons; break
        case PayOn.Net.value: weight = netTons; break
        case PayOn.Defect.value: weight = defectTons; break
        case PayOn.NatureDefect.value: weight = natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: weight = mismanufactureDefectTons; break
        default: weight = netTons
      }

      return `${PayOn.fromInt(payOn)}: ${weight.toFixed(3)}t`
    },

    refreshPaymentDetailDialog () {
      const refreshedPayee = this.payeeInfo?.find(p => p.payeeId === this.focusedPayee.payeeId)
      if (refreshedPayee === undefined) {
        this.close()
        return
      }
      this.focusedPayee = refreshedPayee
    }
  }
}
</script>

<style scoped>
table td {
  padding: 0px 12px;
  border-collapse:separate;
}
</style>
