<template>
  <v-footer id="core-footer" absolute height="60" class="tertiary">
    <div class="footer-items">
        <span class="font-weight-light secondary--text" style="">
          &copy;
          {{ (new Date()).getFullYear() }}
          Legna Software, LLC
        </span>
        <v-divider class="black--text" vertical></v-divider>
        <span v-for="link in links" :key="link.name">
          <a id="footer-link" :href="link.Link" target="blank" class="secondary--text footer-links">{{ link.name }}</a>
        </span>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    links () {
      return ([
        { name: this.$t('privacyPolicy'), Link: 'https://www.legnasoftware.com/privacy' }
      ])
    }
  }
}
</script>

<style scoped>
a:hover {
  color: #D15F27 !important;
}
a:visited, span:visited {
  color: grey
}
#core-footer {
  z-index: 0;
}
#footer-link:hover {
  color: #D15F27 !important;
}
</style>
