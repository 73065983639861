import messages from '../lang'

export default {
  auditHeaders (locale) {
    return [
      {
        sortable: true,
        text: messages[locale].changedBy,
        value: 'modifiedBy'
      },
      {
        sortable: true,
        text: messages[locale].date,
        value: 'modifiedAt'
      },
      {
        sortable: true,
        text: messages[locale].type,
        value: 'opType'
      },
      {
        sortable: true,
        text: messages[locale].field,
        value: 'componentSegments'
      },
      {
        sortable: false,
        text: messages[locale].from,
        value: 'from'
      },
      {
        sortable: false,
        text: messages[locale].to,
        value: 'to'
      },
      {
        sortable: true,
        text: messages[locale].cause,
        value: 'modifiedReason'
      }
    ]
  }
}
