export default {
  setCertificationLoadingStatus (state, status) {
    state.certificationLoadingStatus = status
  },
  setCertifications (state, certifications) {
    state.certifications = certifications
  },
  addCertification (state, certification) {
    state.certifications.push(certification)
  },
  removeCertification (state, certificationId) {
    state.certifications = state.certifications.filter(a => a.certificationId !== certificationId)
  },
  updateCertification (state, certification) {
    const index = state.certifications.findIndex(a => a.certificationId === certification.certificationId)
    if (index !== -1) {
      Object.assign(state.certifications[index], certification)
    }
  }
}
