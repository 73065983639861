<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.GlobalAdmin" @n-shortcut="mutateCertification(null, 'create')">
  <v-container fluid data-testid="certification-table">
    <Dialog :stateId="dialogId" maxWidth="900px" @dialog-closing="close">
      <ConfirmDelete
        @close="close"
        v-if="deleting"
        :title="$t('certification')"
        @delete="deleteItem"
        @cancel-delete="close"/>
      <CertificationForm
        @close="close"
        @certification-mutated="certificationMutated"
        v-if="editing || creating"
        :isEditing="editing"
        :propCertification="focusedCertification"
        :certificationCategory="category"
      />
    </Dialog>
    <v-row>
      <v-col cols="12">
        <material-card
          color="secondary"
          :title="$t('certifications')"
          :text="$t('certificationDescription')"
          full-width
        >
          <DataTable
          :items="filteredCertifications"
          :headers="headers"
          :customCells="customTableCells"
          :actions="tableActions"
          :loading.sync="loading"
          @new-certification="mutateCertification(null, 'create')"
          @refresh="refreshCertifications"
          >
            <template #custom-controls>
              <v-select
              style="max-width: 200px; min-width: 150px;"
              :items="categories"
              class="ml-2"
              item-color="secondary"
              v-model="category"
              data-testid="certification-category-name"
              label="Certification Category"
              />
            </template>
            <template #actions="slotProps">
              <v-icon
                small
                class="mr-2"
                color="success"
                data-testid="certification-edit-button"
                @click="mutateCertification(slotProps.item, 'edit')"
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                color="error"
                data-testid="certification-delete-button"
                @click="mutateCertification(slotProps.item, 'delete')"
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                >mdi-delete-forever</v-icon
              >
            </template>
          </DataTable>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CertificationHeaders from '@/headers/Certification'
import { CertificationCategory } from '@/utils/Enumerations'
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

export default {
  name: 'CertificationSettings',

  components: {
    Dialog: () => import('@/components/Dialog'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    CertificationForm: () => import('./CertificationForm.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  created () {
    this.refreshCertifications()
  },

  computed: {
    ...mapGetters('certification', ['allCertifications']),

    headers () {
      return CertificationHeaders.certificationHeaders()
    },

    tableActions () {
      return [
        {
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          actionName: 'refresh'
        },
        {
          icon: 'mdi-plus',
          text: this.$t('newCertification'),
          actionName: 'new-certification',
          disabled: !userAssignedClaim(UserClaims.GlobalAdmin)
        }
      ]
    },

    filteredCertifications () {
      return this.allCertifications.filter(c => c.category === CertificationCategory.toInt(this.category))
    },

    customTableCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  data: () => ({
    dialogId: 'certification-settings',
    categories: [...CertificationCategory.names],
    editing: false,
    deleting: false,
    creating: false,
    loading: false,
    category: CertificationCategory.names[0],
    focusedCertification: {},
    UserClaims
  }),

  watch: {
    deleting (val) {
      if (!val) {
        this.refreshCertifications()
      }
    }
  },

  methods: {
    ...mapActions('certification', ['fetchCertifications', 'deleteCertification']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,

    refreshCertifications () {
      this.loading = true
      this.fetchCertifications(null)
        .finally(() => {
          this.loading = false
        })
    },

    mutateCertification (certification, mutationType) {
      this.close()
      this.focusedCertification = Object.assign({}, certification)

      switch (mutationType) {
        case 'delete':
          this.deleting = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
          break
        case 'edit':
          this.editing = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '900px' })
          break
        case 'create':
          this.focusedCertification = {}
          this.creating = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '900px' })
          break
      }
    },

    async deleteItem () {
      await this.deleteCertification(this.focusedCertification.certificationId)
      this.close()
    },

    certificationMutated () {
      this.refreshCertifications()
      this.close()
    },

    close () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.deleting = false
      this.editing = false
      this.creating = false
      this.focusedCertification = {}
    }
  }
}
</script>
