<template>
  <div style="margin: 0; padding: 0; display: inline-block;">
    <Selector
    label="Timeframe"
    :items="usingTimeframes"
    :initialItem="initialTimeframe"
    @item-selected="timeframeSelected"
    itemValue="label"
    useCustomSlots>
      <template #selection="{item}">{{$t(item.label)}}</template>
      <template #item="{item}">{{$t(item.label)}}</template>
      <template #append-outer="{item}">
        <Icon
        v-if="item.appendOuter"
        :icon="item.appendOuter.icon"
        :small="false"
        @icon-clicked="item.appendOuter.action"
        :tooltipText="item.appendOuter.tooltip"/>
      </template>
    </Selector>

    <Dialog :stateId="dialogId" hideRefresh hideFullscreen>
      <CustomDateRangeForm
        :sinceTime="resolvedTimeframe.sinceTime"
        :untilTime="resolvedTimeframe.untilTime"
        :max="max"
        @dates-chosen="customTimeframeChosen"
        @close="closeCustomDateRangeDialog"
      />
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeframes as dashboardTimeframes, weekTimeframe } from './DashboardTimeframe'
export default {
  name: 'TimeframeSelector',
  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    CustomDateRangeForm: () => import('@/components/dashboard/CustomDateRangeForm.vue'),
    Selector: () => import('@/components/core/Selector.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },
  props: {
    timeframes: {
      type: Array,
      required: false,
      default: undefined
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCustom: {
      type: Boolean,
      required: false,
      default: true
    },
    timeLabelValue: {
      type: String,
      required: false,
      default: undefined
    },
    max: {
      type: Number,
      required: false,
      default: 98
    }
  },
  data: () => ({
    initialTimeframe: undefined,
    selectedTimeframe: undefined,
    dialogId: 'timeframe-selector'
  }),

  computed: {
    defaultTimeframes () {
      return dashboardTimeframes
    },

    usingTimeframes () {
      const out = this.timeframes ?? this.defaultTimeframes
      return (this.allowCustom)
        ? [...out,
          {
            label: 'custom',
            resolve: () => ({}),
            appendOuter: {
              icon: 'mdi-clock-edit-outline',
              tooltip: this.$t('specifyTimeframe'),
              action: () => this.openOrUpdateDialog({ id: this.dialogId, width: '40%' })
            }
          }]
        : out
    },

    resolvedTimeframe () {
      return (this.selectedTimeframe?.resolve)
        ? this.selectedTimeframe.resolve()
        : weekTimeframe()
    }
  },

  watch: {
    selectedTimeframe (timeframe) {
      this.initialTimeframe = timeframe.label
      this.doEmit()
    }
  },

  created () {
    this.selectInitialValue()
    if (this.immediate) this.doEmit()
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    timeframeSelected (timeframe) {
      if (!timeframe) return

      if (timeframe.label === 'custom') {
        this.openOrUpdateDialog({ id: this.dialogId, width: '40%' })
      } else {
        this.selectedTimeframe = timeframe
      }
    },

    customTimeframeChosen (timeframe) {
      this.selectedTimeframe = {
        label: 'custom',
        resolve: () => ({
          sinceTime: timeframe.sinceTime,
          untilTime: timeframe.untilTime
        })
      }
      this.closeCustomDateRangeDialog()
    },

    closeCustomDateRangeDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    selectInitialValue () {
      if (this.timeLabelValue) {
        this.initialTimeframe = this.usingTimeframes.find(t => t.label === this.timeLabelValue)
      }

      this.initialTimeframe ??=
        (this.usingTimeframes.length > 0)
          ? this.usingTimeframes[0]
          : undefined
    },

    doEmit () {
      if (this.selectedTimeframe !== undefined) {
        const r = this.selectedTimeframe.resolve()
        this.$emit('item-selected', {
          sinceTime: r.sinceTime,
          untilTime: r.untilTime,
          timeLabel: this.selectedTimeframe.label
        })
      }
    }
  }
}
</script>
