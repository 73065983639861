import i18n from '@/i18n'
import { HarvestUnits } from '@/utils/Enumerations'

export default {
  compositionHeaders (units = HarvestUnits.Tons) {
    const isTons = units === HarvestUnits.Tons
    return [
      {
        sortable: false,
        text: i18n.t('name'),
        value: 'product'
      },
      {
        sortable: false,
        text: isTons ? i18n.t('harvestedTons') : i18n.t('harvestedMBF'),
        value: isTons ? 'harvestedTons' : 'harvestedMBF',
        align: 'right'
      },
      {
        sortable: false,
        text: isTons ? i18n.t('adjustmentTons') : i18n.t('adjustmentMBF'),
        value: isTons ? 'adjustmentTons' : 'adjustmentMBF',
        align: 'right'
      },
      {
        sortable: false,
        text: isTons ? i18n.t('remainingTons') : i18n.t('remainingMBF'),
        value: isTons ? 'remainingTons' : 'remainingMBF',
        align: 'right'
      },
      {
        sortable: false,
        text: isTons ? i18n.t('expectedTons') : i18n.t('expectedMBF'),
        value: isTons ? 'expectedTons' : 'expectedMBF',
        align: 'right'
      },
      {
        sortable: false,
        text: isTons ? i18n.t('pricePerTon') : i18n.t('pricePerMBF'),
        value: isTons ? 'pricePerTon' : 'pricePerMBF',
        align: 'right'
      },
      {
        sortable: false,
        text: i18n.t('cruisedValue'),
        value: 'cruisedValue',
        align: 'right'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
