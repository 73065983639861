<template>
  <Selector
    return-object
    :items="locations"
    :label="$t('destinations')"
    :initialItem="initialDestination"
    @item-selected="locationSelected"
    itemText="name"
    >
  </Selector>
</template>

<script>
import { mapGetters } from 'vuex'
import { LocalStorageKeys } from '@/utils/constants.js'
import { ContractModes } from '../../utils/Enumerations'

export default {
  name: 'DestinationSelector',

  components: {
    Selector: () => import('@/components/core/Selector.vue')
  },

  props: {
    contractMode: {
      type: Object,
      required: true
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    initialDestination: undefined,
    selectedItem: {}
  }),

  computed: {
    ...mapGetters('locations', ['allLocations']),
    isByproduct () {
      return this.contractMode === ContractModes[1]
    },

    locationStorageKey () {
      return this.isByproduct ? LocalStorageKeys.BYPRODUCTS_DASHBOARD_DESTINATION_ID : LocalStorageKeys.LOGS_DASHBOARD_DESTINATION_ID
    },

    locations () {
      const allDestinationsOption = {
        name: this.isByproduct ? this.$t('allLocations') : this.$t('allDestinations'),
        locationId: -1,
        destinationAccountId: -1
      }

      const internalDestinationsOption = {
        name: this.$t('internalDestinations'),
        locationId: -1,
        destinationAccountId: null
      }

      const options = [allDestinationsOption]
      if (this.allLocations.length > 1 && !this.isByproduct) options.push(internalDestinationsOption)
      options.push(...this.allLocations)

      return options
    }
  },

  created () {
    this.setInitialValue()
    if (this.immediate) this.doEmit()
  },

  methods: {
    setInitialValue () {
      const storedLocation = localStorage.getItem(this.locationStorageKey)

      if (storedLocation) {
        const parsed = JSON.parse(storedLocation)
        this.initialDestination = this.locations.find(l => l.locationId === parsed.locationId && l.destinationAccountId === parsed.destinationAccountId)
      } else {
        this.initialDestination = this.locations[0]
      }
    },

    locationSelected (dest) {
      this.selectedItem.locationId = dest.locationId
      this.selectedItem.destinationAccountId = dest.destinationAccountId
      this.selectedItem.location = dest

      localStorage.setItem(this.locationStorageKey, JSON.stringify(dest))

      this.doEmit()
    },

    doEmit () {
      if (this.selectedItem.locationId || this.selectedItem.destinationAccountId !== undefined) this.$emit('item-selected', this.selectedItem)
    }
  }
}
</script>
