import i18n from '@/i18n'
import { PayBy, PayOn } from '../utils/Enumerations'
import { formatMoney } from '../utils/NumericMutations'

const ifn = (cond, v) => cond ? v : undefined

export function headers ({ includeGross = false } = {}) {
  return [
    {
      sortable: false,
      text: i18n.t('account'),
      value: 'accountName'
    },
    {
      sortable: false,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payOn'),
      value: 'computedAtPayOn',
      format: x => PayOn.fromInt(x)
    },
    {
      sortable: true,
      text: i18n.t('payBy'),
      value: 'computedAtPayBy',
      format: x => PayBy.fromInt(x)
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'computedAtRate',
      align: 'right',
      format: formatMoney
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    },
    ifn(includeGross, {
      sortable: true,
      text: i18n.t('gross'),
      value: 'grossAmount',
      align: 'right',
      format: formatMoney
    })
  ].filter(h => h !== undefined)
}
