<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :label="label"
    :menu-props="{ bottom: true, offsetY: true }"
    :style="style"
    color="secondary"
    return-object
    :item-text="itemText"
    :item-value="itemValue"
    :solo="variant === 'solo'"
    :outlined="variant === 'outlined'"
    @change="itemSelected"
    :no-data-text="$t('noDataForSelection')"
  >

  <template #selection v-if="useCustomSlots">
    <slot name="selection" :item="selectedItem" v-if="selectedItem !== undefined"></slot>
  </template>
  <template #item="{item}" v-if="useCustomSlots">
    <slot name="item" :item="item"/>
  </template>
  <template v-slot:append-outer>
    <slot name="append-outer" :item="selectedItem" v-if="selectedItem?.appendOuter !== undefined"></slot>
  </template>
</v-select>
</template>

<script>
export default {
  name: 'Selector',

  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      default: undefined
    },
    minWidth: {
      type: String,
      default: undefined
    },
    initialItem: {
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectFirstOnChange: {
      type: Boolean,
      default: false
    },
    equality: {
      type: Function,
      required: false,
      default: (a, b) => a === b
    },
    itemText: {
      type: String,
      required: false,
      default: undefined
    },
    useCustomSlots: {
      type: Boolean,
      required: false,
      default: false
    },
    itemValue: {
      type: String,
      required: false,
      default: undefined
    },
    variant: {
      type: String,
      required: false,
      default: 'solo'
    }
  },

  computed: {
    style () {
      return this.minWidth || !this.width
        ? { minWidth: this.minWidth || '200px' }
        : { width: this.width }
    }
  },

  watch: {
    items: {
      handler (val) {
        if (val.length === 0) return

        if (this.selectedItem === undefined) {
          this.itemSelected(val[0], 0)
          return
        }

        if (this.selectFirstOnChange) {
          const previousSelectionIndex = val.findIndex(i => this.equality(i, this.selectedItem))
          const selectionIndex = previousSelectionIndex !== -1 ? previousSelectionIndex : 0
          this.itemSelected(val[selectionIndex], selectionIndex)
        }
      },
      deep: true
    }
  },

  created () {
    this.setInitialItem()
  },

  data: () => ({
    selectedItem: undefined,
    selectedIndex: 0
  }),

  methods: {
    itemSelected (item, index) {
      this.selectedItem = item
      this.selectedIndex = index
      this.$emit('item-selected', item)
    },

    setInitialItem () {
      if (this.items === undefined) { return }
      if (this.items.length <= 0) { return }
      if (this.initialItem !== undefined) this.selectedIndex = this.items?.findIndex(i => this.equality(i, this.initialItem))
      if (this.selectFirstOnChange === true) { return }

      this.selectedItem = this.initialItem === undefined ? this.items[0] : this.initialItem
      this.$emit('item-selected', this.selectedItem)
    }
  }
}
</script>
