<template>
  <v-card>
    <v-card-title class="error headline white--text">
      {{$t('activityCostWarning')}}
    </v-card-title>
    <v-card-text class="subtitle-1 mt-2">
      {{
        $t('activityCostDescription')
      }}
    </v-card-text>
    <v-card-actions>
      <v-btn data-testid="activity-warn-close-button" color="black" text @click="$emit('confirmation', false)">{{$t('cancel')}}</v-btn>
      <v-spacer/>
      <v-btn data-testid="activity-warn-submit-button" color="error" @click="$emit('confirmation', true)">
        {{$t('saveChanges')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'ActivityCostWarning',

  props: {
    activityCost: Number
  },

  methods: {
    formatMoney
  }
}
</script>
