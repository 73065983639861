export default {
  tract: (fill) => {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="24" height="24" viewBox="0 0 24 24">
      <path fill="${fill}" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
    </svg>`
  },
  load: (fill) => {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="24" height="24" viewBox="0 0 24 24">
    <path
        fill="${fill}" d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z" style="stroke-width:1.5;stroke-dasharray:none;stroke:#000000;stroke-opacity:0.5;stroke-linejoin:round;stroke-linecap:square;paint-order:markers stroke fill" />  
    </svg>`
  },
  home: `<?xml version="1.0" encoding="UTF-8"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="24" height="24" viewBox="0 0 24 24">
    <path fill="#F9F6DF" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" style="stroke-width:1.5;stroke-dasharray:none;stroke:#000000;stroke-opacity:0.5;stroke-linejoin:round;stroke-linecap:square;paint-order:markers stroke fill" />
  </svg>`,
  empty: (fill, outline) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
   <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      id="svg3729"
      sodipodi:docname="empty-24px.svg"
      inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
      <metadata
         id="metadata3735">
      <rdf:RDF>
         <cc:Work
            rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
               rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            <dc:title></dc:title>
         </cc:Work>
      </rdf:RDF>
      </metadata>
      <defs
         id="defs3733" />
      <sodipodi:namedview
         pagecolor="#ffffff"
         bordercolor="#666666"
         borderopacity="1"
         objecttolerance="10"
         gridtolerance="10"
         guidetolerance="10"
         inkscape:pageopacity="0"
         inkscape:pageshadow="2"
         inkscape:window-width="1920"
         inkscape:window-height="1017"
         id="namedview3731"
         showgrid="false"
         inkscape:zoom="35.333333"
         inkscape:cx="17.029986"
         inkscape:cy="12"
         inkscape:window-x="-8"
         inkscape:window-y="-8"
         inkscape:window-maximized="1"
         inkscape:current-layer="svg3729" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
         <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
         id="path3727"
         fill=""
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccss"
         style="fill:none;fill-opacity:1;stroke:${outline};stroke-opacity:1;paint-order:stroke fill markers;stroke-width:1.5;stroke-dasharray:none" />
      <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z m 0,19.47 C 9.82,17.86 6,12.54 6,9 6,5.69 8.69,3 12,3 c 3.31,0 6,2.69 6,6 0,3.83 -4.25,9.36 -6,11.47 z"
         id="path3727"
         fill="${fill}"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccsscsssc" />
   </svg>
   `
  },
  twentyFive: (fill, outline) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         version="1.1"
         id="svg3729"
         sodipodi:docname="25pct-24px.svg"
         inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
      <metadata
         id="metadata3735">
         <rdf:RDF>
            <cc:Work
               rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
               rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            <dc:title />
            </cc:Work>
         </rdf:RDF>
      </metadata>
      <defs
         id="defs3733" />
      <sodipodi:namedview
         pagecolor="#ffffff"
         bordercolor="#666666"
         borderopacity="1"
         objecttolerance="10"
         gridtolerance="10"
         guidetolerance="10"
         inkscape:pageopacity="0"
         inkscape:pageshadow="2"
         inkscape:window-width="1920"
         inkscape:window-height="1137"
         id="namedview3731"
         showgrid="false"
         inkscape:zoom="35.333333"
         inkscape:cx="3.2328158"
         inkscape:cy="12"
         inkscape:window-x="-8"
         inkscape:window-y="-8"
         inkscape:window-maximized="1"
         inkscape:current-layer="svg3729" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
         <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
         id="path3727"
         fill=""
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccss"
         style="fill:none;fill-opacity:1;stroke:${outline};stroke-opacity:1;paint-order:stroke fill markers;stroke-width:1.5;stroke-dasharray:none" />
      <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.959766,13.339922 7.259766,13.669922 L 12,23.490234 12.740234,22.669922 C 13.040234,22.339922 20,14.57 20,9 20,4.59 16.41,1 12,1 Z m 0,2 c 3.31,0 6,2.69 6,6 0,2.367565 -1.623733,5.382477 -3.277344,7.839844 H 9.2910156 C 7.5826521,14.284449 6,11.277544 6,9 6,5.69 8.69,3 12,3 Z"
         id="path3727"
         fill="${fill}"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccssssccss" />
      </svg>
   `
  },
  fifty: (fill, outline) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         version="1.1"
         id="svg3729"
         sodipodi:docname="50pct-24px.svg"
         inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
      <metadata
         id="metadata3735">
         <rdf:RDF>
            <cc:Work
               rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
               rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            <dc:title />
            </cc:Work>
         </rdf:RDF>
      </metadata>
      <defs
         id="defs3733" />
      <sodipodi:namedview
         pagecolor="#ffffff"
         bordercolor="#666666"
         borderopacity="1"
         objecttolerance="10"
         gridtolerance="10"
         guidetolerance="10"
         inkscape:pageopacity="0"
         inkscape:pageshadow="2"
         inkscape:window-width="1920"
         inkscape:window-height="1137"
         id="namedview3731"
         showgrid="false"
         inkscape:zoom="35.333333"
         inkscape:cx="7.8318724"
         inkscape:cy="11.575472"
         inkscape:window-x="-8"
         inkscape:window-y="-8"
         inkscape:window-maximized="1"
         inkscape:current-layer="svg3729" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
         <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
         id="path3727"
         fill=""
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccss"
         style="fill:none;fill-opacity:1;stroke:${outline};stroke-opacity:1;paint-order:stroke fill markers;stroke-width:1.5;stroke-dasharray:none" />
      <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.959766,13.339922 7.259766,13.669922 L 12,23.490234 12.740234,22.669922 C 13.040234,22.339922 20,14.57 20,9 20,4.59 16.41,1 12,1 Z m 0,2 c 3.31,0 6,2.69 6,6 0,0.6728663 -0.137534,1.400347 -0.363281,2.150391 H 6.3671875 C 6.1342599,10.390457 6,9.6642525 6,9 6,5.69 8.69,3 12,3 Z"
         id="path3727"
         fill="${fill}"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccssssccss" />
   </svg>
   `
  },
  seventyFive: (fill, outline) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
   <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      id="svg3729"
      sodipodi:docname="75pct-24px.svg"
      inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
   <metadata
      id="metadata3735">
      <rdf:RDF>
         <cc:Work
            rdf:about="">
         <dc:format>image/svg+xml</dc:format>
         <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
         <dc:title />
         </cc:Work>
      </rdf:RDF>
   </metadata>
   <defs
      id="defs3733" />
   <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1920"
      inkscape:window-height="1137"
      id="namedview3731"
      showgrid="false"
      inkscape:zoom="35.333333"
      inkscape:cx="11.355744"
      inkscape:cy="11.909182"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      inkscape:current-layer="svg3729" />
   <path
      fill="none"
      d="M0 0h24v24H0V0z"
      id="path3725" />
   <path
      fill="none"
      d="M0 0h24v24H0V0z"
      id="path3725" />
      <path
      d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
      id="path3727"
      fill=""
      inkscape:connector-curvature="0"
      sodipodi:nodetypes="sscccss"
      style="fill:none;fill-opacity:1;stroke:${outline};stroke-opacity:1;paint-order:stroke fill markers;stroke-width:1.5;stroke-dasharray:none" />
   <path
      d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.959766,13.339922 7.259766,13.669922 L 12,23.490234 12.740234,22.669922 C 13.040234,22.339922 20,14.57 20,9 20,4.59 16.41,1 12,1 Z m 0,2 c 2.781577,0 5.119439,1.9017694 5.796875,4.4726562 H 6.203125 C 6.8805608,4.9017694 9.2184228,3 12,3 Z"
      id="path3727"
      fill="${fill}"
      inkscape:connector-curvature="0"
      sodipodi:nodetypes="sscccsssccs" />
   </svg>
   `
  },
  oneHundred: (fill, outline) => {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         width="24"
         height="25"
         viewBox="0 0 24 24"
         version="1.1"
         id="svg3729"
         sodipodi:docname="100pct-24px.svg"
         inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
      <metadata
         id="metadata3735">
         <rdf:RDF>
            <cc:Work
               rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
               rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            <dc:title />
            </cc:Work>
         </rdf:RDF>
      </metadata>
      <defs
         id="defs3733" />
      <sodipodi:namedview
         pagecolor="#ffffff"
         bordercolor="#666666"
         borderopacity="1"
         objecttolerance="10"
         gridtolerance="10"
         guidetolerance="10"
         inkscape:pageopacity="0"
         inkscape:pageshadow="2"
         inkscape:window-width="1920"
         inkscape:window-height="1137"
         id="namedview3731"
         showgrid="false"
         inkscape:zoom="16"
         inkscape:cx="10.984379"
         inkscape:cy="3.84292"
         inkscape:window-x="-8"
         inkscape:window-y="-8"
         inkscape:window-maximized="1"
         inkscape:current-layer="svg3729" />
      <path
         fill="none"
         d="M0 0h24v24H0V0z"
         id="path3725" />
         <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
         id="path3727"
         fill=""
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccss"
         style="fill:none;fill-opacity:1;stroke:${outline};stroke-opacity:1;paint-order:stroke fill markers;stroke-width:1.5;stroke-dasharray:none" />
      <path
         d="M 12,1 C 7.59,1 4,4.59 4,9 c 0,5.57 6.96,13.34 7.26,13.67 L 12,23.49 12.74,22.67 C 13.04,22.34 20,14.57 20,9 20,4.59 16.41,1 12,1 Z"
         id="path3727"
         fill="${fill}"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="sscccss" />

      </svg>
   `
  }
}
