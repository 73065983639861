<template>
  <v-card data-testid="team-form">
    <v-card-title class="secondary white--text">
      <v-row>
        <v-col>
          <span class="headline">{{title}}</span>
        </v-col>
        <v-col cols="auto">
          <BaseDialogActions hideRefresh hideFullscreen/>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        :disabled="isButtonDisabled"
        :lazyValidation="true"
        :buttonText="$t(isEditing ? 'saveChanges' : 'create')"
        @submit="saveTeam"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
              v-model="team.name"
              :rules="[rules.required]"
              :label="$t('name')"
              color="black"
              :counter="maxNameLength"
              :maxlength="maxNameLength"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
              v-model="team.description"
              :label="$t('description')"
              color="black"
              :counter="maxDescLength"
              :maxlength="maxDescLength"
              outlined
              auto-grow
              clearable
              dense
              />
            </v-col>
            <v-col :cols="10">
              <UserAutocomplete
              multiple
              chips
              deletableChips
              :label="$t('users')"
              :propUserIds="propTeam?.users?.map(u => u.applicationUserId) ?? undefined"
              :rules="[rules.requiredSelect]"
              :fetchUsers="false"
              @user-chosen="usersMutated"
              />
            </v-col>
          </v-row>
        </v-container>
        <template #right-action>
          <v-checkbox
          v-model="team.isActive"
          class="mr-4"
          :label="$t('active')"/>
        </template>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions, mapGetters } from 'vuex'

const MAX_NAME_LENGTH = 64
const MAX_DESC_LENGTH = 128

export default {
  name: 'TeamForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    UserAutocomplete: () => import('@/components/autocomplete/UserAutocomplete.vue')
  },

  props: {
    propTeam: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    maxNameLength: MAX_NAME_LENGTH,
    maxDescLength: MAX_DESC_LENGTH,
    rules: fieldRules.rules,
    team: {
      name: undefined,
      description: undefined,
      isActive: true,
      userIds: []
    }
  }),

  created () {
    if (this.propTeam) {
      this.team.teamId = this.propTeam.teamId
      this.team.name = this.propTeam.name
      this.team.description = this.propTeam.description
      this.team.isActive = this.propTeam.isActive
      this.team.userIds = this.propTeam.users.map(u => u.applicationUserId)
    }
  },

  computed: {
    ...mapGetters('user', ['allUsers', 'usersLoading']),

    title () {
      return this.propTeam ? this.$t('editing', { name: this.propTeam.name }) : this.$t('createNewTeam')
    },

    isEditing () {
      return !!this.propTeam
    },

    isButtonDisabled () {
      return !this.team.name?.trim().length > 0 || !this.team.userIds.length > 0
    },

    userSelectorErrors () {
      return this.team.userIds.length < 1 ? [this.$t('required')] : []
    }
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('team', ['createTeam', 'updateTeam']),

    async saveTeam () {
      if (!this.validate) return false

      if (this.isEditing) {
        await this.updateTeam(this.team)
        this.$emit('team-mutated')
      } else {
        await this.createTeam(this.team)
        this.$emit('team-mutated')
      }
    },

    validate () {
      if (!this.team.name) {
        this.setSnackError(this.$t('nameIsRequired'))
        return false
      }

      if (this.team.name.length > MAX_NAME_LENGTH) {
        this.setSnackError(this.$t('nameIsTooLong'))
        return false
      }

      if (this.team.description.length > MAX_DESC_LENGTH) {
        this.setSnackError(this.$t('descriptionIsTooLong'))
        return false
      }

      if (!this.team.userIds || this.team.userIds.length < 1) {
        this.setSnackError(this.$t('usersAreRequired'))
        return false
      }

      return true
    },

    async refreshUsers () {
      await this.getAllUsers()
    },

    usersMutated (users) {
      this.team.userIds = users.map(u => u.applicationUserId)
    }
  }
}
</script>
