export default {
  setLoading (state, status) {
    state.activitiesLoading = status
  },
  setActivities (state, activities) {
    state.activities = activities
  },
  addActivity (state, activity) {
    state.activities.push(activity)
  },
  removeActivity (state, activityId) {
    state.activities = state.activities.filter(a => a.activityId !== activityId)
  },
  updateActivity (state, activity) {
    const index = state.activities.findIndex(a => a.activityId === activity.activityId)
    if (index !== -1) {
      Object.assign(state.activities[index], activity)
    }
  },
  setOpenPanels (state, openPanels) {
    state.openPanels = openPanels
  },
  setActivitiesByEntity (state, activitiesByEntity) {
    state.activitiesByEntity = activitiesByEntity
  }
}
