<template>
  <div>
    <DataTable
      :headers="headers"
      :items.sync="ticketDefects"
      :customCells="customCells"
      :hide-header="true"
    >
      <template #name="{ item }">
        <DefectName
          @image-clicked="imageClicked"
          :has-images="item.hasImages"
          :defectId="item.ticketDefectId"
          :ticketId="item.ticketId"
        >
          <span
          :id="item.hasImages ? 'table-shortcut' : ''"
          @click="item.hasImages ? imageClicked(item.ticketDefectId, item.ticketId) : null">
            {{item.name}}
          </span>
        </DefectName>
      </template>
    </DataTable>
    <v-dialog v-model="defectImages" width="40vw">
      <DefectImageViewer
        v-if="defectImages"
        :ticketDefectId="defectId"
        :ticketId="ticketId"
        @close="defectImages = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ticketDefectHeaders } from '@/headers/Defect.js'
import { DefectCollectionType } from '@/utils/Enumerations.js'
export default {
  name: 'DefectTable',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    DefectImageViewer: () => import('@/components/defects/DefectImageViewer.vue'),
    DefectName: () => import('@/components/defects/DefectName.vue')
  },

  data: () => ({
    defectImages: false,
    defectId: undefined,
    ticketId: undefined
  }),

  props: {
    ticketDefects: {
      type: Array,
      required: true
    }
  },

  computed: {
    defectCollectionType () {
      if (this.ticketDefects.length === 0) { return DefectCollectionType.Simple.value }
      return this.ticketDefects[0].quantity > 0
        ? DefectCollectionType.Itemized.value
        : DefectCollectionType.Segmented.value
    },

    headers () {
      return ticketDefectHeaders(this.defectCollectionType)
    },

    customCells () {
      return [
        {
          slotName: 'name',
          value: 'name'
        }
      ]
    }
  },

  methods: {
    imageClicked (defectIdToOpen, ticketIdToOpen) {
      this.defectId = defectIdToOpen
      this.ticketId = ticketIdToOpen
      this.defectImages = true
    }
  }
}
</script>
