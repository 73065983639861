export default {
  setIncompleteTickets (state, incompleteTickets) {
    state.incompleteTickets = incompleteTickets
  },
  setLastWidgetTap (state, { widgetId, x, y = undefined }) {
    state.lastWidgetTap.widgetId = widgetId
    state.lastWidgetTap.x = x
    state.lastWidgetTap.y = y
  }
}
