import Constants from '@/utils/constants.js'
import { redirectUrl } from '../../env-var.js'

let url = redirectUrl.includes('VUE') ? process.env.VUE_APP_LOGIN_REDIRECT_URI : redirectUrl

if (!url) {
  url = 'http://localhost:8080/'
}

export const msalConfig = {
  auth: {
    clientId: Constants.CLIENT_REGISTRATION_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: url,
    postLogoutRedirectUri: url
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  scope: {
    scopes: [`api://${Constants.SERVER_REGISTRATION_ID}/.default`]
  }
}

export const graphMsalConfig = {
  auth: {
    clientId: Constants.CLIENT_REGISTRATION_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: url,
    postLogoutRedirectUri: url
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}
