var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({on:{"data-uploaded":function($event){return _vm.$emit('usersImported')}},scopedSlots:_vm._u([_vm._l((_vm.claimsHeaders),function(claimHeader,i){return {key:`preview-item.${claimHeader.value}`,fn:function({ value }){return [_c('div',{key:`claim-icon-${claimHeader.value}-${i}`},[(value)?_c('Icon',{attrs:{"icon":"mdi-checkbox-marked-circle","iconColor":"success","tooltipText":_vm.$t('assigned'),"tooltipColor":"success","small":""}}):_c('span')],1)]}}})],null,true)},'CsvImport',{
    parse: _vm.parse,
    preview: _vm.preview,
    upload: _vm.upload,
    exampleData: _vm.exampleData,
    entityName: _vm.entityName,
    rowName: _vm.rowName
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }