import { ref } from 'vue'

export class MultipleError extends Error {
  constructor (message, errors) {
    super(message)
    this.name = 'MultipleError'
    this.errors = errors
  }
}

const toArray = a => Array.isArray(a) ? a : [a]
const funcIsAsync = f => f.constructor?.name === 'AsyncFunction'
const isThenable = p => p !== null && typeof p === 'object' && typeof p.then === 'function'

async function promiseEach (promises) {
  const result = await Promise.allSettled(promises)
  if (result.find(r => r.status === 'rejected') !== undefined) {
    const errors = result.filter(r => r.status === 'rejected').map(r => r.reason)
    throw (errors.length === 1) ? errors[0] : new MultipleError('Collected multiple errors for promise collection', errors)
  }

  return result.map(r => r.value)
}

export class LoadHooks {
  static trace (tag, consoleMethod = console.log) {
    return new LoadHooks(
      () => { consoleMethod(`[${tag}] load started`) },
      () => { consoleMethod(`[${tag}] load finished`) },
      () => { consoleMethod(`[${tag}] load successful`) },
      e => { consoleMethod(`[${tag}] load failed with message:`, e.message) }
    )
  }

  static traceDuration (tag, consoleMethod = console.log) {
    consoleMethod ??= console.log
    return new LoadHooks(
      () => { consoleMethod(`[${tag}] load started`); return Date.now() },
      d => { consoleMethod(`[${tag}] load finished in ${Date.now() - d}ms`) },
      () => { consoleMethod(`[${tag}] load successful`) },
      (e, d) => { consoleMethod(`[${tag}] load failed after ${Date.now() - d}ms with message:`, e.message) }
    )
  }

  /**
   * @template P Type of the payload returned by the onStart function
   * @param {() => P} onStart Called before the loading action is started. This function's result is passed into the other functions as `payload`
   * @param {(payload: P) => any} onEnd Called after the loading action ends
   * @param {(payload: P) => any} onSuccess Called iff the loading action did not throw an error
   * @param {(error: Error, payload: P) => any} onError Called iff the loading action threw an error
   */
  constructor (onStart = undefined, onEnd = undefined, onSuccess = undefined, onError = undefined) {
    const noOp = () => {}
    const checkValidFn = fn => {
      if (fn === undefined || fn === null) return noOp
      if (typeof fn !== 'function') throw new Error('Load hook argument is not a function')
      return fn
    }
    this.start = checkValidFn(onStart)
    this.end = checkValidFn(onEnd)
    this.success = checkValidFn(onSuccess)
    this.error = checkValidFn(onError)
  }
}

/**
 * @template T type of data returned from the loading function
 * @param {LoadHooks[]} hooksArray
 * @param {Promise<T> | () => Promise<T>} loadingAction an action that executes a loading operation
 * @param {any[]} loadingArgs an array of arguments to be passed to the loading function
 * @returns {Promise<T>} the promised result of the loading function
 */
const loadWithHooks = async (hooksArray, loadingAction, loadingArgs = []) => {
  const payloads = hooksArray.map(h => h.start())
  try {
    const result = await (isThenable(loadingAction) ? loadingAction : loadingAction(...loadingArgs))
    hooksArray.forEach((hook, idx) => { hook.success(payloads[idx]); hook.end(payloads[idx]) })
    return result
  } catch (e) {
    hooksArray.forEach((hook, idx) => { hook.error(e, payloads[idx]); hook.end(payloads[idx]) })
    throw e
  }
}

export class ResourceLoader {
  // eslint doesn't like private class members, so we need to work around it with this
  /* eslint-disable no-dupe-class-members */

  static _defaultOptions = {
    customHooks: [],
    name: undefined,
    bindTo: undefined,
    awaitBehavior: 'all' // 'all' | 'each'
  }

  static empty = Object.freeze(new ResourceLoader([], { name: 'ResourceLoader.empty' }))

  constructor (loadingActions = [], options = ResourceLoader._defaultOptions) {
    // Expect the value of loadingActions to match
    // (f | async f | ResourceLoader | Thenable)
    // [(f | async f | ResourceLoader | Thenable), ...]

    // Note: Thenable is the minimum interface to use the `await` keyword
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#thenables

    options = Object.assign({}, ResourceLoader._defaultOptions, options)

    const customHooks = toArray(options.customHooks)
    for (const hooks of customHooks) {
      ResourceLoader.#validateHooks(hooks)
    }

    this._loadingActions = ResourceLoader.#validateLoadingActionArg(loadingActions)
    this._customHooks = customHooks
    this._name = options.name
    this._bindTo = options.bindTo
    this._awaitBehavior = ResourceLoader.#validateAwaitBehavior(options.awaitBehavior) // "each" | "all"

    this.loading = ref(false)
    this.error = ref(undefined)
    this.success = ref(false)
  }

  #hooks = new LoadHooks(() => {
    this.loading = true
    this.error = undefined
    this.success = false
  }, () => {
    this.loading = false
  }, () => {
    this.success = true
  }, e => {
    this.error = e
  })

  #delayedHooks (delay) {
    return new LoadHooks(() => {
      this.loading = false
      this.error = undefined
      this.success = false
      return setTimeout(() => { this.loading = true }, delay)
    }, timeout => {
      clearTimeout(timeout)
      this.loading = false
    }, () => {
      this.success = true
    }, e => {
      this.error = e
    })
  }

  static #validateHooks (hooks) {
    if (!(hooks instanceof LoadHooks)) throw new Error('Custom hooks object is not an instance of LoadHooks')
  }

  static #validateLoadingAction (action) {
    if (!(action instanceof ResourceLoader || typeof action === 'function' || isThenable(action))) {
      throw new Error('loading action is not a function, Thenable, or ResourceLoader')
    }
  }

  static #validateLoadingActionArg (arg) {
    const actions = toArray(arg)
    for (const action of actions) ResourceLoader.#validateLoadingAction(action)
    return actions
  }

  static #validateAwaitBehavior (arg) {
    if (!(arg === 'each' || arg === 'all')) throw new Error(`invalid value for promise behavior: expected one of 'each' or 'all', saw ${arg}`)
    return arg
  }

  static #loadingActionToPromise (action, args = [], bindTo = undefined) {
    args = toArray(args)

    // handle ResourceLoaders, Thenables, and normal or async functions
    if (isThenable(action)) return action
    if (action instanceof ResourceLoader) return action.load(...args)
    return (funcIsAsync(action))
      ? action.call(bindTo, ...args)
      : Promise.resolve(action.call(bindTo, ...args))
  }

  static #loadingActionsToSinglePromise (actions, args, bindTo, isEach) {
    // handle one or many actions and combine them if there are > 1
    switch (actions.length) {
      case 0:
        return Promise.resolve()
      case 1:
        return ResourceLoader.#loadingActionToPromise(actions[0], args, bindTo)
      default:
        if (!Array.isArray(args)) throw new Error('Resource loader with multiple loading actions expects an array of arguments, instead received', args)
        const invokableActions = actions.map((a, i) => ResourceLoader.#loadingActionToPromise(a, args?.[i] ?? [], bindTo))
        return isEach ? promiseEach(invokableActions) : Promise.all(invokableActions)
    }
  }

  async #load (actions, hooksToUse, args) {
    if (Object.isFrozen(this)) throw new Error(`Cannot load from a frozen loader object ${this._name ?? ''}`)
    return await loadWithHooks(
      [hooksToUse, ...this._customHooks],
      ResourceLoader.#loadingActionsToSinglePromise(actions, args, this._bindTo, this._awaitBehavior === 'each'))
  }

  async #loadThis (hooksToUse, args) {
    return await this.#load(this._loadingActions, hooksToUse, args)
  }

  async load (...args) {
    return await this.#loadThis(this.#hooks, args)
  }

  async loadDelayed (delay, ...args) {
    return await this.#loadThis(this.#delayedHooks(delay), args)
  }

  async loadOnce (loadingActions) {
    return await this.#load(loadingActions, this.#hooks)
  }

  async loadOnceDelayed (loadingActions, delay) {
    return await this.#load(loadingActions, this.#delayedHooks(delay))
  }

  addHooks (customHooks) {
    const hooksArr = toArray(customHooks)
    for (const hooks of hooksArr) {
      ResourceLoader.#validateHooks(hooks)
    }
    this._customHooks = [
      ...this._customHooks,
      ...hooksArr
    ]
    return this
  }

  addLoadingAction (loadingAction) {
    this._loadingActions = [
      ...this._loadingActions,
      ...ResourceLoader.#validateLoadingActionArg(loadingAction)
    ]
    return this
  }

  /* eslint-enable no-dupe-class-members */
}
