import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchLocations (context, { includeDeckHistory = false, includeLogDecks = true, includeByproductDecks = false } = {}) {
    try {
      context.commit('setLoading', true)
      const url = ApiServer.urlFor('locations', {
        params: { includeDeckHistory, includeLogDecks, includeByproductDecks }
      })
      const response = await ApiServer.get(url)
      context.commit('setLocations', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchLocationWithId (_, locationId) {
    const path = `locations/${locationId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async updateLocation (_, location) {
    const path = `locations/${location.locationId}`
    const response = await ApiServer.put(path, location)
    return response.data
  },
  async createLocation (_, location) {
    const path = `businessentities/${location.businessEntityId}/locations`
    const response = await ApiServer.post(path, location)
    return response.data
  },
  async deleteLocation (_, locationId) {
    const path = `locations/${locationId}`
    const response = await ApiServer.delete(path)
    return response.data
  }
}
