<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col cols="12" xs="12" sm="12" md="1" lg="1">
          <v-autocomplete
          label="State"
          autocomplete="disabled"
          data-testid="location-state"
          :items="states"
          @change="stateChanged"
          color="black"
          item-color="secondary"
          v-model="countrySubdivision"/>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-autocomplete
          autocomplete="disabled"
          data-testid="location-counties"
          :items="selectedCounties"
          v-model="countrySecondarySubdivision"
          @change="countyChosen"
          color="black"
          item-color="secondary"
          label="County"/>
        </v-col>
        <slot name="map-and-parcel-number"></slot>
        <slot name="acres"></slot>
        <slot name="pickup-radius"></slot>
        <slot name="logging-accessibility"></slot>
        <template>
          <v-col cols="12" xs="12" sm="12" md="8" :lg="(this.shapeFile && (this.shapeFile.name.length > 13)) ? 5 : 4">
            <v-file-input
              v-model="shapeFile"
              accept=".zip"
              underlined
              color="secondary"
              :label="$t('uploadTractShapefile')"
              :disabled="importedShapeFile !== null"
            >
              <template #append-outer>
                <Icon
                :disabled="!shapeFile || importedShapeFile !== null"
                icon="mdi-upload"
                iconColor="secondary"
                large
                :small="false"
                :tooltipText="$t('importShapefile')"
                @icon-clicked="importShapefileBtnClicked"
                />
                <Icon
                v-if="importedShapeFile !== null && isEditing"
                iconColor="secondary"
                :small="false"
                large
                icon="mdi-download"
                :tooltipText="$t('downloadShapefile')"
                @icon-clicked="downloadBtnClicked"
                />
                <Icon
                v-if="importedShapeFile !== null && isEditing"
                icon="mdi-file-document-remove-outline"
                iconColor="secondary"
                large
                :small="false"
                :tooltipText="$t('deleteShapefile')"
                @icon-clicked="deleteShapefileBtnClicked"
                />
              </template>
            </v-file-input>
          </v-col>
        </template>
        <v-spacer />
        <v-col cols="auto">
          <v-row align="center" dense>
            <v-col cols="auto" v-if="latLon">
              <Icon
                icon="mdi-compass-outline"
                iconColor="secondary"
                large
                :small="false"
                :tooltipText="$t('latLonToDMS')"
                @icon-clicked="toggleInputType"
              />
            </v-col>
            <v-col cols="auto" v-if="!latLon && smallDevice">
              <Icon
                icon="mdi-compass-outline"
                iconColor="secondary"
                large
                :small="false"
                :tooltipText="$t('dMSToLatLon')"
                @icon-clicked="toggleInputType"
              />
            </v-col>
            <v-col cols="auto">
              <v-row align="center" dense>
                <v-col cols="auto" v-if="!latLon && !smallDevice">
                  <Icon
                    icon="mdi-compass-outline"
                    iconColor="secondary"
                    large
                    :small="false"
                    :tooltipText="$t('dMSToLatLon')"
                    @icon-clicked="toggleInputType"
                  />
                </v-col>
                <v-col cols="auto" v-if="latLon">
                  <v-text-field
                    ref="latTField"
                    v-model="latitudeTextField"
                    data-testid="location-latitude"
                    :label="$t('latitude')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.required, rules.decimal, rules.validLatitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="latLon">
                  <v-text-field
                    ref="lonTField"
                    v-model="longitudeTextField"
                    data-testid="location-longitude"
                    :label="$t('longitude')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.required, rules.decimal, rules.validLongitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="!latLon">
                  <v-text-field
                    ref="degreesLatTField"
                    v-model="degreesTextFieldLat"
                    data-testid="location-degreeLat"
                    :label="$t('degrees')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.integer, rules.validDegreesNorthSouthForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="!latLon && smallDevice" align-self="center">
                  <Icon
                    :icon="degreesNorth ? 'mdi-alpha-n-box-outline' : 'mdi-alpha-s-box-outline'"
                    iconColor="secondary"
                    large
                    :small="false"
                    :tooltipText="degreesNorth ? $t('north') : $t('south')"
                    @icon-clicked="toggleNorthSouth"
                  />
                </v-col>
                <v-col cols="auto" v-if="!latLon">
                  <v-text-field
                    ref="minutesLatTField"
                    v-model="minutesTextFieldLat"
                    data-testid="location-minutesLat"
                    :label="$t('location-minutes')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.integer, rules.validMinutesForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="!latLon">
                  <v-text-field
                    ref="secondsLatTField"
                    v-model="secondsTextFieldLat"
                    data-testid="location-secondsLat"
                    :label="$t('seconds')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.required, rules.decimal, rules.fourDecimalPlacesOrFewer, rules.validSecondsForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="!latLon && !smallDevice" align-self="center">
                  <Icon
                    :icon="degreesNorth ? 'mdi-alpha-n-box-outline' : 'mdi-alpha-s-box-outline'"
                    iconColor="secondary"
                    large
                    :small="false"
                    :tooltipText="degreesNorth ? $t('north') : $t('south')"
                    @icon-clicked="toggleNorthSouth"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center" v-if="!latLon">
                <v-spacer v-if="!smallDevice" />
                <v-col cols="auto">
                  <v-text-field
                    ref="degreesLonTField"
                    v-model="degreesTextFieldLon"
                    data-testid="location-degreeLon"
                    :label="$t('degrees')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.integer, rules.validDegreesEastWestForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="smallDevice" align-self="center">
                  <Icon
                    :icon="degreesEast ? 'mdi-alpha-e-box-outline' : 'mdi-alpha-w-box-outline'"
                    iconColor="secondary"
                    large
                    :small="false"
                    :tooltipText="degreesEast ? $t('east') : $t('west')"
                    @icon-clicked="toggleEastWest"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-text-field
                    ref="minutesLonTField"
                    v-model="minutesTextFieldLon"
                    data-testid="location-minutesLon"
                    :label="$t('location-minutes')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.integer, rules.validMinutesForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto">
                  <v-text-field
                    ref="secondsLonTField"
                    v-model="secondsTextFieldLon"
                    data-testid="location-secondsLon"
                    :label="$t('seconds')"
                    color="black"
                    @blur="onTextFieldBlur"
                    :rules="[rules.required, rules.decimal, rules.fourDecimalPlacesOrFewer, rules.validSecondsForTextBox]"
                    type="number"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="auto" v-if="!smallDevice" align-self="center">
                  <Icon
                    :icon="degreesEast ? 'mdi-alpha-e-box-outline' : 'mdi-alpha-w-box-outline'"
                    iconColor="secondary"
                    large
                    :small="false"
                    :tooltipText="degreesEast ? $t('east') : $t('west')"
                    @icon-clicked="toggleEastWest"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" v-if="latLon">
              <v-btn class="secondary" data-testid="location-apply" :disabled="!showApplyButton" @click="applyLocation">
                {{ $t('apply') }}
              </v-btn>
              <v-btn text color="black" data-testid="location-reset" :disabled="!showResetButton" @click="resetLocation">
                {{ $t('reset') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-spacer v-if="!smallDevice"/>
              <v-col cols="auto" v-if="!latLon">
                <v-btn class="secondary" data-testid="location-apply" :disabled="!showApplyButton" @click="applyLocation">
                  {{ $t('apply') }}
                </v-btn>
                <v-btn text color="black" data-testid="location-reset" :disabled="!showResetButton" @click="resetLocation">
                  {{ $t('reset') }}
                </v-btn>
              </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0" id="tract-location-column">
          <div id="tract-location" :style="mapStyle">
            <div id="tract-location-map">
              <v-tooltip bottom color="black">
                <template
                  v-slot:activator="{on}">
                  <v-btn
                    class="scale-bar-toggle-button"
                    v-on="on"
                    :ripple="false"
                    elevation="0"
                    color="#00000000"
                    @click="toggleScaleBar()">
                  </v-btn>
                </template>
                <span class="subtitle-1 white--text" style="white-space: pre-line">{{ scaleBarToggleTooltip }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="deleteShapeFileConfirmation" width="400px">
      <ConfirmDialog
      :title="$t('deleteTractShapefile')"
      :body="$t('deleteTractShapefileBody', {tractName: propTract.name})"
      :confirmText="$t('deleteShapefileConfirmation')"
      @confirm="confirmDeleteShapefile"
      @cancel="cancel">
      </ConfirmDialog>
    </v-dialog>
  </v-row>
</template>

<script>
import * as atlas from 'azure-maps-control'
import fieldRules from '@/utils/rules'
import { azureMapsApiKey } from '../../../env-var.js'
import counties from '@/utils/state-counties.json'
import { mapActions } from 'vuex'
import { shapefileClient } from '../../utils/Shapefiles'
import shp from 'shpjs'
import { LocalStorageKeys } from '../../utils/constants'
import { getFormattedCoordWithTrailingZeroes, getFormattedCoord } from '../../utils/NumericMutations'
import { mapResizeTimeout } from '@/utils/MapHelpers'

const TIME_MAXIMUM = 60
const DEGREE_LON_MAX = 180
const DEGREE_LAT_MAX = 90
const SECONDS_DECIMAL_LIMIT = 4

export default {
  name: 'LocationSelection',

  props: {
    spot: {
      type: Object,
      required: true
    },
    locations: {
      type: Array,
      default: () => { return [] }
    },
    propTract: {
      type: Object,
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    markerCircle: {
      type: [Number, undefined],
      required: false,
      default: undefined
    }
  },

  components: {
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    map: null,
    marker: null,
    rules: fieldRules.rules,
    latitude: null,
    longitude: null,
    latitudeTextField: null,
    longitudeTextField: null,
    degreesLat: null,
    minutesLat: null,
    secondsLat: null,
    degreesTextFieldLat: null,
    minutesTextFieldLat: null,
    secondsTextFieldLat: null,
    degreesLon: null,
    minutesLon: null,
    secondsLon: null,
    degreesTextFieldLon: null,
    minutesTextFieldLon: null,
    secondsTextFieldLon: null,
    degreesNorth: false,
    degreesNorthOriginal: false,
    degreesEast: false,
    degreesEastOriginal: false,
    countrySubdivision: '',
    countrySecondarySubdivision: '',
    dragging: false,
    showMap: false,
    shapeFile: null,
    shapeFileAsArrayBuffer: null,
    boundarySource: null,
    mapLayers: null,
    deleteShapeFileConfirmation: false,
    importedShapeFile: null,
    circleShape: undefined,
    scaleBarRendered: false,
    currentScaleBar: 'imperial',
    scaleBarImport: undefined,
    scaleBarOptions: ['imperial', 'metric'],
    mapStyle: {
      width: '100%',
      height: '42vh'
    },
    resizeObserver: undefined,
    latLon: true
  }),

  computed: {
    selectedCounties () {
      if (counties[this.countrySubdivision] === undefined) return []
      const names = counties[this.countrySubdivision].map(c => c.N)
      return names
    },

    states () {
      return Object.keys(counties)
    },

    smallDevice () {
      return this.$vuetify.breakpoint.xsOnly
    },

    showApplyButton () {
      if (this.latLon) {
        const latitudeText = getFormattedCoord(this.latitudeTextField)
        const longitudeText = getFormattedCoord(this.longitudeTextField)
        const refsLoaded = this.$refs.latTField && this.$refs.lonTField
        const validFields = this.$refs.latTField?.valid && this.$refs.lonTField?.valid
        if (isNaN(latitudeText) || isNaN(longitudeText) || !this.rules.validLongitude(this.spot.longitude) || !this.rules.validLatitude(this.spot.latitude) || (!validFields && refsLoaded)) {
          return false
        }
        return parseFloat(this.latitude) !== latitudeText || parseFloat(this.longitude) !== longitudeText
      } else {
        const degreeTextLat = getFormattedCoord(this.degreesTextFieldLat)
        const degreeTextLon = getFormattedCoord(this.degreesTextFieldLon)
        const minutesTextLat = getFormattedCoord(this.minutesTextFieldLat)
        const minutesTextLon = getFormattedCoord(this.minutesTextFieldLon)
        const secondsTextLat = parseFloat(this.secondsTextFieldLat).toFixed(SECONDS_DECIMAL_LIMIT)
        const secondsTextLon = parseFloat(this.secondsTextFieldLon).toFixed(SECONDS_DECIMAL_LIMIT)
        const refsLoaded = this.$refs.degreesLatTField && this.$refs.degreesLonTField && this.$refs.minutesLatTField && this.$refs.minutesLonTField && this.$refs.secondsLatTField && this.$refs.secondsLonTField
        const validFields = this.$refs.degreesLatTField?.valid && this.$refs.degreesLonTField?.valid && this.$refs.minutesLatTField?.valid && this.$refs.minutesLonTField?.valid && this.$refs.secondsLatTField?.valid && this.$refs.secondsLonTField?.valid

        if (isNaN(degreeTextLat) || isNaN(degreeTextLon) || isNaN(minutesTextLat) || isNaN(minutesTextLon) || isNaN(secondsTextLat) || isNaN(secondsTextLon) || !this.rules.validLongitude(this.spot.longitude) ||
        !this.rules.validLatitude(this.spot.latitude) || (!validFields && refsLoaded)) {
          return false
        }
        return parseFloat(this.degreesLat) !== degreeTextLat || parseFloat(this.degreesLon) !== degreeTextLon || parseFloat(this.minutesLat) !== minutesTextLat || parseFloat(this.minutesLon) !== minutesTextLon ||
        this.secondsLat.toFixed(SECONDS_DECIMAL_LIMIT) !== secondsTextLat || this.secondsLon.toFixed(SECONDS_DECIMAL_LIMIT) !== secondsTextLon || this.degreesNorth !== this.degreesNorthOriginal || this.degreesEast !== this.degreesEastOriginal
      }
    },

    showResetButton () {
      if (this.latLon) {
        const latitudeText = getFormattedCoord(this.latitudeTextField)
        const longitudeText = getFormattedCoord(this.longitudeTextField)
        return parseFloat(this.latitude) !== latitudeText || parseFloat(this.longitude) !== longitudeText
      } else {
        const degreeTextLat = getFormattedCoord(this.degreesTextFieldLat)
        const degreeTextLon = getFormattedCoord(this.degreesTextFieldLon)
        const minutesTextLat = getFormattedCoord(this.minutesTextFieldLat)
        const minutesTextLon = getFormattedCoord(this.minutesTextFieldLon)
        const secondsTextLat = parseFloat(this.secondsTextFieldLat).toFixed(SECONDS_DECIMAL_LIMIT)
        const secondsTextLon = parseFloat(this.secondsTextFieldLon).toFixed(SECONDS_DECIMAL_LIMIT)
        return parseFloat(this.degreesLat) !== degreeTextLat || parseFloat(this.degreesLon) !== degreeTextLon || parseFloat(this.minutesLat) !== minutesTextLat || parseFloat(this.minutesLon) !== minutesTextLon ||
        this.secondsLat.toFixed(SECONDS_DECIMAL_LIMIT) !== secondsTextLat || this.secondsLon.toFixed(SECONDS_DECIMAL_LIMIT) !== secondsTextLon || this.degreesNorth !== this.degreesNorthOriginal || this.degreesEast !== this.degreesEastOriginal
      }
    },

    shapeFileSelectionLabel (defaultLabel) {
      return defaultLabel
    },

    scaleBarToggleTooltip () {
      return this.$t('changeToUnits', { units: this.$t(this.currentScaleBar === 'imperial' ? 'metric' : 'imperial') })
    }
  },

  watch: {
    spot: {
      handler (val) {
        this.latitude = val.latitude
        this.longitude = val.longitude
        this.latitudeTextField = getFormattedCoordWithTrailingZeroes(val.latitude)
        this.longitudeTextField = getFormattedCoordWithTrailingZeroes(val.longitude)

        this.latLonValuestoDMS()

        this.countrySubdivision = val.countrySubdivision
        this.countrySecondarySubdivision = val.countrySecondarySubdivision

        if (this.marker && this.map) {
          this.marker.setOptions({
            position: [val.longitude, val.latitude]
          })
        }
      },
      immediate: true
    },

    shapeFile: {
      handler (val) {
        if (val === null) this.boundarySource.clear()
      }
    },

    markerCircle (radius) {
      if (this.circleShape) {
        this.circleShape.setProperties({
          ...this.circleShape.getProperties(),
          radius
        })
      }
    }
  },

  mounted () {
    this.$nextTick(_ => {
      this.setupShapefile()
      this.initMap()
      this.resizeObervser = new ResizeObserver(this.onResize).observe(document.getElementById('tract-location-column'))
    })
  },

  async beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    if (this.resizeObserver) await this.resizeObserver.unobserve()
    await this.map.markers.clear()
    if (this.mapLayers !== null) {
      await this.mapLayers.forEach(async layer => {
        await this.map?.layers.remove(layer.getId())
      })
    }
    this.map = null
  },

  methods: {
    ...mapActions('tract', ['getLocationWithCoordinate', 'getLocationWithCompanyAddress']),

    async setupShapefile () {
      try {
        this.importedShapeFile = await shapefileClient.getShapefile(this.propTract.tractId)
      } catch (e) {}
    },

    latLonValuestoDMS () {
      const latConversion = this.convertToDMS(this.latitude)
      this.degreesLat = latConversion.degrees
      this.minutesLat = latConversion.minutes
      this.secondsLat = latConversion.seconds

      this.degreesNorth = (this.latitude >= 0)
      this.degreesNorthOriginal = (this.latitude >= 0)

      this.degreesTextFieldLat = parseInt(this.degreesLat)
      this.minutesTextFieldLat = parseInt(this.minutesLat)
      this.secondsTextFieldLat = this.secondsLat.toFixed(SECONDS_DECIMAL_LIMIT)

      const lonConversion = this.convertToDMS(this.longitude)
      this.degreesLon = lonConversion.degrees
      this.minutesLon = lonConversion.minutes
      this.secondsLon = lonConversion.seconds

      this.degreesEast = (this.longitude >= 0)
      this.degreesEastOriginal = (this.longitude >= 0)

      this.degreesTextFieldLon = parseInt(this.degreesLon)
      this.minutesTextFieldLon = parseInt(this.minutesLon)
      this.secondsTextFieldLon = this.secondsLon.toFixed(SECONDS_DECIMAL_LIMIT)
    },

    convertToDMS (value) {
      const wholeVal = Math.abs(value)
      const degrees = Math.floor(wholeVal)
      const minutesNotFixed = (wholeVal - degrees) * 60
      const minutes = Math.floor(minutesNotFixed)
      const seconds = (minutesNotFixed - minutes) * 60

      return {
        degrees: degrees,
        minutes: minutes,
        seconds: seconds
      }
    },

    convertToLatLon (val) {
      const degrees = val.degrees
      const minutes = val.minutes
      const seconds = val.seconds
      const postive = val.northEast

      return postive ? degrees + (minutes / 60) + (seconds / 3600) : -1 * (degrees + (minutes / 60) + (seconds / 3600))
    },

    toggleInputType () {
      this.latLon = !this.latLon
    },

    toggleNorthSouth () {
      this.degreesNorth = !this.degreesNorth
    },

    toggleEastWest () {
      this.degreesEast = !this.degreesEast
    },

    async confirmDeleteShapefile () {
      this.shapeFile = null
      this.boundarySource.clear()
      this.deleteShapeFileConfirmation = false
      await shapefileClient.deleteFile(this.propTract.tractId, this.importedShapeFile)
      this.importedShapeFile = null
    },

    cancel () {
      this.deleteShapeFileConfirmation = false
    },

    async importShapefileBtnClicked () {
      const maxSize = 10 * 1024 // 15 KB
      if (this.shapeFile.size > maxSize) {
        this.shapeFile = null
        this.shapeFileAsArrayBuffer = null
        this.setSnackError(this.$t('uploadedShapefileTooLarge'))
        return
      }

      this.shapeFileAsArrayBuffer = await this.getShapeFileAsArrayBuffer(this.shapeFile)
      const geojson = await this.getGeojson(this.shapeFileAsArrayBuffer)
      if (!geojson) {
        this.shapeFile = null
        this.shapeFileAsArrayBuffer = null
        this.setSnackError(this.$t('uploadedShapefileCannotDecode'))
        return
      }

      this.applyLocationFromShapefile(geojson)

      this.map.setCamera({
        bounds: atlas.data.BoundingBox.fromData(geojson),
        padding: 50
      })

      const json = JSON.stringify(geojson, undefined, 2)
      const geojsonFile = new File([json], 'geojson.json', { type: 'text/plain' })

      if (this.isEditing) {
        try {
          await shapefileClient.uploadShapefile(this.propTract.tractId, this.shapeFile)
          this.importedShapeFile = await shapefileClient.getShapefile(this.propTract.tractId)
          await shapefileClient.uploadGeojson(this.propTract.tractId, geojsonFile)
        } catch (e) {
          return
        }
      } else {
        this.$emit('shapefile', this.shapeFile)
        this.$emit('geojsonfile', geojsonFile)
      }

      this.handleBoundary()
    },

    async downloadBtnClicked () {
      window.open(this.importedShapeFile)
    },

    async deleteShapefileBtnClicked () {
      this.deleteShapeFileConfirmation = true
    },

    async getShapeFileAsArrayBuffer (shapefile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(shapefile)
      })
    },

    applyLocation () {
      if (this.latLon && this.rules.validLongitude(this.longitude) && this.rules.validLatitude(this.latitude)) {
        const coordinate = {
          lat: getFormattedCoord(this.latitudeTextField),
          lng: getFormattedCoord(this.longitudeTextField)
        }
        this.getLocationCounty(coordinate)
      } else if (!this.latLon) {
        const DMSObjectLat = {
          degrees: parseFloat(this.degreesTextFieldLat),
          minutes: parseFloat(this.minutesTextFieldLat),
          seconds: parseFloat(this.secondsTextFieldLat),
          northEast: this.degreesNorth
        }
        const DMSObjectLon = {
          degrees: parseFloat(this.degreesTextFieldLon),
          minutes: parseFloat(this.minutesTextFieldLon),
          seconds: parseFloat(this.secondsTextFieldLon),
          northEast: this.degreesEast
        }
        const coordinate = {
          lat: getFormattedCoord(this.convertToLatLon(DMSObjectLat)),
          lng: getFormattedCoord(this.convertToLatLon(DMSObjectLon))
        }
        this.getLocationCounty(coordinate)
      }
    },

    applyLocationFromShapefile (geojson) {
      this.latitude = ((atlas.data.BoundingBox.fromData(geojson)[1] + atlas.data.BoundingBox.fromData(geojson)[3]) / 2).toFixed(6)
      this.longitude = ((atlas.data.BoundingBox.fromData(geojson)[0] + atlas.data.BoundingBox.fromData(geojson)[2]) / 2).toFixed(6)
      if (this.marker && this.map) {
        this.marker.setOptions({
          position: [this.longitude, this.latitude]
        })
      }
      if (this.rules.validLongitude(this.longitude) && this.rules.validLatitude(this.latitude)) {
        const coordinate = {
          lat: parseFloat(this.latitude),
          lng: parseFloat(this.longitude)
        }
        this.getLocationCounty(coordinate)
      }
    },

    resetLocation () {
      this.degreesNorth = this.degreesNorthOriginal
      this.degreesEast = this.degreesEastOriginal

      this.$emit('dragged', {
        latitude: parseFloat(this.latitude.toFixed(6)),
        longitude: parseFloat(this.longitude.toFixed(6)),
        countrySubdivision: this.countrySubdivision,
        countrySecondarySubdivision: this.countrySecondarySubdivision
      })
    },

    initMap () {
      const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
      const mapOptions = {
        center: [this.longitude, this.latitude],
        zoom: 14,
        view: 'Auto',
        autoResize: true,
        style: 'satellite_road_labels',
        authOptions: {
          authType: 'subscriptionKey',
          subscriptionKey: apiKey
        }
      }
      this.map = new atlas.Map('tract-location-map', mapOptions)

      this.map.events.add('ready', () => {
        this.showMap = true
        this.addMarker()
        this.boundarySource = new atlas.source.DataSource()
        this.map.sources.add(this.boundarySource)
        this.handleBoundary()
        // this.addDestinations()
      })
      if (!this.scaleBarRendered && document.getElementById('atlasMapControlImport')) {
        this.scaleBarRendered = true
        import('@/utils/azure-maps-scale-bar-control.js').then(
          async sb => {
            this.scaleBarImport = sb
            await this.toggleScaleBar(true)
          })
      }
      this.onResize()
    },

    async handleBoundary () {
      const shapefile = await this.getShapefileForBoundary()
      if (!shapefile) return
      const geojson = await this.getGeojson(shapefile)
      if (!geojson) return
      this.renderGeojsonAsBoundary(geojson)
    },

    async getGeojson (shapefile) {
      try {
        return await shp(shapefile)
      } catch (e) {
        return null
      }
    },

    async getShapefileForBoundary () {
      // If uploaded shapefile - draw uploaded shapefile
      if (this.shapeFileAsArrayBuffer) {
        return this.shapeFileAsArrayBuffer
      // Otherwise draw existing shapefile
      } else if (this.isEditing) {
        try {
          return await shapefileClient.getShapefile(this.propTract.tractId)
        } catch (e) {
          return null
        }
      // Otherwise draw nothing
      } else {
        return null
      }
    },

    renderGeojsonAsBoundary (geojson) {
      this.boundarySource.clear()
      this.boundarySource.setShapes(geojson)
      if (this.mapLayers !== null) {
        this.mapLayers.forEach(layer => {
          this.map.layers.remove(layer.getId())
        })
      }
      this.mapLayers = [
        new atlas.layer.PolygonLayer(this.boundarySource, null, {
          strokeWidth: 2,
          filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
        }),
        new atlas.layer.LineLayer(this.boundarySource, null, {
          strokeColor: 'white',
          strokeWidth: 2,
          filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
        })
      ]
      this.map.layers.add(this.mapLayers, 'labels')
    },

    countyChosen () {
      const index = counties[this.countrySubdivision].findIndex(c => c.N === this.countrySecondarySubdivision)
      const county = counties[this.countrySubdivision][index]
      const coords = county.C.split(',')
      const coordinate = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      }

      this.emitLocationAndDragCamera(coordinate, this.countrySubdivision, county.N)
    },

    stateChanged () {
      this.countrySecondarySubdivision = this.selectedCounties[0]
      this.countyChosen()
    },

    setCamera (coordinate) {
      this.map.setCamera({
        center: coordinate,
        type: 'ease'
      })
    },

    // async addDestinations () {
    //   this.locations.forEach(location => {
    //     this.getLocationWithCompanyAddress(location.address).then(spot => {
    //       this.map.markers.add(new atlas.HtmlMarker({
    //         position: [spot.longitude, spot.latitude],
    //         htmlContent: '<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" /></svg>'
    //       }))
    //     })
    //   })
    // },

    addMarker () {
      const position = [this.longitude, this.latitude]

      this.marker = new atlas.HtmlMarker({
        draggable: true,
        position: position,
        pixelOffset: [0, 0],
        color: '#D15F27'
      })

      this.map.events.add('dragend', this.marker, (e) => {
        const coordinate = (e.target.marker._lngLat)
        this.getLocationCounty(coordinate)
      })

      this.map.markers.add(this.marker)

      if (this.markerCircle) {
        const dataSource = new atlas.source.DataSource()
        this.map.sources.add(dataSource)
        const point = new atlas.data.Point(position)
        const shape = new atlas.Shape(point, null, {
          subType: 'Circle',
          radius: this.markerCircle
        })
        dataSource.add(shape)
        this.circleShape = shape
        this.map.events.add('drag', this.marker, e => {
          shape.setCoordinates(atlas.data.Position.fromLatLng(e.target.marker._lngLat))
        })
        this.map.layers.add(new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: '#d15f27',
          strokeDashArray: [4, 4]
        }))
        this.map.layers.add(new atlas.layer.PolygonLayer(dataSource, null, {
          fillColor: '#d15f27',
          fillOpacity: 0.25,
          fillPattern: 'fill-diagonal-stripes-up-red'
        }))
      }
    },

    async getLocationCounty (coordinate) {
      try {
        const { countrySubdivision, countrySecondarySubdivision } = await this.getLocationWithCoordinate({
          latitude: coordinate.lat,
          longitude: coordinate.lng
        })
        this.countrySubdivision = countrySubdivision
        this.countrySecondarySubdivision = countrySecondarySubdivision
        this.emitLocationAndDragCamera(coordinate, countrySubdivision, countrySecondarySubdivision)
      } catch (error) {
        this.setSnackError(this.$t('errorGettingLocationStateAndCounty'))
      }
    },

    emitLocationAndDragCamera (coordinate, state, county) {
      this.$emit('dragged', {
        latitude: parseFloat(coordinate.lat.toFixed(6)),
        longitude: parseFloat(coordinate.lng.toFixed(6)),
        countrySubdivision: state,
        countrySecondarySubdivision: county
      })
      if (this.circleShape !== undefined) {
        const p = atlas.data.Position.fromLatLng([coordinate.lng, coordinate.lat])
        this.circleShape.setCoordinates(p)
      }

      this.map.setCamera({
        center: [coordinate.lng, coordinate.lat],
        type: 'ease',
        duration: 350
      })
    },

    async toggleScaleBar (firstRender = false) {
      if (firstRender) {
        this.currentScaleBar = this.scaleBarOptions[parseInt(localStorage.getItem(LocalStorageKeys.TRACT_MAP_SCALE_BAR_TYPE))] ?? this.currentScaleBar
      } else {
        const options = this.scaleBarOptions
        this.currentScaleBar = options[(options.indexOf(this.currentScaleBar) + 1) % options.length]
        localStorage.setItem(LocalStorageKeys.TRACT_MAP_SCALE_BAR_TYPE, options.indexOf(this.currentScaleBar))
        if (this.scaleBarControl) {
          this.map.controls.remove(this.scaleBarControl)
          this.scaleBarControl = undefined
        }
      }
      if (this.currentScaleBar) {
        this.scaleBarControl = new this.scaleBarImport.atlas.control.ScaleBarControl({ maxBarLength: 120, units: this.currentScaleBar })
        this.map.controls.add(this.scaleBarControl)
      }
    },

    onResize () {
      this.mapStyle = {
        width: '100%',
        height: `${document.getElementById('tract-form')?.offsetHeight - 420}px` || '42vh',
        maxHeight: '75vw'
      }
      if (this.map) {
        setTimeout(() => this.map.map.resize(), mapResizeTimeout)
      }
    },

    onTextFieldBlur () {
      if (this.latLon && (this.rules.validLatitudeForTextBox(this.latitudeTextField) || Math.abs(parseFloat(this.latitudeTextField)) <= DEGREE_LAT_MAX)) {
        this.latitudeTextField = getFormattedCoordWithTrailingZeroes(this.latitudeTextField)
      }
      if (this.latLon && (this.rules.validLongitudeForTextBox(this.longitudeTextField) || Math.abs(parseFloat(this.longitudeTextField)) <= DEGREE_LON_MAX)) {
        this.longitudeTextField = getFormattedCoordWithTrailingZeroes(this.longitudeTextField)
      }
      if (!this.latLon && (this.rules.validDegreesNorthSouthForTextBox(this.degreesTextFieldLat) || (Math.abs(parseFloat(this.degreesTextFieldLat)) <= DEGREE_LAT_MAX && parseFloat(this.degreesTextFieldLat) >= 0))) {
        this.degreesTextFieldLat = parseInt(Math.floor(this.degreesTextFieldLat))
      }
      if (!this.latLon && (this.rules.validDegreesEastWestForTextBox(this.degreesTextFieldLon) || (Math.abs(parseFloat(this.degreesTextFieldLon)) <= DEGREE_LON_MAX && parseFloat(this.degreesTextFieldLon) >= 0))) {
        this.degreesTextFieldLon = parseInt(Math.floor(this.degreesTextFieldLon))
      }
      if (!this.latLon && (this.rules.validMinutesForTextBox(this.minutesTextFieldLat) || (Math.abs(parseFloat(this.minutesTextFieldLat)) <= TIME_MAXIMUM && parseFloat(this.minutesTextFieldLat) >= 0))) {
        this.minutesTextFieldLat = parseInt(Math.floor(this.minutesTextFieldLat))
      }
      if (!this.latLon && (this.rules.validMinutesForTextBox(this.minutesTextFieldLon) || (Math.abs(parseFloat(this.minutesTextFieldLon)) <= TIME_MAXIMUM && parseFloat(this.minutesTextFieldLon) >= 0))) {
        this.minutesTextFieldLon = parseInt(Math.floor(this.minutesTextFieldLon))
      }
      if (!this.latLon && (this.rules.validSecondsForTextBox(this.secondsTextFieldLat) || (Math.abs(parseFloat(this.secondsTextFieldLat)) <= TIME_MAXIMUM && parseFloat(this.secondsTextFieldLat) >= 0))) {
        this.secondsTextFieldLat = parseFloat(this.secondsTextFieldLat).toFixed(SECONDS_DECIMAL_LIMIT)
      }
      if (!this.latLon && (this.rules.validSecondsForTextBox(this.secondsTextFieldLon) || (Math.abs(parseFloat(this.secondsTextFieldLon)) <= TIME_MAXIMUM && parseFloat(this.secondsTextFieldLon) >= 0))) {
        this.secondsTextFieldLon = parseFloat(this.secondsTextFieldLon).toFixed(SECONDS_DECIMAL_LIMIT)
      }
    }
  }
}
</script>

<style scoped src="@/styles/map-scoped.css"/>
