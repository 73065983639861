<template>
   <v-container fluid class="pb-5" id="inventorySummaryDeckSelector">
      <v-row justify="end">
        <DeckSelector
        v-if="!locationsLoading"
        isDashboard
        @deck-selected="deckSelected"
        @location-selected="locationSelected"
        :contentType="deckContentType"
        />
      </v-row>
      <GridContainer
      class="pb-4 mt-n8">
        <GridArea :area="chartArea" :id="inventoryWeightId">
          <LineChartCard
          valueKey="weights"
          yFormatType="TONS"
          :data.sync="inventoryChartData"
          elementId="inventory-weight-chart-gridded"
          :title="$t('inventoryWeightInTons')"
          :tooltip="inventoryUpdatedTooltip">
            <template #actions>
              <Icon
              :disabled="inventoryChartData.length === 0"
              icon="mdi-file-delimited-outline"
              iconColor="white"
              :small="false"
              :tooltipText="$t('downloadInventorySummary')"
              @icon-clicked="openInventoryCsvDownload"/>
            </template>
          </LineChartCard>
        </GridArea>
        <GridArea :area="chartArea" :id="inventoryValueId">
          <LineChartCard
          :data.sync="inventoryChartData"
          yFormatType="USD"
          valueKey="values"
          elementId="inventory-value-chart-gridded"
          :title="$t('inventoryValueInDollars')"
          :tooltip="inventoryUpdatedTooltip">
            <template #actions>
              <Icon
              :disabled="inventoryChartData.length === 0"
              icon="mdi-file-delimited-outline"
              iconColor="white"
              :small="false"
              :tooltipText="$t('downloadInventorySummary')"
              @icon-clicked="openInventoryCsvDownload"/>
            </template>
          </LineChartCard>
        </GridArea>
        <GridArea :area="[2, { lg: 12, xl: 6 }]" :id="timeInYardId">
          <TimeInYardCard
            color="black"
            icon="mdi-clock-time-four-outline"
            sub-icon="mdi-update"
            :xValues="filteredYardTimes.dates"
            :yValues="filteredYardTimes.times"
            :loadCounts="filteredYardTimes.loadCounts"
            elementId="yard-time-chart-gridded"
            :loading="yardChartsLoading"
            :title="$t('avgTimeInYard')"
            :csvFilename="yardTimeFilename"
          />
        </GridArea>
        <GridArea :area="cardArea">
          <material-stats-card
            class="fill-height"
            color="success"
            icon="mdi-currency-usd"
            :title="$t('inventoryValue')"
            :value="deckStats.value"
            sub-icon="mdi-update"
            sub-text=""
          />
        </GridArea>
        <GridArea :area="cardArea">
          <v-card
          class="fill-height"
          >
            <v-card-text>
              <v-card color="black" class="float-left">
                <v-icon color="white" style="padding: 15px; font-size: 40px;">
                  mdi-weight
                </v-icon>
              </v-card>
              <div class="text-right">
                <p class="grey--text text-right category">
                  {{ $t('currentInventoryUnits', { units: deckStats.consumptionMode === ConsumptionMode.WeightBased.value ? ` (${$t('tons')})` : '' }) }}
                </p>
                <h3 class="subtitle pa-0 ma-0">
                  {{ deckStats.weight + `${deckStats.consumptionMode === ConsumptionMode.WeightBased.value ? '' : ` ${$t('tons').toLowerCase()}`}` }}
                </h3>
                <h3 class="subtitle pa-0 ma-0" v-if="deckStats.consumptionMode !== ConsumptionMode.WeightBased.value && deckHistory" style="display:inline;">
                  {{ `${loadsOnDeck} ${loadsOnDeck === 1 ? ` ${$t('load').toLowerCase()}` : ` ${$t('loads').toLowerCase()}`}` }}
                </h3>
                <Icon
                v-if="deckStats.consumptionMode === -1"
                margin="mt-n2"
                icon="mdi-information-outline"
                iconColor="grey"
                tooltipColor="grey"
                :tooltipText="$t('loadCountOnlyIncludesDataFromTicketBasedDecks')"
                :small="false"
                />
              </div>
            </v-card-text>
          </v-card>
        </GridArea>
      </GridContainer>
      <Dialog :stateId="dialogId">
        <InventoryCsvDownload
        :selectedLocation="selectedLocation"
        :selectedDeck="selectedDeck"
        :contentType="deckContentType"
        @close="resetDialogs"/>
      </Dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import { mapActions, mapGetters } from 'vuex'
import { uniqueDialogId } from '../../utils/componentHelpers'
import { DeckContentType, ConsumptionMode } from '../../utils/Enumerations'
export default {
  name: 'InventorySummary',
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    InventoryCsvDownload: () => import('./InventoryCsvDownload.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    GridArea: () => import('@/components/helper/GridArea.vue'),
    GridContainer: () => import('@/components/helper/GridContainer.vue'),
    DeckSelector: () => import('@/components/dashboard/DeckSelector.vue'),
    TimeInYardCard: () => import('@/components/dashboard/TimeInYardCard.vue'),
    LineChartCard: () => import('@/components/dashboard/LineChartCard.vue')
  },

  props: {
    isByproducts: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    ConsumptionMode,
    yardTimes: [],
    dialogId: uniqueDialogId('dashboard-inventory-summary'),
    decksLoading: true,
    yardChartsLoading: true,
    selectedLocation: undefined,
    selectedDeck: undefined,
    deckHistory: undefined,
    inventoryChartData: [],
    filteredYardTimes: {
      dates: [],
      times: [],
      loadCounts: []
    },
    cardArea: [1, { sm: 12, md: 6 }],
    chartArea: [4, { md: 12, lg: 12, xl: 6 }],
    DeckContentType
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('locations', ['locationsLoading']),

    deckArray () {
      return (this.selectedDeck?.deckId === -1 ? this.selectedDeck.allDecks : (this.selectedDeck !== undefined ? [this.selectedDeck] : []))
    },

    inventoryLastRecordedOn () {
      if (!this.deckHistory || !this.deckHistory[0]?.history) return undefined
      const deckHistory = this.deckHistory[0]?.history
      const idx = (deckHistory.length > 2 && moment(deckHistory[0].recordedOn).isBefore(moment(deckHistory[1].recordedOn)))
        ? deckHistory.length - 1
        : 0
      return moment.utc(deckHistory[idx]?.recordedOn).local()
    },

    inventoryUpdatedTooltip () {
      return this.inventoryLastRecordedOn ? `${this.$t('lastUpdated')}: ${this.inventoryLastRecordedOn.local().format('MMM Do YYYY h:mm a')}` : ''
    },

    deckStats () {
      if (this.selectedDeck === undefined) { return [] }
      const deckArray = this.selectedDeck.deckId === -1 ? this.selectedDeck.allDecks : [this.selectedDeck]
      return {
        weight: tonStringFromPounds(deckArray.reduce((a, b) => a + b.onDeck, 0)),
        value: formatMoney(deckArray.reduce((a, b) => a + b.value, 0)),
        consumptionMode: this.getConsumptionMode(deckArray)
      }
    },

    yardTimeFilename () {
      if (this.selectedLocation === undefined) { return '' }
      if (this.selectedLocation.locationId === -1) { return '' }

      return `TimeInYard-${this.selectedLocation.name || ''}-${moment().format()}.csv`.replace(/\s+/g, '')
    },

    loadsOnDeck () {
      return this.selectedDeck.deckId === -1
        ? this.deckHistory.reduce((a, b) => this.selectedDeck.allDecks.map(d => d.deckId).includes(b.deckId) ? a + b?.loadsOnDeck ?? 0 : a, 0)
        : this.deckHistory.find(d => d.deckId === this.selectedDeck.deckId)?.loadsOnDeck ?? 0
    },

    deckContentType () {
      return this.isByproducts ? DeckContentType.Byproducts.value : DeckContentType.Logs.value
    },

    inventoryWeightId () {
      return `${'inventoryWeightChart'}${this.isByproducts ? 'Bp' : ''}`
    },

    inventoryValueId () {
      return `${'inventoryValueChart'}${this.isByproducts ? 'Bp' : ''}`
    },

    timeInYardId () {
      return `${'timeInYardChart'}${this.isByproducts ? 'Bp' : ''}`
    }
  },

  methods: {
    ...mapActions('dashboard', ['getHarvestedByRegion', 'getYardTimes']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user', ['fetchCompanyInfo']),
    ...mapActions('decks', ['fetchHistory']),

    async deckSelected (deck) {
      this.selectedDeck = deck
      this.deckHistory = await this.fetchHistory({ locationId: deck?.locationId, deckId: deck?.deckId })
      this.setInventoryChartData()
    },
    async locationSelected (location) {
      this.selectedLocation = location
      this.yardChartsLoading = true
      try {
        this.yardTimes = await this.getYardTimes({ locationId: location.locationId === -1 ? undefined : location.locationId, contentType: this.deckContentType })
        this.setFilteredYardTimes()
      } finally {
        this.yardChartsLoading = false
      }
    },

    setFilteredYardTimes () {
      this.filteredYardTimes = { dates: [], times: [], loadCounts: [] }
      if (this.yardTimes === undefined) { return }

      this.yardTimes.forEach(({ date, timeInYardSeconds, loadCount }) => {
        if (new Date(date) >= new Date().setDate(new Date().getDate() - 13)) {
          this.filteredYardTimes.dates.push(date)
          this.filteredYardTimes.times.push(timeInYardSeconds)
          this.filteredYardTimes.loadCounts.push(loadCount)
        }
      })
    },

    openInventoryCsvDownload () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '500px' })
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    getConsumptionMode (deckArray) {
      const consumptionModes = deckArray.map(d => d.consumptionMode)
      if (consumptionModes.every(i => i === ConsumptionMode.TicketBased.value)) {
        return ConsumptionMode.TicketBased.value
      }
      if (consumptionModes.every(i => i === ConsumptionMode.WeightBased.value)) {
        return ConsumptionMode.WeightBased.value
      }
      return -1
    },

    setInventoryChartData () {
      this.inventoryChartData = this.selectedLocation && (this.selectedLocation.locationId !== -1 || this.selectedDeck.deckId !== -1)
        ? this.deckArray.map((deck) => {
          const deckHistory = this.deckHistory.find(dh => dh.deckId === deck.deckId).history
          return { // Show individual decks because there is a selected location
            name: deck.name,
            locationName: deck.locationName,
            dates: deckHistory.map(h => moment.utc(h.recordedOn).add(-1, 'days').format('MM/DD/YYYY')),
            weights: deckHistory.map(h => parseFloat((h.onDeck / 2000).toFixed(3))),
            values: deckHistory.map(h => h.value)
          }
        })
        : this.deckArray.reduce((acc, deck) => { // Aggregate deck information by location when viewing all locations
          const location = acc.find(i => i.name === deck.locationName)
          const deckHistory = this.deckHistory.find(dh => dh.deckId === deck.deckId).history
          if (location) {
            location.weights = location.weights.map((w, idx) => parseFloat((w + deckHistory[idx].onDeck / 2000).toFixed(3)))
            location.values = location.values.map((v, idx) => v + deckHistory[idx].value)
          } else {
            acc.push({
              name: deck.locationName,
              dates: deckHistory.map(h => moment.utc(h.recordedOn).add(-1, 'days').format('MM/DD/YYYY')),
              weights: deckHistory.map(h => parseFloat((h.onDeck / 2000).toFixed(3))),
              values: deckHistory.map(h => h.value)
            })
          }
          return acc
        }, [])
    }
  }
}
</script>
