<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <CorrectionUser
        :correction="correction.correction"
        :type="correctionTypeFromInt(correction.correction.type)"
        :createdAt="correction.correction.createdAt"/>
      </v-col>
      <NewTicketValues :correction="correction" v-if="correction.correction.type === 0" v-bind="{ contractMode }"/>
      <v-col cols="12">
        <v-tabs
        v-model="currentTab"
        background-color="transparent"
        color="#888888"
        :slider-color="contractTypeColor"
        slider-size="4">
          <v-tab active-class="active-tab" v-for="(type, tabIndex) in correctionTypes" :key="tabIndex">
            {{ type.title }}
          </v-tab>
          <v-tabs-items v-model="currentTab" touchless>
            <v-tab-item v-for="(type, itemIndex) in correctionTypes" :key="itemIndex">
              <v-expansion-panels v-model="openPanels" multiple flat accordion tile class="mt-3">
                <v-expansion-panel :key="`${itemIndex}-corrected`">
                  <v-expansion-panel-header ripple :color="contractTypeColor">
                    {{ $t('corrected') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :style="borderStyle">
                    <v-data-table
                    class="mt-3"
                    dense
                    :items="type.corrected"
                    :headers="getHeadersForTitle(type.title)"
                    :hide-default-footer="type.corrected.length < 10">
                    <template v-for="header in getHeadersForTitle(type.title).filter(h => h.format)" #[`item.${header.value}`]="{ item }">
                      <span :key="`fmt-header-${header.value}`">{{header.format(item[header.value])}}</span>
                    </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :key="`${itemIndex}-new`" v-if="correction.correction.type !== 2">
                  <v-expansion-panel-header ripple :color="contractTypeColor">
                    {{ $t('new') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :style="borderStyle">
                    <v-data-table
                      class="mt-3"
                      :items="type.new"
                      :headers="getHeadersForTitle(type.title)"
                      :hide-default-footer="type.new.length < 10"
                      dense>
                      <template v-for="header in getHeadersForTitle(type.title).filter(h => h.format)" #[`item.${header.value}`]="{ item }">
                        <span :key="`fmt-header-${header.value}`">{{header.format(item[header.value])}}</span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { headers } from '@/headers/CorrectionHeaders.js'
import { CorrectionType } from '@/utils/Enumerations.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { colorClassForContractType, panelBorderForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'CorrectionDetail',

  components: {
    CorrectionUser: () => import('@/components/ticket/corrections/CorrectionUser.vue'),
    NewTicketValues: () => import('@/components/ticket/corrections/NewTicketValues.vue')
  },

  props: {
    correction: {
      type: Object,
      required: true
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  computed: {
    correctionTypes () {
      const types = []
      const newPayables = this.correction.newPayables || []
      const correctedPayables = this.correction.correctedPayables || []
      if (newPayables.length > 0 || correctedPayables.length > 0) {
        types.push({
          corrected: correctedPayables,
          new: newPayables,
          title: this.$t('payables')
        })
      }
      const newReceivables = this.correction.newReceivables || []
      const correctedReceivables = this.correction.correctedReceivables || []
      if (newReceivables.length > 0 || correctedReceivables.length > 0) {
        types.push({
          corrected: correctedReceivables,
          new: newReceivables,
          title: this.$t('receivables')
        })
      }
      const newJournalEntries = this.correction.newJournalEntries || []
      const correctedJournalEntries = this.correction.correctedJournalEntries || []
      if (newJournalEntries.length > 0 || correctedJournalEntries.length > 0) {
        types.push({
          corrected: correctedJournalEntries,
          new: newJournalEntries,
          title: this.$t('accruals')
        })
      }
      return types
    },

    ticket () {
      return this.correction.correction.ticket
    },

    contractTypeColor () {
      return colorClassForContractType(this.ticket.contractType)
    },

    borderStyle () {
      return panelBorderForContractType(this.ticket.contractType)
    }
  },

  data: () => ({
    currentTab: 0,
    openPanels: [0, 1]
  }),

  methods: {
    correctionTypeFromInt: (x) => CorrectionType.fromInt(x),
    formatMoney,
    getHeadersForTitle (title) {
      return headers({ includeGross: title === this.$t('payables') })
    }
  }
}
</script>
