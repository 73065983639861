<template>
    <v-card>
        <v-card-title :style="headerStyle">
            <span class="headline">Log Counts</span>
            <v-spacer/>
            <Icon
              icon="mdi-close"
              iconColor="tertiary"
              dataTestId="ticket-defect-edit-close-button"
              :small="false"
              :large="true"
              margin=""
              tooltipColor="secondary"
              :tooltipText="$t('close')"
              @icon-clicked="$emit('close')"
            />
        </v-card-title>
        <v-card-text>
          <canvas id="logCountsChart"></canvas>
        </v-card-text>
    </v-card>
</template>

<script>
import { Chart } from 'chart.js'
import { mapActions } from 'vuex'

export default {
  name: 'LogCountChart',
  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    ticketId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    loading: false,
    logCounts: [],
    logCountsChart: null
  }),

  computed: {
    headerStyle () {
      return 'background-color: #d15f27; color: white;'
    }
  },

  created () {
    this.createLogCountChart()
  },

  methods: {
    ...mapActions('ticket', ['fetchTicketLogCounts']),

    async fetchLogCounts () {
      this.logCounts = await this.fetchTicketLogCounts(this.ticketId)
    },

    async createLogCountChart () {
      this.loading = true
      await this.fetchLogCounts()
      const chartContent = this.logCounts

      var labels = []
      var counts = []

      for (const length in chartContent) {
        labels.push(`${chartContent[length].length}'`)
        counts.push(chartContent[length].count)
      }

      const chartElement = document.getElementById('logCountsChart')
      const data = {
        labels: labels,
        datasets: [{
          label: 'Number of Logs Per Length',
          data: counts,
          backgroundColor: '#d15f27'
        }]
      }
      const config = {
        type: 'bar',
        data: data,
        options: {
          indexAxis: 'y',
          scales: {
            x: {
              ticks: {
                stepSize: 1
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'right'
          },
          title: {
            display: true,
            text: 'Number of Logs Per Length'
          }
        }
      }

      this.logCountsChart = new Chart(chartElement, config)
      this.loading = false
    }
  }

}
</script>
