<template>
  <v-card data-testid="custom-date-range-form">
    <v-card-title class="secondary white--text">
      <span class="headline">{{ $t('printTickets') }}</span>
      <v-spacer/>
      <span>
        <Icon
          icon="mdi-calendar-arrow-left"
          iconColor="white"
          dataTestId="select-start"
          :large="true"
          :small="false"
          :tooltipText="$t('selectStartDate')"
          @icon-clicked="hasStartDate = !hasStartDate"
        />
      </span>
      <span class="mr-4">
        <Icon
          icon="mdi-calendar-arrow-right"
          iconColor="white"
          class="mr-4"
          dataTestId="select-end"
          :large="true"
          :small="false"
          :tooltipText="$t('selectEndDate')"
          @icon-clicked="hasUntilDate = !hasUntilDate"
        />
      </span>
      <Icon
        icon="mdi-close"
        iconColor="white"
        dataTestId="ticket-date-close"
        :large="true"
        :small="false"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="CustomTicketDateRangeForm"
        testId="save-custom-ticket-date-range-form"
        :lazyValidation="true"
        :disabled="isButtonDisabled"
        :buttonText="$t('printTickets')"
        @submit="setDateButtonPressed"
      >
        <v-container>
          <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <DatePicker
                dataTestId="tract-ticket-start"
                :dateLabel="$t('startDate')"
                :allowFuture="false"
                :disabled="!hasStartDate"
                @valid-date="(val) => {isValidStartDate = val}"
                @date-picked="startDateChosen"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <DatePicker
                dataTestId="tract-ticket-end"
                :dateLabel="$t('endDate')"
                :allowFuture="false"
                :disabled="!hasUntilDate"
                @valid-date="(val) => {isValidEndDate = val}"
                @date-picked="endDateChosen"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import moment from 'moment'
import { localToUTC } from '@/utils/DateFormatter.js'

export default {
  name: 'CustomTicketDateRangeForm',

  components: {
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    isValidStartDate: true,
    isValidEndDate: true,
    chosenSinceDate: '',
    chosenUntilDate: '',
    hasStartDate: false,
    hasUntilDate: false
  }),

  computed: {
    isButtonDisabled () {
      return !(this.isValidStartDate && this.isValidEndDate)
    },

    dateRangeObj () {
      return {
        sinceTime: this.chosenSinceDate ? moment(this.chosenSinceDate).format() : moment(this.sinceTime).format(),
        untilTime: moment(this.chosenUntilDate).format()
      }
    }
  },

  methods: {
    startDateChosen (date) {
      this.chosenSinceDate = date
    },

    endDateChosen (date) {
      this.chosenUntilDate = date
    },

    setDateButtonPressed () {
      const differenceInDays = moment(this.chosenUntilDate).diff(moment(this.chosenSinceDate), 'days')

      if (differenceInDays < 0) {
        this.setSnackError(this.$t('startDateBeforeEndDate'))
        return
      }

      const untilTime = moment(this.chosenUntilDate).set({ h: 23, m: 59, s: 59 })

      const dateRange = {
        sinceTime: this.hasStartDate ? localToUTC(this.chosenSinceDate) : null,
        untilTime: this.hasUntilDate ? untilTime.toISOString() : null
      }
      this.$emit('dates-chosen', dateRange)
    }
  }
}
</script>
