<template>
  <v-card>
    <v-card-title class="secondary white--text">
      <span class="headline">
        {{title}}
      </span>
      <v-spacer>
      </v-spacer>
      <Icon
        dataTestId="close-template-detail-form"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        :large="true"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <v-container fluid class="mt-6">
        <v-row>
          <v-row wrap>
            <v-col cols="12" xs="12" sm="6">
              <ProductAutocomplete
                ref="firstField"
                data-testid="template-detail-product"
                :productId="selectedProduct ? selectedProduct.productId : undefined"
                :excludedProducts="existingDetailNames"
                @product-chosen="productChosen"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <MoneyTextField
                data-testid="template-detail-rate"
                :initialValue="templateDetail.rate"
                :label="$t('rate')"
                @val-changed="rateEntered"
                :extraRules="['validActivityRate']"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn class="secondary" :disabled="saveBtnDisabled" @click="saveChanges">
        {{$t('saveChanges')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { MAX_ACTIVITY_RATE } from '../../../utils/rules'
export default {
  name: 'ProductDetailForm',

  props: {
    propTemplateDetail: {
      type: Object
    },
    existingDetails: {
      type: Array
    }
  },

  components: {
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    ProductAutocomplete: () => import('@/components/autocomplete/ProductAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    templateDetail: {
      product: '',
      productId: undefined,
      rate: 0
    },
    selectedProduct: undefined
  }),

  computed: {
    editing () {
      return this.propTemplateDetail !== undefined
    },

    title () {
      return this.editing
        ? this.$t('updateTemplateDetail', { productName: this.templateDetail.product })
        : this.$t('createTemplateDetail')
    },

    saveBtnDisabled () {
      return this.templateDetail.rate <= 0 || this.selectedProduct === undefined
    },

    existingDetailNames () {
      return this.existingDetails.map(d => d.product)
    }
  },

  created () {
    if (this.editing) {
      this.templateDetail = JSON.parse(JSON.stringify(this.propTemplateDetail))
      this.selectedProduct = {
        productId: this.templateDetail.productId,
        name: this.templateDetail.product
      }
    }
  },

  methods: {
    productChosen (product) {
      this.selectedProduct = product
    },

    rateEntered (rate) {
      this.templateDetail.rate = rate
    },

    saveChanges () {
      if (this.templateDetail.rate <= MAX_ACTIVITY_RATE) {
        const requestBody = {
          productId: this.selectedProduct.productId,
          product: this.selectedProduct.name,
          rate: this.templateDetail.rate,
          activityTemplateDetailId: this.templateDetail.activityTemplateDetailId
        }

        this.$emit('template-detail-updated', requestBody)
      } else {
        this.setSnackError(this.$t('invalidActivityRate'))
      }
    }
  }
}
</script>
