<template>
  <v-list
    dense>
    <v-list-item
    v-for="(errorText, i) in errorStrings"
    :key="getListKey(i)">
      <v-list-item-content>
        <span
        :data-testid="getDataTestId(i)"
        class="error--text subtitle-1">
          {{errorText}}
        </span>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'ErrorList',

  props: {
    errorType: {
      type: String,
      required: true
    },
    errorStrings: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    getDataTestId (index) {
      return `${this.errorType}-error-${index}`
    },

    getListKey (index) {
      return `${this.errorType}-error-key-${index}`
    }
  }
}
</script>
