<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-1">
        <v-col cols="auto">
          <v-select
          v-if="snapshots.length > 0"
          v-model="selectedSnapshotRecord"
          :label="$t('snapshotDate')"
          :items="snapshots"
          @change="snapshotSelected(selectedSnapshotRecord)"
          item-text="uploadedAt"
          return-object>
            <template #selection="{item}">
              {{ formatDate(item.createdAt) }}
              <Icon
              v-if="recordIsUnUploaded(item)"
              icon="mdi-alert"
              :tooltipText="$t('snapshotploadInProgress')"/>
            </template>
            <template #item="{item}">
              {{ formatDate(item.createdAt) }}
              <Icon
              v-if="recordIsUnUploaded(item)"
              icon="mdi-alert"
              :tooltipText="$t('snapshotploadInProgress')"/>
            </template>
            <template #append-outer>
              <Icon
              @icon-clicked="refreshSnapshotList"
              icon="mdi-refresh"
              :small="false"
              :tooltipText="$t('refreshSnapshotList')"
              />
            </template>
          </v-select>
        </v-col>
        <v-spacer/>
        <v-col cols="auto" align="right" v-if="!isOnDemand">
          <span class="title">{{ nextSnapshotAvailableAt }}</span>
        </v-col>
        <v-col cols="auto" align="right" v-else>
          <v-btn
            class="secondary white--text"
            min-width="130px"
            @click="takeOnDemandSnapshotButton()"
            :disabled="disableOnDemandButton">
              {{ $t('takeOnDemandSnapshot') }}
            </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-1 mb-3">
        <v-col cols="12">
          <SnapshotBreakdown
            v-if="version === 1"
            :snapshot="selectedSnapshot"
            :isByproducts="isByproducts"
            :snapshotRecord="selectedSnapshotRecord"
            :snapshotPeriodEndDate="periodEndDateFromSnapshotUploadDate(selectedPeriodEndDate)"
            @refresh-snapshot="updateSnapshotList"/>
        </v-col>
      </v-row>
      <v-row class="my-12 pa-12" v-if="snapshots.length === 0" justify="center">
        <span class="headline black--text">
          {{ $t('noSnapshotAvailable') }}
        </span>
      </v-row>
      <v-row class="my-12 pa-12" v-if="uploadInProgressMessage" justify="center">
        <span class="headline black--text">
          {{ $t('adjustingEntriesUnderway') }}
        </span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { AdjustingEntriesFrequency, AdjustingEntriesSnapshotUploadStatus } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'AdjustingEntries',

  components: {
    SnapshotBreakdown: () => import('@/components/accounting/adjusting-entries/v1/SnapshotBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    contractMode: {
      type: Object,
      requried: true
    }
  },

  data: () => ({
    snapshots: [],
    selectedSnapshotRecord: undefined,
    selectedSnapshot: undefined,
    showManualSnapshotButtonToggle: false,
    selectedPeriodEndDate: undefined
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    isOnDemand () {
      return this.companyInfo.adjustingEntriesFrequency === AdjustingEntriesFrequency.OnDemand.value
    },
    version () {
      return this.selectedSnapshot?.version ?? 0
    },

    isByproducts () {
      return this.contractMode?.value === 1
    },
    nextSnapshotAvailableAt () {
      const nextMonth = moment().endOf('month').add(1, 'days').format('L')
      return this.$t('nextSnapshotAvailableDate', { date: nextMonth })
    },
    uploadInProgressMessage () {
      return this.selectedSnapshotRecord?.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value && !this.selectedSnapshot
    },
    disableOnDemandButton () {
      return this.snapshots[0]?.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value
    }
  },

  async created () {
    await this.updateSnapshotList()
  },

  methods: {
    ...mapActions('adjusting-entries', ['takeOnDemandSnapshot', 'snapshotsList', 'fetchSnapshot']),

    async takeOnDemandSnapshotButton () {
      try {
        await this.takeOnDemandSnapshot()
      } finally {
        await this.updateSnapshotList()
      }
    },

    async snapshotSelected (snapshot) {
      this.selectedSnapshot = undefined
      this.selectedSnapshotRecord = snapshot
      this.selectedPeriodEndDate = snapshot.uploadedAt
      if (this.selectedSnapshotRecord.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Uploaded.value) this.selectedSnapshot = await this.fetchSnapshot(snapshot)
    },

    async updateSnapshotList () {
      this.snapshots = (await this.snapshotsList()).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      this.selectedSnapshotRecord = this.snapshots[0] ?? undefined
      if (this.selectedSnapshotRecord) this.snapshotSelected(this.selectedSnapshotRecord)
    },

    adjustingEntriesFrequency: (x) => AdjustingEntriesFrequency.forInt(x),

    async refreshSnapshotList () {
      await this.updateSnapshotList()
    },

    periodEndDateFromSnapshotUploadDate (date) {
      return this.isOnDemand
        ? moment(date)
        : moment(date).add(-1, 'days')
    },

    formatDate (date) {
      const utcDate = moment.utc(date)
      if (!this.isOnDemand) utcDate.add(-1, 'days')

      return moment(utcDate).local().format(this.isOnDemand ? 'L - LT' : 'L')
    },

    recordIsUnUploaded (record) {
      return record.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value
    }
  }
}
</script>
