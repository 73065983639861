import i18n from '../i18n'
import { ConsumptionMode, DeckContentType } from '../utils/Enumerations'
import { tonStringFromPounds, formatMoney } from '../utils/NumericMutations'

export function deckHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('status'),
      value: 'status',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('contentType'),
      value: 'contentType',
      align: 'center',
      format: ct => DeckContentType.fromInt(ct)
    },
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('onDeckTons'),
      value: 'onDeck',
      align: 'right',
      format: tonStringFromPounds
    },
    {
      sortable: true,
      text: i18n.t('value'),
      value: 'value',
      align: 'right',
      format: formatMoney
    },
    {
      sortable: true,
      text: i18n.t('consumptionMode'),
      value: 'consumptionMode',
      align: 'center',
      format: cm => ConsumptionMode.fromInt(cm)
    },
    {
      sortable: true,
      text: i18n.t('modifiedAt'),
      value: 'openClose'
    },
    {
      sortable: true,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function deckAdjustmentHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('createdBy'),
      value: 'createdBy',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('createdAt'),
      value: 'createdAt',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('onDeckAt'),
      value: 'onDeckAt',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('valueAt'),
      value: 'valueAt',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('averageValuePerTonAt'),
      value: 'averageValuePerTonAt',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('adjustmentTons'),
      value: 'adjustmentTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('adjustmentValue'),
      value: 'adjustmentValue',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('onDeck'),
      value: 'onDeck',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('value'),
      value: 'value',
      align: 'right'
    },
    {
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}
