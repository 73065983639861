export const sortLandowners = (a, b) => {
  const objA = JSON.parse(a)
  const objB = JSON.parse(b)
  return (objA.loCount - objB.loCount) || (objA.loAccount.localeCompare(objB.loAccount))
}

export const sortSettlementInlineRates = (a, b) => {
  if (a?.length === b?.length) {
    let sumA = 0
    let sumB = 0
    a.forEach((value, i) => {
      sumA += value
      sumB += b[i]
    })
    return sumA - sumB
  } else {
    return a?.length - b?.length
  }
}
