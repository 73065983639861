export const defaultCoordinates = {
  latitude: 36.099,
  longitude: -80.2494
}

export const mapResizeTimeout = 250

export const addMapControlScript = () => { // The scale bar needs this to render
  if (!document.getElementById('atlasMapControlImport')) {
    const atlasScript = document.createElement('script')
    atlasScript.setAttribute('src', 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.js')
    atlasScript.setAttribute('id', 'atlasMapControlImport')
    document.head.insertBefore(atlasScript, document.head.firstChild)
  }
}

export const removeMapControlScript = () => {
  if (document.getElementById('atlasMapControlImport')) document.getElementById('atlasMapControlImport').remove()
  if (document.getElementById('stylesForScaleBar')) document.getElementById('stylesForScaleBar').remove() // This element is produced by the scale bar module
}
