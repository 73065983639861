export default {

  setLoading (state, status) {
    state.productsLoading = status
  },
  setProducts (state, products) {
    state.products = products
  },
  addProduct (state, product) {
    state.products.push(product)
  },
  removeProduct (state, productId) {
    state.products = state.products.filter(product => product.productId !== productId)
  },
  updateProduct (state, product) {
    const index = state.products.findIndex(v => v.productId === product.productId)
    if (index !== -1) {
      Object.assign(state.products[index], product)
    }
  }
}
