<template>
  <div>
    <div
      v-show="hasData"
      ref="container"
      :id="realContainerId"
      style="position: relative; height: 98%; width: 100%"
      class="pa-4"
    >
      <canvas ref="canvas" :id="realCanvasId"></canvas>
    </div>
    <div v-show="!hasData" style="text-align: center; height: 100%; width: 100%;margin:5% 0%;">
      <span
        class="headline black--text"
      >
        {{$t('noDataAvailableForChart')}}
      </span>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js'
import { logChartColorFor } from '@/components/dashboard/ChartColors.js'
export default {
  name: 'BarChartSimple',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  created () {
    this.generatedCanvasId = this.canvasId || `canvas-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
    this.generatedContainerId = this.containerId || `container-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
  },

  data: () => ({
    chartColors: [
      '#D15F27',
      '#D68D5F',
      '#B24008',
      '#FF8D55',
      '#A83600',
      '#E9BE9D',
      '#D6642C'
    ],
    byproductColors: [
      '#111111',
      '#333333',
      '#555555',
      '#aaaaaa',
      '#666666',
      '#cccccc',
      '#444444'
    ],
    chart: null,
    generatedCanvasId: 'canvas',
    generatedContainerId: 'container'
  }),

  watch: {
    yValues: {
      handler (_) {
        this.draw()
      },
      deep: true
    }
  },

  computed: {
    xValues () {
      return this.data.xValues
    },
    yValues () {
      return this.data.yValues
    },
    elementId () {
      return this.data.elementId
    },
    realCanvasId () {
      return this.canvasId || this.generatedCanvasId
    },
    realContainerId () {
      return this.containerId || this.generatedContainerId
    },
    downloadCSV () {
      return !this.disableCsv && this.$listeners['download-csv'] !== undefined
    },
    titleStyle () {
      return `color: ${this.textColor}; background-color: ${this.color}`
    },
    hasData () {
      return this.xValues.length > 0
    }
  },

  async mounted () {
    await this.$nextTick()
    this.$nextTick(this.draw)
  },

  methods: {
    createChart (canvas) {
      const colors = this.xValues.map((_, idx) => logChartColorFor(idx, this.xValues.length))

      this.chart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: this.xValues,
          datasets: [{
            label: '',
            data: this.yValues,
            backgroundColor: colors
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          resizeDelay: 500,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            }
          }
        }
      })
    },

    draw (force = false) {
      if (!force && (!this.$refs)) return
      const chart = Chart.getChart(this.$refs.canvas)
      if (chart) chart.destroy()
      this.createChart(this.$refs.canvas)
    }
  }
}
</script>
