import i18n from '@/i18n'

export default {
  payPeriodHeaders () {
    return ([
      {
        sortable: false,
        text: i18n.t('status'),
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('startDate'),
        value: 'startDate'
      },
      {
        sortable: true,
        text: i18n.t('endDate'),
        value: 'endDate'
      },
      {
        sortable: true,
        text: i18n.t('payDate'),
        value: 'payDate'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  }
}
