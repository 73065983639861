<template>
  <DataTable
  :items="tractSummaries"
  :headers="headers"
  :customCells="customCells"
  :hideHeader="true">
    <template #in-weight-tons="{item}">
      {{tonStringFromPounds(item.inWeight)}}
    </template>
    <template #out-weight-tons="{item}">
      {{tonStringFromPounds(item.outWeight)}}
    </template>
    <template #net-weight-tons="{item}">
      {{tonStringFromPounds(item.netWeight)}}
    </template>
    <template #defect-weight-tons="{item}">
      {{tonStringFromPounds(item.defectWeight)}}
    </template>
    <template #body.append>
      <tr class="font-weight-bold">
        <td class="text-left">Totals</td>
        <td class="text-right">{{getTotalLoads(tractSummaries)}}</td>
        <td class="text-right">{{getTotalInWeight(tractSummaries)}}</td>
        <td class="text-right">{{getTotalOutWeight(tractSummaries)}}</td>
        <td class="text-right">{{getTotalDefectTons(tractSummaries)}}</td>
        <td class="text-right">{{getTotalNetTons(tractSummaries)}}</td>
      </tr>
    </template>
  </DataTable>
</template>

<script>
import { tractSummaryHeaders } from '@/headers/Cycles.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
export default {
  name: 'TractSummaries',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  props: {
    tractSummaries: {
      type: Array,
      required: true
    }
  },

  computed: {
    headers () {
      return tractSummaryHeaders(this.$i18n.locale)
    },
    customCells () {
      return [
        {
          slotName: 'net-weight-tons',
          value: 'netWeight'
        },
        {
          slotName: 'in-weight-tons',
          value: 'inWeight'
        },
        {
          slotName: 'out-weight-tons',
          value: 'outWeight'
        },
        {
          slotName: 'defect-weight-tons',
          value: 'defectWeight'
        }
      ]
    }
  },
  methods: {
    tonStringFromPounds,

    getTotalLoads (tractSummaries) {
      return tractSummaries.reduce((a, b) => a + b.loads, 0)
    },

    getTotalInWeight (tractSummaries) {
      return tonStringFromPounds(tractSummaries.reduce((a, b) => a + b.inWeight, 0))
    },

    getTotalOutWeight (tractSummaries) {
      return tonStringFromPounds(tractSummaries.reduce((a, b) => a + b.outWeight, 0))
    },

    getTotalDefectTons (tractSummaries) {
      return tonStringFromPounds(tractSummaries.reduce((a, b) => a + b.defectWeight, 0))
    },

    getTotalNetTons (tractSummaries) {
      return tonStringFromPounds(tractSummaries.reduce((a, b) => a + b.netWeight, 0))
    }
  }
}
</script>
