<template>
  <div style="height: 90%;">
    <transition name="fade" mode="out-in">
    <router-view id="view"></router-view>
  </transition>
  <GlobalShortcuts/>
  <SystemNotifications/>
  <LoadingOverlay/>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
export default {
  components: {
    GlobalShortcuts: () => import('./GlobalShortcuts.vue'),
    SystemNotifications: () => import('./SystemNotifications.vue'),
    LoadingOverlay: () => import('./LoadingOverlay.vue')
  },

  metaInfo () {
    return {
      title: 'Legna Software'
    }
  },

  created () {
    this.registerChartElements()
  },

  methods: {
    registerChartElements () {
      Chart.register(...registerables)
    }
  }
}
</script>

<style>
.v-data-table-header__icon {
  display: none !important;
}
</style>

<style scoped>

#footer {
  position: fixed;
  bottom: 200;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background-color:#E8E8E8 !important;
}

.v-data-table-header th .v-data-table-header__icon {
  display: none;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .33s ease;
}

.fade-leave-active {
  transition: opacity .33s ease;
  opacity: 0;
}
</style>
