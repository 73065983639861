<template>
  <v-row>
    <v-col cols="12" xs="12">
      <v-row dense>
        <v-col cols="12" xs="12" sm="12" md="5" lg="4" xl="3">
          <v-form @submit.prevent>
            <v-text-field
              v-model="newBundleName"
              class="mt-5"
              data-testid="new-bundle-name"
              maxLength="64"
              counter="64"
              outlined
              fill-width
              :label="$t('newBundleName')"
              :disabled="!userAssignedClaim(UserClaims.ContractManager)"
              >
                <template #append>
                  <v-btn
                    class="mt-n2"
                    type="submit"
                    icon
                    color="secondary"
                    :disabled="newBundleName === ''"
                    @click="addBundle"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" xs="12">
          <v-data-table
          dense
          item-key="name"
          show-expand
          single-expand
          style="cursor: pointer;"
          :loading="templatesLoading"
          :items="allTemplateBundles"
          :headers="headers"
          :expanded.sync="expanded"
          @click:row="rowClicked">
            <template #item.actions="{ item }">
              <Icon
                icon="mdi-pencil"
                tooltipColor="success"
                iconColor="success"
                dataTestId="bundle-edit-button"
                :tooltipText="$t('editName')"
                :small="false"
                @icon-clicked="editBundleName(item)"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)" />
              <Icon
                icon="mdi-delete-forever"
                tooltipColor="error"
                iconColor="error"
                dataTestId="bundle-delete-button"
                :tooltipText="$t('deleteBundle')"
                :small="false"
                @icon-clicked="openDeleteBundleDialog(item)"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)" />
            </template>
            <template #expanded-item="{ headers, item }">
              <td class="grey lighten-5" :colspan="headers.length+1">
                <BundleBreakdown
                  :propBundle="item"
                  @add-template-to-bundle="openCounterpartyForCreateDialog"
                  @edit-template-builder="openCounterpartyForEditDialog"
                  @delete-template-from-bundle="openDeleteTemplateDialog"
                />
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <Dialog :stateId="dialogId" @close="closeSubdialogs">
      <SelectCounterpartyDialog
        v-if="selectCounterpartyDialog"
        :newTemplateRequest="newTemplateRequest"
        :propBuilder="focusedBuilder"
        @counterparty-selected="counterpartySelected"
        @cancel="closeDialogsAtOrAbove(dialogId)"
      />
      <EditBundleDialog
      v-if="editBundleDialog && focusedBundle"
      :propBundle="focusedBundle"
      @close="closeEditDialog"/>
      <ConfirmDialog
      v-if="deleteTemplateDialog"
      :title="$t('deleteTemplate')"
      :body="$t('deleteTemplateFromBundle')"
      @confirm="deleteTemplate"
      @cancel="closeDialogsAtOrAbove(dialogId)"/>
      <ConfirmDialog
      v-if="deleteBundleDialog"
      :title="$t('deleteBundle')"
      :body="$t('deleteBundleConfirmation')"
      @confirm="deleteBundle"
      @cancel="closeDialogsAtOrAbove(dialogId)"/>
    </Dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AccountingCategory, FinancialIntegrationType, UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'BundlesTab',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    BundleBreakdown: () => import('./BundleBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    SelectCounterpartyDialog: () => import('./SelectCounterpartyDialog.vue'),
    EditBundleDialog: () => import('./EditBundleDialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    dialogId: 'bundles-tab',
    expanded: [],
    newBundleName: '',
    headers: [
      {
        sortable: true,
        text: 'Name',
        value: 'name',
        align: 'left'
      },
      {
        sortable: true,
        text: 'Actions',
        value: 'actions',
        align: 'center'
      }
    ],
    selectCounterpartyDialog: false,
    deleteTemplateDialog: false,
    focusedBuilder: undefined,
    focusedBundle: null,
    editBundleDialog: false,
    deleteBundleDialog: false,
    focusedTemplate: undefined,
    newTemplateRequest: undefined,
    deleteTemplateRequest: undefined,
    deleteBundleRequest: undefined,
    UserClaims
  }),

  computed: {
    ...mapGetters('activity-templates', ['allTemplateBundles', 'templatesLoading']),
    ...mapGetters('user', ['companyInfo'])
  },

  watch: {
    editBundleDialog (val) {
      if (!val) {
        this.closeEditDialog(false)
      }
    }
  },

  async created () {
    await this.refresh()
  },

  methods: {
    ...mapActions('activity-templates', ['fetchTemplateBundles', 'createTemplateBundle', 'deleteTemplateBundle', 'updateTemplateBundle', 'updateActivityBuilder', 'deleteTemplateFromBundle']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,
    async refresh () {
      await this.fetchTemplateBundles()
    },

    closeSubdialogs () {
      this.deleteTemplateDialog = false
      this.deleteBundleDialog = false
      this.selectCounterpartyDialog = false
      this.editBundleDialog = false
    },

    rowClicked (item) {
      this.expanded = []
      this.$nextTick(() => {
        this.expanded = [item]
      })
    },

    openCounterpartyForCreateDialog (requestObj) {
      this.focusedBuilder = undefined
      this.newTemplateRequest = requestObj
      this.selectCounterpartyDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px' })
    },

    openCounterpartyForEditDialog (builder) {
      this.focusedBuilder = builder
      this.focusedBundle = this.expanded[0]
      this.newTemplateRequest = undefined
      this.selectCounterpartyDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px' })
    },

    openDeleteTemplateDialog (requestObj) {
      this.deleteTemplateRequest = requestObj
      this.deleteTemplateDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    openDeleteBundleDialog (requestObj) {
      this.deleteBundleRequest = requestObj
      this.deleteBundleDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    editBundleName (bundle) {
      this.focusedBundle = bundle
      this.editBundleDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    closeEditDialog (edited) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedBundle = null
      if (edited) {
        this.refresh()
      }
    },

    async addBundle () {
      if (this.newBundleName.trim() === '') {
        return
      }

      await this.createTemplateBundle({ name: this.newBundleName, activityBuilders: [] })
      await this.refresh()
      const newBundleIndex = this.allTemplateBundles.map(bundle => bundle.name).indexOf(this.newBundleName)
      this.expanded = [this.allTemplateBundles[newBundleIndex]]
      this.newBundleName = ''
    },

    async deleteBundle () {
      await this.deleteTemplateBundle(this.deleteBundleRequest)
      this.closeDialogsAtOrAbove(this.dialogId)
      await this.refresh()
    },

    async counterpartySelected (accountSelected, limitToBaseRate) {
      if (this.focusedBuilder) await this.editBuilder(accountSelected, limitToBaseRate)
      else await this.addTemplateToBundle(accountSelected, limitToBaseRate)
    },

    async addTemplateToBundle ({ account, runtimeField }, limitToBaseRate) {
      if (runtimeField !== undefined && runtimeField !== null) this.newTemplateRequest.runtimeField = runtimeField
      else if (account) this.newTemplateRequest.counterpartyId = account.accountId
      this.newTemplateRequest.limitToBaseRate = limitToBaseRate

      if (account?.exportCode === '' &&
        this.newTemplateRequest.category !== AccountingCategory.Accrual.value &&
        this.companyInfo.financialIntegrationType !== FinancialIntegrationType.None.value) {
        this.setSnackError(this.$t('counterpartyExportCodeErrorBundle', { accountName: account?.name }))
        return
      }

      await this.updateTemplateBundle(this.newTemplateRequest)
      await this.refresh()
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async editBuilder ({ account, runtimeField }, limitToBaseRate) {
      const requestObj = {
        templateBundleId: this.focusedBundle.templateBundleId,
        activityBuilderId: this.focusedBuilder.activityBuilderId,
        activityTemplateId: this.focusedBuilder.activityTemplateId,
        runtimeField,
        counterpartyId: account?.accountId ?? undefined,
        limitToBaseRate: limitToBaseRate ?? false
      }

      if (account?.exportCode === '' &&
      this.focusedBuilder.category !== AccountingCategory.Accrual.value &&
      this.companyInfo.financialIntegrationType !== FinancialIntegrationType.None.value) {
        this.setSnackError(this.$t('counterpartyExportCodeErrorBundle', { accountName: account?.name }))
        return
      }
      await this.updateActivityBuilder(requestObj)
      await this.refresh()
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async deleteTemplate () {
      await this.deleteTemplateFromBundle(this.deleteTemplateRequest)
      this.refresh()
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
