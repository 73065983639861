export const customTicketSummaryCells =
  [
    {
      slotName: 'ticket-number',
      value: 'ticketNumber'
    },
    {
      slotName: 'net-weight-tons',
      value: 'netWeight'
    },
    {
      slotName: 'in-weight-tons',
      value: 'inWeight'
    },
    {
      slotName: 'out-weight-tons',
      value: 'outWeight'
    },
    {
      slotName: 'defect-weight-tons',
      value: 'defectWeight'
    },
    {
      slotName: 'product',
      value: 'productName'
    },
    {
      slotName: 'weighed-in-at',
      value: 'weighedInAt',
      dateFormat: ('MM/DD/YYYY - LT')
    },
    {
      slotName: 'weighed-out-at',
      value: 'weighedOutAt',
      dateFormat: ('MM/DD/YYYY - LT')
    }
  ]
