<template>
  <v-row>
    <v-col cols="12">
      <v-tabs
      background-color="white"
      color="#888888"
      slider-color="secondary"
      slider-size="4">
        <v-tab active-class="active-tab">
          {{ $t('mainContact') }}
        </v-tab>
        <v-tab active-class="active-tab" :disabled="checkForBlankContact(mainContact) === undefined">
          {{ $t('mobileAppContacts') }}
        </v-tab>
        <v-tab-item disabled>
          <v-col cols="4">
            <ContactCard
            isMainContact
            :showCrud="!readonly"
            :contact="contactForCard(mainContact)"
            :addContact="checkForBlankContact(mainContact) === undefined"
            @click:edit="editMain = true; showDialog = true"
            @click:create="editMain = true; showDialog = true"/>
          </v-col>
        </v-tab-item>
        <v-tab-item disabled>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" md="4" xl="4">
                {{ $t('transporterContact') }}
                <Icon
                  v-if="checkForBlankContact(transporterContact) === undefined"
                  icon="mdi-plus"
                  :small="false"
                  :tooltipText="$t('addTransporterContact')"
                  @icon-clicked="createTransporter = true; showDialog = true"
                  :disabled="readonly"/>
                <ContactCard
                v-if="checkForBlankContact(transporterContact) !== undefined"
                isMobileAppContactInfo
                @click:edit="editTransporter = true; showDialog = true"
                @click:delete="$emit('deleteTransporterContact')"
                :contact="contactForCard(transporterContact)"
                :showCrud="!readonly"/>
              </v-col>
              <v-col cols="12" sm="12" md="4" xl="4">
                <span>
                  {{ $t('loaderContact') }}
                  <Icon
                  v-if="checkForBlankContact(loaderContact) === undefined"
                  icon="mdi-plus"
                  :small="false"
                  :tooltipText="$t('addLoaderContact')"
                  @icon-clicked="createLoader = true; showDialog = true"
                  :disabled="readonly"/>
                </span>
                <ContactCard
                v-if="checkForBlankContact(loaderContact) !== undefined"
                isMobileAppContactInfo
                :contact="contactForCard(loaderContact)"
                @click:delete="$emit('deleteLoaderContact')"
                @click:edit="editLoader = true; showDialog = true"
                :showCrud="!readonly"/>
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-dialog v-model="showDialog" max-width="50%" @input="closeDialog()">
      <ContactForm
      v-if="showDialog"
      isCompanyInfoContact
      :propContact="getPropContact()"
      @close="closeDialog()"
      @contact-updated="contactUpdated"
      @contact-created="contactCreated"/>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'CompanyAppContacts',

  components: {
    ContactCard: () => import('../../account/contacts/ContactCard.vue'),
    Icon: () => import('../../../components/helper/Icon.vue'),
    ContactForm: () => import('../../account/contacts/ContactForm.vue')
  },

  props: {
    mainContact: {
      type: Object,
      required: true
    },
    transporterContact: {
      type: Object,
      required: false
    },
    loaderContact: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    editMain: false,
    editTransporter: false,
    editLoader: false,
    createLoader: false,
    createTransporter: false,
    showDialog: false
  }),

  methods: {
    contactForCard (contact) {
      return {
        person: {
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          position: 'Transporter Contact',
          email: contact?.email,
          phoneNumber: contact?.phoneNumber
        }
      }
    },

    closeDialog () {
      this.editMain = false
      this.editTransporter = false
      this.editLoader = false
      this.createLoader = false
      this.createTransporter = false
      this.showDialog = false
    },

    getPropContact () {
      if (this.editMain) return this.checkForBlankContact(this.mainContact) === undefined ? undefined : this.contactForCard(this.mainContact)
      if (this.editTransporter) return this.contactForCard(this.transporterContact)
      if (this.editLoader) return this.contactForCard(this.loaderContact)
      return undefined
    },

    checkForBlankContact (contact) {
      if (contact?.email === '' &&
        contact?.firstName === '' &&
        contact?.lastName === '' &&
        contact?.phoneNumber === '') return undefined
      return contact
    },

    contactUpdated (contact) {
      if (this.editMain) this.$emit('mainEdited', contact.person)
      if (this.editTransporter) this.$emit('transporterSet', contact.person)
      if (this.editLoader) this.$emit('loaderSet', contact.person)
      this.closeDialog()
    },

    contactCreated (contact) {
      if (this.editMain) this.$emit('mainEdited', contact.person)
      if (this.createLoader) this.$emit('loaderSet', contact.person)
      if (this.createTransporter) this.$emit('transporterSet', contact.person)
      this.closeDialog()
    }
  }
}
</script>
