export default {
  setTractTypeLoadingStatus (state, status) {
    state.tractLoadingStatus = status
  },
  setTractTypes (state, tractTypes) {
    state.tractTypes = tractTypes
  },
  addTractType (state, tract) {
    state.tractTypes.push(tract)
  },
  removeTractType (state, tractTypeId) {
    state.tractTypes = state.tractTypes.filter(t => t.tractTypeId !== tractTypeId)
  },
  updateTractType (state, tract) {
    const index = state.tractTypes.findIndex(t => t.tractTypeId === tract.tractTypeId)
    if (index !== -1) {
      Object.assign(state.tractTypes[index], tract)
    }
  }
}
