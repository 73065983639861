export default {
  setLoading (state, status) {
    state.loading = status
  },
  setTickets (state, tickets) {
    state.tickets = tickets
  },
  addTicket (state, ticket) {
    state.tickets.push(ticket)
  },
  setPostedTickets (state, tickets) {
    state.postedTickets = tickets
  },
  setDateFilter (state, dateFilter) {
    state.dateFilter = dateFilter
  },
  setContractMode (state, contractMode) {
    state.contractMode = contractMode
  },
  applyFilter (state, filter) {
    switch (filter.name) {
      case 'advancedSearch':
        state.filters[filter.name].query = filter.query
        break
      case 'contract':
        state.filters[filter.name].contractId = filter.contractId
        break
      case 'phrase':
        state.filters[filter.name].phrase = filter.phrase
        break
      case 'tract':
        state.filters[filter.name].tractId = filter.tractId
        break
      case 'dateRange':
        state.filters[filter.name].startDate = filter.startDate
        state.filters[filter.name].endDate = filter.endDate
        break
    }

    if (filter.name !== 'advancedSearch') {
      state.filters.advancedSearch.active = false
      state.filters.advancedSearch.query = null
    }

    state.filters[filter.name].active = true
  },

  removeFilter (state, filter) {
    switch (filter.name) {
      case 'advancedSearch':
        state.filters[filter.name].query = undefined
        break
      case 'phrase':
        state.filters[filter.name].phrase = ''
        break
      case 'contract':
        state.filters[filter.name].contractId = undefined
        break
      case 'tract':
        state.filters[filter.name].tractId = undefined
        break
      case 'dateRange':
        state.filters[filter.name].startDate = undefined
        state.filters[filter.name].endDate = undefined
        break
    }

    if (filter.name !== 'advancedSearch') {
      state.filters.advancedSearch.active = false
      state.filters.advancedSearch.query = null
    }

    state.filters[filter.name].active = false
  },

  removeTicket (state, ticketId) {
    state.tickets = state.tickets.filter(t => t.ticketId !== ticketId)
  },
  updateTicket (state, ticket) {
    const index = state.tickets.findIndex(t => t.ticketId === ticket.ticketId)
    if (index !== -1) {
      Object.assign(state.tickets[index], ticket)
    }
  }
}
