<template>
  <div :style="cellStyle">
    <slot></slot>
  </div>
</template>

<script>
import { selectBreakpoint } from '@/utils/componentHelpers'
export default {
  name: 'GridArea',

  props: {
    area: {
      type: Array,
      default: undefined
    },
    width: {
      type: [Number, Object],
      default: 1
    },
    height: {
      type: [Number, Object],
      default: 1
    },
    overflow: {
      type: String,
      default: undefined
    },
    order: {
      type: Number,
      default: undefined,
      required: false
    }
  },

  computed: {
    cellStyle () {
      const getDimension = (prop) => {
        if (typeof prop === 'number') {
          return prop
        } else {
          return selectBreakpoint(prop, this.$vuetify.breakpoint.name)
        }
      }

      const useArea = (this.area !== undefined && this.area.length === 2)

      const height = getDimension((useArea) ? this.area[0] : this.height)
      const width = getDimension((useArea) ? this.area[1] : this.width)

      return `
        grid-area: span ${height} / span ${width} !important;
        ${this.overflow ? `overflow: ${this.overflow};` : ''}
        ${this.order !== undefined ? `order: ${this.order}` : ''}
      `
    }
  }

}
</script>
