<template>
  <v-banner class="white--text mb-8" color="error" single-line elevation="5">
    <v-tooltip bottom color="secondary" max-width="650px">
      <template #activator="{ on }">
        <span v-on="on">
          {{ $t('contractApprovalStatusMessage', { status: $t(approvalStatusString) }) }}
        </span>
      </template>
      <span class="subtitle-1 white--text">
        {{ $t(`${approvalStatusString}Description`) }}
      </span>
    </v-tooltip>
    <template #actions>
      <v-tooltip bottom color="secondary" max-width="650px">
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              v-if="showReturnedNoteButton"
              color="white"
              text
              @click="viewNoteDialog=true"
            >
              {{ $t('seeNotes') }}
            </v-btn>
          </span>
        </template>
        <span class="subtitle-1 white--text">
          {{ $t('seeModificationNoteTooltip') }}
        </span>
      </v-tooltip>
      <v-tooltip bottom color="secondary" max-width="650px">
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              v-if="showReviewButtons"
              color="white"
              text
              @click="tryApprove"
            >
              {{ $t('approve') }}
            </v-btn>
          </span>
        </template>
        <span class="subtitle-1 white--text">
          {{ $t('appproveContractModificationsTooltip') }}
        </span>
      </v-tooltip>
      <v-tooltip bottom color="secondary" max-width="650px">
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              v-if="showReviewButtons"
              color="white"
              text
              @click="tryDeny"
            >
              {{ $t('return') }}
            </v-btn>
          </span>
        </template>
        <span class="subtitle-1 white--text">
          {{ $t('returnContractModificationsTooltip') }}
        </span>
      </v-tooltip>
    </template>
    <v-dialog
      v-if="approveDialog"
      v-model="approveDialog"
      width="400px"
    >
      <ApprovalNoteDialog
        :title="$t('approveContractModifications')"
        :noteText="$t('approveNoteText')"
        :confirmText="$t('approve')"
        @confirm="approve"
        @cancel="approveDialog=false"
      />
    </v-dialog>
    <v-dialog
      v-if="returnDialog"
      v-model="returnDialog"
      width="400px"
    >
      <ApprovalNoteDialog
        :title="$t('returnContractModifications')"
        :noteText="$t('returnNoteText')"
        :confirmText="$t('return')"
        @confirm="deny"
        @cancel="returnDialog=false"
      />
    </v-dialog>
    <v-dialog
      v-if="viewNoteDialog"
      v-model="viewNoteDialog"
      width="400px"
    >
      <ViewNoteDialog
        :noteText="approval?.noteContent"
        @close="viewNoteDialog=false"
      />
    </v-dialog>
    <v-dialog
      v-if="confirmApproveUnfinished"
      v-model="confirmApproveUnfinished"
      width="400px"
    >
      <ConfirmDialog
        :title="$t('confirmApprove')"
        :body="$t('confirmReviewUnfinishedtext', {status: $t('approveLowercase')})"
        :confirmText="$t('approve')"
        @confirm="approve"
        @cancel="confirmApproveUnfinished = false"
      />
    </v-dialog>
    <v-dialog
      v-if="confirmReturnUnfinished"
      v-model="confirmReturnUnfinished"
      width="400px"
    >
      <ConfirmDialog
        :title="$t('confirmApprove')"
        :body="$t('confirmReviewUnfinishedtext', {status: $t('returnLowercase')})"
        :confirmText="$t('return')"
        @confirm="returnDialog=true"
        @cancel="confirmReturnUnfinished = false"
      />
    </v-dialog>
  </v-banner>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaims, ContractApprovalStatus } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility'

export default {
  name: 'ApprovalStatusIndicator',

  components: {
    ApprovalNoteDialog: () => import('@/components/contract/contract-detail/ApprovalNoteDialog.vue'),
    ViewNoteDialog: () => import('@/components/contract/contract-detail/ViewNoteDialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    approvalStatus: {
      type: Number,
      required: true
    },

    contractId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    isLoading: false,
    approval: {},
    approveDialog: false,
    returnDialog: false,
    viewNoteDialog: false,
    confirmApproveUnfinished: false,
    confirmReturnUnfinished: false
  }),

  computed: {
    ...mapGetters('user', ['userInfo']),

    approvalStatusString () {
      return ContractApprovalStatus.labelFromInt(this.approvalStatus)
    },

    showReviewButtons () {
      return !this.isLoading &&
        userAssignedClaim(UserClaims.ContractReviewer) &&
        [ContractApprovalStatus.PendingModification.value, ContractApprovalStatus.PendingReview.value].includes(this.approvalStatus) &&
        this.approval?.requestedByUserId !== this.userInfo.applicationUserId
    },

    showReturnedNoteButton () {
      return !this.isLoading &&
        this.approval?.noteContent !== null
    }
  },

  async created () {
    this.isLoading = true
    this.approval = await this.getMostRecentContractApproval(this.contractId)
    this.isLoading = false
  },

  methods: {
    ...mapActions('contract-approvals', ['getMostRecentContractApproval', 'updateContractApproval']),
    ...mapActions('contract', ['getContract']),

    tryApprove () {
      if (this.approvalStatus === ContractApprovalStatus.PendingModification.value) {
        this.confirmApproveUnfinished = true
      } else {
        this.approve()
      }
    },

    approve () {
      this.review(ContractApprovalStatus.Approved, null)
      this.approveDialog = false
    },

    tryDeny () {
      if (this.approvalStatus === ContractApprovalStatus.PendingModification.value) {
        this.confirmReturnUnfinished = true
      } else {
        this.returnDialog = true
      }
    },

    deny (noteContent) {
      this.review(ContractApprovalStatus.Returned, noteContent)
      this.returnDialog = false
    },

    async review (status, noteContent) {
      const requestObj = {
        contractApprovalId: this.approval.contractApprovalId,
        contractId: this.contractId,
        status: status.value,
        note: noteContent === null || noteContent.length === 0 ? null : { content: noteContent }
      }
      await this.updateContractApproval(requestObj)
      this.$emit('review', this.contractId)
    }
  }
}
</script>
