<template>
  <v-card>
    <v-card-text>
      <v-container v-show="!locationsLoading" fluid class="fill-height">
        <v-row dense>
          <v-col :cols="12" xs="12" sm="12" md="auto">
            <DeckSelector
              showEntitySelector
              :showAll="false"
              :propInitialLocation="location"
              :propDeckId="deck ? deck.deckId : undefined"
              @location-selected="locationSelected"
              @deck-selected="deckSelected"
              @entity-selected="entitySelected"
              :contentType="contentType.value"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-text-field
              v-if="consumptionMode === ticketBased"
              v-model="search"
              data-testid="consume-ticket-search"
              :label="$t('search')"
              color="secondary"
              clearable
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <span class="font-italic">
              {{consumptionModeText}}
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="consumptionMode === ticketBased" cols="auto" class="mt-4">
            <ColumnAdjuster
              dynamicSize
              :propColumnSet.sync="columnSet"
              @new-columns-selected="newColumnsSelected"
            />
            <v-menu
            bottom
            left
            v-model="menuOpen">
              <template #activator="{ on, attrs}">
                <v-icon
                color="secondary"
                v-bind="attrs"
                v-on="on">mdi-download</v-icon>
              </template>
              <v-list>
                <v-list-item
                v-for="(action, index) in actions"
                :key="`download-action-${index}`"
                :disabled="action.disabled"
                @click="action.onClick(action.clickArgument)">
                  <v-avatar left >
                    <v-icon
                    :color="action.isEnabled ? 'grey' : 'black'">
                      {{action.icon}}
                    </v-icon>
                  </v-avatar>
                  <v-list-item-title class="subtitle-1">
                    {{action.text}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="consumptionMode === ticketBased">
          <v-col cols="12">
            <v-data-table
              v-model="selected"
              :items="formattedTicketsForConsumption"
              :search="search"
              show-select
              dense
              :footer-props="{
                'items-per-page-options': [25, 50, 100, -1]
              }"
              :items-per-page="25"
              item-key="ticketId"
              :headers="gridHeaders"
              @update:sort-by="updateSort('by', $event)"
              @update:sort-desc="updateSort('desc', $event)"
            >
              <template #item.extTicketNumber1="{item}">
                <span>
                  {{ item.extTicketNumber1 !== '' ? item.extTicketNumber1 : '-' }}
                </span>
              </template>
              <template #item.extTicketNumber2="{item}">
                <span>
                  {{ item.extTicketNumber2 !== '' ? item.extTicketNumber2 : '-' }}
                </span>
              </template>
              <template #item.mbf="{item}">
                <span>
                  {{ formatMbf(item.mbf) }}
                </span>
              </template>
              <template #item.weighedOutAt="{ item }">{{
                utcToLocalDate(item.weighedOutAt, 'L - LT')
              }}</template>
              <template #item.deckAssignedAt="{ item }">
                <span>{{ utcToLocalDate(item.deckAssignedAt, 'L - LT') }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col xs="12" sm="12" md="6" lg="6">
            <v-text-field
              :label="$t('onDeckLbs')"
              outlined
              :value="onDeck"
              color="secondary"
              readonly
            />
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="weightToConsume"
              data-testid="consume-weight"
              :label="$t('weight')"
              color="secondary"
              type="number"
              hide-spin-buttons
            />
          </v-col>
        </v-row>
        <v-row>
          <Dialog :stateId="dialogId" maxWidth="500px" @close="closeDialog">
            <ConfirmConsumption
              v-if="confirmConsumptionDialog"
              :deck="deck"
              :weightToConsume="parsedWeightToConsume"
              :ticketsForConsumption="selected"
              :productMap="productIdDictionary"
              @close="closeDialog"
              @tickets-consumed="ticketsConsumed"
            />
            <ConfirmDialog
              v-if="confirmTransferDialog"
              :title="consumptionMode === ticketBased ? $t('transferTickets') : $t('transferWeight')"
              :confirmText="consumptionMode === ticketBased ? $t('transferTickets') : $t('transferWeight')"
              body=""
              width="500px"
              color="secondary"
              @confirm="transferTickets"
              @cancel="closeDialog"
            >
              <template #custom-body>
                <v-row dense>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                    <span class="font-weight-bold">{{ $t('selectDeck') }}:</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-card elevation="0">
                      <DeckSelector
                        limitToOpen
                        :showAll="false"
                        :propInitialLocation="location"
                        :currentDeckId="deck.deckId"
                        :consumptionMode="consumptionMode"
                        @deck-selected="destinationDeckSelected"
                        :contentType="deck.contentType"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </ConfirmDialog>
          </Dialog>
        </v-row>
      </v-container>
      <v-container fluid v-if="locationsLoading">
        <v-row align="center" justify="center" class="pa-12 ma-12">
          <v-progress-circular indeterminate color="secondary"/>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <AggregateTotalTable
        v-if="consumptionMode === ticketBased"
        :aggregateTotal="aggregateTotal"
      />
      <v-spacer />
      <v-btn
      @click="openConfirmTransferDialog"
      :disabled="submitButtonsDisabled"
      class="secondary">
        {{transferButtonText}}
      </v-btn>
      <v-btn
      @click="openConfirmConsumptionDialog"
      :disabled="submitButtonsDisabled"
      class="secondary">
        {{submitButtonText}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import TicketHeaders from '@/headers/Ticket'
import { ConsumptionMode, ContractModes, DeckContentType } from '../../../utils/Enumerations'
import { generatePdf } from '@/utils/pdf-formatters/TicketsOnDeck.js'
import { generateCsvString } from '@/utils/CSVUtility'
import { saveAs } from 'file-saver'
import { localeCompareAlphanumeric } from '@/utils/base'

export default {
  name: 'ConsumeTickets',

  created () {
    this.initializeHeaders()
  },

  components: {
    DeckSelector: () => import('@/components/dashboard/DeckSelector.vue'),
    ConfirmConsumption: () => import('@/components/ticket/ticket-consumption/ConfirmConsumption.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    AggregateTotalTable: () => import('@/components/ticket/ticket-consumption/AggregateTotalTable.vue')
  },

  props: {
    refreshFlag: {
      type: Boolean,
      default: false
    },
    productIdDictionary: {
      type: Object,
      default: () => ({})
    },
    contractMode: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    dialogId: 'consumption',
    location: undefined,
    deck: undefined,
    entity: undefined,
    confirmConsumptionDialog: false,
    confirmTransferDialog: false,
    weightToConsume: 0,
    deckId: null,
    destinationDeckId: null,
    search: '',
    columnSet: null,
    pdfColumnSort: undefined,
    sortDirection: undefined,
    selected: [],
    menuOpen: false,
    DeckContentType
  }),

  computed: {
    ...mapGetters('consumption', ['ticketsForConsumption']),
    ...mapGetters('locations', ['allLocations', 'locationsLoading']),

    headers () {
      return TicketHeaders.ticketConsumptionHeaders(this.$i18n.locale)
    },

    consumptionMode () {
      return this.deck?.consumptionMode ?? 0
    },

    ticketBased () {
      return ConsumptionMode.TicketBased.value
    },

    parsedWeightToConsume () {
      return Number(this.weightToConsume)
    },

    contentType () {
      return this.contractMode === undefined || this.contractMode.value !== ContractModes[1].value
        ? DeckContentType.Logs
        : DeckContentType.Byproducts
    },

    aggregateTotal () {
      let averageRpiFlag = true
      const totals = this.selected.reduce((t, ticket) => {
        t.weightTotal += ticket.inWeight - ticket.outWeight - ticket.defectWeight
        t.mbfTotal += (ticket.inWeight - ticket.outWeight - ticket.defectWeight) * this.productIdDictionary[ticket.product].conversionRateW2V
        if (ticket.rpi === 0) {
          averageRpiFlag = false
        } else {
          t.rpiTotal += (ticket.rpi * (ticket.inWeight - ticket.outWeight - ticket.defectWeight))
        }
        return t
      }, { weightTotal: 0, mbfTotal: 0, rpiTotal: 0 })

      return {
        weightTons: tonStringFromPounds(totals.weightTotal),
        mbf: numberWithCommas(Math.ceil(totals.mbfTotal)),
        rpi: averageRpiFlag ? numberWithCommas((totals.rpiTotal / totals.weightTotal), 2) : 0
      }
    },

    submitButtonsDisabled () {
      return this.consumptionMode === ConsumptionMode.TicketBased.value
        ? this.selected.length === 0
        : this.weightToConsume <= 0 || (this.deck?.onDeck ?? 0) < this.weightToConsume * 2000
    },

    transferButtonText () {
      return this.consumptionMode === ConsumptionMode.TicketBased.value
        ? this.$t('transferSelected')
        : this.$t('transferWeight')
    },

    submitButtonText () {
      return this.consumptionMode === ConsumptionMode.TicketBased.value
        ? this.$t('consumeSelected')
        : this.$t('consumeWeight')
    },

    consumptionModeText () {
      const consumptionModeText = this.consumptionMode === ConsumptionMode.TicketBased.value
        ? this.$t('ticketBased')
        : this.$t('weightBased')
      return this.$t('consumptionModeType', { type: consumptionModeText })
    },

    onDeck () {
      return this.deck !== undefined ? tonStringFromPounds(this.deck.onDeck) : 0
    },

    gridHeaders () {
      return this.headers.getColumns() ?? []
    },

    actions () {
      return [
        {
          text: this.$t('downloadCSV'),
          icon: 'mdi-file-delimited-outline',
          onClick: this.downloadCsv,
          disabled: false
        },
        {
          text: this.$t('downloadPDF'),
          icon: 'mdi-file-pdf-box',
          onClick: this.downloadPDF,
          disabled: false
        }
      ]
    },

    formattedTicketsForConsumption () {
      const tickets = this.ticketsForConsumption
      tickets.forEach(ticket => {
        ticket.netWeight = this.getNetWeight(ticket)
        ticket.mbf = this.calculateTicketMbf(ticket)
      })

      return tickets
    }
  },

  watch: {
    refreshFlag (val) {
      if (val === true) {
        this.fetchLocations({ includeByproductDecks: true })
      }
    }
  },
  methods: {
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('consumption', ['fetchTicketsForConsumption', 'fetchConsumptionBatches', 'postConsumptionBatch', 'revertTickets', 'postTransferBatches']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,

    locationSelected (location) {
      this.location = location
    },

    destinationDeckSelected (deck) {
      this.destinationDeckId = deck.deckId
    },

    async transferTickets () {
      if (this.destinationDeckId === null || this.destinationDeckId === undefined) {
        this.setSnackError(this.$t('noDeckSelected'))
        return
      }
      const ticketIds = this.selected.map(t => t.ticketId)
      const requestObj = {
        ticketIds: this.consumptionMode === ConsumptionMode.TicketBased.value ? ticketIds : [],
        destinationDeckId: this.destinationDeckId,
        fromDeckId: this.deck.deckId,
        weight: this.consumptionMode === ConsumptionMode.WeightBased.value ? Math.round(this.weightToConsume * 2000) : null
      }
      await this.postTransferBatches(requestObj)
      this.refresh()
      this.closeDialog()
      this.$emit('trigger-refresh')
    },

    deckSelected (deck) {
      this.deck = deck
      this.refresh()
    },

    entitySelected (entity) {
      this.entity = entity
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    initializeHeaders () {
      this.columnSet = TicketHeaders.ticketConsumptionHeaders()
    },

    refresh () {
      this.selected = []
      this.weightToConsume = 0
      this.fetchTicketsForConsumption(this.deck.deckId)
    },

    ticketsConsumed () {
      this.closeDialog()
      this.$emit('trigger-refresh')
      this.refresh()
    },

    closeDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.destinationDeckId = null
      this.confirmTransferDialog = false
      this.confirmConsumptionDialog = false
    },

    openConfirmTransferDialog () {
      if (this.allLocations.length === 1 && this.allLocations[0].decks?.filter(deck => deck.status === 0).length === 1) {
        this.setSnackError(this.$t('oneDeckTransfer'))
        return
      }
      this.confirmTransferDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '500px', allowFullscreen: false })
    },

    openConfirmConsumptionDialog () {
      this.confirmConsumptionDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '500px', allowFullscreen: false })
    },

    getNetWeight (item) {
      const netWeight = item.inWeight - item.outWeight - item.defectWeight
      if (item.inWeight <= 0) {
        return this.$t('notAvailible')
      } else {
        return tonStringFromPounds(netWeight)
      }
    },

    calculateTicketMbf (ticket) {
      return Math.ceil((ticket.inWeight - ticket.outWeight - ticket.defectWeight) * this.productIdDictionary[ticket.product].conversionRateW2V)
    },

    formatMbf (mbf) {
      return numberWithCommas(mbf)
    },

    updateSort (byDesc, event) {
      if (byDesc === 'by') {
        this.pdfColumnSort = event
      } else if (byDesc === 'desc') {
        this.sortDirection = event
      }
    },

    async downloadPDF () {
      const tickets = this.getSortedTickets()

      await generatePdf({
        tickets: tickets,
        deck: this.deck.name,
        location: this.location.name,
        entity: this.entity.name,
        headers: this.columnSet.getColumns(),
        products: this.productIdDictionary
      })
    },

    getSortedTickets () {
      const tickets = this.ticketsForConsumption
      if (this.sortDirection || this.pdfColumnSort) {
        tickets.sort((a, b) => {
          const aValue = a[this.pdfColumnSort].toString()
          const bValue = b[this.pdfColumnSort].toString()
          return localeCompareAlphanumeric(aValue, bValue) * (this.sortDirection ? -1 : 1)
        })
      }
      return tickets
    },

    downloadCsv () {
      const tickets = this.getSortedTickets()
      const csvString = generateCsvString(
        tickets,
        this.columnSet.getColumns().map(h => ({
          text: h.text,
          value: t => this.csvValueOf(t, h)
        })),
        { emptyValue: this.$t('notAvailable') }
      )

      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${this.entity.name}_${this.location.name}_${this.deck.name}.csv`)
    },

    csvValueOf (ticket, header) {
      switch (header.value) {
        case 'netWeight':
          const netWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
          if (ticket.inWeight <= 0) {
            return 'N/A'
          } else {
            return tonStringFromPounds(netWeight)
          }
        case 'mbf':
          return this.calculateTicketMbf(ticket)
        case 'deckAssignedAt' || 'weighedOutAt':
          return utcToLocalDate(ticket[header.value], 'YYYY-MM-DDTHH:mm:SS')
        default:
          return ticket[header.value]
      }
    }
  }
}
</script>
