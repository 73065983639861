<template>
  <div :class="{ 'moving-arrow-mini': isMini, 'moving-arrow': !isMini }" :style="arrowStyle">
  </div>
</template>
<script>
export default {
  name: 'MovingArrow',
  props: {
    moveY: {
      type: Number,
      default: 0
    },
    isMini: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Styles to animate the arrow
     * @returns {{transform: string}}
     */
    arrowStyle () {
      return {
        transform: `translate3d(0px, ${this.moveY}px, 0px)`
      }
    }
  }
}
</script>
<style>
.moving-arrow {
  border-right: 17px solid #f9f6df;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  display: inline-block;
  position: absolute;
  left: 240px;
  top: 95px;
  transition: all 0.4s cubic-bezier(.32, 1.18, .56, .92), left 0.1s;
}
 .moving-arrow-mini {
  border-right: 17px solid #f9f6df;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  display: inline-block;
  position: absolute;
  left: 65px;
  top: 95px;
  transition: all 0.4s cubic-bezier(.32, 1.18, .56, .92), left 0.51s;
}
 @media (max-width: 992px) {
  .moving-arrow {
    display: none;
  }
}
</style>
