import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchAccountInsurancePolicies (_, accountId) {
    const path = `accounts/${accountId}/insurancepolicies`
    const response = await ApiServer.get(path)
    return response.data
  },

  async deleteAccountInsurancePolicy (_, identifiers) {
    const path = `accounts/${identifiers.accountId}/insurancepolicies/${identifiers.accountInsurancePolicyId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async createInsurancePolicy (_, insurancePolicyRequestObj) {
    const path = `accounts/${insurancePolicyRequestObj.identifiers.accountId}/insurancepolicies`
    const response = await ApiServer.post(path, insurancePolicyRequestObj.accountInsurancePolicy)
    return response.data
  },

  async updateInsurancePolicy (_, insurancePolicyRequestObj) {
    const accountId = insurancePolicyRequestObj.identifiers.accountId
    const accountInsurancePolicyId = insurancePolicyRequestObj.identifiers.accountInsurancePolicyId
    const path = `accounts/${accountId}/insurancepolicies/${accountInsurancePolicyId}`
    const response = await ApiServer.put(path, insurancePolicyRequestObj.accountInsurancePolicy)
    return response.data
  },

  async fetchRecentInsurancePolicyNames (_) {
    const path = 'accounts/insurancepolicynames'
    const response = await ApiServer.get(path)
    return response.data
  }
}
