import ApiServer from '@/utils/api/ApiServer.js'
import store from '@/store'
import Cookies from 'js-cookie'
import { LocalStorageKeys } from '../../../utils/constants'
import { initializePendo } from '@/utils/pendo.js'
import { logoutWithRedirect, hasAccount } from '@/msal-browser/Authentication'

export default {
  async initializeApplication (context) {
    if (!hasAccount()) { return }

    context.commit('setInitializing', true)

    try {
      const claims = (await ApiServer.get('applicationuser/userclaims')).data
      context.commit('setUserClaims', claims)

      const [companyInfo, userInfo, notifications] = (await Promise.all([
        ApiServer.get('companyInfo'),
        ApiServer.get('applicationUser'),
        ApiServer.get('notifications')
      ])).map(r => r.data)

      await store.dispatch('user-settings/getUserSettings')

      initializePendo(userInfo, companyInfo)

      context.commit('setCompanyInfo', companyInfo)
      context.commit('setUserInfo', userInfo)
      context.commit('notifications/setNotifications', notifications, { root: true })

      context.commit('setInitialized', true)
    } finally {
      context.commit('setInitializing', false)
    }
  },

  setLoginRedirectResult (context, result) {
    context.commit('setLoginRedirectResult', result)
  },

  logout (context, showPopup) {
    if (showPopup) {
      logoutWithRedirect()
    }

    Cookies.remove('bearerToken')
    localStorage.removeItem(LocalStorageKeys.MULTI_TENANT_CONFIGURATION)
    context.commit('setInitialized', false)
  },

  setInvalidAccount (context, account) {
    context.commit('setInvalidAccount', account)
  },

  async updateUserInfo (context, userInfo) {
    const path = `applicationUsers/${userInfo.applicationUserId}`
    const response = await ApiServer.put(path, userInfo)
    context.commit('setUserInfo', userInfo)
    return response.data
  },

  async fetchCompanyInfo (context) {
    const path = 'companyinfo'
    const response = await ApiServer.get(path)
    context.commit('setCompanyInfo', response.data)
    return response.data
  },

  async updateCompanyInfo (_, companyInfo) {
    const path = 'companyInfo'
    try {
      const response = await ApiServer.put(path, companyInfo)
      return response?.data
    } catch (e) {
      console.error(e)
    }
  },

  async fetchCompanyLocations (_) {
    const path = 'locations'
    const response = await ApiServer.get(path)
    return response.data
  },

  async createCompanyLocation (_, location) {
    const path = 'locations'
    const response = await ApiServer.post(path, location)
    return response.data
  },

  async deleteCompanyLocation (_, locationId) {
    const path = `locations/${locationId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async updateCompanyLocation (_, location) {
    const path = `locations/${location.locationId}`
    const response = await ApiServer.put(path, location)
    return response.data
  },

  async getUserInfo (context) {
    const response = await ApiServer.get('applicationUser')
    context.commit('setUserInfo', response.data)
    return response.data
  },

  async getAllUsers (context) {
    try {
      context.commit('setUsersLoading', true)
      const response = await ApiServer.get('applicationUsers')
      context.commit('setAllUsers', response.data)
      return response.data
    } finally {
      context.commit('setUsersLoading', false)
    }
  },

  async getAllForesterUsers (context) {
    const response = await ApiServer.get('applicationUsers', { params: { function: 'Forester' } })
    context.commit('setForesterUsers', response.data)
    return response.data
  },

  async fetchAllBusinessEntities (context) {
    context.commit('setEntitiesLoading', true)
    try {
      const path = 'businessEntities'
      const response = await ApiServer.get(path)
      context.commit('setBusinessEntities', response.data)
      return response.data.sort((a, b) => a.name.localeCompare(b.name))
    } finally {
      context.commit('setEntitiesLoading', false)
    }
  },

  async createBusinessEntity (context, entity) {
    context.commit('setEntitiesLoading', true)
    try {
      const path = 'businessEntities'
      const response = await ApiServer.post(path, entity)
      return response.data
    } finally {
      context.commit('setEntitiesLoading', false)
    }
  },

  async deleteBusinessEntity (context, businessEntityId) {
    context.commit('setEntitiesLoading', true)
    try {
      const path = `businessEntities/${businessEntityId}`
      const response = await ApiServer.delete(path)
      return response.data
    } finally {
      context.commit('setEntitiesLoading', false)
    }
  },

  async updateBusinessEntity (context, businessEntity) {
    context.commit('setEntitiesLoading', true)
    try {
      const path = `businessEntities/${businessEntity.businessEntityId}`
      const response = await ApiServer.put(path, businessEntity)
      return response.data
    } finally {
      context.commit('setEntitiesLoading', false)
    }
  },

  async fetchAllUserClaims (context) {
    const path = 'applicationusers/userclaims'
    const response = await ApiServer.get(path)

    // Refresh user's own stored roles
    const applicationUserId = context.getters.userInfo.applicationUserId
    const currentUserRoles = response.data.find(r => r.applicationUserId === applicationUserId).userClaims
    context.commit('setUserClaims', currentUserRoles)

    return response.data
  },

  async updateUserClaims (_, { id, claims }) {
    const path = `applicationusers/${id}/userclaims`
    const body = {
      applicationUserId: id,
      claims: claims
    }
    const response = await ApiServer.post(path, body)
    return response.data
  },

  async importUser (_, { preferredUsername, name, objectId, roles }) {
    // Create User
    const path = 'applicationuser'
    const body = {
      name: name,
      preferredUsername: preferredUsername,
      objectId: objectId
    }
    const response = (await ApiServer.post(path, body)).data
    const applicationUserId = response.applicationUserId

    if (roles.length <= 0) return response.data

    // Post claims
    const claimsPath = `applicationusers/${applicationUserId}/userclaims`
    const claimsPostBody = {
      applicationUserId: applicationUserId,
      claims: roles
    }
    const claimsPostResponse = await ApiServer.post(claimsPath, claimsPostBody)
    return claimsPostResponse.data
  },

  async refreshUserClaims (context) {
    const path = 'applicationuser/userclaims'
    const response = await ApiServer.get(path)
    context.commit('setUserClaims', response.data)
    return response.data
  }
}
