<template>
  <v-row justify="end">
    <v-col cols="auto">
      <slot name="prepend"></slot>
    </v-col>
    <v-col cols="auto">
      <v-row no-gutters>
        <DestinationSelector @item-selected="destinationSelected" :contractMode="contractMode" :immediate="immediate" class="mr-2"/>
        <TimeframeSelector @item-selected="timePeriodSelected" :timeLabelValue="timeLabel"/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { numberWithCommas } from '@/utils/NumericMutations'

export default {
  name: 'DestinationDateSelector',

  components: {
    TimeframeSelector: () => import('./TimeframeSelector.vue'),
    DestinationSelector: () => import('./DestinationSelector.vue')
  },

  props: {
    contractMode: {
      type: Object,
      required: true
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false
    },
    timeLabel: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    selectedObj: {
      sinceTime: undefined,
      untilTime: undefined,
      locationId: undefined,
      timePeriod: undefined,
      location: undefined,
      label: undefined,
      wasCustomClicked: false
    },
    initialTimePeriod: undefined,
    initialDestination: undefined
  }),

  methods: {
    numberWithCommas,

    doEmit () {
      if ((this.selectedObj.locationId || this.selectedObj.destinationAccountId !== undefined) && this.selectedObj.sinceTime) {
        this.$emit('item-selected', this.selectedObj)
      }
    },

    async timePeriodSelected (timePeriod) {
      this.selectedObj.timePeriod = timePeriod
      this.selectedObj.label = timePeriod.timeLabel
      this.selectedObj.sinceTime = timePeriod.sinceTime
      this.selectedObj.untilTime = timePeriod.untilTime
      this.selectedObj.wasCustomClicked = timePeriod === 'Custom'
      this.doEmit()
    },

    async destinationSelected (dest) {
      this.selectedObj.locationId = dest.locationId
      this.selectedObj.destinationAccountId = dest.destinationAccountId
      this.selectedObj.location = dest.location
      this.doEmit()
    }
  }
}
</script>
