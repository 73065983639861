import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchActivityTemplates (context) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor('activitytemplates')
      const response = await ApiServer.get(url)
      context.commit('setActivityTemplates', response.data)
      return response.data.sort((a, b) => a.name.localeCompare(b.name))
    } finally {
      context.commit('setLoading', false)
    }
  },

  async fetchActivityTemplateById (_, templateId) {
    const url = ApiServer.urlFor(`activityTemplates/${templateId}`)
    const response = await ApiServer.get(url)
    return response.data
  },

  async createActivityTemplate (context, activityTemplate) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor('activityTemplates')
      const response = await ApiServer.post(url, activityTemplate)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async deleteActivityTemplate (context, activityTemplateId) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor(`activityTemplates/${activityTemplateId}`)
      const response = await ApiServer.delete(url)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async updateActivityTemplate (context, updateRequest) {
    context.commit('setLoading', true)
    try {
      const activityTemplate = updateRequest.template
      const headers = { forceDownChanges: updateRequest.applyChangesDownstream }
      const url = ApiServer.urlFor(`activityTemplates/${activityTemplate.activityTemplateId}`)
      const response = await ApiServer.put(url, activityTemplate, { headers: headers })
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async updateTemplateDetail (_, { templateDetail, activityTemplateId }) {
    const url = ApiServer.urlFor([
      'activityTemplates',
      activityTemplateId,
      'activityTemplateDetails',
      templateDetail.activityTemplateDetailId
    ])
    const response = await ApiServer.put(url, templateDetail)
    return response.data
  },

  async deleteTemplateDetail (_, { activityTemplateDetailId, activityTemplateId }) {
    const url = ApiServer.urlFor([
      'activityTemplates',
      activityTemplateId,
      'activityTemplateDetails',
      activityTemplateDetailId
    ])
    return ApiServer.delete(url)
  },

  async createTemplateDetail (_, { templateDetail, activityTemplateId }) {
    const url = ApiServer.urlFor(`activityTemplates/${activityTemplateId}/activityTemplateDetails`)
    const response = await ApiServer.post(url, templateDetail)
    return response.data
  },

  async fetchTemplateDetails (_, activityTemplateId) {
    const url = ApiServer.urlFor(`activityTemplates/${activityTemplateId}/activityTemplateDetails`)
    const response = await ApiServer.get(url)
    return response.data
  },

  async fetchTemplateBundles (context) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor('templateBundles')
      const response = await ApiServer.get(url)
      context.commit('setTemplateBundles', response.data)
      return response.data.sort((a, b) => a.name.localeCompare(b.name))
    } finally {
      context.commit('setLoading', false)
    }
  },

  async createTemplateBundle (context, templateBundle) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor('templateBundles')
      const response = await ApiServer.post(url, templateBundle)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async deleteTemplateBundle (context, templateBundle) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor(`templateBundles/${templateBundle.templateBundleId}`)
      const response = await ApiServer.delete(url)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async updateTemplateBundle (context, requestObj) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor(`templateBundles/${requestObj.templateBundleId}/activityBuilders`)
      const response = await ApiServer.post(url, requestObj)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async updateActivityBuilder (context, requestObj) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor(`templateBundles/${requestObj.templateBundleId}/activityBuilders/${requestObj.activityBuilderId}`)
      const response = await ApiServer.put(url, requestObj)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async updateTemplateBundleName (context, requestObj) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor(`templateBundles/${requestObj.templateBundleId}`)
      const response = await ApiServer.put(url, requestObj)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async deleteTemplateFromBundle (context, { templateBundleId, activityBuilderId }) {
    context.commit('setLoading', true)
    try {
      const url = ApiServer.urlFor([
        'templateBundles',
        templateBundleId,
        'activityBuilders',
        activityBuilderId
      ])
      const response = await ApiServer.delete(url)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async fetchActivityTemplateStatsById (_, templateId) {
    const url = ApiServer.urlFor(`activityTemplates/${templateId}/stats`)
    const response = await ApiServer.get(url)
    return response.data
  }
}
