<template>
  <v-container fluid data-testid="decks-table">
    <v-row dense>
      <v-col cols="auto">
        <span class="mr-2 subtitle-1">
          {{$t('decks')}}
        </span>
        <Icon
          icon="mdi-plus"
          iconColor="secondary"
          :tooltipText="$t('creatingDeck')"
          :small="false"
          dataTestId="deck-create-button"
          @icon-clicked="openForm()"
          :disabled="!userAssignedClaim(UserClaims.InventoryManager)"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          :label="$t('consumptionMode')"
          color="secondary"
          :value="consumptionModeText"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <DataTable
          class="mt-n8"
          :items.sync="decks"
          :headers="headers"
          :showSearch="false"
          :showDense="false"
          :no-data-text="$t('noDecksForLocation')"
          :customCells="customTableCells"
        >
          <template #status="{item}">
            <Icon
            dataTestId="deck-open-icon"
            :icon="item.status === 1 ? 'mdi-close' : 'mdi-checkbox-marked-circle'"
            :iconColor="item.status === 1 ? 'black' : 'success'"
            :tooltipColor="item.status === 1 ? 'black' : 'success'"
            :tooltipText="item.status === 1 ? $t('closed') : $t('open')"
            :small="false"
            @icon-clicked="openForm(item)"/>
          </template>
          <template #on-deck="{item}">
            {{tonStringFromPounds(item.onDeck)}}
          </template>
          <template #name="{item}">
            <Icon
            v-if="item.isDefault"
            icon="mdi-star"
            iconColor="rgba(0,0,0,.87)"
            tooltipColor="black"
            :tooltipText="getTooltip(item)"
            />
            {{item.name}}
          </template>
          <template #value="{item}">
            {{formatMoney(item.value)}}
          </template>
          <template #consumption-mode="{item}">
            <span>{{consumptionModeFromInt(item.consumptionMode)}}</span>
          </template>
          <template #modified-at="{item}">
            <v-tooltip
              bottom
              :color="item.status === 0 ? 'green' : 'black'"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{utcToLocalDate(item.openClose)}}
                </span>
              </template>
              <span
                class="white--text"
              >
                {{ item.status === 0 ? 'Opened At' : 'Closed At' }}
              </span>
            </v-tooltip>
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-backup-restore"
            iconColor="error"
            dataTestId="deck-edit-button"
            :tooltipText="$t('reset')"
            :disabled="item.consumptionMode === 0 || !userAssignedClaim(UserClaims.InventoryManager)"
            @icon-clicked="openDeckResetForm(item)"/>
            <Icon
            icon="mdi-pencil"
            iconColor="success"
            dataTestId="deck-edit-button"
            :tooltipText="$t('edit')"
            @icon-clicked="openForm(item)"
            :disabled="!userAssignedClaim(UserClaims.InventoryManager)"/>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="close">
      <DeckForm
        v-if="deckForm == true"
        :propDeck="focusedDeck"
        :location="location"
        @close="close"
        @deck-mutated="deckMutated"
      />
      <DeckResetForm
        v-if="deckResetForm == true"
        :propDeck="focusedDeck"
        :location="location"
        @close="close"
        @deck-mutated="deckMutated"
      />
    </Dialog>
  </v-container>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { deckHeaders } from '@/headers/Deck.js'
import { ConsumptionMode, DeckContentType } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

export default {
  name: 'Decks',

  props: {
    location: {
      type: Object,
      required: true
    },
    decks: {
      type: Array,
      required: true
    },
    consumptionMode: {
      type: Number,
      required: true
    }
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    DeckForm: () => import('./DeckForm.vue'),
    DeckResetForm: () => import('./DeckResetForm.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    dialogId: `decks-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`,
    focusedDeck: undefined,
    deckForm: false,
    deckResetForm: false,
    loading: false,
    UserClaims
  }),

  computed: {
    headers () {
      return deckHeaders(this.$i18n.locale)
    },

    consumptionModeText () {
      return this.consumptionModeFromInt(this.consumptionMode)
    },

    customTableCells () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'name',
          value: 'name'
        },
        {
          slotName: 'on-deck',
          value: 'onDeck'
        },
        {
          slotName: 'value',
          value: 'value'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'modified-at',
          value: 'openClose'
        },
        {
          slotName: 'consumption-mode',
          value: 'consumptionMode'
        }
      ]
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    tonStringFromPounds,
    utcToLocalDate,
    userAssignedClaim,

    consumptionModeFromInt (mode) {
      return ConsumptionMode.fromInt(mode)
    },

    close () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.deckForm = false
      this.deckResetForm = false
      this.focusedDeck = undefined
    },

    deckMutated () {
      this.close()
      this.$emit('deck-mutated')
    },

    openForm (deck) {
      this.close()
      this.focusedDeck = deck
      this.deckForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '900' })
    },

    openDeckResetForm (deck) {
      this.close()
      this.focusedDeck = deck
      this.deckResetForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '1800' })
    },

    getTooltip (deck) {
      return this.$t('defaultCategoryDeck', { category: DeckContentType.fromInt(deck.contentType) })
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined ::v-deep(fieldset) {
  border-color: #D15F27;
}

.v-text-field--outlined ::v-deep(input) {
  color: black !important;
}
</style>
