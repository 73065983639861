export const arrayToObjectBy = (arr, key) => {
  const keyFn = (typeof key === 'function') ? key : elt => elt[key]
  const out = {}
  arr.forEach((elt) => { out[keyFn(elt)] = elt })
  return out
}

export const indexArrayBy = (arr, key) => {
  const keyFn = (typeof key === 'function') ? key : elt => elt[key]
  const out = {}
  arr.forEach((elt, i) => { out[keyFn(elt)] = i })
  return out
}

export const scatterToObject = (array, obj, options = _defaultScatterOptions) => {
  options = { ..._defaultScatterOptions, ...options }
  const out = _populateKeys(obj)
  array.forEach((item, index) => {
    const keyArrays = (options.multi)
      ? _matchItemToObjectMany(item, index, array, obj)
      : [_matchItemToObjectOnce(item, index, array, obj)]

    for (const keyArray of keyArrays) {
      if (keyArray !== undefined) _assignNested(out, keyArray, item)
    }
  })
  return out
}

const _defaultScatterOptions = {
  multi: false
}

export const padArrayTo = (length, padItem, items = { start: [], end: [] }) => {
  items.start ??= []
  items.end ??= []
  const itemLength = items.start.length + items.end.length
  if (itemLength >= length) return [...items.start, ...items.end].slice(0, length)
  return [
    ...items.start,
    ...new Array(length - itemLength).fill(padItem),
    ...items.end
  ]
}

const _populateKeys = (obj, pop = {}) => {
  for (const key of Object.keys(obj)) {
    pop[key] = (typeof obj[key] === 'object')
      ? _populateKeys(obj[key])
      : []
  }
  return pop
}

const _matchItemToObjectOnce = (item, index, array, obj, keys = []) => {
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object') {
      const m = _matchItemToObjectOnce(item, obj[key], [...keys, key])
      if (m !== undefined) return m
    } else if (obj[key](item, index, array)) return [...keys, key]
  }
  return undefined
}

const _matchItemToObjectMany = (item, index, array, obj, keys = []) => {
  const kkArray = []
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object') {
      const submatch = _matchItemToObjectMany(item, obj[key], [...keys, key])
      for (const m of submatch) {
        kkArray.push(m)
      }
    } else if (obj[key](item, index, array)) kkArray.push([...keys, key])
  }
  return kkArray
}

const _assignNested = (obj, keys, value) => {
  let o = obj
  const lastKey = keys[keys.length - 1]
  for (const k of keys.slice(0, keys.length - 1)) {
    o = o[k]
  }
  if (Array.isArray(o[lastKey])) o[lastKey].push(value)
  else o[lastKey] = [value]
}

export const hashString32 = (s, seed = 0) => {
  let hash = 0xdeafbead ^ seed
  for (let i = 0; i < s.length; i++) {
    hash = ((hash << 5) - hash) + s.charCodeAt(i)
    hash |= 0
  }
  const out = Math.abs(hash)
  return out & 0xffffffff
}

export const sumsFor = (items, sums) => {
  const keys = Array.isArray(sums) ? sums : [sums]

  const initValues = {}
  keys.forEach(key => { initValues[key] = 0 })
  items.forEach(item => {
    keys.forEach(key => {
      if (item[key] !== undefined) initValues[key] += item[key]
    })
  })
  return initValues
}

export const deepMapIf = (obj, mapIf, mapFn) => {
  const out = {}
  for (const key in obj) {
    if (mapIf(obj[key])) {
      const mapped = mapFn(obj[key], key)
      if (mapped !== undefined) out[key] = mapped
    } else if (typeof obj[key] === 'object') {
      out[key] = deepMapIf(obj[key], mapIf, mapFn)
    } else {
      out[key] = obj[key]
    }
  }
  return out
}

export const flatMapObjectIf = (obj, mapIf, mapFn) => {
  const out = []
  for (const key in obj) {
    if (mapIf(obj[key])) {
      const mapped = mapFn(obj[key], key)
      if (mapped !== undefined) out.push(mapped)
    } else if (typeof obj[key] === 'object') {
      const mapped = flatMapObjectIf(obj[key], mapIf, mapFn)
      for (const m of mapped) out.push(m)
    } else {
      out[key] = obj[key]
    }
  }
  return out
}

export const localeCompareAlphanumeric = (a, b) => a.localeCompare(b, 'en', { numeric: true })
