<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="mutateSetting(null, 'create')">
  <v-container fluid data-testid="setting-table">
    <v-dialog v-model="dialog" max-width="900">
      <SettingForm
        @close="close"
        @setting-mutated="settingMutated"
        v-if="editing || creating"
        :isEditing="editing"
        :propSetting="focusedSetting"
      />
    </v-dialog>
    <v-dialog width="400px" v-model="deleting">
      <ConfirmDelete
        @close="close"
        v-if="deleting"
        :title="$t('setting')"
        @delete="deleteItem"
        @cancel-delete="close"/>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <material-card
          color="secondary"
          :title="$t('settings')"
          :text="$t('settingDescription')"
          full-width
        >
          <DataTable
          :items="allSettings"
          :headers="headers"
          :customCells="customTableCells"
          :actions="tableActions"
          :loading.sync="loading"
          @new-setting="mutateSetting(null, 'create')"
          @refresh="refreshSettings"
          >
            <template #actions="slotProps">
              <v-icon
                small
                class="mr-2"
                color="success"
                data-testid="setting-edit-button"
                @click="mutateSetting(slotProps.item, 'edit')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                color="error"
                data-testid="setting-delete-button"
                @click="mutateSetting(slotProps.item, 'delete')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                >mdi-delete-forever</v-icon
              >
            </template>
          </DataTable>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SettingHeaders from '@/headers/Setting'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'SettingSettings',

  components: {
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    SettingForm: () => import('./SettingForm.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  created () {
    this.refreshSettings()
  },

  computed: {
    ...mapGetters('setting', ['allSettings']),

    headers () {
      return SettingHeaders.settingHeaders(this.$i18n.locale)
    },

    tableActions () {
      return [
        {
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          actionName: 'refresh'
        },
        {
          icon: 'mdi-plus',
          text: this.$t('newSetting'),
          actionName: 'new-setting',
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        }
      ]
    },
    customTableCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  data: () => ({
    editing: false,
    dialog: false,
    deleting: false,
    creating: false,
    dense: true,
    loading: false,
    search: '',
    focusedSetting: {},
    UserClaims
  }),

  watch: {
    deleting (val) {
      if (!val) {
        this.refreshSettings()
      }
    },

    dialog (val) {
      if (!val) {
        this.close()
      }
    }
  },

  methods: {
    ...mapActions('setting', ['fetchSettings', 'deleteSetting']),
    userAssignedClaim,

    refreshSettings () {
      this.loading = true
      this.fetchSettings(null)
        .finally(() => {
          this.loading = false
        })
    },

    mutateSetting (setting, mutationType) {
      this.focusedSetting = Object.assign({}, setting)

      switch (mutationType) {
        case 'delete':
          this.deleting = true
          break
        case 'edit':
          this.editing = true
          this.dialog = true
          break
        case 'create':
          this.focusedSetting = {}
          this.creating = true
          this.dialog = true
          break
        default:
          this.close()
          break
      }
    },

    async deleteItem () {
      await this.deleteSetting(this.focusedSetting.settingId)
      this.settingMutated()
    },

    settingMutated () {
      this.refreshSettings()
      this.close()
    },

    close () {
      this.deleting = false
      this.editing = false
      this.creating = false
      this.dialog = false
      this.focusedSetting = {}
    }
  }
}
</script>
