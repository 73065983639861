var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({on:{"parse-result":_vm.setLandownerMap},scopedSlots:_vm._u([{key:"preview-item.typeName",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.tractType.name))])]}},{key:"preview-item.logger",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.accountDictionary?.[`id-${item.loggerAccountId}`]?.name))])]}},{key:"preview-item.landOwner",fn:function({ item }){return [_c('span',[(_vm.landownerOwnersDictionary.get(item.name)?.length === 1)?_c('span',[_vm._v(_vm._s(_vm.accountDictionary?.[`id-${_vm.landownerOwnersDictionary.get(item.name)[0].accountId}`]?.name))]):(_vm.landownerOwnersDictionary.get(item.name)?.length === 0)?_c('span',[_vm._v(_vm._s(_vm.$t('notAvailable')))]):_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-icon',_vm._g({attrs:{"color":"black"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('span',{staticClass:"subtitle-1 white--text"},[_vm._v(" "+_vm._s(_vm.getOwnershipString(item))+" ")])])],1)])]}},{key:"preview-item.businessEntity",fn:function({ item }){return [(item.tractType.category !== _vm.enums.TractTypeCategory.Delivered.value)?_c('span',[_vm._v(" "+_vm._s(_vm.businessEntityDictionary?.[`id-${item.businessEntityId}`]?.name)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('notAvailable')))])]}},{key:"preview-item.coordinates",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(`${item.spot.latitude}, ${item.spot.longitude}`))])]}},{key:"preview-item.location",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(`${item.spot.countrySecondarySubdivision}, ${item.spot.countrySubdivision}`))])]}}])},'CsvImport',{
  parse: _vm.parse,
  preview: _vm.preview,
  upload: _vm.upload,
  exampleData: _vm.exampleData,
  entityName: _vm.entityName,
  rowName: _vm.rowName,
  disabled: _vm.disabled
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }