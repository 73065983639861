import Vuetify from 'vuetify'
import Vue from 'vue'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: '#D15F27',
        secondary: '#D15F27',
        tertiary: '#F9F6DF',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a',
        byproduct: '#363636'
      }
    }
  }
})
