<template>
  <v-container fluid class="mx-n3">
    <v-tabs
    v-model="currentSubModuleTab"
    slider-color="secondary"
    color="#888888"
    slider-size="4">
      <v-tab v-for="subModuleName in subModuleNames" :key="subModuleName">
        <span class="black--text" style="font-size: 16px;">
          {{subModuleName}}
        </span>
      </v-tab>
      <v-spacer/>
      <v-tab class="black--text" style="font-size: 16px">
        {{ $t('corrections') }}
      </v-tab>
      <v-tab>
        <span class="black--text" style="font-size: 16px">
          {{ $t('miscPayments') }}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <ProductionSummary
        v-if="currentSubModuleTab === 0"
        :productionSummaryObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <SettlementsByContract
        v-if="currentSubModuleTab === 1"
        :settlementsObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <SettlementsByPayee
        v-if="currentSubModuleTab === 2"
        :settlementsObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <PaymentRegister
        v-if="currentSubModuleTab === 3"
        :paymentRegisterObject="currentSubModule"
        :isExportBatch="isExportBatch"/>
      </v-tab-item>
      <v-tab-item>
        <TicketRegister
        v-if="currentSubModuleTab === 4"
        :ticketRegisterObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <SettlementCorrections
        v-if="currentSubModuleTab === 5"
        :corrections="currentSubModule"
        :contractMode="contractMode"/>
      </v-tab-item>
      <v-tab-item>
        <MiscSettlementPayments
        v-if="currentSubModuleTab === 6"
        :miscPayments="currentSubModule"
        :isExportBatch="isExportBatch"/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="secondary"/>
    </v-row>
    <v-row class="my-12 pa-12" v-if="showNoDataText" justify="center" align="center">
      <span class="headline black--text">
        {{ $t('noDataForSelection') }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SettlementKeys, SettlementSubKeys, SubKeyToTabMap } from '@/components/settlements/SettlementKeys.js'
import { settlementTractPaymentHeaders } from '@/headers/ExportBatch.js'
import { ContractModes } from '../../../utils/Enumerations'
export default {
  name: 'AccountsPayableModule',

  components: {
    PaymentRegister: () => import('@/components/settlements/accounts-payable-module/payment-register/PaymentRegister.vue'),
    SettlementsByContract: () => import('@/components/settlements/accounts-payable-module/settlements-by-contract/SettlementsByContract.vue'),
    SettlementsByPayee: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SettlementsByPayee.vue'),
    ProductionSummary: () => import('@/components/settlements/accounts-payable-module/production-summary/PayableProductionSummary.vue'),
    TicketRegister: () => import('@/components/settlements/accounts-payable-module/ticket-register/TicketRegister.vue'),
    SettlementCorrections: () => import('@/components/settlements/accounts-payable-module/other-modules/SettlementCorrections.vue'),
    MiscSettlementPayments: () => import('@/components/settlements/accounts-payable-module/other-modules/MiscSettlementPayments.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  watch: {
    currentSubModuleTab (val) {
      const moduleConfiguration = {
        moduleKey: SettlementKeys.AccountsPayable,
        subModuleKey: SettlementSubKeys.AP.ProductionSummary
      }

      moduleConfiguration.subModuleKey = SubKeyToTabMap.AP.Logs.find(kv => kv.Tab === val)?.Key ?? moduleConfiguration.subModuleKey

      this.setCurrentModule(moduleConfiguration)
    }
  },

  computed: {
    ...mapGetters('settlements', ['payableModule', 'currentModuleKey', 'currentSubModuleKey', 'loading', 'dateConfiguration']),

    contractMode () {
      return ContractModes[0]
    },

    isExportBatch () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined
    },

    subModuleNames () {
      return this.payableModule.moduleNames.slice(0, 5)
    },

    additionalSubmodules () {
      return this.payableModule.moduleNames.slice(5, 8)
    },

    currentSubModule () {
      return this.payableModule[this.currentSubModuleKey]
    },

    headers () {
      return {
        tractPaymentHeaders: settlementTractPaymentHeaders()
      }
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AP.SettlementsByContract:
          return this.currentSubModule.settlements.length === 0
        case SettlementSubKeys.AP.SettlementsByPayee:
          return this.currentSubModule.payees.length === 0
        case SettlementSubKeys.AP.PaymentRegister:
          return this.currentSubModule.payments.length === 0
        case SettlementSubKeys.AP.ProductionSummary:
          return this.currentSubModule.production.length === 0
        case SettlementSubKeys.AP.TicketRegister:
          return this.currentSubModule.tractTickets.length === 0 && this.currentSubModule.transferTickets.length === 0
        case SettlementSubKeys.AP.Corrections:
          return this.currentSubModule.length === 0
        case SettlementSubKeys.AP.MiscPayments:
          return this.currentSubModule.advances.length === 0 &&
            this.currentSubModule.accountPayments.length === 0 &&
            this.currentSubModule.tractPayments.length === 0
      }

      return true
    }
  },

  methods: {
    ...mapActions('settlements', ['setCurrentModule'])
  },

  created () {
    this.currentSubModuleTab = SubKeyToTabMap.AP.Logs.find(kv => kv.Key === this.currentSubModuleKey)?.Tab ?? 0
  }
}
</script>
