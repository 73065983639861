<template>
  <v-container fluid>
    <v-expansion-panels v-model="openPanel" accordion flat tile>
      <v-expansion-panel v-for="(recoveriesByPayPeriod, index) in verifiedAdvanceRecoveries" :key="index">
        <v-expansion-panel-header v-if="recoveriesByPayPeriod.recoveries.length > 0" >
          <v-row>
            <v-col cols="auto">
              <Icon
                dataTestId="recovery-period-status"
                :icon="getPeriodStatus(recoveriesByPayPeriod.payPeriod.status).icon"
                :tooltipText="getPeriodStatus(recoveriesByPayPeriod.payPeriod.status).tooltipText"
                :small="false"
                :iconColor="index === openPanel ? 'white': getPeriodStatus(recoveriesByPayPeriod.payPeriod.status).color"
                :tooltipColor="getPeriodStatus(recoveriesByPayPeriod.payPeriod.status).color"
              />
            </v-col>
            <v-col cols="auto">
              <span>{{ getPayPeriodString(recoveriesByPayPeriod.payPeriod) }}</span>
            </v-col>
          </v-row>
          <template #actions>
            {{ formatMoney(recoveredInPayPeriod(recoveriesByPayPeriod.recoveries)) }}
            <v-icon class="ml-2" :color="index === openPanel ? 'white' : ''">mdi-chevron-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          :class="recoveriesByPayPeriod.recoveries.length > 0 ? '' : 'white'"
        >
          <RecoveryTable
            :recoveries="recoveriesByPayPeriod.recoveries"
            @edit-recovery="editRecovery"
            @delete-recovery="deleteRecovery"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { mapGetters } from 'vuex'
export default {
  name: 'RecoveriesPanels',

  components: {
    RecoveryTable: () => import('./RecoveriesTable.vue'),
    Icon: () => import('../../helper/Icon.vue')
  },
  data: () => ({
    openPanel: 0
  }),
  computed: {
    ...mapGetters('recovery', ['advanceRecoveries']),
    verifiedAdvanceRecoveries () {
      return this.advanceRecoveries.length === 0 ? [{ recoveries: [] }] : this.advanceRecoveries
    }
  },
  methods: {
    formatMoney,
    editRecovery (item) {
      this.$emit('edit-recovery', item)
    },
    deleteRecovery (item) {
      this.$emit('delete-recovery', item)
    },
    getPayPeriodString (payPeriod) {
      const { startDate, endDate } = payPeriod
      const convertedEndDate = new Date(endDate)
      convertedEndDate.setDate(convertedEndDate.getDate() - 1)
      return `${utcToLocalDate(startDate)} - ${utcToLocalDate(convertedEndDate)}`
    },
    recoveredInPayPeriod (recoveries) {
      return recoveries.reduce((accum, recovery) => accum + recovery.amount, 0)
    },
    getPeriodStatus (status) {
      switch (status) {
        case 0:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case 1:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case 2:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case 3:
          return {
            color: 'black',
            tooltipText: this.$t('exported'),
            icon: 'mdi-export'
          }
        default:
          return {
            color: 'black',
            tooltipText: this.$t('error'),
            icon: 'mdi-exclamation'
          }
      }
    }
  }
}
</script>
