import i18n from '@/i18n'

export default {
  recoveryHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('type'),
        value: 'recoveryMethod'
      },
      {
        sortable: true,
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2'
      },
      {
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('paidAt'),
        value: 'paidAt'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
