<template>
  <v-container grid-list-lg class="ma-n2">
    <DatePicker
    dataTestId="ticket-filter-start"
    :dateLabel="$t('startDate')"
    :startDate="startDate"
    :disabled="disabled"
    @valid-date="(val) => {isValidStartDate = val}"
    @date-picked="startDateChosen"/>
    <DatePicker
    dataTestId="ticket-filter-end"
    :dateLabel="$t('endDate')"
    :startDate="endDate"
    :disabled="disabled"
    @valid-date="(val) => {isValidEndDate = val}"
    @date-picked="endDateChosen"/>
    <v-btn class="secondary"
      data-testid="ticket-filter-submit"
      :disabled="isButtonDisabled"
      v-if="showApply"
      @click="applyClicked">
      Apply
    </v-btn>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { localToUTC } from '@/utils/DateFormatter.js'
import moment from 'moment'
export default {
  name: 'TimeFilter',

  props: {
    showApply: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propStartDate: {
      type: String,
      default: null
    },
    propEndDate: {
      type: String,
      default: null
    }
  },

  components: {
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  data: () => ({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    isValidStartDate: true,
    isValidEndDate: true
  }),

  created () {
    if (this.propStartDate && this.propEndDate) {
      this.startDate = new Date(this.propStartDate)
      this.endDate = new Date(this.propEndDate)
    } else {
      const today = new Date()
      today.setDate(today.getDate() - 30)
      this.startDate = today.toISOString()
    }
  },

  computed: {
    isButtonDisabled () {
      return !(this.isValidStartDate && this.isValidEndDate)
    }
  },

  watch: {
    startDate () {
      if (!this.showApply) {
        this.$emit('dates-chosen', {
          startDate: localToUTC(moment(this.startDate).format()),
          endDate: localToUTC(moment(this.endDate).hour(23).minutes(59).seconds(59).format(), true)
        })
      }
    },

    endDate () {
      if (!this.showApply) {
        this.$emit('dates-chosen', {
          startDate: localToUTC(moment(this.startDate).format()),
          endDate: localToUTC(moment(this.endDate).hour(23).minutes(59).seconds(59).format(), true)
        })
      }
    }
  },

  methods: {
    ...mapMutations('ticket', ['setDateFilter', 'applyFilter']),
    startDateChosen (date) { (this.startDate = date) },
    endDateChosen (date) { this.endDate = date },

    applyClicked () {
      const endDate = moment(this.endDate).hour(23).minutes(59).seconds(59).format()
      this.applyFilter({
        name: 'dateRange',
        startDate: localToUTC(new Date(this.startDate).toISOString()),
        endDate: endDate
      })
    }
  }
}
</script>
