<template>
  <v-menu
    bottom
    offset-y
    left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mx-n1">
        <v-icon
        medium
        v-bind="attrs"
        color="secondary"
        data-testid="tract-detail-actions"
        v-on="on">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="$emit('download-csv')">
        <v-avatar left>
          <v-icon color="black">mdi-file-delimited-outline</v-icon>
        </v-avatar>
        <v-list-item-title>
          {{$t('downloadCSV')}}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="importTickets(false)" :disabled="!userAssignedClaim(UserClaims.TicketManager)">
        <v-avatar left>
          <v-img
          :src="cloudNewPath"
          height="32"
          width="32"
          contain
          />
        </v-avatar>
        <v-list-item-title>
          {{importLabel}}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="importTickets(true)" :disabled="!userAssignedClaim(UserClaims.TicketManager)">
        <v-avatar left>
          <v-img
          :src="cloudEditPath"
          height="32"
          width="32"
          contain
          />
        </v-avatar>
        <v-list-item-title>
          {{updateLabel}}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { userAssignedClaim } from '../../utils/ClaimUtility'
import { UserClaims } from '../../utils/Enumerations'
export default {
  name: 'ContractContextMenu',

  props: {
    contractMode: {
      type: Object,
      default: () => ({ value: 0 })
    }
  },

  data: () => ({
    cloudNewPath: 'img/cloud-new.svg',
    cloudEditPath: 'img/cloud-edit.svg',
    UserClaims
  }),

  computed: {
    importLabel () {
      switch (this.contractMode.value) {
        case 0: return this.$t('importLogsTickets')
        case 1: return this.$t('importByproductTickets')
        case 2: return this.$t('importTransferTickets')
      }

      return this.$t('importLogsTickets')
    },

    updateLabel () {
      switch (this.contractMode.value) {
        case 0: return this.$t('updateLogsTickets')
        case 1: return this.$t('updateByproductTickets')
        case 2: return this.$t('updateTransferTickets')
      }

      return this.$t('importLogsTickets')
    }
  },

  methods: {
    userAssignedClaim,
    importTickets (isUpdate) {
      this.$emit('import-tickets', isUpdate)
    }
  }
}
</script>
