<template>
  <v-autocomplete
    color="black"
    item-color="secondary"
    ref="productAutocomplete"
    v-model="chosenProduct"
    :label="$t('product')"
    item-text="name"
    item-value="name"
    return-object
    clearable
    :data-testid="dataTestId"
    :loading="productsLoading"
    :items="products"
    :disabled="disabled"
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon class="ml-n1 mr-1" @click="getProducts" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EnumValue, ContractType } from '../../utils/Enumerations'

export default {
  name: 'ProductAutocomplete',

  props: {
    productId: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    contract: { type: Object, default: undefined },
    isDetailForm: { type: Boolean, default: false },
    limitToContract: { type: Boolean, default: false },
    woodType: {
      type: EnumValue,
      required: false,
      default: undefined
    },
    excludedProducts: { type: Array, default: function () { return [] } },
    dataTestId: { type: String, default: 'product-autocomplete' },
    restrictToContractProducts: { type: Boolean, default: false }
  },

  data: () => ({
    products: [],
    chosenProduct: undefined
  }),

  watch: {
    chosenProduct: {
      handler (val) {
        this.$emit('product-chosen', val)
      },
      deep: true
    },

    contract: {
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getProducts()
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('product', ['allProducts', 'productsLoading']),
    productRequestObj () {
      const isByproducts = this.contract?.type === ContractType.ByproductSale.value || this.contract?.type === ContractType.ByproductPurchase.value
      return { includeByProducts: isByproducts, includeProduction: !isByproducts }
    }
  },

  async created () {
    await this.getProducts()
  },

  methods: {
    ...mapActions('product', ['fetchProducts', 'fetchProductById']),

    getContractId () {
      if (this.contract === undefined) { return }
      if (this.isDetailForm === true) { return }
      if (this.contract.acceptsAnyLoad) { return }
      return this.contract.contractId
    },

    async getProducts () {
      this.products = []
      const products = (await this.fetchProducts({
        ...this.productRequestObj,
        contractId: this.getContractId()
      })).filter(p => this.woodType === undefined || p.woodType === this.woodType.value)

      if (this.productId !== -1 &&
        this.productId !== undefined &&
        this.productId !== null &&
        !products.some(p => p.productId === this.productId) &&
        !this.restrictToContractProducts
      ) {
        products.push(await this.fetchProductById({ productId: this.productId }))
      }
      const findProductOnContract = (contract, targetProductId) =>
        (contract.products?.map(p => p.productId) ?? contract.productIds).find(id => id === targetProductId)

      this.products = products
        .filter(product => !(this.excludedProducts.includes(product.name) && this.productId !== product.productId))
        .filter(product => (this.limitToContract && this.contract?.type === 3) ? findProductOnContract(this.contract, product.productId) !== undefined : true)

      this.chosenProduct = this.products.find(product => this.productId === product.productId)
      if (this.chosenProduct === undefined && this.products.length === 1) {
        this.chosenProduct = this.products[0]
      }
    }
  }
}
</script>
