<template>
  <v-card>
    <v-card-title class="secondary headline white--text">
      <span class="ml-2">{{$t('biDataReplication')}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card hover>
              <v-card-title>
                <span>{{pumpStatus}}</span>
              </v-card-title>
              <v-card-text>
                <div>
                  <pre class="black--text" :style="{ fontSize: '16px', fontWeight: 'bold' }">{{detailString}}</pre>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="secondary" :disabled="!canRetryBiPump" @click="$emit('click:retry')">{{$t('retry')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ExportBatchBiPumpStatus } from '@/utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
import { UserClaims } from '../../../utils/Enumerations'
export default {
  name: 'BIDataReplicationDetailDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    exportBatch: {
      type: Object,
      required: true
    }
  },

  computed: {
    pumpStatus () {
      return ExportBatchBiPumpStatus.fromInt(this.exportBatch.secondaryBiPumpStatus)
    },

    canRetryBiPump () {
      return (this.exportBatch.biPumpStatus === ExportBatchBiPumpStatus.Success.value && this.exportBatch.secondaryBiPumpStatus === ExportBatchBiPumpStatus.Error.value) && userAssignedClaim(UserClaims.GlobalAdmin)
    },

    detailString () {
      if (this.exportBatch.secondaryBiPumpStatus === ExportBatchBiPumpStatus.Pending.value) {
        return this.$t('biDataReplicationWaitingOrInProgress')
      }
      try {
        const ds = JSON.stringify(JSON.parse(this.exportBatch.secondaryBiPumpDetail || undefined), null, 2).trim()
        return ds
      } catch (e) {
        if (e instanceof SyntaxError) {
          return this.exportBatch.secondaryBiPumpDetail || undefined
        }
        console.error(e)
        return undefined
      }
    }
  }
}
</script>
