<template>
<v-row dense no-gutters>
  <v-col align-self="center" cols="12" xs="12" sm="1" md="1" lg="1">
    <Icon
      iconColor="white"
      :icon="contractTypeIcons[contract.type]"
      :small="false"
      :large="true"
      :tooltipText="contractTypeFromInt(contract.type)"
    />
  </v-col>
  <v-col v-if="isLogsContract" cols="12" xs="12" sm="11" md="11" lg="11">
    <v-row dense no-gutters>
      <v-col style="margin-bottom: -10px" cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-crosshairs-gps"
          small
          :tooltipText="$t('tract')"
        />
        <span class="header-contract">
          {{contract.tract || $t('notAvailable')}}
        </span>
      </v-col>
      <v-col style="margin-bottom: -10px" cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-domain"
          small
          :tooltipText="$t('destination')"
        />
        <span class="header-contract">
          {{contract.destination}}
        </span>
      </v-col>
      <v-col style="margin-bottom: -10px" cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-map-marker-circle"
          small
          :tooltipText="$t('setting')"
        />
        <span class="header-contract">
          {{contract.setting || $t('notAvailable')}}
        </span>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-account"
          small
          :tooltipText="$t('account')"
        />
        <span class="header-contract">
          {{contract.account}}
        </span>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else cols="12" xs="12" sm="11" md="11" lg="11">
    <v-row dense no-gutters>
      <v-col style="margin-bottom: -10px" cols="12" v-if="!!contract.fromAccount">
        <Icon
        iconColor="white"
        icon="mdi-account-arrow-right"
        small
        :tooltipText="$t('fromAccount')"
      />
        <span class="header-contract">
          {{contract.fromAccount || $t('notAvailable')}}
        </span>
      </v-col>
      <v-col style="margin-bottom: -10px" cols="12" v-if="!!contract.tract">
        <Icon
        iconColor="white"
        icon="mdi-crosshairs-gps"
        small
        :tooltipText="$t('tract')"
      />
        <span class="header-contract">
          {{contract.tract || $t('notAvailable')}}
        </span>
      </v-col>
      <v-col style="margin-bottom: -10px" cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-account"
          small
          :tooltipText="$t('account')"
        />
        <span class="header-contract">
          {{contract.account}}
        </span>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <Icon
          iconColor="white"
          icon="mdi-domain"
          small
          :tooltipText="$t('destination')"
        />
        <span class="header-contract">
          {{contract.destination}}
        </span>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import { ContractType } from '@/utils/Enumerations.js'

export default {
  name: 'ActivityFormContractHeader',

  props: {
    contract: {
      type: Object,
      default: undefined
    }
  },

  components: {
    Icon: () => import('../helper/Icon.vue')
  },

  computed: {
    contractTypeIcons () {
      return ['mdi-alpha-p-circle-outline', 'mdi-alpha-w-circle-outline', 'mdi-alpha-l-circle-outline', 'mdi-alpha-b-circle-outline', 'mdi-transfer', 'mdi-alpha-b-circle-outline']
    },
    isLogsContract () {
      return this.contract.type === 0 || this.contract.type === 1
    }
  },

  methods: {
    contractTypeFromInt: (type) => ContractType.fromInt(type)
  }
}
</script>

<style scoped>
  .header-contract {
    font-size: 16px;
    color: white;
  }
</style>
