import ApiServer from '@/utils/api/ApiServer.js'
import { TractContent } from '@/model/Tract.js'

export default {
  async fetchTractContents (context, tractId) {
    const path = `tracts/${tractId}/tractContents`
    const response = await ApiServer.get(path)
    const tractContents = response.data.map(tc => new TractContent(tc))
    context.commit('setTractContents', tractContents)
    return tractContents
  },
  async deleteTractContent (context, ids) {
    const path = `tracts/${ids.tractId}/tractContents/${ids.tractContentId}`
    const response = await ApiServer.delete(path)
    context.commit('removeTractContent', ids.tractContentId)
    return response.data
  },
  async createTractContent (context, tractContent) {
    const parsedTractContent = {
      productId: parseInt(tractContent.productId),
      tractId: parseInt(tractContent.tractId),
      expected: parseFloat(tractContent.expected),
      expectedMBF: parseFloat(tractContent.expectedMBF),
      adjustment: parseFloat(tractContent.adjustment),
      adjustmentMBF: parseFloat(tractContent.adjustmentMBF),
      pricePerTon: parseFloat(tractContent.pricePerTon),
      pricePerMBF: parseFloat(tractContent.pricePerMBF)
    }

    const path = `tracts/${tractContent.tractId}/tractcontents`

    const response = await ApiServer.post(path, parsedTractContent)
    context.commit('addTractContent', response.data)
    return response
  },
  async updateTractContent (context, tractContent) {
    const path = `tracts/${tractContent.tractId}/tractContents/${tractContent.tractContentId}`
    const response = await ApiServer.put(path, tractContent)
    return response
  }
}
