export const customPayableCells =
  [
    {
      slotName: 'rate',
      value: 'rate'
    },
    {
      slotName: 'payout',
      value: 'payout'
    },
    {
      slotName: 'weight',
      value: 'weight'
    },
    {
      slotName: 'product',
      value: 'product'
    },
    {
      slotName: 'actions',
      value: 'actions'
    },
    {
      slotName: 'base-cost-rate',
      value: 'baseCost.rate'
    }
  ]

export const customMiscPaymentCells = [
  {
    slotName: 'createdAt',
    value: 'createdAt',
    dateFormat: 'MM/DD/YYYY'
  },
  {
    slotName: 'payDate',
    value: 'payDate',
    dateFormat: 'MM/DD/YYYY'
  },
  {
    slotName: 'status',
    value: 'payPeriodStatus'
  },
  {
    slotName: 'amount',
    value: 'amount'
  },
  {
    slotName: 'actions',
    value: 'actions'
  }
]

export const customRecoveryCells =
  [
    {
      slotName: 'balance',
      value: 'balance'
    },
    {
      slotName: 'recovered',
      value: 'recovered'
    }
  ]

export const customAdvanceCells =
  [
    {
      slotName: 'amount',
      value: 'amount'
    }
  ]

export const customCorrectionCells =
  [
    {
      slotName: 'activity',
      value: 'activity'
    }
  ]
