<template>
  <div>
    <v-tooltip bottom :color="color" v-if="hasCertifications">
      <template #activator="{on}">
        <div
          v-on="on"
          @click="getTooltipData"
          @mouseleave="resetTooltipData"
          style="padding-right: 0.20em; display: inline-block"
        >
          <v-icon v-if="certificationStatus === CertificationStatus.Multiple.value"
            :small="small"
            :color="color"
            :class="margin"
          >
            mdi-dots-horizontal
          </v-icon>
          <v-icon v-else
            :small="small"
            :color="color"
            :class="margin"
          >
            mdi-certificate-outline
          </v-icon>
          <v-icon
            v-if="certificationStatus === CertificationStatus.Expiring.value"
            :small="small"
            color="warning"
            style="padding-left: 0.20em"
          >
            mdi-alert
          </v-icon>
        </div>
      </template>
      <span class="subtitle-1 white--text" style="white-space: pre-line">
        <span v-if="certifications">
          {{$t('accountCertifications')}}:
          <span v-for="(cert,i) in certifications" :key="i" :class="tooltipColor(cert)">
            <br>
            {{cert.name}}
          </span>
        </span>
        <span v-else-if="loading">
          {{$t('accountCertifications')}}:
          <v-progress-linear indeterminate color="#fff" background-color="#ddd" rounded/>
        </span>
        <span v-else-if="certificationStatus === CertificationStatus.Active.value">
          {{$t('accountIsCertified')}}
        </span>
        <span v-else-if="certificationStatus === CertificationStatus.Expiring.value">
          {{$t('accountCertificationExpiring')}}
        </span>
        <span v-else>
          {{$t('accountCertificationMultiple')}}
        </span>
      </span>
    </v-tooltip>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { CERTIFICATION_EXPIRY_WARNING_DAYS } from '@/utils/constants.js'
import { CertificationStatus } from '@/utils/Enumerations.js'

export default {
  name: 'AccountName',

  props: {
    accountId: {
      type: Number,
      default: -1
    },
    certificationStatus: {
      type: Number,
      default: CertificationStatus.None.value
    },
    small: {
      type: Boolean,
      default: true
    },
    margin: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'black'
    },
    hasExpiringCertification: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    certifications: undefined,
    loading: false,
    CertificationStatus
  }),

  computed: {
    hasCertifications () {
      return [
        CertificationStatus.Active.value,
        CertificationStatus.Expiring.value,
        CertificationStatus.Multiple.value
      ].includes(this.certificationStatus)
    }
  },

  methods: {
    ...mapActions('certification', ['fetchAccountCertificationsByAccountId']),

    async getTooltipData () {
      if (!this.hasCertifications || this.certificationTooltip !== undefined || this.accountId === -1) {
        return
      }
      const setLoading = setTimeout(() => {
        this.loading = true
      }, 100)
      this.certifications = await this.fetchAccountCertificationsByAccountId(this.accountId)
      clearTimeout(setLoading)
      this.loading = false
    },

    resetTooltipData (event) {
      setTimeout(() => {
        this.certifications = undefined
      }, 200)
      event.target.blur()
      this.loading = false
    },

    getCertificationStatus (certification) {
      const now = moment.utc()
      const criticalDate = moment.utc().add(CERTIFICATION_EXPIRY_WARNING_DAYS, 'd')
      const effectiveDate = certification.effectiveDate ? moment.utc(certification.effectiveDate) : undefined
      const expirationDate = certification.expirationDate ? moment.utc(certification.expirationDate) : undefined
      if (effectiveDate?.isAfter(now)) {
        return CertificationStatus.Inactive.value
      }
      if (expirationDate?.isBetween(now, criticalDate)) {
        return CertificationStatus.Expiring.value
      }
      if (expirationDate?.isBefore(now)) {
        return CertificationStatus.Expired.value
      }
      return CertificationStatus.Active.value
    },

    tooltipColor (certification) {
      switch (this.getCertificationStatus(certification)) {
        case CertificationStatus.Inactive.value:
          return 'grey--text'
        case CertificationStatus.Active.value:
          return 'white--text'
        case CertificationStatus.Expiring.value:
          return 'warning--text'
        case CertificationStatus.Expired.value:
          return 'error--text'
      }
    }
  }
}
</script>
