import { ContractType } from '@/utils/Enumerations.js'

export const selectBreakpoint = (values, breakpoint) => {
  let currentBp = values.xs ?? values.sm ?? values.md ?? values.lg ?? values.xl
  for (const bp of ['xs', 'sm', 'md', 'lg', 'xl']) {
    currentBp = values[bp] ? values[bp] : currentBp
    if (bp === breakpoint) {
      return currentBp
    }
  }
}

export async function tickTock () {
  for (const fn of arguments) {
    await this.$nextTick()
    fn()
  }
}

export const uniqueDialogId = tag => `${tag}-${Math.floor(Math.random() * 0xFFFFFF).toString(16)}`

export const colorClassForContractType = (contractType, includeWhiteText = true) => {
  const whiteTextString = includeWhiteText ? ' white--text' : ''
  let color = ''
  switch (contractType) {
    case ContractType.ByproductSale.value:
    case ContractType.ByproductPurchase.value:
      color = 'byproduct'
      break
    case ContractType.Transfer.value:
      color = 'grey'
      break
    case ContractType.Production.value:
    case ContractType.WoodsSale.value:
    default:
      color = 'secondary'
  }

  return `${color} ${whiteTextString}`
}

export const colorClassForContractMode = (contractMode, includeWhiteText = true) => {
  const whiteTextString = includeWhiteText ? ' white--text' : ''
  let color = ''
  switch (contractMode) {
    case 1:
      color = 'byproduct'
      break
    case 2:
      color = 'grey'
      break
    default:
      color = 'secondary'
      break
  }
  return `${color} ${whiteTextString}`
}

export const panelBorderForContractType = (contractType) => {
  return `border: 3px solid ${hexCodeForContractType(contractType)};`
}

export const hexCodeForContractType = (contractType) => {
  switch (contractType) {
    case ContractType.ByproductPurchase.value:
    case ContractType.ByproductSale.value:
      return '#363636'
    case ContractType.Transfer.value:
      return '#9E9E9E'
    case ContractType.Production.value:
    case ContractType.WoodsSale.value:
    default:
      return '#D15F27'
  }
}

export const hexCodeForContractMode = (contractMode) => {
  switch (contractMode) {
    case 1:
      return '#363636'
    case 2:
      return '#9E9E9E'
    default:
      return '#D15F27'
  }
}

export const getMenuContainerStyle = (w, h) => {
  const maxHeight = h - 200
  const maxWidth = w - 100
  const minWidth = w > 500 ? 400 : w - 100

  const styleObject = w > 550 && h > 800
    ? {
      width: '400px'
    }
    : {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      maxHeight: `${maxHeight}px`,
      maxWidth: `${maxWidth}px`,
      minWidth: `${minWidth}px`
    }
  return styleObject
}
