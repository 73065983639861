<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="headline white--text">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      testId="defect-form-save-btn"
      @submit="saveChanges">
        <v-container fluid grid-list-md>
          <v-row dense>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
              ref="firstField"
              color="black"
              data-testid="defect-def-name"
              :rules="[rules.required]"
              :label="$t('name')"
              v-model="defect.name"
              maxlength="64"
              counter/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
              color="black"
              data-testid="defect-def-lbs"
              @click="defect.defectLBS = ''"
              :label="$t('defectPounds')"
              :rules="[rules.positiveDecimal, rules.validWeightPounds]"
              v-model="defect.defectLBS"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-autocomplete
              color="black"
              item-color="secondary"
              data-testid="defect-def-cat"
              :items="categories"
              :label="$t('defectCategory')"
              :rules="[rules.required]"
              v-model="defect.category"
              auto-select-first
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
              v-model="defect.defaultPercentage"
              :rules="[rules.positiveInteger, rules.validDefectDefaultPercentage]"
              :label="$t('defaultPercentage')"
              ></v-text-field>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
              <v-checkbox
              v-model="defect.isActive"
              color="secondary"
              :label="$t('isActive')"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import * as rulesData from '@/utils/rules'
import { mapActions } from 'vuex'
import { DefectCategory } from '@/utils/Enumerations.js'
export default {
  name: 'DefectDefinitionForm',

  props: {
    propDefect: {
      type: Object,
      default: undefined
    },
    locationId: {
      type: Number,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    rules: rulesData.default.rules,
    maxPounds: rulesData.MAX_TICKET_WEIGHT_POUNDS,
    categories: DefectCategory.names,
    defect: {
      name: '',
      isActive: true,
      defectLBS: 0,
      defaultPercentage: 0,
      category: 0
    }
  }),

  computed: {
    title () {
      if (this.propDefect) {
        return this.$t('editingDefect', { defectName: this.propDefect.name })
      } else {
        return this.$t('creatingDefect')
      }
    }
  },

  created () {
    if (this.propDefect) {
      this.defect = JSON.parse(JSON.stringify(this.propDefect))
      this.defect.defectLBS = parseInt(this.defect.defectLBS)
      this.defect.category = this.defectCategoryFromInt(this.defect.category)
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  methods: {
    ...mapActions('defects', ['createLocationDefect', 'updateLocationDefect', 'fetchDefectsForLocation']),
    defectCategoryFromInt: (x) => DefectCategory.fromInt(x),
    defectCategoryToInt: (x) => DefectCategory.toInt(x),
    async saveChanges () {
      if (!this.validateDefect()) {
        return
      }

      const requestObj = JSON.parse(JSON.stringify(this.defect))
      requestObj.defectLBS = parseInt(this.defect.defectLBS)
      requestObj.category = this.defectCategoryToInt(this.defect.category)
      requestObj.locationId = this.locationId
      if (this.propDefect) {
        await this.updateLocationDefect(requestObj)
        this.$emit('defect-mutated')
      } else {
        await this.createLocationDefect(requestObj)
        this.$emit('defect-mutated')
      }
    },

    closeForm () {
      this.$emit('close')
    },

    validateDefect () {
      const { name, defaultPercentage, defectLBS } = this.defect
      if (name.trim() === '') {
        this.setSnackError(this.$t('defectNameError'))
        return false
      }

      if (parseInt(defectLBS) > this.maxPounds || parseInt(defectLBS) < 0) {
        this.setSnackError(this.$t('weightNotValidPounds'))
        return false
      }

      if (parseInt(defaultPercentage) > 100 || parseInt(defaultPercentage) < 0) {
        this.setSnackError(this.$t('invalidPercentage'))
        return false
      }

      return true
    }
  }
}
</script>
