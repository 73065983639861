import ApiServer from './api/ApiServer'
import store from '@/store/index.js'
import axios from 'axios'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import { BlobServiceClient } from '@azure/storage-blob'
import { SasTokenLocations } from './constants'

class TicketImageClient {
  type = undefined
  constructor (type) {
    this.type = type
  }

  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.TICKET_IMAGES_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = await this.getEndpointByType(this.type)
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.TICKET_IMAGES_SAS, JSON.stringify(response))
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.TICKET_IMAGES_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  async getEndpointByType (type) {
    if (type === 'ticket') {
      return 'companyinfo/blobinfo/ticketimages'
    } else if (type === 'defect') {
      return 'companyinfo/blobinfo/ticketdefectimages'
    } else {
      return null
    }
  }

  async getBlobLocationByType (type, ticketId, defectId) {
    if (type === 'ticket') {
      return `${this.location}/${ticketId}/`
    } else if (type === 'defect') {
      return `${this.location}/${ticketId}/${defectId}/`
    } else {
      return null
    }
  }

  async getImageUrls (ticketId, defectId = null) {
    const urls = []
    try {
      const containerClient = await this.getContainerClient()
      var blobLocation = await this.getBlobLocationByType(this.type, ticketId, defectId)
      const blobs = containerClient.listBlobsByHierarchy('/', { prefix: blobLocation })
      let blobItem = await blobs.next()
      while (!blobItem.done) {
        urls.push(createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.TICKET_IMAGES_SAS)).data[0].blobSasUri, this.container, blobItem.value.name))
        blobItem = await blobs.next()
      }
    } catch (e) {
      console.error(e)
      store.dispatch('snackbar/setSnackError', `Could not get images for ticket #${ticketId}`)
    }
    return urls
  }

  async deleteImage (imageUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (imageUrl instanceof URL)
        ? decodeURI(imageUrl.pathname)
        : decodeURI(new URL(decodeURI(imageUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = await containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.delete()
    } catch (e) {
      const errorMessage = 'Could not delete ticket image'
      store.dispatch('snackbar/setSnackError', errorMessage)
      throw e
    }
  }
}

export const ticketImageClient = new TicketImageClient('ticket')
export const defectImageClient = new TicketImageClient('defect')

class RecognitionResultClient {
  client = undefined
  table = undefined

  async initConnection () {
    const response = await ApiServer.get(ApiServer.urlFor('companyinfo/tableinfo/recognitionresults'))
    localStorage.setItem(SasTokenLocations.TABLE_INFO_SAS, JSON.stringify(response.data[0].tableSasUri))
  }

  async getAnalysisData (ticketId) {
    await this.initConnection()
    const url = new URL(JSON.parse(localStorage.getItem(SasTokenLocations.TABLE_INFO_SAS)))
    url.searchParams.append('$filter', (`${ticketId}`.match(/^9+$/)) ? `RowKey ge '${ticketId}-' and RowKey lt '${ticketId}0-'` : `RowKey ge '${ticketId}-' and RowKey lt '${ticketId + 1}-'`)
    const response = await axios.get(url, {
      headers: {
        'x-ms-date': new Date(Date.now()).getUTCDate(),
        Accept: 'application/json;odata=nometadata, */*'
      }
    })
    const dataArray = response.data?.value ?? []
    return dataArray.filter(d => d.AnalysisData !== undefined).map(d => JSON.parse(d.AnalysisData))
  }
}

const recognitionResultClient = new RecognitionResultClient()

class TicketRecognitionClient {
  async getRecognitionData (ticketId, includeRecognitionData = true) {
    const imageUrls = await ticketImageClient.getImageUrls(ticketId)
    if (includeRecognitionData) {
      const reqAnalysisData = await recognitionResultClient.getAnalysisData(ticketId)
      const analysisData = reqAnalysisData.map(d => Array.isArray(d) ? d[0] : d)
      const findValidAnalysisData = url => {
        const data = analysisData.find(({ resourceUri }) => {
          const ru = new URL(resourceUri)
          return ru.origin === url.origin && ru.pathname === url.pathname
        })
        return (data && typeof data === 'object' && data.version === 1)
          ? data
          : {}
      }
      if (analysisData) {
        return imageUrls
          .map(u => ({
            ...findValidAnalysisData(u),
            resourceUri: u.toString()
          }))
      }
    }

    return imageUrls.map(u => ({ resourceUri: u.toString() }))
  }
}
export const ticketRecognitionClient = new TicketRecognitionClient()
