export default {
  setTractContentLoadingStatus (state, status) {
    state.tractContentLoadingStatus = status
  },
  setTractContents (state, tractContents) {
    state.tractContents = tractContents
  },
  addTractContent (state, tractContent) {
    state.tractContents.push(tractContent)
  },
  removeTractContent (state, tractContentId) {
    state.tractContents = state.tractContents.filter(a => a.tractContentId !== tractContentId)
  },
  updateTractContent (state, tractContent) {
    const index = state.tractContents.findIndex(a => a.tractContentId === tractContent.tractContentId)
    if (index !== -1) {
      Object.assign(state.tractContents[index], tractContent)
    }
  }
}
