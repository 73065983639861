export default {
  setExportData (state, exportData) {
    state.exportData = {
      tickets: createMeaningfulObjects(exportData.ticketExports, 'Tickets'),
      ticketFinances: createMeaningfulObjects(exportData.financialExports, 'Ticket Financials'),
      ticketRecoveries: createMeaningfulObjects(exportData.recoveryExports, 'Recoveries'),
      ticketCorrections: createMeaningfulObjects(exportData.ticketCorrectionExports, 'Corrections'),
      correctionFinancialExports: createMeaningfulObjects(exportData.correctionFinancialExports, 'Correction Financials'),
      correctionRecoveryExports: createMeaningfulObjects(exportData.correctionRecoveryExports, 'Correction Recoveries')
    }
  },

  setLoading (state, loading) {
    state.loading = loading
  }
}

const createMeaningfulObjects = (exportObjects, resourceName) => {
  const meaningfulObject = {
    name: resourceName,
    resources: {},
    isEmpty: true
  }
  exportObjects.forEach(exportObject => {
    meaningfulObject.isEmpty = false

    const { label, blobSasUri, container, location } = exportObject
    const labelItems = label.split('/')

    meaningfulObject.resources[labelItems[1]] = createResourceUrl(blobSasUri, container, location)
  })

  return meaningfulObject
}

const createResourceUrl = (blobSasUri, container, location) => {
  const queryParameterStartIndex = blobSasUri.indexOf('?')
  return `${blobSasUri.slice(0, queryParameterStartIndex)}${container}/${location}${blobSasUri.slice(queryParameterStartIndex, blobSasUri.length)}`
}
