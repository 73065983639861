export default {
  setReceivableLoadingStatus (state, status) {
    state.receivableLoadingStatus = status
  },
  setReceivables (state, receivables) {
    state.receivables = receivables
  },
  addReceivable (state, receivable) {
    state.receivables.push(receivable)
  },
  updateReceivable (state, receivable) {
    const index = state.receivables.findIndex(d => d === receivable.receivableId)
    if (index !== -1) {
      Object.assign(state.receivables[index], receivable)
    }
  },
  removeReceivable (state, receivableId) {
    state.receivables = state.receivables.filter(d => d.receivableId !== receivableId)
  }
}
