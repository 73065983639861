<template>
  <v-card>
    <v-card-title class="secondary white--text headline">
      <span>{{this.$t('downloadCSV')}}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close-csv-export')"
      :tooltipText="$t('close')"
      dataTestId="close-csv-export-button"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <v-container
        class="px-0"
        fluid
      >
        <v-radio-group v-model="chosenOption">
          <v-radio
            v-for="(option, i) in options"
            :key="option"
            color="secondary"
            :label="option"
            :value="i"
          ></v-radio>
        </v-radio-group>
        <span v-if="downloadUnavailable" class="error--text">{{$t('settlementCsvUnavailable')}}</span>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <Icon
      v-if="downloadUnavailable"
      :tooltipText="$t('refresh')"
      :small="false"
      iconColor="black"
      tooltipColor="black"
      icon="mdi-refresh"
      @icon-clicked="refreshExportBatch"
      />
      <v-spacer/>
      <v-btn class="secondary" @click="exportCSV" :disabled="downloadUnavailable">
        {{this.$t('download')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CSVExport',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    isByProducts: {
      type: Boolean,
      required: true
    },
    isAdjustingEntries: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    options: ['Accounts Payable', 'Accounts Receivable', 'Accruals', 'All'],
    chosenOption: 0,
    exportBatch: null
  }),

  computed: {
    ...mapGetters('settlements', ['dateConfiguration']),

    downloadUnavailable () {
      return !this.isAdjustingEntries && this.exportBatch?.exportsSavedOn === null
    }
  },

  created () {
    if (!this.isAdjustingEntries) {
      this.refreshExportBatch()
    }
  },

  methods: {
    ...mapActions('settlements', ['fetchSettlementCSV']),
    ...mapActions('export-batch', ['fetchExportBatchById']),

    async refreshExportBatch () {
      this.exportBatch = await this.fetchExportBatchById(this.dateConfiguration.exportBatch)
    },

    async exportCSV () {
      if (this.isAdjustingEntries) {
        this.$emit('downloadCsv', this.chosenOption)
        return
      }

      if (this.downloadUnavailable) {
        return
      }

      const requestObj = {
        ...this.dateConfiguration,
        isByproduct: this.isByProducts,
        type: this.getRequestType(this.chosenOption)
      }

      if (this.chosenOption !== 3) {
        this.fetchSettlementCSV(requestObj)
        return
      }

      // Fetch All
      requestObj.type = 'apcsvexport'
      await this.fetchSettlementCSV(requestObj)
      requestObj.type = 'arcsvexport'
      await this.fetchSettlementCSV(requestObj)
      requestObj.type = 'jecsvexport'
      await this.fetchSettlementCSV(requestObj)
    },

    getRequestType (type) {
      switch (type) {
        case 0:
          return 'apcsvexport'
        case 1:
          return 'arcsvexport'
        case 2:
          return 'jecsvexport'
        default:
          return undefined
      }
    }
  }
}
</script>
