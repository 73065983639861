import ApiServer from '@/utils/api/ApiServer.js'

export default {
  initializeFilter (context) {
    context.commit('initializeFilter')
  },

  removeFilter (context, filter) {
    const currentFilter = JSON.parse(JSON.stringify(context.getters.filter))
    currentFilter[filter.key] = filter.defaultValue
    context.commit('setFilter', currentFilter)
  },
  setFilter (context, filter) {
    context.commit('setFilter', filter)
  },
  async fetchDeviceRegistrations (context) {
    const response = await ApiServer.get('deviceRegistrations')
    context.commit('setDeviceRegistrations', response.data)
    return response.data
  },
  async getRegistration (context, registrationId) {
    const response = await ApiServer.get(`deviceRegistrations/${registrationId}`)
    return response.data
  },
  async updateRegistration (context, registration) {
    const response = await ApiServer.put(`deviceRegistrations/${registration.deviceRegistrationId}`,
      {
        Identifier: registration.identifier.toString(),
        deviceRegistrationId: registration.deviceRegistrationId,
        ExpiresOn: registration.updatedExpiresOn
      },
      {
        headers: {
          'Content-Type': 'application/json',
          identifier: registration.identifier
        }
      }
    )
    return response.data
  },
  async deleteRegistration (context, registration) {
    const response = await ApiServer.delete(`deviceRegistrations/${registration.deviceRegistrationId}`, {
      headers: {
        identifier: registration.identifier
      }
    })
    return response.data
  }
}
