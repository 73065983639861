export default {

  setContactLoadingStatus (state, status) {
    state.contactLoadingStatus = status
  },
  setContacts (state, contacts) {
    state.contacts = contacts
  },
  addContact (state, contact) {
    state.contacts.push(contact)
  },
  removeContact (state, contactId) {
    state.contacts = state.contacts.filter(contact => contact.contactId !== contactId)
  },
  updateContact (state, contact) {
    const index = state.contacts.findIndex(v => v.contactId === contact.contactId)
    if (index !== -1) {
      Object.assign(state.contacts[index], contact)
    }
  }
}
