<template>
  <v-container fluid>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      @change="fileChanged"/>
    <DataTable
    :items.sync="files"
    :headers="headers"
    :actions="tableActions"
    :customCells="customCells"
    @refresh="loadFiles"
    @upload="uploadFile">
      <template #name="{item}">
        {{ item.name }}
      </template>
      <template #type="{item}">
        {{ item.type }}
      </template>
      <template #date="{item}">
        {{ dateFromMilliseconds(item.date) }}
      </template>
      <template #actions="{item}">
        <Icon
          icon="mdi-download"
          iconColor="success"
          :tooltipText="$t('downloadFile')"
          @icon-clicked="downloadFile(item)"
        />
        <Icon
          v-if="isEditing"
          icon="mdi-delete-forever"
          iconColor="error"
          :tooltipText="$t('deleteTractFileConfirmation')"
          @icon-clicked="deleteBtnClicked(item)"/>
      </template>
      <template #user="{item}">
        {{ userFromId(item.user) }}
      </template>
      <template #footer v-if="isEditing">
        {{fileLimitFooter}}
      </template>
    </DataTable>
    <v-dialog v-model="deleteConfirmation" width="400px">
      <ConfirmDialog
      :title="$t('deleteTractFile')"
      :body="$t('deleteTractFileBody', {filename: fileForDelete.name, tractName: tract.name})"
      :confirmText="$t('deleteTractFileConfirmation')"
      @confirm="deleteFile(fileForDelete)"
      @cancel="deleteConfirmation = false"/>
  </v-dialog>
  </v-container>
</template>
<script>
import TractHeaders from '@/headers/Tract.js'
import { tractFilesClient } from '../../../utils/TractFiles'
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'TractFiles',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    tract: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    columnSet: null,
    files: [],
    selectedFile: null,
    deleteConfirmation: false,
    fileForDelete: {
      name: undefined,
      type: undefined,
      ref: undefined
    },
    isSelecting: false,
    allowedFileMimes: undefined,
    users: []
  }),

  computed: {
    headers () {
      return TractHeaders.fileHeaders()
    },

    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        (this.isEditing) ? {
          actionName: 'upload',
          icon: 'mdi-plus',
          text: this.$t('uploadFile'),
          disabled: this.files.length >= 10
        } : undefined
      ].filter(a => a !== undefined)
    },

    customCells () {
      return [
        'name',
        'type',
        'user',
        'date',
        'actions'
      ].map(n => ({ slotName: n, value: n }))
    },

    fileLimitFooter () {
      return this.files.length + ' / 10 ' + this.$t('files')
    }
  },

  async created () {
    this.loadFiles()
    this.users = await this.getAllUsers()
    this.allowedFileMimes = new Set([
      'application/pdf',
      'application/msword',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
      'image/png',
      'image/jpeg',
      'image/heic',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.ms-word'
    ])
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),
    async loadFiles () {
      const loadedFiles = await tractFilesClient.getFiles(this.tract)
      this.files = loadedFiles
    },

    async uploadToBlob () {
      if (!this.selectedFile) return
      if (!this.validateUpload(this.selectedFile)) return
      await tractFilesClient.uploadFile(this.tract, this.selectedFile)
      this.loadFiles()
      this.selectedFile = undefined
    },

    async deleteFile (file) {
      await tractFilesClient.deleteFile(file.ref)
      this.loadFiles()
      this.deleteConfirmation = false
    },

    async downloadFile (file) {
      const fetchedFile = await fetch(file.ref)
      const blob = await fetchedFile.blob()
      saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
    },

    deleteBtnClicked (file) {
      this.deleteConfirmation = true
      this.fileForDelete = file
    },

    uploadFile () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },

    fileChanged (e) {
      this.selectedFile = e.target.files[0]
      this.uploadToBlob()
      this.isSelecting = false
      this.$refs.uploader.value = ''
    },

    validateUpload (file) {
      const limit = 1000 * 1000 * 3
      if (file.size > limit) { // Exceeds 3MB
        this.setSnackError(this.$t('fileTooLargeSnack', { limit: '3MB' }))
        this.selectedFile = undefined
        return false
      }
      if (!this.allowedFileMimes.has(file.type)) {
        this.setSnackError(this.$t('fileMimeNotAllowedSnack'))
        this.selectedFile = undefined
        return false
      }
      return true
    },

    dateFromMilliseconds (milliseconds) {
      const d = new Date(+milliseconds)
      return utcToLocalDate(d)
    },

    userFromId (id) {
      return this.users.find(u => u.applicationUserId.toString() === id.toString())?.name
    }
  }
}
</script>
