<template>
  <v-select
    v-model="entity"
    :items="entities"
    :label="label || $t('entity')"
    :menu-props="{ bottom: true, offsetY: true }"
    item-value="businessEntityId"
    item-text="name"
    color="secondary"
    return-object
    style="width: 300px; max-width: 300px;"
    @change="entitySelected"
    :persistent-hint="persistentHint"
    :hint="hint"
    :no-data-text="$t('noEntitiesAvailable')"
  >
  </v-select>
</template>

<script>
export default {
  name: 'EntitySelector',

  props: {
    entities: {
      type: Array,
      required: true
    },
    initialEntity: {
      type: Object
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    persistentHint: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    entity: undefined
  }),

  computed: {
    entityName () {
      if (!this.entity?.businessEntityId && !this.entity?.name) {
        return 'SELECT ENTITY'
      } else {
        return this.entity.name
      }
    }
  },

  watch: {
    initialEntity (val) {
      if (val !== undefined && this.entity === undefined) {
        this.entity = val
      }
    }
  },

  created () {
    if (this.initialEntity) {
      this.entity = this.initialEntity
    }

    if (this.entities.length === 1 || this.entity === undefined) {
      this.entitySelected(this.entities[0])
    }
  },

  methods: {
    entitySelected (val) {
      this.$emit('entity-selected', val)
      this.entity = val
    }
  }
}
</script>
