<template>
  <v-autocomplete
    v-model="selectedContract"
    :label="$t('contract')"
    :data-testid="dataTestId"
    :items="filteredContracts"
    :filter="filterItems"
    :no-data-text="$t('noOpenContracts')"
    :disabled="disabled"
    :hint="hint"
    color="black"
    item-value="contractId"
    return-object
  >
    <template #append-outer>
      <v-icon @click="refreshContracts(false)" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
    <template #item="{item}">
      <v-container>
        <v-row dense no-gutters>
          <v-col cols="6" v-for="(field, i) in contractFields" :key="i">
            <Icon
              :dataTestId="`contract-auto-${field.name}`"
              :icon="field.icon"
              :tooltipText="$t(field.name)"
              iconColor="grey"
              tooltipColor="secondary"
              propagateClick
            />
            <span>
              {{item[field.name] ?? 'N/A'}}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-divider/>
        </v-row>
      </v-container>
    </template>
    <template #selection="{item}">
      <div class="mr-2" v-for="(field, i) in contractFields" :key="i">
        <v-icon small class="mb-1 mr-1">{{field.icon}}</v-icon>
        <span>{{item[field.name] ?? 'N/A'}}</span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex'
import { ContractStatus, ContractApprovalStatus } from '@/utils/Enumerations.js'
export default {
  name: 'ContractAutocomplete',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    contractId: { type: Number },
    tractId: { type: Number },
    disabled: { type: Boolean, default: false },
    dataTestId: { type: String, default: 'contract-complete' },
    hint: { type: String, default: '' },
    contractMode: { type: Object, default: () => ({ value: 0 }) },
    includePaused: { type: Boolean, default: true },
    includeDraft: { type: Boolean, default: true },
    includeActiveApprovals: { type: Boolean, default: true },
    validStatuses: { type: Array, default: () => [ContractStatus.Open.value] },
    contractType: { type: Number, default: undefined }
  },

  data: () => ({
    selectedContract: undefined,
    contracts: []
  }),

  watch: {
    selectedContract (val) {
      if (val === undefined || val === null) {
        this.$emit('contract-chosen', undefined)
        return
      }

      this.$emit('contract-chosen', val)
    }
  },

  computed: {
    filteredContracts () {
      const contractClause = (c) => {
        const allowedStatus = this.validStatuses.includes(c.status)
        const includeDraft = this.includeDraft || !c.isDraft
        const includePaused = this.includePaused || !c.paused
        const includeActiveApprovals = this.includeActiveApprovals ||
          (c.approvalStatus === ContractApprovalStatus.Approved.value || c.approvalStatus === null)

        const isPropContract = c.contractId === this.contractId
        const handleSameType = this.contractMode.value === 1 && this.contractType !== undefined
          ? (c.type === this.contractType)
          : true

        return (allowedStatus && includeDraft && includePaused && includeActiveApprovals && handleSameType) || isPropContract
      }

      return this.contracts.filter(contractClause)
    },

    mode () {
      return this.contractMode.value
    },

    contractFields () {
      return (this.contractMode?.value === 0)
        ? [
          { name: 'tract', icon: 'mdi-crosshairs-gps' },
          { name: 'destination', icon: 'mdi-domain' },
          { name: 'setting', icon: 'mdi-map-marker-circle' },
          { name: 'account', icon: 'mdi-account' }
        ]
        : [
          { name: 'fromAccount', icon: 'mdi-account-arrow-right' },
          { name: 'account', icon: 'mdi-account' },
          { name: 'destination', icon: 'mdi-domain' }
          // TODO: IH - Restore after reintroducting TSBP
          // { name: 'tract', icon: 'mdi-crosshairs-gps' }
        ]
    }
  },

  created () {
    this.refreshContracts(true)
  },

  methods: {
    ...mapActions('contract', ['fetchContractsForAutocomplete', 'getContract']),
    ...mapActions('tract', ['fetchContractsForTract']),

    async refreshContracts (firstFetch) {
      this.contracts = this.tractId === undefined
        ? await this.fetchContractsForAutocomplete({
          includeOpen: true,
          includeOnHold: false,
          includeClosed: false,
          includeExpired: false,
          limitToDraft: false,
          limitToInternal: false,
          limitToPaused: false,
          includeProduction: this.mode === 0,
          includeWoodsSale: this.mode === 0,
          includeByProduct: this.mode === 1,
          includeTransfer: this.mode === 2,
          includeByproductPurchase: this.mode === 1
        })
        : await this.fetchContractsForTract(this.tractId)

      if (firstFetch) {
        this.setInitialContract()
      }
    },

    filterItems (item, queryText) {
      const search = queryText.toLowerCase()
      return this.contractFields.some(cf => (item?.[cf.name] ?? '').toLowerCase().indexOf(search) > -1)
    },

    async setInitialContract () {
      this.selectedContract = (this.contractId)
        ? this.contracts.find(c => c.contractId === this.contractId)
        : (this.filteredContracts.length === 1)
          ? this.filteredContracts[0]
          : undefined

      if (this.selectedContract === undefined && this.contractId) {
        const contract = await this.getContract(this.contractId)
        this.contracts.unshift(contract)
        this.selectedContract = contract
      }
    }
  }
}
</script>

<style scoped>
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: blue !important;
}
</style>
