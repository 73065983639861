<template>
  <v-container fluid data-testid="imports-view">
    <material-card
      color="secondary"
      :title="$t('imports')"
      :text="$t('importsDescription')"
      full-width
    >
      <v-tabs v-model="currentTab" class="mt-4" color="secondary">
        <v-tab :disabled="!userAssignedClaim(UserClaims.AccountManager)">{{$t('accounts')}}</v-tab>
        <v-tab :disabled="!userAssignedClaim(UserClaims.TemplateManager)">{{$t('activityTemplates')}}</v-tab>
        <v-tab :disabled="!userAssignedClaim(UserClaims.ContractManager)">{{$t('tracts')}}</v-tab>
        <v-tab :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)">{{$t('products')}}</v-tab>
        <v-tab :disabled="!userAssignedClaim(UserClaims.InventoryManager)">{{$t('logCounts')}}</v-tab>
        <v-tab-item disabled>
          <AccountImports/>
        </v-tab-item>
        <v-tab-item disabled>
          <ActivityTemplateImports/>
        </v-tab-item>
        <v-tab-item disabled>
          <TractImports/>
        </v-tab-item>
        <v-tab-item disabled>
          <ProductImports/>
        </v-tab-item>
        <v-tab-item disabled>
          <TicketLogCountImports/>
        </v-tab-item>
      </v-tabs>
    </material-card>
  </v-container>
</template>

<script>
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
export default {
  name: 'Imports',

  components: {
    AccountImports: () => import('./AccountImports.vue'),
    ActivityTemplateImports: () => import('./ActivityTemplateImports.vue'),
    TractImports: () => import('./TractImports.vue'),
    ProductImports: () => import('./ProductImports.vue'),
    TicketLogCountImports: () => import('./TicketLogCountImports.vue')
  },

  data: () => ({
    currentTab: 0,
    UserClaims
  }),

  created () {
    this.currentTab = this.getDefaultTab()
  },

  methods: {
    userAssignedClaim,
    getDefaultTab () {
      if (userAssignedClaim(UserClaims.AccountManager)) return 0
      if (userAssignedClaim(UserClaims.TemplateManager)) return 1
      if (userAssignedClaim(UserClaims.ContractManager)) return 2
      if (userAssignedClaim(UserClaims.GlobalAdmin)) return 3
      return undefined
    }
  }
}
</script>
