import { UserClaims } from './Enumerations'
export const
  globalNavigationShortcuts = {
    initiatingKey: 'g',
    shortcuts: [
      {
        id: 'dashboard',
        shortcut: 'g+b',
        route: 'dashboard',
        key: 'b',
        name: 'Go to Dashboard'
      },
      {
        id: 'contracts',
        shortcut: 'g+c',
        route: 'contracts',
        key: 'c',
        name: 'Go to Contracts'
      },
      {
        id: 'tickets',
        shortcut: 'g+t',
        route: 'tickets',
        key: 't',
        name: 'Go to Tickets'
      },
      {
        id: 'tracts',
        shortcut: 'g+r',
        route: 'tracts',
        key: 'r',
        name: 'Go to Tracts'
      },
      {
        id: 'tract-analysis',
        shortcut: 'g+y',
        route: 'tract-analysis',
        key: 'y',
        name: 'Go to Tract Analysis'
      },
      {
        id: 'accounts',
        shortcut: 'g+a',
        route: 'accounts',
        key: 'a',
        name: 'Go to Accounts'
      },
      {
        id: 'advances',
        shortcut: 'g+d',
        route: 'balances',
        key: 'd',
        name: 'Go to Balances'
      },
      {
        id: 'current-payables',
        shortcut: 'g+p',
        route: 'current-payables',
        key: 'p',
        name: 'Go to Current Payables'
      },
      {
        id: 'settings',
        shortcut: 'g+s',
        route: 'settings',
        key: 's',
        name: 'Go to Settings'
      },
      {
        id: 'settlements',
        shortcut: 'g+m',
        route: 'settlements',
        key: 'm',
        name: 'Go to Settlements'
      }
    ]
  }

export const
  settingsNavigationShortcuts = {
    initiatingKey: 'g',
    shortcuts: [
      {
        id: 'templates',
        shortcut: 'g+T',
        route: 'templates',
        key: 'T',
        name: 'Go to Templates'
      },
      {
        id: 'pay-period',
        shortcut: 'g+A',
        route: 'pay-period',
        key: 'A',
        name: 'Go to Calendar'
      },
      {
        id: 'products',
        shortcut: 'g+P',
        route: 'products',
        key: 'P',
        name: 'Go to Products'
      },
      {
        id: 'settings',
        shortcut: 'g+S',
        route: 'settings',
        key: 'S',
        name: 'Go to Tract Settings'
      },
      {
        id: 'certifications',
        shortcut: 'g+C',
        route: 'certifications',
        key: 'C',
        name: 'Go to Certifications'
      },
      {
        id: 'tracttypes',
        shortcut: 'g+Y',
        route: 'tracttypes',
        key: 'Y',
        name: 'Go to Tract Types'
      },
      {
        id: 'byproducts',
        shortcut: 'g+B',
        route: 'byproducts',
        key: 'B',
        name: 'Go to Byproducts'
      },
      {
        id: 'tags',
        shortcut: 'g+G',
        route: 'tags',
        key: 'G',
        name: 'Go to Tags'
      },
      {
        id: 'imports',
        shortcut: 'g+M',
        route: 'imports',
        key: 'M',
        name: 'Go to Imports'
      },
      {
        id: 'downloads',
        shortcut: 'g+D',
        route: 'downloads',
        key: 'D',
        name: 'Go to Downloads'
      },
      {
        id: 'companyinfo',
        shortcut: 'g+I',
        route: 'companyinfo',
        key: 'I',
        name: 'Go to Company Info'
      },
      {
        id: 'devices',
        shortcut: 'g+V',
        route: 'devices',
        key: 'V',
        name: 'Go to Devices'
      }
    ]
  }

export const
  commonShortcuts = [
    {
      id: 'n-shortcut',
      shortcut: 'n',
      key: 'n',
      name: 'Create New...'
    }
  ]

export const
  ticketsShortcuts = {
    initiatingKey: 'o',
    shortcuts: [
      {
        id: 'posting',
        shortcut: 'o+p',
        key: 'p',
        name: 'Open Ticket Posting'
      },
      {
        id: 'advanced-search',
        shortcut: 'o+s',
        key: 's',
        name: 'Open Advanced Search'
      },
      {
        id: 'inventory-operations',
        shortcut: 'o+i',
        key: 'i',
        name: 'Open Inventory Operations',
        requireContractMode: { value: 0 },
        requiredClaim: UserClaims.InventoryManager
      },
      {
        id: 'reconciliation',
        shortcut: 'o+r',
        key: 'r',
        name: 'Open Ticket Reconciliation',
        requiredClaim: UserClaims.TicketManager
      }
    ]
  }
