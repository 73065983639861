export default {
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setLocationDefects (state, locationDefects) {
    state.locationDefects = locationDefects
  },
  setTicketDefects (state, ticketDefects) {
    state.ticketDefects = ticketDefects
  }
}
