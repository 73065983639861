import TicketQuery from '@/model/TicketParams'

export default {
  allTickets: ({ tickets }) => tickets,
  ticketsLoading: ({ loading }) => loading,
  postedTickets: ({ postedTickets }) => postedTickets,
  filter: ({ filters, contractMode }) => {
    if (filters.advancedSearch.active) {
      return filters.advancedSearch.query
    }
    return new TicketQuery({
      inTransit: filters.inTransit.active,
      weighedIn: filters.weighedIn.active,
      weighedOut: filters.weighedOut.active,
      readyForPayment: filters.readyForPayment.active,
      posted: filters.posted.active,
      dateRange: filters.dateRange.active ? filters.dateRange : undefined,
      limitInternal: filters.internal.active,
      contractId: filters.contract.contractId,
      tractId: filters.tract.tractId,
      phrase: filters.phrase.phrase,
      hasImages: filters.missingImages.active ? false : undefined,
      contractMode: contractMode.value
    })
  },
  filters: ({ filters }) => filters,
  contractMode: ({ contractMode }) => contractMode
}
