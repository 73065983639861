<template>
  <v-card
    :max-height="maxHeight ? maxHeight : (dialog ? '90vh' : '600px')"
    :height="height ? height : (dialog ? '90vh' : '525px')"
    elevation="0"
    style="display: flex; flex-flow: column; flex: 1; overflow: auto"
  >
    <v-card-title class="secondary white--text" v-if="dialog">
      {{title}}
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text style="flex: 1; overflow: hidden; min-height: 0; flex-basis: min-content" class="pa-0 ma-0">
      <div
      ref="container"
      style="max-height: 100%; max-width: 100%; height: 100%; text-align: center; position: relative;"
      @mouseenter="handleContainerMouseOver"
      @mouseleave="handleContainerMouseOut"
      >
        <canvas
        ref="canvas"
        width="400"
        height="400"
        draggable
        style="user-select: none;"
        @click="handleCanvasClick"
        @mousewheel="handleCanvasMouseWheel"
        @dragstart="handleDragStart"
        @drag="handleDrag"/>
        <div
        ref="focusControls"
        v-if="containerHover"
        :style="`background: rgba(0,0,0,0.7); ${focusControlStyle}`"
        >
          <Icon
            icon="mdi-magnify-minus-outline"
            iconColor="white"
            :small="false"
            :tooltipText="$t('zoomOut')"
            @icon-clicked="increaseCanvasScale(0 - zoomIncrement)"
          />
          <Icon
            icon="mdi-magnify-plus-outline"
            iconColor="white"
            :small="false"
            :tooltipText="$t('zoomIn')"
            @icon-clicked="increaseCanvasScale(zoomIncrement)"
          />
          <Icon
            icon="mdi-rotate-right-variant"
            iconColor="white"
            :small="false"
            :tooltipText="`Rotate Image`"
            @icon-clicked="rotateCanvas"
          />
          <Icon
            margin="ml-1"
            icon="mdi-fit-to-page-outline"
            iconColor="white"
            :small="false"
            :tooltipText="$t('fitImage')"
            @icon-clicked="resetCurrentContextTransform"
          />
        </div>
        <div v-show="!loadedState" style="position: absolute; top: 40%; left: 50%; text-align: center">
          <span v-if="imageData.length === 0 && !loadingIndicator">No images</span>
          <v-progress-circular v-else-if="!error" indeterminate color="secondary" class="delay-fade-in"/>
          <span v-else>Could not load image</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="border-top: solid 2px #D15F27;" >
      <v-pagination
        v-model="imagePage"
        color="secondary"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        :length="imageData.length"
      />
      <v-spacer/>
      <slot name="actions"/>
      <Icon
      v-if="annotations && anyImageHasAnnotations && false"
      :disabled="currentImageContext.annotations.length == 0"
      :icon="drawAnnotations && currentImageContext.annotations.length > 0 ? 'mdi-image-outline' : 'mdi-text'"
      iconColor="secondary"
      :small="false"
      :tooltipText="`${drawAnnotations ? 'Hide' : 'Display'} Annotations`"
      @icon-clicked="toggleDrawAnnotations()"/>
      <Icon
      v-if="!dialog"
      icon="mdi-fullscreen"
      iconColor="secondary"
      :small="false"
      :tooltipText="$t('openImageViewer')"
      @icon-clicked="openDisplayDialog()"/>
      <Icon
      v-if="allowDelete"
      icon="mdi-delete-outline"
      iconColor="secondary"
      :small="false"
      :tooltipText="$t('deleteImage')"
      @icon-clicked="openDeleteDialog()"/>
    </v-card-actions>
    <Dialog :stateId="dialogId">
      <ImageViewer v-if="dialogShow === 'display'" :title="title" :imageData="imageData" dialog :propImagePage="imagePage" maxHeight="90vh" draggable :annotations="annotations"/>
      <ConfirmDelete
      v-if="dialogShow === 'delete'"
      :title="$t('ticketImage')"
      @cancel-delete="closeDialogs"
      @delete="deleteImage"
      />
    </Dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ImageViewer',
  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },
  props: {
    imageData: {
      type: Array,
      default: () => [],
      required: true
    },
    title: {
      type: String,
      default: 'Image Viewer',
      required: false
    },
    propImagePage: {
      type: Number,
      default: 1,
      required: false
    },
    rotationOffset: {
      type: Number,
      default: 0,
      required: false
    },
    height: {
      type: String,
      default: '',
      required: false
    },
    maxHeight: {
      type: String,
      default: '',
      required: false
    },
    maxWidth: {
      type: String,
      default: '500px',
      required: false
    },
    dialog: {
      type: Boolean,
      default: false,
      required: false
    },
    draggable: {
      type: Boolean,
      default: false,
      required: false
    },
    adaptiveScale: {
      type: Boolean,
      default: true,
      required: false
    },
    annotations: {
      type: Boolean,
      default: true,
      required: false
    },
    annotationMode: {
      type: String,
      default: 'overlay',
      required: false
    },
    allowDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingIndicator: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    drawAnnotations: false,
    dialogId: 'image-viewer',
    dialogShow: '',
    loadError: false,
    containerHover: false,
    imagePage: 1,
    loaded: false,
    error: false,
    images: [],
    imageWidth: '100%',
    imageHeight: '100%',
    lastDragEvent: undefined,
    zoomFocus: false,
    zoomIncrement: 0.2,
    rotateIncrement: Math.PI / 2,
    debugMode: false,
    resizeHandlerTimeout: -1
  }),

  watch: {
    imagePage () {
      this.$emit('page-changed', this.imagePage)
    },

    propImagePage () {
      this.imagePage = this.propImagePage
    },

    currentImagePage: {
      handler () { this.loadCurrentImage() }
    },

    annotations (a) {
      this.drawAnnotations = a
    },

    imageData: {
      handler (data) {
        this.imagePage = (data.length > 0) ? 1 : 0
        this.populateImageContexts(data)
        requestAnimationFrame(this.draw)
      },
      immediate: true,
      deep: true
    },
    currentImageContext: {
      handler (newContext, oldContext) {
        if (newContext?.url !== oldContext?.url) {
          this.loadCurrentImage()
        }
      },
      deep: true,
      immediate: true
    },
    currentContextTransform: {
      handler (transform) {
        if (transform) requestAnimationFrame(this.draw)
      },
      deep: true
    }
  },

  created () {
    this.dialogId = `image-viewer-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
    this.imagePage = this.propImagePage
    this.drawAnnotations = this.annotations
  },

  mounted () {
    window.addEventListener('resize', this.handleWindowResize)
  },

  beforeDestroy () {
    this.images.forEach(i => {
      i.image.remove()
    })
    window.removeEventListener('resize', this.handleWindowResize)
  },

  computed: {
    currentImagePage () {
      return this.imagePage - 1
    },

    offsetRadians () {
      return 2 * Math.PI * (this.rotationOffset % 360) / 360
    },

    canvasRef () {
      return this.$refs.canvas
    },

    focusControlStyle () {
      return `
        position: absolute; 
        bottom: 0; 
        left: ${this.dialog ? '50%' : 'unset'}; 
        ${this.dialog ? 'transform: translateX(-50%)' : ''}
        right: ${this.dialog ? 'unset' : '0'}
        background: rgba(0,0,0,0.7); 
        padding: 10px
      `
    },

    currentImageContext () {
      if (this.images.length <= this.currentImagePage) this.populateImageContexts(this.imageData)
      const current = this.currentImagePage < this.images.length ? this.images[this.currentImagePage] : undefined
      return current
    },

    currentContextTransform () {
      return this.currentImageContext?.transform
    },

    anyImageHasAnnotations () {
      return this.images.find(image => image.annotations && image.annotations.length > 0) !== undefined
    },

    loadedState () {
      return this.loaded && !this.loadingIndicator
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    loadCurrentImage () {
      if (this.images.length === 0 || this.images.length <= this.currentImagePage) {
        this.loaded = false
        this.error = false
        return
      }
      // we have to use explicit data properties rather than computing them from
      // the image context because Vue is not performant enough for that
      this.loaded = this.images[this.currentImagePage]?.loaded ?? false
      this.error = this.images[this.currentImagePage]?.error ?? false
      requestAnimationFrame(this.draw)
      this.loadImageFromContext(this.images[this.currentImagePage]).then(() => {
        this.images[this.currentImagePage].loaded = this.loaded = true
        this.images[this.currentImagePage].error = this.error = false
        this.loaded = true
        this.error = false
        requestAnimationFrame(this.draw)
      }).catch(e => {
        this.images[this.currentImagePage].loaded = this.loaded = false
        this.images[this.currentImagePage].error = this.error = true
        console.error(e)
      })
    },

    resetContextTransform (imageContext) {
      imageContext.transform.translate.x = 0
      imageContext.transform.translate.y = 0
      imageContext.transform.rotate = 0
      imageContext.transform.scale = 1
    },
    makeImageContext (arg) {
      // arg can be of form:
      /*
       * string
       * URL
       * {
       *   url: string | URL,
       *   annotations: {
       *     name: string,
       *     bounds: { x: float, y: float }[][],
       *     rgba: { r, g, b: [0-255], a: [0-1] },
       *     color: string?,
       *     borderColor: string?
       *   }[]
       * }
       */
      const url = arg?.url?.toString() ?? arg?.toString() ?? ''
      const annotations = arg?.annotations ?? []
      const image = new Image()
      image['data-src'] = url

      const imageContext = {
        image,
        url,
        annotations,
        loaded: false,
        error: false,
        transform: {
          translate: { x: 0, y: 0 }, // pixels in canvas space
          rotate: 0,
          scale: 1
        }
      }
      return imageContext
    },
    makeCanvasCoordinate (x, y) {
      return {
        offset: { x, y },
        relative: {
          x: (this.$refs.canvas !== undefined) ? x - this.$refs.canvas.width / 2 : 0,
          y: (this.$refs.canvas !== undefined) ? y - this.$refs.canvas.height / 2 : 0
        }
      }
    },
    async loadImageFromContext (imageContext, attemptReload = false) {
      await new Promise((resolve, reject) => {
        if (imageContext.error && !attemptReload) {
          reject(new Error('image has loading error and attempt reload is not set'))
        }
        if (!imageContext.loaded || imageContext.image.src === '') {
          imageContext.image.onerror = (e) => {
            reject(e)
          }
          imageContext.image.onload = (e) => {
            const mapBounds = (bounds) => {
              return bounds.map(poly => {
                return poly.map(p => ({ x: p.x * imageContext.image.naturalWidth, y: p.y * imageContext.image.naturalHeight }))
              })
            }
            imageContext.annotations = imageContext.annotations.map(a => ({
              ...a,
              bounds: mapBounds(a.bounds)
            }))
            resolve(imageContext)
          }
          imageContext.image.src = imageContext.image['data-src']
        } else {
          resolve(imageContext)
        }
      })
    },
    populateImageContexts (urls) {
      this.images = urls.map(url => this.makeImageContext(url))
    },

    openDisplayDialog () {
      this.dialogShow = 'display'
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    openDeleteDialog () {
      this.dialogShow = 'delete'
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    closeDialogs () {
      this.dialogShow = ''
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    deleteImage () {
      this.closeDialogs()
      this.$emit('delete', this.images[this.currentImagePage]?.url)
    },

    add (a, b) {
      return {
        x: a.x + b.x,
        y: a.y + b.y
      }
    },

    sub (a, b) {
      return {
        x: a.x - b.x,
        y: a.y - b.y
      }
    },

    rotateRadCW (p, rad, c = { x: 0, y: 0 }) {
      const sin = Math.sin(rad)
      const cos = Math.cos(rad)
      const x = p.x - c.x
      const y = p.y - c.y
      return {
        x: x * cos - y * sin + c.x,
        y: y * cos + x * sin + c.y
      }
    },

    scaleBy (p, s) {
      return {
        x: p.x * s,
        y: p.y * s
      }
    },

    imageBoundingBox (image) {
      const ww = image.naturalWidth / 2
      const hh = image.naturalHeight / 2
      const tl = this.rotateRadCW({ x: -ww, y: hh }, this.currentContextTransform.rotate)
      const tr = this.rotateRadCW({ x: ww, y: hh }, this.currentContextTransform.rotate)
      const xm = Math.max(Math.abs(tl.x), Math.abs(tr.x))
      const ym = Math.max(Math.abs(tl.y), Math.abs(tr.y))

      return [
        { x: ww - xm, y: hh - ym },
        { x: ww + xm, y: hh - ym },
        { x: ww - xm, y: hh + ym },
        { x: ww + xm, y: hh + ym }
      ]
    },

    boundingDimensions (image) {
      const boundingBox = this.imageBoundingBox(image)
      const xb = boundingBox.map(p => p.x)
      const yb = boundingBox.map(p => p.y)
      return {
        width: Math.max(...xb) - Math.min(...xb),
        height: Math.max(...yb) - Math.min(...yb)
      }
    },

    translateContext (x, y) {
      this.images[this.currentImagePage].transform.translate.x += x
      this.images[this.currentImagePage].transform.translate.y += y
    },

    rotateContextBy (rad) {
      this.images[this.currentImagePage].transform.rotate = (this.images[this.currentImagePage].transform.rotate + rad) % (Math.PI * 2)
    },

    setContextRotation (rad) {
      this.images[this.currentImagePage].transform.rotate = rad
    },

    increaseCanvasScale (inc, target = undefined) {
      const origScale = this.currentContextTransform.scale
      if (inc > 0) {
        this.images[this.currentImagePage].transform.scale *= inc + 1
        this.images[this.currentImagePage].transform.scale += inc / 5
      } else {
        this.images[this.currentImagePage].transform.scale = Math.max(1, origScale / (1 - inc) + (inc / 2))
      }
      if (target === undefined) {
        target = {
          x: 0 - this.currentContextTransform.translate.x,
          y: 0 - this.currentContextTransform.translate.y
        }
      } else {
        target = this.sub(target, this.currentContextTransform.translate, 0)
      }
      const newScale = this.currentContextTransform.scale
      const delta = this.sub(target, this.scaleBy(target, newScale / origScale))
      this.translateContext(delta.x, delta.y)
      requestAnimationFrame(this.draw)
    },

    rotateCanvas () {
      this.rotateContextBy(this.rotateIncrement)
      requestAnimationFrame(this.draw)
    },

    resetCurrentContextTransform () {
      this.resetContextTransform(this.images[this.currentImagePage])
      requestAnimationFrame(this.draw)
    },

    expandPolygon (points, ratio = 1.1) {
      const summed = points.reduce((p, c) => ({ x: p.x + c.x, y: p.y + c.y }), { x: 0, y: 0 })
      const l = points.length
      const center = {
        x: summed.x / l,
        y: summed.y / l
      }
      const f = ratio - 1
      const out = points.map(p => {
        return {
          x: p.x + (p.x - center.x) * f,
          y: p.y + (p.y - center.y) * f
        }
      })
      return out
    },

    draw () {
      const canvas = this.canvasRef
      if (!canvas) return
      canvas.width = this.$refs.container.clientWidth
      canvas.height = this.$refs.container.clientHeight
      canvas.style.width = canvas.width
      canvas.style.height = canvas.height
      const ctx = canvas.getContext('2d', { })
      const clearCanvas = () => {
        ctx.resetTransform()
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      }
      if (!this.loaded) {
        clearCanvas()
        return
      }
      canvas.style.imageRendering = 'pixelated'
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'

      const doTransformation = ({ image, transform }) => {
        const boundingDimensions = this.boundingDimensions(image)
        const widthRatio = ctx.canvas.width / boundingDimensions.width
        const heightRatio = ctx.canvas.height / boundingDimensions.height
        const canvasWidth = ctx.canvas.width
        const canvasHeight = ctx.canvas.height
        const ratio = Math.min(widthRatio, heightRatio)
        const { translate, rotate, scale } = transform
        const totalScale = (this.adaptiveScale) ? scale * ratio : scale
        const scaledWidth = image.naturalWidth * totalScale
        const scaledHeight = image.naturalHeight * totalScale
        const rc = {
          x: scaledWidth / 2,
          y: scaledHeight / 2
        }
        ctx.translate(rc.x, rc.y)
        const neededOffset = {
          x: (canvasWidth - scaledWidth) / 2 * 1,
          y: (canvasHeight - scaledHeight) / 2 * 1
        }
        ctx.translate(neededOffset.x, neededOffset.y)
        ctx.translate(translate.x, translate.y)
        ctx.rotate(this.offsetRadians + rotate)
        ctx.translate(0 - rc.x, 0 - rc.y)
        ctx.scale(totalScale, totalScale)
      }

      const drawCrosshair = () => {
        ctx.save()
        ctx.resetTransform()
        ctx.strokeStyle = 'black'
        ctx.lineWidth = '1'
        ctx.moveTo(canvas.width / 2, 0)
        ctx.lineTo(canvas.width / 2, canvas.height)
        ctx.stroke()
        ctx.moveTo(0, canvas.height / 2)
        ctx.lineTo(canvas.width, canvas.height / 2)
        ctx.stroke()
        ctx.restore()
      }

      const drawTestSprite = (image) => {
        ctx.beginPath()
        ctx.lineWidth = '6'
        ctx.strokeStyle = 'black'
        const grad = ctx.createLinearGradient(0, 0, image.naturalWidth, image.naturalHeight)
        grad.addColorStop(0, 'blue')
        grad.addColorStop(1, 'red')
        ctx.fillStyle = grad
        ctx.fillRect(0, 0, image.naturalWidth, image.naturalHeight)
        ctx.fillStyle = '#f7de72'
        const points = [
          [image.naturalWidth / 2, image.naturalHeight / 2],
          [image.naturalWidth / 4, image.naturalHeight / 4],
          [image.naturalWidth / 4 * 3, image.naturalHeight / 4 * 3],
          [image.naturalWidth / 4 * 1, image.naturalHeight / 4 * 3],
          [image.naturalWidth / 4 * 3, image.naturalHeight / 4 * 1]
        ]
        for (const point of points) {
          const dimension = 10 / this.currentContextTransform.scale
          ctx.fillRect(point[0] - dimension / 2, point[1] - dimension / 2, dimension, dimension)
        }
      }

      const drawAnnotations = ({ annotations, transform }) => {
        const drawPoints = (points, annotation) => {
          if (points.length < 3) return
          const path = new Path2D()
          path.moveTo(points[0].x, points[0].y)
          for (const point of points.slice(1)) {
            path.lineTo(point.x, point.y)
          }
          path.closePath()
          if (this.annotationMode === 'difference') {
            ctx.globalCompositeOperation = 'difference'
            ctx.fillStyle = 'white'
          } else {
            ctx.fillStyle = annotation.color ?? `rgba(${annotation.rgba.r},${annotation.rgba.g},${annotation.rgba.b},${annotation.rgba.a})`
            ctx.strokeStyle = annotation.borderColor ?? `rgba(${annotation.rgba.r - 20},${annotation.rgba.g - 20},${annotation.rgba.b - 20},${annotation.rgba.a + 0.3})`
            ctx.lineWidth = `${8 / (transform.scale * 1.8)}`
          }
          ctx.fill(path)
          ctx.stroke(path)
        }

        for (const annotation of annotations ?? []) {
          if (!annotation) continue
          for (const points of annotation.bounds) {
            drawPoints(points, annotation)
          }
        }
        ctx.globalCompositeOperation = 'source-over'
      }

      clearCanvas()
      doTransformation(this.currentImageContext)
      if (!this.debugMode) {
        ctx.drawImage(this.currentImageContext.image, 0, 0)
        if (this.drawAnnotations) drawAnnotations(this.currentImageContext)
      } else {
        drawTestSprite(this.currentImageContext.image)
        drawCrosshair()
      }
    },

    handleDragStart (e) {
      const image = new Image()
      // set to a zero width transparent image
      image.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII='
      e.dataTransfer.setDragImage(image, 0, 0)
    },

    handleDrag (e) {
      e.preventDefault()
      if (!this.draggable) {
        return
      }
      if (!this.lastDragEvent) {
        this.lastDragEvent = e
        return
      }

      if (e.screenX === 0 && e.screenY === 0) {
        this.lastDragEvent = undefined
        return
      }

      const deltaX = (e.screenX - (this.lastDragEvent?.screenX ?? 0))
      const deltaY = (e.screenY - (this.lastDragEvent?.screenY ?? 0))
      if (deltaX !== 0 || deltaY !== 0) {
        this.translateContext(deltaX, deltaY)
      }
      requestAnimationFrame(this.draw)
      this.lastDragEvent = e
    },

    handleCanvasClick (e) {
      const coords = this.makeCanvasCoordinate(e.offsetX, e.offsetY)
      console.debug(coords)
    },
    handleCanvasMouseWheel (e) {
      e.preventDefault()
      const canvasCoord = this.makeCanvasCoordinate(e.offsetX, e.offsetY)
      this.increaseCanvasScale(e.deltaY > 0 ? -0.2 : 0.2, canvasCoord.relative)
    },

    handleContainerMouseOver (e) {
      this.containerHover = true
    },

    handleContainerMouseOut (e) {
      this.containerHover = false
    },

    handleWindowResize () {
      if (this.resizeHandlerTimeout !== -1) {
        clearTimeout(this.resizeHandlerTimeout)
        this.resizeHandlerTimeout = -1
      }
      setTimeout(() => {
        requestAnimationFrame(this.draw)
      }, 400)
    },

    toggleDrawAnnotations () {
      this.drawAnnotations = !this.drawAnnotations
      requestAnimationFrame(this.draw)
    }
  }
}
</script>

<style scoped>
@keyframes delayed-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.delay-fade-in {
  animation: delayed-fade-in 0.6s ease;
}
</style>
