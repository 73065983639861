import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
PDFMake.vfs = pdfFonts.pdfMake.vfs

export async function createProductionSummaryPDF (productionSummary) {
  const periodEndDate = new Date(productionSummary.periodEndDate)
  const docDefinition = {
    pageMargins: 40,
    pageOrientation: 'portrait',
    content: [
      {
        columns: [
          {
            width: 'auto',
            text: 'Production Summary',
            style: 'header'
          },
          {
            width: '*',
            text: ''
          }
        ]
      },
      {
        columns: [
          {
            width: 'auto',
            text: `Pay Period: ${utcToLocalDate(productionSummary.periodStartDate)} - ${utcToLocalDate(periodEndDate.setDate(periodEndDate.getDate() - 1))}`,
            style: 'nestedTable'
          },
          {
            width: '*',
            text: ''
          },
          {
            text: `Date: ${utcToLocalDate(new Date().toISOString())}`,
            width: 'auto',
            style: ['rightAlign', 'nestedTable']
          }
        ]
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true
      },
      subHeader: {
        fontSize: 14,
        bold: true
      },
      rightAlign: {
        alignment: 'right'
      },
      nestedTable: {
        fontSize: 12
      },
      bold: {
        bold: true
      }
    }
  }

  docDefinition.content.push(getProductSummaryTables(productionSummary))
  getProductionSummaryTables(productionSummary.production).forEach(table => {
    docDefinition.content.push(table)
  })
  await PDFMake.createPdf(docDefinition).open()
  return 'pdf-complete'
}

function getProductSummaryTables (productionSummary) {
  const { productSummary } = productionSummary
  const productSummaryTable = getProductSummaryTable(productSummary)
  return productSummaryTable
}

function getProductSummaryTable (productSummary) {
  const keys = ['Product', 'Rate', 'Loads', 'Net Tons', { text: 'Amount', alignment: 'right' }]
  const values = []
  const processedProductSummaries = processProductSummaries(productSummary)

  processedProductSummaries.forEach(productSummary => {
    values.push(
      Object.values(productSummary)
    )
  })

  return [
    {
      table: {
        widths: ['auto', '*', '*', '*', '*'],
        body: [
          keys,
          ...values
        ]
      },
      layout: 'lightHorizontalLines',
      pageBreak: 'after',
      style: 'nested',
      marginTop: 10
    }
  ]
}

function processProductSummaries (productSummary) {
  const processedProductSummaries = []
  const totals = {
    productName: { text: 'Totals', bold: true },
    rate: { text: '-', bold: true },
    loads: 0,
    netTons: 0,
    amount: 0
  }

  productSummary.forEach(ps => {
    totals.loads += ps.loads
    totals.netTons += ps.weight
    totals.amount += ps.amount

    processedProductSummaries.push({
      productName: ps.productName,
      rate: formatMoney(ps.rate),
      loads: ps.loads,
      netTons: tonStringFromPounds(ps.weight),
      amount: { text: formatMoney(ps.amount), alignment: 'right' }
    })
  })

  totals.netTons = { text: tonStringFromPounds(totals.netTons), style: 'bold' }
  totals.amount = { text: formatMoney(totals.amount), bold: true, alignment: 'right' }
  totals.loads = { text: totals.loads, bold: true }
  processedProductSummaries.push(totals)

  return processedProductSummaries
}

function getProductionSummaryTables (production) {
  const tables = []
  production.forEach((p, index) => {
    tables.push(getProductionTable(p, index === production.length - 1))
  })
  return tables
}

function getProductionTable (production, pageBreak) {
  const tractTables = []
  const header = getHeader(production)
  const footer = getFooter(production)
  production.productionDetails.forEach(pd => {
    tractTables.push(getTractTable(pd))
  })

  return {
    table: {
      widths: ['*'],
      body: [
        [header],
        ...tractTables,
        [footer]
      ]
    },
    pageBreak: pageBreak ? undefined : 'after'
  }
}

function getTractTable (productionDetails) {
  const keys = ['Product', 'Rate', 'Loads', 'Net Tons', { text: 'Amount', alignment: 'right' }]
  const values = []
  const processedProductSummaries = processProductSummaries(productionDetails.tractProduction)
  processedProductSummaries.forEach(productSummary => {
    values.push(
      Object.values(productSummary)
    )
  })

  return [
    {
      table: {
        widths: ['*'],
        body: [
          [`Tract: ${productionDetails.tractName}`],
          [{
            table: {
              widths: [200, '*', '*', '*', '*'],
              body: [
                keys,
                ...values
              ]
            },
            layout: 'lightHorizontalLines',
            style: 'nestedTable'
          }]
        ]
      },
      layout: 'noBorders'
    }
  ]
}

function getHeader (production) {
  const millName = production.name

  return [
    {
      table: {
        widths: ['auto'],
        body: [
          [{ text: millName, style: 'bold' }]
        ]
      },
      layout: {
        vLineColor: function (i, node) {
          if (i === 0 || i === node.table.widths.length) { return 'white' }
          return (i % 2 === 0 || i === node.table.widths.length - 1) ? 'black' : 'white'
        },
        hLineColor: function () {
          return 'white'
        }
      }
    }
  ]
}

function getFooter (production) {
  let totalAmount = 0
  let totalWeight = 0
  let loadCount = 0
  production.productionDetails.forEach(pd => {
    pd.tractProduction.forEach(load => {
      totalAmount += load.amount
      totalWeight += load.weight
      loadCount += load.loads
    })
  })

  return [
    {
      table: {
        widths: ['*', 'auto', 'auto', 'auto'],
        body: [
          [{ text: 'Overall', style: 'bold' }, { text: `Loads: ${loadCount}`, style: 'bold' }, { text: `Net Tons: ${tonStringFromPounds(totalWeight)}`, style: 'bold' }, { text: `Amount: ${formatMoney(totalAmount)}`, style: 'bold' }]
        ]
      },
      layout: {
        vLineColor: function (i, node) {
          if (i === 0 || i === node.table.widths.length) { return 'white' }
          return (i % 2 === 0 || i === node.table.widths.length - 1) ? 'black' : 'white'
        },
        hLineColor: function () {
          return 'white'
        }
      }
    }
  ]
}
