import messages from '@/lang'
import { localeCompareAlphanumeric } from '@/utils/base.js'

export function allJournalEntryHeaders (locale) {
  return [
    {
      sortable: true,
      text: messages[locale].hashSign,
      value: 'ticketNumber'
    },
    {
      sortable: true,
      text: messages[locale].extTicketNum1,
      value: 'extTicketNumber1',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].extTicketNum2,
      value: 'extTicketNumber2',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].account,
      value: 'accountName',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].activity,
      value: 'activityName',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].tract,
      value: 'tractName',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].product,
      value: 'product',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].netWeightTons,
      value: 'netWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].payOn,
      value: 'computedAtPayOn',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].payBy,
      value: 'computedAtPayBy',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].rate,
      value: 'computedAtRate',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].amount,
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: messages[locale].actions,
      value: 'actions',
      align: 'center'
    }
  ]
}

export function allBPJournalEntryHeaders (locale) {
  return [
    {
      sortable: true,
      text: messages[locale].hashSign,
      value: 'ticketNumber'
    },
    {
      sortable: true,
      text: messages[locale].extTicketNum1,
      value: 'extTicketNumber1',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].extTicketNum2,
      value: 'extTicketNumber2',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].account,
      value: 'accountName',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].activity,
      value: 'activityName',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].product,
      value: 'product',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].netWeightTons,
      value: 'netWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].payOn,
      value: 'computedAtPayOn',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].payBy,
      value: 'computedAtPayBy',
      align: 'center'
    },
    {
      sortable: true,
      text: messages[locale].rate,
      value: 'computedAtRate',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].amount,
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: messages[locale].actions,
      value: 'actions',
      align: 'center'
    }
  ]
}

export function journalEntriesByAccountHeaders (locale) {
  return [
    {
      sortable: true,
      text: messages[locale].account,
      value: 'accountName'
    },
    {
      sortable: true,
      align: 'right',
      text: messages[locale].ticketCount,
      value: 'ticketCount'
    },
    {
      sortable: true,
      align: 'right',
      text: messages[locale].totalNetWeight,
      value: 'totalNetWeight'
    },
    {
      sortable: true,
      text: messages[locale].totalJournalEntry,
      value: 'totalJournalEntry',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].averageRate,
      value: 'averageRate',
      align: 'right'
    },
    {
      sortable: false,
      text: messages[locale].actions,
      value: 'actions',
      align: 'center'
    }
  ]
}

export function ticketJournalEntryHeaders (locale) {
  return [
    {
      sortable: true,
      text: messages[locale].counterparty,
      value: 'accountName'
    },
    {
      sortable: true,
      text: messages[locale].activity,
      value: 'activityName'
    },
    {
      sortable: true,
      text: messages[locale].payOn,
      value: 'payOn'
    },
    {
      sortable: true,
      text: messages[locale].payBy,
      value: 'payBy'
    },
    {
      sortable: true,
      text: messages[locale].rate,
      value: 'rate',
      align: 'right'
    },
    {
      sortable: true,
      text: messages[locale].amount,
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: messages[locale].actions,
      value: 'actions',
      align: 'center'
    }
  ]
}
