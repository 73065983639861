<template>
  <v-expansion-panels v-model="openPanel" accordion flat tile>
    <v-expansion-panel v-for="(location, index) in entity.locations" :key="index">
        <LocationPanel
          :propLocation="location"
          :businessEntityId="entity.businessEntityId"
          :locations="entity.locations"
          :index="index"
          :openPanel="openPanelIndex"
          @entity-mutated="$emit('location-mutated')"
        />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Locations',

  components: {
    LocationPanel: () => import('@/components/settings-components/company-config/LocationPanel.vue')
  },

  props: {
    entity: {
      type: Object,
      default: undefined,
      required: true
    }
  },

  computed: {
    openPanelIndex () {
      return this.openPanel
    }
  },

  data: () => ({
    openPanel: undefined
  })
}
</script>
