<template>
  <v-card>
    <v-card-title :class="classString">
      <v-row>
        <v-col cols="10">
          <span style="overflow-wrap: anywhere; word-wrap: break-word; word-break: normal;">
            {{ $t('ticketsByEntityAndActivity', {entity: payableSummary.businessEntity, activity: payableSummary.activity}) }}
          </span>
        </v-col>
        <v-spacer/>
        <v-col align="right">
          <Icon
          icon="mdi-close"
          icon-color="white"
          large
          :small="false"
          :tooltipText="$t('close')"
          @icon-clicked="$emit('close')"/>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-data-table
          :items="tickets"
          :headers="headers">
            <template #item.date="{item}">
              {{ utcToLocalDate(item.date, 'L - LT') }}
            </template>
            <template #item.grossAmount="{item}">
              {{ formatMoney(item.gross) }}
            </template>
            <template #item.recovered="{item}">
              {{ formatMoney(item.recovered) }}
            </template>
            <template #item.netTons="{item}">
              {{ tonStringFromPounds(item.netWeight) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'TicketwiseFinancialBreakdown',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    payableSummary: {
      type: Object,
      required: true
    },
    isCorrection: {
      type: Boolean,
      default: false
    },
    includeRecoveries: {
      type: Boolean,
      default: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headers () {
      const headerArray = [{
        align: 'right',
        sortable: 'true',
        text: this.$t('ticketNumber'),
        value: 'ticketNumber'
      },
      {
        align: 'right',
        sortable: 'true',
        text: this.isCorrection ? this.$t('createdAt') : this.$t('weighedOutAt'),
        value: 'date'
      },
      {
        align: 'right',
        sortable: 'true',
        text: this.$t('grossAmount'),
        value: 'grossAmount'
      }]
      if (this.includeRecoveries) {
        headerArray.push({
          align: 'right',
          sortable: 'true',
          text: this.$t('recovered'),
          value: 'recovered'
        })
      }
      headerArray.push({
        align: 'right',
        sortable: 'true',
        text: this.$t('netWeightTons'),
        value: 'netTons'
      })
      return headerArray
    },

    classString () {
      return `headline py-4 white--text ${this.isByproducts ? 'byproduct' : 'secondary'}`
    },

    tickets () {
      return Array.from(this.payableSummary.tickets?.values() ?? [])
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,
    utcToLocalDate
  }
}
</script>
