<template>
  <CsvImport v-bind="{
    parse,
    preview,
    upload,
    entityName,
    rowName,
    exampleData
  }">
    <template #preview-item.collectPieceInfo="{ value }">
      <Icon
      v-if="value"
      icon="mdi-checkbox-marked-circle"
      iconColor="success"
      :small="false"
      />
      <span v-else/>
    </template>
  </CsvImport>
</template>

<script>
import { WoodType, TimberType } from '@/utils/Enumerations.js'
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import ProductHeaders from '@/headers/Product'
import { mapActions } from 'vuex'
export default {
  name: 'ProductImports',

  components: {
    CsvImport: () => import('./CsvImport.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  computed: {
    entityName () {
      return this.$t('product')
    },
    rowName () {
      return p => p.name
    },

    parse () {
      return {
        schema: {
          name: CsvColumn.from({
            name: 'name',
            type: CsvTypeTransformation.from({
              validate: s => s.length > 64 || s.length === 0 ? this.$t('productNameInvalid') : true
            })
          }),
          code: CsvColumn.from({
            name: 'code',
            type: CsvTypeTransformation.from({ validate: s => s.length > 10 ? this.$t('productNameInvalid') : true })
          }),
          woodType: CsvColumn.build(types => ({
            name: 'woodType',
            type: types.enumNameToInt(WoodType, 'Wood Type')
          })),
          timberType: CsvColumn.build(types => ({
            name: 'timberType',
            type: types.enumNameToInt(TimberType, 'Timber Type')
          })),
          conversionRateW2V: CsvColumn.from({
            name: 'conversionRate',
            type: CsvTypeTransformation.from({
              transform: parseFloat,
              validate: n => (Number.isNaN(n) || n < 0 || n > 5) ? this.$t('invalidConversionRateW2V') : true
            })
          }),

          collectPieceInfo: CsvColumn.from({
            name: 'requiresPieces',
            type: CsvTypeTransformation.from({ transform: s => s === 'requires' })
          })
        }
      }
    },

    preview () {
      return { headers: ProductHeaders.productBulkImportPreviewHeaders() }
    },

    upload () {
      return {
        action: this.createProduct
      }
    },

    exampleData () {
      return 'data:text/csv;charset=utf-8,name,code,conversionRate,woodType,timberType,requiresPieces\nDefault 1,pr1,0.2,Hardwood,Poles,requires\nDefault 2,pr2,0.5,Hardwood,Posts,requires\nDefault 3,,0.8,Hardwood,Chip-n-Saw,requires\nDefault 4,,1.1,Hardwood,Pulpwood,\nDefault 5,,1.4,Softwood,Fuelwood Chips,\nDefault 6,pr6,1.7,Softwood,Fuelwood/Firewood,\nDefault 7,pr7,2,Softwood,Sawtimber,requires\nDefault 8,pr8,2.3,Softwood,Posts,requires'
    }
  },

  methods: {
    ...mapActions('product', ['createProduct'])
  }
}
</script>
