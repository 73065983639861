import store from '@/store/index.js'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { BlobServiceClient } from '@azure/storage-blob'
import i18n from '../i18n'
import { SasTokenLocations } from './constants'

class ShapefileClient {
  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  async getEndpoint () {
    return 'companyinfo/blobinfo/tractshapes'
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.SHAPE_FILES_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = await this.getEndpoint()
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.SHAPE_FILES_SAS, JSON.stringify(response))
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.SHAPE_FILES_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  getBlobLocation (tractId) {
    return `${this.location}/${tractId}/`
  }

  async getShapefile (tractId) {
    try {
      const containerClient = await this.getContainerClient()
      const blobLocation = this.getBlobLocation(tractId)
      const blobs = containerClient.listBlobsByHierarchy('/', { prefix: blobLocation })
      let blobItem = await blobs.next()

      // skip if geojson
      if (blobItem.value.name.split('.').pop() !== 'zip') blobItem = await blobs.next()

      const blobUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.SHAPE_FILES_SAS)).data[0].blobSasUri, this.container, blobItem.value.name)
      return blobUrl.href
    } catch (e) {
      if (e.name === 'TypeError') {
        throw new ReferenceError('No shapefile for tract #' + tractId)
      }
      if (e.code !== 'AuthorizationFailure') {
        store.dispatch('snackbar/setSnackError', i18n.t('getShapefileFailure', { tractName: store.getters['tract/allTracts'].find(t => t.tractId === tractId).name ?? '' }))
      }
      throw e
    }
  }

  async getAllShapeFiles (tracts) {
    const shapeFiles = []
    try {
      const containerClient = await this.getContainerClient()
      for (const tract of tracts) {
        const location = this.getBlobLocation(tract.tractId)
        const blobs = containerClient.listBlobsByHierarchy('/', { prefix: location })
        let blobItem = await blobs.next()
        if (blobItem?.value !== undefined) {
          if (blobItem.value.name.split('.').pop() !== 'zip') blobItem = await blobs.next() // skip if geojson
          const blobUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.SHAPE_FILES_SAS)).data[0].blobSasUri, this.container, blobItem.value.name)
          shapeFiles.push(blobUrl.href)
        }
      }
    } catch (e) {
      console.error(e)
      return shapeFiles
    }
    return shapeFiles
  }

  async deleteFile (tractId, fileUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (fileUrl instanceof URL)
        ? decodeURI(fileUrl.pathname)
        : decodeURI(new URL(decodeURI(fileUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = await containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.deleteIfExists()

      const geojsonBlobLocation = this.getBlobLocation(tractId) + 'geojson.json'
      const geojsonBlockBlobClient = containerClient.getBlockBlobClient(geojsonBlobLocation)
      await geojsonBlockBlobClient.deleteIfExists()
    } catch (e) {
      store.dispatch('snackbar/setSnackError', i18n.t('deleteShapefileFailure', { tractName: store.getters['tract/allTracts'].find(t => t.tractId === tractId).name ?? '' }))
      throw e
    }
  }

  async uploadShapefile (tractId, shapefile) {
    // Delete exisiting shapefile if it exists
    try {
      let exisitingShapefile = null
      exisitingShapefile = await this.getShapefile(tractId)
      if (exisitingShapefile) await this.deleteFile(tractId, exisitingShapefile)
    } catch (e) {
      // No exisiting shapefile
    }
    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = await this.getBlobLocation(tractId) + shapefile.name
      const blockBlobClient = await containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: shapefile.type
        }
      }
      await blockBlobClient.uploadData(shapefile, options)
    } catch (e) {
      console.error(e)
      if (e.code !== 'AuthorizationFailure') store.dispatch('snackbar/setSnackError', i18n.t('uploadShapefileFailure', { tractName: store.getters['tract/allTracts'].find(t => t.tractId === tractId).name ?? '' }))
      throw e
    }
  }

  async getGeojson (tractId) {
    const containerClient = await this.getContainerClient()
    const blobLocation = await this.getBlobLocation(tractId)
    const blobs = containerClient.listBlobsByHierarchy('/', { prefix: blobLocation })
    let blobItem = await blobs.next()
    // skip if zip
    if (blobItem.value.name.split('.').pop() !== 'json') blobItem = await blobs.next()

    const blobUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.SHAPE_FILES_SAS)).data[0].blobSasUri, this.container, blobItem.value.name)
    return blobUrl.href
  }

  async uploadGeojson (tractId, geojsonFile) {
    // check for existing geojson
    try {
      let existingJson = null
      existingJson = await this.getGeojson(tractId)
      if (existingJson) await this.deleteFile(tractId, existingJson)
    } catch (e) {
      // no existing geojson
    }
    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = this.getBlobLocation(tractId) + geojsonFile.name
      const blockBlobClient = containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: geojsonFile.type
        }
      }
      await blockBlobClient.uploadData(geojsonFile, options)
    } catch (e) {
      console.error(e)
    }
  }
}

export const shapefileClient = new ShapefileClient()
