import i18n from '../i18n'

export function importUserHeaders () {
  return ([
    {
      sortable: true,
      text: i18n.t('preferredUsername'),
      value: 'preferredUsername'
    },
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('objectId'),
      value: 'objectId'
    }
  ])
}
