<template>
  <v-card>
    <v-card-title :class="`secondary white--text headline`">
      <span>
        {{$t('updateDeviceRegistration')}}
      </span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('cancel')"
      :tooltipText="$t('cancel')"
      dataTestId="update-device-registration-cancel-button"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      large
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper @submit="saveChanges">
        <v-container fluid>
          <v-row v-if="device" class="black--text" style="font-size: 14px;">
            <v-col cols="12">
              <span class="subtitle" style="font-size: 16px;">
                {{$t('deviceInformation')}}
              </span>
            </v-col>
            <v-col cols="6" xs="12" sm="12" md="6">
              <v-icon small>mdi-account</v-icon>
              <span>
                {{device.accountName}}
              </span>
            </v-col>
            <v-col cols="6" xs="12" sm="12" md="6">
              <v-tooltip v-if="applicationImage(device).value !== -1" bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <img contain class="mr-1" height="14" v-bind="attrs" v-on="on" :src="applicationImage(device).src"/>
                </template>
                <span class="subtitle-1 white--text" style="white-space: pre-line">{{$t(applicationImage(device).label)}}</span>
              </v-tooltip>
              <Icon v-else
              icon="mdi-tablet-android"
              iconColor="black"
              :tooltipText="$t(applicationImage(device).label)"
              tooltipColor="black"
              />
              <span>
                {{device.deviceID}}
              </span>
            </v-col>
            <v-col cols="12" class="mt-6">
              <DatePicker
              dateLabel="Expires"
              dataTestId="device-expirdate"
              :startDate="expirationDate"
              :mustBeAfter="expirationDate"
              @date-picked="expirationDateChosen"/>
            </v-col>
          </v-row>
          <v-row v-else justify="center" align="center">
            <v-progress-circular class="pa-12 ma-12" indeterminate color="secondary"/>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import { TicketOriginImage } from '@/model/Ticket'
export default {
  name: 'UpdateDeviceRegistration',
  props: {
    registrationId: {
      type: Number,
      required: true
    }
  },
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },
  data: () => ({
    device: undefined,
    expirationDate: undefined
  }),
  async created () {
    const device = await this.getRegistration(this.registrationId)
    this.expirationDate = utcToLocalDate(device.expiresOn)
    this.device = device
  },
  methods: {
    ...mapActions('device-registration', ['getRegistration', 'updateRegistration']),
    expirationDateChosen (date) {
      this.expirationDate = date
    },

    applicationImage (device) {
      const icons = [
        {
          value: 0,
          label: 'createdOnLoader',
          src: TicketOriginImage.LOADER
        },
        {
          value: 1,
          label: 'createdOnTransporter',
          src: TicketOriginImage.TRANSPORTER
        }
      ]
      return icons.find(i => i.value === device.application) ?? {
        value: -1,
        label: 'unknown',
        src: ''
      }
    },

    async saveChanges () {
      const updateRequest = {
        identifier: this.device.identifier,
        deviceRegistrationId: this.device.deviceRegistrationId
      }
      updateRequest.updatedExpiresOn = localToUTC(this.expirationDate)
      await this.updateRegistration(updateRequest)
      this.$emit('registration-updated')
    }
  }
}
</script>
