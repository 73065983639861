<template>
  <v-row>
    <v-col cols="12">
      <span class="subtitle-1">
        {{ $t('scales') }}
      </span>
    </v-col>
    <v-col cols="12" md="6" lg="3" v-for="(scale, index) in scales" :key="`scale-${index}`">
      <ScaleCard
        :scale="scale"/>
    </v-col>
    <v-col cols="12" v-if="scales.length <= 0">
      <span class="subtitle-3">
        {{ $t('noScalesToDisplay') }}
      </span>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ScaleSettings',

  components: {
    ScaleCard: () => import('@/components/settings-components/company-config/receiver-settings/ScaleCard.vue')
  },

  props: {
    scales: {
      type: Array,
      required: true
    }
  }
}
</script>
