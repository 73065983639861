<template>
  <v-card>
    <v-card-title class="secondary white--text">
      {{$t('editStatus')}}
      <v-spacer></v-spacer>
      <Icon
      :small="false"
      large
      icon="mdi-close"
      iconColor="white"
      tooltipColor="secondary"
      :tooltipText="$t('close')"
      @icon-clicked="close"
      />
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="status"
        :items="statuses"
        :menu-props="{ bottom: true, offsetY: true }"
        class="mt-6"
        item-text="name"
        item-value="name"
        return-object
        color="black"
      />
    </v-card-text>
    <v-card-actions>
      <v-container fluid class="px-0">
        <v-row justify="end">
          <v-col cols="auto" class="px-0">
            <v-btn class="secondary" @click="saveChanges">
              {{$t('saveChanges')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { TractStatus } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
export default {
  name: 'EditTractStatus',

  props: {
    tract: {
      type: Object,
      required: true
    },
    propStatus: {
      type: Number,
      required: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    status: null
  }),

  computed: {
    statuses () {
      const status = TractStatus.find(s => s.value === this.propStatus)

      let statusesToExclude = []

      switch (status.value) {
        case 0:
          statusesToExclude = [3, 4]
          break
        case 1:
          statusesToExclude = [3, 4]
          break
        case 2:
          statusesToExclude = [3, 4]
          break
        case 3:
          statusesToExclude = [0, 1, 2, 5]
          break
        case 4:
          statusesToExclude = [0, 1, 2]
          break
        case 5:
          statusesToExclude = [0, 1, 2, 3]
          break
      }

      return TractStatus.filter(s => !statusesToExclude.includes(s.value))
    }
  },

  mounted () {
    this.status = this.statuses.find(s => s.value === this.propStatus)
  },

  methods: {
    ...mapActions('tract', ['updateTract']),
    async saveChanges () {
      if (this.status.value === this.propStatus) {
        this.$emit('close')
        return
      }

      const request = {
        ...this.tract,
        status: this.status.value
      }

      await this.updateTract(request)

      this.$emit('close', this.status.value)
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
