<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title class="error">
        <span class="white--text headline">
          {{`${$t('systemNotifications')} (${systemNotifications.length})`}}
        </span>
        <v-spacer/>
        <Icon
        icon="mdi-close"
        dataTestId="sysnot-close-button"
        icon-color="white"
        tooltip-color="error"
        :tooltip="$t('close')"
        :small="false"
        :tooltip-text="$t('dismiss')"
        :large="true"
        @icon-clicked="dialog = false"
        />
      </v-card-title>
      <v-card-text class="mt-6">
        <div  v-for="(notification, index) in systemNotifications" :key="index">
          <p class="title">
            {{getNotificationText(notification)}}
          </p>
          <p class="subtitle-2">
            {{getNotificationSubtext(notification)}}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-2">
          <v-row justify="end">
            <v-col cols="auto" class="px-0">
              <v-btn class="error" @click="goToCalendar">{{$t('goToCalendar')}}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { NotificationIdentifier } from '@/utils/Enumerations.js'
export default {
  name: 'SystemNotifications',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    dialog: false,
    dialogHasBeenShown: false
  }),

  computed: {
    ...mapGetters('notifications', ['systemNotifications'])
  },

  watch: {
    systemNotifications: {
      handler (val) {
        if (val.length > 0) {
          if (this.dialogHasBeenShown) return
          this.dialogHasBeenShown = true
          this.dialog = true
        }
      },
      immediate: true
    }
  },

  methods: {
    getNotificationText (notification) {
      return NotificationIdentifier.fromInt(notification.identifier)
    },
    getNotificationSubtext (notification) {
      switch (notification.identifier) {
        case NotificationIdentifier.NoFiscalYear.value:
          return this.$t('noFiscalYearSubtext')
        case NotificationIdentifier.FiscalYearExpiration.value:
          return this.$t('fiscalYearExpirationSubtext')
        case NotificationIdentifier.NoPayPeriod.value:
          return this.$t('noPayPeriodSubtext')
        case NotificationIdentifier.LastPayPeriod.value:
          return this.$t('lastPayPeriodSubtext')
      }
    },
    goToCalendar () {
      const currentPath = this.$router.app._route.path
      if (currentPath !== '/settings') {
        this.$router.push('/settings')
      }
      this.dialog = false
    }
  }
}
</script>
