<template>
  <v-menu
    v-model="datePickerMenu"
    ref="datePicker"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }" >
      <v-text-field
        v-model="formattedDate"
        readonly
        persistent-hint
        color="black"
        clearable
        v-on="on"
        :label="label"
        :rules="rules"
        @click:clear="isoDate = ''"
      >
        <template #append>
          <v-icon v-on="on" color="secondary">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      color="secondary"
      v-model="isoDate"
      dark
      header-color="black"
      width="500"
      no-title
      @click:date="datePickerMenu = false"
    />
  </v-menu>
</template>

<script>
import moment from 'moment'
import { utcToLocalDate } from '@/utils/DateFormatter.js'

export default {
  name: 'CompactDatePicker',

  props: {
    initialDate: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: undefined
    },
    rules: {
      type: Array,
      default: undefined
    }
  },

  watch: {
    isoDate (val) {
      if (val === '') {
        this.$emit('date-picked')
      } else {
        this.$emit('date-picked', moment(val).format())
      }

      if (this.isoDate === '') {
        this.formattedDate = ''
      }

      this.formattedDate = moment(this.isoDate).format('MM/DD/YYYY')
    }
  },

  data: () => ({
    datePickerMenu: false,
    isoDate: '',
    formattedDate: ''
  }),

  created () {
    if (this.initialDate === '' || !this.initialDate) {
      this.isoDate = ''
    } else {
      this.isoDate = moment(utcToLocalDate(this.initialDate)).format()
    }
  }
}
</script>
