<template>
  <v-data-table
  :items-per-page="-1"
  hide-default-footer
  :items="entries"
  :headers="headers">
    <template #item.businessEntity="{item}">
      <span id="table-shortcut" @click="emitSelection(item)">{{ item.businessEntity }}</span>
    </template>
    <template #item.activity="{item}">
      <span id="table-shortcut" @click=emitSelection(item)>{{ item.activity }}</span>
    </template>
    <template #item.glCode="{item}">
      <span id="table-shortcut" @click="emitSelection(item)">{{ item.glCode }}</span>
    </template>
    <template #item.glOffset="{item}">
      <span id="table-shortcut" @click="emitSelection(item)">{{ item.glOffset }}</span>
    </template>
    <template #item.netWeight="{item}">
      <span id="table-shortcut" @click="emitSelection(item)">{{ tonStringFromPounds(getTicketWeight(item), 3) }}</span>
    </template>
    <template #item.grossAmount="{item}">
      <span id="table-shortcut" @click="emitSelection(item)">{{ formatMoney(item.grossAmount) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
export default {
  name: 'TicketFinancialsTable',

  props: {
    entries: {
      type: Array,
      required: true
    },
    isCorrections: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headers () {
      const headers = AdjustingEntriesHeaders.ticketContractPayments()
      if (!this.isCorrections) {
        headers.push({
          align: 'right',
          sortable: true,
          text: this.$t('netWeightTons'),
          value: 'netWeight'
        })
      }
      return headers
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,
    emitSelection (item) {
      this.$emit('aggregateSelected', item)
    },
    getTicketWeight (item) {
      const uniqueTickets = Array.from(item.tickets.values() ?? [])
      return uniqueTickets.reduce((sum, t) => sum + t.netWeight, 0)
    }
  }
}
</script>
