<template>
  <v-card data-testid="setting-form">
    <v-card-title class="secondary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close')"
      :tooltipText="$t('close')"
      iconColor="white"
      icon="mdi-close"
      dataTestId="setting-close-button"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="settingForm"
        testId="save-setting"
        :buttonText="saveBtnText"
        :lazyValidation="true"
        @submit="saveSetting"
      >
        <v-container grid-list-lg>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="setting.name"
                data-testid="setting-name"
                :rules="[rules.required]"
                :label="$t('name')"
                color="black"
                ref="firstField"
                counter="64"
                maxlength="64"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="setting.code"
                data-testid="setting-code"
                :label="$t('code')"
                color="black"
                counter="10"
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions } from 'vuex'
export default {
  name: 'SettingForm',

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    isEditing: Boolean,
    propSetting: Object
  },

  data: () => ({
    rules: fieldRules.rules,
    setting: {
      settingId: -1,
      code: '',
      name: ''
    }
  }),

  created () {
    if (this.isEditing) {
      this.setting = JSON.parse(JSON.stringify(this.propSetting))
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  computed: {
    title () {
      return this.isEditing ? this.$t('editingSetting') : this.$t('creatingSetting')
    },

    saveBtnText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('createNewSetting')
    }
  },

  methods: {
    ...mapActions('setting', ['createSetting', 'updateSetting']),

    async saveSetting () {
      if (!this.$refs.form.$refs.settingForm.validate()) {
        return
      }

      if (this.isEditing) {
        await this.updateSetting(this.setting)
        this.$emit('setting-mutated')
      } else {
        await this.createSetting({ name: this.setting.name, code: this.setting.code })
        this.$emit('setting-mutated')
      }
    }
  }
}
</script>
