export default {
  setSnack (state, snack) {
    state.snackColor = 'secondary'
    state.timeout = 2500
    state.snack = snack
  },

  setSnackError (state, error) {
    const errorMessage = error.message ? error.message : error

    state.snackColor = 'error'
    state.timeout = 5000

    if (error.code !== undefined && error.code !== null) {
      state.snack = `${errorMessage} \n ${error.source ? 'Source: ' + error.source.label + ', ' : ''}Code: ${error.code}`
    } else {
      state.snack = errorMessage
    }
  }
}
