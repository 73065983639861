var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({},'CsvImport',{
  parse: _vm.parse,
  preview: _vm.preview,
  upload: _vm.upload,
  exampleData: _vm.exampleData,
  entityName: _vm.entityName,
  rowName: _vm.rowName
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }