<template>
  <div>
    <v-autocomplete
    :items="tags"
    return-object
    item-text="value"
    multiple
    chips
    :label="label ? $t('tags') : undefined"
    v-model="selectedTags">
      <template #append-outer>
        <v-icon color="secondary" @click="addTag" :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)">mdi-plus</v-icon>
      </template>
    </v-autocomplete>
    <Dialog :stateId="dialogId" hideRefresh hideFullscreen>
      <TagForm @tag-mutated="getTags" @close="close" :propTag="undefined"/>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
export default {
  name: 'TagSelector',

  props: {
    propTags: {
      type: Array
    },
    label: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    TagForm: () => import('@/components/account/account-tags/TagForm.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  data: () => ({
    dialogId: 'tag-selector',
    selectedTags: [],
    tagNames: [],
    tags: [],
    loading: false,
    UserClaims
  }),

  watch: {
    selectedTags: {
      deep: true,
      handler (val) {
        const ids = val.map(t => t.tagId)
        this.$emit('tags-chosen', ids)
      }
    }
  },

  created () {
    this.getTags()
  },

  methods: {
    ...mapActions('tags', ['fetchTags']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,
    async getTags () {
      this.close()
      this.loading = true
      try {
        const tags = await this.fetchTags(0)
        this.tags = tags
        this.addTagsFromProps()
      } finally {
        this.loading = false
      }
    },

    close () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    addTagsFromProps () {
      if (this.propTags) {
        this.selectedTags = this.propTags
      }
    },

    addTag () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px', allowFullscreen: false })
    },

    tagsSelected (selected) {
      this.selectedTags = selected
    }
  }
}
</script>
