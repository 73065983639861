import { serverUrl, pendoStatus } from '../../env-var.js'
import md5 from 'crypto-js/md5'

const CURRENT_URL = window.location.href
const PRODUCTION_API = 'api-prod-angel'
const STAGING_DOMAIN = 'angel-staging.azurewebsites.net'
const ALLOWED_TENANT_DOMAIN_REGEX = /^[a-zA-Z0-9._%+-]+@legnalumber\.onmicrosoft\.com$/

export function initializePendo (userInfo, companyInfo) {
  if ((serverUrl.includes(PRODUCTION_API) &&
    !pendoStatus.includes('VUE_APP') &&
    pendoStatus !== '' &&
    pendoStatus !== 'false') ||
    (CURRENT_URL.includes(STAGING_DOMAIN) &&
      ALLOWED_TENANT_DOMAIN_REGEX.test(userInfo.preferredUsername))) {
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: md5(`${companyInfo.code}:${userInfo.applicationUserId}`).toString(),
        name: userInfo.name,
        email: userInfo.preferredUsername
      },
      account: {
        id: companyInfo.code,
        name: companyInfo.name
      }
    })
    console.log('Pendo initialized!')
  }
}
