<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
        :items="payables"
        dense
        :headers="headers">
          <template #[`item.amount`]="{item}">
            {{formatMoney(item.amount)}}
          </template>
          <template #[`item.createdAt`]="{item}">
            {{utcToLocalDate(item.createdAt)}}
          </template>
          <template #[`item.payDate`]="{item}">
            {{utcToLocalDate(item.payDate)}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TractHeaders from '@/headers/Tract.js'
import { formatMoney } from '../../../../utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'CurrentMiscPayables',

  props: {
    payables: {
      type: Array
    }
  },

  computed: {
    headers () {
      return TractHeaders.currentTractPayableHeaders().filter(h => h.text !== this.$t('actions') && (this.includeTract || h.text !== this.$t('tractName')))
    },

    includeTract () {
      return this.payables.some(p => p.tractName !== undefined)
    }
  },

  methods: {
    formatMoney,
    utcToLocalDate
  }
}
</script>
