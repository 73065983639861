export default function getIconForObjectType (objectType, enabled = true) {
  switch (objectType.toLowerCase()) {
    case 'logger':
      return 'mdi-axe'
    case 'destination':
      return enabled ? 'mdi-domain' : 'mdi-domain-off'
    case 'account':
      return enabled ? 'mdi-account' : 'mdi-account-outline'
    case 'fromaccount':
      return 'mdi-account-arrow-right'
    case 'tract':
      return enabled ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-off'
    case 'product':
      return enabled ? 'mdi-pine-tree-variant' : 'mdi-pine-tree-variant-outline'
    case 'region':
      return enabled ? 'mdi-map-marker' : 'mdi-map-marker-off' // TODO: not committed to this
    case 'landowner':
      return 'mdi-target-account' // TODO: not committed to this
    case 'tracttype':
      return 'mdi-target-variant' // TODO: not committed to this
    case 'defect':
      return enabled ? 'mdi-alert-decagram' : 'mdi-alert-decagram-outline' // TODO: not committed to this
    case 'date':
      return 'mdi-clock-outline'
    case 'certification':
      return 'mdi-certificate-outline'
  }
}
