<template>
  <div ref="gridContainer" :id="containerId" :style="`--overflow-mode: ${overflow}`">
    <slot/>
  </div>
</template>

<script>
import { selectBreakpoint } from '@/utils/componentHelpers.js'
export default {
  name: 'GridContainer',
  props: {
    gap: {
      type: [String, Number],
      default: '20px',
      required: false
    },
    outerGap: {
      type: [String, Number],
      default: '0px',
      required: false
    },
    rows: {
      type: [Object, String, Array],
      default: () => ({ sm: '150px', md: 'calc(120px + 2vw)' })
    },
    columns: {
      type: [Object, String, Array],
      default: () => ({ sm: 'repeat(6, 1fr)', md: 'repeat(12, 1fr)' })
    },
    inline: {
      type: Boolean,
      default: false
    },
    flow: {
      type: String,
      default: undefined,
      required: false
    },
    overflow: {
      type: String,
      default: 'unset'
    }
  },

  data: () => ({
    containerId: `grid-container-${Math.floor(Math.random() * 65536).toString(16)}`
  }),

  watch: {
    $props: {
      handler () {
        this.setGridStyle()
      },
      deep: true
    },
    breakpoint () {
      this.setGridStyle()
    }
  },

  mounted () {
    this.setGridStyle()
  },

  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.name
    }
  },

  methods: {
    setGridStyle () {
      const container = this.$refs.gridContainer
      const getDimensionStyle = (prop) => {
        if (typeof prop === 'string') {
          return {
            mode: prop.trim().match(/^repeat/) ? 'template' : 'auto',
            value: prop
          }
        } else if (Array.isArray(prop)) {
          return {
            mode: 'template',
            value: prop.join(' ')
          }
        } else {
          if (!prop.mode && !prop.value) {
            return getDimensionStyle(selectBreakpoint(prop, this.$vuetify.breakpoint.name))
          } else {
            return {
              mode: prop.mode,
              value: prop.value
            }
          }
        }
      }

      const parseGap = (gap) => (typeof gap === 'number') ? `${gap}px` : gap

      const columnStyle = getDimensionStyle(this.columns)
      const rowStyle = getDimensionStyle(this.rows)

      container.style.display = `${this.inline ? 'inline-' : ''}grid`
      container.style.gridGap = parseGap(this.gap)
      container.style.padding = parseGap(this.outerGap)
      container.style.gridAutoFlow = this.flow || 'row'

      if (columnStyle.mode === 'template') {
        container.style.gridTemplateColumns = columnStyle.value
      } else {
        container.style.gridAutoColumns = columnStyle.value
      }

      if (rowStyle.mode === 'template') {
        container.style.gridTemplateRows = rowStyle.value
      } else {
        container.style.gridAutoRows = rowStyle.value
      }
    }
  }
}
</script>

<style scoped>
div > * {
  overflow: var(--overflow-mode);
}
</style>
