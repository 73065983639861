import ApiServer from '@/utils/api/ApiServer.js'
export default {
  async getUserSettings (context) {
    const response = await ApiServer.get('userPreferences')
    if (response.status === 204) {
      return response.data
    }
    const newUserSettings = await handleIncompleteUserSettings(context, JSON.parse(response.data.preferences))
    context.commit('setUserSettings', newUserSettings)
  },

  async updateUserSettings (context, userSettings) {
    if (JSON.stringify(context.getters.mutatedUserSettings) === JSON.stringify(context.getters.userSettings)) {
      return
    }

    const response = await ApiServer.post('userPreferences', {
      preferences: JSON.stringify(userSettings)
    })
    context.commit('setUserSettings', userSettings)
    return response.data
  },

  async deleteUserSetting (context, setting) {
    context.commit('removeUserSetting', setting)
    const getResponse = await ApiServer.get('userPreferences')
    const getResponseObj = JSON.parse(getResponse.data.preferences)
    delete getResponseObj[setting.type][setting.key]
    const postResponse = await ApiServer.post('userPreferences', {
      preferences: JSON.stringify(getResponseObj)
    })
    return postResponse.data
  }
}

const handleIncompleteUserSettings = async (context, storedUserSettings) => {
  const defaultUserSettings = context.getters.mutatedUserSettings
  let shouldUpdate = false
  Object.keys(defaultUserSettings).forEach(type => {
    if (!storedUserSettings[type]) {
      storedUserSettings[type] = defaultUserSettings[type]
      shouldUpdate = true
    } else {
      Object.keys(defaultUserSettings[type]).forEach(key => {
        if (!storedUserSettings[type][key]) {
          storedUserSettings[type][key] = defaultUserSettings[type][key]
          shouldUpdate = true
        }
      })
    }
  })
  if (shouldUpdate) {
    await ApiServer.post('userPreferences', {
      preferences: JSON.stringify(storedUserSettings)
    })
  }
  return storedUserSettings
}
