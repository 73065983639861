function groupAccountingItemsByAccountName (items, itemType) {
  const itemsByAccount = {}
  items.forEach(t => {
    if (itemsByAccount[t.accountName] === undefined) {
      itemsByAccount[t.accountName] = {
        accountName: t.accountName
      }
      itemsByAccount[t.accountName][itemType] = [t]
    } else {
      itemsByAccount[t.accountName][itemType].push(t)
    }
  })

  return Object.values(itemsByAccount)
}

function getUniqueEntitiesFromAccountingItems (items) {
  const businessEntities = []
  items.forEach(item => {
    if (businessEntities[item.businessEntityId] === undefined) {
      businessEntities[item.businessEntityId] = {
        name: item.businessEntityName,
        businessEntityId: item.businessEntityId
      }
    }
  })

  return Object.values(businessEntities)
}

function getUniqueNetWeight (items) {
  const ticketIds = {}
  let total = 0

  items.forEach(item => {
    if (ticketIds[item.ticketId] === undefined) {
      ticketIds[item.ticketId] = 0
      total += item.inWeight - item.outWeight - item.defectWeight
    }
  })

  return total
}

function uniqueTicketCount (items) {
  const ticketIds = {}

  items.forEach(item => {
    if (ticketIds[item.ticketId] === undefined) {
      ticketIds[item.ticketId] = true
    }
  })

  return Object.keys(ticketIds).length
}

export default {
  groupAccountingItemsByAccountName,
  getUniqueEntitiesFromAccountingItems,
  getUniqueNetWeight,
  uniqueTicketCount
}
