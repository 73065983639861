export const getDefaultTract = () => {
  return {
    acres: 0,
    cost: 0,
    spot: null,
    businessEntityId: null,
    code: '',
    name: '',
    status: 0,
    mapAndParcelNumber: '',
    certifcationIds: [],
    loggerIds: [],
    loggerAccountId: null,
    landOwnerAccountId: null,
    foresterUserId: null,
    harvestStartDate: null,
    harvestEndDate: null,
    purchaseDate: null,
    requiresExt1: false,
    requiresExt2: false,
    type: {
      tractTypeId: null
    }
  }
}
