import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchActivities (context, contractId) {
    context.commit('setLoading', true)
    try {
      const path = `contracts/${contractId}/activities`
      const response = await ApiServer.get(path)
      context.commit('setActivities', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async deleteActivity (_, requestObj) {
    const { contractId, activityId } = requestObj
    const path = `contracts/${contractId}/activities/${activityId}`
    const response = await ApiServer.delete(path)
    return response
  },
  async createActivity (_, requestObj) {
    const path = `contracts/${requestObj.contractId}/activities`
    const response = await ApiServer.post(path, requestObj)
    return response.data
  },
  async updateActivity (_, requestObj) {
    const path = `contracts/${requestObj.contractId}/activities/${requestObj.activityId}`
    const response = await ApiServer.put(path, requestObj)
    return response.data
  },

  async fetchActivitiesByEntity (context, contractId) {
    context.commit('setLoading', true)
    try {
      const activitiesByEntity = []
      const { data: allActivities } = await ApiServer.get(`contracts/${contractId}/activities`)
      context.commit('setActivities', allActivities)
      const businessEntities = await context.dispatch('user/fetchAllBusinessEntities', undefined, { root: true })
      businessEntities.forEach(entity => {
        if (allActivities.some(activity => activity.activityTemplate.businessEntityId === entity.businessEntityId)) {
          activitiesByEntity.push(entity)
        }
      })
      context.commit('setActivitiesByEntity', activitiesByEntity)
    } finally {
      context.commit('setLoading', false)
    }
  }
}
