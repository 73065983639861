<template>
  <v-container fluid>
    <span class="title">{{ $t('advances') }}</span>
    <v-row dense>
      <v-col cols="12">
        <DataTable
        :items.sync="advances"
        :headers="headers"
        :customCells="customCells"
        :actions=[]
        :showSearch="false"
        :showDense="false"
        >
        <template #gl-offset="{item}">
          {{item.glOffset === '' ? $t('notAvailable') : item.glOffset}}
        </template>
        <template #amount="{item}">
          {{formatMoney(item.amount)}}
        </template>
        <template #recovered="{item}">
          {{formatMoney(calculateRecoveredForAdvance(item))}}
        </template>
        <template #remaining-balance="{item}">
          {{formatMoney(item.balance)}}
        </template>
        <template #tract="{item}">
          {{ item.tract ?? $t('notAvailable') }}
        </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { settlementAdvanceHeaders } from '@/headers/ExportBatch.js'
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'SettlementAdvances',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  props: {
    advances: {
      type: Array,
      required: true
    },
    isExportBatch: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
  }),

  computed: {
    headers () {
      return settlementAdvanceHeaders(this.isExportBatch)
    },

    customCells () {
      return [
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'recovered',
          value: 'recovered'
        },
        {
          slotName: 'remaining-balance',
          value: 'remainingBalance'
        },
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'gl-offset',
          value: 'glOffset'
        },
        {
          slotName: 'tract',
          value: 'tract'
        }
      ]
    }
  },

  methods: {
    formatMoney,

    calculateRecoveredForAdvance ({ balance, amount, recovered }) {
      return recovered === undefined ? amount - balance : recovered
    }
  }
}
</script>
