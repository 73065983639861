<template>
  <v-card flat>
    <Dialog :stateId="dialogId" maxWidth="80vw" @dialog-closing="closeForm(true)">
      <AdvanceForm
        v-if="editing || creating"
        :propAdvance="focusedAdvance"
        :account="accountAdvance"
        :editing="editing"
        @advance-changed="closeForm(true)"
      />
      <Recoveries
      :advance="focusedAdvance"
      @recovery-changed="mutatedRecoveries = true"
      @close="closeForm(true)"
      @cancel="closeForm(false)"
      v-if="applyingRecovery"/>
      <ConfirmDelete
      v-if="deleting"
      :title="$t('advance')"
      @delete="deleteItem"
      @cancel-delete="closeForm"
      />
    </Dialog>
    <v-layout wrap class="my-3">
      <v-spacer/>
      <Icon
      icon="mdi-plus"
      dataTestId="advance-add-button"
      :small="false"
      :large="true"
      :tooltipText="$t('new')"
      @icon-clicked="createAdvance"
      :disabled="!userAssignedClaim(UserClaims.ContractManager)"
      />
    </v-layout>
    <div class="lg_expansion-panel_wrapper">
      <v-card-text>
        <AdvanceTable
        :dense="dense"
        :propAdvances.sync="accountAdvance.advances"
        :asAtDate="asAtDate"
        @viewRecoveries="applyRecovery"
        @edit="editAdvance"
        @delete="confirmDeleteAdvance"/>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'AccountAdvances',

  props: {
    accountAdvance: Object,
    dense: Boolean,
    asAtDate: String
  },

  data: () => ({
    dialogId: 'advance-panel-content',
    focusedAdvance: {},
    applyingRecovery: false,
    creating: false,
    editing: false,
    dialog: false,
    deleting: false,
    mutatedRecoveries: false,
    width: 500,
    UserClaims
  }),

  watch: {
    dialog (val) {
      if (!val) {
        this.closeForm(this.mutatedRecoveries)
        this.mutatedRecoveries = false
      }
    }
  },

  created () {
    this.dialogId = `advance-panel-content-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    AdvanceForm: () => import('../../account/advances/AdvanceForm.vue'),
    AdvanceTable: () => import('./AdvanceTable.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    Recoveries: () => import('../recoveries/Recoveries.vue'),
    Icon: () => import('../../helper/Icon.vue')
  },

  methods: {
    ...mapActions('advances', ['deleteAdvance']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,
    applyRecovery (advance) {
      this.closeForm()
      this.focusedAdvance = advance
      this.applyingRecovery = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    editAdvance (advance) {
      this.closeForm()
      this.focusedAdvance = advance
      this.editing = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '1000px' })
    },

    createAdvance () {
      this.closeForm()
      this.creating = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '1000px' })
    },

    confirmDeleteAdvance (advance) {
      this.closeForm()
      this.focusedAdvance = advance
      this.deleting = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
    },

    async deleteItem () {
      await this.deleteAdvance(this.focusedAdvance)
      this.closeForm(true)
    },

    closeForm (refresh) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.creating = false
      this.deleting = false
      this.editing = false
      this.applyingRecovery = false
      this.viewingRecoveries = false
      this.focusedAdvance = {}
      if (refresh) {
        this.$emit('refresh')
      }
    }
  }

}
</script>
