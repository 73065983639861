<template>
  <v-card>
    <v-card-title class="secondary white--text">
      <span class="headline">
        {{title}}
      </span>
      <v-spacer></v-spacer>
      <Icon
        icon="mdi-history"
        iconColor="tertiary"
        :small="false"
        :tooltipText="$t('history')"
        @icon-clicked="viewDiameterWeightsModifications"/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col>
            <v-file-input
            v-if="userAssignedClaim(UserClaims.InventoryManager)"
            v-model="diameterWeightCSV"
            :label="$t('uploadDiameterWeightCSV')"
            :hint="$t('willDeleteExistingDiameterWeightsForThisLocation')"
            persistent-hint
            >
            <template #prepend-inner>
              <Icon
              icon="mdi-information-outline"
              dataTestId="diameter-weight-example-download"
              :tooltipText="$t('downloadExampleTemplate')"
              :small="false"
              color="black"
              @icon-clicked="downloadExampleCSV"></Icon>
              <a href=""/>
            </template>
            <template #append-outer>
              <v-btn
              class="secondary mt-n2"
              :disabled="!diameterWeightCSV"
              @click="uploadDiameterWeights"
              >
              {{$t('upload')}}
              </v-btn>
            </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="errors.length > 0">
          <v-col>
            <span class="error--text subtitle-1">{{$t('invalidCSV')}}</span>
          </v-col>
          <v-col cols="12" v-for="(error, i) in errors" :key="i" class="mb-n2">
            <span class="error--text">
              {{error.text}}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="diameterWeights.length > 0">
          <v-col cols="12">
            <v-data-table
            :items="diameterWeights"
            :headers="headers"
            dense
            :hide-default-footer="diameterWeights.length < 10">
            </v-data-table>
          </v-col>
        </v-row>
        <v-row dense v-else justify="center">
          <v-col cols="8">
            <span class="text-center font-weight-bold title">
              {{$t('noDiameterWeightsAdded')}}
            </span>
          </v-col>
        </v-row>
        <Dialog :stateId="historyDialogId" @dialog-closing="closeHistoryDialog">
          <ModificationHistory entityType="diameterWeights" :entityName="location.name" :entityId="location.locationId"/>
        </Dialog>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'
import { diameterWeightsHeaders } from '@/headers/Defect.js'
import { UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
export default {
  name: 'DiameterWeightDialog',

  props: {
    location: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue')
  },

  data: () => ({
    diameterWeights: [],
    diameterWeightCSV: null,
    errors: [],
    historyDialogId: 'diameter-weights-history',
    UserClaims
  }),

  computed: {
    title () {
      return this.$t('diameterWeightsForLocation', { locationName: this.location.name })
    },

    headers () {
      return diameterWeightsHeaders(this.$i18n.locale)
    }
  },

  created () {
    this.getDiameterWeightInfo()
  },

  methods: {
    ...mapActions('defects', ['fetchDiameterInfoForLocation', 'putDiameterWeights']),
    ...mapActions('dialog', ['closeDialogsAtOrAbove', 'openOrUpdateDialog']),
    userAssignedClaim,

    async getDiameterWeightInfo () {
      this.diameterWeights = await this.fetchDiameterInfoForLocation(this.location.locationId)
    },

    downloadExampleCSV () {
      const data = 'data:text/csv;charset=utf-8,Diameter,Weight\n7,130\n8,173\n9,277\n10,338\n11,405'
      saveAs(data, 'DiameterWeightExample.csv')
    },

    async uploadDiameterWeights () {
      this.errors = []

      const reader = new FileReader()
      reader.readAsText(this.diameterWeightCSV)
      reader.onload = async () => {
        const lines = reader.result.split('\n')
        lines.shift()

        const diameterWeights = []
        for (const [index, line] of lines.entries()) {
          if (line === '' || line === undefined || line === null) continue
          const commaIndex = line.indexOf(',')
          let diameter = line.substring(0, commaIndex)
          let weight = line.substring(commaIndex, line.length)

          diameter = diameter.replaceAll(',', '').replaceAll('"', '')
          weight = weight.replaceAll(',', '').replaceAll('"', '')

          const parsedDiameter = Number(diameter)
          const parsedWeight = Number(weight)

          if (!Number.isInteger(parsedDiameter)) {
            this.errors.push({
              text: this.$t('invalidDiameter', { diameter: diameter, lineNumber: index + 1 })
            })
            continue
          }

          if (!Number.isInteger(parsedWeight)) {
            this.errors.push({
              text: this.$t('invalidWeight', { weight: weight, lineNumber: index + 1 })
            })
            continue
          }

          diameterWeights.push({
            diameter: Number(parsedDiameter),
            weight: Number(parsedWeight)
          })
        }

        if (this.errors.length > 0) {
          this.setSnackError(this.$t('invalidCSV'))
          return
        }

        await this.putDiameterWeights({
          diameterWeights: diameterWeights,
          locationId: this.location.locationId
        })
        this.diameterWeightCSV = null
        await this.getDiameterWeightInfo()
        this.$emit('diameter-weights', this.diameterWeights)
      }
    },

    close () {
      this.$emit('close')
    },

    closeHistoryDialog () {
      this.closeDialogsAtOrAbove(this.historyDialogId)
    },

    viewDiameterWeightsModifications () {
      this.openOrUpdateDialog(this.historyDialogId)
    }
  }
}
</script>
