export class WidgetSlot {
  constructor (id, dimensions = [3, 6], details = undefined) {
    this.id = id
    this.dimensions = dimensions
    this.details = details
  }
}

export class WidgetSlotSet {
  constructor (widgetSlots = []) {
    this.widgetSlots = widgetSlots
  }

  get idSet () {
    return new Set(this.widgetSlots.map(ws => ws.id))
  }

  find (id) {
    return this.widgetSlots.find(ws => ws.id === id)
  }

  contains (id) {
    return this.find(id) !== undefined
  }

  #widgetFrom (arg) {
    return (arg instanceof WidgetSlot)
      ? arg
      : new WidgetSlot(arg)
  }

  withRemoved (id) {
    const targetIndex = this.widgetSlots.findIndex(ws => ws.id === id)
    if (targetIndex === -1) return this
    return new WidgetSlotSet([
      ...this.widgetSlots.slice(0, targetIndex),
      ...this.widgetSlots.slice(targetIndex + 1)
    ])
  }

  withAllRemoved (ids) {
    const idSet = new Set(ids)
    return new WidgetSlotSet(this.widgetSlots.filter(ws => !idSet.has(ws.id)))
  }

  withAppended (widgetArg) {
    return new WidgetSlotSet([
      ...this.widgetSlots,
      this.#widgetFrom(widgetArg)
    ])
  }

  withAllAppended (widgetArgs) {
    return new WidgetSlotSet([
      ...this.widgetSlots,
      ...widgetArgs.map(arg => this.#widgetFrom(arg))
    ])
  }

  withInserted (widgetArg, index = 0) {
    const widgetSlot = this.#widgetFrom(widgetArg)
    if (index <= 0) return new WidgetSlotSet([widgetSlot, ...this.widgetSlots])
    if (index >= this.widgetSlots.length) return new WidgetSlotSet([...this.widgetSlots, widgetSlot])
    return new WidgetSlotSet([
      ...this.widgetSlots.slice(0, index),
      widgetSlot,
      ...this.widgetSlots.slice(index)
    ])
  }

  withAllInserted (widgetArgs, index = 0) {
    const widgetSlots = widgetArgs.map(wa => this.#widgetFrom(wa))
    if (index <= 0) return new WidgetSlotSet([...widgetSlots, ...this.widgetSlots])
    if (index >= this.widgetSlots.length) return new WidgetSlotSet([...this.widgetSlots, ...widgetSlots])
    return new WidgetSlotSet([
      ...this.widgetSlots.slice(0, index),
      ...widgetSlots,
      ...this.widgetSlots.slice(index)
    ])
  }
}
