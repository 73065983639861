import ApiServer from '@/utils/api/ApiServer.js'

export default {
  // Certifications
  async fetchCertifications (context) {
    const url = ApiServer.urlFor('certifications')
    const response = await ApiServer.get(url)
    context.commit('setCertifications', response.data)
    var sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    return sortedData
  },
  async createCertification (context, certification) {
    const newCertification = {
      name: certification.name,
      code: certification.code,
      category: certification.category
    }
    const url = ApiServer.urlFor('certifications')
    const response = await ApiServer.post(url, newCertification)
    context.commit('addCertification', response.data)
    return response.data
  },
  async updateCertification (context, certification) {
    const url = ApiServer.urlFor(`certifications/${certification.certificationId}`)
    const response = await ApiServer.put(url, certification)
    context.commit('updateCertification', response.data)
    return response.data
  },
  async deleteCertification (context, certificationId) {
    const url = ApiServer.urlFor(`certifications/${certificationId}`)
    const response = await ApiServer.delete(url)
    context.commit('removeCertification', certificationId)
    return response.data
  },

  // Account Certifications
  async fetchAccountCertificationsByAccountId (_, accountId) {
    const url = ApiServer.urlFor(`accounts/${accountId}/certifications`)
    const response = await ApiServer.get(url)
    return response.data
  },
  async fetchAccountCertification (_, accountCertificationRequestObj) {
    const { accountId, certificationId } = accountCertificationRequestObj
    const url = ApiServer.urlFor(`accounts/${accountId}/certifications/${certificationId}`)
    const response = await ApiServer.get(url)
    return response.data
  },
  async addAccountCertification (_, certification) {
    const url = ApiServer.urlFor(`accounts/${certification.accountId}/certifications`)
    const response = await ApiServer.post(url, certification)
    return response.data
  },
  async updateAccountCertification (_, certification) {
    const url = ApiServer.urlFor(`accounts/${certification.accountId}/certifications/${certification.certificationId}`)
    const response = await ApiServer.put(url, certification)
    return response.data
  },
  async removeAccountCertification (_, certification) {
    const url = ApiServer.urlFor(`accounts/${certification.accountId}/certifications/${certification.certificationId}`)
    const response = await ApiServer.delete(url)
    return response
  }
}
