export default {
  advances: [],
  advancesForAccount: [],
  advancesLoading: false,
  advanceModifications: [],
  advanceModification: undefined,
  advanceModificationsDialog: {
    status: false,
    modifications: []
  }
}
