export default {
  setUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },

  setUserClaims (state, userClaims) {
    state.userClaims = userClaims
  },

  setCompanyInfo (state, companyInfo) {
    state.companyInfo = companyInfo
  },

  setBusinessEntities (state, businessEntities) {
    state.businessEntities = businessEntities
  },

  setInitializing (state, initializing) {
    state.initializing = initializing
  },

  setInitialized (state, initialized) {
    state.initialized = initialized
  },

  setLoginRedirectResult (state, loginWithRedirectResult) {
    state.loginWithRedirectResult = loginWithRedirectResult
  },

  setEntitiesLoading (state, entitiesLoading) {
    state.entitiesLoading = entitiesLoading
  },

  setInvalidAccount (state, account) {
    state.invalidAccount = account
  },

  setAllUsers (state, allUsers) {
    state.allUsers = allUsers
  },

  setUsersLoading (state, usersLoading) {
    state.usersLoading = usersLoading
  },

  setForesterUsers (state, foresterUsers) {
    state.foresterUsers = foresterUsers
  }
}
