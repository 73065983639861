import i18n from '../i18n'

export default {
  teamHeaders () {
    return ([
      {
        sortable: false,
        text: i18n.t('active'),
        value: 'active',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('description'),
        value: 'description'
      },
      {
        sortable: false,
        text: i18n.t('users'),
        value: 'userIds',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  }
}
