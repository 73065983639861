import { BlockBlobClient } from '@azure/storage-blob'
import ApiServer from './api/ApiServer'

export const uploadSettlementReportForEmail = async (emailAddress, settlementReportPdf) => {
  const fileExtension = /pdf$/.test(settlementReportPdf.type)
    ? 'pdf'
    : 'zip'
  const sasUri = await getSettlementReportSasToken(emailAddress, fileExtension)
  const blockBlobClient = new BlockBlobClient(sasUri)
  await blockBlobClient.uploadData(settlementReportPdf, {
    blobHTTPHeaders: {
      blobContentType: settlementReportPdf.type
    }
  })
}

const getSettlementReportSasToken = async (emailAddress, fileExtension) => {
  const response = await ApiServer.get(ApiServer.urlFor('companyinfo/blobinfo/settlementreports', {
    params: { emailAddress, fileExtension }
  }))
  const { blobSasUri } = response.data[0]
  return blobSasUri
}
