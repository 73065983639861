import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

export const isTokenValid = () => {
  const token = Cookies.get('bearerToken')
  if (token === '' || token === null || token === undefined) {
    return false
  }

  const decodedToken = jwtDecode(token)

  if (decodedToken === null || decodedToken === undefined) {
    return false
  }

  if (isExpired(decodedToken.exp)) {
    return false
  }

  return true
}

export const getToken = () => {
  const token = Cookies.get('bearerToken')
  return isTokenValid() ? token : false
}

export const setToken = (token) => {
  Cookies.set('bearerToken', token, { secure: true, sameSite: 'strict' })
}

const isExpired = (utcSeconds) => {
  const currentDate = new Date()
  const expirationDate = new Date(0)
  expirationDate.setUTCSeconds(utcSeconds)
  const expiresInMs = expirationDate.getTime() - currentDate.getTime()
  return expiresInMs <= 0
}
