export default [
  {
    path: '/dashboard',
    view: 'Dashboard'
  },
  {
    path: '/settings',
    name: 'Settings',
    view: 'Settings',
    props: true
  },
  {
    path: '/tracts',
    name: 'Tracts',
    view: 'Tracts'
  },
  {
    path: '/tract-analysis',
    name: 'Tract Analysis',
    view: 'TractAnalysis'
  },
  {
    path: '/tickets',
    name: 'Tickets',
    view: 'Tickets'
  },
  {
    path: '/contracts',
    name: 'Contracts',
    view: 'Contracts'
  },
  {
    path: '/accounts',
    name: 'Accounts',
    view: 'Accounts'
  },
  {
    path: '/notifications',
    view: 'Notifications'
  },
  {
    path: '/device-registrations',
    view: 'DeviceRegistrations',
    name: 'Devices'
  },
  {
    path: '/accounts-receivable',
    view: 'Accounting/AccountsReceivable',
    name: 'Accounts Receivable'
  },
  {
    path: '/current-payables',
    view: 'Accounting/AccountsPayable',
    name: 'Current Payables'
  },
  {
    path: '/accruals',
    view: 'Accounting/Accruals',
    name: 'Accruals'
  },
  {
    path: '/settlements',
    view: 'Accounting/Settlements',
    name: 'Settlements'
  },
  {
    path: '/balances',
    view: 'Accounting/Balances',
    name: 'Balances'
  }
]
