<template>
  <v-container>
    <v-card>
      <v-card-title
      class="lighten-1 white--text subtitle-1"
      style="background-color: #d15f27; color: white; height: 35px">
        <Icon
        margin="mt-n3 mr-1"
        :icon="!isByproduct ? 'mdi-crosshairs-gps' : 'mdi-account-arrow-right'"
        :tooltipText="!isByproduct ? $t('tract') : $t('fromAccount')"
        iconColor="white"
        tooltipColor="secondary"/>
        <span class="mt-n3" style="width: 50%; white-space: nowrap; word-break: normal; overflow: hidden; text-overflow: ellipsis;">
          {{ !isByproduct ? contract.tractName : contract.fromAccountName }}
        </span>
      </v-card-title>
      <v-card-text style="overflow-y: auto; height: 150px">
        <v-container>
          <v-row>
            <v-col>
              <v-row v-for="(ci, index) in contractIconsFor(contract.type, 'contract')" :key="index" no-gutters class="ml-n3">
                <v-col cols="auto">
                  <Icon
                  :icon="ci.icon"
                  :tooltipText="$t(ci.tooltip)"
                  iconColor="black"
                  tooltipColor="secondary"/>
                </v-col>
                <v-col style="word-break: break-word;">
                  {{ ci.value(contract) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col align="right" cols="auto" class="mr-n4">
              <SettlementsContractActions
              :contract="contract"
              :tickets="contract.tickets"
              @unposted="$emit('unposted-tickets')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { contractIconsFor } from '../accounts-payable-module/ContractIcons'
export default {
  name: 'RateVerificationContractCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    SettlementsContractActions: () => import('@/components/settlements/SettlementsContractActions.vue')
  },

  props: {
    contract: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('settlements', ['currentSubModuleKey']),
    isByproduct () {
      return this.currentSubModuleKey.includes('byproduct')
    }
  },

  methods: {
    contractIconsFor
  }
}
</script>
