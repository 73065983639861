<template>
  <v-card>
    <v-card-title class="secondary headline white--text">
      <span class="ml-2">{{$t('integrationDetails')}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card hover>
              <v-card-title>
                <span>{{integrationStatus}}</span>
              </v-card-title>
              <v-card-text>
                <div>
                  <pre class="black--text" :style="{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'pre-wrap' }">{{detailString}}</pre>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="secondary" :disabled="!canRetryIntegration" @click="$emit('click:retry')">{{$t('retry')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { AdjustingEntriesSnapshotIntegrationStatus } from '@/utils/Enumerations'
import { mapGetters } from 'vuex'
import { userAssignedClaim } from '../../../../utils/ClaimUtility'
import { UserClaims } from '../../../../utils/Enumerations'
export default {
  name: 'SnapshotIntegrationDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    snapshotRecord: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    integrationStatus () {
      return AdjustingEntriesSnapshotIntegrationStatus.fromInt(this.snapshotRecord.integrationStatus)
    },

    canRetryIntegration () {
      return this.snapshotRecord.integrationStatus === AdjustingEntriesSnapshotIntegrationStatus.Error.value && userAssignedClaim(UserClaims.GlobalAdmin)
    },

    integrationPending () {
      return this.snapshotRecord.integrationStatus === AdjustingEntriesSnapshotIntegrationStatus.Pending.value
    },

    integrationSuccess () {
      return this.snapshotRecord.integrationStatus === AdjustingEntriesSnapshotIntegrationStatus.Success.value
    },

    detailString () {
      if (this.integrationSuccess) {
        return this.$t('csvDataStoredInContainerPath', { container: 'angel-exports', path: this.snapshotRecord.integrationDetail })
      }
      if (this.integrationPending) {
        return this.$t('integrationWaitingOrInProgress')
      }
      try {
        const ds = JSON.stringify(JSON.parse(this.snapshotRecord.integrationDetail || undefined), null, 2).trim()
        return ds
      } catch (e) {
        if (e instanceof SyntaxError) {
          return this.snapshotRecord.integrationDetail || undefined
        }
        console.error(e)
        return undefined
      }
    }
  }
}
</script>
