<template>
  <v-container fluid>
    <v-row>
      <v-col align="right">
        <div v-if="companyLogoHref === undefined">
          <Icon
            icon-color="white"
            icon="mdi-file-image-plus"
            :small="false"
            :tooltipText="$t('uploadCompanyLogo')"
            xLarge
            @icon-clicked="changeLogo"/>
          </div>
          <div v-else class="logo-container">
            <img
            :src="companyLogoHref"
            style="width: 75px; height: 75px; width: 100%"
            class="image"
            @click="deleteLogoConfirmation = true"
            alt="Logo">
            <div class="delete-icon">
              <v-icon large @click="deleteLogoConfirmation = true">mdi-delete</v-icon>
            </div>
          </div>
      </v-col>
    </v-row>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      @change="fileChanged"/>
    <v-dialog v-model="deleteLogoConfirmation" width="400px">
      <ConfirmDialog
        :title="$t('deleteCompanyLogo')"
        :body="$t('deleteCompanyLogoBody')"
        :confirmText="$t('deleteLogo')"
        @confirm="deleteLogo()"
        @cancel="deleteLogoConfirmation = false"/>
    </v-dialog>
  </v-container>
</template>

<style>
  .logo-container {
    display: inline-block;
    position: relative
  }
  .logo-container:hover .image{
    opacity: 0.5;
    cursor: pointer;
  }
  .logo-container:hover .delete-icon{
    bottom: 0;
    right: 0;
    cursor: pointer;
    display: block
  }
  .logo-container .delete-icon {
    display: none;
    position: absolute
  }
</style>

<script>
import { companyLogoClient } from '../../../utils/CompanyLogo'
export default {
  name: 'CompanyLogo',

  props: {
    companyInfo: {
      type: Object,
      required: true
    },
    allowEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    companyLogoHref: undefined,
    selectedFile: undefined,
    isSelecting: false,
    deleteLogoConfirmation: false,
    allowedLogoMimes: undefined
  }),

  async created () {
    if (this.companyInfo.companyInfoId) this.companyLogoHref = await companyLogoClient.getCompanyLogo(this.companyInfo.companyInfoId)
    this.allowedLogoMimes = new Set([
      'image/png',
      'image/jpeg'
    ])
  },

  watch: {
    companyInfo: {
      async handler (val) {
        if (val.companyInfoId) this.companyLogoHref = await companyLogoClient.getCompanyLogo(this.companyInfo.companyInfoId)
      }
    }
  },

  methods: {
    changeLogo () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })
      this.$refs.uploader.click()
    },

    fileChanged (e) {
      this.selectedFile = e.target.files[0]
      this.uploadToBlob()
      this.isSelecting = false
      this.$refs.uploader.value = ''
    },

    async uploadToBlob () {
      const validationResult = await this.validateLogo(this.selectedFile)
      if (!validationResult) return
      await companyLogoClient.uploadCompanyLogo(this.companyInfo.companyInfoId, this.selectedFile)
      this.companyLogoHref = await companyLogoClient.getCompanyLogo(this.companyInfo.companyInfoId)
    },

    async deleteLogo () {
      await companyLogoClient.deleteCompanyLogo(this.companyLogoHref)
      this.companyLogoHref = undefined
      this.deleteLogoConfirmation = false
    },

    async validateLogo (file) {
      const limit = 1000 * 1000 // 1MB
      if (file.size > limit) {
        this.setSnackError(this.$t('fileTooLargeSnack', { limit: '1MB' }))
        this.selectedFile = undefined
        return false
      }
      if (!this.allowedLogoMimes.has(file.type)) {
        this.setSnackError(this.$t('fileMimeNotAllowedSnack'))
        this.selectedFile = undefined
        return false
      }
      const dimensions = await companyLogoClient.getLogoDimensions(await companyLogoClient.convertImageToDataUrl(file))
      if (dimensions.width < 60 || dimensions.height < 60) {
        this.setSnackError(this.$t('imageTooSmallSnack'))
        this.selectedFile = undefined
        return false
      }
      if ((Math.abs(dimensions.width - dimensions.height) / Math.max(dimensions.width, dimensions.height)) > 0.25) {
        this.setSnackError(this.$t('imageNotSquareSnack'))
        this.selectedFile = undefined
        return false
      }
      return true
    }
  }
}
</script>
