<template>
  <v-card flat width="100%" data-testid="detail-table">
    <v-card-title class="subtitle-1"
      >{{ $t("details") }}
    </v-card-title>
    <v-card-text class="mt-n4">
      <v-dialog v-model="deleting" width="400px">
        <ConfirmDelete
        v-if="deleting"
        :title="$t('detail')"
        @delete="deleteItem"
        @cancel-delete="closeDelete"/>
      </v-dialog>
      <v-data-table
        :headers="detailHeaders"
        :items="activity.activityDetails"
        hide-default-footer
        :dense="true"
        :no-data-text="$t('noDetails')"
        class="mt-2"
      >
        <template v-slot:item.cost.rate="{ item }">
          {{ formatMoney(item.cost.rate) }}
        </template>
        <template v-slot:item.cost.payOn="{ item }">{{
          payOnFromInt(item.cost.payOn)
        }}</template>
        <template v-slot:item.cost.payBy="{ item }">{{
          payByFromInt(item.cost.payBy)
        }}</template>
        <template v-if="showActions" v-slot:item.action="{ item }">
          <v-icon small color="success" class="mr-2" data-testid="detail-edit-button" @click="editDetail(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small color="error" data-testid="detail-edit-button" @click="confirmDeleteDetail(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
      <v-dialog
      width="700px"
      v-model="autoPopulateDialog">
        <AutoPopulateDialog
        v-if="autoPopulateDialog"
        :contractId="contract.contractId"
        :tractContents.sync="tractContents"
        :activity="activity"
        @populated="populateSuccess"
        @error="populateSuccess"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PayOn, PayBy } from '@/utils/Enumerations'
import { formatMoney } from '@/utils/NumericMutations'
import DetailHeaders from '@/headers/Detail'

export default {
  name: 'DetailTable',

  components: {
    AutoPopulateDialog: () => import('./AutoDetailPopulate.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue')
  },

  props: {
    activity: Object,
    contract: Object,
    showActions: Boolean
  },

  data: () => ({
    editing: false,
    focusedDetail: {},
    deleting: false,
    autoPopulateDialog: false
  }),

  computed: {
    ...mapGetters('tract-contents', ['allTractContents']),
    ...mapGetters('product', ['allProducts']),
    detailHeaders () { return this.showActions ? DetailHeaders.detailHeadersWithCRUD(this.$i18n.locale) : DetailHeaders.detailHeadersNoCRUD(this.$i18n.locale) },

    allowAutopopulate () {
      return this.activity.activityDetails.length === 0 && this.showActions && this.allTractContents.length !== 0
    }
  },

  methods: {
    ...mapActions('activity-detail', [
      'deleteActivityDetail'
    ]),
    formatMoney,

    async deleteItem (detail) {
      const detailRequestObj = {
        activityDetailId: this.focusedDetail.activityDetailId,
        contractId: this.contract.contractId,
        activityId: this.activity.activityId
      }
      await this.deleteActivityDetail(detailRequestObj)
      this.$emit('detail-deleted')
      this.closeDelete()
    },

    populateSuccess () {
      this.$emit('done-adding-detail')
      this.autoPopulateDialog = false
    },

    editDetail (detail) {
      detail.activityId = this.activity.activityId
      this.$emit('edit-detail', detail)
    },

    confirmDeleteDetail (detail) {
      this.focusedDetail = detail
      this.deleting = true
    },

    close () {
      this.$emit('close')
    },

    detailAdded (val) {
      this.$emit('done-adding-detail')
    },

    closeDelete () {
      this.deleting = false
      this.focusedDetail = {}
    },

    payByFromInt (val) {
      return PayBy.fromInt(val)
    },

    payOnFromInt (val) {
      return PayOn.fromInt(val)
    }
  }
}
</script>
