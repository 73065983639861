<template>
  <v-card data-testid="detail-form">
    <v-dialog
    width="700px"
    v-model="autoPopulateDialog">
      <AutoPopulateDialog
      v-if="autoPopulateDialog"
      :contractId="contract.contractId"
      :activity="activity"
      :tractContents="tractContents"
      @cancel="autoPopulateDialog = false"
      @populated="populateSuccess"
      />
    </v-dialog>
    <v-dialog
    v-model="confirmDetailCost"
    persistent
    width="400">
      <DetailCostWarning v-if="confirmDetailCost"
      :activityCost="activity.baseCost.rate"
      :detailCost="detail.cost.rate"
      @cancel="confirmDetailCost = false"
      @create-detail="handleDetailWarningResult"
      />
    </v-dialog>
    <v-card-title :class="contractTypeColor" v-if="!hideTitle">
      <span class="headline">{{title}}</span>
      <v-spacer/>
      <Icon
      v-if="allowAutopopulate"
      iconColor="white"
      icon="mdi-animation"
      dataTestId="detail-populate-button"
      :small="false"
      :tooltipText="$t('autoPopulateButtonText')"
      @icon-clicked="autoPopulateDialog = true"
      />
      <Icon
      iconColor="white"
      icon="mdi-close"
      dataTestId="detail-close"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('cancel')"
      />
    </v-card-title>
    <v-card-text class="mt-4">
      <FormWrapper
        testId="save-detail"
        :buttonText="saveButtonText"
        :buttonColor="contractTypeColor"
        @submit="postActivityDetail"
      >
      <v-container fluid grid-list-md>
        <v-row wrap>
          <v-col cols="12" xs="12" sm="6">
            <ProductAutocomplete
              ref="firstField"
              data-testid="detail-product"
              :contract="contract"
              :excludedProducts="excludedProducts"
              :isDetailForm="true"
              :productId.sync="product.productId"
              :focus.sync="productError"
              @product-chosen="productChosen"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6">
            <BaseCostForm
              data-testid="detail-rate"
              :propBaseCost="detail.cost"
              :focus.sync="costError"
              :editing="true"
              :hidePayBy="true"
              :expectedValue="expectedRateForProduct"
              @base-cost-changed="baseCostChanged"
            />
          </v-col>
        </v-row>
      </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MAX_ACTIVITY_RATE } from '@/utils/rules'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'DetailForm',

  components: {
    ProductAutocomplete: () => import('../../autocomplete/ProductAutocomplete.vue'),
    BaseCostForm: () => import('../BaseCostForm.vue'),
    AutoPopulateDialog: () => import('./AutoDetailPopulate.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    DetailCostWarning: () => import('./DetailCostWarning.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    activity: Object,
    contract: Object,
    editing: Boolean,
    propDetail: Object,
    hideTitle: { Boolean, default: false },
    rateStats: { Object, default: undefined }
  },

  computed: {
    ...mapGetters('product', ['allProducts']),

    allowAutopopulate () {
      return this.activity.activityDetails.length === 0 && this.tractContents.length > 0
    },

    saveButtonText () {
      return this.editing ? this.$t('saveChanges') : this.$t('addDetail')
    },

    title () {
      return this.editing ? this.$t('editingDetail') : this.$t('newDetail')
    },

    expectedRateForProduct () {
      return this.rateStats?.detailStats?.find(s => s.productId === this.product.productId)?.recentAverage
    },

    contractTypeColor () {
      return colorClassForContractType(this.contract?.type)
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.$children[0].focus()
    }, 500)
  },

  data: () => ({
    autoPopulateDialog: false,
    confirmDetailCost: false,
    costError: false,
    productError: false,
    excludedProducts: [],
    tractContents: [],
    product: {},
    detail: {
      cost: {},
      product: {}
    }
  }),

  beforeMount () {
    this.excludedProducts = this.activity.activityDetails.map(d => d.product.name)
  },

  created () {
    this.refreshTractContents(this.contract.tractId)
    if (this.editing) {
      this.detail = JSON.parse(JSON.stringify(this.propDetail))
      this.product = this.detail.product
    } else {
      this.detail.cost = JSON.parse(JSON.stringify(this.activity.baseCost))
    }
  },

  methods: {
    ...mapActions('activity-detail', [
      'createActivityDetail',
      'updateActivityDetail'
    ]),
    ...mapActions('activity', ['updateActivity']),
    ...mapActions('tract-contents', ['fetchTractContents']),

    productChosen (product) {
      this.product = product
    },
    baseCostChanged (baseCost) {
      this.detail.cost = baseCost
    },

    async createDetail (detailRequestObj) {
      try {
        const response = await this.createActivityDetail(detailRequestObj)
        this.$emit('detail-added', response)
        this.resetDetail()
      } catch (error) {
        const { code } = error
        this.handleError(code)
      }
    },

    async updateDetail (detailRequestObj) {
      try {
        const response = await this.updateActivityDetail(detailRequestObj)
        this.$emit('detail-updated', response)
      } catch (error) {
        const { code } = error
        this.handleError(code)
      }
    },

    postActivityDetail () {
      if (!this.validate()) {
        return
      }

      const detailRequestObj = this.getDetailRequestObject()

      if (this.editing) {
        detailRequestObj.activityDetail.activityDetailId = this.propDetail.activityDetailId
        this.updateDetail(detailRequestObj)
      } else {
        this.createDetail(detailRequestObj)
      }
    },

    async refreshTractContents () {
      if (this.contract.tractId) {
        const tractContents = await this.fetchTractContents(this.contract.tractId)
        this.tractContents = tractContents
      }
    },

    handleDetailWarningResult () {
      const detailRequestObj = this.getDetailRequestObject()
      if (this.editing) {
        detailRequestObj.activityDetail.activityDetailId = this.propDetail.activityDetailId
        this.updateDetail(detailRequestObj)
      } else {
        this.createDetail(detailRequestObj)
      }
    },

    handleError (code) {
      const isCostError = code === 'E0036' || 'E0001'
      const isProductError = code === 'E0037' || '8BB6D' || 'E0003'

      if (isCostError) {
        this.costError = !this.costError
      } else if (isProductError) {
        this.productError = !this.productError
      }
    },

    validate () {
      if (!this.product.name) {
        this.setSnackError(this.$t('noProductSelected'))
        return false
      }

      if (this.activity.baseCost.rate > this.detail.cost.rate) {
        this.confirmDetailCost = true
        return false
      }

      if (MAX_ACTIVITY_RATE < this.detail.cost.rate) {
        this.setSnackError(this.$t('invalidActivityRate'))
        return false
      }

      return true
    },

    populateSuccess () {
      this.$emit('detail-added')
      this.autoPopulateDialog = false
    },

    getDetailRequestObject () {
      return {
        activityDetail: {
          rate: parseFloat(this.detail.cost.rate)
        },
        activityId: this.activity.activityId,
        contractId: this.contract.contractId,
        productId: this.product.productId
      }
    },

    resetDetail () {
      this.chosenProductName = ''
      this.detail.productId = -1
      this.detail.cost.rate = this.activity.baseCost.rate
      this.detail.cost.payOn = this.activity.baseCost.payOn
      this.detail.cost.payBy = this.activity.baseCost.payBy
    },
    filter (item, queryText, itemText) {
      return item.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    }
  }
}
</script>
