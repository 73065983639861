import { ContractModes } from '@/utils/Enumerations.js'
export default {
  tickets: [],
  loading: false,
  filteredTickets: [],
  postedTickets: [],
  contractMode: ContractModes[0],
  filters: {
    inTransit: {
      name: 'inTransit',
      icon: 'mdi-truck',
      active: true
    },
    weighedIn: {
      name: 'weighedIn',
      icon: 'mdi-weight',
      active: true
    },
    weighedOut: {
      name: 'weighedOut',
      icon: 'mdi-receipt-text-clock-outline',
      active: true
    },
    readyForPayment: {
      name: 'readyForPayment',
      icon: 'mdi-currency-usd',
      active: true
    },
    posted: {
      name: 'posted',
      icon: 'mdi-checkbox-marked-circle',
      active: false
    },
    internal: {
      name: 'internal',
      icon: 'mdi-home',
      active: false
    },
    missingImages: {
      name: 'missingImages',
      active: false,
      icon: 'mdi-image-off-outline'
    },
    dateRange: {
      name: 'dateRange',
      icon: 'mdi-calendar',
      active: false,
      startDate: undefined,
      endDate: undefined
    },
    advancedSearch: {
      name: 'advancedSearch',
      query: undefined,
      active: false
    },
    phrase: {
      name: 'phrase',
      active: false,
      icon: 'mdi-text',
      phrase: ''
    },
    contract: {
      name: 'contract',
      icon: 'mdi-altimeter',
      active: false,
      contractId: undefined
    },
    tract: {
      name: 'tract',
      icon: 'mdi-crosshairs-gps',
      active: false,
      tractId: undefined
    }
  }
}
