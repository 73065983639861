import ApiServer from '@/utils/api/ApiServer.js'
import moment from 'moment'

export default {
  async fetchAdvances (context, { asAtDate = null, limitToUnpaid = false, hideZeroBalance = false }) {
    context.commit('setLoading', true)

    const asAt = asAtDate ? moment.utc(asAtDate).format() : undefined
    const path = ApiServer.urlFor('advances', {
      version: 2,
      params: {
        asAt,
        limitToUnpaid,
        hideZeroBalance
      }
    })

    try {
      const response = await ApiServer.get(path)
      context.commit('setAdvances', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async postRecovery (_, recoveryrequestObj) {
    const path = `advances/${recoveryrequestObj.advanceId}/recoveries`
    const response = await ApiServer.post(path, recoveryrequestObj.recovery)
    return response.data
  },
  async getAdvance (_, advanceId) {
    const response = await ApiServer.get(`advances/${advanceId}`)
    return response.data
  },
  async deleteAdvance (context, requestObj) {
    const response = await ApiServer.delete(`accounts/${requestObj.payeeId}/advances/${requestObj.advanceId}`)
    context.commit('removeAdvance', requestObj.advanceId)
    return response.data
  },
  async createAdvance (context, requestObj) {
    const path = `accounts/${requestObj.payeeId}/advances`
    const response = await ApiServer.post(path, requestObj)
    context.commit('addAdvance', response.data)
    return response.data
  },
  async updateAdvance (_, requestObj) {
    const path = `accounts/${requestObj.payeeId}/advances/${requestObj.advanceId}`
    const response = await ApiServer.put(path, requestObj)
    return response.data
  },
  async fetchAdvancesForAccount (context, accountId) {
    try {
      context.commit('setLoading', true)
      const path = `v2/accounts/${accountId}/advances`
      const response = await ApiServer.get(path)
      context.commit('setAdvancesForAccount', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchModificationsForAdvance (context, requestObj) {
    try {
      context.commit('setLoading', true)
      const response = await ApiServer.get(`advances/${requestObj.advanceId}/modifications`)
      context.commit('setAdvanceModifications', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchModification (context, requestObj) {
    try {
      context.commit('setLoading', true)
      const response = await ApiServer.get(`advances/${requestObj.advanceId}/modifications/${requestObj.advanceModificationId}`)
      context.commit('setAdvanceModification', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async createAdvanceModification (context, requestObj) {
    const path = `advances/${requestObj.advanceId}/modifications`
    const response = await ApiServer.post(path, requestObj)
    context.commit('setAdvanceModification', response.data)
    return response.data
  }
}
