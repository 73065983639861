<template>
  <v-dialog v-model="dialog" persistent width="500px">
    <v-card>
      <v-card-title class="error headline white--text">{{$t('unauthorizedAccount')}}</v-card-title>
      <v-card-text class="mt-6">
        <p class="subtitle-1">{{ $t('signOutBody') }}</p>
        <p class="subtitle-1">{{ signedInAccountText }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="error" text @click="signOutConfirmed">{{$t('signOut')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SignOutDialog',

  data: () => ({
    dialog: false
  }),

  watch: {
    invalidAccount (val) {
      if (val) {
        this.dialog = true
      }
    }
  },

  computed: {
    ...mapGetters('user', ['userInfo', 'invalidAccount']),

    signedInAccountText () {
      const email = this.userInfo?.preferredUsername ?? this.invalidAccount?.username
      if (!email) return ''
      return this.$t('signedInAccount', { email: email.toLowerCase() })
    }
  },

  methods: {
    ...mapActions('user', ['logout', 'setInvalidAccount']),
    async signOutConfirmed () {
      this.setInvalidAccount(null)
      this.logout(true)
      this.dialog = false
    }
  }
}
</script>
