<template>
  <v-card data-testid="pay-period-form">
    <v-card-title class="secondary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="payPeriodForm"
        testId="save-pay-period"
        :disabled="isButtonDisabled"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="savePayPeriod"
      >
      <v-container grid-list-lg class="mt-6">
        <v-row dense>
          <v-col cols="12" sm="12" md="12" lg="4">
            <DatePicker
            dataTestId="pay-period-start-date"
            :disabled="lastPayPeriod !== undefined || propPayPeriod"
            :dateLabel="$t('startDate')"
            :startDate="payPeriod.startDate"
            @valid-date="(val) => {isValidStartDate = val}"
            @date-picked="startDateChosen"/>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <DatePicker
            dataTestId="pay-period-end-date"
            focusInput
            :dateLabel="$t('endDate')"
            :startDate="payPeriod.endDate"
            @valid-date="(val) => {isValidEndDate = val}"
            @date-picked="endDateChosen"/>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <DatePicker
            v-if="!rerenderPayDate"
            dataTestId="pay-period-pay-date"
            :dateLabel="$t('payDate')"
            :startDate="payPeriod.payDate"
            @valid-date="(val) => {isValidPayDate = val}"
            @date-picked="payDateChosen"/>
          </v-col>
        </v-row>
      </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions } from 'vuex'
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import moment from 'moment'
export default {
  name: 'PayPeriodForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    propPayPeriod: Object,
    lastPayPeriod: Object,
    fiscalYear: Object
  },

  data: () => ({
    rules: fieldRules.rules,
    rerenderPayDate: false,
    isValidStartDate: true,
    isValidEndDate: true,
    isValidPayDate: true,
    payPeriod: {
      startDate: new Date().toISOString(),
      payDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    }
  }),

  created () {
    this.setInitialDates()
  },

  computed: {
    title () {
      return this.propPayPeriod ? this.$t('updatingPayPeriod') : this.$t('creatingPayPeriod')
    },

    saveBtnText () {
      return this.propPayPeriod ? this.$t('saveChanges') : this.$t('createNewPayPeriod')
    },

    isButtonDisabled () {
      return !(this.isValidStartDate && this.isValidEndDate && this.isValidPayDate)
    }
  },

  methods: {
    ...mapActions('pay-period', ['createPayPeriod', 'updatePayPeriod']),

    startDateChosen (date) {
      this.payPeriod.startDate = date
    },

    setInitialDates () {
      if (this.propPayPeriod) {
        const endDate = moment.utc(this.propPayPeriod.endDate).add(-1, 'days').format()
        const payDate = moment.utc(this.propPayPeriod.payDate).add(-1, 'days').format()
        this.payPeriod = {
          startDate: utcToLocalDate(this.propPayPeriod.startDate),
          endDate: utcToLocalDate(endDate),
          payDate: utcToLocalDate(payDate),
          payPeriodId: this.propPayPeriod.payPeriodId
        }
      } else if (this.lastPayPeriod) {
        this.setPayPeriodDates(this.lastPayPeriod)
      } else {
        const fyStartDate = moment(this.fiscalYear.startDate)

        if (fyStartDate.year() > moment().year()) {
          this.payPeriod.startDate = this.fiscalYear.startDate
          this.payPeriod.endDate = moment(this.payPeriod.startDate).add(6, 'days').format()
          this.payPeriod.payDate = moment(this.payPeriod.startDate).add(6, 'days').format()
          return
        }

        let startDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0).year(fyStartDate.year())
        if (!startDate.isAfter(fyStartDate)) {
          startDate = fyStartDate.format()
        }
        this.payPeriod.startDate = startDate
        this.payPeriod.endDate = moment(startDate).month(moment().month()).add(7, 'days').format()
        this.payPeriod.payDate = moment(startDate).month(moment().month()).add(7, 'days').format()
      }

      if (this.payPeriod.endDate > this.fiscalYear.endDate) {
        this.payPeriod.endDate = moment(this.fiscalYear.endDate).hours(0).minutes(0).seconds(0).format()
        this.payPeriod.payDate = moment(this.fiscalYear.endDate).hours(0).minutes(0).seconds(0).format()
      }
    },

    endDateChosen (date) {
      const payDateDiff = (this.getDaysBetweenDates(this.payPeriod.endDate, this.payPeriod.payDate))
      this.payPeriod.payDate = moment(date).add(payDateDiff, 'days').format()
      this.rerenderPayDate = true

      this.$nextTick(_ => {
        this.rerenderPayDate = false
      })

      this.payPeriod.endDate = date
      this.isValidPayDate = true
    },

    payDateChosen (date) {
      this.payPeriod.payDate = date
    },

    getDaysBetweenDates (dateOne, dateTwo) {
      const startDate = new Date((dateOne))
      const endDate = new Date((dateTwo))
      const diffTime = Math.abs(endDate - startDate)
      return Math.min(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 14)
    },

    setPayPeriodDates (lastPayPeriod) {
      this.payPeriod.startDate = utcToLocalDate(lastPayPeriod.endDate)
      this.payPeriod.endDate = moment(this.payPeriod.startDate).hours(0).minutes(0).seconds(0).add(this.getDaysBetweenDates(lastPayPeriod.startDate, lastPayPeriod.endDate), 'days').format()
      this.payPeriod.payDate = moment(this.payPeriod.endDate).hours(0).minutes(0).seconds(0).add(this.getDaysBetweenDates(lastPayPeriod.endDate, lastPayPeriod.payDate), 'days').format()
    },

    async savePayPeriod () {
      if (!this.validate()) {
        return
      }

      const ppRequestObj = {
        fiscalYearId: this.fiscalYear.fiscalYearId,
        startDate: localToUTC(this.payPeriod.startDate),
        endDate: localToUTC(moment(this.payPeriod.endDate).add(1, 'days').format()),
        payDate: localToUTC(moment(this.payPeriod.payDate).add(1, 'days').format())
      }

      if (this.propPayPeriod) {
        ppRequestObj.payPeriodId = this.propPayPeriod.payPeriodId
        await this.updatePayPeriod(ppRequestObj)
        this.$emit('pay-period-mutated')
      } else {
        await this.createPayPeriod(ppRequestObj)
        this.$emit('pay-period-mutated')
      }
    },

    validate () {
      const { startDate, endDate, payDate } = this.payPeriod
      if (startDate > endDate) {
        this.setSnackError(this.$t('startDateBeforeEndDate'))
        return false
      } else if (endDate > payDate) {
        this.setSnackError(this.$t('endDateBeforePayDate'))
        return false
      } else if (startDate > payDate) {
        this.setSnackError(this.$t('startDateBeforePayDate'))
        return false
      }

      return true
    }
  }
}
</script>
