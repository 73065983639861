<template>
  <CsvImport
    v-bind="{
      parse,
      preview,
      upload,
      exampleData,
      entityName,
      rowName
    }"
    @data-uploaded="$emit('usersImported')"
  >
    <template v-for="(claimHeader, i) in claimsHeaders" v-slot:[`preview-item.${claimHeader.value}`]="{ value }">
      <div :key="`claim-icon-${claimHeader.value}-${i}`">
        <Icon
        v-if="value"
        icon="mdi-checkbox-marked-circle"
        iconColor="success"
        :tooltipText="$t('assigned')"
        tooltipColor="success"
        small
        />
        <span v-else/>
      </div>
    </template>
  </CsvImport>
</template>

<script>
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import { importUserHeaders } from '../../../headers/User'
import { rules } from '@/utils/FormRules.js'
import { UserClaims } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
export default {
  name: 'UserImports',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    CsvImport: () => import('./CsvImport.vue')
  },

  computed: {
    entityName () { return this.$t('user') },
    rowName () { return u => u.preferredUsername },

    parse () {
      return {
        schema: {
          preferredUsername: CsvColumn.from({
            name: 'preferredUsername',
            type: CsvTypeTransformation.from({
              validate: rules.validEmail
            })
          }),
          name: CsvColumn.from({
            name: 'name',
            type: CsvTypeTransformation.from({
              validate: s => s === '' || s.length > 64 ? this.$t('invalidUsername') : true
            })
          }),
          objectId: CsvColumn.from({
            name: 'objectId',
            type: CsvTypeTransformation.from({
              validate: rules.validGuid
            })
          }),
          roles: Object.fromEntries(UserClaims.enums.map(claim => [claim.label, CsvColumn.from({
            name: claim.label,
            required: false,
            type: CsvTypeTransformation.from({
              transform: s => s === '1',
              validate: (_, rawArg) => rawArg === '1' || rawArg === '0' || (!!rawArg === false)
            })
          })]))
        }
      }
    },

    preview () {
      return {
        map: user => ({
          ...user,
          ...user.roles
        }),

        headers: importUserHeaders().concat(this.claimsHeaders)
      }
    },

    upload () {
      return {
        map: user => ({
          ...user,
          roles: Object.entries(user.roles).map(([name, assigned]) => {
            return { systemClaim: UserClaims.forLabel(name).value, value: assigned ? 1 : 0 }
          })
        }),
        action: this.importUser
      }
    },

    exampleData () {
      return `data:text/csv;charset=utf-8,preferredUsername,name,objectId,${this.sampleRolesHeadersString}\nemail1@email.com,Name1,87800458-16c0-4697-8d3b-7ede9cc87722,${this.sampleRolesAssignmentsString()}\nemail2@email.com,Name2,b6eb4c6f-2dcb-4ed0-a372-d17cb5469c94,${this.sampleRolesAssignmentsString()}`
    },

    sampleRolesHeadersString () {
      return UserClaims.enums.map(c => c.label).toString()
    },

    claimsHeaders () {
      return UserClaims.enums.map(claim => ({
        sortable: true,
        text: claim.name,
        value: claim.label,
        align: 'center'
      }))
    }
  },

  methods: {
    ...mapActions('user', ['importUser']),
    sampleRolesAssignmentsString () {
      return UserClaims.enums.map(() => Math.random() < 0.5 ? '0' : '1').join(',')
    }
  }
}
</script>
