<template>
  <v-card data-testid="fiscal-year-table">
    <v-dialog v-model="dialog" :max-width="this.$vuetify.breakpoint.mdAndDown ? `50vw` : `600`">
      <FiscalYearForm
      :propFiscalYear="focusedFiscalYear"
      @fiscal-year-mutated="closeDialog(true)"
      @close="closeDialog(false)"
      :lastFiscalYear="lastFiscalYear"
      v-if="dialog"
      ></FiscalYearForm>
    </v-dialog>
    <v-dialog v-model="deleting" width="400">
      <ConfirmDelete
        @close="closeDialog"
        v-if="deleting"
        :title="$t('fiscalYear')"
        @delete="deleteItem"
        @cancel-delete="closeDialog"/>
    </v-dialog>
    <v-card-title class="secondary white--text headline">
      <span>
        {{$t('manageFiscalYears')}}
      </span>
      <v-spacer/>
      <Icon
      icon="mdi-refresh"
      :small="false"
      :large="true"
      icon-color="white"
      tooltipColor="secondary"
      dataTestId="fiscal-year-refresh-button"
      :tooltipText="$t('refresh')"
      @icon-clicked="refreshFiscalYears"
      />
      <Icon
      icon="mdi-plus"
      :small="false"
      :large="true"
      icon-color="white"
      tooltipColor="secondary"
      dataTestId="fiscal-year-create-button"
      :tooltipText="$t('new')"
      @icon-clicked="openCreateFiscalYear"
      :disabled="!userAssignedClaim(UserClaims.ContractManager)"
      />
      <Icon
      icon="mdi-close"
      :small="false"
      :large="true"
      icon-color="white"
      tooltipColor="secondary"
      dataTestId="fiscal-year-close-button"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
      class="mt-2"
      :items="allFiscalYears"
      :headers="headers">
        <template #item.status="{item}">
          <Icon
          dataTestId="fiscal-year-status"
          :icon="getStatus(item).icon"
          :small="false"
          :icon-color="getStatus(item).iconColor"
          :tooltipColor="getStatus(item).tooltipColor"
          :tooltipText="getStatus(item).tooltipText"/>
        </template>
        <template #item.actions="{item}">
          <Icon
          tooltipColor="error"
          :tooltipText="$t('delete')"
          iconColor="error"
          icon="mdi-delete-forever"
          dataTestId="fiscal-year-delete-button"
          @icon-clicked="openDeleteFiscalYear(item)"
          :disabled="!userAssignedClaim(UserClaims.ContractManager)"
          />
        </template>
        <template #item.startDate="{item}">
          {{ utcToLocalDate(item.startDate) }}
        </template>
        <template #item.endDate="{item}">
          {{ getEndDate(item.endDate)}}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiscalYearHeaders from '@/headers/FiscalYear.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import moment from 'moment'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'FiscalYear',

  data: () => ({
    dialog: false,
    deleting: false,
    focusedFiscalYear: undefined,
    UserClaims
  }),

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    FiscalYearForm: () => import('./FiscalYearForm.vue')
  },

  computed: {
    ...mapGetters('fiscal-year', ['allFiscalYears']),
    headers () {
      return FiscalYearHeaders.fiscalYearHeaders(this.$i18n.locale)
    },

    lastFiscalYear () {
      let lastFiscalYear = this.allFiscalYears[0]

      for (let i = 0; i < this.allFiscalYears.length; i++) {
        const fiscalYear = this.allFiscalYears[i]
        if (lastFiscalYear.startDate < fiscalYear.startDate) {
          lastFiscalYear = fiscalYear
        }
      }

      return lastFiscalYear
    }
  },

  methods: {
    ...mapActions('fiscal-year', ['fetchFiscalYears', 'deleteFiscalYear']),
    utcToLocalDate,
    userAssignedClaim,
    refreshFiscalYears () {
      this.fetchFiscalYears()
    },

    getEndDate (endDate) {
      // End dates for fiscal years are saved server side with one day added.
      const formatted = moment.utc(endDate).subtract(1, 'days').format()
      return utcToLocalDate(formatted)
    },

    async deleteItem () {
      await this.deleteFiscalYear(this.focusedFiscalYear)
      this.closeDialog(true)
    },

    openCreateFiscalYear () {
      this.focusedFiscalYear = undefined
      this.dialog = true
    },

    openDeleteFiscalYear (fiscalYear) {
      this.focusedFiscalYear = fiscalYear
      this.deleting = true
    },

    closeDialog (refresh) {
      if (refresh) {
        this.refreshFiscalYears()
      }

      this.focusedFiscalYear = undefined
      this.dialog = false
      this.deleting = false
    },

    getStatus (fiscalYear) {
      switch (fiscalYear.status) {
        case 0:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case 1:
          return {
            color: 'green',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case 2:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
      }
    }
  }
}
</script>
