<template>
  <div>
    <v-tooltip bottom :color="color" v-if="hasImages">
      <template #activator="{on}">
        <v-icon
        :disabled="defectId === -1"
        v-on="on"
        color="grey"
        @click="$emit('image-clicked', defectId, ticketId)"
        style="padding-right: 0.20em"
        >
          mdi-image-broken-variant
        </v-icon>
      </template>
      <span class="subtitle-1 white--text" style="white-space: pre-line">{{$t('defectImagesTitle')}}</span>
    </v-tooltip>
    <slot>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'DefectName',

  props: {
    hasImages: {
      type: Boolean,
      default: false
    },
    defectId: {
      type: Number,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
