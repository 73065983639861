<template>
  <v-card>
    <v-card-title class="secondary">
      <span class="tertiary--text headline">{{ title }}</span>
      <v-spacer />
      <Icon
        dataTestId="contact-close"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        :large="true"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <FormWrapper
          strongValidation
          :buttonText="$t('modifyScale', { mode: this.$t('save') })"
          :disabled="!enableSubmit"
          @submit="saveChangesPressed"
        >
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-select
                v-model="chosenConnectionType"
                item-color="secondary"
                :label="$t('connectionType')"
                :items="ReceiverScaleConnectionTypes.enums"
                item-text="name"
                return-object
                color="black"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="scaleEdit.name"
                data-testid="scale-name"
                color="black"
                maxLength="32"
                counter="32"
                :label="$t('scaleName')"
                :rules="nameFieldValidationRules"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="scaleEdit.address"
                data-testid="scale-address"
                color="black"
                maxLength="256"
                :label="$t('scaleAddress')"
                :rules="addressFieldValidationRules"
              />
            </v-col>
            <v-col v-if="displayPort" cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="scaleEdit.port"
                data-testid="scale-port"
                color="black"
                hide-spin-buttons
                type="number"
                :label="$t('scalePort')"
                :rules="[rules.positiveInteger, rules.validNumericRange(0, 65535)]"
              />
            </v-col>
            <v-col v-if="displayWeighInMotion" cols="12" sm="12" md="6" lg="6">
              <v-checkbox
                v-model="scaleEdit.weighInMotion"
                :label="$t('weighInMotion')"
              />
            </v-col>
            <v-col v-if="displaySkipDelimiter" cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="scaleEdit.delimiter"
                data-testid="scale-skip"
                hide-spin-buttons
                color="black"
                type="number"
                :label="$t('scaleDelimiter')"
                :rules="[rules.positiveInteger]"
              />
            </v-col>
            <v-col v-if="displaySkipDelimiter" cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="scaleEdit.skip"
                data-testid="scale-skip"
                hide-spin-buttons
                color="black"
                type="number"
                :label="$t('scaleSkip')"
                :rules="[rules.positiveInteger, rules.validNumericRange(0, 63)]"
              />
            </v-col>
          </v-row>
        </FormWrapper>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { ReceiverScaleConnectionTypes } from '@/utils/Enumerations.js'
export default {
  name: 'ScaleForm',

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propScale: {
      type: Object,
      default: undefined
    },
    uniqueScaleItems: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    scaleEdit: {
      name: '',
      address: '',
      connectionType: '',
      weighInMotion: false,
      port: 10001,
      skip: null,
      delimiter: null
    },
    chosenConnectionType: ReceiverScaleConnectionTypes.HTTP,
    ReceiverScaleConnectionTypes
  }),

  created () {
    if (this.propScale) {
      this.scaleEdit = JSON.parse(JSON.stringify(this.propScale))
      this.chosenConnectionType = this.scaleEdit.connectionType
    }
  },

  computed: {
    title () {
      return this.propScale === undefined ? this.$t('modifyScale', { mode: this.$t('adding') }) : this.$t('modifyScale', { mode: this.$t('editing', { name: '' }) })
    },
    creating () {
      return this.propScale === undefined
    },
    displayPort () {
      return this.chosenConnectionType.value === ReceiverScaleConnectionTypes.TCP.value || this.chosenConnectionType.value === ReceiverScaleConnectionTypes.Custom.value
    },
    displayWeighInMotion () {
      return this.chosenConnectionType.value === ReceiverScaleConnectionTypes.TCP.value
    },
    displaySkipDelimiter () {
      return this.chosenConnectionType.value === ReceiverScaleConnectionTypes.Custom.value
    },
    enableSubmit () {
      if (this.displaySkipDelimiter) {
        if (isNaN(this.scaleEdit.port) || isNaN(this.scaleEdit.skip) || isNaN(this.scaleEdit.delimiter) || this.chosenConnectionType === undefined) {
          return false
        }
        return this.chosenConnectionType?.value !== this.propScale?.connectionType.value || this.scaleEdit.name !== this.propScale?.name || this.scaleEdit.address !== this.propScale?.address ||
        Number(this.scaleEdit.port) !== Number(this.propScale?.port) || Number(this.scaleEdit.skip) !== Number(this.propScale?.skip) || Number(this.scaleEdit.delimiter) !== Number(this.propScale?.delimiter)
      } else if (this.displayWeighInMotion) {
        if (isNaN(this.scaleEdit.port) || this.chosenConnectionType === undefined) {
          return false
        }
        return this.chosenConnectionType?.value !== this.propScale?.connectionType.value || this.scaleEdit.name !== this.propScale?.name || this.scaleEdit.address !== this.propScale?.address ||
        Number(this.scaleEdit.port) !== Number(this.propScale?.port) || this.scaleEdit.weighInMotion !== this.propScale?.weighInMotion
      } else {
        if (this.chosenConnectionType === undefined) {
          return false
        }
        return this.chosenConnectionType?.value !== this.propScale?.connectionType.value || this.scaleEdit.name !== this.propScale?.name || this.scaleEdit.address !== this.propScale?.address
      }
    },
    addressFieldValidationRules () {
      if (this.displayWeighInMotion && this.creating) {
        return [this.rules.required, this.rules.validIpAddress, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleAddresses, new Set(), 'duplicateScaleAddress')]
      } else if (this.displayWeighInMotion) {
        return [this.rules.required, this.rules.validIpAddress, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleAddresses, new Set().add(this.propScale.address.toLowerCase()), 'duplicateScaleAddress')]
      } else if (this.creating) {
        return [this.rules.required, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleAddresses, new Set(), 'duplicateScaleAddress')]
      } else {
        return [this.rules.required, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleAddresses, new Set().add(this.propScale.address.toLowerCase()), 'duplicateScaleAddress')]
      }
    },
    nameFieldValidationRules () {
      if (this.creating) {
        return [this.rules.required, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleNames, new Set(), 'duplicateScaleName')]
      } else {
        return [this.rules.required, this.rules.validUniqueStringCollection(this.uniqueScaleItems.scaleNames, new Set().add(this.propScale.name.toLowerCase()), 'duplicateScaleName')]
      }
    }
  },

  methods: {
    saveChangesPressed () {
      if (this.chosenConnectionType.value === ReceiverScaleConnectionTypes.HTTP.value) {
        this.scaleEdit.port = null
        this.scaleEdit.delimiter = null
        this.scaleEdit.skip = null
        this.scaleEdit.weighInMotion = false
      } else if (this.displayWeighInMotion) {
        this.scaleEdit.delimiter = null
        this.scaleEdit.skip = null
      } else {
        this.scaleEdit.weighInMotion = false
      }
      const scaleObject = {
        receiverScaleSettingId: this.scaleEdit.receiverScaleSettingId,
        name: this.scaleEdit.name,
        address: this.scaleEdit.address,
        port: (!this.scaleEdit.port || this.scaleEdit.port === '') ? 10001 : this.scaleEdit.port,
        delimiter: this.scaleEdit.delimiter,
        weighInMotion: this.scaleEdit.weighInMotion,
        skip: this.scaleEdit.skip,
        connectionType: this.chosenConnectionType
      }
      if (!this.propScale) {
        this.$emit('create-scale', scaleObject)
      } else {
        this.$emit('edit-scale', scaleObject)
      }
      this.$emit('close')
    }
  }
}
</script>
