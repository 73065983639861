<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-if="filterTypes" cols="12" class="pt-4 pb-2">
        <v-row dense>
          <v-col cols="12" md="6">
            <div>
              <v-select
                v-if="Object.keys(filterItems).length > 0"
                clearable
                :items="filterTypes.filter(ft => this.filterItems[ft?.key]?.length > 0)"
                return-object
                :value="filterType"
                @input="setFilterType"
                :label="$t('filterBy')"
                outlined
                rounded
                dense
                class="mb-n6 mr-2"
              >
                <template #item="{ item, on }">
                  <v-list-item v-on="on">
                    <v-icon class="mr-2">{{getIconForObjectType(item.key)}}</v-icon>
                    <span>{{item.name}}</span>
                  </v-list-item>
                </template>
                <template #selection="{ item } ">
                  <template>
                    <v-icon class="mr-2">{{getIconForObjectType(item.key)}}</v-icon>
                    <span>{{item.name}}</span>
                  </template>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <v-select
                v-if="filterItems"
                v-show="filterType?.name"
                clearable
                :items="filterItems[filterType?.key]"
                return-object
                :value="selectedFilter"
                @input="setFilter"
                outlined
                rounded
                dense
                :label="filterType?.name"
                class="mb-n6 mr-2"
                :disabled="filterItems[filterType?.key]?.length <= 1"
              >
                <template #item="{ item, on }">
                  <v-list-item v-on="on">
                    <span>{{item}}</span>
                  </v-list-item>
                </template>
                <template #selection="{ item } ">
                  <template>
                    <span>{{item}}</span>
                  </template>
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <DataTable
          :items="itemsFiltered || corrections"
          :headers="headers"
          :selected.sync="selectedItemsModel"
          :customCells="customCells"
          :showSelect="showSelect"
          tableKey="ticketCorrectionId"
          :keepSelectionWhenItemsChange="filterTypes != undefined"
          :showSearch="showSearch"
          :showDense="showDense"
        >
          <template #actions="{item}">
            <Icon
            icon="mdi-information-outline"
            margin="mr-1"
            dataTestId="correction-detail-button"
            :small="false"
            :tooltipText="$t('correctionDetails')"
            @icon-clicked="viewDetailedCorrection(item)"/>
          </template>
          <template #footer v-if="showSelect">
            <v-col class="pt-4">
              <span class="grey--text">{{'*' + $t(contractMode.value === logsMode ? 'tractAccountDestinationSettingMayBeResultOfCorrections' : 'accountDestinationMayBeResultOfCorrections')}}</span>
            </v-col>
          </template>
        </DataTable>
      </v-col>
  </v-row>
    <DetailedCorrectionDialog
    v-if="correctionDetailDialog"
    :ticketId="focusedCorrection.ticketId"
    :correctionId="focusedCorrection.ticketCorrectionId"
    @close="closeCorrectionDetail"
    :contractMode="contractMode"
    />
  </v-container>
</template>

<script>
import { ticketCorrectionHeaders } from '@/headers/ExportBatch.js'
import { ContractModes } from '@/utils/Enumerations.js'
import getIconForObjectType from '@/utils/IconHelpers'

export default {
  name: 'SettlementCorrections',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    DetailedCorrectionDialog: () => import('@/components/ticket/corrections/DetailedCorrectionDialog.vue')
  },

  props: {
    corrections: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      required: false
    },
    showSelect: {
      type: Boolean,
      required: false
    },
    contractMode: {
      type: Object,
      required: true
    },
    filterTypes: {
      type: Array,
      required: false,
      default: undefined
    },
    filterTypeFromStorage: {
      type: String,
      required: false,
      default: undefined
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showDense: {
      type: Boolean,
      default: true
    }
  },

  async created () {
    if (this.filterTypes) {
      if (this.filterTypeFromStorage) this.filterType = this.filterTypes.find(ft => ft.key === this.filterTypeFromStorage)
      this.setFilterItems()
      this.setFilterType(this.filterType)
    }
  },

  data: () => ({
    getIconForObjectType,
    correctionDetailDialog: false,
    focusedCorrection: undefined,
    filterType: undefined,
    selectedFilter: undefined,
    filterItems: {},
    itemsFiltered: undefined
  }),

  watch: {
    selectedFilter: {
      deep: true,
      handler (val) {
        this.setFilter(val)
      }
    }
  },

  computed: {
    selectedItemsModel: {
      get () { return this.selectedItems },
      set (value) {
        this.$emit('update:selectedItems', value)
      }
    },
    headers () {
      return this.contractMode.value === this.logsMode
        ? ticketCorrectionHeaders().filter(i => i.value !== 'fromAccount')
        : ticketCorrectionHeaders().filter(i => i.value !== 'tract' && i.value !== 'setting')
    },

    customCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },

    logsMode () {
      return ContractModes.find(i => i.text === this.$t('logs')).value
    }
  },

  methods: {
    async viewDetailedCorrection (correction) {
      this.focusedCorrection = correction
      this.correctionDetailDialog = true
    },

    closeCorrectionDetail () {
      this.focusedCorrection = undefined
      this.correctionDetailDialog = false
    },

    setFilterType (ft) {
      this.filterType = ft
      this.selectedFilter = undefined
      this.$emit('filter-type-set', ft?.key)
      if (this.filterItems[this.filterType?.key]?.length === 1) this.setFilter(this.filterItems[this.filterType?.key][0])
    },

    setFilter (f) {
      this.selectedFilter = f
      this.itemsFiltered = f
        ? this.corrections.filter(t => t[this.filterType?.key] === this.selectedFilter)
        : this.corrections
    },

    setFilterItems () {
      this.filterTypes.forEach(ft => {
        this.filterItems[ft.key] = [...new Set(this.corrections.map(t => t[ft.key]))].filter(i => i !== null && i.length > 0).sort((a, b) => a.localeCompare(b))
      })
      this.setFilter(undefined)
      if (this.filterItems[this.filterType?.key]?.length < 1) this.setFilterType(undefined)
      if (this.filterItems[this.filterType?.key]?.length === 1) this.setFilter(this.filterItems[this.filterType?.key][0])
    }
  }
}
</script>
