<template>
  <v-container fluid>
    <DataTable
      :items.sync="ticketDetails"
      :headers="headers"
      :customCells="customTicketSummaryCells"
      :hideHeader="true"
      >
        <template #ticket-number="{item}">
          {{getTicketNumber(item)}}
        </template>
        <template #product="{item}">
          {{item.productName}}
        </template>
        <template #in-weight-tons="{item}">
          {{tonStringFromPounds(item.inWeight)}}
        </template>
        <template #out-weight-tons="{item}">
          {{tonStringFromPounds(item.outWeight)}}
        </template>
        <template #net-weight-tons="{item}">
          {{tonStringFromPounds(item.netWeight)}}
        </template>
        <template #defect-weight-tons="{item}">
          {{tonStringFromPounds(item.defectWeight)}}
          <Icon
            v-if="item.loadDefects.length > 0"
            iconColor="black"
            dataTestId="ticket-sum-view-defect"
            @icon-clicked="viewTicketDefects(item)"
            icon="mdi-dots-horizontal-circle-outline"
            tooltipText="View Defects"/>
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td class="text-left">Totals</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">{{tonStringFromPounds(totals.totalInWeight)}}</td>
            <td class="text-right">{{tonStringFromPounds(totals.totalOutWeight)}}</td>
            <td class="text-right">{{tonStringFromPounds(totals.totalDefectWeight)}}</td>
            <td class="text-right">{{tonStringFromPounds(totals.totalNetWeight)}}</td>
          </tr>
      </template>
    </DataTable>
    <Dialog :stateId="dialogId" @close="close">
      <TicketDefectCard
        v-if="ticketDefectsDialog"
        :ticketDefects="focusedDefects"
        @close="close"
      />
    </Dialog>
  </v-container>
</template>

<script>
import { ticketRegisterHeaders } from '@/headers/Cycles.js'
import { customTicketSummaryCells } from './CustomTicketSummaryCells.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapActions } from 'vuex'
export default {
  name: 'TicketSummaryCard',

  props: {
    ticketDetails: {
      type: Array,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TicketDefectCard: () => import('@/components/defects/TicketDefectCard.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    contractDialog: false,
    ticketDefectsDialog: false,
    focusedDefects: undefined,
    dialogId: 'ticket-summary-defect',
    customTicketSummaryCells
  }),

  computed: {
    totals () {
      const totals = {
        totalInWeight: 0,
        totalOutWeight: 0,
        totalDefectWeight: 0,
        totalNetWeight: 0
      }

      this.ticketDetails.forEach(t => {
        totals.totalInWeight += t.inWeight
        totals.totalOutWeight += t.outWeight
        totals.totalDefectWeight = t.defectWeight
      })

      totals.totalNetWeight = totals.totalInWeight - totals.totalOutWeight - totals.totalDefectWeight

      return totals
    },
    headers () {
      return ticketRegisterHeaders(this.$i18n.locale)
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    tonStringFromPounds,

    viewTicketDefects (ticket) {
      this.dialogId = `ticket-summary-defect-${ticket.ticketId}`
      this.focusedDefects = ticket.loadDefects
      this.ticketDefectsDialog = true
      this.$nextTick(() => {
        this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
      })
    },

    getTicketNumber ({ ticketNumber, extTicketNumber1 }) {
      var str = `${ticketNumber}`

      if (extTicketNumber1 !== '' && extTicketNumber1 !== null && extTicketNumber1 !== undefined) {
        str += ` (${extTicketNumber1})`
      }

      return str
    },

    close () {
      this.focusedDefects = undefined
      this.ticketDefectsDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }
}
</script>
