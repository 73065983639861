<template>
  <v-card>
    <v-card-title class="secondary">
      <v-row>
        <v-col>
          <span class="headline white--text">{{ $t('specifyReceiverLocation') }}</span>
        </v-col>
        <v-col cols="auto">
          <Icon
          icon="mdi-close"
          iconColor="white"
          :small="false"
          large
          :tooltipText="$t('close')"
          @icon-clicked="$emit('close')"/>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="12" class="mt-6">
          <div id="account-map" style="height: 65vh"></div>
        </v-col>
        <v-col cols="auto">
          <v-text-field
          v-model="latitudeTextField"
          :label="$t('latitude')"
          color="black"
          @blur="onTextFieldBlur"
          :rules="[rules.required, rules.decimal, rules.validLatitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
          type="number"
          class="mb-n4"
          hide-spin-buttons/>
        </v-col>
        <v-col cols="auto">
          <v-text-field
          v-model="longitudeTextField"
          :label="$t('longitude')"
          color="black"
          @blur="onTextFieldBlur"
          :rules="[rules.required, rules.decimal, rules.validLongitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
          type="number"
          class="mb-n4"
          hide-spin-buttons/>
        </v-col>
        <v-col>
          <v-btn
          :disabled="!showApplyButton"
          class="mr-3 mt-n2 mb-n4 secondary"
          @click="applyLocation">
          {{ $t('apply') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" class="mb-n4">
          <v-btn
          :disabled="!showSubmitButton"
          class="secondary mt-n2"
          @click="submitLocation">
            {{ $t('submit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as atlas from 'azure-maps-control'
import fieldRules from '@/utils/rules'
import { azureMapsApiKey } from '../../../env-var.js'
import { defaultCoordinates } from '../../utils/MapHelpers'
import { getFormattedCoord, getFormattedCoordWithTrailingZeroes } from '../../utils/NumericMutations'
import { mapResizeTimeout } from '@/utils/MapHelpers'

export default {
  name: 'AccountMap',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propLocation: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    spot: {
      latitude: defaultCoordinates.latitude,
      longitude: defaultCoordinates.longitude
    },
    marker: undefined,
    latitudeTextField: undefined,
    longitudeTextField: undefined,
    resizeObserver: undefined
  }),

  computed: {
    showApplyButton () {
      const latitudeText = parseFloat(this.latitudeTextField)
      const longitudeText = parseFloat(this.longitudeTextField)
      if (isNaN(latitudeText) || isNaN(longitudeText) || latitudeText < -90 || latitudeText > 90 || longitudeText < -180 || longitudeText > 180) {
        return false
      }
      return this.spot.latitude !== latitudeText || this.spot.longitude !== longitudeText
    },

    showSubmitButton () {
      const latitudeText = parseFloat(this.latitudeTextField)
      const longitudeText = parseFloat(this.longitudeTextField)
      if (isNaN(latitudeText) || isNaN(longitudeText) || latitudeText < -90 || latitudeText > 90 || longitudeText < -180 || longitudeText > 180) {
        return false
      }
      return this.spot.latitude !== this.propLocation?.latitude || this.spot.longitude !== this.propLocation?.longitude
    }
  },

  watch: {
    spot: {
      handler (val) {
        this.latitudeTextField = getFormattedCoordWithTrailingZeroes(val.latitude)
        this.longitudeTextField = getFormattedCoordWithTrailingZeroes(val.longitude)
      },
      deep: true
    }
  },

  async mounted () {
    if (this.propLocation?.latitude && this.propLocation?.longitude) {
      this.spot.latitude = this.propLocation.latitude
      this.spot.longitude = this.propLocation.longitude
      this.updateCoordinateTextBoxes()
    } else {
      await this.setLocation()
    }

    setTimeout(() => {
      this.initMap()
      this.resizeObserver = new ResizeObserver(this.onResize).observe(document.getElementById('account-map'))
    }, 100)
  },

  async beforeDestroy () {
    if (this.resizeObserver) await this.resizeObserver.unobserve()
    await this.map.markers.clear()
    this.map = null
  },

  methods: {
    submitLocation () {
      this.$emit('selected-location', this.spot)
    },

    applyLocation () {
      if (this.rules.validLongitude(this.spot.longitude) && this.rules.validLatitude(this.spot.latitude)) {
        const coordinate = {
          latitude: getFormattedCoord(this.latitudeTextField),
          longitude: getFormattedCoord(this.longitudeTextField)
        }
        this.spot.latitude = coordinate.latitude
        this.spot.longitude = coordinate.longitude
        this.setMarkerLocation()
        this.setCamera([this.spot.longitude, this.spot.latitude])
      }
    },

    setMarkerLocation () {
      if (this.marker && this.map) {
        this.marker.setOptions({
          position: [this.spot.longitude, this.spot.latitude]
        })
      }
    },

    initMap () {
      const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
      this.map = new atlas.Map('account-map', {
        center: [this.spot.longitude, this.spot.latitude],
        zoom: 10,
        view: 'Auto',
        autoResize: true,
        style: 'satellite_road_labels',
        authOptions: {
          authType: 'subscriptionKey',
          subscriptionKey: apiKey
        }
      })

      this.map.events.add('ready', () => {
        this.addMarker()
      })
    },

    addMarker () {
      const position = [this.spot.longitude, this.spot.latitude]

      this.marker = new atlas.HtmlMarker({
        draggable: true,
        position: position,
        pixelOffset: [0, 0],
        color: '#D15F27'
      })

      this.map.events.add('dragend', this.marker, (e) => {
        const coordinate = (e.target.marker._lngLat)
        this.handleDrag(coordinate)
      })

      this.map.markers.add(this.marker)
    },

    async handleDrag (coordinate) {
      this.spot.latitude = parseFloat(coordinate.lat.toFixed(6))
      this.spot.longitude = parseFloat(coordinate.lng.toFixed(6))
      this.setCamera([this.spot.longitude, this.spot.latitude])
    },

    onResize () {
      if (this.map) {
        setTimeout(() => this.map.map.resize(), mapResizeTimeout)
      }
    },

    setCamera (coordinate) {
      this.map.setCamera({
        center: coordinate,
        type: 'ease',
        duration: 350
      })
    },

    async requestLocation () {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },

    async setLocation () {
      try {
        const { coords: coordinate } = await this.requestLocation()
        this.spot.latitude = coordinate.latitude
        this.spot.longitude = coordinate.longitude
      } catch (error) {
        this.spot.latitude = defaultCoordinates.latitude
        this.spot.longitude = defaultCoordinates.longitude
        this.updateCoordinateTextBoxes()
      }
    },

    updateCoordinateTextBoxes () {
      this.latitudeTextField = getFormattedCoordWithTrailingZeroes(this.spot.latitude)
      this.longitudeTextField = getFormattedCoordWithTrailingZeroes(this.spot.longitude)
    },

    onTextFieldBlur () {
      if (this.rules.validLatitudeForTextBox(this.latitudeTextField) === true || Math.abs(parseFloat(this.latitudeTextField)) <= 90) {
        this.latitudeTextField = getFormattedCoordWithTrailingZeroes(this.latitudeTextField)
      }
      if (this.rules.validLongitudeForTextBox(this.longitudeTextField) === true || Math.abs(parseFloat(this.longitudeTextField)) <= 180) {
        this.longitudeTextField = getFormattedCoordWithTrailingZeroes(this.longitudeTextField)
      }
    }
  }
}
</script>
