import messages from '@/lang'

export default {
  settingHeaders (locale) {
    return [
      {
        sortable: true,
        text: messages[locale].name,
        value: 'name'
      },
      {
        sortable: false,
        text: messages[locale].actions,
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
