// Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0)
export const legnaSecondaryGray = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 477.73 134.65" style="enable-background:new 0 0 477.73 134.65;" xml:space="preserve">
<style type="text/css">
.st0{fill:#9e9e9e;}
</style>
<g>
<path class="st0" d="M118.91,59.45V2.21h10.82v47.64h22.55v9.59H118.91z M176.21,60.44c-3.17,0-6-0.53-8.49-1.6
c-2.49-1.07-4.59-2.57-6.31-4.51c-1.72-1.94-3.05-4.29-3.98-7.05c-0.93-2.76-1.39-5.86-1.39-9.31c0-3.39,0.45-6.45,1.35-9.18
c0.9-2.73,2.2-5.07,3.9-7.01c1.69-1.94,3.77-3.44,6.23-4.51c2.46-1.07,5.25-1.6,8.36-1.6c3.33,0,6.23,0.57,8.69,1.72
c2.46,1.15,4.48,2.71,6.07,4.67c1.58,1.97,2.77,4.25,3.57,6.85c0.79,2.6,1.19,5.37,1.19,8.32v3.44h-28.45v1.06
c0,3.09,0.87,5.58,2.62,7.45c1.75,1.87,4.35,2.81,7.79,2.81c2.62,0,4.77-0.54,6.44-1.63c1.67-1.09,3.16-2.47,4.47-4.15l5.66,6.33
c-1.75,2.47-4.14,4.4-7.17,5.8C183.72,59.74,180.2,60.44,176.21,60.44z M176.05,23.53c-2.79,0-5,0.93-6.64,2.79
c-1.64,1.86-2.46,4.26-2.46,7.22v0.66h17.55v-0.74c0-2.95-0.73-5.34-2.17-7.17C180.87,24.45,178.78,23.53,176.05,23.53z
M240.83,63.06c0,2.24-0.4,4.22-1.19,5.94c-0.79,1.72-2.09,3.16-3.9,4.31c-1.8,1.15-4.13,2.02-6.97,2.62
c-2.84,0.6-6.31,0.9-10.41,0.9c-3.61,0-6.67-0.23-9.18-0.7c-2.52-0.46-4.58-1.12-6.19-1.97c-1.61-0.85-2.78-1.9-3.49-3.16
c-0.71-1.26-1.07-2.68-1.07-4.26c0-2.41,0.67-4.28,2.01-5.62c1.34-1.34,3.21-2.2,5.62-2.58v-0.9c-1.75-0.44-3.1-1.3-4.06-2.59
c-0.96-1.29-1.43-2.8-1.43-4.56c0-1.09,0.22-2.07,0.66-2.92c0.44-0.85,1.02-1.57,1.76-2.18c0.74-0.6,1.57-1.11,2.5-1.52
c0.93-0.41,1.91-0.72,2.95-0.95v-0.33c-2.62-1.2-4.58-2.87-5.86-5c-1.29-2.13-1.93-4.62-1.93-7.46c0-4.37,1.5-7.88,4.51-10.53
c3.01-2.65,7.41-3.97,13.2-3.97c1.31,0,2.61,0.08,3.9,0.25c1.28,0.16,2.47,0.44,3.57,0.82v-1.39c0-4.15,2.02-6.23,6.07-6.23h6.64
v8.04h-9.1v1.15c2.24,1.26,3.91,2.9,5,4.92c1.09,2.02,1.64,4.35,1.64,6.97c0,4.32-1.49,7.79-4.47,10.41
c-2.98,2.62-7.39,3.94-13.24,3.94c-2.52,0-4.7-0.25-6.56-0.74c-0.82,0.38-1.53,0.89-2.13,1.52c-0.6,0.63-0.9,1.41-0.9,2.34
c0,2.13,1.86,3.2,5.58,3.2h11.48c5.25,0,9.06,1.08,11.44,3.24C239.64,56.24,240.83,59.23,240.83,63.06z M230.99,64.29
c0-1.37-0.55-2.43-1.64-3.2c-1.09-0.77-2.98-1.15-5.66-1.15h-14.6c-1.48,1.09-2.21,2.57-2.21,4.43c0,1.64,0.68,2.92,2.05,3.85
c1.37,0.93,3.69,1.39,6.97,1.39h5.25c3.44,0,5.95-0.44,7.5-1.31C230.21,67.43,230.99,66.09,230.99,64.29z M218.36,37.31
c5.14,0,7.71-2.17,7.71-6.52V29.4c0-4.35-2.57-6.52-7.71-6.52c-5.14,0-7.71,2.17-7.71,6.52v1.39
C210.65,35.14,213.22,37.31,218.36,37.31z M245.14,59.45v-42.8h10.5v7.13h0.41c0.87-2.3,2.25-4.22,4.14-5.78
c1.89-1.56,4.5-2.34,7.83-2.34c4.43,0,7.82,1.45,10.17,4.35c2.35,2.9,3.53,7.03,3.53,12.38v27.06h-10.5V33.46
c0-3.06-0.55-5.36-1.64-6.89c-1.09-1.53-2.9-2.3-5.41-2.3c-1.09,0-2.15,0.15-3.16,0.45c-1.01,0.3-1.91,0.74-2.71,1.31
c-0.79,0.57-1.44,1.29-1.93,2.13c-0.49,0.85-0.74,1.84-0.74,2.99v28.29H245.14z M322.34,59.45c-2.3,0-4.11-0.67-5.45-2.01
c-1.34-1.34-2.17-3.13-2.5-5.37h-0.49c-0.71,2.79-2.16,4.88-4.35,6.27c-2.19,1.39-4.89,2.09-8.12,2.09
c-4.37,0-7.74-1.14-10.09-3.43c-2.35-2.29-3.53-5.34-3.53-9.16c0-4.41,1.58-7.7,4.76-9.85c3.17-2.15,7.68-3.23,13.53-3.23h7.3
v-3.12c0-2.41-0.63-4.26-1.89-5.58c-1.26-1.31-3.28-1.97-6.07-1.97c-2.46,0-4.44,0.53-5.94,1.6c-1.5,1.07-2.78,2.34-3.81,3.81
l-6.23-5.61c1.58-2.47,3.69-4.47,6.31-5.98c2.62-1.51,6.1-2.27,10.41-2.27c5.79,0,10.19,1.31,13.2,3.93
c3.01,2.62,4.51,6.38,4.51,11.29V51h4.26v8.45H322.34z M304.95,52.81c2.35,0,4.35-0.52,5.99-1.55c1.64-1.03,2.46-2.55,2.46-4.56
v-5.62h-6.72c-5.47,0-8.2,1.74-8.2,5.21v1.38c0,1.74,0.56,3.03,1.68,3.87C301.28,52.39,302.87,52.81,304.95,52.81z M21.32,132.44
c-4.92,0-9.09-0.88-12.5-2.63c-3.42-1.75-6.35-4.05-8.81-6.9l7.3-7.06c1.97,2.28,4.17,4.01,6.6,5.21c2.43,1.19,5.12,1.79,8.08,1.79
c3.33,0,5.85-0.72,7.54-2.16c1.69-1.44,2.54-3.38,2.54-5.82c0-1.9-0.55-3.44-1.64-4.64c-1.09-1.19-3.14-2.06-6.15-2.6l-5.41-0.81
C7.43,105,1.72,99.45,1.72,90.14c0-2.57,0.48-4.9,1.44-6.98c0.96-2.08,2.34-3.86,4.14-5.34c1.8-1.48,3.98-2.61,6.52-3.41
s5.42-1.19,8.65-1.19c4.32,0,8.09,0.71,11.32,2.14c3.23,1.42,5.99,3.53,8.28,6.33l-7.38,6.98c-1.42-1.73-3.14-3.14-5.17-4.23
c-2.02-1.08-4.56-1.63-7.63-1.63c-3.12,0-5.45,0.58-7.01,1.75s-2.34,2.8-2.34,4.92c0,2.17,0.63,3.77,1.89,4.8
c1.26,1.03,3.28,1.79,6.07,2.28l5.33,0.99c5.79,1.04,10.07,2.89,12.83,5.54c2.76,2.65,4.14,6.36,4.14,11.12
c0,2.74-0.48,5.21-1.44,7.43c-0.96,2.22-2.35,4.13-4.18,5.75c-1.83,1.61-4.07,2.86-6.72,3.74C27.81,132,24.76,132.44,21.32,132.44z
M68.06,132.44c-3.06,0-5.81-0.52-8.24-1.56c-2.43-1.04-4.51-2.54-6.23-4.51c-1.72-1.97-3.05-4.33-3.98-7.09
c-0.93-2.76-1.39-5.86-1.39-9.31c0-3.44,0.46-6.53,1.39-9.27c0.93-2.73,2.26-5.07,3.98-7.01c1.72-1.94,3.8-3.43,6.23-4.47
c2.43-1.04,5.18-1.56,8.24-1.56c3.06,0,5.82,0.52,8.28,1.56c2.46,1.04,4.55,2.53,6.27,4.47c1.72,1.94,3.05,4.28,3.98,7.01
c0.93,2.73,1.39,5.82,1.39,9.27c0,3.44-0.46,6.55-1.39,9.31c-0.93,2.76-2.26,5.12-3.98,7.09c-1.72,1.97-3.81,3.47-6.27,4.51
C73.88,131.91,71.12,132.44,68.06,132.44z M68.06,123.99c2.79,0,4.97-0.85,6.56-2.54c1.58-1.69,2.38-4.18,2.38-7.46v-7.95
c0-3.22-0.79-5.68-2.38-7.38c-1.59-1.69-3.77-2.54-6.56-2.54c-2.73,0-4.89,0.85-6.48,2.54c-1.59,1.7-2.38,4.16-2.38,7.38v7.95
c0,3.28,0.79,5.77,2.38,7.46C63.17,123.14,65.32,123.99,68.06,123.99z M96.82,96.93H90.5v-8.28h6.31V82.5c0-3.77,1-6.67,2.99-8.69
c2-2.02,4.93-3.03,8.81-3.03h7.46v8.45h-8.77v9.43h8.77v8.28h-8.77v34.52h-10.5V96.93z M136.57,131.45c-3.61,0-6.35-0.94-8.24-2.82
c-1.89-1.88-2.83-4.57-2.83-8.06V97.09h-6.31v-8.45h3.28c1.58,0,2.67-0.37,3.24-1.11c0.57-0.74,0.86-1.84,0.86-3.32v-7.3h9.43
v11.73h8.77v8.45h-8.77V123h8.12v8.45H136.57z M148.61,88.65h10.09l4.35,17.63l3.36,14.68h0.33l3.94-14.68l5-17.63h9.51l5.17,17.63
l4.02,14.68h0.33l3.36-14.68l4.26-17.63h9.76l-11.73,42.8h-11.32l-5.49-18.86l-3.2-11.64h-0.25l-3.12,11.64l-5.41,18.86h-11.15
L148.61,88.65z M248.64,131.45c-2.3,0-4.11-0.67-5.45-2.01c-1.34-1.34-2.17-3.13-2.5-5.37h-0.49c-0.71,2.79-2.16,4.88-4.35,6.27
c-2.19,1.39-4.89,2.09-8.12,2.09c-4.37,0-7.74-1.14-10.09-3.43c-2.35-2.29-3.53-5.34-3.53-9.16c0-4.41,1.58-7.7,4.76-9.85
c3.17-2.15,7.68-3.23,13.53-3.23h7.3v-3.12c0-2.41-0.63-4.26-1.89-5.58c-1.26-1.31-3.28-1.97-6.07-1.97c-2.46,0-4.44,0.53-5.94,1.6
c-1.5,1.07-2.78,2.34-3.81,3.81l-6.23-5.61c1.58-2.47,3.69-4.47,6.31-5.98c2.62-1.51,6.1-2.27,10.41-2.27
c5.79,0,10.19,1.31,13.2,3.93c3.01,2.62,4.51,6.38,4.51,11.29V123h4.26v8.45H248.64z M231.25,124.81c2.35,0,4.35-0.52,5.99-1.55
c1.64-1.03,2.46-2.55,2.46-4.56v-5.62h-6.72c-5.47,0-8.2,1.74-8.2,5.21v1.38c0,1.74,0.56,3.03,1.68,3.87
C227.57,124.39,229.17,124.81,231.25,124.81z M260.69,131.45v-42.8h10.5v8.86h0.41c0.27-1.15,0.7-2.25,1.27-3.32
c0.57-1.07,1.32-2.01,2.25-2.83c0.93-0.82,2.04-1.48,3.32-1.97c1.28-0.49,2.77-0.74,4.47-0.74h2.3v9.92h-3.28
c-3.55,0-6.23,0.52-8.04,1.56c-1.8,1.04-2.71,2.73-2.71,5.08v26.24H260.69z M308.23,132.44c-3.17,0-6-0.53-8.49-1.6
c-2.49-1.07-4.59-2.57-6.31-4.51c-1.72-1.94-3.05-4.29-3.98-7.05c-0.93-2.76-1.39-5.86-1.39-9.31c0-3.39,0.45-6.45,1.35-9.18
c0.9-2.73,2.2-5.07,3.9-7.01c1.69-1.94,3.77-3.44,6.23-4.51c2.46-1.07,5.25-1.6,8.36-1.6c3.33,0,6.23,0.57,8.69,1.72
c2.46,1.15,4.48,2.71,6.07,4.67c1.58,1.97,2.77,4.25,3.57,6.85c0.79,2.6,1.19,5.37,1.19,8.32v3.44h-28.45v1.06
c0,3.09,0.87,5.58,2.62,7.45c1.75,1.87,4.35,2.81,7.79,2.81c2.62,0,4.77-0.54,6.44-1.63c1.67-1.09,3.16-2.47,4.47-4.15l5.66,6.33
c-1.75,2.47-4.14,4.4-7.17,5.8C315.73,131.74,312.22,132.44,308.23,132.44z M308.07,95.53c-2.79,0-5,0.93-6.64,2.79
c-1.64,1.86-2.46,4.26-2.46,7.22v0.66h17.55v-0.74c0-2.95-0.73-5.34-2.17-7.17C312.89,96.45,310.8,95.53,308.07,95.53z"/>
<path class="st0" d="M410.41,0c-37.12,0-67.33,30.2-67.33,67.32c0,37.12,30.2,67.33,67.32,67.33s67.33-30.2,67.33-67.32
C477.73,30.2,447.53,0,410.41,0z M468.14,67.33c0,1.62-0.08,3.21-0.21,4.8l-11.29,0c0.16-1.58,0.25-3.18,0.25-4.8
c0-25.63-20.85-46.48-46.48-46.48c-1.62,0-3.22,0.08-4.8,0.25l0-11.28c1.58-0.13,3.18-0.21,4.8-0.21
C442.24,9.59,468.14,35.49,468.14,67.33z M446.98,72.12l-11.39,0c0.3-1.55,0.46-3.16,0.46-4.8c0-14.14-11.5-25.64-25.64-25.64
c-1.64,0-3.24,0.15-4.8,0.45l0-11.39c1.57-0.2,3.17-0.31,4.8-0.31c20.34,0,36.89,16.55,36.89,36.89
C447.3,68.95,447.18,70.55,446.98,72.12z M405.61,52.02c1.52-0.48,3.13-0.74,4.8-0.74c8.85,0,16.05,7.2,16.05,16.05
c0,1.67-0.27,3.28-0.74,4.8l-20.1,0L405.61,52.02z M396.02,93l-11.28,0l0-77.36c3.57-1.78,7.34-3.21,11.28-4.23L396.02,93z
M405.61,81.72l60.71,0c-1.01,3.94-2.45,7.71-4.23,11.28l-56.49,0L405.61,81.72z M375.14,21.66l0,71.33l-16.42,0
c-3.86-7.74-6.04-16.45-6.04-25.67C352.68,48.76,361.49,32.23,375.14,21.66z M364.75,102.59l31.27,0l0,20.65
C383.42,120,372.49,112.59,364.75,102.59z M410.41,125.06c-1.62,0-3.21-0.08-4.8-0.22l0-22.25l50.46,0
C445.5,116.24,428.97,125.06,410.41,125.06z"/>
</g>
</svg>`
