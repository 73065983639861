<template>
  <div>
    <v-data-table
    :items="counterparties"
    :headers="headers"
    :expanded="expanded"
    item-key="counterpartyId"
    show-expand
    single-expand
    @click:row="rowClicked"
    style="cursor: pointer;"
    dense>
      <template #item.netTons="{item}">
        {{numberWithCommas(isMetricTons ? item.netMetricTons : item.netTons, 3)}}
      </template>
      <template #item.grossTons="{item}">
        {{numberWithCommas(isMetricTons ? item.grossMetricTons : item.grossTons, 3)}}
      </template>
      <template #item.averageGrossRate="{item}">
        {{getAverageRate(item, false)}}
      </template>
      <template #item.averageNetRate="{item}">
        {{getAverageRate(item, true)}}
      </template>
      <template #item.amount="{item}">
        {{formatMoney(item.amount)}}
      </template>
      <template #expanded-item="{item}">
        <td :colspan="headers.length + 1" style="cursor: auto">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="4" v-for="contract in item.contracts" :key="contract.contractId">
                <v-card>
                  <v-card-title
                  class="lighten-1 white--text subtitle-1"
                  style="height: 35px; background-color: #363636; cursor: pointer;"
                  @click="openBreakdownDialog('activities', item, contract)">
                    <div class="mt-n3" style="width: 65%; white-space: nowrap; word-break: normal; overflow: hidden; text-overflow: ellipsis;">{{`${$t('destinationAccount')}: ${contract.destinationAccountName}`}}</div>
                    <v-spacer/>
                    <span class="mt-n3">{{formatMoney(contract.amount)}}</span>
                  </v-card-title>
                  <v-card-text class="mt-2" style="overflow-y: auto; height:125px;">
                    <v-row>
                      <v-col cols="6" v-if="contract.fromAccountName">
                        <span class="py-1">
                          <Icon icon="mdi-account-arrow-right" iconColor="black" :tooltipText="$t('fromAccount')" tooltipColor="secondary"/>
                          {{contract.fromAccountName}}
                        </span>
                      </v-col>
                      <v-col cols="6" v-else-if="contract.tract">
                        <span class="py-1">
                          <Icon icon="mdi-crosshairs-gps" iconColor="black" :tooltipText="$t('tract')" tooltipColor="secondary"/>
                          {{ contract.tract }}
                        </span>
                      </v-col>
                      <v-col v-if="isByproduct" align="right">
                        <SettlementsContractActions
                        :tickets="contract.loadSummaries"
                        :contract="contract"
                        @unposted="$emit('refresh-settlement')"
                        @edited="$emit('refresh-settlement')"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <table style="width: 100%">
                          <tr class="font-weight-bold">
                            <td>{{$t('activity')}}</td>
                            <td>{{$t('averageRate')}}</td>
                            <td>{{$t('unit')}}</td>
                          </tr>
                          <tr v-for="payment in contract.paymentRollup" :key="`${contract.contractId}-${payment.activityId}`">
                            <td>{{payment.activityName}}</td>
                            <td class="text-right">{{formatMoney(payment.averageRate)}}</td>
                            <td class="text-right">{{getUnitTextForActivity(payment, contract.distance)}}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="4" v-for="(payableType) in payableTypes.filter(pt => pt.getItems(item).length > 0)" :key="`payableType-${payableType.title}`">
                <v-card>
                  <v-card-title
                  class="lighten-1 white--text subtitle-1"
                  style="height: 35px; background-color: #363636; cursor: pointer;"
                  @click="payableType.onclick(item)"
                  >
                    <span class="mt-n3">{{$t(payableType.title)}}</span>
                    <v-spacer/>
                    <span class="mt-n3">{{formatMoney(payableType.getItems(item).map(payableType.amount).reduce((p, c) => p + c, 0))}}</span>
                  </v-card-title>
                  <v-card-actions>
                    <v-row>
                      <v-col width="100%">
                        {{ $t(payableType.title) }}: {{ payableType.count(item) }}
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <Dialog :stateId="dialogId">
      <SettlementActivityBreakdown
        isReceivable
        v-if="focusedContractInfo && dialogKey === 'activities'"
        :propContractInfo="focusedContractInfo"
        :propPayee="focusedCounterparty"
        @refresh-settlement="$emit('refresh-settlement')"
      />
      <CounterpartyCorrectionsDialog
        v-if="focusedCounterparty && dialogKey === 'corrections'"
        :payer="focusedCounterparty"
        isByproduct
      />
    </Dialog>
  </div>
</template>

<script>
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { bpReceivablesByPayerHeaders } from '@/headers/Cycles.js'
import { PayOn, PayBy, ActivityModifier } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ReceivablesByPayer',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    SettlementActivityBreakdown: () => import('@/components/settlements/accounts-payable-module/settlements-by-contract/SettlementActivityBreakdown.vue'),
    CounterpartyCorrectionsDialog: () => import('@/components/settlements/accounts-receivable-module/receivables-by-payer/CounterpartyCorrectionsDialog.vue'),
    SettlementsContractActions: () => import('@/components/settlements/SettlementsContractActions.vue')
  },

  props: {
    receivablesByContractObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    expanded: [],
    focusedCounterparty: undefined,
    focusedContractInfo: undefined,
    dialogId: uniqueDialogId('receivable-summary-by-contract'),
    dialogKey: ''
  }),

  computed: {
    ...mapGetters('settlements', ['useMetricTons', 'useByproductMetricTons']),

    counterparties () {
      return this.receivablesByContractObject?.counterpartyDetails ?? []
    },

    headers () {
      return bpReceivablesByPayerHeaders(this.$i18n.locale)
    },

    isMetricTons () {
      return this.isByproduct ? this.useByproductMetricTons : this.useMetricTons
    },

    payableTypes () {
      return [
        {
          title: 'ticketCorrections',
          getItems: details => details?.corrections ?? [],
          amount: c => c.amount,
          onclick: (counterparty, contractInfo) => this.openBreakdownDialog('corrections', counterparty, contractInfo),
          count: details => (new Set((details?.corrections ?? []).map(c => c.ticketNumber))).size
        }
      ]
    }
  },

  watch: {
    receivablesByContractObject: {
      handler () {
        this.refreshSettlementActivityDialog()
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('posting', ['unpostContractTickets', 'unpostTicket']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    numberWithCommas,

    rowClicked (item) {
      this.expanded = (this.expanded.length > 0 && item.counterpartyId === this.expanded[0]?.counterpartyId)
        ? []
        : [item]
    },

    getAverageRate (counterpartyDetails, isNet) {
      const denominator = isNet
        ? this.isMetricTons ? counterpartyDetails.netMetricTons : counterpartyDetails.netTons
        : this.isMetricTons ? counterpartyDetails.grossMetricTons : counterpartyDetails.grossTons

      if (denominator <= 0) return '$0.00'

      return formatMoney(counterpartyDetails.amount / denominator)
    },

    getUnitTextForActivity ({ payOn, payBy, modifier, grossTons, grossMetricTons, netTons, netMetricTons }, distance) {
      if (modifier === ActivityModifier.DistanceTraveled.value) {
        return this.$t('distanceInMiles', { distance })
      }

      const isGross = payOn === PayOn.Gross.value
      const isMetric = payBy === PayBy.MetricWeight.value

      const weight = isMetric
        ? (isGross ? grossMetricTons : netMetricTons)
        : (isGross ? grossTons : netTons)

      const unit = isMetric ? 'mt' : 't'
      const payOnText = payOn === PayOn.NotApplicable.value ? this.$t('payLowerBy') : PayOn.fromInt(payOn)
      const payByText = payBy === PayBy.Load.value ? PayBy.Load.label : PayBy.fromInt(PayBy.Weight.value)

      return `${payOnText} ${payByText}: ${weight.toFixed(3)}${unit}`
    },

    openBreakdownDialog (dialogKey, counterparty, contractInfo) {
      this.dialogKey = dialogKey
      this.focusedCounterparty = counterparty
      this.focusedContractInfo = contractInfo
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    refreshSettlementActivityDialog () {
      const refreshedCounterparty = this.receivablesByContractObject?.counterpartyDetails?.find(c => c.counterpartyId === this.focusedCounterparty?.counterpartyId)
      if (refreshedCounterparty === undefined) {
        return
      }

      const refreshedContract = refreshedCounterparty.contracts?.find(c => c.contractId === this.focusedContractInfo?.contractId)
      if (refreshedContract === undefined) {
        return
      }

      this.focusedCounterparty = refreshedCounterparty
      this.focusedContractInfo = refreshedContract
    }
  }
}
</script>
