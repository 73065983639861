<template>
  <v-card max-width="400px" min-height="250px" max-height="250px" data-testid="add-location-dialog">
    <v-card-title class="white--text secondary headline">
      {{ $t('createLocationForEntity') }}
    </v-card-title>
    <v-card-text class="mt-6">
      <p class="subtitle-1 black--text">{{ $t('addLocationToEntityDialog') }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn data-testid="location-cancel-button" class="not-now" text color="black" @click="$emit('cancel-location')">{{$t('notNow')}}</v-btn>
      <v-spacer/>
      <v-btn data-testid="location-create-button" class="yes secondary" @click="$emit('create-location')">{{ $t('yes')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AddLocationEntityDialog'
}
</script>
