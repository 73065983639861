<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <span class="headline">
          {{$t('productDetails')}}
        </span>
      </v-col>
      <v-col cols="auto">
        <Icon
          dataTestId="new-product-detail"
          margin="mt-1 ml-n3"
          :small="false"
          icon="mdi-plus"
          :tooltipText="$t('newProductDetail')"
          @icon-clicked="$emit('new-product-detail-clicked')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
        :items="propProductDetails"
        :headers="headers"
        dense>
          <template #item.rate="{item}">
            {{formatMoney(item.rate)}}
          </template>
          <template #item.actions="{ item }">
            <Icon
              dataTestId="edit-template-detail"
              margin="mr-2"
              icon="mdi-pencil"
              iconColor="success"
              :tooltipText="$t('edit')"
              @icon-clicked="$emit('edit-template-detail-clicked', item)"
            />
            <Icon
              dataTestId="new-template-detail"
              iconColor="error"
              icon="mdi-delete"
              :tooltipText="$t('delete')"
              @icon-clicked="$emit('delete-template-detail-clicked', item)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailHeaders from '@/headers/Activity.js'
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'ProductDetails',

  props: {
    activityId: {
      type: Number
    },
    propProductDetails: {
      type: Array
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  computed: {
    headers () {
      return DetailHeaders.detailHeaders(this.$i18n.locale)
    }
  },

  methods: {
    formatMoney
  }
}
</script>
