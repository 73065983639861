<template>
  <v-container fluid grid-list-lg>
    <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="4">
      <v-col>
        <p class="subtitle-1 mr-2 mb-n1" :style="windowWidth < 960 ? 'text-align: center' : 'text:align: left;'">{{$t('generalInformation')}}</p>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('tract')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{journalEntry.tractName}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('activity')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{journalEntry.activityName}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('product')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{this.journalEntry.product ? this.journalEntry.product : 'Other'}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('payOn')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{payByFromInt(journalEntry.computedAtPayBy)}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('payBy')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{payOnFromInt([journalEntry.computedAtPayOn])}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span style="color: black;">{{$t('gross')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span style="color: black;">{{`${numberWithCommas(((journalEntry.inWeight - journalEntry.outWeight) / poundMultiplier).toFixed(poundMultiplier === 1 ? 0 : 3))} ${weightLabel}s`}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span style="color:red;">{{$t('defect')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span style="color: red;">{{`${numberWithCommas((journalEntry.defectWeight / poundMultiplier).toFixed(poundMultiplier === 1 ? 0 : 3))} ${weightLabel}s`}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span style="color: green;">{{$t('net')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span style="color: green;">{{`${numberWithCommas((journalEntry.netWeight / poundMultiplier).toFixed(poundMultiplier === 1 ? 0 : 3))} ${weightLabel}s`}}</span>
          </v-col>
        </v-row>
        <v-row class="mb-n4">
          <v-col md="12" lg="4" class="mb-n4">
            <span>{{$t('calculated')}}:</span>
          </v-col>
          <v-col md="12" lg="8">
            <span>{{utcToLocalDate(journalEntry.computedAt, 'L - LT')}}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <v-divider v-if="smallScreenSize"></v-divider>
    <v-col cols="12" xs="12" sm="12" md="12" lg="4">
      <p class="subtitle-1 mr-2 mt-4" style="text-align: center;">Effective JournalEntry</p>
      <h2 class="mb-n4 mt-n3" style="text-align:center;">
        {{journalEntryAmount}}
      </h2>
      <h3 class="subtitle-1" style="text-align:center;">
        {{formatMoney(journalEntry.computedAtRate)}} per ton
      </h3>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="mt-4">
      <p class="subtitle-1 mr-2" style="text-align: center;">Yield Breakdown</p>
      <canvas id="weightChart"></canvas>
      <p class="mr-6" style="color: green; text-align: right">{{netPercentage}} net</p>
      <p class="mr-6 mt-n3" style="color: red; text-align: right">{{defectPercentage}} defect</p>
    </v-col>
    </v-row>
    <v-divider/>
    <v-divider/>
    <v-col cols="12" xs="12">
    <v-row no-gutters>
      <v-btn
      class="secondary white--text"
      min-width="130px"
      @click="poundMultiplier === 1 ? poundMultiplier = 2000 : poundMultiplier = 1">
        <v-icon small class="mr-2" color="white">mdi-scale</v-icon>
        <span>{{poundMultiplier === 1 ? 'LBS' : 'TONS'}}</span>
      </v-btn>
    </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations'
import { PayOn, PayBy } from '@/utils/Enumerations'
import { Chart } from 'chart.js'
import { mapActions } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'

export default {
  name: 'JournalEntryDetailContent',

  props: {
    propJournalEntry: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    weightChart: null,
    poundMultiplier: 2000,
    recoveries: [],
    windowWidth: 0,
    journalEntry: {},
    recoveriesLoading: false
  }),

  computed: {
    weightLabel () {
      if (this.poundMultiplier === 1) {
        return 'lb'
      } else {
        return 'ton'
      }
    },

    journalEntryAmount () {
      return `${formatMoney(this.journalEntry.amount)}`
    },

    smallScreenSize () {
      return this.windowWidth < 960
    },

    pricePerTon () {
      if (this.journalEntry.amount <= 0) {
        return this.formatMoney(0)
      }
      if (this.poundMultiplier === 1) {
        let precision = 0
        const ppt = this.journalEntry.amount / this.journalEntry.netWeight
        if (ppt < 0.01) {
          precision = 5
        } else {
          precision = 2
        }
        return `$${ppt.toFixed(precision)}`
      }
      return this.formatMoney(this.journalEntry.amount / (this.journalEntry.netWeight / this.poundMultiplier))
    },

    netPercentage () {
      const percentage = ((this.journalEntry.netWeight / (this.journalEntry.netWeight + this.journalEntry.defectWeight)) * 100).toFixed(2)
      return `${percentage}%`
    },

    defectPercentage () {
      const percentage = ((this.journalEntry.defectWeight / (this.journalEntry.netWeight + this.journalEntry.defectWeight)) * 100).toFixed(2)
      return `${percentage}%`
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.journalEntry = JSON.parse(JSON.stringify(this.propJournalEntry))
      this.createWeightChart()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    ...mapActions('journalEntry', ['getJournalEntry']),
    utcToLocalDate,
    formatMoney,
    numberWithCommas,
    payOnFromInt: (x) => PayOn.fromInt(x),
    payByFromInt: (x) => PayBy.fromInt(x),
    createWeightChart () {
      const chartElement = document.getElementById('weightChart')
      this.weightChart = new Chart(chartElement, {
        type: 'doughnut',
        data: this.getWeightData()
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },

    getWeightData () {
      const { netWeight, defectWeight } = this.journalEntry

      return {
        labels: [this.$t('net'), this.$t('defect')],
        datasets: [
          {
            backgroundColor: [
              'green',
              '#f55a4e'
            ],
            data: [
              netWeight,
              defectWeight
            ]
          }
        ]
      }
    }
  }
}
</script>
