<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip
        bottom
        color="secondary"
      >
        <template #activator="{ on: tooltip }">
          <v-icon
            color="secondary"
            class="mt-4 mr-n2"
            v-bind="attrs"
            v-on="{...tooltip, ...menu}"
          >
            mdi-application-edit
          </v-icon>
        </template>
        <span
          class="subtitle-1 white--text"
          style="white-space: pre-line"
        >
          {{$t('adjustWidgets')}}
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="secondary white--text">
        {{$t('adjustWidgets')}}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-container :style="containerStyle">
          <v-row dense>
            <v-col
              cols="12"
              v-for="widget in getWidgets()"
              :key="`widget-id-${widget.id}`"
            >
              <v-sheet
                :style="widgetSheetStyles"
                outlined
                @click="toggleVisibility(widget)"
              >
              <v-row dense align="center">
                <v-col cols="auto" class="pr-0">
                  <Icon
                  iconColor="black"
                  :tooltipText="$t(widget.widgetType.label)"
                  :icon=widget.widgetType.icon
                />
                </v-col>
                <v-col style="overflow-wrap: break-word;">
                  {{ $t(widget.text) }}
                </v-col>
                <v-col cols="auto" class="pl-0 py-0">
                  <v-icon
                  :color=visibilityColor(widget.visible)
                  style="float: right"
                >
                  {{ visibilityIcon(widget.visible) }}
                </v-icon>
                </v-col>
              </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions bottom>
        <v-spacer/>
        <v-btn
          class="secondary"
          @click="apply"
          :disabled="!changed"
        >
          {{$t('apply')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { BaseDashboardWidgetSet } from '@/model/DashboardWidget.js'

export default {
  name: 'OperationalWidgetPicker',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propWidgetSet: BaseDashboardWidgetSet,
    openMenuKey: { type: Number, required: false, default: 0 }
  },

  data: () => ({
    widgetSet: null,
    menuOpen: false,
    changed: false
  }),

  watch: {
    menuOpen: {
      immediate: true,
      handler (val) {
        if (val) {
          this.changed = false
        } else {
          this.widgetSet = this.propWidgetSet.clone()
        }
      }
    },

    openMenuKey () {
      if (!this.menuOpen) this.menuOpen = true
    },

    widgetSet: {
      handler () {
        this.changed = true
      },
      deep: true
    }
  },

  computed: {
    widgetSheetStyles () {
      return {
        fontSize: '18px',
        padding: '8px',
        cursor: 'pointer',
        userSelect: 'none'
      }
    },

    containerStyle () {
      return { maxWidth: '500px', minWidth: this.$vuetify.breakpoint.xs ? '260px' : '500px' }
    }
  },

  methods: {
    apply () {
      this.widgetSet.saveWidgetInfo()
      this.menuOpen = false
      this.$emit('new-widget-layout', this.widgetSet)
    },

    toggleVisibility (widget) {
      widget.visible = !widget.visible
    },

    visibilityIcon (visible) {
      return visible ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
    },

    visibilityColor (visible) {
      return visible ? 'green' : 'black'
    },

    getWidgets () {
      return this.widgetSet.widgets
    }
  }
}
</script>
