import { BlobServiceClient } from '@azure/storage-blob'
import { sasNeedsRefresh } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { SasTokenLocations } from './constants'

class EBDocumentArchiveClient {
  async checkForRefresh () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.EXPORT_ARCHIVE_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = 'companyinfo/blobinfo/exportbatcharchive'
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.EXPORT_ARCHIVE_SAS, JSON.stringify(response))

      this.container = response.data[0].container
      this.location = response.data[0].location
      this.uri = response.data[0].blobSasUri
      this.client = new BlobServiceClient(this.uri)
      this.containerClient = this.client.getContainerClient(this.container)
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.EXPORT_ARCHIVE_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.uri = storedResponse.data[0].blobSasUri
      this.client = new BlobServiceClient(this.uri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  async fetchDocumentFromBlobStorage (path) {
    await this.checkForRefresh()
    const blobName = `${this.location}/${path}/export.pdf`
    const blobClient = this.containerClient.getBlobClient(blobName)
    const response = await blobClient.download()
    return await response.blobBody
  }
}

export const ebDocumentArchiveClient = new EBDocumentArchiveClient()
