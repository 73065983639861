export default {
  pushDialog (state, dialogObject) {
    state.dialogStack.push(dialogObject)
  },
  pushOrSetDialog (state, dialogObject) {
    if (
      state.dialogStack.length > 0 &&
      state.dialogStack[state.dialogStack.length - 1].id === dialogObject.id
    ) {
      const isFullscreen = state.dialogStack[state.dialogStack.length - 1].fullscreen
      state.dialogStack.pop()
      state.dialogStack.push(dialogObject)
      state.dialogStack[state.dialogStack.length - 1].fullscreen = isFullscreen
    } else {
      state.dialogStack.push(dialogObject)
    }
  },

  setDialogFullscreenById (state, { id, fullscreen }) {
    const targetIndex = state.dialogStack.findIndex(ds => ds.id === id)
    if (targetIndex !== -1) {
      state.dialogStack[targetIndex].fullscreen = fullscreen
    }
  },

  requestCloseDialog (state) {
    if (state.dialogStack.length > 0) {
      state.dialogStack[state.dialogStack.length - 1].closeRequested = true
    }
  },
  requestCloseDialogById (state, dialogId) {
    const targetIndex = state.dialogStack.findIndex(ds => ds.id === dialogId)
    if (targetIndex !== -1) {
      state.dialogStack[targetIndex].closeRequested = true
    }
  },
  requestCloseAllDialogs (state) {
    if (state.dialogStack.length > 0) {
      for (let i = state.dialogStack.length - 1; i >= 0; i--) {
        state.dialogStack[i].closeRequested = true
      }
    }
  },
  setTopDialogFullscreen (state, isFullscreen) {
    if (state.dialogStack.length > 0) {
      state.dialogStack[state.dialogStack.length - 1].fullscreen = isFullscreen
    }
  },
  popDialog (state) {
    state.dialogStack.pop()
  },
  popAllDialogs (state) {
    while (state.dialogStack.length > 0) {
      state.dialogStack.pop()
    }
  },
  popAllDialogsAtAndAbove (state, dialogId) {
    const targetIndex = (Array.isArray(dialogId))
      ? state.dialogStack.findIndex(ds => dialogId.some(id => ds.id === id))
      : state.dialogStack.findIndex(ds => ds.id === dialogId)
    if (targetIndex === -1) return
    while (state.dialogStack.length > targetIndex) {
      state.dialogStack.pop()
    }
  },
  requestCloseDialogsAtOrAbove (state, dialogId) {
    const targetIndex = (Array.isArray(dialogId))
      ? state.dialogStack.findIndex(ds => dialogId.some(id => ds.id === id))
      : state.dialogStack.findIndex(ds => ds.id === dialogId)
    if (targetIndex === -1 || state.dialogStack.length === 0) return
    for (let i = state.dialogStack.length - 1; i >= targetIndex; i--) {
      state.dialogStack[i].closeRequested = true
    }
  }
}
