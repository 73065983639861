<template>
  <v-card
    :max-width="width"
    :max-height="height"
    :width="width"
  >
    <v-card-title :class="`white--text headline ${color}`">
      {{ title }}
    </v-card-title>
    <v-card-text class="mt-6">
      <v-container grid-list-sm>
        <v-row>
          <v-col>
            <span class="title font-weight-bold">
              {{ noteText }}
            </span>
            <v-textarea
              v-model="content"
              outlined
              auto-grow
              counter
              maxlength="1024"
              clearable
              dense
              color="black"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-n4"
        text
        color="black"
        @click="$emit('cancel')"
      >
        {{ cancelText ?? $t('cancel') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        :class="color"
        class="mr-n4"
        @click="$emit('confirm', content)"
      >
        {{ confirmText ?? $t('submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ApprovalNoteDialog',

  props: {
    title: {
      type: String,
      required: true
    },
    noteText: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      required: false
    },
    cancelText: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: 'secondary'
    },
    width: {
      type: String,
      required: false,
      default: '400px'
    },
    height: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    content: null
  })
}
</script>
