<template>
  <CsvImport v-bind="{
    parse,
    preview,
    upload,
    exampleData,
    entityName,
    rowName
  }"/>
</template>

<script>
import { CsvColumn, typeConstructors } from '../../../utils/csv/parse.js'
import ticketHeaders from '@/headers/Ticket'
import { TicketStatus, ContractModes } from '../../../utils/Enumerations.js'
import { mapActions } from 'vuex'
import { groupBy } from 'lodash'
export default {
  name: 'TicketLogCountImports',

  components: {
    CsvImport: () => import('./CsvImport.vue')
  },

  computed: {
    entityName () { return this.$t('logCounts') },
    rowName () { return tlc => `#${tlc.ticketNumber} - ${tlc.length}'` },
    parse () {
      return {
        schema: {
          ticketNumber: CsvColumn.from({
            name: 'ticketNumber',
            type: typeConstructors.integer
          }),
          length: CsvColumn.from({
            name: 'length',
            type: typeConstructors.float
          }),
          count: CsvColumn.from({
            name: 'count',
            type: typeConstructors.integer
          })
        },

        context: async (parsedColumns) => {
          const ticketNumbers = parsedColumns.map(c => c.ticketNumber)
          const ticketList = await this.getTicketsWithNumbers({
            ticketNumbers,
            contractMode: ContractModes[0]
          })
          return new Map(ticketList.map(t => [t.ticketNumber, t]))
        },

        map: (parsedColumn, { context }) => {
          const { ticketId, status } = context.get(parsedColumn.ticketNumber) ?? {}
          return {
            ...parsedColumn,
            ticketId,
            ticketStatus: status
          }
        },

        validate: (logCount) => {
          if (logCount.ticketId === undefined) {
            return `No ticket #${logCount.ticketNumber} exists`
          }
          if ([TicketStatus.Exported.value, TicketStatus.Posted.value].includes(logCount.ticketStatus)) {
            return `Cannot add log counts to ticket #${logCount.ticketNumber} in ${TicketStatus.fromInt(logCount.ticketStatus)} state`
          }
          return true
        }
      }
    },

    preview () {
      return { headers: ticketHeaders.importTicketLogCountHeaders() }
    },

    upload () {
      return {
        map: {
          mapAll: (logCountRows) => {
            const grouped = groupBy(logCountRows, 'ticketId')
            return Object.entries(grouped).map(([stringKey, logCounts]) => ({
              ticketId: parseInt(stringKey),
              logCounts
            }))
          },
          equality: (logCountRow, mapped) => logCountRow.ticketId === mapped.ticketId
        },

        action: this.createTicketLogCounts
      }
    },

    exampleData () {
      return 'data:text/csv;charset=utf-8,ticketNumber,length,count\n1,37,23\n1,46,12'
    }
  },

  methods: {
    ...mapActions('ticket', ['getTicketsWithNumbers', 'createTicketLogCounts'])
  }
}
</script>
