<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <span class="subtitle-1">
            {{ $t('receiverLocation') }}
          </span>
          <Icon
          v-if="coordinatesSpecified"
          :small="false"
          icon="mdi-close"
          tooltipColor="secondary"
          :tooltipText="$t('removeReceiverLocation')"
          tabindex="0"
          @icon-clicked="removeLocation()"/>
          <Icon
          :small="false"
          icon="mdi-map"
          tooltipColor="secondary"
          :tooltipText="$t('specifyReceiverLocation')"
          tabindex="0"
          @icon-clicked="specifyLocation()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span v-if="coordinatesSpecified">
            <v-row>
              <v-col cols="2">
                <v-text-field
                :label="$t('latitude')"
                v-model="propSpot.latitude"
                readonly
                outlined
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                :label="$t('longitude')"
                v-model="propSpot.longitude"
                readonly
                outlined/>
              </v-col>
            </v-row>
          </span>
          <span v-else>
            <v-col>
              {{ $t('noReceiverLocationSpecified') }}
            </v-col>
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="specifyReceiverLocationDialog" max-width="70%">
      <AccountMap
      :propLocation="propSpot"
      @close="specifyReceiverLocationDialog = false"
      @selected-location="selectedLocation"
      v-if="specifyReceiverLocationDialog"/>
    </v-dialog>
  </v-row>
</template>

<script>
import fieldRules from '@/utils/rules'
export default {
  name: 'AccountLocation',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountMap: () => import('./AccountMap.vue')
  },

  props: {
    propSpot: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    specifyReceiverLocationDialog: false,
    map: undefined,
    marker: undefined
  }),

  computed: {
    coordinatesSpecified () {
      return this.propSpot?.latitude && this.propSpot?.longitude
    }
  },

  methods: {
    specifyLocation () {
      this.specifyReceiverLocationDialog = true
    },

    removeLocation () {
      this.$emit('removed-location')
    },

    selectedLocation (location) {
      this.specifyReceiverLocationDialog = false
      this.$emit('selected-location', location)
    }
  }
}
</script>
