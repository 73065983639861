import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchExportDownloadLinks (context) {
    try {
      context.commit('setLoading', true)
      const exportData = await getAllExportData()
      context.commit('setExportData', exportData)
    } finally {
      context.commit('setLoading', false)
    }
  },

  async triggerModificationHistoryExportEmail (_, requestObject) {
    const path = 'exports/auditExport'
    const response = await ApiServer.post(path, requestObject)
    return response.data
  }
}

const getAllExportData = async () => {
  const { data: ticketsExportData } = await ApiServer.get(PATHS.TicketExports)
  const { data: financialExportData } = await ApiServer.get(PATHS.FinancialExports)
  const { data: recoveryExportData } = await ApiServer.get(PATHS.RecoveryExports)
  const { data: ticketCorrectionExports } = await ApiServer.get(PATHS.TicketCorrectionExports)
  const { data: correctionFinancialExports } = await ApiServer.get(PATHS.CorrectionFinancialExports)
  const { data: correctionRecoveryExports } = await ApiServer.get(PATHS.CorrrectionRecoveryExports)

  return {
    ticketExports: ticketsExportData,
    financialExports: financialExportData,
    recoveryExports: recoveryExportData,
    ticketCorrectionExports: ticketCorrectionExports,
    correctionFinancialExports: correctionFinancialExports,
    correctionRecoveryExports: correctionRecoveryExports
  }
}

const PATHS = {
  TicketExports: 'companyInfo/blobInfo/ticketExports',
  FinancialExports: 'companyInfo/blobInfo/ticketFinancialsExports',
  RecoveryExports: 'companyInfo/blobInfo/ticketFinancialRecoveriesExports',
  TicketCorrectionExports: 'companyinfo/blobinfo/ticketCorrectionExports',
  CorrectionFinancialExports: 'companyinfo/blobinfo/ticketCorrectionFinancialsExports',
  CorrrectionRecoveryExports: 'companyinfo/blobinfo/ticketCorrectionRecoveriesExports'
}
