import { PDFDocument } from 'pdf-lib'
import store from '@/store/index.js'
import { TimberType, WoodType } from '@/utils/Enumerations'
import { numberWithCommas } from '@/utils/NumericMutations'

export const getPtFormBlobForTract = async ({
  year,
  quarter,
  timberTypeVolume
}) => {
  const formUrl = './pdf-forms/PT-283T.pdf'
  const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(formPdfBytes)
  const form = pdfDoc.getForm()
  initializeFields(form)

  const companyInfo = store.getters['user/companyInfo']

  setTextField(form, 'YEAR', year)
  switch (quarter) {
    case 1:
      form.getCheckBox('CHECK_QUARTER_1').check()
      break
    case 2:
      form.getCheckBox('CHECK_QUARTER_2').check()
      break
    case 3:
      form.getCheckBox('CHECK_QUARTER_3').check()
      break
    case 4:
      form.getCheckBox('CHECK_QUARTER_4').check()
      break
  }

  if (timberTypeVolume.timberVolumes) {
    timberTypeVolume.timberVolumes.forEach(function (timberVolumeObj) {
      fillVolumeByObj(timberVolumeObj, form)
    })
  }

  const landownerMailingAddress = timberTypeVolume.mailingAddress ? formatMailingAddress(timberTypeVolume.mailingAddress) : ''
  const landownerCityStateZip = timberTypeVolume.mailingAddress ? formatCityStateZip(timberTypeVolume.mailingAddress) : ''

  setTextField(form, 'SECTION_A_NAME', timberTypeVolume.landowner)
  setTextField(form, 'SECTION_A_STREET_ADDRESS', landownerMailingAddress)
  setTextField(form, 'SECTION_A_CITY_STATE', landownerCityStateZip)

  const companyName = companyInfo?.name ? companyInfo.name : ''
  const companyAddress = companyInfo?.address ? formatMailingAddress(companyInfo.address) : ''
  const companyCityStateZip = companyInfo?.address ? formatCityStateZip(companyInfo.address) : ''

  setTextField(form, 'SECTION_B_NAME', companyName)
  setTextField(form, 'SECTION_B_STREET_ADDRESS', companyAddress)
  setTextField(form, 'SECTION_B_CITY_STATE', companyCityStateZip)

  const tractCounty = timberTypeVolume.countrySecondarySubdivision ? timberTypeVolume.countrySecondarySubdivision : ''
  const tractAcres = timberTypeVolume.acres > 0 ? timberTypeVolume.acres.toFixed(2) : ''
  const tractMapAndParcelNumber = timberTypeVolume.mapAndParcelNumber ? timberTypeVolume.mapAndParcelNumber : ''

  setTextField(form, 'SECTION_C_STREET_ADDRESS', '')
  setTextField(form, 'SECTION_C_COUNTY', tractCounty)
  setTextField(form, 'SECTION_C_CITY', '')
  setTextField(form, 'SECTION_C_ACRES', tractAcres)
  setTextField(form, 'SECTION_C_MAP_NUMBER', tractMapAndParcelNumber)
  setTextField(form, 'SECTION_C_TAX_NAME', '')

  const taxPayerValue = timberTypeVolume.taxpayerValue > 0 ? '$ ' + numberWithCommas(timberTypeVolume.taxpayerValue, 2) : ''
  setTextField(form, 'TPV_TOTAL_VALUE', taxPayerValue)
  setTextField(form, 'TAV_TOTAL_VALUE', '')
  setTextField(form, 'TPV_MILLAGE_RATE', '')
  setTextField(form, 'TAV_MILLAGE_RATE', '')
  setTextField(form, 'TPV_TAX_DUE', '')
  setTextField(form, 'TAV_TAX_DUE', '')

  setTextField(form, 'SECTION_F_DATE_OF_SALE', '')
  setTextField(form, 'SECTION_F_DATE_OF_PAYMENT_RECIEVED_af_date', '')
  setTextField(form, 'SECTION_F_DATE_OF_TAX_PAID_af_date', '')
  setTextField(form, 'SECTION_F_CERTIFIED_BY', '')
  setTextField(form, 'SECTION_F_CERTIFIED_DATE', '')

  setTextField(form, 'SECTION_G_SELLER_SIG', '')
  setTextField(form, 'SECTION_G_DATE_OF_SELLER_SIG', '')
  setTextField(form, 'SECTION_G_PURCHASER_SIG', '')
  setTextField(form, 'SECTION_G_DATE_OF_PURCHASER_SIG', '')

  setTextField(form, 'RECIEVED_BY', '')

  const pdfBytes = await pdfDoc.save()
  return new Blob([pdfBytes], { type: 'application/pdf' })
}

const fillVolumeByObj = ({ timberType, woodType, tons }, form) => {
  switch (timberType) {
    case TimberType.Pulpwood.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_PULPWOOD', tons.toFixed(2))
      else setTextField(form, 'HARD_PULPWOOD', tons.toFixed(2))
      break
    case TimberType.ChipNSaw.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_CHIPNSAW', tons.toFixed(2))
      else setTextField(form, 'HARD_CHIPNSAW', tons.toFixed(2))
      break
    case TimberType.Sawtimber.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_SAWTIMBER', tons.toFixed(2))
      else setTextField(form, 'HARD_SAWTIMBER', tons.toFixed(2))
      break
    case TimberType.Poles.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_POLES', tons.toFixed(2))
      else setTextField(form, 'HARD_POLES', tons.toFixed(2))
      break
    case TimberType.Posts.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_POSTS', tons.toFixed(2))
      else setTextField(form, 'HARD_POSTS', tons.toFixed(2))
      break
    case TimberType.FuelwoodChips.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_FUELWOOD_CHIPS', tons.toFixed(2))
      else setTextField(form, 'HARD_FUELWOOD_CHIPS', tons.toFixed(2))
      break
    case TimberType.FuelwoodFirewood.value:
      if (woodType === WoodType.Softwood.value) setTextField(form, 'SOFT_FUELWOOD_FIREWOOD', tons.toFixed(2))
      else setTextField(form, 'HARD_FUELWOOD_FIREWOOD', tons.toFixed(2))
      break
  }
}

const formatMailingAddress = (address) => {
  if (!address) return ''
  return `${address.addressLine1} ${address.addressLine2 ?? ''}`
}

const formatCityStateZip = (address) => {
  return address
    ? `${address.city}, ${address.state} ${address.postalCode}`
    : ''
}

function initializeFields (form) {
  form.getCheckBox('CHECK_SINGLE_SUM').uncheck()
  form.getCheckBox('CHECK_QUARTERLY_SOLD').uncheck()
  form.getCheckBox('CHECK_QUARTERLY_HARVESTED').uncheck()

  setTextField(form, 'YEAR')

  setTextField(form, 'SECTION_A_NAME')
  setTextField(form, 'SECTION_A_STREET_ADDRESS')
  setTextField(form, 'SECTION_A_CITY_STATE')

  setTextField(form, 'SECTION_B_NAME')
  setTextField(form, 'SECTION_B_STREET_ADDRESS')
  setTextField(form, 'SECTION_B_CITY_STATE')

  setTextField(form, 'SECTION_C_STREET_ADDRESS')
  setTextField(form, 'SECTION_C_COUNTY')
  setTextField(form, 'SECTION_C_CITY')
  setTextField(form, 'SECTION_C_ACRES')
  setTextField(form, 'SECTION_C_MAP_NUMBER')
  setTextField(form, 'SECTION_C_TAX_NAME')

  setTextField(form, 'SOFT_PULPWOOD')
  setTextField(form, 'HARD_PULPWOOD')
  setTextField(form, 'SOFT_CHIPNSAW')
  setTextField(form, 'HARD_CHIPNSAW')
  setTextField(form, 'SOFT_SAWTIMBER')
  setTextField(form, 'HARD_SAWTIMBER')
  setTextField(form, 'SOFT_POLES')
  setTextField(form, 'HARD_POLES')
  setTextField(form, 'SOFT_POSTS')
  setTextField(form, 'HARD_POSTS')
  setTextField(form, 'SOFT_FUELWOOD_CHIPS')
  setTextField(form, 'HARD_FUELWOOD_CHIPS')
  setTextField(form, 'SOFT_FUELWOOD_FIREWOOD')
  setTextField(form, 'HARD_FUELWOOD_FIREWOOD')

  setTextField(form, 'TPV_TOTAL_VALUE')
  setTextField(form, 'TAV_TOTAL_VALUE')
  setTextField(form, 'TPV_MILLAGE_RATE')
  setTextField(form, 'TAV_MILLAGE_RATE')
  setTextField(form, 'TPV_TAX_DUE')
  setTextField(form, 'TAV_TAX_DUE')

  setTextField(form, 'SECTION_F_DATE_OF_SALE')
  setTextField(form, 'SECTION_F_DATE_OF_PAYMENT_RECIEVED_af_date')
  setTextField(form, 'SECTION_F_DATE_OF_TAX_PAID_af_date')
  setTextField(form, 'SECTION_F_CERTIFIED_BY')
  setTextField(form, 'SECTION_F_CERTIFIED_DATE')

  setTextField(form, 'SECTION_G_SELLER_SIG')
  setTextField(form, 'SECTION_G_DATE_OF_SELLER_SIG')
  setTextField(form, 'SECTION_G_PURCHASER_SIG')
  setTextField(form, 'SECTION_G_DATE_OF_PURCHASER_SIG')

  setTextField(form, 'RECIEVED_BY')
}

function setTextField (form, selector, text = '') {
  const textField = form.getTextField(selector)
  textField.setText(String(text))
  textField.setFontSize(10)
}
