export default {
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setLocations (state, locations) {
    state.locations = locations
    state.locationsByAccountId = locations.reduce((p, location) => ({
      ...p,
      [location.accountId]: location
    }), {})
  }
}
