import store from '@/store/index.js'
import { getRelevantUrlComponent } from './ApiHelpers.js'
import { outboundRequests } from '@/utils/api/Interceptor.js'
import i18n from '@/i18n'

export const errorHandler = (error) => {
  if (error.message === 'REQ_IN_FLIGHT') {
    throw error
  }
  if (!error.config) {
    return
  }

  if (error.config.method === 'get') {
    delete outboundRequests[error.config.url]
  }

  if (error?.response?.data?.code === 'RSTRE') {
    store.dispatch('snackbar/setSnack', i18n.t('settlementDataOutOfDate'))
    throw error
  }
  const snackMessage = getSnackMessageForError(error)

  if (snackMessage) {
    store.dispatch('snackbar/setSnackError', snackMessage)
  }
  store.dispatch('global/setLoading', false)

  throw error
}

export const getSnackMessageForError = (error) => {
  if (error.code === 'ECONNABORTED') {
    const msg = `${error.message}`
    return {
      message: `${msg.match(/time(d )out/) ? 'Connection timed out' : 'There was a connection error'} while accessing the resource at ${error.config.url}\n`,
      code: 'ECONN',
      isClientSide: true
    }
  }

  const config = error.config
  if (!error.response) {
    return {
      message: genericErrorMessage(config),
      code: 'Uncaught Error'
    }
  }

  if (error.response.status === 403) {
    if (error.request.responseURL.toLowerCase().endsWith('applicationuser/userclaims')) {
      store.dispatch('user/setInvalidAccount', true)
    } else {
      store.dispatch('user/refreshUserClaims')
    }
    return {
      message: 'The requested resource or action is not permitted. Please contact your organization\'s admin to request access.',
      code: 'DENIED',
      isClientSide: true
    }
  }

  const { message, code, errors, status } = error.response.data
  if (message && code) {
    return {
      message: message,
      code: code
    }
  }

  if (errors && status) {
    const keys = Object.keys(errors)
    if (keys.length > 0) {
      const error = errors[keys[0]]

      return {
        message: error[0],
        code: status
      }
    }
  }

  return {
    message: genericErrorMessage(config),
    code: 'Uncaught Error'
  }
}

const genericErrorMessage = (config) => {
  let messageText = ''

  switch (config.method) {
    case 'get':
      messageText = 'There was a problem getting the resource:'; break
    case 'put':
      messageText = 'There was a problem updating the resource:'; break
    case 'post':
      messageText = 'There was a problem updating the resource:'; break
    case 'delete':
      messageText = 'There was a problem updating the resource:'; break
  }

  const resourceName = getRelevantUrlComponent(config.url)

  return `${messageText} ${resourceName}\nRequest URL: ${config.url}`
}
