<template>
  <v-card>
    <v-card-title class="secondary white--text headline">
      <span v-if="accountName">{{ $t('updateAccountCertification') }}</span>
      <v-spacer/>
      <Icon
        iconColor="white"
        icon="mdi-close"
        large
        :small="false"
        :tooltipText="$t('cancel')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper hideButton>
        <v-container fluid>
          <v-row v-if="!loading && accountId && certificationId" class="black--text" style="font-size: 14px">
            <v-col cols="12" style="font-size: 18px;">
              {{ $t('accountName') }}: {{ accountName }}
            </v-col>
            <v-col cols="12">
              <AccountCertificationCard
                :certification="certification"
                :file="file"
                @certification-edit-initiated="formDialogIsOpen = true"
                @certification-removed="removeCertification"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
    <v-dialog v-model="formDialogIsOpen" max-width="60%">
      <AccountCertificationForm
        v-if="formDialogIsOpen"
        :propCertification="certification"
        :accountId="accountId"
        hideFileUpload
        @form-closed="formDialogIsOpen = false"
        @certification-edited="editCertification"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { AccountFileCategory } from '@/utils/Enumerations.js'
import { accountFilesClient } from '@/utils/AccountFiles'
export default {
  name: 'UpdateAccountCertification',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    AccountCertificationCard: () => import('@/components/account/certifications/AccountCertificationCard.vue'),
    AccountCertificationForm: () => import('@/components/account/certifications/AccountCertificationForm.vue')
  },

  props: {
    accountId: {
      type: Number,
      default: undefined
    },
    certificationId: {
      type: Number,
      default: undefined
    },
    accountName: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    loading: undefined,
    certification: undefined,
    formDialogIsOpen: false,
    file: undefined
  }),

  async created () {
    this.loading = true
    this.certification = await this.fetchAccountCertification({ accountId: this.accountId, certificationId: this.certificationId })
    await this.getFile()
    this.loading = false
  },

  methods: {
    ...mapActions('certification', ['fetchAccountCertification', 'updateAccountCertification', 'removeAccountCertification']),

    async editCertification (certification) {
      await this.updateAccountCertification(certification)
      this.$emit('account-certification-changed')
    },

    async removeCertification (certification) {
      await this.removeAccountCertification(certification).then(async (response) => {
        if (response && this.file?.ref) await accountFilesClient.deleteFile(this.file.ref)
      })
      this.$emit('account-certification-changed')
    },

    async getFile () {
      const files = await accountFilesClient.getFileByPolicy(this.certification, AccountFileCategory.Certification)
      this.file = files && files.length > 0 ? files[0] : null
    }
  }
}
</script>
