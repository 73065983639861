import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { getFooter, nestedTableStyling, defaultDocumentConfig } from './GenericSettlementsFunctions.js'
import { sumsFor } from '@/utils/base'
import i18n from '../../i18n'
PDFMake.vfs = pdfFonts.pdfMake.vfs

const noBorder = [false, false, false, false]
const bottomBorder = [false, false, false, true]
const topBorder = [false, true, false, false]

export const generatePdf = async ({
  dateRangeString,
  entity, productHeaders,
  ticketHeaders,
  tractSummary,
  isStumpage,
  includeTickets,
  sortConfig
}) => {
  const destinationTables = tractSummary.destinationsInPeriod
    .map((d, index) => getDestinationTable(d, productHeaders, includeTickets, ticketHeaders, index === tractSummary.destinationsInPeriod.length - 1, sortConfig))
  const content = includeTickets
    ? [...destinationTables.map(t => [isStumpage ? getSummaryTables(tractSummary.summary, dateRangeString) : {}, t])]
    : [isStumpage ? getSummaryTables(tractSummary.summary, dateRangeString) : {},
      ...destinationTables]

  const docDefinition = {
    ...defaultDocumentConfig,
    header: getHeader(entity, dateRangeString, tractSummary.summary),
    footer: getFooter,
    pageOrientation: 'landscape',
    content: content,
    style: nestedTableStyling
  }

  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  today = mm + dd + yyyy

  await PDFMake.createPdf(docDefinition).download(`${tractSummary.summary.tractName}_Product_Costs_Information_${today}.pdf`.replace(/\s/g, ''))
}

const getHeader = (entity, dateRangeString, summary) => ({
  fontSize: 15,
  bold: true,
  marginTop: 20,
  marginLeft: 35,
  marginRight: 35,
  table: {
    widths: '*',
    body: [
      [
        {
          table: {
            body: [
              [{ text: entity.name, alignment: 'left' }]
            ]
          },
          layout: 'noBorders'
        },
        {
          alignment: 'center',
          table: {
            widths: '*',
            body: [
              [{ text: `${summary.tractName} Product Costs by Destination`, fontSize: 15, alignment: 'center' }],
              [{ text: dateRangeString, fontSize: 10, alignment: 'center' }]
            ]
          },
          layout: 'noBorders'
        },
        { text: '' }
      ]
    ]
  },
  layout: 'noBorders'
})

const getSummaryTables = (summary, dateRangeString) => {
  const cellStyle = (alignment = 'left') => ({ border: noBorder, fontSize: 8, alignment: alignment })
  const tractSummaryTable = {
    table: {
      body: [
        [{ text: i18n.t('tractSummary'), decoration: 'underline', bold: true, border: noBorder, fontSize: 12, alignment: 'left' }, { text: '', border: noBorder }],
        [{ text: `${i18n.t('tractType')}:`, ...cellStyle() }, { text: summary.tractType, ...cellStyle() }],
        [{ text: `${i18n.t('loadsInPeriod')}:`, ...cellStyle() }, { text: summary.loadsInPeriod, ...cellStyle('right') }],
        [{ text: `${i18n.t('loadsThroughEndDate', { endDate: untilTime(dateRangeString) })}:`, ...cellStyle() }, { text: numberWithCommas(summary.loadsThroughUntilTime), ...cellStyle('right') }]
      ]
    },
    style: nestedTableStyling
  }
  const stumpageCostTable = {
    table: {
      body: [
        [{ text: i18n.t('stumpageCosts'), decoration: 'underline', bold: true, border: noBorder, fontSize: 12, alignment: 'left' }, { text: '', border: noBorder }],
        [{ text: `${i18n.t('purchasePrice')}:`, ...cellStyle() }, { text: formatMoney(summary.purchasePrice), ...cellStyle('right') }],
        [{ text: `${i18n.t('costsFromStartToEnd', { startDate: sinceTime(dateRangeString), endDate: untilTime(dateRangeString) })}:`, ...cellStyle() }, { text: formatMoney(summary.periodStumpageCosts), ...cellStyle('right') }],
        [{ text: `${i18n.t('costsThroughEndDate', { endDate: untilTime(dateRangeString) })}:`, ...cellStyle() }, { text: formatMoney(summary.stumpageCostsThroughUntilTime), ...cellStyle('right') }],
        [{ text: `${i18n.t('lifetimeCosts')}:`, ...cellStyle() }, { text: formatMoney(summary.lifetimeStumpageCosts), ...cellStyle('right') }],
        [{ text: `${i18n.t('balanceThroughEndDate', { endDate: untilTime(dateRangeString) })}:`, ...cellStyle() }, { text: formatMoney(summary.remainingBalance), ...cellStyle('right') }]
      ]
    },
    style: nestedTableStyling
  }
  return {
    marginBottom: 20,
    table: {
      widths: ['*', '*'],
      body: [
        [tractSummaryTable, stumpageCostTable]
      ]
    },
    layout: 'noBorders'
  }
}

const getDestinationTable = (destination, productHeaders, includeTickets, ticketHeaders, isLastDestination, sortConfig) => {
  const productSummaries = destination.productSummaries.map(ps => ({
    ...ps,
    stumpageCostPerTon: ps.netWeight > 0 ? ps.stumpageCost / (ps.netWeight / 2000) : 0,
    productionCostPerTon: ps.netWeight > 0 ? ps.productionCost / (ps.netWeight / 2000) : 0
  }))

  const productTable = getProductTable(productSummaries, productHeaders)

  const ticketSummaries = destination.ticketSummaries.map(ts => ({
    ...ts,
    stumpageCostPerTon: ts.netWeight > 0 ? ts.stumpageCost / (ts.netWeight / 2000) : 0,
    productionCostPerTon: ts.netWeight > 0 ? ts.productionCost / (ts.netWeight / 2000) : 0
  }))

  const ticketTable = includeTickets ? getProductTable(ticketSummaries, ticketHeaders, sortConfig) : undefined
  const body = [
    [{ text: destination.accountName, bold: true, border: noBorder, fontSize: 12, alignment: 'left' }],
    [{ text: i18n.t('productSummaries'), bold: true, border: noBorder, fontSize: 10, alignment: 'left' }],
    [productTable]
  ]

  if (ticketTable) {
    body.push(
      [{ text: '', border: noBorder }],
      [{ text: i18n.t('ticketSummaries'), bold: true, border: noBorder, fontSize: 10, alignment: 'left' }],
      [ticketTable]
    )
  }

  return {
    marginBottom: 20,
    table: {
      widths: ['*'],
      body: body
    },
    style: nestedTableStyling,
    pageBreak: includeTickets && !isLastDestination ? 'after' : undefined
  }
}

const getProductTable = (productSummaries, productHeaders, sortConfig) => {
  const sums = sumsFor(productSummaries, productHeaders.filter(h => h.total).map(h => h.value))

  if (sortConfig) {
    productSummaries.sort((a, b) => {
      const aValue = a[sortConfig.columnSort.value]
      const bValue = b[sortConfig.columnSort.value]
      if (aValue < bValue) return sortConfig.sortDirection.isAscending ? -1 : 1
      if (aValue > bValue) return sortConfig.sortDirection.isAscending ? 1 : -1
      return 0
    })
  }
  const totals = productHeaders.map(h => {
    return (h.total)
      ? h.total({ productSummaries, sums, total: sums[h.value], count: productSummaries.length })
      : undefined
  })

  const headerRow = productHeaders.map(h => ({ text: h.text, border: bottomBorder, fontSize: 8, alignment: h.align ? h.align : 'left' }))
  const bodyRows = productSummaries
    .map((ps) => productHeaders
      .map(h => ({ text: h.format ? h.format(ps[h.value]) : ps[h.value], border: noBorder, fontSize: 8, alignment: h.align }))
    )
  const totalRow = totals.map((t, i) => ({ text: (productHeaders[i].format && productHeaders[i].total) ? productHeaders[i].format(t) : t ?? '-', border: topBorder, bold: true, fontSize: 8, alignment: productHeaders[i].align }))

  return {
    table: {
      widths: ['*', ...productHeaders.slice(0, -1).map(_ => 'auto')],
      headerRows: 1,
      body: [
        headerRow,
        ...bodyRows,
        totalRow
      ]
    },
    layout: {
      hLineWidth: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 2 : 1
      },
      vLineWidth: function (i, node) {
        return (i === 0 || i === node.table.widths.length) ? 2 : 1
      },
      hLineColor: function (i) {
        return i < 2 || i > 4 ? 'black' : 'white'
      },
      vLineColor: function () {
        return 'black'
      }
    },
    style: nestedTableStyling
  }
}

const sinceTime = (dateRangeString) => {
  return dateRangeString.split('-')?.[0]?.trim()
}

const untilTime = (dateRangeString) => {
  return dateRangeString.split('-')?.[1]?.trim()
}
