import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchSettings (context) {
    try {
      context.commit('setLoading', true)
      const response = await ApiServer.get('settings')
      context.commit('setSettings', response.data)
      var sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
      return sortedData
    } finally {
      context.commit('setLoading', false)
    }
  },
  async deleteSetting (context, settingId) {
    const path = `settings/${settingId}`
    const response = await ApiServer.delete(path)
    context.commit('removeSetting', settingId)
    return response.data
  },
  async createSetting (context, setting) {
    const response = await ApiServer.post('settings', setting)
    context.commit('addSetting', response.data)
    return response.data
  },
  async updateSetting (context, setting) {
    const response = await ApiServer.put('settings/' + setting.settingId, setting)
    context.commit('updateSetting', setting)
    return response.data
  }
}
