import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchActivityDetails (context) {
    const response = await ApiServer.get('activityDetails')
    context.commit('setActivityDetails', response.data)
    return response.data
  },
  async deleteActivityDetail (_, ids) {
    const path = `contracts/${ids.contractId}/activities/${ids.activityId}/ActivityDetails/${ids.activityDetailId}`
    const response = await ApiServer.delete(path)
    return response.data
  },
  async createActivityDetail (_, requestObj) {
    const { activityDetail, activityId, contractId, productId } = requestObj
    const path = `contracts/${contractId}/activities/${activityId}/ActivityDetails`
    const headers = { productId: productId }
    const response = await ApiServer.post(path, activityDetail, { headers: headers })
    return response.data
  },
  async updateActivityDetail (_, requestObj) {
    const { activityDetail, activityId, contractId, productId } = requestObj
    const path = `contracts/${contractId}/activities/${activityId}/ActivityDetails/${activityDetail.activityDetailId}`
    const headers = { productId: productId }
    const response = await ApiServer.put(path, activityDetail, { headers: headers })
    return response.data
  }
}
