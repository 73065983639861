import i18n from '../i18n'

export default {
  ticketContractPayments () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('activity'),
        value: 'activity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glOffset'),
        value: 'glOffset'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('grossAmount'),
        value: 'grossAmount'
      }
    ]
  },

  paymentTicketsBreakdown () {
    return [
      {
        align: 'right',
        sortable: 'true',
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber'
      },
      {
        align: 'center',
        sortable: 'true',
        text: i18n.t('weighedOut'),
        value: 'weighedOutDate'
      },
      {
        align: 'right',
        sortable: 'true',
        text: i18n.t('grossAmount'),
        value: 'grossAmount'
      },
      {
        align: 'right',
        sortable: 'true',
        text: i18n.t('recovered'),
        value: 'recovered'
      },
      {
        align: 'right',
        sortable: 'true',
        text: i18n.t('netWeightTons'),
        value: 'netTons'
      }
    ]
  },

  recoveriesAggregate () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('activity'),
        value: 'activity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glOffset'),
        value: 'glOffset'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('recovered'),
        value: 'recovered'
      }
    ]
  },

  recoveriesBreakdown () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'advance'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('recovered'),
        value: 'amount'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('balance'),
        value: 'advanceBalance'
      }
    ]
  },

  miscPayments () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('entity'),
        value: 'businessEntity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('activity'),
        value: 'activity'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glCode'),
        value: 'glCode'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('glOffset'),
        value: 'glOffset'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount'
      }
    ]
  },

  tractPaymentBreakdown () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'title'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('dateIssued'),
        value: 'dateIssued'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount'
      }
    ]
  },

  accountPaymentBreakdown () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'title'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('dateIssued'),
        value: 'dateIssued'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount'
      }
    ]
  },

  advancePayableBreakdown () {
    return [
      {
        align: 'left',
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        align: 'left',
        sortable: true,
        text: i18n.t('title'),
        value: 'title'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('effective'),
        value: 'effective'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('balance'),
        value: 'balance'
      }
    ]
  }

}
