<template>
  <ShortcutWrapper :nShortcutRequiredClaim="UserClaims.GlobalAdmin" @n-shortcut="createNewTeam">
    <v-container fluid id="team-settings">
      <v-row>
        <v-col>
          <material-card
          color="secondary"
          full-width
          :title="$t('teams')"
          :text="$t('teamDescription')">
            <DataTable
            :items="allTeams ?? []"
            :headers="headers"
            :actions="tableActions"
            :customCells="customTableCells"
            :loading="loading"
            @new-team="createNewTeam"
            @refresh="refreshTeams"
            @click:row="rowClicked">
              <template #actions="{ item }">
                <v-icon
                small
                class="mr-2"
                color="success"
                @click="editTeam(item)"
                @click.stop.prevent
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)">
                  mdi-pencil
                </v-icon>
                <v-icon
                small
                color="error"
                @click="openDeleteDialog(item)"
                @click.stop.prevent
                :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)">
                  mdi-delete-forever
                </v-icon>
              </template>
              <template #active="{ item }">
                <v-icon v-if="item.isActive" color="success">mdi-checkbox-marked-circle</v-icon><v-icon color="black" v-else>mdi-cancel</v-icon>
              </template>
              <template #users="{ item }">
                <v-container id="teamUserChipsContainer">
                  <v-row v-if="allUsers.length > 0" justify="center">
                    <v-chip-group column>
                      <v-chip
                      small
                      pill
                      v-for="u in item.users" v-bind:key="u.name"
                      :ripple="false" >
                        {{ u.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                </v-container>
              </template>
              <template #related="{ item }">
                <Icon icon="mdi-information-outline" :tooltipText="$t('itemDetails', {item: $t('team')})" @icon-clicked="openTeamDetail(item)"/>
              </template>
            </DataTable>
          </material-card>
        </v-col>
      </v-row>
      <Dialog
      :stateId="dialogId"
      :maxWidth="`${teamFormOpen ? '900' : viewingTeam ? '600' : '400'}px`"
      @click:outside="closeDialog"
      @dialog-closing="closeDialog"
      @close="closeDialog">
        <TeamForm
        v-if="teamFormOpen"
        :isEditing="editingTeam"
        :propTeam="editingTeam"
        @team-mutated="closeTeamFormAndRefresh"/>
        <ConfirmDelete
        @close="closeDeleteDialog"
        v-if="!!deleting"
        :title="$t('team')"
        @delete="removeTeam"
        @cancel-delete="closeDeleteDialog"/>
        <TeamDetail
        v-if="!!viewingTeam"
        :team="viewingTeam"
        @edit="editTeam"/>
      </Dialog>
    </v-container>
  </ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TeamHeaders from '@/headers/Team'
import { userAssignedClaim } from '@/utils/ClaimUtility'
import { UserClaims } from '@/utils/Enumerations'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'TeamSettings',

  components: {
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    TeamForm: () => import('@/components/settings-components/team/TeamForm.vue'),
    TeamDetail: () => import('@/components/settings-components/team/TeamDetail.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    UserClaims,
    dialogId: uniqueDialogId('procurement-team-form'),
    teamFormOpen: false,
    editingTeam: undefined,
    deleting: undefined,
    viewingTeam: undefined
  }),

  async created () {
    await this.getAllUsers()
    await this.fetchTeams()
  },

  computed: {
    ...mapGetters('team', ['allTeams', 'teamsLoading']),
    ...mapGetters('user', ['allUsers']),

    tableActions () {
      return [
        {
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          actionName: 'refresh'
        },
        {
          icon: 'mdi-plus',
          text: this.$t('newTeam'),
          actionName: 'new-team',
          disabled: !userAssignedClaim(UserClaims.GlobalAdmin)
        }
      ]
    },

    headers () {
      return TeamHeaders.teamHeaders()
    },

    customTableCells () {
      return [
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'active',
          value: 'active'
        },
        {
          slotName: 'users',
          value: 'userIds'
        },
        {
          slotName: 'related',
          value: 'related'
        }
      ]
    },

    viewingUsers () {
      return this.allUsers?.filter(u => this.viewingTeam?.userIds.includes(u.applicationUserId))
    },

    loading () {
      return this.teamsLoading
    }
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('team', ['fetchTeams', 'deleteTeam']),

    userAssignedClaim,

    createNewTeam () {
      this.editingTeam = undefined
      this.teamFormOpen = true
      this.openOrUpdateDialog(this.dialogId)
    },

    closeTeamForm () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.teamFormOpen = false
      this.editingTeam = undefined
    },

    closeDeleteDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.deleting = undefined
    },

    openDeleteDialog (item) {
      this.teamFormOpen = false
      this.viewingTeam = undefined
      this.openOrUpdateDialog(this.dialogId)
      this.deleting = item
    },

    closeTeamFormAndRefresh () {
      this.closeTeamForm()
      this.refreshTeams()
    },

    openTeamDetail (item) {
      this.viewingTeam = item
      this.openOrUpdateDialog(this.dialogId)
    },

    async refreshTeams () {
      await this.fetchTeams()
      await this.getAllUsers()
    },

    editTeam (team) {
      this.viewingTeam = undefined
      this.teamFormOpen = true
      this.editingTeam = team
      this.openOrUpdateDialog(this.dialogId)
    },

    removeTeam () {
      this.deleteTeam(this.deleting.teamId).then(_ => {
        this.closeDialogsAtOrAbove(this.dialogId)
        this.refreshTeams()
      })
    },

    closeDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.deleting = undefined
      this.teamFormOpen = false
      this.viewingTeam = undefined
    },

    rowClicked ({ data }) {
      this.openTeamDetail(data)
    }
  }
}
</script>

<style>
#teamUserChipsContainer .v-slide-group__content {
  display: block;
}

#team-settings td {
  word-break: break-word;
}
</style>
