var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({scopedSlots:_vm._u([{key:"preview-item.collectPieceInfo",fn:function({ value }){return [(value)?_c('Icon',{attrs:{"icon":"mdi-checkbox-marked-circle","iconColor":"success","small":false}}):_c('span')]}}])},'CsvImport',{
  parse: _vm.parse,
  preview: _vm.preview,
  upload: _vm.upload,
  entityName: _vm.entityName,
  rowName: _vm.rowName,
  exampleData: _vm.exampleData
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }