import i18n from '@/i18n'
import { LocalStorageKeys } from '@/utils/constants'

export default {
  dateConfiguration: {
    payPeriod: undefined,
    fiscalYear: undefined,
    exportBatch: undefined
  },
  loading: false,
  arSalesAnalysisBusinessEntity: undefined,
  currentModuleKey: 'accountsPayable',
  currentSubModuleKey: 'productionSummary',
  moduleNames: [i18n.t('accountsPayable'), i18n.t('accountsReceivable'), i18n.t('accruals')],
  useMetricTons: localStorage.getItem(LocalStorageKeys.SETTLEMENTS_USE_METRIC_TONS) === 'true',
  useByproductMetricTons: localStorage.getItem(LocalStorageKeys.SETTLEMENTS_USE_BYPRODUCT_METRIC_TONS) === 'true',
  accountsPayable: {
    moduleNames: [
      i18n.t('productionSummary'),
      i18n.t('byContract'),
      i18n.t('byPayee'),
      i18n.t('paymentRegister'),
      i18n.t('ticketRegister'),
      i18n.t('tractPayments'),
      i18n.t('advances'),
      i18n.t('corrections')
    ],
    productionSummary: undefined,
    settlementsByContract: undefined,
    settlementsByPayee: undefined,
    paymentRegister: undefined,
    bpPaymentRegister: undefined,
    ticketRegister: undefined,
    corrections: undefined,
    byproductCorrections: undefined,
    byproductSettlementsByPayee: undefined,
    miscPayments: {
      advances: undefined,
      corrections: undefined,
      accountPayments: undefined
    }
  },
  accountsReceivable: {
    moduleNames: [i18n.t('receivableRegister'), i18n.t('receivableSummary'), i18n.t('salesAnalysis')],
    receivableRegister: undefined,
    bpReceivableRegister: undefined,
    bpReceivablesByPayer: undefined,
    productionSummary: undefined,
    salesAnalysis: undefined
  },
  accruals: {
    moduleNames: [i18n.t('register'), i18n.t('depletion')],
    register: undefined,
    bpRegister: undefined,
    depletion: undefined
  },
  tractPayments: undefined,
  settlementContractStatuses: {
    statuses: {}
  },
  settlementPayeeStatuses: {
    statuses: {}
  },
  exportBatchSasCapsule: undefined
}
