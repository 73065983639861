<template>
  <v-data-table
      :items="cruiseData"
      :headers="headers"
      :hide-default-footer="cruiseData.length < 10"
      :footer-props="{ 'items-per-page-options': [10,25,50,100,-1] }"
      :items-per-page="10"
      dense
      :no-data-text="$t('noCruiseData')"
    >
      <template #item.pricePerTon="{ item }">
        <span>{{ formatMoney(item.pricePerTon) }}</span>
      </template>
      <template #item.pricePerMBF="{ item }">
        <span>{{ formatMoney(item.pricePerMBF) }}</span>
      </template>

      <template #item.cruisedValue="{ item }">
        <span>{{ formatMoney(item.cruisedValue) }}</span>
      </template>

      <template #item.harvestedTons="{ item }">
        <span>{{ numberWithCommas(item.harvestedTons, 3) }}</span>
      </template>
      <template #item.harvestedMBF="{ item }">
        <span>{{ numberWithCommas(item.harvestedMBF, 3) }}</span>
      </template>

      <template #item.adjustmentTons="{ item }">
        <span>{{ numberWithCommas(item.adjustmentTons, 3) }}</span>
      </template>
      <template #item.adjustmentMBF="{ item }">
        <span>{{ numberWithCommas(item.adjustmentMBF, 3) }}</span>
      </template>

      <template #item.remainingTons="{ item }">
        <span>{{ numberWithCommas(item.remainingTons, 3) }}</span>
      </template>
      <template #item.remainingMBF="{ item }">
        <span>{{ numberWithCommas(item.remainingMBF, 3) }}</span>
      </template>

      <template #item.expectedTons="{ item }">
        <span>{{ numberWithCommas(item.expectedTons, 3) }}</span>
      </template>
      <template #item.expectedMBF="{ item }">
        <span>{{ numberWithCommas(item.expectedMBF, 3) }}</span>
      </template>

      <template v-if="!hideCrud" #item.actions="{ item }">
        <Icon
        v-if="!hideCrud && !item?.hideCrud"
        icon="mdi-pencil"
        iconColor="success"
        dataTestId="cruise-edit-button"
        :tooltipText="$t('edit')"
        @icon-clicked="editCruiseData(item)"
        />
        <Icon
        v-if="!hideCrud && !item?.hideCrud"
        icon="mdi-delete-forever"
        iconColor="error"
        dataTestId="cruise-delete-button"
        :tooltipText="$t('delete')"
        @icon-clicked="deleteCruiseData(item)"
        />
      </template>
      <template #body.append v-if="cruiseData.length > 1">
        <tr class="font-weight-bold" style="border: solid thin;">
          <td class="text-left">{{$t('totals')}}</td>
          <td class="text-right">{{totals.harvested}}</td>
          <td class="text-right">{{totals.adjustment}}</td>
          <td class="text-right">{{totals.remaining}}</td>
          <td class="text-right">{{totals.expected}}</td>
          <td class="text-right">---</td>
          <td class="text-right">{{formatMoney(totals.cruisedValue)}}</td>
          <td class="text-right"></td>
        </tr>
      </template>
      <template #top>
        <slot name="top"/>
      </template>
    </v-data-table>
</template>

<script>
import CruiseDataHeaders from '@/headers/Composition'
import { mapGetters } from 'vuex'
import { formatMoney, tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations'
import { cruiseDataTotals } from './CruiseDataTotals'

export default {
  name: 'CruiseDataTable',

  props: {
    hideCrud: {
      type: Boolean,
      required: false,
      default: false
    },
    propCruiseData: {
      type: Array,
      default: undefined
    },
    units: {
      required: true,
      type: Object
    }
  },

  components: {
    Icon: () => import('../../helper/Icon.vue')
  },

  computed: {
    ...mapGetters('tract-contents', ['allTractContents']),

    cruiseData () {
      return this.propCruiseData ?? this.allTractContents
    },

    totals () {
      return cruiseDataTotals(this.cruiseData, this.units)
    },

    headers () {
      const headers = CruiseDataHeaders.compositionHeaders(this.units)
      if (this.hideCrud) { return headers.slice(0, -1) }
      return headers
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,
    numberWithCommas,

    deleteCruiseData (cruiseData) {
      this.$emit('delete', cruiseData)
    },

    editCruiseData (cruiseData) {
      this.$emit('edit', cruiseData)
    }
  }
}
</script>
