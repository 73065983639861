export default {

  setLoading (state, status) {
    state.accountsLoading = status
  },
  setAccounts (state, accounts) {
    state.accounts = accounts
  },
  setTransitionAccount (state, account) {
    state.transitionAccount = account
  },
  setTransitionMode (state, mode) {
    state.transitionMode = mode
  },
  addAccount (state, account) {
    state.accounts.push({
      accountId: account.accountId,
      code: account.code,
      name: account.name,
      exportCode: account.exportCode,
      email: account.email
    })
  },
  removeAccount (state, accountId) {
    state.accounts = state.accounts.filter(account => account.accountId !== accountId)
  },
  updateAccount (state, account) {
    const index = state.accounts.findIndex(v => v.accountId === account.accountId)
    if (index !== -1) {
      Object.assign(state.accounts[index], account)
    }
  }
}
