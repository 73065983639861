import ApiServer from '@/utils/api/ApiServer.js'
import moment from 'moment'

export default {
  async fetchFiscalYears (context) {
    const path = 'fiscalYears'
    const response = await ApiServer.get(path)
    response.data.sort((a, b) => {
      return moment(a.endDate).isBefore(moment(b.endDate)) ? -1 : 1
    })
    context.commit('setFiscalYears', response.data)
    return response.data
  },

  async createFiscalYear (_, fiscalYear) {
    const path = 'fiscalYears'
    const response = await ApiServer.post(path, fiscalYear)
    return response.data
  },

  async deleteFiscalYear (_, fiscalYear) {
    const path = `fiscalYears/${fiscalYear.fiscalYearId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async updateFiscalYear (_, fiscalYear) {
    const path = `fiscalYears/${fiscalYear.fiscalYearId}`
    const response = await ApiServer.put(path, fiscalYear)
    return response.data
  }
}
