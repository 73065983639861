import store from '@/store/index.js'
import { SETTING_TYPES } from '@/utils/UserSettings.js'

export class DataTableColumn {
  id
  text
  textAbbr
  align
  value
  width
  visible
  hidable
  group
  columnAdjusterText
  sort

  constructor ({
    id,
    text,
    textAbbr = undefined,
    align,
    value,
    width,
    tooltip = '',
    visible = true,
    invisible = false,
    hidable = true,
    format = undefined,
    total = undefined,
    group = undefined,
    columnAdjusterText = undefined,
    sort = undefined
  }) {
    this.id = id
    this.text = text
    this.textAbbr = textAbbr
    this.width = width
    this.align = align
    this.value = value
    this.visible = visible
    this.invisible = invisible
    this.hidable = hidable
    this.tooltip = tooltip
    this.format = format
    this.total = total
    this.group = group
    this.columnAdjusterText = columnAdjusterText
    this.sort = sort
  }

  clone () {
    return new DataTableColumn(this)
  }
}

export class ColumnSet {
  columns
  setName
  userSettingKey

  constructor ({
    columns,
    setName,
    userSettingKey
  }) {
    this.setName = setName
    this.columns = columns
    this.userSettingKey = userSettingKey

    if (process.env.NODE_ENV === 'development') {
      const columnIdSet = new Set(this.columns.map(c => c.id))
      console.assert(columnIdSet.size === this.columns.length, `Column set ${setName} has at least ${this.columns.length - columnIdSet.size} duplicate column ID(s).`)
    }

    const hiddenColumnIds = store.getters['user-settings/mutatedUserSettings'][SETTING_TYPES.TABLE][this.userSettingKey]
    if (hiddenColumnIds) {
      this.columns.forEach(column => this.showColumn(column.columnId))
      hiddenColumnIds.forEach(columnId => {
        this.hideColumn(columnId)
      })
    }
  }

  getColumns () {
    return this.columns.filter(c => c.visible && !c.invisible)
  }

  hideColumn (columnId) {
    const columnIndex = this.columns.findIndex(c => c.id === columnId)
    if (columnIndex !== -1) {
      this.columns[columnIndex].visible = false
    }
  }

  showColumn (columnId) {
    const columnIndex = this.columns.findIndex(c => c.id === columnId)
    if (columnIndex !== -1) {
      this.columns[columnIndex].visible = true
    }
  }

  isColumnHidden (id) {
    const columnIndex = this.columns.findIndex(c => c.id === id)
    if (columnIndex !== -1) {
      return !this.columns[columnIndex].visible
    } else {
      return true
    }
  }

  getSummableHeaders () {
    return this.columns.filter(c => c.total !== undefined).map(c => c.value)
  }

  saveColumnInfo () {
    if (this.userSettingKey) {
      store.commit('user-settings/mutateUserSetting', {
        type: SETTING_TYPES.TABLE,
        key: this.userSettingKey,
        value: this.columns.filter(c => !c.visible && c.id !== undefined).map(c => c.id)
      })
      store.dispatch('user-settings/updateUserSettings', store.getters['user-settings/mutatedUserSettings'])
    }
  }

  clone () {
    return new ColumnSet({
      columns: this.columns.map(c => c.clone()),
      setName: this.setName,
      userSettingKey: this.userSettingKey
    })
  }
}
