export default {
  userInfo: (state) => state.userInfo,
  userClaims: (state) => state.userClaims,
  companyInfo: (state) => state.companyInfo,
  businessEntities: (state) => state.businessEntities,
  initialized: (state) => state.initialized,
  initializing: (state) => state.initializing,
  loginWithRedirectResult: (state) => state.loginWithRedirectResult,
  entitiesLoading: (state) => state.entitiesLoading,
  invalidAccount: (state) => state.invalidAccount,
  allUsers: (state) => state.allUsers,
  usersLoading: (state) => state.usersLoading,
  foresterUsers: (state) => state.foresterUsers
}
