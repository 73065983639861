import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import store from '@/store/index.js'
import {
  getPaymentRollupTable,
  getLoadSummaryTable,
  defaultDocumentConfig,
  nestedTableStyling,
  getHeaderForSettlement,
  formatAddress,
  getFileNameForPeriod,
  fileNameForString,
  getDateInfo
} from './GenericSettlementsFunctions.js'
import { getCoverPageForPayee } from './SettlementCoverPage.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { companyLogoClient, PdfLogoDimensions } from '@/utils/CompanyLogo'

PDFMake.vfs = pdfFonts.pdfMake.vfs

export async function generateSinglePDFSettlementReport (settlementsByPayee, params = {}) {
  const { filenamePrefix, exportBatch, isByproducts = false } = params
  const dateInfo = getDateInfo(settlementsByPayee, exportBatch)
  const payees = settlementsByPayee.payees
  const companyInfoId = store.getters['user/companyInfo'].companyInfoId
  const logoAsDataUrl = await companyLogoClient.getCompanyLogoAsDataUrl(companyInfoId)
  const logoTopMargin = await companyLogoClient.getTopMarginToCenterImage(companyInfoId)
  const docDefinition = {
    ...defaultDocumentConfig,
    content: []
  }

  payees.forEach((payee, payeeIndex) => {
    const lastPayee = payeeIndex === payees.length - 1
    const coverOnly = payee.contracts.length === 0
    docDefinition.content.push(getCoverPageForPayee(dateInfo, payee, {
      pageBreak: !(lastPayee && coverOnly),
      unexported: exportBatch.exportBatchId === undefined,
      isByproducts
    }))

    payee.contracts.forEach((contract, contractIndex) => {
      const lastPage = lastPayee && contractIndex === payee.contracts.length - 1
      docDefinition.content.push(getReportForContract(dateInfo, payee, contract, lastPage))
    })
  })

  const tmp = PDFMake.createPdf(docDefinition)
  tmp.getStream()
  const pageNumbers = []
  for (let i = 0; i < tmp.docDefinition.content.length; i++) {
    const content = tmp.docDefinition.content[i]
    const positions = JSON.parse(JSON.stringify(content.positions))
    if (content === undefined || content === null) {
      continue
    }

    if (positions === undefined) {
      continue
    }

    if (positions.length === 0) {
      continue
    }

    pageNumbers.push(positions[positions.length - 1].pageNumber)
  }

  docDefinition.footer = generateFooter(pageNumbers, payees)
  docDefinition.header = genHeader(pageNumbers, payees, { logoAsDataUrl, logoTopMargin })

  const pdf = PDFMake.createPdf(docDefinition)

  const filename = `${filenamePrefix ? fileNameForString(filenamePrefix) + '-' : ''}${getFileNameForPeriod(dateInfo)}`.replace(/\s/g, '')
  const blob = await new Promise(resolve => {
    pdf.getBlob(resolve)
  })

  return { blob, filename }
}

const genHeader = (pageNumbers, payees, { logoAsDataUrl, logoTopMargin }) => {
  const payeeNameArray = payees.flatMap(payee => {
    const names = []
    names.push(payee.payeeName)
    payee.contracts.forEach(_ => names.push(payee.payeeName))
    return names
  })

  let lastPayeeUsed = null

  const getTitleForPayee = (currentPage) => {
    const index = pageNumbers.findIndex(pn => currentPage <= pn)
    if (index === -1) return ''
    const payee = payeeNameArray[index]
    if (lastPayeeUsed !== payee) {
      lastPayeeUsed = payee
      return 'Settlement Statement Summary'
    }
    return 'Settlement Statement'
  }

  const headerFunction = (page, pageNumbers) => {
    const companyInfo = store.getters['user/companyInfo']
    const companyName = companyInfo.name ? companyInfo.name : ''
    const companyAddress = formatAddress(companyInfo)
    const companyPhoneNumber = companyInfo.mainContact && companyInfo.mainContact.phoneNumber ? companyInfo.mainContact.phoneNumber : ''

    const companyInfoSection = (logoAsDataUrl === undefined || logoTopMargin === undefined)
      ? [
        {
          table: {
            body: [
              [''],
              [{ text: companyName, alignment: 'left' }],
              [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
              [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
            ]
          },
          layout: 'noBorders'
        }
      ]
      : [
        {
          image: logoAsDataUrl,
          maxWidth: PdfLogoDimensions.LOGO_MAX_WIDTH,
          maxHeight: PdfLogoDimensions.LOGO_MAX_HEIGHT,
          marginTop: logoTopMargin,
          alignment: 'center'
        },
        {
          table: {
            body: [
              [''],
              [{ text: companyName, alignment: 'left' }],
              [{ text: companyAddress, alignment: 'left', fontSize: 8 }],
              [{ text: companyPhoneNumber, alignment: 'left', fontSize: 8 }]
            ]
          },
          layout: 'noBorders'
        }
      ]

    return {
      fontSize: 15,
      bold: true,
      marginLeft: 40,
      marginRight: 40,
      table: {
        widths: '*',
        body: [
          [
            {
              table: {
                body: [
                  companyInfoSection
                ]
              },
              layout: 'noBorders'
            },
            {
              alignment: 'center',
              table: {
                widths: '*',
                body: [
                  [''],
                  [''],
                  [{ text: getTitleForPayee(page), fontSize: 15, alignment: 'center' }]
                ]
              },
              layout: 'noBorders'
            },
            { text: '' }
          ]
        ]
      },
      layout: 'noBorders'
    }
  }

  return headerFunction
}

const generateFooter = (pageNumbers, payees) => {
  const payeeNameArray = payees.flatMap(payee => {
    const names = []
    names.push(payee.payeeName)
    payee.contracts.forEach(_ => names.push(payee.payeeName))
    return names
  })

  const findPayeeName = (currentPage) => {
    const index = pageNumbers.findIndex(pn => currentPage <= pn)
    if (index === -1) return ''
    return payeeNameArray[index]
  }

  const getFooter = (page) => {
    return {
      marginRight: 50,
      marginLeft: 50,
      columns: [
        {
          width: '*',
          text: `${page} - ${findPayeeName(page)}`,
          fontSize: 8,
          bold: true
        },
        {
          width: '*',
          text: utcToLocalDate(new Date().toISOString()),
          alignment: 'right',
          fontSize: 8,
          bold: true
        }
      ]
    }
  }

  return getFooter
}

export const getReportForContract = (dateInfo, payee, contract, lastPage) => {
  const header = getHeaderForSettlement(dateInfo, payee, contract)
  const paymentRollupTable = getPaymentRollupTable(contract.paymentRollup, contract.distance, contract.type)
  const loadSummaryTable = getLoadSummaryTable(contract.loadSummaries)

  return {
    table: {
      widths: ['*'],
      body: [
        header,
        paymentRollupTable,
        loadSummaryTable
      ]
    },
    ...nestedTableStyling,
    pageBreak: lastPage ? '' : 'after'
  }
}
