export default {
  setLoading (state, status) {
    state.templatesLoading = status
  },
  setTemplateBundles (state, templateBundles) {
    state.templateBundles = templateBundles
  },
  setActivityTemplates (state, activityTemplates) {
    state.activityTemplates = activityTemplates
  },
  addActivityTemplate (state, activityTemplate) {
    state.activityTemplates.push(activityTemplate)
  },
  removeActivityTemplate (state, activityTemplateId) {
    state.activityTemplates = state.activityTemplatess.filter(a => a.activityTemplateId !== activityTemplateId)
  },
  updateActivityTemplate (state, activityTemplate) {
    const index = state.activityTemplates.findIndex(a => a.activityTemplateId === activityTemplate.activityTemplateId)
    if (index !== -1) {
      Object.assign(state.activityTemplates[index], activityTemplate)
    }
  }
}
