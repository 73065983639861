import { formatMoney } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import messages from '@/lang'
import i18n from '../i18n'
import {
  CorrectionType,
  IntegrationSourceType,
  AccountingCategory,
  IntegrationStatus
} from '@/utils/Enumerations.js'

const valueOrNotAvailable = (value) => {
  if (value === '' || value === null || value === undefined) {
    return messages['en-US'].notAvailable
  }

  return value
}

export function settlementTractPaymentHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('code'),
      value: 'tractCode',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('exportCode'),
      value: 'exportCode',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('businessEntity'),
      value: 'businessEntityName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    }
  ]
}

export function settlementAccountPaymentHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('code'),
      value: 'accountCode',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('exportCode'),
      value: 'exportCode',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('businessEntity'),
      value: 'businessEntityName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    }
  ]
}

export function ticketCorrectionHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('ticketNumber'),
      value: 'ticketNumber'
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tract'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'account'
    },
    {
      sortable: true,
      text: i18n.t('destination'),
      value: 'destination'
    },
    {
      sortable: true,
      text: i18n.t('fromAccount'),
      value: 'fromAccount'
    },
    {
      sortable: true,
      text: i18n.t('setting'),
      value: 'setting'
    },
    {
      sortable: true,
      text: i18n.t('type'),
      value: 'correctionType',
      format: (value) => CorrectionType.forInt(value).name
    },
    {
      text: i18n.t('notes'),
      value: 'notes'
    },
    {
      sortable: true,
      text: i18n.t('createdBy'),
      value: 'createdBy'
    },
    {
      sortable: true,
      text: i18n.t('createdAt'),
      value: 'createdAt',
      format: (value) => utcToLocalDate(value, 'L - LT')
    },
    {
      align: 'center',
      text: i18n.t('actions'),
      value: 'actions'
    }
  ]
}

export function settlementAdvanceHeaders (isExportBatch) {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('payee'),
      value: 'payeeName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tract',
      align: 'left',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('exportCode'),
      value: 'exportCode',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: isExportBatch ? i18n.t('recoveredInBatch') : i18n.t('recoveries'),
      value: 'recovered',
      align: 'right',
      format: formatMoney
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    }
  ]
}

export function businessIntegrationHeaders (category) {
  let headers = [
    {
      sortable: true,
      text: i18n.t('entityCode'),
      value: 'businessEntityCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('sourceType'),
      value: 'sourceType',
      align: 'center',
      format: (value) => IntegrationSourceType.forInt(value)?.name
    },
    {
      sortable: true,
      text: i18n.t('debitCode'),
      value: 'debitAccountCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('creditCode'),
      value: 'creditAccountCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('group'),
      value: 'groupingKey1',
      align: 'center',
      format: valueOrNotAvailable
    },
    {
      sortable: true,
      text: i18n.t('status'),
      value: 'integrationStatus',
      align: 'center',
      format: (value) => IntegrationStatus.forInt(value)?.name
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'absAmount',
      align: 'right'
    }
  ]

  switch (category.value) {
    case AccountingCategory.Receivable.value:
      headers.splice(5, 0, ...[
        {
          sortable: true,
          text: i18n.t('productCode'),
          value: 'productCode',
          align: 'center'
        },
        {
          sortable: true,
          text: i18n.t('ext1'),
          value: 'ext1',
          align: 'center',
          format: valueOrNotAvailable
        },
        {
          sortable: true,
          text: i18n.t('ext2'),
          value: 'ext2',
          align: 'center',
          format: valueOrNotAvailable
        }
      ])

      // remove grouping key as it is not applicable for receivables
      headers = headers.filter(h => h.value !== 'groupingKey1')
      break
    case AccountingCategory.Accrual.value:
      headers.splice(5, 0, ...[
        {
          sortable: true,
          text: i18n.t('counterpartyExportCode'),
          value: 'counterpartyExportCode',
          align: 'center',
          format: valueOrNotAvailable
        }
      ])
      break
    default: break
  }

  return headers
}
