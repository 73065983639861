<template>
  <v-card class="fill-height rounded-lg elevation-2">
    <div style="display: flex; flex-flow: column; width: 100%; height: 100%">
      <v-card-title :style="allTitleStyles" :class="allTitleClasses">
        <slot name="title"/>
      </v-card-title>
      <v-card-text style="flex-grow: 1" class="pa-0">
        <slot/>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'GridCard',

  props: {
    titleStyle: {
      type: String,
      required: false,
      default: ''
    },
    titleClasses: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    allTitleClasses () {
      return `full-width headline elevation-0 rounded-t-lg ${this.titleClasses}`
    },

    allTitleStyles () {
      return `user-select: none; ${this.titleStyle}`
    }
  }
}
</script>
