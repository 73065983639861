<template>
  <ImageViewer
  :title="`Ticket #${ticketNumber}`"
  :imageData="ticketImageContextData"
  :propImagePage="1"
  :scrollZoom="{ mode: 'cursor', increment: 0.3 }"
  draggable
  @page-changed="handlePageChange"
  :annotations="includeImageData"
  v-on="{ delete: deleteImage }"
  :allowDelete="$listeners.delete !== undefined"
  :height="height"
  :maxHeight="maxHeight"
  :loadingIndicator="loadingIndicator"
  :rotationOffset="degreeCeil(currentImageData?.dimensions?.angle ?? 0)"
  />
</template>

<script>
import { hashString32 } from '@/utils/base'
export default {
  name: 'TicketImageViewer',
  components: {
    ImageViewer: () => import('@/components/helper/ImageViewer.vue')
  },

  props: {
    ticketNumber: {
      type: Number,
      required: true
    },
    ticketImageData: {
      type: Array,
      required: true
    },
    includeImageData: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      default: '',
      required: false
    },
    maxHeight: {
      type: String,
      default: '',
      required: false
    },
    emitBehavior: {
      type: [String, Boolean],
      default: false,
      required: false
    },
    confidenceWarningThreshold: {
      type: Object,
      default: undefined,
      required: false
    },
    loadingIndicator: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    currentContextPage: 1,
    ticketImageContextData: [],
    pagesVisited: new Set(),
    warnings: []
  }),

  computed: {
    currentImageData () {
      return this.ticketImageData[this.currentContextPage - 1]
    },
    hasAutofillData () {
      const anyNonNullField = (fields) => Object.values(fields).some(field => field.value !== undefined && field.value !== null)
      return this.currentImageData?.fields && anyNonNullField(this.currentImageData.fields)
    },
    emitBehaviorSet () {
      const behavior = (typeof this.emitBehavior === 'boolean')
        ? ((this.emitBehavior) ? 'initial' : '')
        : this.emitBehavior
      const always = behavior === 'always'
      const initial = always || behavior === 'initial'
      return {
        always,
        initial
      }
    },
    emitWarnings () {
      return this.$listeners.warnings !== undefined
    },
    emitClickedThrough () {
      return this.$listeners['clicked-through'] !== undefined && this.warnings.find(w => w.severity === 'high') !== undefined
    },
    confidenceWarningThresholdsCalc () {
      return {
        low: this.confidenceWarningThreshold?.low ?? 0.90,
        high: this.confidenceWarningThreshold?.high ?? 0.75
      }
    }
  },

  watch: {
    ticketImageData: {
      handler (data) {
        this.ticketImageContextData = data.map(this.makeImageContext)
        if (this.emitBehaviorSet.initial) this.emitSelectedData()
        if (this.emitWarnings) {
          this.warnings = this.generateWarningsFor(this.ticketImageData)
          this.$emit('warnings', this.warnings)
        }
        if (this.emitClickedThrough) {
          this.pagesVisited = new Set()
          if (this.currentContextPage > 0) this.pagesVisited.add(this.currentContextPage)
          this.$emit('clicked-through', this.ticketImageData.length === this.pagesVisited.size)
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    hashString32,
    handlePageChange (page) {
      this.$emit('page-changed')
      this.currentContextPage = page

      if (this.emitClickedThrough) {
        this.pagesVisited.add(this.currentContextPage)
        if (this.pagesVisited.size === this.ticketImageData.length) this.$emit('clicked-through', true)
      }
      if (this.emitBehaviorSet.always) this.emitSelectedData()
    },
    expandPolygon (points, ratio = 1.1) {
      const summed = points.reduce((p, c) => ({ x: p.x + c.x, y: p.y + c.y }), { x: 0, y: 0 })
      const l = points.length
      const center = {
        x: summed.x / l,
        y: summed.y / l
      }
      const f = ratio - 1
      const out = points.map(p => {
        return {
          x: p.x + (p.x - center.x) * f,
          y: p.y + (p.y - center.y) * f
        }
      })
      return out
    },
    randomRgb (inStr, low = 180, seed = 100) {
      return {
        r: low + Math.floor(this.hashString32(inStr, seed) % 255 - low),
        g: low + Math.floor(this.hashString32(inStr, seed + 1) % 255 - low),
        b: low + Math.floor(this.hashString32(inStr, seed + 2) % 255 - low)
      }
    },
    rgbForKey (key) {
      const rgb = (r, g, b) => ({ r, g, b })
      switch (key) {
        case 'weighedOutAt':
          return rgb(100, 200, 220)
        default:
          return this.randomRgb(key)
      }
    },
    makeImageContext (arg) {
      const parseArgUrl = (arg) => {
        if (typeof arg === 'string') return arg
        if (arg instanceof URL) return arg.toString()
        return arg?.resourceUri?.toString() ?? ''
      }
      const url = parseArgUrl(arg)
      const arrayToObjectPoint = ap => ({ x: ap[0], y: ap[1] })
      const fields = arg?.fields ?? {}

      return {
        url,
        annotations: Object.keys(fields).map(key => ({
          name: key,
          bounds: (fields[key]?.bounds ?? []).map(poly => this.expandPolygon(poly.map(arrayToObjectPoint))),
          rgba: { ...this.rgbForKey(key, 180), a: 0.3 },
          get color () {
            return `rgba(${this.rgba.r},${this.rgba.g},${this.rgba.b},${this.rgba.a})`
          },
          get borderColor () {
            return `rgba(${this.rgba.r - 20},${this.rgba.g - 20},${this.rgba.b - 20},${this.rgba.a + 0.3})`
          }
        }))
      }
    },

    degreeCeil (angle) {
      return Math.ceil((angle - 45) / 90) * 90
    },

    generateWarningsFor (analysisData) {
      const maxTotalConfidence = this.maxTotalConfidence(analysisData)

      const low = this.confidenceWarningThresholdsCalc.low
      const high = this.confidenceWarningThresholdsCalc.high
      const totalConfidenceWarning = (maxTotalConfidence < low && analysisData.length > 0)
        ? this.makeWarning(
          this.$t(maxTotalConfidence < high ? 'recognizedVeryLowConfidence' : 'recognizedLowConfidence'),
          maxTotalConfidence < high ? 'high' : 'low')
        : undefined
      const multipleSetsWarning = (this.distinctFieldSets(analysisData).length > 1)
        ? this.makeWarning(this.$t('recognizedValuesDontMatch'), 'high')
        : undefined
      return [
        multipleSetsWarning,
        totalConfidenceWarning
      ].filter(w => w !== undefined).sort((a, b) => a.severity.localeCompare(b.severity))
    },

    distinctFieldSets (analysisData) {
      return analysisData.reduce((p, c) => {
        const compareData = {
          fields: c.fields ?? {},
          model: c.model
        }
        const matchingSet = p.find(set => Object.keys(compareData.fields).filter(key => compareData.fields[key]?.value !== null && compareData.fields[key]?.value !== undefined).reduce((allMatching, key) => allMatching && set[key]?.value === compareData.fields[key]?.value, true))
        return (matchingSet === undefined && Object.keys(compareData.fields).length > 0)
          ? [...p, compareData.fields]
          : p
      }, [])
    },

    maxTotalConfidence (analysisData) {
      const confidences = analysisData.map(d => d.confidence)
      const max = confidences.reduce((a, b) => Math.max(a, b), 0)
      return max
    },

    deleteImage (url) {
      if (this.$listeners.delete) this.$emit('delete', url)
    },

    emitSelectedData () {
      const selectedData = this.ticketImageData.length > this.currentContextPage - 1
        ? this.ticketImageData[this.currentContextPage - 1]
        : undefined
      if (selectedData) this.$emit('data-selected', selectedData)
    },

    makeWarning (message, severity = 'low') {
      return {
        message,
        severity
      }
    }
  }
}
</script>
