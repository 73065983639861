<template>
  <v-menu bottom left :close-on-content-click="false" v-model="sectionPickerMenu">
    <template #activator="{ on: onMenu, attrs: menuAttrs }">
      <v-tooltip bottom :color="color">
        <template #activator="{ on: onTooltip, attrs: tooltipAttrs }">
          <v-btn icon :color="color" v-on="{ ...onMenu, ...onTooltip }" v-bind="{ ...menuAttrs, tooltipAttrs }" large class="mt-n2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span class="subtitle-1 white--text">{{$t('editDashboardSections')}}</span>
      </v-tooltip>
    </template>
    <v-card class="px-4 pa-1">
      <v-checkbox v-for="(section, i) in sectionSet" :key="i" v-model="section.visible" :label="$t(section.label)" :color="color"/>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SETTING_KEYS, SETTING_TYPES } from '@/utils/UserSettings'
import { colorClassForContractMode } from '../../utils/componentHelpers'
export default {
  name: 'SectionPicker',

  props: {
    includeInventory: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    sections: [
      {
        id: 0,
        label: 'inventory',
        visible: true
      },
      {
        id: 1,
        label: 'loads',
        visible: true
      },
      {
        id: 2,
        label: 'operational',
        visible: true
      }
    ],
    sectionsBp: [
      {
        id: 0,
        label: 'inventory',
        visible: true
      },
      {
        id: 1,
        label: 'loads',
        visible: true
      }
    ],
    sectionPickerMenu: false
  }),

  created () {
    const hiddenSections = this.mutatedUserSettings[SETTING_TYPES.TABLE][this.settingKey] ?? []
    this.sectionSet = this.sectionSet.map(section => ({
      ...section,
      visible: hiddenSections.find(id => section.id === id) === undefined
    }))
  },

  computed: {
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    ...mapGetters('ticket', ['contractMode']),
    visibleSet () {
      return this.sectionSet.filter(s => s.visible).map(s => s.id)
    },

    settingKey () {
      return this.contractMode.value === 0 ? SETTING_KEYS.DASHBOARD_HIDDEN_SECTIONS : SETTING_KEYS.DASHBOARD_BP_HIDDEN_SECTIONS
    },

    sectionSet: {
      get: function () {
        const result = this.contractMode.value === 0 ? this.sections : this.sectionsBp
        return result.filter(i => this.includeInventory || i.label !== 'inventory')
      },
      set: function (val) {
        this.contractMode.value === 0 ? this.sections = val : this.sectionsBp = val
      }
    },

    color () {
      return colorClassForContractMode(this.contractMode.value, false)
    }
  },

  watch: {
    visibleSet: {
      handler (v) {
        this.$emit('sections', v)
      },
      immediate: true
    },

    sectionPickerMenu (isOpen) {
      if (!isOpen) {
        this.saveSettings()
      }
    },

    contractMode (_) {
      const hiddenSections = this.mutatedUserSettings[SETTING_TYPES.TABLE][this.settingKey] ?? []
      this.sectionSet = this.sectionSet.map(section => ({
        ...section,
        visible: hiddenSections.find(id => section.id === id) === undefined
      }))
    }
  },

  methods: {
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapMutations('user-settings', ['mutateUserSetting']),
    saveSettings () {
      this.mutateUserSetting({
        type: SETTING_TYPES.TABLE,
        key: this.settingKey,
        value: this.sectionSet.filter(s => !s.visible).map(s => s.id)
      })
      this.updateUserSettings(this.mutatedUserSettings)
    }
  }
}
</script>
