<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col align="right" cols="12" md="6" lg="5" xl="4">
        <v-row class="mb-n6">
          <v-col align="left" cols="8">
            <span class="title" style="font-weight: bold">
              {{ $t('totalRemainingBalance') }}:
            </span>
          </v-col>
          <v-col align="right" cols="4">
            <span class="title" style="font-weight: bold">
              {{ formatMoney(grandTotal) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-n1 mb-2">
          <v-col align="left" cols="8">
            <span class="subtitle-1">
              {{ $t('totalDepletedInBatch') }}:
            </span>
          </v-col>
          <v-col align="right" cols="4">
            <span class="subtitle-1">
              {{ formatMoney(totalDepletion) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n1">
      <v-expansion-panels v-model="openPanels" flat accordion tile multiple>
        <v-expansion-panel v-for="(key) in Object.keys(groupedDepletionSummaries)" :key="key">
          <v-expansion-panel-header ripple>
            <v-row>
              <v-col>
                {{ $t('tractStatus') }}: {{ TractStatus.find(ts => ts.value === Number(key)).name }}
              </v-col>
              <v-col align="right">
                {{ remainingDepletionTotal(groupedDepletionSummaries[key]) }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DataTable
            :items="groupedDepletionSummaries[key]"
            :headers="headers"
            :showSearch="false"
            :showDense="false"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import TractHeaders from '@/headers/Tract.js'
import { TractStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'DepletionModule',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  props: {
    depletionObj: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    openPanels: [0],
    TractStatus
  }),

  computed: {
    grandTotal () {
      return this.depletionObj.tractBalanceSummaries.reduce((total, dep) => total + (dep.tractCost - (dep.depletionInBatch + dep.previouslyDepleted)), 0)
    },

    totalDepletion () {
      return this.depletionObj.tractBalanceSummaries.reduce((total, dep) => total + dep.depletionInBatch, 0)
    },

    headers () {
      return TractHeaders.depletionBalanceHeaders().columns
    },

    items () {
      return this.depletionObj.tractBalanceSummaries.map(tbs => ({
        ...tbs,
        overUnder: this.overUnder(tbs),
        remaining: this.remaining(tbs),
        totalDepleted: this.totalDepleted(tbs)
      }))
    },

    groupedDepletionSummaries () {
      return Object.groupBy(this.items, tbs => tbs.tractStatus)
    }
  },

  methods: {
    formatMoney,
    utcToLocalDate,

    remainingDepletionTotal (items) {
      const unformattedAmount = items.reduce((remaining, curr) => remaining + (curr.tractCost - (curr.depletionInBatch + curr.previouslyDepleted)), 0)
      const amount = formatMoney(unformattedAmount)
      return `${this.$t('remainingBalance')}: ${amount}`
    },

    overUnder (item) {
      const totalDepletion = item.previouslyDepleted + item.depletionInBatch
      return totalDepletion - item.tractCost
    },

    remaining (item) {
      const totalDepletion = item.previouslyDepleted + item.depletionInBatch
      return item.tractCost - totalDepletion
    },

    totalDepleted (item) {
      return item.previouslyDepleted + item.depletionInBatch
    }
  }
}
</script>
