import i18n from '@/i18n'
import { DefectCategory, DefectCollectionType } from '@/utils/Enumerations.js'
import { tonStringFromPounds } from '@/utils/NumericMutations'

export function defectHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'defectLBS',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('defaultPercentage'),
      value: 'defaultPercentage',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('category'),
      value: 'category'
    },
    {
      sortable: true,
      text: i18n.t('status'),
      value: 'isActive'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function diameterWeightsHeaders () {
  return [
    {
      text: i18n.t('diameter'),
      value: 'diameter'
    },
    {
      text: i18n.t('weight'),
      value: 'weight'
    }
  ]
}

export function ticketDefectFormHeaders () {
  return [
    {
      sortable: false,
      text: i18n.t('defectDefinitions'),
      value: 'name'
    },
    {
      sortable: false,
      text: i18n.t('category'),
      value: 'category',
      format: (value) => DefectCategory.fromInt(value)
    },
    {
      sortable: false,
      text: i18n.t('quantity'),
      value: 'quantity',
      align: 'center'
    }
  ]
}

export function ticketDefectHeaders (defectType = DefectCollectionType.Itemized.value) {
  const variableHeaders = defectType === DefectCollectionType.Itemized.value
    ? [
      { sortable: true, text: i18n.t('quantity'), value: 'quantity', align: 'right' }
    ]
    : [
      { sortable: true, text: i18n.t('diameter'), value: 'diameter', align: 'right' },
      { sortable: true, text: i18n.t('percentage'), value: 'percentage', align: 'right', format: (value) => `${value}%` }
    ]
  return [
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('category'),
      value: 'category',
      format: (value) => DefectCategory.fromInt(value)
    },
    ...variableHeaders,
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'totalLBS',
      align: 'right',
      format: tonStringFromPounds
    }
  ]
}

export function ticketDefectCardHeader () {
  return [
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'defect'
    },
    {
      sortable: true,
      text: i18n.t('quantity'),
      value: 'quantity',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('defectTons'),
      value: 'defectLBS',
      align: 'right'
    }
  ]
}
