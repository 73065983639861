var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-row',{attrs:{"dense":""}},[(_vm.isByproductSale)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"3"}},[(_vm.showOtherDates)?_c('DatePicker',{attrs:{"dateLabel":_vm.$t('departedAt'),"dataTestId":"ticket-departedat-date","allowFuture":false,"startDate":_vm.departedAt,"max":new Date().toISOString()},on:{"date-picked":_vm.departedAtDateSelected,"valid-date":(val) => {
          _vm.isValidDepartureDate = val
          _vm.validEntry()
        }}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":_vm.isByproductPurchase ? '4' : '3'}},[(_vm.weighedInAt && _vm.showOtherDates && _vm.weighedInDate !== '')?_c('DatePicker',{attrs:{"dateLabel":_vm.$t('weighedInAt'),"dataTestId":"ticket-weighin-date","allowFuture":false,"startDate":_vm.weighedInAt,"max":new Date().toISOString()},on:{"date-picked":_vm.weighedInDateSelected,"valid-date":(val) => {
          _vm.isValidInDate = val
          _vm.validEntry()
        }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":_vm.isByproductPurchase ? '4' : '3'}},[(_vm.weighedOutAt !== '')?_c('DatePicker',{attrs:{"dateLabel":_vm.$t('weighedOutAt'),"data-testid":"ticket-weighout-date","allowFuture":false,"startDate":_vm.weighedOutAt,"max":new Date().toISOString()},on:{"date-picked":_vm.weighedOutDateSelected,"valid-date":(val) => {
          _vm.isValidOutDate = val
          _vm.validEntry()
        }}}):_vm._e()],1)],1),(_vm.showClockTime)?_c('v-row',{attrs:{"dense":""}},[(_vm.isByproductSale)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":_vm.isByproductPurchase ? '4' : '3'}},[(_vm.showOtherDates)?_c('TimePicker',{attrs:{"isEditing":_vm.isEditing,"startTime":_vm.departedAtTime,"expandable":false,"label":_vm.$t('departedAt'),"data-testid":"ticket-departure-time"},on:{"time-picked":_vm.departedAtTimeSelected,"valid-time":(val) => {
          _vm.isValidInTime = val
          _vm.validEntry()
        }}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":_vm.isByproductPurchase ? '4' : '3'}},[(_vm.showOtherDates)?_c('TimePicker',{attrs:{"isEditing":_vm.isEditing,"startTime":_vm.weighedInAtTime,"expandable":false,"data-testid":"ticket-weighin-time","label":_vm.$t('weighedInAt')},on:{"time-picked":_vm.weighedInTimeSelected,"valid-time":(val) => {
          _vm.isValidInTime = val
          _vm.validEntry()
        }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":_vm.isByproductPurchase ? '4' : '3'}},[_c('TimePicker',{attrs:{"expandable":false,"label":_vm.$t('weighedOutAt'),"data-testid":"ticket-weightout-time","startTime":_vm.weighedOutAtTime,"isEditing":_vm.isEditing},on:{"time-picked":_vm.weighedOutTimeSelected,"valid-time":(val) => {
          _vm.isValidOutTime = val
          _vm.validEntry()
        }}})],1)],1):_vm._e(),(!_vm.weighOutOnly)?_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":_vm.$t('showOtherDates'),"color":"secondary"},model:{value:(_vm.showOtherDates),callback:function ($$v) {_vm.showOtherDates=$$v},expression:"showOtherDates"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":_vm.$t('editClockTime'),"color":"secondary"},model:{value:(_vm.showClockTime),callback:function ($$v) {_vm.showClockTime=$$v},expression:"showClockTime"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"black","data-testid":"reset-date-button"},on:{"click":_vm.resetDates}},[_vm._v(" "+_vm._s(_vm.$t('resetDates'))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }