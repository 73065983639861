<template>
  <CsvImport v-bind="{
    exampleData,
    entityName,
    rowName,
    parse,
    preview,
    upload
  }">
    <template #preview-item.shipping="{ value }">
      <p v-for="(addressLine, i) in formatAddress(value)" :key="`shippingaddr-line-${addressLine}-${i}`" class="ma-0">
        {{ addressLine }}
        <br>
      </p>
    </template>
    <template #preview-item.billing="{ value }">
      <p v-for="(addressLine, i) in formatAddress(value)" :key="`billingaddr-line-${addressLine}-${i}`" class="ma-0">
        {{ addressLine }}
        <br>
      </p>
    </template>
    <template #preview-item.tags="{ value }">
      <p class="ma-0">
        {{ formatTags(value) }}
      </p>
    </template>
  </CsvImport>
</template>

<script>
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import { AddressType, TagArea } from '../../../utils/Enumerations.js'
import { accountBulkImportPreviewHeaders } from '../../../headers/Account'
import { mapActions } from 'vuex'
export default {
  name: 'AccountImports',

  components: {
    CsvImport: () => import('./CsvImport.vue')
  },

  data: () => ({
    accountTags: []
  }),

  computed: {
    entityName () { return this.$t('account') },
    rowName () { return a => a.name },
    parse () {
      return {
        schema: async () => {
          await this.getTags()

          return {
            name: CsvColumn.from({
              name: 'Name',
              type: CsvTypeTransformation.from({
                validate: s => s === '' || s.length > 64 ? this.$t('accountNameBlank') : true
              })
            }),
            code: CsvColumn.from({
              name: 'Code',
              type: CsvTypeTransformation.from({
                validate: s => s.length > 10 ? this.$t('accountCodeBlank') : true
              })
            }),
            exportCode: CsvColumn.from({
              name: 'ExportCode',
              type: CsvTypeTransformation.from({
                validate: s => s.length > 32 ? this.$t('accountExportCodeBlank') : true
              })
            }),
            shipping: {
              addressLine1: CsvColumn.from({ name: 'ShippingLine1' }),
              addressLine2: CsvColumn.from({ name: 'ShippingLine2' }),
              city: CsvColumn.from({ name: 'ShippingCity' }),
              postalCode: CsvColumn.from({ name: 'ShippingPostalCode' }),
              state: CsvColumn.from({ name: 'ShippingState' })
            },
            billing: {
              addressLine1: CsvColumn.from({ name: 'BillingLine1' }),
              addressLine2: CsvColumn.from({ name: 'BillingLine2' }),
              city: CsvColumn.from({ name: 'BillingCity' }),
              postalCode: CsvColumn.from({ name: 'BillingPostalCode' }),
              state: CsvColumn.from({ name: 'BillingState' })
            },
            tags: {
              tag1: CsvColumn.from({ name: 'Tag1', required: false }),
              tag2: CsvColumn.from({ name: 'Tag2', required: false }),
              tag3: CsvColumn.from({ name: 'Tag3', required: false }),
              tag4: CsvColumn.from({ name: 'Tag4', required: false }),
              tag5: CsvColumn.from({ name: 'Tag5', required: false })
            }
          }
        },

        validate: account => {
          const errors = []
          for (const address of [account.shipping, account.billing].filter(a => a.addressLine1)) {
            if (!address.city) errors.push(this.$t('addressMissingCity'))
            if (!address.state) errors.push(this.$t('addressMissingState'))
            if (!address.postalCode) errors.push(this.$t('addressMissingPostalCode'))
          }

          const { tag1, tag2, tag3, tag4, tag5 } = account.tags
          const tags = [tag1, tag2, tag3, tag4, tag5]

          for (const tag of tags) {
            if (!this.accountTags.map(t => t.value).includes(tag) && tag) errors.push(this.$t('invalidTag', { tagName: tag }))
            if (tags.filter(t => t === tag && t).length > 1 && !errors.includes(this.$t('multipleTags', { tagName: tag }))) {
              errors.push(this.$t('multipleTags', { tagName: tag }))
            }
          }

          if (tags.findLastIndex(e => e !== '') > tags.findIndex(e => e === '') && tags.includes('')) {
            errors.push(this.$t('invalidTagOrdering'))
          }

          return errors
        }
      }
    },

    preview: () => ({
      headers: accountBulkImportPreviewHeaders()
    }),

    upload () {
      return {
        map: ({ name, code, exportCode, shipping, billing, tags }) => ({
          name,
          code,
          exportCode,
          addresses: [
            shipping.addressLine1 ? { address: { ...shipping, type: AddressType.ShipTo.value } } : undefined,
            billing.addressLine1 ? { address: { ...billing, type: AddressType.BillTo.value } } : undefined
          ].filter(a => a),
          tagIds: this.getTagIds(tags)
        }),

        action: this.createAccount
      }
    },

    exampleData () {
      return 'data:text/csv;charset=utf-8,Name,Code,ExportCode,BillingLine1,BillingLine2,BillingCity,BillingPostalCode,BillingState,ShippingLine1,ShippingLine2,ShippingCity,ShippingPostalCode,ShippingState,Tag1,Tag2,Tag3,Tag4,Tag5\nCongaree Forestry Services,AC3082,EX9132,500 W5th St,Suite 500,Winston-Salem,27101,NC,500 W5th St,Suite 500,Winston-Salem,27101,NC,Vendor,Supplier,Truck,Mill,Logger\nRax Trucking,AC5268,EX1923,500 W5th St,Suite 502,Winston-Salem,27101,NC,500 W5th St,Suite 502,Winston-Salem,27101,NC,Customer,Vendor,,,'
    }
  },

  methods: {
    ...mapActions('account', ['createAccount']),
    ...mapActions('tags', ['fetchTags']),

    formatAddress (addr) {
      if (!addr.addressLine1 || !addr.city || !addr.state || !addr.postalCode) {
        return [this.$t('none')]
      } else {
        return addr.addressLine2
          ? [addr.addressLine1, addr.addressLine2, addr.city + ', ' + addr.state + ' ' + addr.postalCode]
          : [addr.addressLine1, addr.city + ', ' + addr.state + ' ' + addr.postalCode]
      }
    },

    formatTags (tags) {
      return Object.values(tags).filter(t => t.trim() !== '').join(', ')
    },

    async getTags () {
      const tags = await this.fetchTags(TagArea.Accounts.value)
      this.accountTags = tags
    },

    getTagIds (tags) {
      const filteredTags = Object.values(tags).filter(t => t)
      return this.accountTags.filter(t => filteredTags.includes(t.value)).map(t => t.tagId)
    }
  }
}
</script>
