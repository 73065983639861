import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { nestedTableStyling, defaultDocumentConfig } from './GenericSettlementsFunctions.js'
import { tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
PDFMake.vfs = pdfFonts.pdfMake.vfs

export const generatePdf = async ({
  tickets,
  deck,
  location,
  entity,
  headers,
  products
}) => {
  const docDefinition = {
    ...defaultDocumentConfig,
    header: getHeader(entity, location, deck),
    footer: getFooter,
    content: content(tickets, headers, products),
    pageOrientation: 'landscape',
    style: nestedTableStyling
  }

  await PDFMake.createPdf(docDefinition).download(`${entity}_${location}_${deck}.pdf`)
}

const getHeader = (entity, location, deck) => ({
  fontSize: 15,
  bold: true,
  marginTop: 10,
  marginLeft: 35,
  marginRight: 35,
  table: {
    widths: ['auto', '*', 'auto'],
    body: [
      [
        {
          table: {
            body: [
              [{ text: entity, alignment: 'left', layout: 'noBorders' }],
              [{ text: location, alignment: 'left', layout: 'noBorders', fontSize: 12 }]
            ]
          },
          layout: 'noBorders'
        },
        { text: '' },
        {
          table: {
            body: [
              [{ text: `Deck: ${deck}`, alignment: 'left', layout: 'noBorders', fontSize: 12 }]
            ]
          },
          layout: 'noBorders',
          alignment: 'right'
        }
      ]
    ]
  },
  layout: 'noBorders'
})

function getFooter (page, pages) {
  return {
    marginRight: 50,
    marginLeft: 50,
    columns: [
      {
        width: '*',
        text: `${page}/${pages}`,
        fontSize: 8,
        bold: true
      },
      {
        width: '*',
        text: `${utcToLocalDate(new Date().toISOString(), 'L - LT')}`,
        alignment: 'right',
        fontSize: 8,
        bold: true
      }
    ]
  }
}

const content = (tickets, headers, products) => {
  const headerRow = headers.map(h => ({ text: h.text, fontSize: 8, alignment: h.align ? h.align : 'left', bold: true }))

  const valueOf = (ticket, header) => {
    switch (header.value) {
      case 'netWeight':
        const netWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
        if (ticket.inWeight <= 0) {
          return 'N/A'
        } else {
          return tonStringFromPounds(netWeight)
        }
      case 'mbf':
        return numberWithCommas(Math.ceil((ticket.inWeight - ticket.outWeight - ticket.defectWeight) * products[ticket.product].conversionRateW2V))
      case 'extTicketNumber1' || 'extTicketNumber1':
        if (ticket[header.value] === '') {
          return '-'
        } else {
          return ticket[header.value]
        }
      default:
        return header.format ? header.format(ticket[header.value]) : ticket[header.value]
    }
  }

  const bodyRows = tickets
    .map((t) => headers
      .map(h => ({ text: valueOf(t, h), fontSize: 8, alignment: h.align }))
    )

  return [
    {
      marginBottom: 24,
      layout: tableLayout(),
      table: {
        alignment: 'right',
        headerRows: 1,
        dontBreakRows: true,
        body: [
          [
            { text: 'Totals', fontSize: 8, bold: true },
            { text: '', fontSize: 8 }
          ],
          [
            { text: 'Loads', fontSize: 8 },
            { text: `${tickets.length}`, alignment: 'right', fontSize: 8 }
          ],
          [
            { text: 'Net Tons', fontSize: 8 },
            { text: `${tonStringFromPounds(tickets.map((t) => t.inWeight - t.outWeight - t.defectWeight).reduce((a, b) => a + b, 0))}`, alignment: 'right', fontSize: 8 }
          ],
          [
            { text: 'MBF', fontSize: 8 },
            { text: `${numberWithCommas(tickets.map((t) => (t.inWeight - t.outWeight - t.defectWeight) * products[t.product].conversionRateW2V).reduce((a, b) => a + b, 0))}`, alignment: 'right', fontSize: 8 }
          ]
        ]
      }
    },
    {
      marginBottom: 24,
      layout: tableLayout(),
      table: {
        widths: headers.map(h => h.width),
        headerRows: 1,
        body: [
          headerRow,
          ...bodyRows
        ]
      }
    }
  ]
}

const tableLayout = () => ({
  fillColor: function (rowIndex) {
    return (rowIndex % 2 === 0) ? 'white' : 'lightgrey'
  },
  vLineWidth: function (i) {
    return 0
  },
  hLineWidth: function (i) {
    if (i === 1) {
      return 1
    } else {
      return 0
    }
  }
})
