import messages from '@/lang'

export function tagHeaders (locale) {
  return [
    {
      sortable: true,
      text: messages[locale].name,
      value: 'value'
    },
    {
      sortable: false,
      text: messages[locale].actions,
      value: 'actions',
      align: 'right'
    }
  ]
}
